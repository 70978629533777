import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Button } from '../../../../UI';
import { Select, TextInput, CheckItem } from '../../../../Forms';

const Inline = (props) => {
	// The block's data object
	const block = props.block;

	// the formValidation hook.
	const formValidation = props.formValidation;

	const changedHandler = props.changed;
	const inputChangedHandler = props.inputChanged;
	const checkboxChangedHandler = props.checkboxChanged;

	const contentPosition = props.contentPosition;
	const openMainMenuSelector = props.openMainMenuSelector;

	return (
		<>
			<Select
				label="Text"
				name="has_caption"
				changed={(ev) => {
					changedHandler(
						ev.target.name,
						ev.target.value === 'true' ? true : false,
						false
					);
				}}
				value={block.has_caption}
			>
				<option value={false}>Dold</option>
				<option value={true}>Synlig</option>
			</Select>

			{block.has_caption && (
				<Select
					label="Textens placering"
					name="content_position"
					changed={inputChangedHandler}
					value={contentPosition}
				>
					<option value="above">Ovanför bild</option>
					<option value="below">Nedanför bild</option>
				</Select>
			)}

			<Select
				label="Länk"
				name="link_type"
				changed={inputChangedHandler}
				value={block.link_type}
			>
				<option value="none">Ingen</option>
				<option value="on_image">Hela bilden</option>
			</Select>

			{(() => {
				if (block.link_type === 'on_image') {
					return (
						<>
							<TextInput
								label="Länkadress (URL)"
								name="link.uri"
								id="link_uri_inline"
								value={block.link.uri}
								preventBuilderPreview
								formValidationUnregister={
									formValidation.unregisterElement
								}
								changed={(ev, ...data) => {
									formValidation.watch(
										ev,
										inputChangedHandler,
										data
									);
								}}
								error={formValidation.errors['link_uri_inline']}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true,
										validation: 'slugurl',
									})
								}
							/>

							<ScButtonContainer>
								<ScButton
									isPrimary
									isSmall
									onClick={openMainMenuSelector}
								>
									Välj i sidträd
								</ScButton>
							</ScButtonContainer>

							<CheckItem
								type="checkbox"
								title="Öppna länken i ett nytt fönster"
								description="Länken öppnas i ett nytt fönster eller flik"
								name="link.new_window"
								changed={checkboxChangedHandler}
								checked={block.link.new_window}
							/>
						</>
					);
				}
			})()}
		</>
	);
};

Inline.propTypes = {
	openMainMenuSelector: PropTypes.func.isRequired,
	block: PropTypes.object.isRequired,
	contentPosition: PropTypes.string.isRequired,

	changed: PropTypes.func.isRequired,
	inputChanged: PropTypes.func.isRequired,
	checkboxChanged: PropTypes.func.isRequired,

	formValidation: PropTypes.object.isRequired,
};

export default Inline;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;
