import * as actionTypes from './actionTypes';

/**
 * Opens a modal with a certain config.
 * @param {*} title
 */
export const openModalAction = (initialState) => {
	return {
		type: actionTypes.OPEN_MODAL,
		initialState: initialState,
	};
};

export const closeModalAction = (modalId) => {
	return {
		type: actionTypes.CLOSE_MODAL,
		modalId: modalId,
	};
};

export const setModalConfigAction = (modalId, configKey, value) => {
	return {
		type: actionTypes.SET_MODAL_CONFIG,
		modalId: modalId,
		configKey: configKey,
		data: value,
	};
};

export const setModalStateAction = (modalId, data) => {
	return {
		type: actionTypes.SET_MODAL_STATE,
		modalId: modalId,
		data: data,
	};
};

/**
 * 
 * @param {*} modalId 
 * @param {*} updatedState 
 */
export const updateModalStateAction = (modalId, updatedState) => {
	return {
		type: actionTypes.UPDATE_MODAL_STATE,
		modalId: modalId,
		updatedState: updatedState,
	};
};
