import React from 'react';
import styled, { css } from 'styled-components/macro';
import itemTypes from './itemTypes';
import Draggable from '../../hoc/DragDrop/Draggable';
import { Icon, Tooltip } from '../UI';
import { SkeletonNavItem } from '../Skeletons';
import { TextInput, CheckItem } from '../Forms';

const Item = (props) => {
	const id = props.item.id;
	const uuid = props.item.uuid;
	const isChild = props.item.is_child;
	const isParent = props.item.children.length > 0;
	const amountChildren = props.item.children.length || 0;
	const parentReference = props.item.parent_reference;
	const type = props.item.type;
	const title = props.item.title;
	const tooltip = props.item.tooltip;
	const isDraft = props.item.is_draft;
	const inputDoneEditing = props.inputDoneEditing;

	let clickTimer = 0;
	let clickPrevent = false;

	// whether an item can expand/collapse or not
	let expandable = null;
	if(isParent) {
		expandable = (
			<ScIconContainer>
				<Icon
					onClick={(ev) => props.toggleExpand(ev, id)}
					icon={[
						'fal',
						props.isExpanded ? 'chevron-up' : 'chevron-down'
					]}
				/>
			</ScIconContainer>
		);
	}

	// Create additional icons
	// if a middleware parameter is set to the opts declaration then
	// the middleware will be called first and may change original data or add more parameters
	let opts = null;
	if(Array.isArray(props.opts) && props.opts.length > 0) {
		opts = props.opts.map((opt, index) => {
			if(!props.isInput && !props.newItemIsBeingCreated) {
				if(
					!opt.showOnlyForType ||
					opt.showOnlyForType.includes(type) ||
					(props.isStackable && opt.showForStackableItems)
				) {
					const hasPermission =
						'hasPermission' in opt ? opt.hasPermission : true;

					return (
						<ScIconActions
							isDisabled={!hasPermission}
							key={`opt_${index}`}
						>
							<Icon
								icon={opt.icon}
								onClick={(ev) => {
									if(!hasPermission) return;

									ev.stopPropagation();
									if(
										!opt.middleware &&
										!props.middlewares[opt.middleware]
									) {
										return opt.action(ev, opt, props);
									} else {
										return props.middlewares[
											opt.middleware
										](ev, opt, props, opt.action);
									}
								}}
								{...opt.props}
							/>
						</ScIconActions>
					);
				}
			}

			return null;
		});
	}

	/**
	 * Calls the inputDoneEditing callback
	 * 
	 * @param {Event} ev
	 * @returns {void}
	 */
	const inputDoneHandler = React.useCallback((ev) => {
		if(inputDoneEditing) inputDoneEditing(ev, props.item, props.rootNavigationId);
	}, [inputDoneEditing, props.item, props.rootNavigationId]);

	return (
		<>
			<ScNavItem
				id={`${props.scope}_id_${id}`}
				itemId={id}
				uuid={uuid}
				index={props.index}
				rootNavigationId={props.rootNavigationId}
				parentReference={parentReference}
				itemType={type}
				amountChildren={amountChildren}
				enableDrag={props.enableDrag || false}
				isStackable={props.isStackable}
				isExpanded={props.isExpanded}
				isChild={isChild}
				isParent={isParent}
				isParentLastInScope={props.isParentLastInScope}
				isParentInRootLevel={props.isParentInRootLevel}
				isHandle={true}
				allowedDirection="vertical"
				scope={props.scope}
			>
				{props.isLoading ? (
					<SkeletonNavItem />
				) : (
					<>
						{tooltip && (
							<ScTooltip style={tooltip.style}>
								<Tooltip
									isBright={props.isHighlighted}
									isRight
									text={tooltip.text}
								>
									<ScTooltipIcon icon={tooltip.icon} />
								</Tooltip>
							</ScTooltip>
						)}
						<ScNavItemContainer
							isHighlighted={props.isHighlighted}
							isExpanded={props.isExpanded}
							isDisabled={props.isDisabled}
							isChecked={props.isChecked}
							isInactive={props.isInactive}
							itemType={type}
							useCheckboxes={props.useCheckboxes ? true : false}
							onClick={(ev) => {
								ev.stopPropagation();
								if(props.isInput || !props.clicked || props.isDisabled) return;

								clickTimer = setTimeout(function () {
									if(!clickPrevent) {
										props.clicked(ev, { ...props.item });
									}
									clickPrevent = false;
								}, 200);
							}}
							onDoubleClick={(ev) => {
								ev.stopPropagation();
								if(props.isInput || !props.dblClicked || props.isDisabled || props.isInactive) return;

								clearTimeout(clickTimer);
								clickPrevent = true;

								props.dblClicked(ev, { ...props.item });
							}}
						>
							<ScTitleContainer className={props.className}>
								{props.useCheckboxes && props.selectMultiple ? (
									<>
										<ScCheckContainer>
											<ScCheckbox
												type="checkbox"
												id={id}
												width="16px"
												height="16px"
												changed={props.useCheckboxes}
												checked={props.isChecked}
											/>
										</ScCheckContainer>

										<ScIconContainer>
											<Icon
												fixedWidth
												icon={defineIcon(
													type,
													props.isExpanded,
													props.isStackable,
													props.stackableIcons,
													amountChildren,
													isDraft
												)}
											/>
										</ScIconContainer>
									</>
								) : (
									<ScIconContainer>
										<Icon
											fixedWidth
											icon={defineIcon(
												type,
												props.isExpanded,
												props.isStackable,
												props.stackableIcons,
												amountChildren,
												isDraft
											)}
										/>
									</ScIconContainer>
								)}

								<ScTitle>
									{props.isInput ? (
										<ScNewItemInput
											inputRef={(ev) => props.scrollTo(ev)}
											blurred={inputDoneHandler}
											pressed={(ev) => {
												if(ev.key === 'Enter') inputDoneHandler(ev);
											}}
											placeholder="New item"
										/>
									) : (
										title
									)}
								</ScTitle>
							</ScTitleContainer>

							<ScIconWrapper>
								{!props.isInactive && opts}
								{expandable}
							</ScIconWrapper>
						</ScNavItemContainer>
						{props.children}
					</>
				)}
			</ScNavItem>
		</>
	);
};

/**
 * * Defines an icon
 *
 * @param {string} itemType
 * @param {boolean} isExpanded
 * @param {boolean} isStackable
 * @param {object} stackableIcons
 * @param {integer} amountChildren
 */
const defineIcon = (
	itemType,
	isExpanded,
	isStackable,
	stackableIcons,
	amountChildren,
	isDraft
) => {
	switch(true) {
		case isStackable && stackableIcons && amountChildren > 0:
			return isExpanded
				? stackableIcons.stacked.expanded
				: stackableIcons.stacked.collapsed;

		case isStackable && stackableIcons && amountChildren === 0:
			return stackableIcons.single;

		case isDraft:
			return ['fal', 'file-pen'];

		case itemType === 'folder' && isExpanded:
			return 'folder-open';

		default:
			return itemTypes.find((type) => type.name === itemType).icon;
	}
};

export default Item;

const ScIconContainer = styled.div`
	margin-right: 8px;
	/* width: 16px; */
`;

const ScIconActions = styled.div`
	margin-right: 8px;
	/* width: 16px; */
	display: none;
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;

const ScTooltipIcon = styled(Icon)`
	padding: 0;

	svg {
		height: 12px;
		width: 12px;
	}
`;

const ScNewItemInput = styled(TextInput)`
	background-color: #fafafa;
	border-radius: 4px;
	line-height: 32px;
	height: 32px;
	margin: 0;
	border: 0;
	outline: none;
	box-shadow: none;
	color: #333;

	:focus {
		border-color: transparent;
		box-shadow: 0 0 0px rgba(0, 0, 0, 0);
	}
`;

const ScTooltip = styled.div`
	position: absolute;
	top: 4px;
	left: 8px;
	display: flex;
	line-height: 0;
	justify-content: center;
	z-index: 9;

	${(props) =>
		props.style &&
		props.style.color &&
		css`
			color: ${props.style.color};
		`}
`;

const ScTitle = styled.div`
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ScTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding-right: 8px;
	padding-left: 8px;
	overflow: hidden;
	height: 40px;
	align-items: center;
`;

const ScIconWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const ScNavItemContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 8px;

	:hover {
		background-color: var(--bg-grey-color);
		.isDragging & {
			background-color: transparent;
			font-weight: 400;
		}

		${ScIconWrapper} > {
			${ScIconActions} {
				display: block;

				.isDragging & {
					display: none;
				}
			}
		}
	}

	${(props) =>
		props.isHighlighted &&
		!props.useCheckboxes &&
		css`
			/* font-weight: 600; */
			color: var(--navItemHighlited-font-Color);
			background-color: var(--navItemHighlited-bg-Color);

			&:hover {
				background-color: var(--navItemHighlited-bg-Color);
			}
		`}

	${(props) =>
		props.isHighlighted &&
		props.useCheckboxes &&
		css`
			${ScTitle} {
				font-weight: bold;
			}
		`}    

	${(props) =>
		props.isDisabled &&
		css`
			${ScTitleContainer} {
				color: var(--navItemNotPublished-font-Color);
				pointer-events: none;
			}
		`}

	${(props) => props.isInactive && css`
		${ScTitleContainer} {
			color: var(--navItemNotPublished-font-Color);
		}
	`}
`;

const ScNavItem = styled(Draggable)`
	position: relative;
	align-items: flex-start;
	display: flex;
	user-select: none;
	flex-direction: column;
	cursor: pointer;
	/* div[itemtype='folder'] {
		svg path{
						stroke: #333;
						stroke-width: 16px;
					}
	}
	 */

	body.isDragging &:hover {
		${(props) =>
		(props.isStackable || props.isParent) &&
			css`
				${(props) =>
		!props.isExpanded &&
					css`
						color: #333;
						font-weight: 900;
						position: relative;
						overflow: visible;

						animation: example 0.2s ease-in 0.5s forwards;
					`}
			`}

		&.dragClone {
			opacity: 0.4;
			* {
				&:before,
				&:after {
					content: none;
				}
			}

			&:after,
			&:before {
				content: none;
			}
			${ScNavItemContainer} {
				&:after,
				&:before {
					content: none;
				}
			}
			${ScIconWrapper} {
				display: none;
			}
		}
	}

	${(props) =>
		props.publishDateIcon === 'isScheduled' &&
		css`
			${ScTitleContainer} {
				color: var(--navItemNotPublished-font-Color);
			}
		`}

	${(props) =>
		props.publishDateIcon === 'hasPassed' &&
		css`
			${ScTitleContainer} {
				color: var(--navItemNotPublished-font-Color);
			}
		`}

${(props) =>
		props.publishDateIcon === 'isScheduled' &&
		css`
			${ScTooltipIcon} {
				display: inline-block;
				color: #20b126;
			}
		`}

${(props) =>
		props.publishDateIcon === 'hasPassed' &&
		css`
			${ScTooltipIcon} {
				display: inline-block;
				color: #d0021b;
			}
		`}

${(props) =>
		props.isChild &&
		css`
			border-left: 1px solid gray;
			margin-left: 24px;
			border: 0;
			width: calc(100% - 24px);

			:before {
				content: '';
				position: absolute;
				width: 1px;
				opacity: 1;
				height: 100%;
				left: -24px;
				background-color: #ccc;
			}

			${ScNavItemContainer} {
				position: relative;

				:after {
					position: absolute;
					left: 0;
					top: 50%;

					background-color: #ccc;
					content: '';
					height: 1px;
					width: 10px;
				}

				:before {
					bottom: 0;
					left: 0;
					position: absolute;
					top: 0;

					background-color: #ccc;
					content: '';
					width: 1px;
				}
			}

			:last-child > {
				${ScNavItemContainer}::before {
					bottom: 50%;
				}
			}
		`}

${(props) =>
		props.isParentLastInScope &&
		css`
			//& {
			//	border-left: 1px solid transparent;
			//}
			:before {
				content: none;
			}
		`}

${(props) =>
		props.isParentInRootLevel &&
		css`
			/* border-left: 1px solid transparent; */
			&:before {
				content: none;
			}
		`}
`;

const ScCheckbox = styled(CheckItem)`
	margin-bottom: 0;
	margin-left: 4px;
`;

const ScCheckContainer = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`;