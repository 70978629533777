import React from 'react';
import styled from 'styled-components/macro';
import { GridViewProps } from './GridView.types';
import { SkeletonGrid } from '../../../Skeletons';
import BlockEmpty from '../../../Builder/BlockTypes/BlockEmpty/BlockEmpty';

// component to show when props.children is empty
const emptyComp = (
	<BlockEmpty
		style={{ background: 'transparent' }}
		icon={['fal', 'ghost']}
		title="Här var det tomt..."
		description=""
	/>
);

const GridView: React.FC<GridViewProps> = (props) => {
	const gridViewContent = props.children ?? emptyComp;

	return (
		<ScView>
			{props.isLoading ? <SkeletonGrid /> : gridViewContent}
		</ScView>
	);
};

export default GridView;

const ScView = styled.div`
	align-content: flex-start;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	overflow-y: auto;
	padding: 32px 32px 80px;
	background: #fff;
`;