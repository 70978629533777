import update from 'immutability-helper';
import {
	OrderState,
	SetOrdersAction,
	SetOrderAction
} from './../types/OrderTypes';
import { SET_LISTITEM_ORDERS, SET_ORDER } from '../actions/actionTypes';

const initialState: OrderState = {
	// all orders in a summarized format (OrderListtiem instead of a full Order object)
	listItemOrders: [],

	// stores a single order, will be the last one fetched with fetchOrder
	currentOrder: null,

	// the current state of the pagination.
	paginationInfo: {
		// total amount of pages available.
		pageCount: 0,

		// total amount of results in all pages.
		totalResults: 0,

		// how many items/results there are showed in a page.
		resultsPerPage: 0
	}
};

let updatedState;

/**
 * Handles the action with new state's data and create a new state to store.
 *
 * @param {object} state The new state to store.
 * @param {object} action The action data the reducer will use to calculate new state.
 */
const orderManagement = (
	state = initialState,
	action: SetOrdersAction | SetOrderAction
) => {
	switch(action.type) {
		case SET_LISTITEM_ORDERS:
			updatedState = update(state, {
				listItemOrders: {
					$set: action.orders
				},

				paginationInfo: {
					$set: action.paginationInfo
				}
			});

			return updatedState;

		case SET_ORDER:
			return update(state, {
				currentOrder: {
					$set: action.order
				}
			});

		default:
			return state;
	}
};

export default orderManagement;
