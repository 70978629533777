import React from 'react';
import { PageLanguageContext } from '../../PageLanguage/PageLanguageContext/PageLanguageContext';

/**
 * This function is a shortcut to access the page language context
 * It returns the active page language and a method to change language
 * 
 */
const usePageLanguage = () => {
	const { activeLanguage, setLanguage }  = React.useContext(PageLanguageContext);

	/**
     * Change the page language state
     * 
	 * @param {string} lang
     * @returns {void}
     */
	const changeLanguage = React.useCallback((lang: string): void => {
		setLanguage(lang);
	}, [setLanguage]);

	return  { 
		activeLanguage, 
		changeLanguage
	};
};

export default usePageLanguage;
