import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const InternalLink = (props) => {
	return (
		<ScContainer
			to={props.to}
			color={props.color}
			className={props.className}
			style={props.style}
		>
			{props.children}
		</ScContainer>
	);
};

export default InternalLink;

InternalLink.propTypes = {
	to: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.string
};

const ScContainer = styled(Link)`
	text-decoration: none;
	display: inline-block;
	color: var(--font-body-color);

	&:hover {
		text-decoration: underline;
	}

	&:active {
		color: inherit;
	}
	&:visited {
		color: inherit;
	}
`;
