import React from 'react';
import styled from 'styled-components/macro';

const DialogSection = (props) => {
	return (
		<ScContainer className={props.className}>
			<ScDialogTitle>{props.title}</ScDialogTitle>
			<ScActions>{props.children}</ScActions>
		</ScContainer>
	);
};

export default DialogSection;

const ScContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ScDialogTitle = styled.div`
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	background: var(--bg-dark-grey-color);
	font-weight: 600;
	padding: 8px;
	font-size: 14px;
	color: var(--font-bright-color);
`;
const ScActions = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
