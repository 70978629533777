import styled from 'styled-components/macro';

import Frame from 'react-frame-component';

const Iframe = styled(Frame)`
	height: 100%;
	transition: width 0.3s ease;

	${(props) =>
		props.screenwidth ? `width: ${props.screenwidth};` : 'width: 100%;'}
`;

export default Iframe;
