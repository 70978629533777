import * as actionTypes from './actionTypes';
import { navigationWithPublishIcon } from '../../utils/publishDateHelper';

// -----------------------------------
// --------- Navigation

/**
 * Set the current selected navItem(s)
 *
 * @param {string[]} selected 	The selected navitems.
 */
export const setSelectedNavigationAction = (selected) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_SET_SELECTED,
		selected: selected,
	};
};

/**
 * Clean the current selected navItems.
 */
export const cleanSelectedNavigationAction = () => {
	return {
		type: actionTypes.CACHE_NAVIGATION_CLEAN_SELECTED,
	};
};

/**
 *
 * Duplicates a navigation item.
 *
 * @param {*} newNavObj
 * @param {*} originalNavID
 */
export const duplicateNavigationAction = (newNavObj, originalNavID) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_DUPLICATE,
		newNavObj: newNavObj,
		originalNavID: originalNavID,
	};
};

/**
 * Duplicate a navigation item of type folder.
 * 
 * @param {number} originalNavID		The id of the original anv item that we want dulpicated.
 * @param {object} duplicatedItem 		Duplicated new nav item of type folder.
 * @param {object} newItems 			All new children items of duplicatedItem
 */
export const duplicateNavigationFolderAction = (originalNavID, duplicatedItem, newItems) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_FOLDER_DUPLICATE,
		data: {
			originalNavID: originalNavID,
			duplicatedItem: duplicatedItem,
			newItems: newItems,
		},
	};
};

/**
 * Removes a navigation item.
 * 
 * @param {int} itemID     The id of the navitem
 * @param {int} parentID   The id of the navitems's parent
 */
export const removeNavigationAction = (itemID, parentID) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_REMOVE,
		itemID: itemID,
		parentID: parentID,
	};
};

/**
 * Store a complete object with navigation items
 *
 */
export const storeAllNavigation = (navigation) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_POPULATE,
		navigation: navigation,
	};
};

/**
 * Updates the navigation tree with the new content
 *
 * @param {object} data
 */
export const updateNavigationItems = (data) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_UPDATE,
		navigation: navigationWithPublishIcon(data),
	};
};

// -----------------------------------
// --------- Templates

/**
 * Store all available templates
 * @param {object}  templates    All templates available
 */
export const storeAllTemplates = (templates) => {
	return {
		type: actionTypes.CACHE_TEMPLATES_POPULATE,
		templates: templates,
	};
};

/**
 * Store all available templates
 *
 * @param {object} templates All templates available
 */
export const storeAllNavigationsTypes = (navigationTypes) => {
	return {
		type: actionTypes.CACHE_NAVIGATION_TYPES_POPULATE,
		navigationTypes: navigationTypes,
	};
};
