import * as actionTypes from './actionTypes';

/**
 * Generic block content updating
 *
 * @param {object} tree
 */
export const setCategoriesTree = (tree) => {
	return {
		type: actionTypes.SET_CATEGORIES_TREE,
		tree: tree
	};
};

/**
 * Fetches all products
 *
 * @param {array} products
 */
export const setProducts = (products, query) => {
	return {
		type: actionTypes.SET_PRODUCTS,
		products: products,
		query: query
	};
};

/**
 * Sets a single product
 *
 * @param {*} product
 */
export const setProduct = (product) => {
	return {
		type: actionTypes.SET_PRODUCT,
		product: product
	};
};

/**
 *
 * Appends products
 *
 * @param {*} products
 */
export const appendProducts = (products) => {
	return {
		type: actionTypes.APPEND_PRODUCTS,
		products: products
	};
};

/**
 *
 * @param {int} productId
 * @param {array} categories
 * @param {object} settings
 */
export const updateProductState = (
	productId,
	categories,
	settings,
	thumbnail
) => {
	return {
		type: actionTypes.UPDATE_PRODUCT,
		productId,
		categories,
		settings,
		thumbnail: thumbnail
	};
};

export const clearProduct = () => {
	return {
		type: actionTypes.CLEAR_EDIT_PRODUCT
	};
};

/**
 * Will call reducer that appends categories
 * to a products current set
 *
 * @param {id} product
 * @param {array} categories
 */
export const setCategoriesToProduct = (productId, categories) => {
	return {
		type: actionTypes.SET_PRODUCT_CATEGORIES,
		productId: productId,
		categories: categories
	};
};

/**
 * Will call reducer that appends categories
 * to a list of products current set
 *
 * @param {array} products
 * @param {array} categories
 */
export const setCategoriesToProducts = (products, categories) => {
	return {
		type: actionTypes.APPEND_PRODUCTS_CATEGORIES,
		products: products,
		categories: categories
	};
};

/**
 * Adds a selected product
 *
 * @param {int|string} productId
 */
export const selectProduct = (productId) => {
	return {
		type: actionTypes.SELECT_PRODUCT,
		productId: productId
	};
};

/**
 * Remove a selected product
 *
 * @param {int|string} productId
 */
export const deselectProduct = (productId) => {
	return {
		type: actionTypes.DESELECT_PRODUCT,
		productId: productId
	};
};

export const selectProductAndCategories = (productId, categories) => {
	return {
		type: actionTypes.SELECT_PRODUCT_AND_CATEGORIES,
		productId: productId,
		categories: categories
	};
};

export const selectProductDeselectCategories = (productId, categories) => {
	return {
		type: actionTypes.SELECT_PRODUCT_AND_DESELECT_CATEGORIES,
		productId: productId
	};
};

export const setSelectedCategories = (categories) => {
	return {
		type: actionTypes.SELECT_CATEGORY,
		categories: categories
	};
};

export const deselectAll = () => {
	return {
		type: actionTypes.DESELECT_ALL
	};
};

export const markProductAsReady = (productsIds) => {
	return {
		type: actionTypes.MARK_PRODUCT_AS_READY,
		productsIds: productsIds
	};
};

export const setFilteredProducts = (products, value) => {
	return {
		type: actionTypes.SET_FILTERED_PRODUCTS,
		products: products,
		filtered: value
	};
};

export const clearFilteredProducts = () => {
	return {
		type: actionTypes.CLEAR_FILTERED_PRODUCTS
	};
};

export const clearState = () => {
	return {
		type: actionTypes.CLEAR_PRODUCT_STATE
	};
};
