import update from 'immutability-helper';

/**
 * Helper function that will calculate the position of an item dropped on the folder
 *
 * @param source
 * @param destination
 * @returns {{updatedState: {}, payload: {parent_id: *, navigation_id: *, index}}}
 */
export const storeInFolder = (payload, source, destination) => {
	if (source.parentReference === destination.itemId) return {};

	let willBeChild = destination.itemType === 'folder';

	// the folder that will adopt the item
	const adoptiveParent = destination.itemId;

	// see how many children it currently has
	// using the length of the array equals next index and thus will place at bottom
	const amountOfChildren = payload[adoptiveParent].children.length;

	const updatedState = update(payload, {
		// remove the child from it's parent children's array
		[source.parentReference]: {
			children: {
				$splice: [[source.index, 1]],
			},
		},
		// add the child to the adoptive parents children array at last index
		[destination.itemId]: {
			children: {
				$splice: [[amountOfChildren, 0, source.itemId]],
			},
		},
		// update the dragged items references
		[source.itemId]: {
			parent_reference: {
				$set: destination.itemId,
			},
			is_child: {
				$set: willBeChild,
			},
		},
	});

	return {
		updatedState: updatedState,
		axiosPayload: {
			parent_id: destination.itemId,
			navigation_id: destination.rootNavigationId,
			index: amountOfChildren,
		},
	};
};

export const storeInStack = (payload, source, destination) => {
	if (source.parentReference === destination.itemId) return {};

	// the folder that will adopt the item
	const adoptiveParent = destination.itemId;

	if (adoptiveParent === source.itemId) return;

	// see how many children it currently has
	// using the length of the array equals next index and thus will place at bottom
	const amountOfChildren = payload[adoptiveParent].children.length;

	const updatedState = update(payload, {
		// remove the child from it's parent children's array
		[source.parentReference]: {
			children: {
				$splice: [[source.index, 1]],
			},
		},
		// add the child to the adoptive parents children array at last index
		[destination.itemId]: {
			children: {
				$splice: [[amountOfChildren, 0, source.itemId]],
			},
		},
		// update the dragged items references
		[source.itemId]: {
			parent_reference: {
				$set: destination.itemId,
			},
			is_child: {
				$set: true,
			},
		},
	});

	return {
		updatedState: updatedState,
		axiosPayload: {
			parent_id: destination.itemId,
			navigation_id: destination.rootNavigationId,
			index: amountOfChildren,
		},
	};
};

/**
 * Will store
 *
 * @param source
 * @param destination
 */
export const addToEmptyCategory = (payload, source, destination) => {
	const updatedState = update(payload, {
		// remove the source from it's parent
		[source.parentReference]: {
			children: {
				$splice: [[source.index, 1]],
			},
		},
		// add to the new category
		[destination.navId]: {
			children: {
				$splice: [[0, 0, source.itemId]],
			},
		},
		// update the child's parent reference
		[source.itemId]: {
			parent_reference: {
				$set: destination.navId,
			},
			is_child: {
				$set: false,
			},
		},
	});

	return {
		updatedState,
		axiosPayload: {
			parent_id: destination.navId,
			navigation_id: destination.navId,
			index: 0,
		},
	};
};

/**
 * Helper function that will calculate the changes of position of an item
 *
 * @param source
 * @param destination
 * @param instruction
 * @returns {{updatedState: {}, payload: {parent_id: *, navigation_id: *, index}}}
 */
export const changePosition = (payload, source, destination, instruction) => {
	const sourceIndex = source.index;
	const destinationIndex = destination.index;
	const adjustedIndex = instruction.adjustIndex;

	let index = destinationIndex;

	switch (true) {
		case instruction.position === 'bottom' &&
			destination.parentReference !== source.parentReference:
			index = index + 1;
			break;

		case destination.parentReference === source.parentReference &&
			sourceIndex > destinationIndex &&
			instruction.position === 'top':
			index = destinationIndex;
			break;

		case destination.parentReference === source.parentReference &&
			sourceIndex < destinationIndex &&
			instruction.position === 'top':
			index = destinationIndex - 1;
			break;

		default:
	}

	const updatedState = update(payload, {
		// remove from item from the source parent children's array
		// note, this piece of code only runs if destination parent is different from source parent
		[source.parentReference]: {
			children: {
				$splice: [[source.index, 1]],
			},
		},
		[destination.parentReference]: {
			children: {
				$splice: [
					source.parentReference !== destination.parentReference
						? [sourceIndex - adjustedIndex, 0]
						: [sourceIndex, 1],
					[index, 0, source.itemId],
				],
			},
		},
		[source.itemId]: {
			parent_reference: {
				$set: destination.parentReference,
			},
			is_child: {
				$set: destination.isChild,
			},
		},
	});

	return {
		updatedState: updatedState,
		axiosPayload: {
			parent_id: destination.parentReference,
			navigation_id: destination.rootNavigationId,
			index: index,
		},
	};
};

/**
 * Adding a new nav item state helper
 *
 * @param {object} payload
 * @param {object} item
 */
export const addedNewItem = (payload, item) => {
	let spliceIndex = payload[item.parent_reference].children.findIndex(
		(itemKey) => itemKey === 'add'
	);

	const updatedState = update(payload, {
		[item.parent_reference]: {
			children: {
				$splice: [[spliceIndex, 1, item.id]],
			},
		},
		$merge: {
			[item.id]: item,
		},
		$unset: ['add'],
	});

	return updatedState;
};

/**
 * Updates a navitem on changes through settings modal
 *
 * @param {object} payload
 * @param {object} updatedNavItem
 */
export const updatedNavItem = (payload, updatedNavItem) => {
	// Get original item as updatedNavItem does not contain all the data needed like the 'id'
	const originalNavItem = Object.values(payload).find(
		(item) => item.uuid === updatedNavItem.uuid
	);

	// Convert the template id from "string" to "int"
	updatedNavItem.template_id = +updatedNavItem.template_id;

	// Generate updated state
	const updatedState = update(payload, {
		[originalNavItem.id]: {
			$merge: updatedNavItem,
		},
	});

	return updatedState;
};

/**
 * Another connection added a new nav item - state helper
 *
 * @param {object} payload
 * @param {object} item
 */
export const socketAddedNewItem = (payload, item) => {
	const parent = payload[item.parent_reference];

	const siblings = parent.children;

	let spliceIndex = siblings.length;

	const updatedState = update(payload, {
		[item.parent_reference]: {
			children: {
				$splice: [[spliceIndex, 0, item.id]],
			},
		},
		$merge: {
			[item.id]: item,
		},
	});

	return updatedState;
};

/**
 * Another connection changed position of an item - state helper
 *
 * @param {*} payload
 * @param {*} data
 */
export const socketChangePosition = (payload, data) => {
	const itemId = data.id;
	const parentAfterChange = data.parent_id
		? data.parent_id
		: data.navigation_id;

	const parentBeforeChange = payload[itemId].parent_reference;
	const positionBeforeChange = payload[parentBeforeChange].children.findIndex(
		(id) => id === itemId
	);
	const positionAfterChange = data.index;

	const updatedState = update(payload, {
		[parentBeforeChange]: {
			children: {
				$splice: [[positionBeforeChange, 1]],
			},
		},
		[parentAfterChange]: {
			children: {
				$splice:
					parentBeforeChange === parentAfterChange
						? [
								[positionBeforeChange, 1],
								[positionAfterChange, 0, itemId],
						  ]
						: [[positionAfterChange, 0, itemId]],
			},
		},
		[itemId]: {
			navigation_id: {
				$set: data.navigation_id,
			},
			parent_reference: {
				$set: parentAfterChange,
			},
			is_child: {
				$set: data.parent_id ? true : false,
			},
		},
	});

	return updatedState;
};

/**
 * Another connection changed name of an item through settings - state helper
 *
 * @param {object} payload
 * @param {object} updatedNavItem
 */
export const socketUpdatedNavItem = (payload, updatedNavItem) => {
	// Get original item as updatedNavItem does not contain all the data needed like the 'id'
	const originalNavItem = Object.values(payload).find(
		(item) => item.id === updatedNavItem.id
	);

	if (originalNavItem.title === updatedNavItem.name) return payload;

	// Generate updated state
	const updatedState = update(payload, {
		[originalNavItem.id]: {
			title: {
				$set: updatedNavItem.name,
			},
		},
	});

	return updatedState;
};

/**
 * Another connection deleted a navitem - state helper
 *
 * @param {*} payload
 * @param {*} data
 */
export const socketRemoveItem = (payload, data) => {
	const itemId = data.items[0];

	if (!payload[itemId]) return payload;

	const parent = payload[itemId].parent_reference;

	const itemIndex = payload[parent].children.findIndex(
		(item) => item === itemId
	);

	const updatedState = update(payload, {
		[parent]: {
			children: {
				$splice: [[itemIndex, 1]],
			},
		},
		$unset: [itemId],
	});

	return updatedState;
};
