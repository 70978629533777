import React from 'react';

const useDoubleClick = () => {
	const timer = React.useRef<ReturnType<typeof setTimeout>| null>(null);

	/**
     * Check if event is triggered by single or double click
     * 
     * @param {React.MouseEvent<HTMLElement>} ev
     * @returns {Promise<boolean>}
     */
	const clickedHandler = async (ev: React.MouseEvent<HTMLElement>): Promise<boolean> => {
		if(timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}
        
		return new Promise((resolve, reject) => {
			//Single
			if(ev.detail === 1) {
				timer.current = setTimeout(() => {
					resolve(false);
				}, 200);
				
				//Double
			} else if(ev.detail === 2) {
            	resolve(true);
			}

		});
	};

	return { clickedHandler };
};

export default useDoubleClick;