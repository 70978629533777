import React from 'react';
import ModuleContainer from '../../../../components/Content/ModuleContainer/ModuleContainer';

const Statistics: React.FC = () => {
	return (
		<ModuleContainer header="Statistik">
			<iframe
				title="Statistics"
				width="100%"
				height="700px"
				src="https://datastudio.google.com/embed/reporting/da3b2e3a-f69f-434f-925b-19ebd5f90da6/page/dUJsB"
				frameBorder="0"
				style={{ border: 0, height: 'calc(100vh - 190px)' }}
				allowFullScreen
			>
			</iframe>
		</ModuleContainer>
	);
};

export default Statistics;
