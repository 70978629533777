import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import styled from 'styled-components/macro';
import ProductDetails from './ProductDetails';
import axios from '../../../utils/oc-axios';
import Tree from '../../../components/Tree/Tree';
import SplitSlider from '../../../components/SplitSlider';
import {
	fetchAllCategoriesThunk,
	addCategoriesToProductsThunk,
	addCategoriesToProductThunk,
	setProductsAsReadyThunk,
	updateProductThunk,
	appendProductsThunk,
	setProductsThunk
} from '../../../store/thunks/thunk-products';
import {
	selectProduct,
	deselectProduct,
	selectProductAndCategories,
	selectProductDeselectCategories,
	setSelectedCategories,
	deselectAll,
	clearProduct,
	clearState
} from '../../../store/actions/action-products';
import { SkeletonTree } from '../../../components/Skeletons';
import useModal from '../../../hooks/Modal/useModal';
import { BasicContentModal, Icon } from '../../../components/UI';
import SmallList from '../../../components/UI/SmallList';
import useCommonProduct from '../hooks/useCommonProduct';
import useFormValidation from '../../../hooks/useFormValidation';
import ProductsWrapper from '../components/ProductsWrapper/ProductsWrapper';
import Header from '../components/ProductsWrapper/Header';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import { BADGE, BRAND, FILTER, SORT_ORDER, STATUS } from '../components/ProductsWrapper/constants';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';

const Products = (props) => {
	// modal is saving
	const [modalIsSaving, setModalIsSaving] = useState(false);

	// Holds the product brands received from backend
	const [brands, setBrands] = useState([]);
	
	// Notification used to inform user that product has no assigned category
	const notification = useAlert()[1];

	// used in details view
	const formValidation = useFormValidation();

	// extracted reference to the submit function instead of passing
	// the entire formValidation object
	// this results in a performance boost as the reference does not necessarily change on rerender
	const submitWithValidation = formValidation.submit;

	// fetches common product methods
	const [addToCategoryHelper, markProductsAsReadyHelper] = useCommonProduct();

	// keeps track of the loading state of the component
	const [itemManagerIsLoading, setItemManagerIsLoading] = useState(true);

	// reference keeps track whether to just append categories
	// or if categories could be removed from a product as well
	// if multiple products are selected then categories will only
	// be appended
	const appendCategoriesOnly = useRef(false);

	const requestedPages = useRef([]);

	// shows a modal displaying an error message
	// in case selected products have no categories assigned
	// when clicking "mark as ready"
	const errorModal = useModal();
	const errorModalAsComponent = errorModal.getAsComponent;
	const errorModalOpen = errorModal.open;

	// shows when clicking on the gear-option on a product
	// will trigger a modal showing product specific info
	const productDetailModal = useModal();
	const productDetailModalAsComponent = productDetailModal.getAsComponent;
	const productDetailModalOpen = productDetailModal.open;

	// Redux dispatcher
	const dispatch = useDispatch();

	// fetch stored categories for the tree
	const categories = useSelector(
		(state) => state.productsManagement.categories
	);

	// fetch products
	const products = useSelector((state) => {
		if(state.productsManagement.filteredProducts.length > 0)
			return state.productsManagement.filteredProducts;
		return state.productsManagement.products;
	});

	// fetch selections
	const selected = useSelector((state) => state.productsManagement.selected);

	// fetches header settings
	const headerSettings = useSelector(
		(state) => state.productsManagement.query
	);

	/**
	 * Fetch products by query
	 * 
	 * @param {React.SyntheticEvent<HTMLSelectElement>} ev
	 * @param {SelectProps}
	 * @returns {void}
	 */
	const fetchProductsByQuery = useCallback((ev, props) => {
		const value = ev.target.value;
		let updatedQueryValues;

		switch(props.id) {
			case SORT_ORDER:

				const [sort, order] = value.split(',');
		
				updatedQueryValues = {
					...headerSettings,
					sort,
					order
				};
				break;

			case FILTER:

				updatedQueryValues = {
					...headerSettings,
					filter: value
				};
				break;

			case BRAND:

				updatedQueryValues = {
					...headerSettings,
					brand: value === 'all' ? '' : value
				};
				break;
			
			case BADGE:

				updatedQueryValues = {
					...headerSettings,
					badge: value === 'all' ? '' : value
				};
				break;

			case STATUS:

				updatedQueryValues = {
					...headerSettings,
					status: value === 'all' ? '' : value
				};
				break;
		
			default:
				break;
		}
		//Remove selected products before reloading the products list
		if(selected.products.length > 0) {
			dispatch(deselectAll());
		}

		setItemManagerIsLoading(true);

		dispatch(setProductsThunk(updatedQueryValues)).then(() =>
			setItemManagerIsLoading(false));

		// clear the currently loaded pages
		requestedPages.current = [];
	}, [dispatch, headerSettings, selected.products]);

	/**
	 * Fetch 100 more products when scrolling down to the bottom of the window
	 * 
	 * @returns {void}
	 */
	const fetchProductsByPage = React.useCallback((nextPage = 1) => {
		// if the requested page was already loaded then kill further requests here
		if(requestedPages.current.includes(nextPage)) return;

		// add a requested page to reference list
		requestedPages.current.push(nextPage);

		dispatch(appendProductsThunk(nextPage, 100, headerSettings));
	}, [dispatch, headerSettings]);

	/**
	 * onMount
	 */
	useEffect(() => {
		// fetch categories
		dispatch(fetchAllCategoriesThunk()).catch((err) => {});

		// fetch initial products
		dispatch(setProductsThunk(null))
			.then(() => setItemManagerIsLoading(false))
			.catch((err) => {});

		return () => dispatch(clearState());
	}, [dispatch]);

	/**
	 * Fecth product brands on mount to be able to filter products by brand
	 */
	React.useEffect(() => {
		axios.get('/modules/products/brands')
			.then(res => {
				const brands = Object.values(res.data);
				setBrands(['all', ...brands]);
			})
			.catch(err => console.error('Det gick inte att hämta varumärken', err));
	}, []);

	/**
	 * Triggers a modal containing the edited product
	 *
	 * @param {object} item
	 */
	const showProductSettings = useCallback((item) => {
		const selectedProduct = products.find(
			(product) => product.id === item.id
		);

		// closure to spare code repetition for differnet save modes
		const saveProduct = (updatedState, closeModal, markAsReady = false) => {
			submitWithValidation(() => {
				setModalIsSaving(true);

				if(markAsReady) {
					updatedState = update(updatedState, {
						product: {
							settings: {
								ready: {
									$set: true
								}
							}
						}
					});
				}

				dispatch(updateProductThunk(updatedState))
					.then((resp) => {
						setModalIsSaving(false);
						closeModal();

						//Update the list of products, only when a product is marked as ready and not filtered by 'all'
						if(markAsReady && headerSettings.filter !== 'all') {
							setItemManagerIsLoading(true);
							
							dispatch(setProductsThunk(headerSettings)).then(() => {
						        	setItemManagerIsLoading(false);
							});
							
						}
						
					})
					.catch((resp) => {
						setModalIsSaving(false);
					});
			});
		};

		const buttons = [
			{
				text: 'Avbryt',
				isDefault: true,
				action: (originalState, currentState, closeModal) => {
					dispatch(clearProduct());
					closeModal();
				}
			},
			{
				text: 'Spara',
				action: (originalState, currentState, closeModal) => {
					saveProduct(currentState, closeModal, false);
				}
			}
		];

		if(!selectedProduct.settings.ready) {
			buttons.splice(1, 0, {
				text: 'Spara & Klarmarkera',
				action: (originalState, currentState, closeModal) => {
					const selectedCategories = currentState.product.categories;

					// Prevent user from marking product as ready if no category is assigned to the product
					if(selectedCategories.length === 0) {
						notification('SHOW', {
							priority: AlertPriorityTypes.error,
							title: 'Produkten saknar kategorier'
						});
						return;
					}
					saveProduct(currentState, closeModal, true);
				}
			});
		}

		productDetailModalOpen({
			width: '98%',
			height: '98%',
			position: 'center',
			style: 'overflow: hidden;',
			actions: buttons,
			state: {
				product: {
					...selectedProduct
				},
				categories: categories
			}
		});
	}, [categories, dispatch, headerSettings, notification, productDetailModalOpen, products, submitWithValidation]);

	/**
	 * Extracts a product number from modal state
	 */
	const getProductNumber = useCallback(() => {
		return productDetailModal.currentState
			? productDetailModal.currentState.product.number
			: null;
	}, [productDetailModal.currentState]);

	/**
	 * Clears a searched result set
	 */
	const clearSearchHandler = useCallback(() => {
		const updatedQueryValues = {
			...headerSettings,
			query: ''
		};

		setItemManagerIsLoading(true);

		dispatch(setProductsThunk(updatedQueryValues)).then(() =>
			setItemManagerIsLoading(false));
	}, [dispatch, headerSettings]);

	/**
	 * Performs a search after article number or name
	 * Will update the search result if > 0 results are found
	 *
	 * @param {Event} ev
	 */
	const searchHandler = useCallback((ev) => {
		if(ev.key === 'Enter') {
			const value = ev.target.value.trim();
			const updatedQueryValues = {
				...headerSettings,
				query: value
			};

			setItemManagerIsLoading(true);

			dispatch(setProductsThunk(updatedQueryValues)).then(() =>
				setItemManagerIsLoading(false));
		}
	}, [dispatch, headerSettings]);

	/**
	 * Handles the allocation of categories to products
	 */
	const setCategoriesHandler = useCallback(() => {
		const selectedProductIds = selected.products;
		const selectedCategoryIds = selected.categories;

		// this method is called we are adding multiple products to
		// one or more categories
		if(selected.products.length > 1) {
			dispatch(
				addCategoriesToProductsThunk(
					selectedProductIds,
					selectedCategoryIds
				)
			);
			return;
		}

		// if we are only working with one selected product
		// this is what we dispatch
		dispatch(
			addCategoriesToProductThunk(selectedProductIds, selectedCategoryIds)
		);
	}, [dispatch, selected.categories, selected.products]);

	/**
	 * Marks products as "ready"
	 * A requirement for this is that every selected product has categories assigned
	 */
	const markProductsAsReadyHandler = useCallback(() => {
		const {
			productsWithoutCategories,
			selectedProductIds
		} = markProductsAsReadyHelper(selected.products, products);

		// if no categories were assigned to a selected product
		// then show a modal containing this error
		if(productsWithoutCategories.length > 0) {
			errorModalOpen({
				position: 'center',
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							closeModal();
						}
					}
				],
				state: {
					errors: productsWithoutCategories,
					showMore: false
				}
			});

			return;
		}
        
		dispatch(setProductsAsReadyThunk(selectedProductIds)).finally(() => {
			setItemManagerIsLoading(true);
			//Update the list of products, if filter isn't set to view all products
			if(headerSettings.filter !== 'all') {
				dispatch(setProductsThunk(headerSettings)).then(() => {
					setItemManagerIsLoading(false);
				});
			} else {
				setItemManagerIsLoading(false);
			}
		});
		
	}, [dispatch, errorModalOpen, headerSettings, markProductsAsReadyHelper, products, selected.products]);

	/**
	 * Handles the checking of categories in tree
	 *
	 * @param {Event} ev
	 * @param {object} props
	 */
	const addToCategoryHandler = useCallback((ev, props) => {
		const categoryId = props.id;
		const updatedState = addToCategoryHelper(
			selected.categories,
			categories,
			categoryId
		);
		dispatch(setSelectedCategories(updatedState));
	}, [addToCategoryHelper, categories, dispatch, selected.categories]);

	/**
	 * Handles product selection
	 *
	 * @param {boolean} isSelected
	 * @param {int|string} itemId
	 * @param {object} suggestedState
	 */
	const selectProductHandler = useCallback(
		(isSelected, itemId, suggestedState) => {
			if(itemId === 'all') {
				return dispatch(deselectAll());
			}

			const productIndex = products.findIndex(
				(product) => product.id === itemId
			);

			const product = products[productIndex];

			// if the product was previously selected, then unselect it
			if(selected.products.includes(itemId)) {
				
				dispatch(deselectProduct(itemId));

				// predict the amount of selected products
				// and take appropriate action
				switch(selected.products.length - 1) {
					case 1:
						// find the remaining products id
						const remainingItemId = selected.products.find(
							(productId) => productId !== itemId
						);

						const indexOfRemainingItem = products.findIndex(
							(product) => product.id === remainingItemId
						);

						const productCategories =
							products[indexOfRemainingItem].categories;

						// switch the flag that indicates appending only to off
						appendCategoriesOnly.current = false;

						// update the state
						return dispatch(
							selectProductAndCategories(
								remainingItemId,
								productCategories
							)
						);

					default:
						return;
				}
				
			} else {
				// predict the amount of selected products
				// and take appropriate action
				switch(selected.products.length + 1) {
					case 1:
						const productCategories = product.categories;

						// if only one product is selected then
						// dispatch it's categories with it to display
						// in tree
						return dispatch(
							selectProductAndCategories(
								itemId,
								productCategories
							)
						);

					default:
						if(!appendCategoriesOnly.current) {
							// switch the flag that indicates appending only to on
							appendCategoriesOnly.current = true;
							return dispatch(
								selectProductDeselectCategories(itemId)
							);
						}

						return dispatch(selectProduct(itemId));
				}
			}
		},
		[dispatch, products, selected.products]
	);

	let errors = null;
	if(errorModal.currentState) {
		errors = errorModal.currentState.errors.map((productId) => {
			const productIndex = products.findIndex(
				(product) => product.id === productId
			);

			const product = products[productIndex];

			return (
				<div key={productIndex}>
					{product.name}
				</div>
			);
		});
	}

	// options for "Monday"-bar
	const generateSelectionBarOpts = useCallback(
		() => [
			{
				id: 'move',
				icon: ['fal', 'sitemap'],
				label: 'Tilldela',
				action: setCategoriesHandler
			},
			{
				id: 'finished',
				icon: ['fal', 'check'],
				label: 'Klarmarkera',
				action: markProductsAsReadyHandler
			}
		],
		[setCategoriesHandler, markProductsAsReadyHandler]
	);

	// generate action bar
	const generateProductAction = useCallback(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: showProductSettings
			}
		],
		[showProductSettings]
	);

	return (
		<ScContainer>
			{errorModalAsComponent(
				<BasicContentModal
					title="Det gick inte att klarmarkera"
					text="Följande produkter har ingen kategori tilldelade"
					showLessTitle="Visa mindre"
					showMoreTitle="Visa mer"
					enableCollapse
				>
					<SmallList>
						{errors}
					</SmallList>
				</BasicContentModal>
			)}

			{productDetailModalAsComponent(
				<ProductDetails
					isSaving={modalIsSaving}
					number={getProductNumber()}
					formValidation={formValidation}
				/>
			)}

			{useMemo(
				() => (
					<SplitSlider axis="horizontal">
						<ScSide
							key="side_A"
							size={30}
							minSize={20}
						>
							<ScHeader>
								<Sch2>
									Produkter
								</Sch2>
							</ScHeader>

							<Tree
								collapsePreviouslyExpanded={selected.categories.length < 1}
								scope="categories"
								payload={categories}
								checked={selected.categories}
								skeleton={<SkeletonTree />}
								preExpanded={['r6'].concat(selected.categories)}
								useCheckboxes={
									selected.products.length > 0
										? addToCategoryHandler
										: false
								}
								highlighted={
									selected.products.length > 1
										? getMergedSelectedItemsCategories(
											selected.products,
											products
										)
										: []
								}
								stackableIcons={{
									stacked: {
										collapsed: ['fal', 'tags'],
										expanded: ['fas', 'tags']
									},
									single: ['fal', 'tag']
								}}
							/>
						</ScSide>
						<ScMain
							size={70}
							minSize={50}
						>
							<ProductsWrapper
								header={(
									<Header
										searched={searchHandler}
										setHeaderValues={fetchProductsByQuery}
										clearedSearch={clearSearchHandler}
										brands={brands}
									/>
								)}
								headerSettings={headerSettings}
								additionalLayers={thumbnailAdditionalLayers()}
								items={products}
								isLoading={itemManagerIsLoading}
								selectedItems={selected.products}
								selectionEnabled={true}
								selectionBarButtons={generateSelectionBarOpts()}
								selectionChanged={selectProductHandler}
								nextPageCallback={fetchProductsByPage}
								sortingOptions={sortingOptions}
								selectionMaxAmount={100}
								itemActions={
									selected.products.length >= 1
										? []
										: generateProductAction()
								}
							/>
						</ScMain>
					</SplitSlider>
				),
				[addToCategoryHandler, brands, categories, clearSearchHandler, fetchProductsByPage, fetchProductsByQuery, generateProductAction, generateSelectionBarOpts, headerSettings, itemManagerIsLoading, products, searchHandler, selectProductHandler, selected.categories, selected.products]
			)}
		</ScContainer>
	);
};

export default withErrorBoundary(Products);

/**
 * Sorting options for the list/table view
 */
const sortingOptions = [
	{
		name: '',
		property: 'thumbnail',

		table: {
			render: (item, value) => (
				<img
					alt={item.original_name}
					src={value}
					style={{ width: '32px' }}
				/>
			)
		}
	},
	{
		name: 'Namn',
		property: 'name'
	},
	{
		name: 'Artikelnummer',
		property: 'number'
	}
];

/**
 * Provides Item Manager with an additional layer
 * This layer will show appropriate items depending on item state
 *
 * Use inside a function, otherwise React will yell with some error, you can't use JSX components outside a component.
 */
const thumbnailAdditionalLayers = () => ({
	isAllocated: (
		<ScStatusWrapper>
			<ScStatus>
				<Icon icon={['fal', 'sitemap']} />
			</ScStatus>
		</ScStatusWrapper>
	),
	isNotAllocated: (
		<ScStatusWrapper>
			<ScStatus>
				<Icon icon={['fal', 'inbox']} />
			</ScStatus>
		</ScStatusWrapper>
	)
});

/**
 * Will create a flat array of selected products' categories
 * and get rid of duplicates
 *
 * @param {object} selectedItems
 */
const getMergedSelectedItemsCategories = (selectedProducts, products) => {
	const categories = selectedProducts.reduce((prev, productId) => {
		const productIndex = products.findIndex(
			(product) => product.id === productId
		);

		prev.push(...products[productIndex].categories);
		return prev;
	}, []);

	return [...new Set(categories)];
};

const ScHeader = styled.div`
	padding: 16px;
	position: relative;
`;

const ScSide = styled.div`
	/* min-width: 308px; */
	height: 100%;
	overflow-y: auto;
`;

const ScMain = styled.div`
	background-color: #fff;
	/* padding: 16px; */
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
`;

const ScContainer = styled.section`
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	background-color: var(--bg-bright-color);
	width: 100%;
	/* height: 100%; */
	margin: 16px;
`;

const Sch2 = styled.h2`
	text-align: center;
	font-size: 24px;
	margin: 0 0 16px;
`;

export const ScStatusWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 8px;
	left: 8px;
`;

export const ScStatus = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--font-bright-color);
	background: rgba(102, 102, 102, 0.64);
	border-radius: 32px;
	flex-direction: column;
	z-index: 99;
`;
