import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import update from 'immutability-helper';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/oc-axios';
import { fetchAllNavigations } from '../store/thunks/thunk-cache';
import useModal from '../hooks/Modal/useModal';
import { Button, Icon, Message } from '../components/UI';
import Tree from '../components/Tree/Tree';
import SkeletonTree from '../components/Skeletons/SkeletonTree/SkeletonTree';
import useAuth from '../hooks/useAuth/useAuth';
import { NavigationPrivileges } from '../definitions/Privileges';

const TrashBin = () => {
	// This hook returns a function that lets you navigate programmatically
	const navigate = useNavigate();

	// redux dispatcher
	const dispatch = useDispatch();

	// User auth
	const { verifyUserPermission } = useAuth();
	const userCanEmptyTrashCan = verifyUserPermission(
		NavigationPrivileges.DELETE
	);

	const confirmModal = useModal();
	const openConfirmationModal = confirmModal.open;
	const getConfirmModalComponent = confirmModal.getAsComponent;

	const [treeItems, setTreeItems] = useState({});

	/**
	 * Handles when user confirm emptying the trash bin.
	 */
	const emptyTrashBinHandler = React.useCallback((originalState, currentState, closeModal) => {
		// make axios request to empty trash bin
		axios.delete('navigations/bin').then(() => {
			axios.get('navigations/trees/bin').then((resp) => {
				setTreeItems(resp.data);
				closeModal();
			});
		});
	}, []);

	/**
	 * Triggers when user clicks "empty trashbin" button, promp a confirmation modal.
	 */
	const emptyAllConfirmationHandler = React.useCallback((ev) => {
		ev.stopPropagation();

		if(!userCanEmptyTrashCan) return;

		const modalActions = [
			{
				text: 'Avbryt',
				isDefault: true,
				action: (originalState, currentState, closeModal) => {
					closeModal();
				}
			},
			{
				text: 'Töm allt',
				action: emptyTrashBinHandler
			}
		];

		openConfirmationModal({
			title: 'Säker du vill tömma allt?',
			actions: modalActions,
			position: 'center'
		});
	}, [emptyTrashBinHandler, openConfirmationModal, userCanEmptyTrashCan]);

	/**
	 * Removes a nav item from the state.
	 *
	 * @param {object} navItem The navItem to remove
	 */
	const removeNavItemFromState = React.useCallback((navItem) => {
		const parentId = navItem.parent_reference;
		const newState = update(treeItems, {
			// remove reference from parent
			[parentId]: {
				children: {
					$splice: [
						[
							treeItems[parentId].children.indexOf(
								navItem.id
							),
							1
						]
					]
				}
			},

			// remove the navItem
			$unset: [navItem.id]
		});

		setTreeItems(newState);
	}, [treeItems]);

	/**
	 *
	 * Restores a navItem from the trashbin.
	 *
	 * @param {*} ev
	 * @param {*} aa
	 * @param {*} navItem
	 */
	const restoreNavItemHandler = React.useCallback((ev, data, itemProps) => {
		axios
			.post(`/navigations/items/restore/${itemProps.item.id}`)
			.then(() => {
				dispatch(fetchAllNavigations(true)).then((navigation) => {
					removeNavItemFromState(itemProps.item);
				});
			});
	}, [dispatch, removeNavItemFromState]);

	/**
	 * Removes a navItem permanently.
	 *
	 * @param {*} ev
	 * @param {*} aa
	 * @param {*} navItem
	 */
	const removeNavItemHandler = React.useCallback((ev, data, itemProps) => {
		axios
			.delete(`/navigations/items/${itemProps.item.id}`)
			.then(() => removeNavItemFromState(itemProps.item));
	}, [removeNavItemFromState]);

	/**
	 * NavItems actions when hovering a navitem.
	 */
	const treeItemOpts = React.useMemo(() => [
		{
			icon: ['fal', 'arrow-rotate-left'],
			action: restoreNavItemHandler
		},
		{
			icon: ['fal', 'trash'],
			hasPermission: verifyUserPermission(
				NavigationPrivileges.DELETE
			),
			action: removeNavItemHandler
		}
	], [removeNavItemHandler, restoreNavItemHandler, verifyUserPermission]);

	/**
	 * Fetch tree items and store them in the state.
	 */
	React.useEffect(() => {
		axios.get('navigations/trees/bin').then((resp) => {
			setTreeItems(resp.data);
		});
	}, []);

	return (
		<ScContainer>
			{getConfirmModalComponent(
				<ScModalText>
					Detta kan inte ångras!
				</ScModalText>
			)}

			<ScTrashHead>
				{/* allt innehåll i <ScTrashHead> ska flyttas ut och läggas i den ClientHeader ytan där Webbplats m.m. är nu. */}

				<ScBack
					onClick={(ev) => {
						ev.stopPropagation();
						navigate(-1);
					}}
				>
					<Icon icon={['fal', 'chevron-left']} />
					<ScBackText>
						Tillbaka
					</ScBackText>
				</ScBack>

				<ScTitle>
					Papperskorg
				</ScTitle>

				<ScMessage size="16">
					Här kan du återuppta borttagna objekt och använda dem igen.
					Om du väljer att återställa något hamnar det i “Dolda
					sidor”. Först när du placerar ut objektet igen syns det på
					hemsidan
				</ScMessage>
				{/* till hit */}
			</ScTrashHead>

			<ScNavTreeWrapper>
				{Object.keys(treeItems).length === 0 ? (
					<SkeletonTree />
				) : (
					<Tree
						hideHeaders={true}
						scope="trashbin"
						payload={treeItems}
						enableDrag={false}
						opts={treeItemOpts}
						preExpanded={Object.keys(treeItems)}
					/>
				)}
			</ScNavTreeWrapper>
			<ScButtonContainer>
				<Button
					isDisabled={!userCanEmptyTrashCan}
					isPrimary
					onClick={emptyAllConfirmationHandler}
				>
					Töm papperskorgen
				</Button>
			</ScButtonContainer>
		</ScContainer>
	);
};

export default TrashBin;

const ScContainer = styled.div`
	flex: 1;
	/* background-color: var(--bg-bright-color); */
	overflow-x: hidden;
	overflow-y: auto;
	/* padding-bottom: 24px; */
`;

const ScTrashHead = styled.header`
	background: var(--clientHeader-bg-color);
	padding: 32px 16px;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
`;

const ScBack = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;
	cursor: pointer;
`;

const ScBackText = styled.div`
	margin-left: 8px;
`;

const ScTitle = styled.div`
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 8px;
`;

const ScMessage = styled(Message)`
	font-weight: 300;
	font-size: 16px;
`;

const ScNavTreeWrapper = styled.div`
	padding: 16px 0 115px;
`;

const ScButtonContainer = styled.div`
	padding: 32px 16px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-top: 2px solid var(--clientHeader-bg-color);
	background: var(--bg-bright-color);
`;

const ScModalText = styled.div`
	padding: 24px 16px;
	text-align: center;
	font-size: 16px;
`;
