import axios from '../../../utils/oc-axios';
import {
	setInitialSettings,
	setSpecificSettings,
	updateMultipleSettings
} from '../../actions/ControlPanel/action-settings';
import {
	SettingsList,
	Setting,
	UpdatedSettings,
	SettingsAction
} from '../../types/ControlPanel/SettingsTypes';
import { AjaxResponse, ThunkDispatch } from '../../types/ThunkTypes';

/**
 * Used to retrieve all settings
 *
 * @returns {ThunkDispatch<SettingsList>}
 */
export const fetchAllSettings = () => {
	return async (dispatch: ThunkDispatch<SettingsAction>) => {
		return new Promise<SettingsList>((resolve, reject) => {
			axios
				.get('modules/options')
				.then((resp: AjaxResponse<SettingsList>) => {
					const { status, data } = resp;

					if(status !== 200) reject();

					// populateMediaSettings(data).then((populatedTree) => {
					// 	dispatch(setInitialSettings(populatedTree));
					// 	resolve(populatedTree);
					// });

					dispatch(setInitialSettings(data));
					resolve(data);
				})
				.catch((err) => {
					console.error('[thunk-settings]', err);
					reject(err);
				});
		});
	};
};

/**
 * Used to fetch a specific setting
 *
 * @param {number} settingsId
 * @returns {ThunkDispatch<Setting>}
 */
export const fetchSetting = (settingsId: number) => {
	return async (dispatch: ThunkDispatch<SettingsAction>) => {
		return new Promise<Setting>((resolve, reject) => {
			axios
				.get(`modules/options/${settingsId}`)
				.then((resp: AjaxResponse<Setting>) => {
					const { status, data } = resp;

					if(status !== 200) reject();

					dispatch(setSpecificSettings(data));
					resolve(data);
				})
				.catch((err) => {
					console.error('[thunk-settings]', err);
					reject(err);
				});
		});
	};
};

/**
 * Used to update one or several settings
 *
 * @param {UpdatedSettings} payload
 * @returns {ThunkDispatch<SettingsList>}
 */
export const updateSettings = (payload: UpdatedSettings) => {
	return async (dispatch: ThunkDispatch<SettingsAction>) => {
		return new Promise<UpdatedSettings>((resolve, reject) => {
			axios
				.put('modules/options', payload)
				.then((resp) => {
					const { status } = resp;

					if(status !== 204)
						reject(
							new Error(
								`Got HTTP status code ${status}, expected 204, could not update Setting.`
							)
						);

					dispatch(updateMultipleSettings(payload));
					resolve(resp.data);
				})
				.catch((err) => {
					console.error('[thunk-settings]', err);
					reject(err);
				});
		});
	};
};

/**
 * Iterate all settings of typemedia and fetch the complete Media info from backend.
 */
// export const populateMediaSettings = (tree: SettingsList) =>
// 	new Promise<SettingsList>((resolve, reject) => {
// 		let tempTree: SettingsList = { ...tree };

// 		// iterate all entries and only return those that are root (is_root == true)

// 		for (const [key, setting] of Object.entries(tree)) {
// 			if (setting.type === SettingInputTypes.MEDIA) {
// 				// fetch data for media.
// 				axios
// 					.get(`media/files/${setting.value}`)
// 					.then((resp: AxiosResponse<MediaFile>) => {
// 						const data = resp.data;

// 						const updatedSetting = update(setting, {
// 							value: { $set: data },
// 						});

// 						// tempTree = update(tempTree, {
// 						// 	[key]: { $set: updatedSetting },
// 						// });

// 						console.debug(
// 							'aa',
// 							key,
// 							setting,
// 							updatedSetting,
// 							tempTree
// 						);
// 					});
// 			}
// 		}

// 		resolve(tempTree);
// 	});
