import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import update from 'immutability-helper';
import { sortHelper } from './ItemManagerUtils';
import DragDrop from './../../hoc/DragDrop/DragDrop';
import GridView from './Views/GridView';
import TableView from './Views/TableView';
import MultiSelectBar from '../MultiSelectBar/MultiSelectBar';
import { Icon } from '../UI';
import { Select } from '../Forms';
import Search from '../Search/Search';

/**
 * Map the available sorting options to a select/dropdown,
 * 	a select option will connect to a object's property using the 'criteria' prop
 *
 * Only need to change if props.sortingOptions change.
 */
const generateSortingOptions = (sortingOptions) => {
	const result = [];

	for(const option of sortingOptions) {
		const name = option.name;

		// The property may be only one (typeof string) or multiple (joining them, typeof object)
		const property =
			typeof option.property === 'object'
				? // Join multiple properties into one key
				  option.property.properties.join(':')
				: // Use the single property as key
				  option.property;

		// 2 options one for ASC and one for DESC
		result.push(
			<option
				key={`${property},asc`}
				value={`${property},asc`}
			>
				{`${name} (asc)`}
			</option>
		);

		result.push(
			<option
				key={`${property},desc`}
				value={`${property},desc`}
			>
				{`${name} (desc)`}
			</option>
		);
	}

	return result;
};

/**
 * @param {bool} isLoading						Defines if items or a skeleton should be shown.
 *
 * @param disabledView							One view to disable, grid or table, not both!
 *
 * @param headers								Components to show extra in the header.
 *
 * @param {array(object)} items   				An array containing all objects to list
 * @param {array(object)} itemActions			Array containing all actions to show when overing an item.
 * @param {array(string)} selectedItems			Array containing the id of all selected items.
 * @param {int} maxSelectionAmount				The maximum allowed amount of items to be selected at the same time.
 * @param gridItemComponent						The component to use for rendering the item in the GridView
 *
 * @param {array(object)} sortingOptions		The available sorting options to show, used to select which columns should be added to table.
 * @param {func} sortingChanged					Triggered when the sorting changed (newSorting, suggestedState(items)).
 *
 * @param {array(object)} selectionBarButtons	Array with buttons for the SelectionBar
 * @param {func} selectionChanged				Triggered when an item is selected or un-selected (isSelected, itemID, suggestedState)
 *
 * @param {function} searchHandler				A function to call every time the user search (behavior is not defined yet!, may be on key press or enter, etc...)
 */

const ItemManager = (props) => {
	const wrapperRef = useRef(null);
	//	ONLY ONE VIEW can by disabled,so not both! otherwise there is no view to show the data...

	// The default view as grid
	const [state, setState] = useState({
		activeView: props.disabledView === 'grid' ? 'table' : 'grid'
	});

	// run a valdiation on every items before continuing, it will throw exception when a validation fails.
	const validatedItems = useMemo(() => validateItems(props.items), [
		props.items
	]);

	// a list with all selected item's key
	//const [selectedItems, setSelectedItems] = useState([]);

	// Only enable functionality to disable selection if maxSelectionAmount !== 0
	// The selection of items is enabled as long as there are less or equal number of selected items as the maximum.
	const selectionEnabled =
		(props.maxSelectionAmount == null
			? true
			: props.selectedItems.length < props.maxSelectionAmount) &&
		!!props.selectionChanged;

	const [sortBy, setSortBy] = useState({
		// The item's property to sort by.
		criteria: 'name',

		// The direccion in what sorting is, asc or desc
		direction: 'asc',
		state: true
	});

	// Filtering
	// 		get the default filter with , otherwise the first filter (index 0).
	let defaultFilterIndex = props.filters
		? props.filters.findIndex((filter) => filter.default)
		: 0;
	defaultFilterIndex = defaultFilterIndex >= 0 ? defaultFilterIndex : 0;

	// stores the in use filter's index.
	const [filterBy, setFilterBy] = useState(defaultFilterIndex);

	// the current used icon size
	const [iconSize, setIconSize] = useState('regular');

	// true or false depending if the loading skeleton should be visible.
	const isLoading = props.isLoading;

	const viewChangingCallback = props.viewChangingCallback;

	/**
	 * Sets icon size for grid view
	 *
	 * @param {Event} ev		Event triggered by select when selecting a value.
	 */
	const setIconSizeHandler = (ev) => setIconSize(ev.target.value);

	/**
	 * Change the view between grid/table.
	 */
	const setViewHandler = useCallback(
		(view) => {
			// don't continue if the view is already active or ItemManager isLoading = true
			if(view === state.activeView || isLoading) return;

			if(viewChangingCallback) viewChangingCallback();

			const updatedState = update(state, {
				activeView: { $set: view }
			});
			setState(updatedState);
		},
		[isLoading, state, viewChangingCallback]
	);

	/**
	 * Sets a sort order of items.
	 *
	 * @param {array} sorting 		The sort option to sort by [criteria, direction]
	 */
	const sortHandler = useCallback(
		(sorting) => {
			// Only actually sort if we the callback is defined
			// Un-necessary to sort if we can't handle the result in the first place...
			if(props.sortingChanged) {
				//
				// what the next sorting direction is.
				// now asc = then next is desc
				// now desc = then next is asc
				const nextDireccion =
					sortBy.direction === 'asc' ? 'desc' : 'asc';

				// the direction to sort by, if not set, use the next direction.
				const direction = !!sorting[1] ? sorting[1] : nextDireccion;

				// If the prop/criteria is a joined one or simgle
				const isJoinedProperty = sorting[0].indexOf(':') !== -1;

				// If joined prop/criteria use the first one to sort on.
				// Only used for .sort(), we still store the joined criteria in state.
				const criteriaForSorting = isJoinedProperty
					? sorting[0].split(':')[0]
					: sorting[0];

				const updatedSortBy = update(sortBy, {
					criteria: { $set: sorting[0] },
					direction: { $set: direction }
				});

				// Sort a copy of items and not directly in validatedItems, they may come from a state.
				const suggestedState = [...validatedItems];

				suggestedState.sort(
					sortHelper(
						suggestedState,
						criteriaForSorting,
						updatedSortBy.direction
					)
				);

				setSortBy(updatedSortBy);

				// Trigger function so parent can update it's state or not :).
				props.sortingChanged(updatedSortBy, suggestedState);
			} else {
				console.warn(
					'(ItemManager) sortHandler',
					'Callback "props.sortingChanged" is not defined, the items may not be updated.'
				);
			}
		},
		[props, sortBy, validatedItems]
	);

	/**
	 *	Handles when a filter is selected.
	 */
	const filterHandler = useCallback((ev) => {
		ev.stopPropagation();

		// only to change the state is enough, the items will be filtered using the function of the fiter every render.

		const selectedIndex = ev.target.selectedIndex;

		setFilterBy(selectedIndex);
	}, []);

	/**
	 * Generate an select with all filters.
	 *
	 * @param {array} filters 		Array of filter objects.
	 */
	const generateFilters = useCallback(() => {
		// Push filters in array
		const filterComponents = props.filters.map((filter, index) => {
			// Show warning and filter out filter.
			if(!filter.action || typeof filter.action !== 'function') {
				console.warn(
					'ItemManager',
					`The filter ${filter.name} with index ${index} doesn't contains a valid action, must be a function.`
				);
				return null;
			}

			return (
				<option
					key={`filter_${index}`}
					value={index}
				>
					{`${filter.name}`}
				</option>
			);
		}, []);

		return (
			<Select
				changed={filterHandler}
				value={filterBy}
				isDisabled={isLoading}
			>
				{filterComponents}
			</Select>
		);
	}, [filterBy, filterHandler, isLoading, props.filters]);

	/**
	 *
	 * @param {string} itemID	The item's id that the event was triggered on.
	 */
	const itemSelectedHandler = useCallback(
		(itemID) => {
			if(!props.selectedItems.includes(itemID) && selectionEnabled) {
				// Item not selected, then add to array only is selectionEnabled = true
				const suggestedState = update(props.selectedItems, {
					$push: [itemID]
				});

				if(props.selectionChanged)
					props.selectionChanged(true, itemID, suggestedState);
			} else {
				// Only trigger un-selection if the item is selected.
				if(props.selectedItems.includes(itemID)) {
					// Item already selected, then remove it's id from array to un-select it
					const suggestedState = update(props.selectedItems, {
						$set: (arr) => arr.filter((id) => id !== itemID)
					});

					if(props.selectionChanged)
						props.selectionChanged(false, itemID, suggestedState);
				}
			}
		},
		[props, selectionEnabled]
	);

	/**
	 * Do filtering on the items.
	 */
	const filteredItems = useMemo(() => {
		let items = props.filters
			? validateItems(props.filters[filterBy].action())
			: validatedItems;

		if(props.itemsPerPage) items = createPages(items, props.itemsPerPage);

		return items;
	}, [filterBy, props.filters, validatedItems, props.itemsPerPage]);

	/**
	 * Updates the positions when an image is dropped on a horizontal position
	 *
	 * @param {object} source -> the dragged item
	 * @param {object} destination -> the hovered item displaying a valid drop zone
	 * @param {object} instruction -> additional instructions regarding the drop
	 */
	const dragEndHandler = (source, destination, instruction) => {
		if(!source || !destination) return;

		const sourceIndex = source.properties.index;
		const itemObject = validatedItems[source.properties.index];
		let destinationIndex = +destination.properties.index;

		switch(instruction.position) {
			case 'right':
				if(sourceIndex > destinationIndex)
					destinationIndex =
						destinationIndex + instruction.adjustIndex;
				break;

			default:
				if(sourceIndex < destinationIndex)
					destinationIndex = destinationIndex - 1;
		}

		let suggestedState = update(validatedItems, {
			$splice: [
				[sourceIndex, 1],
				[destinationIndex, 0, itemObject]
			]
		});

		if(destinationIndex === sourceIndex) return;

		if(props.selectedItems.length > 1) {
			const itemsToBeMoved = validatedItems.filter((item) => props.selectedItems.includes(item.id));

			const stateWithoutAffectedItems = update(validatedItems, {
				$apply: (items) =>
					items.filter(
						(item) => !props.selectedItems.includes(item.id)
					)
			});

			// As the elements have been removed the destinationIndex is likely to have changed
			// Thus, find the new index according to the destination product's id
			destinationIndex = stateWithoutAffectedItems.findIndex(item => item.id === destination.properties.id);

			// If the drop occurs of the right side of an element
			// Then adjust the index by + 1
			if(instruction.position === 'right') {
				destinationIndex = destinationIndex + 1;
			}

			suggestedState = update(stateWithoutAffectedItems, {
				$splice: [[destinationIndex, 0, ...itemsToBeMoved]]
			});
		}

		if(props.itemDragged) props.itemDragged(suggestedState);
	};

	/**
	 *
	 * Render
	 *
	 */
	return (
		<ScWrapper
			id="itemManagerWrapper"
			ref={wrapperRef}
			className={props.className}
		>
			{/* All options, view, icon size, sorting options, etc*/}
			<ScHeadersWrapper>
				<Header>
					{!props.disabledView && (
						<ScFilterItem>
							<ScToggleWrapper isDisabled={isLoading}>
								<ScToggleItem
									isActive={state.activeView === 'grid'}
									onClick={() => setViewHandler('grid')}
								>
									<Icon icon={['fal', 'border-all']} />
								</ScToggleItem>

								<ScToggleItem
									isActive={state.activeView === 'table'}
									onClick={() => setViewHandler('table')}
								>
									<Icon icon={['fal', 'bars']} />
								</ScToggleItem>
							</ScToggleWrapper>
						</ScFilterItem>
					)}
					{/* Difrent iamges sizes only available in gris view */}
					{state.activeView === 'grid' && (
						<ScFilterItem>
							<Select
								changed={setIconSizeHandler}
								value={iconSize}
								isDisabled={isLoading}
							>
								<option value="regular">
									Stora ikoner
								</option>
								<option value="small">
									Små ikoner
								</option>
							</Select>
						</ScFilterItem>
					)}
					{/* Show sorting options only if whe have items, in gridView & there are any sorting options */}
					{state.activeView === 'grid' &&
						props.sortingOptions &&
						validatedItems.length > 0 && (
							<ScFilterItem>
								<Select
									changed={(ev) =>
										sortHandler(ev.target.value.split(','))}
									value={`${sortBy.criteria},${sortBy.direction}`}
									isDisabled={isLoading}
								>
									{/* Map sortingOptions to components */}
									{generateSortingOptions(
										props.sortingOptions
									)}
								</Select>
							</ScFilterItem>
					)}
					{props.filters && (
						<ScFilterItem>
							{generateFilters()}
						</ScFilterItem>
					)}

					{/* Only show search-bar if a handler is defined. */}
					{!props.customedSearch && props.searched && (
						<ScFilterItem style={{ flex: 1 }}>
							<ScSearch
								searchPlaceholder={props.searchPlaceholder}
								pressed={props.searched}
								cleared={props.clearedSearch}
								isDisabled={isLoading}
							/>
						</ScFilterItem>
					)}

					{props.customSearch && !isLoading && (
						<ScFilterItem style={{ flex: 1 }}>
							{props.customSearch(isLoading)}
						</ScFilterItem>
					)}
				</Header>
				{props.headers}
			</ScHeadersWrapper>

			{/* Render  grid or table view depening on which is active */}
			<DragDrop
				scope="item-manager"
				dragPlaceholder={props.dragPlaceholder}
				onDragEnd={dragEndHandler}
			>
				{state.activeView === 'grid' ? (
					<GridView
                    // Items
						wrapperRef={wrapperRef}
						items={filteredItems}
						itemSize={iconSize}
						itemActions={props.itemActions}
						itemComponent={props.gridItemComponent}
                    // Item selection
						disabledItemsWithActionBar={
                        props.disabledItemsWithActionBar
                    }
						selectionEnabled={selectionEnabled}
						selectedItems={props.selectedItems}
						itemSelectedHandler={itemSelectedHandler}
						thumbnailExtraLayers={props.additionalLayers}
						isLoading={isLoading}
						doubleClicked={props.doubleClicked}
						// Dnd
						enableDnD={!!props.itemDragged}
						// Lazyloading
						nextPageCallback={props.nextPageCallback}
					/>
				) : (
					<TableView
						// Items
						items={filteredItems}
						itemActions={props.itemActions}
						// Item selection
						disabledItemsWithActionBar={
							props.disabledItemsWithActionBar
						}
						selectionEnabled={selectionEnabled}
						selectedItems={props.selectedItems}
						itemSelectedHandler={itemSelectedHandler}
						// Sorting
						// Send the original SortingOptions as it generate it owns buttons for sorting
						sortingOptions={props.sortingOptions}
						sortBy={sortBy}
						sortHandler={sortHandler}
						thumbnailExtraLayers={props.additionalLayers}
						isLoading={isLoading}
						// Lazyloading
						nextPageCallback={props.nextPageCallback}
					/>
				)}
			</DragDrop>
			{/* Only show selectbar if there is any selected item and there is actions for the selectbar*/}
			{props.selectionBarButtons && props.selectedItems.length > 0 && (
				<MultiSelectBar
					onClose={() => props.selectionChanged(false, 'all', [])}
					opts={props.selectionBarButtons}
					amount={props.selectedItems.length}
					isLoading={isLoading}
					// modal={moveFilesModal}
				/>
			)}
		</ScWrapper>
	);
};

ItemManager.propTypes = {
	isLoading: PropTypes.bool,
	disabledView: PropTypes.string,
	headers: PropTypes.array,
	items: PropTypes.array.isRequired,
	itemActions: PropTypes.array,
	doubleClicked: PropTypes.func,

	gridItemComponent: PropTypes.object,
	// Selection
	selectionBarButtons: PropTypes.array,
	selectionChanged: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	selectedItems: PropTypes.array.isRequired,
	maxSelectionAmount: PropTypes.number,
	// Sorting
	sortingOptions: PropTypes.array,
	sortingChanged: PropTypes.func,

	// Filters
	filters: PropTypes.array,

	// Search
	searched: PropTypes.func,
	customSearch: PropTypes.func,
	clearedSearch: PropTypes.func,

	// DnD
	itemDragged: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	itemsPerPage: PropTypes.number,
	nextPageCallback: PropTypes.func
};

export default ItemManager;

/**
 * Validates all items given to itemManager, for ex. they have required properties.
 */
const validateItems = (items) => {
	// properties that are required to be defined in every item.
	const requiredProps = ['id'];

	for(const item of items) {
		// check required properties exist in item.
		for(const prop of requiredProps) {
			if(!item.hasOwnProperty(prop) || !item[prop]) {
				console.error(
					`[ItemManager] - Item missing required property '${prop}'`,
					item
				);
				throw new Error(
					`[ItemManager] - An item is mising the required property '${prop}', see console for details`
				);
			}
		}
	}

	return items;
};

/**
 * Used to create a datastructure used for pages
 * [[item1, item2, item3], [item4, item5, item6]]
 * each array in the above expression represents a page
 *
 * @param {array} dataArray
 * @param {int} perPage
 */
const createPages = (dataArray, perPage) => {
	// create a new reference instead of mutating the original array
	const items = [...dataArray];
	const results = [];

	while(items.length) {
		results.push(items.splice(0, perPage));
	}

	return results;
};

const ScWrapper = styled.div``;

const ScHeadersWrapper = styled.div`
	margin: 16px 32px 0;
	/* position: sticky; */
	top: 16px;
	z-index: 999;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
`;

const ScFilterItem = styled.div`
	margin-top: 16px;
	margin-right: 16px;

	:last-child {
		margin-right: 0;
	}
`;

const ScToggleWrapper = styled.div`
	background: var(--bg-dark-grey-color);
	border-radius: 3px;
	overflow: hidden;
	display: flex;
	height: 40px;
	width: 80px;
	cursor: pointer;

	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;

const ScToggleItem = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: var(--font-bright-color);

	${(props) =>
		props.isActive &&
		css`
			background: var(--bg-dark-color);
		`}
`;

const ScSearch = styled(Search)`
	height: 40px;
`;
