import React, { SyntheticEvent } from 'react';
import styled from 'styled-components/macro';
import axios from './../../../../utils/oc-axios';
import { CustomerDetailsProps } from './model.CustomerDetails';
import { BASE_URL as AXIOS_BASE_URL } from '../../../../settings';
import { CustomerPrivileges } from '../../../../definitions/Privileges';
import { Customer } from '../../../../store/types/CustomerTypes';
import { Button, Loader } from '../../../../components/UI';
import withErrorBoundary from '../../../../hoc/withErrorBoundary';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import useAuth from '../../../../hooks/useAuth/useAuth';
import { AjaxResponse } from '../../../../store/types/ThunkTypes';
import ModalContainer from '../../../../components/UI/Modal/ModalContainer/ModalContainer';

const CustomerDetails: React.FC<CustomerDetailsProps> = (props) => {
	const customerId = props.modal?.currentState.id;
	const [customer, setCustomer] = React.useState<Customer>();

	// if the modal is loading data or not.
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	//If reset password is triggered
	const [
		isTriggeredPassReset,
		setIsTriggeredPassReset
	] = React.useState<boolean>(false);

	const notification = useAlert()[1] as AlertDispatch;

	const { verifyUserPermission } = useAuth();

	// create a pyramid download url
	const downloadURL = `${AXIOS_BASE_URL}modules/customers/download/${customer?.id}`;

	/**
	 * Trigger a password reset for the customer.
	 *
	 * @returns {void}
	 */
	const resetPasswordHandler = React.useCallback(() => {
		if(!customer) return;

		let alertId = notification('SHOW', {
			priority: AlertPriorityTypes.loading,
			title: 'Skickar återställningslänk',
			children: `Skickar en återställningslänk till ${customer.email}...`
		});

		axios
			.post(`modules/customers/reset/${customer.id}`)
			.then(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.success,
					title: 'Återställningslänken skickad',
					children: `Återställningslänk har blivit skickad till ${customer.email}`
				});

				setIsTriggeredPassReset(true);
			})

			.catch(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.error,
					title: 'Ett fel har uppstått',
					children: `Ett fel inträffade när återställningslänken skickades till ${customer.email}, prova igen`
				});
			});
	}, [notification, customer]);

	/**
	 * Used to retrieve a specific customer
	 *
	 * @returns {Promise<Customer>}
	 */
	const fetchCustomer = React.useCallback((customerId: number) => {
		return new Promise<Customer>((resolve, reject) => {
			axios
				.get(`modules/customers/${customerId}`)
				.then((resp: AjaxResponse<Customer>) => {
					const { status, data } = resp;
					if(status !== 200) reject();
					resolve(data);
				})
				.catch((err) => {
					console.error('[CustomerDetails]', err);
					reject(err);
				});
		});
	}, []);

	/**
	 * Fetch customer and set loading to false
	 */
	React.useEffect(() => {
		if(customerId) {
			fetchCustomer(customerId)
				.then((resp) => setCustomer(resp))
				.finally(() => setIsLoading(false));
		}
	}, [customerId, fetchCustomer]);

	return (
		<>
			{isLoading && <Loader />}
			<ModalContainer>
				<ScInfoWrapper>
					<div>
						{customer?.company && (
							<ScText>
								{customer?.company}
							</ScText>
						)}
						<ScText>
							{customer?.firstname} 
							{' '}
							{customer?.lastname}
						</ScText>
						{customer?.street && (
							<ScText>
								{customer?.street}
							</ScText>
						)}

						{customer?.postal_code && (
							<ScText>
								{customer?.postal_code} 
								{' '}
								{customer?.city}
								,
								{' '}
								{customer?.country.name}
							</ScText>
						)}

						{customer?.mobile_phone && (
							<ScText>
								{customer?.mobile_phone}
							</ScText>
						)}
						{customer?.email && (
							<ScText>
								{customer?.email}
							</ScText>
)}
						<ScSpacer>
							<ScText>
								Nyhetsbrev:
								{' '}
								{customer?.settings.email_advertisement
									? 'Ja'
									: 'Nej'}
							</ScText>
							<ScText>
								Katalog:
								{' '}
								{customer?.settings.catalog ? 'Ja' : 'Nej'}
							</ScText>
						</ScSpacer>
					</div>
					<div>
						{customer?.number && (
							<ScText>
								Pyramid kundnr:
								{' '}
								{customer?.number}
							</ScText>
						)}
						{customer?.id && (
							<ScText>
								C3 ID:
								{' '}
								{customer?.id}
							</ScText>
)}

						<ScSpacer>
							{customer?.settings.credit_limit && (
								<ScText>
									Kreditgräns:
									{' '}
									{customer?.settings.credit_limit}
								</ScText>
							)}

							{customer?.settings.excluded_payment_methods && (
								<>
									Otillåtna betalningssätt:
									<ul>
										{customer?.settings.excluded_payment_methods.map(
											(paymentMethod, key) => (
												<li key={key}>
													{paymentMethod}
												</li>
											)
										)}
									</ul>
								</>
							)}
						</ScSpacer>
					</div>
				</ScInfoWrapper>

				<ScControls>
					<Button
						isPrimary
						isSmall
						onClick={(ev: SyntheticEvent) => {
							ev.stopPropagation();
							window.location.href = downloadURL;
						}}
						buttonIcon={['fal', 'file']}
					>
						Ladda ner fil
					</Button>
					<Button
						isPrimary
						isSmall
						isDisabled={isTriggeredPassReset}
						onClick={resetPasswordHandler}
					>
						Skicka återställningslänk för lösenord
					</Button>
				</ScControls>
				{verifyUserPermission(CustomerPrivileges.DELETE) && (
					<ScRemoveUser
						onClick={() => props.openDeleteCustomer(customer)}
					>
						Ta bort kund
					</ScRemoveUser>
				)}
			</ModalContainer>
		</>
	);
};

export default withErrorBoundary(CustomerDetails);

const ScInfoWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const ScSpacer = styled.div`
	margin-top: 24px;
`;

const ScControls = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 104px;
	justify-content: space-between;
`;

const ScRemoveUser = styled.div`
	margin-top: 24px;
	font-size: 12px;
	text-decoration: underline;
	color: var(--red-color);
	text-transform: uppercase;
	white-space: nowrap;
	cursor: pointer;
`;

const ScText = styled.p`
	margin-bottom: 4px;
`;
