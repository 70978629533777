import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ButtonProps, ScButtonProps } from './model.Button';
import { Icon } from '..';

const Button: React.FC<ButtonProps> = (props) => {
	return (
		<ScContainer
			width={props.width}
			ref={props.innerRef}
			isFullWidth={props.isFullWidth}
			isPrimary={props.isPrimary}
			isDisabled={props.isDisabled}
			isXSmall={props.isXSmall}
			isSmall={props.isSmall}
			isLarge={props.isLarge}
			isRounded={props.isRounded}
			className={props.className}
			onClick={props.onClick}
			buttonIcon={props.buttonIcon}
			isAutoWidth={props.isAutoWidth}
			hasBorder={props.hasBorder}
			hasLargerFontSize={props.hasLargerFontSize}
		>
			{props.buttonIcon && (
				<Icon
					style={{ marginRight: 8 }}
					icon={props.buttonIcon}
				/>
			)}
			{props.children}
		</ScContainer>
	);
};

export default Button;

const ScContainer = styled.div<ScButtonProps>`
	position: relative;

	font-weight: 600;
	font-size: 12px;
	background: var(--btn-bg-color);

	width: ${props => props.width + 'px' ?? 'auto'};
	color: var(--btn-font-color);
	cursor: pointer;
	padding: 16px 24px;
	text-transform: uppercase;
	text-align: center;
	user-select: none;
	align-items: center;
	display: ${(props) => (props.isAutoWidth ? 'inline-block' : 'flex')};
	justify-content: center;

	${(props) =>
		props.isRounded &&
		css`
			border-radius: 3px;
		`}

	${(props) =>
		props.hasBorder &&
		css`
			border: 1px solid var(--border-grey-color);
		`}

	${(props) =>
		props.isPrimary &&
		css`
			background: var(--btn-primary-bg-color);
			color: var(--btn-primary-font-color);
			:hover {
				background-color: var(--btn-primary-bg-hover-color);
			}
		`}

	:hover {
		background-color: var(--btn-bg-hover-color);
		color: var(--btn-font-hover-color);
	}

	${(props) =>
		props.isXSmall &&
		css`
			padding: 4px 8px;
			text-transform: none;
			font-weight: 200;
			height: 24px;
			font-size: 10px;
		`}

	${(props) =>
		props.isSmall &&
		css`
			padding: 8px 16px;
			text-transform: none;
			font-weight: 200;
			height: 40px;
		`}

	${(props) =>
		props.isLarge &&
		css`
			height: 48px;
		`}

	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;

			:hover {
				background: var(--btn-bg-color);
				color: var(--btn-font-color);
			}
		`}
	
	${(props) =>
		props.hasLargerFontSize &&
		css`
			font-size: 14px;
		`}
`;
