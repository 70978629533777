import { Editor } from 'slate';

export const LINK = 'link';
export const HEADING_ONE = 'heading-one';
export const HEADING_TWO = 'heading-two';
export const HEADING_THREE = 'heading-three';
export const HEADING_FOUR = 'heading-four';
export const LIST_ITEM = 'list-item';
export const NUMBERED_LIST = 'numbered-list';
export const BULLETED_LIST = 'bulleted-list';
export const PARAGRAPH = 'paragraph';

export const STRONG = 'strong';
export const ITALIC = 'italic';
export const UNDERLINE = 'underline';

export const ALIGN_LEFT = 'left';
export const ALIGN_CENTER = 'center';
export const ALIGN_RIGHT = 'right';

// Extending Slate to it's own OC7Editor namespace
// Currently there's no need for that, but at least then it's prepared for
export const OC7Editor = {
	...Editor,
};
