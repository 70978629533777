import styled, { css } from 'styled-components/macro';
import { ScTableHeadingProps, ScSortIconProps } from './model.TableHeading';
import { Icon } from '../../UI';

export const ScTableHeading = styled.div<ScTableHeadingProps>`
	text-align: left;
	vertical-align: middle;
	white-space: nowrap;
	padding: 8px 16px 8px 0;
	font-size: 12px;
	font-weight: 600;
	display: table-cell;
	&:first-child {
		padding-left: 16px;
	}

	user-select: none;

	${(props) =>
		props.isSortable &&
		css`
			cursor: pointer;
		`}
`;

export const ScSortItem = styled.div`
	padding: 8px 0;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
`;

export const ScSortIcon = styled(Icon)<ScSortIconProps>`
	margin-left: 8px;
	bottom: 0px;
	position: relative;

	/* Fix for icons not being centered by Font-not-so-awesome*/
	${(props) =>
		props.sortOrder === 'desc' &&
		css`
			bottom: -6px;
		`}
`;
