import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from '../../../../utils/oc-axios';
import { TextInput } from '../../../../components/Forms';
import { SkeletonSettings } from '../../../../components/Skeletons';
import Button from '../../../../components/UI/Button/Button';
import { ModalSettingsContainer } from '../../../../components/UI';
import usePageLanguage from '../../../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES } from '../../../../settings';

/**
 * This component is intended to be used inside a modal.
 *
 * @param {object} modal	useModal related stuff like currentState, setState, etc...
 */
const FolderSettings = (props) => {
	const { currentState, updateState } = props.modal;

	// Returns the active page language
	const { activeLanguage } = usePageLanguage();

	const formValidation = props.formValidation;

	/**
	 * Handles when an input changes it's value.
	 *
	 * @param {Event} ev
	 */
	const inputChangedHandler = React.useCallback((ev) => {
		const property = ev.target.name;
		const newValue = ev.target.value;

		updateState({
			[property]: { $set: newValue },
			l10n: {
				[activeLanguage]: {
					[property]: { $set: newValue }
				}
			} 
		});
	}, [activeLanguage, updateState]);

	/**
	 * Fetch folder data, will remove loader when populated.
	 */
	React.useEffect(() => {
		if(!currentState.dataFetched)
			axios
				.get(`navigations/settings/folders/${currentState.uuid}`)
				.then(({ data }) => {
					updateState({
						$set: { ...data, dataFetched: true }
					});
				});
	}, [currentState, updateState]);

	return (
		<>
			{SUPPORTED_LANGUAGES.length > 0 && (
				<ScLanguage>
					{SUPPORTED_LANGUAGES.find(language => language.code === activeLanguage).name}
				</ScLanguage>
			)}

			{!currentState.dataFetched ? (
				<SkeletonSettings />
			) : (
				<ModalSettingsContainer>
					<TextInput
						label="Navigationstitel"
						description="Namnet på sidan när den visas i navigationen."
						isRequired
						name="title"
						id="title"
						value={currentState.title}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['title']}
						changed={(ev, ...data) => {
							formValidation.watch(ev, inputChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<TextInput
						label="Unik adress (URL)"
						description="Den unika adressen till sidan."
						isRequired
						name="slug"
						id="slug"
						value={currentState.slug}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['slug']}
						changed={(ev, ...data) => {
							formValidation.watch(ev, inputChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true,
								validation: 'slug'
							})}
					/>

					<Button
						onClick={async (ev) => {
							ev.stopPropagation();
							await props.duplicate(currentState);
							props.modal.close();
						}}
					>
						Duplicera mapp
					</Button>
				</ModalSettingsContainer>
			)}
		</>
	);
};

FolderSettings.propTypes = {
	modal: PropTypes.object,
	duplicate: PropTypes.func,
	formValidation: PropTypes.object
};

export default FolderSettings;

const ScLanguage = styled.p`
	position: absolute;
	z-index: 1000;
	top: 12px;
	right: 16px;
	font-size: 14px;
	font-weight: 300;
	color: grey;
`;