import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	categories: {},
	products: [],
	selected: {
		products: [],
		categories: []
	},
	filteredProducts: [],
	editingProduct: {},
	query: {
		sort: 'name',
		order: 'asc',
		filter: 'new'
	}
};

let updatedProducts;
let productIndex;

/**
 *
 * @param {object} state
 * @param {object} action
 */
const productsManagement = (state = initialState, action) => {
	switch(action.type) {
		case actionTypes.SET_CATEGORIES_TREE:
			return update(state, {
				categories: {
					$set: action.tree
				}
			});

		case actionTypes.SET_PRODUCTS:
			updatedProducts = action.products.map((product) => {
				return {
					...product,
					description: product.number,
					additionalLayers: addIconLayer(
						product.categories,
						product.settings.ready
					)
				};
			});

			return update(state, {
				products: {
					$set: updatedProducts
				},
				query: {
					$merge: action.query
				}
			});

		case actionTypes.APPEND_PRODUCTS:
			updatedProducts = action.products.map((product) => {
				return {
					...product,
					description: product.number,
					additionalLayers: addIconLayer(
						product.categories,
						product.settings.ready
					)
				};
			});

			return update(state, {
				products: {
					$push: updatedProducts
				}
			});

		case actionTypes.SET_PRODUCT:
			return update(state, {
				editingProduct: {
					$set: action.product
				}
			});

		case actionTypes.UPDATE_PRODUCT: {
			productIndex = state.products.findIndex(
				(product) => product.id === action.productId
			);

			const filteredProductIndex = state.filteredProducts.findIndex(
				(product) => product.id === action.productId
			);

			let updatedState = update(state, {
				products: {
					[productIndex]: {
						categories: {
							$set: action.categories
						},
						settings: {
							$set: action.settings
						},
						additionalLayers: {
							$set: addIconLayer(
								action.categories,
								action.settings
							)
						},
						thumbnail: {
							$set: action.thumbnail
								? action.thumbnail
								: state.products[productIndex].thumbnail
						}
					}
				},
				selected: {
					categories: {
						$set: [...action.categories]
					}
				},
				editingProduct: {
					$set: {}
				}
			});

			if(filteredProductIndex !== -1) {
				updatedState = update(updatedState, {
					filteredProducts: {
						[filteredProductIndex]: {
							thumbnail: {
								$set: action.thumbnail
									? action.thumbnail
									: state.products[productIndex].thumbnail
							}
						}
					}
				});
			}

			return updatedState;
		}

		case actionTypes.APPEND_PRODUCTS_CATEGORIES:
			return update(state, {
				products: {
					$apply: (products) =>
						products.map((product) => {
							if(action.products.includes(product.id)) {
								return {
									...product,
									additionalLayers: addIconLayer(
										action.categories,
										product.settings.ready
									),
									categories: [
										// get rid of duplicates
										...new Set([
											...product.categories,
											...action.categories
										])
									]
								};
							}

							return product;
						})
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});

		case actionTypes.SET_PRODUCT_CATEGORIES:
			productIndex = state.products.findIndex(
				(product) => product.id === action.productId
			);

			const productFilteredIndex = state.filteredProducts.findIndex(
				(product) => product.id === action.productId
			);

			updatedProducts = update(state, {
				products: {
					[productIndex]: {
						categories: {
							$set: action.categories
						},
						additionalLayers: {
							$set: addIconLayer(
								action.categories,
								state.products[productIndex].settings.ready
							)
						}
					}
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});

			if(productFilteredIndex !== -1) {
				updatedProducts = update(updatedProducts, {
					filteredProducts: {
						[productFilteredIndex]: {
							categories: {
								$set: action.categories
							}
						}
					}
				});
			}

			return updatedProducts;

		case actionTypes.SELECT_PRODUCT:
			return update(state, {
				selected: {
					products: {
						$push: [action.productId]
					}
				}
			});

		case actionTypes.DESELECT_PRODUCT:
			const deselectProductIndex = state.selected.products.findIndex(
				(productId) => productId === action.productId
			);

			return update(state, {
				selected: {
					products: {
						$splice: [[deselectProductIndex, 1]]
					},
					categories: {
						$set: []
					}
				}
			});

		case actionTypes.DESELECT_ALL:
			return update(state, {
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});

		case actionTypes.SELECT_PRODUCT_AND_CATEGORIES:
			return update(state, {
				selected: {
					products: {
						$set: [action.productId]
					},
					categories: {
						$set: [...action.categories]
					}
				}
			});

		case actionTypes.SELECT_PRODUCT_AND_DESELECT_CATEGORIES:
			return update(state, {
				selected: {
					products: {
						$push: [action.productId]
					},
					categories: {
						$set: []
					}
				}
			});

		case actionTypes.SELECT_CATEGORY: {
			return update(state, {
				selected: {
					categories: {
						$set: action.categories
					}
				}
			});
		}

		case actionTypes.MARK_PRODUCT_AS_READY: {
			return update(state, {
				products: {
					$apply: (products) =>
						products.map((product) => {
							if(action.productsIds.includes(product.id)) {
								return {
									...product,
									settings: {
										ready: true
									},
									additionalLayers: null
								};
							}
							return product;
						})
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});
		}

		case actionTypes.SET_FILTERED_PRODUCTS: {
			const filter = action.filtered.toLowerCase();

			const resultProducts = Object.values(action.products).map(
				(product) => ({
					id: product.id,
					name: product.name,
					number: product.number,
					thumbnail: product.thumbnail,
					categories: product.product.categories,
					settings: {
						...product.product.settings
					},
					description: product.number,
					additionalLayers: addIconLayer(
						product.product.categories,
						product.product.settings.ready
					)
				})
			);

			// Adds new products to the products array and gets rid of duplicates
			const updatedProducts = [
				...state.products,
				...Object.values(resultProducts)
			].filter(
				(product, index, self) =>
					self.findIndex((p) => p.id === product.id) === index
			);

			// Performs a filter on the new product array
			const filteredProducts = updatedProducts.filter(
				(product) =>
					product.name.toLowerCase().includes(filter) ||
					product.number.includes(filter)
			);

			return update(state, {
				products: {
					$set: updatedProducts
				},
				filteredProducts: {
					$set: filteredProducts
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});
		}

		case actionTypes.CLEAR_FILTERED_PRODUCTS: {
			return update(state, {
				filteredProducts: {
					$set: []
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				}
			});
		}

		case actionTypes.CLEAR_EDIT_PRODUCT: {
			return update(state, {
				editingProduct: {
					$set: {}
				}
			});
		}

		case actionTypes.CLEAR_PRODUCT_STATE: {
			return update(state, {
				categories: {
					$set: {}
				},
				products: {
					$set: []
				},
				selected: {
					products: {
						$set: []
					},
					categories: {
						$set: []
					}
				},
				filteredProducts: {
					$set: []
				},
				editingProduct: {
					$set: {}
				}
			});
		}

		default:
			return state;
	}
};

const addIconLayer = (categories, isReady) => {
	const result = [];
	if(isReady) return result;

	if(categories.length > 0) {
		result.push('isAllocated');
	} else {
		result.push('isNotAllocated');
	}

	return result;
};

export default productsManagement;
