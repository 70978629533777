import React from 'react';
import styled from 'styled-components/macro';
import { ModalSettingsContainerProps } from './model.ModalSettingsContainer';

const ModalSettingsContainer: React.FC<ModalSettingsContainerProps> = (
	props
) => {
	return (
		<ScContainer className={props.className}>
			{props.children}
		</ScContainer>
	);
};

export default ModalSettingsContainer;

const ScContainer = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;
