import axios from 'axios';
import { ErrorResponse } from './error.types';

/**
 * Helper function to extract server side errors
 * 
 * @param {unknown} error 
 * @returns {string}
 */
export const errorMessage = (error: unknown) => {
	if(axios.isAxiosError(error)) {
		const data = error.response?.data as ErrorResponse;
		if(data && data.message) return data.message as string;
	}

	return 'Sorry, an unknown server error have occured. Please try again later.';
};