import React from 'react';
import BlockForm from './BlockForm';
import { BlockFormItemLayout, BlockFormItemTypes, BlockFormType } from './BlockForm.types';
import BlockFormSettings from './Settings/BlockFormSettings';
import { BlockAction, BlockBaseStruct, BlockTypes, BuilderBlock } from '../../Block.types';
import { ModalAction, ModalConfig, ModalProps } from '../../../../hooks/useModal/useModal.model';
import { FormValidation } from '../../../../hooks/useFormValidation/types';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockFormType>>) => void,
	closeModal: () => void,
	changed: (blockState: BuilderBlock<BlockFormType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockFormType>) => void,
	formValidation: FormValidation
): Array<BlockAction> => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData: BuilderBlock<BlockFormType>) => {
				// define buttons for the modal with the block' settings
				const modalActions: ModalAction<BuilderBlock<BlockFormType>>[] = [
					{
						isDefault: true,
						isVisible: true,
						text: 'Avbryt',
						action: (
							originalState: BuilderBlock<BlockFormType>,
							currentState: BuilderBlock<BlockFormType>,
							closeModal: () => void
						) => {
							// Reset builder block settings
							formValidation.resetErrors();
							changed(blockData);
							closeModal();
						}
					},
					{
						isDefault: false,
						isVisible: true,
						text: 'Klar',
						action: (
							originalState: BuilderBlock<BlockFormType>,
							currentState: BuilderBlock<BlockFormType>,
							closeModal: () => void
						) => {
							// Only close the modal if the required forms are valid.
							formValidation.submit(() => {
								// Notify builder to update all block's atributes before closing the modal
								changed(currentState);
								closeModal();
							});
						}
					}
				];

				openModal({
					title: 'Redigerar Formulärblock',
					//@ts-ignore
					actions: modalActions,
					height: false,
					position: 'right',
					width: 'auto',
					hideBackdrop: true,
					isPositionable: true,
					isDismissable: false,
					isDraggable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockFormType>) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStructure: BlockBaseStruct<BlockFormType> = {
	baseStruct: {
		key: null,
		data: {
			uuid: null,
			name: 'Nytt formulär',
			button_text: 'Skicka',
			collective_message: null,
			type: BlockTypes.FORM,
			empty: true,
			show_name: false,
			items: {
				root: {
					key: 'root',
					uuid: null,
					name: null,
					unique_name: 'root',
					value: null,
					description: null,
					placeholder: null,
					minlength: null,
					maxlength: null,
					min: null,
					max: null,
					step: null,
					rows: null,
					size: null,
					layout: BlockFormItemLayout.VERTICAL,
					js_target: null,
					required_error: null,
					validation: null,
					pattern: null,
					type: BlockFormItemTypes.INPUT,
					input_type: null,
					children: [],
					accept: [],
					sort_order: 0,
					root: true,
					multiple: false,
					show_name: false,
					show_description: false,
					required: false,
					selected: false,
					disabled: false,
					readonly: false,
					submit: false,
					autofocus: false
				}
			},
			rulesets: {
				'email': {
					type_name: 'E-postkonfiguration',
					rules: [],
					results: {

						'1': { 
							uuid: null,
							form_item: '',
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							action: 'subject',
							value: null
						},

						'2': { 
							uuid: null,
							form_item: '',
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							action: 'reply-to',
							value: null
						}
					},
					uuid: null,
					active: true,
					trigger: 'all',
					unconditional: true,
					type: 'email'
				},
				'feedback': {
					type_name: 'Återkoppling',
					rules: [],
					results: {
						'1': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'message',
							value: ''
						},
						'2': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'subject',
							value: ''
						},
						'3': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'reply-to',
							value: null
						},
						'4': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'notice',
							value: null
						},
						'5': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'recipient',
							value: ''
						},
						'6': {
							form_item: null,
							link: {
								uuid: '',
								uri: '',
								title: '',
								new_window: false,
								navigation_id: null
							},
							uuid: null,
							action: 'redirect',
							value: ''
						}
					},
					uuid: null,
					active: true,
					trigger: 'all',
					unconditional: true,
					type: 'feedback'
				},
				root: {
					children: [
						'feedback',
						'email'
					]
				}
			},
			hasMultimedia: false
		}
		
	},
	builder: {
		component: <BlockForm />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockFormSettings
			modal={{} as ModalProps<BuilderBlock<BlockFormType>>}
			formValidation={{} as FormValidation}
		           />
	}
};

export default baseStructure;
