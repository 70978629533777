import React from 'react';
import * as ReactTable from 'react-table';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
	CUSTOMER_FILTERBY_ALL,
	CUSTOMER_FILTERBY_COMPANY,
	CUSTOMER_FILTERBY_PRIVATE,
	MODULE_CUSTOMERS_LIST_COLUMNS
} from './consts.Customers';
import { renderTableCellContent } from './func.Customers';
import { SortFilterState } from './types.Customers';
import ModuleContainer from '../../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../../components/Search/Search';
import { SkeletonTable } from '../../../../components/Skeletons';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeading,
	TablePagination,
	TableRow
} from '../../../../components/Table';
import ErrorBoundary from '../../../../hoc/ErrorBoundary/ErrorBoundary';
import withErrorBoundary from '../../../../hoc/withErrorBoundary';
import useModal from '../../../../hooks/Modal/useModal';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import { Customer, CustomerList } from '../../../../store/types/CustomerTypes';
import { Select } from '../../../../components/Forms';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import { BasicContentModal } from '../../../../components/UI';
import { TableHeadingProps } from '../../../../components/Table/TableHeading/model.TableHeading';
import {
	deleteCustomer,
	fetchAllCustomers
} from '../../../../store/thunks/thunk-customers';
import { RootState } from '../../../../store/types/RootTypes';
import PaginationInfo from '../../../../definitions/PaginationInfo';

const Customers: React.FC = (props) => {
	// Redux action dispatcher
	const dispatch = useDispatch<any>();

	// Handles the view loading state
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	// Stores the filtering and sorting settings
	const [sortFilter, setSortFilter] = React.useState<SortFilterState>({
		sort: 'firstname',
		order: 'asc',
		filter: 'all',
		query: ''
	});

	const customers = useSelector(
		(state: RootState) => state.customerManagement.list
	) as CustomerList;

	const deleteCustomerModal = useModal();

	const notification = useAlert()[1] as AlertDispatch;

	// information about how to paginate from the Redux state.
	const paginationInfo: PaginationInfo = useSelector((state: RootState) => {
		return state.customerManagement.paginationInfo;
	});

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		gotoPage,
		nextPage,
		previousPage,
		headerGroups,
		rows,
		page,
		pageCount,
		pageOptions,
		canPreviousPage,
		canNextPage,
		state: { pageIndex }
	} = ReactTable.useTable(
		{
			columns: MODULE_CUSTOMERS_LIST_COLUMNS,
			data: customers,
			initialState: { pageIndex: 0, pageSize: 100 },
			manualPagination: true,

			// Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: paginationInfo.pageCount
		},
		ReactTable.usePagination
	);

	const customerDetailsModal = useModal();

	/**
	 * Handles when changing what to filter after.
	 *
	 * @param {React.ChangeEvent<HTMLSelectElement>} ev
	 * @returns {void}
	 */
	const selectChangedHandler = React.useCallback(
		(ev: React.ChangeEvent<HTMLSelectElement>) => {
			const value = ev.currentTarget.value;

			setSortFilter((state) => ({
				...state,
				filter: value
			}));
			gotoPage(0);
		},
		[gotoPage]
	);

	/**
	 * Handles the text input value / clears search by text input value
	 *
	 * @param {React.MutableRefObject<HTMLInputElement> | undefined} input
	 * @returns {void}
	 */
	const searchFilterChangedHandler = React.useCallback(
		(input: React.MutableRefObject<HTMLInputElement> | undefined) => {
			let value = input?.current?.value;

			if(!value) value = '';

			setSortFilter((state) => ({
				...state,
				query: value as string
			}));
			gotoPage(0);
		},
		[gotoPage]
	);

	/**
	 * Handles when a table heading is clicked.
	 *
	 * @param {TableHeadingProps} properties
	 * @returns {void}
	 */
	const tableHeadingClickedHandler = React.useCallback(
		(properties: TableHeadingProps) => {
			setSortFilter((state) => {
				const isSameColumn = state.sort === properties.id;

				const updatedSortOrder = state.order === 'asc' ? 'desc' : 'asc';

				// If it's a differnet column clicked, then do not toggle the sortOrder
				const finalSortOrder = isSameColumn
					? updatedSortOrder
					: state.order;

				return {
					...state,
					sort: properties.id ?? '',
					order: finalSortOrder
				};
			});
		},
		[]
	);

	/**
	 * Open a modal with a customer's details.
	 *
	 * @param {Customer} customer
	 * @returns {void}
	 */
	const openCustomerDetails = React.useCallback(
		(customer: Customer) => {
			customerDetailsModal.open({
				title: customer.company
					? customer.company
					: `${customer.firstname} ${customer.lastname}`,
				width: '640px',
				isDismissable: 'true',
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (
							originalState: Customer,
							currentState: Customer,
							closeModal: () => void
						) => {
							closeModal();
						}
					}
				],
				state: customer
			});
		},
		[customerDetailsModal]
	);

	/**
	 * Open confirmation modal to delete selected customer
	 *
	 * @param {Customer} customer
	 * @returns {void}
	 */
	const openDeleteCustomer = React.useCallback(
		(customer: Customer) => {
			deleteCustomerModal.open({
				actions: [
					{
						text: 'Nej',
						isDefault: true,
						action: (
							_oState: null,
							_cState: null,
							closeModal: () => void
						) => {
							closeModal();
						}
					},
					{
						text: 'Ja',
						action: (
							_oState: null,
							_cState: null,
							closeModal: () => void
						) => {
							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Användare tas bort',
								children: `Tar bort ${customer?.firstname} ${customer?.lastname}...`
							});

							dispatch(deleteCustomer(customer?.id))
								.then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.info,
										title: 'Användare borttagen',
										children: `Användaren ${customer?.firstname} ${customer?.lastname} har nu bilvit borttagen.`
									});

									// close the details modal
									customerDetailsModal.close();

									// close the confirmation modal
									closeModal();
								})
								.catch(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.error,
										title: 'Ett eller flera fel hittades',
										children: `Det gick inte att ta bort användaren ${customer?.firstname} ${customer?.lastname}.`
									});
								});
						}
					}
				]
			});
		},
		[customerDetailsModal, deleteCustomerModal, dispatch, notification]
	);

	/**
	 * Fetch all customers and set loading to false
	 */
	React.useEffect(() => {
		setIsLoading(true);

		const finalFilter = {
			...sortFilter,
			page: pageIndex + 1
		};

		dispatch(fetchAllCustomers(finalFilter)).finally(() => {
			setIsLoading(false);
		});
	}, [dispatch, pageIndex, sortFilter]);

	return (
		<>
			{deleteCustomerModal.getAsComponent(
				<BasicContentModal
					title={'Du håller på att ta bort kunden'}
					text="Är du säker på att du vill ta bort?"
				/>
			)}
			{customerDetailsModal.getAsComponent(
				<CustomerDetails openDeleteCustomer={openDeleteCustomer} />
			)}
			<ScModuleContainer header="Kunder">
				<ErrorBoundary>
					<ScFilterContainer>
						<ScSelect
							label="Filtreringsområde"
							isDisabled={isLoading}
							changed={selectChangedHandler}
							value={sortFilter.filter}
						>
							<option value={CUSTOMER_FILTERBY_ALL}>
								Alla
							</option>
							<option value={CUSTOMER_FILTERBY_PRIVATE}>
								Privatkund
							</option>
							<option value={CUSTOMER_FILTERBY_COMPANY}>
								Företagskund
							</option>
						</ScSelect>
						<ScSearch
							searchPlaceholder="Sök på Namn/Företagsnamn, Kundnr, C3 ID, E-postadress, Address, Postnr, Postort eller Kontaktperson"
							hasButton={true}
							searchBtnClicked={searchFilterChangedHandler}
							cleared={searchFilterChangedHandler}
							enableSearchOnEnter
							isDisabled={isLoading}
						/>
					</ScFilterContainer>

					<TablePagination
						page={page}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={paginationInfo.totalResults}
						pageOptions={pageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						isDisabled={isLoading}
					/>

					<Table
						isTableFixed
						{...getTableProps()}
					>
						<TableHead>
							{headerGroups.map(
								(headerGroup: ReactTable.HeaderGroup) => (
									<div
										style={{ display: 'table-row' }}
										{...headerGroup.getHeaderGroupProps()}
									>
										{headerGroup.headers.map(
											(
												column: ReactTable.HeaderGroup
											) => {
												return (
													<TableHeading
														isSortable={true}
														isActive={
															sortFilter.sort ===
															column.id
														}
														sortOrder={
															sortFilter.order
														}
														clicked={
															tableHeadingClickedHandler
														}
														id={column.id}
														{...column.getHeaderProps()}
													>
														{column.render(
															'Header'
														)}
													</TableHeading>
												);
											}
										)}
									</div>
								)
							)}
						</TableHead>
						<TableBody {...getTableBodyProps()}>
							{rows.map((row: ReactTable.Row<any>) => {
								prepareRow(row);

								return (
									<TableRow {...row.getRowProps()}>
										{/* TODO replace any with type */}
										{row.cells.map(
											(cell: ReactTable.Cell<any>) => {
												return (
													<TableCell
														isSingleRow
														clicked={() =>
															openCustomerDetails(
																cell.row
																	.original
															)}
														{...cell.getCellProps()}
													>
														{renderTableCellContent(
															cell,
															row
														)}
													</TableCell>
												);
											}
										)}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>

					{isLoading && customers.length === 0 && (
						// Use our custom loading state to show a loading indicator
						<SkeletonTable />
					)}
					<TablePagination
						page={page}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={paginationInfo.totalResults}
						pageOptions={pageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						isDisabled={isLoading}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
					/>
				</ErrorBoundary>
			</ScModuleContainer>
		</>
	);
};

export default withErrorBoundary(Customers);

const ScModuleContainer = styled(ModuleContainer)`
	display: flex;
	flex-direction: column;
`;

const ScSearch = styled(Search)`
	margin-bottom: 16px;
`;

export const ScSelect = styled(Select)`
	margin-right: 8px;
`;

const ScFilterContainer = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 8px;
`;
