import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import BlockEmpty from '../../Builder/BlockTypes/BlockEmpty/BlockEmpty';
import { default as OldTableRow } from '../TableRow';
import { Icon } from '../../UI';
import { SkeletonTable } from '../../Skeletons';
import Page from '../../InfiniteScroll/Page';
import Table from '../Table/Table';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import TableHeading from '../Table/TableHeading';

/**
 * @param {array(object)} items           	An array of objects to render.
 * @param {array(object)} itemActions		Array containing all actions to show when overing an item.
 *
 * @param {int}	selectionEnabled			If the selection of items is enabled.
 * @param {array(string)} selectedItems		An array containing all selected file's key
 * @param {func} itemSelectedHandler		A function to call when an item is selected
 *
 * @param {array(object)} sortingOptions	The available sorting options to show, used to select which columns should be added to table.
 * @param {object} sortBy					The current sorting settings.
 * @param {func} sortHandler                Trigger a re-sort of items.
 *
 * @param {object} thumbnailExtraLayers		Object with extra "layers" for showing on top of the component.
 *
 * @param {bool} isLoading					Defines if items or a skeleton should be shown.
 */
const TableView = (props) => {
	// the current sorting options, like direction (asc or desc, etc)
	const sortBy = props.sortBy;

	// what property of an item is being sorted by
	const sortCriteria = sortBy.criteria;

	// is sorting ascending A-Z
	const isSortASC = sortBy.direction === 'asc';

	const itemSelectedHandler = props.itemSelectedHandler;

	/**
	 * Handle when an TableRow is clicked
	 *
	 * @param {Event} ev			The onClick event.
	 * @param {object} compProps	The React props of the element that triggered the event.
	 */
	const itemClickedHandler = useCallback(
		(compProps) => {
			// trigger function with only the item's id to select it.
			itemSelectedHandler(compProps.extraData.id);
		},
		[itemSelectedHandler]
	);

	/**
	 * Generate the table header based on the sorting options.
	 */
	const tableHeaders = props.sortingOptions.map((option) => {
		// The key to use for the component
		// The property may be only one (typeof string) or multiple (joining them, typeof object)
		const criteria =
			typeof option.property === 'object'
				? // Join multiple properties into one key
				  option.property.properties.join(':')
				: // Use the single property as key
				  option.property;

		const isCriteriaInUse = sortCriteria === criteria;

		// the arrow icon to show, pointing up or down depending on the sorting direction in use.
		const icon = isCriteriaInUse
			? isSortASC
				? ['fas', 'sort-up']
				: ['fas', 'sort-down']
			: ['fal', 'sort-up'];

		return (
			<TableHeading key={`tableHeader_${criteria}`}>
				<ScSortItem
					onClick={() =>
						// sending null in direction, will use next alternative.
						props.sortHandler([criteria, null])}
				>
					{option.name}
					<ScSortIcon
						icon={icon}
						sortingASC={isSortASC}
						isCriteriaInUse={isCriteriaInUse}
					/>
				</ScSortItem>
			</TableHeading>
		);
	});

	/**
	 * Convert array with items (as objects) to Components.
	 *
	 * Only re-calculate if something changed.
	 */
	const renderItems = useCallback(
		(itemsInPage = null) => {
			const itemsReference = itemsInPage ? itemsInPage : props.items;

			return itemsReference.map((item, index) => {
				// array of the components to render as additional layers
				const additionalLayers = [];

				if(
					props.thumbnailExtraLayers &&
					props.thumbnailExtraLayers.length > 0
				) {
					// populate the additionalLayers array with the components.
					for(const [layerName, layer] of Object.entries(
						props.thumbnailExtraLayers
					)) {
						const _l = React.cloneElement(layer, {
							key: `layer_${layerName}`
						});

						if(
							item.additionalLayers &&
							item.additionalLayers.includes(layerName)
						)
							additionalLayers.push(_l);
					}
				}

				return (
					<OldTableRow
						index={index}
						key={`item_${item.id}`}
						thumbnail={item.thumbnail}
						extraData={item}
						actions={props.itemActions}
						clicked={itemClickedHandler}
						isSelected={props.selectedItems.includes(item.id)}
						// Grey-out if selection is disabled and item is not selected, so only selected are with color
						isDisabled={
							!!!props.itemSelectedHandler &&
							!props.selectedItems.includes(item.id)
						}
						selectionEnabled={props.selectionEnabled}
						sortingOptions={props.sortingOptions}
					>
						{additionalLayers}
					</OldTableRow>
				);
			});
		},
		[
			itemClickedHandler,
			props.itemActions,
			props.itemSelectedHandler,
			props.items,
			props.selectedItems,
			props.selectionEnabled,
			props.sortingOptions,
			props.thumbnailExtraLayers
		]
	);

	const renderPages = () => {
		return props.items.map((page, index) => {
			return (
				<Page
					key={`page-${index}`}
					id={`page-${index}`}
					number={index + 1}
					items={renderItems(page)}
					amountPages={props.items.length}
					nextPageCallback={props.nextPageCallback}
					display="table-row-group"
				/>
			);
		});
	};

	// component/skeleton to show when loading
	const loadingComp = <SkeletonTable />;

	// component to show when props.items is empty
	const emptyComp = (
		<BlockEmpty
			style={{ background: 'transparent' }}
			icon={['fal', 'ghost']}
			title="Här var det tomt..."
			description=""
		/>
	);

	// component to show with all items
	const tableComp = (
		<Table>
			<TableHead>
				<TableRow>
					{props.selectionEnabled && (
						<TableHeading style={{ width: 40 }} />
					)}
					{tableHeaders}
					{/* Used by actions, show the extra column only if there are options to skip unused space */}
					{props.itemActions && <TableHeading />}
				</TableRow>
			</TableHead>
			{hasPages(props.items) ? renderPages() : renderItems()}
		</Table>
	);

	let out = loadingComp;
	switch(true) {
		case props.items.length > 0:
			out = tableComp;
			break;

		default:
			out = emptyComp;
	}

	return (
		<ScView>
			{props.isLoading ? loadingComp : out}
		</ScView>
	);
};

TableView.propTypes = {
	items: PropTypes.array,
	itemActions: PropTypes.array,

	// Selection
	selectedItems: PropTypes.array,
	itemSelectedHandler: PropTypes.func,
	// selectionEnabled: PropTypes.bool.isRequired,

	sortingOptions: PropTypes.array,
	sortBy: PropTypes.object,
	sortHandler: PropTypes.func,

	thumbnailExtraLayers: PropTypes.object,

	isLoading: PropTypes.bool
};

export default TableView;

/**
 * Will determine if the item manager currently uses a "page" setup
 * If it is a page setup, all the items in the items array are array wrappers
 * thus we can safely assume that if the first element is an array, then we are
 * in fact going to render pages
 *
 * @param {array} items
 */
const hasPages = (items) => {
	return Array.isArray(items[0]);
};

const ScView = styled.div`
	align-content: flex-start;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	overflow-y: auto;
	padding: 32px 32px 80px;
`;

const ScSortItem = styled.div`
	padding: 8px 0;
	font-size: 14px;
	font-weight: 600;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
`;

const ScSortIcon = styled(Icon)`
	margin-left: 8px;
	bottom: -4px;
	position: relative;

	/* Fix for icons not being centered by Font-not-so-awesome*/
	${(props) =>
		!props.sortingASC &&
		props.isCriteriaInUse &&
		css`
			bottom: 3px;
		`}
`;
