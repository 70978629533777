import React from 'react';
import PropTypes from 'prop-types';

import { BasicContentModal } from '../../../components/UI';
import SmallList from '../../../components/UI/SmallList';

/**
 *
 * @param {object} modal		Modal injected when inside a modal.
 */
const ConflictModal = (props) => {
	// the modal state sent by useModal
	const { currentState } = props.modal;

	// Map texts to components
	const errorsTexts = Object.values(currentState).map((error) => (
		<>{error}</>
	));

	return (
		<BasicContentModal
			title={props.title}
			text={props.text}
			showMoreTitle={props.showMoreText}
			showLessTitle={props.showLessText}
			enableCollapse
		>
			<SmallList>{errorsTexts}</SmallList>
		</BasicContentModal>
	);
};

ConflictModal.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,

	showMoreText: PropTypes.string.isRequired,
	showLessText: PropTypes.string.isRequired,
};

export default ConflictModal;
