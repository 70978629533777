import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonTreeProps } from './model.SkeletonTree';

const SkeletonTree: React.FC<SkeletonTreeProps> = (props) => {
	return (
		<ContentLoader
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={'250'}
			viewBox={'0 0 320 250'}
		>
			<rect
				x="16"
				y="21"
				width="156"
				height="24"
				rx="4"
			/>
			<rect
				x="48"
				y="188"
				width="166"
				height="16"
				rx="4"
			/>
			<rect
				x="16"
				y="184"
				width="24"
				height="24"
				rx="12"
			/>
			<rect
				x="48"
				y="229"
				width="110"
				height="16"
				rx="4"
			/>
			<rect
				x="16"
				y="225"
				width="24"
				height="24"
				rx="12"
			/>
			<rect
				x="48"
				y="65"
				width="150"
				height="16"
				rx="4"
			/>
			<rect
				x="16"
				y="61"
				width="24"
				height="24"
				rx="12"
			/>
			<rect
				x="48"
				y="106"
				width="192"
				height="16"
				rx="4"
			/>
			<rect
				x="16"
				y="102"
				width="24"
				height="24"
				rx="12"
			/>
			<rect
				x="48"
				y="147"
				width="118"
				height="16"
				rx="4"
			/>
			<rect
				x="16"
				y="143"
				width="24"
				height="24"
				rx="12"
			/>
		</ContentLoader>
	);
};

export default SkeletonTree;
