import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { TextInput, Textarea } from '../../../components/Forms';
import { Button, Icon, ModalSettingsContainer } from '../../../components/UI';
import { BASE_URL as AXIOS_BASE_URL } from '../../../settings';

/**
 *
 * @param {object(useModal)} modal 		An object with the useModals data lie currentState, etc...
 */
const FolderSettings = (props) => {
	// the useModal data...
	const { currentState, updateState } = props.modal;

	// decontruct state
	const { uuid, title, description } = currentState;

	// create a download url
	const downloadURL = `${AXIOS_BASE_URL}media/downloads/folder/${uuid}`;

	/**
	 * Handles when an input changes it value.
	 */
	const changedHandler = (ev, b, c) =>
		updateState({
			[ev.target.name]: { $set: ev.target.value }
		});

	return (
		<ModalSettingsContainer>
			<ScButtonContainer>
				<ScButton
					onClick={(ev) => {
						ev.stopPropagation();
						window.location.href = downloadURL;
					}}
					isPrimary
					isSmall
				>
					<ScIcon icon={['fal', 'cloud-arrow-down']} />
					Ladda ner
				</ScButton>
			</ScButtonContainer>

			<TextInput
				label="Namn"
				name="title"
				placeholder=""
				value={title}
				changed={changedHandler}
				isRequired
			/>
			<Textarea
				label="Beskrivning"
				name="description"
				placeholder="Beskrivning..."
				value={description ?? ''}
				changed={changedHandler}
				resizeVertical
			/>
		</ModalSettingsContainer>
	);
};

FolderSettings.propTypes = {
	moda: PropTypes.object
};

export default FolderSettings;

const ScIcon = styled(Icon)`
	margin-right: 8px;
`;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;
