import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

const Action = (props) => {
	return (
		<ScContaienr onClick={props.clicked} index={props.index}>
			{props.children}
		</ScContaienr>
	);
};

export default Action;

Action.propTypes = {
	clicked: PropTypes.func,
	index: PropTypes.number,
};

const ScContaienr = styled.div`
	flex-grow: 1;
	background: var(--action-bg-color);
	border-right: 1px solid var(--border-grey-color);

	cursor: pointer;
	padding: 16px;

	text-align: center;
	user-select: none;
	transition: background 0.3s ease, color 0.3s ease;
	:last-child {
		border-right: 0;
	}

	:hover {
		background-color: var(--action-bg-hover-color);
	}

	${(props) =>
		props.icon &&
		css`
			flex-grow: 0;
		`}
`;
