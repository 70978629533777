/**
 * Strings
 *
 */
// Add support for counting and extracting lines of a string
// https://stackoverflow.com/questions/2035910/how-to-get-the-number-of-lines-in-a-textarea
// eslint-disable-next-line no-extend-native
String.prototype.lines = function() {
	return this.split(/\r*\n/);
};

// eslint-disable-next-line no-extend-native
String.prototype.lineCount = function() {
	return this.lines().length;
};

//
// eslint-disable-next-line no-extend-native
String.prototype.htmlTags = function() {
	return this.split(/(?=<[a-zA-Z]+>[^<]*?<\/[a-zA-Z]+>)/);
};
