import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import usePageLanguage from '../../hooks/usePageLanguage/usePageLanguage';

const LanguageUpdater: React.FC = () => {
	const { lang } = useParams();
	const { changeLanguage } = usePageLanguage();

	/**
     * Update the language state based on the language parameter
     */
	React.useEffect(() => {
		if(lang) changeLanguage(lang);
	}, [lang, changeLanguage]);

	return <Outlet />;
};

export default LanguageUpdater;