import { ControlPanelMenuDefinition, CustomerMenuOptions } from './ControlPanelMenuDefinitions.types';
import { BASE_URL, CUSTOMER_CONTROLPANEL_DEFINITIONS } from '../../settings';

const getFlag = (flag: CustomerMenuOptions) => {
	// Docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators#Flags_and_bitmasks

	return CUSTOMER_CONTROLPANEL_DEFINITIONS & flag;
};

const ControlPanelCustomerDefinitions: Record<string, ControlPanelMenuDefinition> = {};

// Customer specified definitions

// --- Infotool
if(getFlag(CustomerMenuOptions.INFOTOOL_FORMS_DOWNLOAD)) {
	ControlPanelCustomerDefinitions.infotool_form_contact_download = {
		name: 'Formulär Kontakt',
		icon: ['fal', 'download'],
		permissions: [],
		link: {
			internal: false,
			to: `${BASE_URL}forms/infotool/contact`,
			target: '_blank'
		}
	};

	ControlPanelCustomerDefinitions.infotool_form_newsletter_download = {
		name: 'Formulär Nyhetsbrev',
		icon: ['fal', 'download'],
		permissions: [],
		link: {
			internal: false,
			to: `${BASE_URL}forms/infotool/newsletter`,
			target: '_blank'
		}
	};
}

export default ControlPanelCustomerDefinitions;
