import styled from 'styled-components/macro';
import { Select } from '../../../../components/Forms';
import Search from '../../../../components/Search/Search';
import { Button } from '../../../../components/UI';

export const ScFilterContainer = styled.div`
	display: flex;
	margin-top: 16px;

	.rdt {
		margin-left: 8px;
		:first-child {
			margin-left: 0;
		}

		&Picker {
			position: absolute;
		}
	}
`;

export const ScSelect = styled(Select)`
	margin-left: 8px;

	:first-child {
		margin-left: 0;
	}
`;

export const ScSearch = styled(Search)`
	margin-bottom: 16px;
	/* margin-left: 8px; */
`;

export const ScShowingResults = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
`;

export const ScFailure = styled.span`
	color: #d0021b;
`;

export const ScClickable = styled.span`
	font-weight: bold;
	cursor: pointer;
`;

export const ScButton = styled(Button)`
	margin-left: 8px;
    height: 40px;
    align-self: flex-end;
    margin-bottom: 16px;
`;
