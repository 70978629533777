import React from 'react';
import { TableBodyProps } from './model.TableBody';
import { ScTableBody } from './style.TableBody';

const TableBody: React.FC<TableBodyProps> = (props) => (
	<ScTableBody>
		{props.children}
	</ScTableBody>
);

export default TableBody;
