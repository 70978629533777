import React from 'react';

const CustomInput = (props) => {
	const attributes = fixAttributes(props);
	switch (true) {
		case props.offspring.length > 0:
			return (
				<select {...attributes} defaultValue="">
					{props.offspring.map((child, index) => {
						return (
							<option key={`option-${index}`}>
								{child.props.children}
							</option>
						);
					})}
				</select>
			);

		default:
			return <input {...attributes} />;
	}
};

const aliases = {
	class: 'className',
	value: 'defaultValue',
	checked: 'defaultChecked',
};

const fixAttributes = (props) => {
	return Object.keys(props).reduce((prev, currentProp) => {
		const prop = aliases[currentProp] || currentProp;
		const currentValue = props[currentProp];

		if (currentValue) prev[prop] = currentValue;
		return prev;
	}, {});
};

export default CustomInput;
