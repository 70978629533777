import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { BADGE, BRAND, FILTER, GRID, SORT_ORDER, STATUS } from './constants';
import Search from '../../../../components/Search/Search';
import { Select } from '../../../../components/Forms';
import Label from '../../../../components/Forms/Label/Label';
// import { Icon } from '../../../../components/UI';

const Header = (props) => {
	return (
		<ScWrapper>
			{/* <ScFilterItem>
				<ScToggleWrapper isDisabled={props.isLoading}>
					<ScToggleItem
						isActive={props.activeView === GRID}
						onClick={() => props.setViewHandler(GRID)}
					>
						<Icon icon={['fal', 'border-all']} />
					</ScToggleItem>

					<ScToggleItem
						isActive={props.activeView === TABLE}
						onClick={() => props.setViewHandler(TABLE)}
					>
						<Icon icon={['fal', 'bars']} />
					</ScToggleItem>
				</ScToggleWrapper>
			</ScFilterItem> */}

			{props.activeView === GRID && (
				<ScFilterItem>
					<Select
						changed={(ev) =>
							props.setThumbnailSize(ev.target.value)}
						value={props.thumbnailSize}
						isDisabled={props.isLoading}
						label="Ikoner"
					>
						<option value="regular">
							Stora ikoner
						</option>
						<option value="small">
							Små ikoner
						</option>
					</Select>
				</ScFilterItem>
			)}

			<ScFilterItem>
				<Select
					id={SORT_ORDER}
					changed={props.setHeaderValues}
					value={`${props.headerSettings.sort},${props.headerSettings.order}`}
					isDisabled={props.isLoading}
					label="Ordning"
				>
					<option value={'name,asc'}>
						Namn (asc)
					</option>
					<option value={'name,desc'}>
						Namn (desc)
					</option>
					<option value={'number,asc'}>
						Artikelnummer (asc)
					</option>
					<option value={'number,desc'}>
						Artikelnummer (desc)
					</option>
				</Select>
			</ScFilterItem>

			<ScFilterItem>
				<Select
					id={FILTER}
					changed={props.setHeaderValues}
					value={`${props.headerSettings.filter}`}
					isDisabled={props.isLoading}
					label="Tilldelade/klarmarkerade"
				>
					<option value="all">
						Alla produkter
					</option>
					<option value="notAssigned">
						Ej tilldelade
					</option>
					<option value="new">
						Ej klarmarkerade
					</option>
				</Select>
			</ScFilterItem>

			{props.brands && props.brands.length > 0 && (
				<ScFilterItem>
					<Select
						id={BRAND}
						changed={props.setHeaderValues}
						value={props.headerSettings.brand}
						isDisabled={props.isLoading}
						label="Varumärke"
					>
						{props.brands.map(brand => (
							<option
								key={brand}
								value={brand}
							>
								{brand === 'all' ? 'Alla varumärken' : brand}
							</option>
						))}
					</Select>
				</ScFilterItem>
			)}

			<ScFilterItem>
				<Select
					id={BADGE}
					changed={props.setHeaderValues}
					value={props.headerSettings.badge}
					isDisabled={props.isLoading}
					label="Rea/Nyheter"
				>
					<option value="all">
						Alla produkter
					</option>
					<option value="sale">
						Rea
					</option>
					<option value="new">
						Nyheter
					</option>
				</Select>
			</ScFilterItem>

			<ScFilterItem>
				<Select
					id={STATUS}
					changed={props.setHeaderValues}
					value={props.headerSettings.status}
					isDisabled={props.isLoading}
					label="Status"
				>
					<option value="all">
						Alla produkter
					</option>
					<option value="enable">
						Köpbara
					</option>
					<option value="show">
						Synliga
					</option>
					<option value="disable">
						Inaktiverade
					</option>
				</Select>
			</ScFilterItem>

			<ScFilterItem style={{ flex: 1 }}>
				<Label label="Sök">
					<ScSearch
						searchPlaceholder={props.searchPlaceholder}
						pressed={props.searched}
						cleared={props.clearedSearch}
						isDisabled={props.isLoading}
					/>
				</Label>
			</ScFilterItem>
		</ScWrapper>
	);
};

export default Header;

Header.propTypes = {
	activeView: PropTypes.string,
	setThumbnailSize: PropTypes.func,
	thumbnailSize: PropTypes.string,
	isLoading: PropTypes.bool,
	setHeaderValues: PropTypes.func,
	headerSettings: PropTypes.object,
	searchPlaceholder: PropTypes.string,
	searched: PropTypes.func,
	clearedSearch: PropTypes.func,
	brands: PropTypes.array
};

export const ScWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
`;

const ScFilterItem = styled.div`
	margin-top: 16px;
	margin-right: 16px;

	:last-child {
		margin-right: 0;
	}
`;

// const ScToggleWrapper = styled.div`
// 	background: var(--bg-dark-grey-color);
// 	border-radius: 3px;
// 	overflow: hidden;
// 	display: flex;
// 	height: 40px;
// 	width: 80px;
// 	cursor: pointer;

// 	${(props) =>
// 		props.isDisabled &&
// 		css`
// 			opacity: 0.5;
// 			cursor: not-allowed;
// 		`}
// `;

// const ScToggleItem = styled.div`
// 	display: flex;
// 	flex: 1;
// 	justify-content: center;
// 	align-items: center;
// 	flex-direction: column;
// 	color: var(--font-bright-color);

// 	${(props) =>
// 		props.isActive &&
// 		css`
// 			background: var(--bg-dark-color);
// 		`}
// `;

const ScSearch = styled(Search)`
	height: 40px;
`;
