import React from 'react';
import { BlockGiftCardType } from './BlockGiftCard.types';
import BlockGiftCard from './BlockGiftCard';
import BlockGiftCardSettings from './Settings/BlockGiftCardSettings';
import { BlockAction, BlockBaseStruct, BlockTypes, BuilderBlock } from '../../../../../components/Builder/Block.types';
import { FormValidation } from '../../../../../hooks/useFormValidation/types';
import { ModalAction, ModalConfig, ModalProps } from '../../../../../hooks/useModal/useModal.model';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockGiftCardType>>) => void,
	closeModal: () => void,
	changed: (blockState: BuilderBlock<BlockGiftCardType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockGiftCardType>) => void,
	formValidation: FormValidation
): Array<BlockAction> => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData: BuilderBlock<BlockGiftCardType>) => {
				// define buttons for the modal with the block' settings
				const modalActions: ModalAction<BuilderBlock<BlockGiftCardType>>[] = [
					{
						isDefault: true,
						isVisible: true,
						text: 'Avbryt',
						action: (
							originalState: BuilderBlock<BlockGiftCardType>,
							currentState: BuilderBlock<BlockGiftCardType>,
							closeModal: () => void
						) => {
							// Reset builder block settings
							formValidation.resetErrors();
							changed(blockData);
							closeModal();
						}
					},
					{
						isDefault: false,
						isVisible: true,
						text: 'Klar',
						action: (
							originalState: BuilderBlock<BlockGiftCardType>,
							currentState: BuilderBlock<BlockGiftCardType>,
							closeModal: () => void
						) => {
							// Only close the modal if the required forms are valid.
							formValidation.submit(() => {
								// Notify builder to update all block's atributes before closing the modal
								changed(currentState);
								closeModal();
							});
						}
					}
				];

				openModal({
					title: 'Redigerar Formulärblock',
					//@ts-ignore
					actions: modalActions,
					height: false,
					position: 'right',
					width: 'auto',
					hideBackdrop: true,
					isPositionable: true,
					isDismissable: false,
					isDraggable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockGiftCardType>) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStructure: BlockBaseStruct<BlockGiftCardType> = {
	isAddable: true,
	baseStruct: {
		key: null,
		data: {
			uuid: null,
			name: 'Presenkort',
			type: BlockTypes.GIFTCARD,
			title: 'Kontrollera ditt saldo',
			description: 'Har du ett presentkort redan och vill se dess värde.',
			placeholder: 'Ange ditt presentkortsnummer',
			button_text: 'Kontrollera saldo'
		}
	},
	builder: {
		component: <BlockGiftCard />,
		setOpts: generateOpts
	},
	settings: {
		component: (
			<BlockGiftCardSettings
				modal={{} as ModalProps<BuilderBlock<BlockGiftCardType>>}
				formValidation={{} as FormValidation}
			/>
		)
	}
};

export default baseStructure;
