/*
    Warn and Error can keep their default styling
*/

const styles = {
	debug: 'background: #004675; color: #70c6ff',
	log: 'background: #222; color: #bada55',
	info: 'background: #222; color: #bada55',
	warn: 'background: #755400; color: #ffac70',
	error: 'background: #750000; color: #ff7070',

	xhr: 'background: #2b003d; color: #e099ff',
};

const dateToString = (date) => {
	//return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()} @ ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

	const hour = ('0' + date.getHours()).slice(-2);
	const minute = ('0' + date.getMinutes()).slice(-2);
	const seconds = ('0' + date.getSeconds()).slice(-2);
	return `[${hour}:${minute}:${seconds}]`;
};

const isBlackListed = (message) => {
	const items = /componentWillMount|componentDidMount|componentWillReceiveProps/;
	return items.test(message);
};

/**
 * Override the vanilla console with a custom supporting colors :D
 *
 */
const console = ((vanillaConsole) => {
	return {
		spacer: () => {
			vanillaConsole.debug();
		},
		axios_error: (file, error) => {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				vanillaConsole.debug(
					`%c${dateToString(
						new Date()
					)} [Axios XHR] <= ${error.response.config.method.toUpperCase()} %c${
						error.response.status
					}%c ${error.response.config.url}`,
					styles.xhr,
					'background: #750000; color: #ff7070',
					styles.xhr
				);
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				vanillaConsole.debug(
					`%c${dateToString(
						new Date()
					)} [Axios XHR] ${file} <= %cERROR%c ${
						error.config.url
					} ${error}`,
					styles.xhr,
					'background: #750000; color: #ff7070',
					styles.xhr
				);
			} else {
				// Something happened in setting up the request that triggered an Error
				vanillaConsole.debug(
					`%c${dateToString(
						new Date()
					)} [Axios XHR] <= %cUNKNOWN ERROR`,
					styles.xhr,
					'background: #750000; color: #ff7070',
					styles.xhr
				);
			}
		},
		debug: (...args) => {
			vanillaConsole.debug(
				`%c${dateToString(new Date())} [Debug]`,
				styles.debug,
				...args
			);
		},
		log: (...args) => {
			vanillaConsole.log(
				`%c${dateToString(new Date())} [Log]  `,
				styles.log,
				...args
			);
		},
		info: (...args) => {
			vanillaConsole.info(
				`%c${dateToString(new Date())} [Info]  `,
				styles.info,
				...args
			);
		},
		warn: (...args) => {
			if (isBlackListed(args[0])) return;

			vanillaConsole.warn(
				`%c${dateToString(new Date())} [Warn] `,
				styles.warn,
				...args
			);
		},
		error: (...args) => {
			if (/(Invalid prop|Failed prop type)/.test(args)) {
				throw new Error(args);
			}

			vanillaConsole.error(
				`%c${dateToString(new Date())} [Error]`,
				styles.error,
				...args
			);
		},
	};
})(window.console);
window.console = console;

export default console;
