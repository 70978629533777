import React from 'react';
import {
	BULLETED_LIST,
	HEADING_FOUR,
	HEADING_ONE,
	HEADING_THREE,
	HEADING_TWO,
	LIST_ITEM,
	NUMBERED_LIST,
	LINK
} from '../../../OC7Editor';
import { serializeAlignmentsJSX } from '../AlignmentButton/alignments-api';

const Element = ({ attributes, children, element }) => {
	switch(element.type) {
		case HEADING_ONE:
			return (
				<h1
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</h1>
			);
		case HEADING_TWO:
			return (
				<h2
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</h2>
			);

		case HEADING_THREE:
			return (
				<h3
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</h3>
			);

		case HEADING_FOUR:
			return (
				<h4
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</h4>
			);

		case LIST_ITEM:
			return (
				<li {...attributes}>
					{children}
				</li>
			);

		case BULLETED_LIST:
			return (
				<ul
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</ul>
			);

		case NUMBERED_LIST:
			return (
				<ol
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</ol>
			);

		case LINK:
			let href = element.href;

			switch(true) {
				case element.uri_type === 'email' && !href.includes('mailto'):
					href = `mailto: ${href}`;
					break;

				case element.uri_type === 'phone' && !href.includes('tel'):
					href = `tel: ${href}`;
					break;

				default:
			}

			return (
				<a
					{...attributes}
					data-uri_type={element.uri_type}
					title={href}
					href={href}
					className={element.button_class}
				>
					{children}
				</a>
			);
		default:
			return (
				<p
					{...attributes}
					{...serializeAlignmentsJSX(element)}
				>
					{children}
				</p>
			);
	}
};

export default Element;
