import { OC7Editor } from '../../../OC7Editor';

/**
 * Will add/remove mark for block and/or text selection
 *
 * @param {Editor} editor
 * @param {string} format
 */
export const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		OC7Editor.removeMark(editor, format);
	} else {
		OC7Editor.addMark(editor, format, true);
	}
};

/**
 * Will determine whether a mark is currently in use
 *
 * @param {Editor} editor
 * @param {string} format
 */
export const isMarkActive = (editor, format) => {
	const marks = OC7Editor.marks(editor);
	return marks ? marks[format] === true : false;
};
