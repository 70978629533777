import update from 'immutability-helper';
import { createRandomId, isEmptyObject, isNumber } from 'react-utils';
import { getDefaultBlockData, calcRowScopeAfterChange } from './BuilderUtils';

/**
 * **********************************************
 * This file exists to handle block specific logic
 * In order to keep those advanced tasks as simple as possible
 * I decided to outsource each operation it's own function
 * That way it will be easier, and safer, to tweak or make changes to this logic when necessary
 *
 * Using immutability-helper to factor the new state
 * https://github.com/kolodny/immutability-helper
 * **********************************************
 */

/**
 * Adds a new row set to an area which has one or more row sets previously
 * The row sets contains the creation of:
 * - row
 * - column
 * - specified block type
 *
 * @param state Object - the state of the builder
 * @param area  String - the id of the area where the row set should be added
 * @param index Integer - the position within the parent area of the row that trigged this operation (by clicking add block)
 * @param type String - the type of object that should be added
 * @returns {{elements: {blocks: {$merge: {}}, columns: {$merge: {}}, rows: {$merge: {}}, areas: {}}} | boolean}
 */
export const createRowSetToAreaHelper = (state, areaId, index, type) => {
	if (isEmptyObject(state) || !areaId || !isNumber(index) || !type) {
		throw new Error('Unsatisfying params provided to [createRowSetToArea]');
	}

	// creates a new row id for internal reference
	const rowId = createRandomId('row');

	// creates a new column id for internal reference
	const columnId = createRandomId('col');

	// creates a new block id for internal reference
	const blockId = createRandomId(type);

	// fetches structure of block object of corresponding type
	const blockData = getDefaultBlockData(type, blockId);

	if (!blockData) {
		console.warn(`No default block with type ${type} was found`);
		return false;
	}

	return update(state, {
		elements: {
			// adds the new block object to the states' "blocks"
			blocks: {
				$merge: blockData,
			},
			// creates a new column with the reference id and adds the newly
			// created block as a child of it's blocks array
			columns: {
				$merge: {
					[columnId]: {
						blocks: [blockId],
						key: columnId,
					},
				},
			},
			// creates a new row with the reference id and adds the newly
			// created column reference as a child to it's column's array
			rows: {
				$merge: {
					[rowId]: {
						columns: [columnId],
						key: rowId,
						settings: {
							column_division: null,
							visible_mobile: true,
							visible_desktop: true,
						},
						updated: Date.now(),
					},
				},
			},
			areas: {
				// adds the newly created row as a child to the area's rows' array
				// the splice operation indicates that it's added after row that triggered the operation
				[areaId]: {
					rows: {
						$splice: [[index + 1, 0, rowId]],
					},
				},
			},
		},
	});
};

/**
 * Will create a new row set and add to the specified empty area
 * The row sets contains the creation of:
 * - row
 * - column
 * - specified block type
 *
 * @param state Object
 * @param area String
 * @param type String - the type of object that should be added (e.g. row, column, etc)
 *
 * @returns {{elements: {blocks: {$merge: {}}, columns: {$merge: {}}, rows: {$merge: {}}, areas: {$merge: {}}}} | boolean}
 */
export const createRowSetToEmptyAreaHelper = (state, areaId, type) => {
	if (isEmptyObject(state) || !areaId || !type) {
		throw new Error(
			'Unsatisfying params provided to [createRowSetToEmptyArea]'
		);
	}

	// creates a new row id for internal reference
	const rowId = createRandomId('row');

	// creates a new column id for internal reference
	const columnId = createRandomId('col');

	// creates a new block id for internal reference
	const blockId = createRandomId(type);

	// fetches structure of block object of corresponding type
	const blockData = getDefaultBlockData(type, blockId);

	if (!blockData) {
		console.warn(`No default block with type ${type} was found`);
		return false;
	}

	let immutabilityMethod = '$merge';

	if (Array.isArray(state.elements.rows)) {
		immutabilityMethod = '$set';
	}

	return update(state, {
		elements: {
			// adds the new block object to the states' "blocks"
			blocks: {
				[immutabilityMethod]: blockData,
			},
			// creates a new column with the reference id and adds the newly
			// created block as a child of it's blocks array
			columns: {
				[immutabilityMethod]: {
					[columnId]: {
						blocks: [blockId],
						key: columnId,
					},
				},
			},
			// creates a new row with the reference id and adds the newly
			// created column reference as a child to it's column's array
			rows: {
				[immutabilityMethod]: {
					[rowId]: {
						columns: [columnId],
						key: rowId,
						settings: {
							column_division: null,
							visible_mobile: true,
							visible_desktop: true,
						},
						updated: Date.now(),
					},
				},
			},
			// adds the newly created row to the specified empty area's rows array
			areas: {
				[areaId]: {
					$set: {
						rows: [rowId],
						key: areaId,
						placement: 1,
						settings: [],
					},
				},
			},
		},
	});
};

/**
 * Adds the specified block type to it's appropriate position (under the element that triggered the operation)
 * In it's parent column
 *
 * @param state Object - the state of the builder
 * @param column String - the id of the column where the block should be added
 * @param index Integer - the position within the parent column of the block that triggered this operation (by clicking add block)
 * @param type String - the type of object that should be added
 *
 * @returns {{elements: {columns: {}, blocks: {$merge: {}}}} | boolean}
 */
export const createBlockInColumnHelper = (
	state,
	rowId,
	columnId,
	index,
	type
) => {
	if (
		isEmptyObject(state) ||
		!rowId ||
		!columnId ||
		!isNumber(index) ||
		!type
	) {
		throw new Error(
			'Unsatisfying params provided to [createBlockInColumn]'
		);
	}

	// creates a new block id for internal reference
	const blockId = createRandomId(type);

	// fetches structure of block object of corresponding type
	const blockData = getDefaultBlockData(type, blockId);

	if (!blockData) {
		console.warn(`No default block with type ${type} was found`);
		return false;
	}

	return update(state, {
		elements: {
			blocks: {
				$merge: blockData,
			},
			columns: {
				[columnId]: {
					blocks: {
						$splice: [[index + 1, 0, blockId]],
					},
				},
			},
			rows: {
				[rowId]: {
					$merge: {
						updated: Date.now(),
					},
				},
			},
		},
	});
};

/**
 * Will delete a specified block of any type
 * If the blocks is the last in it's column, the column will also be removed
 * If the removed column is the last in the row, the column will also be removed
 * If the row no longer holds any columns, the row will also be removed
 *
 * @param state
 * @param area
 * @param row
 * @param column
 * @param block
 * @param index
 * @returns {{elements: {blocks: {$unset: *[]}, columns: {$unset: *[]}, rows: {$unset: *[]}, areas: {}}}}
 */
export const deleteSpecificBlockHelper = (
	state,
	areaId,
	rowId,
	columnId,
	blockId,
	index
) => {
	if (
		isEmptyObject(state) ||
		!areaId ||
		!rowId ||
		!columnId ||
		!blockId ||
		!isNumber(index)
	) {
		throw new Error(
			'Unsatisfying params provided to [deleteSpecificBlock]'
		);
	}

	// fetch instruction whether source column and source row should be kept
	const instruction = calcRowScopeAfterChange(state, areaId, rowId, columnId);

	return update(state, {
		elements: {
			// remove the block
			blocks: {
				$unset: [blockId],
			},
			columns: {
				// remove the block from columns children
				[columnId]: {
					blocks: {
						$splice: [[index, 1]],
					},
				},
				// if column is empty after block removel, then remove column
				$unset: [instruction.column.keep ? null : columnId],
			},
			rows: {
				// if a column should be removed, then also remove reference from it's row
				[rowId]: {
					columns: {
						$splice: [
							instruction.column.keep
								? [0, 0]
								: [instruction.column.index, 1],
						],
					},
					settings: {
						column_division: {
							$set: null,
						},
					},
					$merge: {
						updated: Date.now(),
					},
				},
				// if the entire row should be removed
				$unset: [instruction.row.keep ? null : rowId],
			},
			areas: {
				// updates area rows array where either nothing happens (if the row should not be removed)
				// or removes the row reference (if the row should be removed)
				[areaId]: {
					rows: {
						$splice: [
							instruction.row.keep
								? [0, 0]
								: [instruction.row.index, 1],
						],
					},
				},
			},
		},
	});
};

export const toggleBlockRowVisibility = (state, rowId, variantType) => {
	const variant = `visible_${variantType}`;
	const updatedValue = !state.elements.rows[rowId].settings[variant];

	return update(state, {
		elements: {
			rows: {
				[rowId]: {
					settings: {
						[variant]: {
							$set: updatedValue,
						},
					},
					updated: {
						$set: Date.now(),
					},
				},
			},
		},
	});
};
