import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Icon } from '../UI';

const Chip = (props) => {
	/**
	 * Handles whenr emoving the Chip.
	 *
	 * @param {Event} ev     Event created by the onClick.
	 */
	const removedHandler = useCallback(
		(ev) => {
			ev.stopPropagation();

			if(props.removed) props.removed(ev, props);
		},
		[props]
	);

	return (
		<ScWrapper
			style={props.style}
			className={props.className}
		>
			<ScText>{props.text}</ScText>
			<Icon
				icon={['fal', 'xmark']}
				onClick={removedHandler}
			/>
		</ScWrapper>
	);
};

Chip.propTypes = {
	name: PropTypes.string,
	text: PropTypes.string.isRequired,
	removed: PropTypes.func
};

export default Chip;

const ScWrapper = styled.div`
	background-color: #ddd;
	border-radius: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 8px;
	margin-bottom: 4px;
	margin-right: 4px;

	&:last-child {
		margin-right: 0	
	}
`;

const ScText = styled.div`
	font-size: 12px;
	margin-right: 8px;
	display: inline-block;
`;
