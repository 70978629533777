import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import useModal from '../../../../../../hooks/Modal/useModal';
import MediaArchive from '../../../../../../containers/MediaArchive/MediaArchive';
import { TextInput } from '../../../../../../components/Forms';
import { ImageThumbnail, Icon } from '../../../../../../components/UI';
import Chip from '../../../../../../components/UI/Chip';
import ScreenVariants from '../../../../../../components/ScreenVariants';
import useProductSearch from '../../../../hooks/useProductSearch';

/**
 * Used to show the content in the settings modal for the SpecialOffer block.
 */
const BlockSpecialOfferSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;

	// The func to trigger Builder update when something inside here changed.
	const blockChanged = props.changed;

	// The block data to show inside this component
	const block = currentState.data.special_offer;

	const builderConfig = useSelector((state) => state.config.builder);
	const viewVariants = builderConfig.variants;
	const [selectedVariant, setSelectedVariant] = useState(
		builderConfig.variants.current
	);

	// REMEMBER! Changing modal's state will trigger re render this component.
	const mediaArchiveModal = useModal();

	const openMediaArchive = useCallback(() => {
		mediaArchiveModal.open({
			position: 'center',
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (originalState, currentState, closeModal) =>
						closeModal()
				}
			]
		});
	}, [mediaArchiveModal]);

	const imageThumbnailActions = useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: openMediaArchive
			},
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				action: () => {
					const variant =
						selectedVariant === 'desktop'
							? 'media'
							: 'mobile_media';

					updateState({
						data: {
							special_offer: {
								[variant]: {
									uuid: { $set: null },
									thumbnail: { $set: null },
									src: { $set: null }
								}
							}
						}
					});
				},
				styles: { hover: { backgroundColor: '#DA534D' }}
			}
		],
		// eslint-disable-next-line no-use-before-define
		[openMediaArchive, selectedVariant, updateState]
	);

	/**
	 * Handles when an input field's value changed.
	 *
	 * @param {SyntheticEvent} ev 		Event that triggered the change.
	 */
	const inputChangedHandler = (ev, props, node) => {
		const property = node.name;
		const value = node.value;
		
		updateState({
			data: {
				special_offer: {
					[property]: { $set: value }
				}
			}
		});
	};

	/**
	 * Adds a product to the block when clicked a search result
	 * Updates modal state + builder accoridngly
	 *
	 * @param {object} product
	 */
	const addProductHandler = useCallback(
		(product, input) => {
			updateState({
				data: {
					special_offer: {
						product_id: {
							$set: product.id
						},
						product_number: {
							$set: product.number
						},
						name: { $set: product.name },
						colors: { $set: product.colors },
						sizes: { $set: product.sizes },
						status: { $set: product.status }
					}
				}
			}).then((updatedState) => {
				// notify builder.
				blockChanged(updatedState);
			});
		},
		[blockChanged, updateState]
	);

	/**
	 * Clears the product from the block, making possible to choose another.
	 */
	const removeProductHandler = () => {
		updateState({
			data: {
				special_offer: {
					product_id: {
						$set: null
					},
					name: { $set: null },
					colors: { $set: null },
					sizes: { $set: null }
				}
			}
		}).then((updatedState) => {
			// notify builder.
			blockChanged(updatedState);
		});
	};

	/**
	 * Media Archive returns an array of objects
	 * Uses the first object (as ImageBlock only allows one image)
	 * And updates the state and closes the modal
	 *
	 * @param {array} files
	 */
	const selectedMediaHandler = (files) => {
		const file = files[0];

		// TODO: media & media_mobile are the hardcoded prop names for now until back-end makes variants dynamic
		const variant =
			selectedVariant === 'desktop' ? 'media' : 'mobile_media';

		const updatedQry = {
			data: {
				special_offer: {
					[variant]: {
						$set: {
							src: file.src,
							uuid: file.uuid
						}
					}
				}
			}
		};

		// update the modals state and then notify builder to show the changes.
		updateState(updatedQry)
			.then((updatedState) => {
			// notify Builder to update the block for preview to work
				blockChanged(updatedState);

				mediaArchiveModal.close();
			});
	};

	/**
	 * Handles when the variant have been changed.
	 */
	const changedVariantHandler = useCallback(
		(variantName) => setSelectedVariant(variantName),
		[]
	);

	const productSearch = useProductSearch(addProductHandler);

	return (
		<>
			{mediaArchiveModal.getAsComponent(
				<MediaArchive
					mediaChosen={(files) => selectedMediaHandler(files)}
					maxSelectionAmount={1}
					fileShowOnly={['image']}
				/>
			)}
			<ScWrapper>
				{block.product_id ? (
					<ScChipWrapper>
						<Chip
							style={{ marginBottom: 16 }}
							text={block.name}
							removed={removeProductHandler}
						/>
					</ScChipWrapper>
				) : (
					<div style={{ marginBottom: 16 }}>
						{productSearch()}
					</div>
				)}
				<ScreenVariants
					currentVariant={selectedVariant}
					variants={viewVariants.available}
					changed={changedVariantHandler}
				>
					<ScImageThumbnail
						height="200px"
						width="100%"
						actions={imageThumbnailActions}
						placeholderClicked={openMediaArchive}
						thumbnail={
							selectedVariant === 'desktop'
								? block.media.src
								: block.mobile_media.src
						}
					/>
				</ScreenVariants>
				<TextInput
					label="Alt text"
					name="alt_text"
					value={block.alt_text}
					changed={inputChangedHandler}
				/>
			</ScWrapper>
		</>
	);
};

BlockSpecialOfferSettings.propTypes = {};

export default BlockSpecialOfferSettings;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;

const ScChipWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const ScImageThumbnail = styled(ImageThumbnail)`
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;
