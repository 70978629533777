import React from 'react';
import styled, { css } from 'styled-components/macro';

const Dropzone = (props) => {
	const text = props.text ? props.text : 'Drop to upload';

	return <ScContainer enabled={props.enabled}>{text}</ScContainer>;
};

const ScContainer = styled.div`
	width: 100%;
	padding: 16px;
	border: 2px dashed #ddd;
	color: var(--font-dark-grey-color);
	text-align: center;
	margin: 0 16px;
	font-size: 12px;
	background-color: transparent;
	transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease;
	user-select: none;
	cursor: default;

	${(props) =>
		props.enabled &&
		css`
			.isDragging & {
				border: 2px dashed #aaa;
				:hover {
					border: 2px dashed #444;
					color: #444;
					background-color: #fefefe;
					cursor: move;
				}
			}
		`}

	${(props) =>
		!props.enabled &&
		css`
			opacity: 0.5;
		`}
`;

export default Dropzone;
