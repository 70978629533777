import React from 'react';
import HtmlToReact, { Parser } from 'html-to-react';
import { isObject } from 'react-utils';
import BlockDefinitions from './BlockDefinitions';

const blockInjector = (props) => {
	const type = props.type;

	// Clone the blocks component and add the props.
	let blockComponent = React.cloneElement(
		BlockDefinitions[props.type].builder.component,
		// Push extra props
		{
			...props
		}
	);

	// figure out how the component should be wrapped
	const blockType = props.blockTypes[type];

	if(isObject(blockType) && blockType.html && blockType.html.trim() !== '') {
		const wrappingMarkUp = blockType.html;
		const htmlToReactParser = new Parser();
		const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
			React
		);
		const processingInstructions = [
			{
				// This is REQUIRED, it tells the parser
				// that we want to insert our React
				// component as a child
				replaceChildren: true,
				shouldProcessNode: function(node) {
					return (
						node.attribs &&
						node.attribs['data-block'] === 'injector'
					);
				},
				processNode: function(node, children, index) {
					return blockComponent;
				}
			},
			{
				// Anything else
				shouldProcessNode: function(node) {
					return true;
				},
				processNode: processNodeDefinitions.processDefaultNode
			}
		];

		blockComponent = htmlToReactParser.parseWithInstructions(
			wrappingMarkUp,
			() => true,
			processingInstructions
		);
	}

	return blockComponent;
};

export default blockInjector;