import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Table = (props) => {
	return <ScTable>
        {props.children}
    </ScTable>;
};

Table.propTypes = {
    children: PropTypes.any.isRequired
};

export default Table;

const ScTable = styled.div`
	width: 100%;
	border-spacing: 0px 4px;
	display: table;
`;
