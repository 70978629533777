import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import SortableList from '../../../../../../components/Sortable/SortableList';
import { Message } from '../../../../../../components/UI';
import useProductSearch from '../../../../hooks/useProductSearch';

/**
 * Used to show the content in the settings modal for the CheckOutBuyMore block.
 */
const BlockCheckoutBuyMoreSettings = (props) => {
	const { changed } = props;
	const { currentState, updateState } = props.modal;
	const block = currentState.data;

	/**
	 * Adds a product to the block
	 * Updates modal state + builder accoridngly
	 *
	 * @param {object} product
	 */
	const addProductHandler = useCallback(
		(product, input) => {
			updateState({
				data: {
					articles: {
						$push: [product]
					}
				}
			}).then((updatedState) => {
				// notify builder.
				changed(updatedState);
			});
		},
		[changed, updateState]
	);

	/**
	 * Handles when a product is removed.
	 *
	 * @param {number} productIndex		The index of the product in the state.products
	 */
	const removeProductHandler = useCallback(
		(productIndex) => {
			updateState({
				data: {
					articles: {
						$splice: [[productIndex, 1]]
					}
				}
			}).then((updatedState) =>
				// notify builder.
				changed(updatedState));
		},
		[changed, updateState]
	);

	/**
	 *	Handles when the order of products have been changed by the user.
	 *
	 * @param {array} suggestedState		A new array with all items in the correct order.
	 */
	const productOrderChangedHandler = useCallback(
		(suggestedState) => {
			updateState({
				data: {
					articles: { $set: suggestedState }
				}
			}).then((updatedState) =>
				// notify builder.
				changed(updatedState));
		},
		[changed, updateState]
	);

	const articleSearch = useProductSearch(addProductHandler, 'article');

	return (
		<ScWrapper>
			{articleSearch()}

			{block.articles.length > 0 && (
				<>
					<Message
						style={{ marginTop: 16 }}
						isSmall
					>
						Dra och släpp för att sortera ordningen i blocket.
					</Message>
					<SortableList
						items={block.articles}
						changed={productOrderChangedHandler}
						itemRemoved={removeProductHandler}
					/>
				</>
			)}
		</ScWrapper>
	);
};

BlockCheckoutBuyMoreSettings.propTypes = {};

export default BlockCheckoutBuyMoreSettings;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;
