import React from 'react';

/**
 * Used to create a datastructure used for pages
 * [[item1, item2, item3], [item4, item5, item6]]
 * each array in the above expression represents a page
 *
 * @param {array} dataArray
 * @param {int} perPage
 */
export const createPages = (dataArray, perPage) => {
	// create a new reference instead of mutating the original array
	const items = [...dataArray];
	const results = [];

	while (items.length) {
		results.push(items.splice(0, perPage));
	}

	return results;
};

/**
 * Determines whether a selection is possible
 * For a selection to be possible a callback is required
 * And selectedItems has to be less than the max param value
 *
 * @param {function} cb
 * @param {int} max
 * @param {[{}]} items
 */
export const isItemSelectionPossible = (cb, max, items) => {
	return (cb && !max ? true : items.length < max) && !!cb;
};

/**
 * Helper function to determine whether lazyloading and pages should be created or not
 *
 * @param {array[{}]} items
 * @param {function} cb
 * @param {int} max
 */
export const itemRendererHelper = (items, cb, max) => {
	if (cb) return createPages(items, max);

	return items;
};

/**
 * Takes a customer header component and injects properties that are needed
 *
 * @param {React.Component} component
 * @param {object} props
 */
export const headerPropertyInjection = (component, props) => {
	const headerProps = {
		activeView: props.activeView,
		isLoading: props.isLoading,
		thumbnailSize: props.thumbnailSize,
		setActiveView: props.setActiveView,
		setIsLoading: props.setIsLoading,
		setThumbnailSize: props.setThumbnailSize,
		setViewHandler: props.setViewHandler,
		headerSettings: props.headerSettings,
	};

	return React.cloneElement(component, headerProps);
};

/**
 * Takes a customer view component and injects properties that are needed
 *
 * @param {React.Component} component
 * @param {object} props
 */
export const viewsPropertyInjection = (component, props) => {
	const viewProps = {
		items: itemRendererHelper(
			props.items,
			props.nextPageCallback,
			props.itemsPerPage
		),
		itemSize: props.thumbnailSize,
		itemActions: props.itemActions,
		disabledItemsWithActionBar: props.disabledItemsWithActionBar,
		selectionEnabled: props.selectionEnabled,
		selectedItems: props.selectedItems,
		itemSelectedHandler: props.itemSelectedHandler,
		thumbnailExtraLayers: props.additionalLayers,
		isLoading: props.isLoading,
		enableDnD: props.enableDnD,
		nextPageCallback: props.nextPageCallback,
	};

	return React.cloneElement(component, viewProps);
};
