import { Column } from 'react-table';

/**
 * Constanst used for mapping property names of User,
 * 	used in the column definition & later in logic when creating columns for the table.
 */

// Columns for React Table.
export const GROUPMEMBERS_LIST_COLUMNS: Column<object>[] = [
	{
		Header: 'Namn',
		accessor: 'name'
	},
	{
		Header: 'Användarnamn',
		accessor: 'login_name'
	},
	{
		Header: 'E-postaddress',
		accessor: 'email'
	}
];
