import React from 'react';
import styled from 'styled-components/macro';
import { RedirectDetailsProps } from './Redirects.types';
import useDeleteRedirect from './hooks/useDeleteRedirect';
import { Button, ModalContainer } from '../../../components/UI';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import TextInput from '../../../components/Forms/TextInput/TextInput';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';

const RedirectDetails: React.FC<RedirectDetailsProps> = (props) => {
	const { modal, refetchRedirects } = props;
	const { currentState: selectedRedirect } = modal;

	// If no id is provided, the modal is used to create a new redirect
	const isNewRedirect = !selectedRedirect.id;

	// Extract the updateSate method from the modal
	const { updateState } = modal;

	// A function to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// Holds the useMutationResult with information about the delete redirect mutation
	const deleteRedirect = useDeleteRedirect();

	/**
	 * Delete the redirect
	 * 
	 * @returns {void}
	 */
	const deleteRedirectHandler = React.useCallback((): void => {
		if(isNewRedirect) return; 

		let alertId = notification('SHOW', {
			priority: AlertPriorityTypes.info,
			title: 'Radera',
			children: 'Raderar 301-länk...'
		});

		// This callback is called if the delete redirect request is successful
		const cb = () => {
			refetchRedirects();
			modal.close();
		};
		
		deleteRedirect.mutate({ data: selectedRedirect, alertId, cb });
	}, [deleteRedirect, modal, notification, refetchRedirects, selectedRedirect, isNewRedirect]);

	/**
	 * Update the state values when the input changes
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	const inputChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;

		updateState({
			[name]: {
				$set: value
			}
		});
	}, [updateState]);

	return (
		<ErrorBoundary>
			<ModalContainer>
				<ScWrapper>

					<ScTitle>
						Begärd url
					</ScTitle>
					{!isNewRedirect && (
						<ScUrl>
							{selectedRedirect.request_url}
						</ScUrl>
					)}
					{isNewRedirect && (
						<ScTextInput
							omitLabel
							hasMaxWidth
							name="request_url"
							changed={inputChangedHandler}
							placeholder="https://www.example.com/old-url"
						/>
					)}

					<ScTitle>
						Skicka vidare till
					</ScTitle>
					{!isNewRedirect && (
						<ScUrl>
							{selectedRedirect.redirect_url}
						</ScUrl>
					)}
					{isNewRedirect && (
						<ScTextInput
							omitLabel
							hasMaxWidth
							name="redirect_url"
							changed={inputChangedHandler}
							placeholder="https://www.example.com/new-url"
						/>
					)}

					{!isNewRedirect && (
						<Button
							isAutoWidth
							buttonIcon={['fas', 'xmark']}
							isPrimary
							onClick={deleteRedirectHandler}
						>
							Ta bort
						</Button>
					)}
				</ScWrapper>
			</ModalContainer>
		</ErrorBoundary>
	);
};

export default React.memo(RedirectDetails);

const ScWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const ScTitle = styled.h2`
    font-weight: 600;
    width: 100%;
`;

const ScUrl = styled.p`
    margin: 16px 0 24px;
    width: 100%;
`;

const ScTextInput = styled(TextInput)`
    margin: 16px 0 24px;
`;