import { useState, useCallback, useRef, useMemo } from 'react';
import update from 'immutability-helper';
import { isObject, isEmptyObject, isString, isFunction } from 'react-utils';

const errorMessages = {
	unknown: 'Detta fält har validerats felaktigt',
	required: 'Detta fält är obligatoriskt',
	min: 'Detta fält kräver minst {min} tecken',
	max: 'Detta fält får max innehålla {max} tecken',
	email: 'Den angivna e-post adressen är inte giltig',
	phone: 'Det angivna nummret är inte giltig',
	url: 'Den angivna URLen verkar inte vara giltig',
	numeric: 'Detta fältet får endast innehålla siffror',
	unchecked: 'Du måste kryssa i denna rutan för att fortsätta',
	date: 'Du har inte angivit ett korrekt datum',
	slug: 'Tillåtna tecken: a-z 0-9 / -',
	slugurl:
		'Det måste vara en giltig URL eller en "slug" vars tillåtna tecken matchar a-z 0-9 / -'
};

const useFormValidation = () => {
	const elementsRef = useRef({});
	const [errors, setErrors] = useState({});

	/**
	 * Handles the validaton of text fields, text areas
	 *
	 * @param {object} inputErrors
	 * @param {string|object} validation
	 * @param {any} value
	 * @param {string} id
	 */
	const _verifyTextFormFields = useCallback(
		(inputErrors, validation, value, id) => {
			// handles common cases and custom regex checks

			switch(true) {
				// verifies email
				case validation === 'email' ||
					(isObject(validation) && validation.type === 'email'):
					if(!_verifyEmail(value))
						inputErrors[id].push(_setError(validation));
					break;

				case validation === 'phone' ||
					(isObject(validation) && validation.type === 'phone'):
					if(!_verifyPhoneNumber(value))
						inputErrors[id].push(_setError(validation));
					break;

				// verifies url
				case validation === 'url' ||
					(isObject(validation) && validation.type === 'url'):
					if(!_verifyUrl(value))
						inputErrors[id].push(_setError(validation));
					break;

				// verifies number
				case validation === 'numeric' ||
					(isObject(validation) && validation.type === 'numeric'):
					if(!_verifyNumeric(value))
						inputErrors[id].push(_setError(validation));
					break;

				case validation === 'date' ||
					(isObject(validation) && validation.type === 'date'):
					if(!_verifyDate(value))
						inputErrors[id].push(_setError(validation));
					break;

				case validation === 'slug' ||
					(isObject(validation) && validation.type === 'slug'):
					if(!_verifySlug(value))
						inputErrors[id].push(_setError(validation));
					break;

				case validation === 'slugurl' ||
					(isObject(validation) && validation.type === 'slugurl'):
					if(!_verifySlugOrUrl(value))
						inputErrors[id].push(_setError(validation));
					break;

				// verifies regex
				case isObject(validation) && validation.type === 'regex':
					const regex = _doRegex(value, validation.pattern);

					if(!regex) {
						inputErrors[id].push(_setError(validation));
					}

					break;

				default:
					if(isObject(validation)) {
						// deals with fields where length is < min
						if(validation.min && value.length < validation.min) {
							const msg = _setError(validation);

							inputErrors[id].push(
								msg.replace('{min}', validation.min)
							);
						}

						// deals with fields where length is > max
						if(validation.max && value.length > validation.max) {
							const msg = _setError(validation);
							inputErrors[id].push(
								msg.replace('{max}', validation.max)
							);
						}
					} else {
						if(!value) inputErrors[id].push(_setError('required'));
					}
			}

			return inputErrors;
		},
		[]
	);

	/**
	 * Handles verification of checkboxes and radio buttons
	 *
	 * @param {object} inputErrors
	 * @param {HTMLElement} reference
	 * @param {object|string} validation
	 * @param {string} id
	 */
	const _verifyCheckedInputs = useCallback(
		(inputErrors, reference, validation, id) => {
			if(!reference.checked) {
				const msg =
					isObject(validation) && 'message' in validation
						? validation
						: 'unchecked';

				inputErrors[id].push(_setError(msg));
			}

			return inputErrors;
		},
		[]
	);

	/**
	 * Registers an element and stores it to a reference
	 *
	 * @param {HTMLElement} ref
	 * @param {object} config
	 */
	const registerElement = useCallback((ref, config) => {
		if(!ref) return;

		const id = ref.getAttribute('id');

		elementsRef.current = {
			...elementsRef.current,
			[id]: {
				...elementsRef.current[id],
				reference: ref,
				config: config
			}
		};
	}, []);

	/**
	 * Unregisters an element from form validation
	 * Useful in dynamic forms where input elements may be unmounted
	 *
	 * @param {*} id
	 */
	const unregisterElement = useCallback((id) => {
		const updatedRef = Object.keys(elementsRef.current).reduce(
			(object, key) => {
				if(key !== id) {
					object[key] = elementsRef.current[key];
				}

				return object;
			},
			{}
		);
            
		setErrors((errors) => update(errors, {
			$unset: [id]
		}));
		elementsRef.current = updatedRef;
	}, []);

	/**
	 * Will return a bool whether there are form validation errors or not
	 */
	const formHasErrors = useCallback(() => {
		return !isEmptyObject(errors);
	}, [errors]);

	/**
	 * Resets the error state
	 */
	const resetErrors = useCallback(() => {
		setErrors({});
	}, []);

	/**
	 * Verify whether the given value for an input matches it's config
	 *
	 * @param {string} id
	 */
	const verify = useCallback(
		(id) => {
			const element = elementsRef.current[id];
			const reference = element.reference;
			const value = reference.value.trim();
			const { validation, required } = element.config;
			const inputErrors = {
				[id]: []
			};

			if(required || value) {
				switch(reference.type) {
					case 'checkbox':
						return _verifyCheckedInputs(
							inputErrors,
							reference,
							validation,
							id
						);

					case 'text':
						return _verifyTextFormFields(
							inputErrors,
							validation,
							value,
							id
						);

					default:
						if(required && !value) {
							inputErrors[id].push(_setError('required'));
						}
				}
			}

			return inputErrors;
		},
		[_verifyCheckedInputs, _verifyTextFormFields]
	);

	/**
	 * Goes through form elements that were registered in the hooks instance
	 * Each element will be verified according to it's unique rule set
	 *
	 * Can be called in parent component
	 * Is called automatically by the submit function when executed
	 */
	const verifyAll = useCallback(() => {
		return Object.keys(elementsRef.current).reduce((current, inputId) => {
			const input = verify(inputId);

			if(input[inputId].length) {
				return {
					...current,
					...input
				};
			} else return current;
		}, {});
	}, [verify]);

	/**
	 * Will listen to changes, verify them and call the given callback method
	 *
	 * @param {EventListenerObject} ev
	 * @param {function} externalCallback
	 * @param {array} callbackArgs
	 */
	const watch = useCallback(
		(ev, externalCallback = null, callbackArgs = null) => {
			const id = ev.target.id;

			if(!isFunction(externalCallback)) {
				console.warn(
					'[watch expects second parameter to be a function]'
				);
				return;
			}

			if(id in elementsRef.current) {
				const inputErrors = verify(id);

				if(inputErrors[id]) {
					let errorState = {
						...errors,
						...inputErrors
					};

					if(!inputErrors[id].length) {
						errorState = Object.keys(errorState).reduce(
							(prev, input) => {
								if(input !== id) prev[input] = errors[input];

								return prev;
							},
							{}
						);
					}

					setErrors(errorState);
				}

				return externalCallback(ev, ...callbackArgs);
			}

			console.warn(
				'[input was not successfully registered. Watch cannot work with what it got]'
			);
		},
		[errors, verify]
	);

	/**
	 * Typically added as the action of a button
	 * Will either call the given callback registered with the hook
	 * Or update the state and re-render parent component if errors were found
	 */
	const submit = useCallback(
		(submitCallback = null) => {
			const formErrors = verifyAll();

			if(!isEmptyObject(formErrors)) setErrors(formErrors);
			else if(submitCallback && isFunction(submitCallback)) {
				setErrors(formErrors);
				submitCallback();
			}
		},
		[verifyAll]
	);

	/**
     * Removes a prop property from the error object
     * 
     * @param {string} id
     */
	const resetSpecificError = useCallback((id) => {
		setErrors((errors) => update(errors, {
			$unset: [id]
		}));
	}, []);

	/**
	 * Reset the hook and use the same instance for another form.
	 */
	const reset = useCallback(() => {
		elementsRef.current = null;

		if(errors.length) resetErrors();
	}, [errors.length, resetErrors]);

	return useMemo(() => {
		return {
			registerElement,
			unregisterElement,
			watch,
			errors,
			submit,
			verify,
			elementsRef,
			formHasErrors,
			reset,
			resetErrors,
			resetSpecificError
		};
	}, [
		errors,
		formHasErrors,
		registerElement,
		reset,
		resetErrors,
		submit,
		unregisterElement,
		verify,
		watch,
		resetSpecificError
	]);
};

/**
 * Defines an error message and returns to callee
 *
 * @param {string|object} validation
 */
const _setError = (validation) => {
	if(validation) {
		switch(true) {
			case isString(validation):
				return errorMessages[validation];

			default:
				const { message, type, min, max } = validation;

				if(message) return message;
				else if(type) return errorMessages[type];
				else if(min) return errorMessages.min;
				else if(max) return errorMessages.max;
				else return errorMessages.unknown;
		}
	}
};

/**
 * Verifies a regular expression
 *
 * @param {string} value
 * @param {string} regex
 */
const _doRegex = (value = null, regex = null) => {
	if(!value || !regex) return false;
	return regex.test(String(value).toLowerCase());
};

/**
 * Verifies whether input is numeric
 *
 * @param {string|number} number
 */
const _verifyNumeric = (number) => {
	const regex = /^\d+$/;
	return _doRegex(number, regex);
};

/**
 * Verifies whether a string is an email or not
 *
 * @param {string} email
 */
const _verifyEmail = (email) => {
	const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	return _doRegex(email, regex);
};

/**
 * Verifies whether a string is a phone number or not
 *
 * @param {string} number
 */
const _verifyPhoneNumber = (number) => {
	const regex = /^[+\d() ]+/;
	return _doRegex(number, regex);
};

/**
 * Verifies if a string is a URL or not
 *
 * @param {string} url
 */
const _verifyUrl = (url) => {
	const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
	return _doRegex(url, regex);
};

/**
 * Verifies if a string id a valid slug
 *
 * @param {string} slug
 */
const _verifySlug = (slug) => {
	const regex = /^[a-z0-9-/]+$/g;
	return _doRegex(slug, regex);
};

/**
 * Verifies a slug or a URL
 *
 * @param {*} str
 */
const _verifySlugOrUrl = (str) => {
	const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$|(^[a-z0-9-/]+$)/;
	return _doRegex(str, regex);
};

/**
 * Verifies a date in the following variations
 *
 * - 11102019 OR 11-10-2019 OR 11/10/2019
 * - 10112019 OR 10-11-2019 OR 10/11/2019
 * - 20191110 OR 2019-11-10 OR 2019/11/10
 *
 *
 * @param {*} date
 */
const _verifyDate = (date) => {
	const mmddyyyyRegex = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/g;
	const ddmmyyyyRegex = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/g;
	const yyyymmddRegex = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048])00)[-/]?02[-/]?29)$/g;

	switch(true) {
		// verifies if the input matches MMDDYYYY (with/(out) / or -)
		case _doRegex(date, mmddyyyyRegex):
			return true;

		case _doRegex(date, ddmmyyyyRegex):
			return true;

		case _doRegex(date, yyyymmddRegex):
			return true;

		default:
			return false;
	}
};

export default useFormValidation;
