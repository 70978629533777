import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../UI';

const DialogAddItem = (props) => {
	return (
		<ScContainer
			name={props.name}
			onClick={props.pressed}
		>
			<Icon icon={props.fIcon} />
			<ScTitle>{props.title}</ScTitle>
		</ScContainer>
	);
};

export default DialogAddItem;

const ScContainer = styled.div`
	padding: 24px 16px;
	width: 104px;
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: background-color 0.3s ease;
	cursor: pointer;
	text-align: center;

	:hover {
		background-color: rgba(0, 0, 0, 0.08);
	}
`;

const ScTitle = styled.p`
	margin-top: 8px;
	font-size: 12px;
`;
