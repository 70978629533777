import React from 'react';
import styled, { css } from 'styled-components/macro';
import { InfoBoxProps, ScInfoBoxProps } from './model.InfoBox';
import { Icon } from '..';

const InfoBox: React.FC<InfoBoxProps> = (props) => {
	return (
		<ScContainer
			isSuccess={props.isSuccess}
			isWarning={props.isWarning}
			isError={props.isError}
		>
			{!(props.isSuccess || props.isWarning || props.isError) && (
				<ScStatusIcon icon={['fal', 'circle-info']} />
			)}
			{props.isSuccess && <ScStatusIcon icon={['fal', 'check']} />}
			{props.isWarning && (
				<ScStatusIcon icon={['fal', 'triangle-exclamation']} />
			)}
			{props.isError && (
				<ScStatusIcon icon={['fal', 'circle-exclamation']} />
			)}

			{props.children}
		</ScContainer>
	);
};

export default InfoBox;

const ScContainer = styled.div<ScInfoBoxProps>`
	padding: 16px;
	font-size: 12px;
	margin-bottom: 16px;
	display: flex;
	background: #87d5f0;
	color: #fafafa;
	align-items: center;

	/* success */
	${(props) =>
		props.isSuccess &&
		css`
			background: #7ed3b2;
		`}

	/* warning */
	${(props) =>
		props.isWarning &&
		css`
			background: #f0d387;
		`}

	/* error */
	${(props) =>
		props.isError &&
		css`
			background: #e76677;
		`}
`;
const ScStatusIcon = styled(Icon)`
	margin-right: 16px;
`;
