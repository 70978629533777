import { Transforms } from 'slate';
import {
	ALIGN_LEFT,
	BULLETED_LIST,
	HEADING_FOUR,
	HEADING_ONE,
	HEADING_THREE,
	HEADING_TWO,
	LIST_ITEM,
	NUMBERED_LIST,
	OC7Editor,
	PARAGRAPH,
} from '../../../OC7Editor';

const allowedTypes = [
	HEADING_ONE,
	HEADING_TWO,
	HEADING_THREE,
	HEADING_FOUR,
	LIST_ITEM,
	NUMBERED_LIST,
	PARAGRAPH,
	BULLETED_LIST,
];

/**
 * Will transform the selected nodes to include the object used for alignment
 *
 * @param {Editor} editor
 * @param {string} format
 */
export const setAlignment = (editor, format) => {
	Transforms.setNodes(
		editor,
		{ alignment: format },
		{
			match: (n) => allowedTypes.includes(n.type),
			split: false, // do not split to a new paragraph
			mode: 'highest',
		}
	);
};

/**
 *
 *
 * @param {Editor} editor
 * @param {string} format
 * @returns {boolean}
 */
export const isAligned = (editor, format) => {
	const [match] = OC7Editor.nodes(editor, {
		match: (n) => n.type === format,
	});

	return !!match;
};

/**
 * Returns a boolean whether a node has the property "alignment"
 *
 * @param {Node} node
 * @returns {boolean}
 */
export const hasAlignment = (node) => {
	return 'alignment' in node; // Reflect.has(node, 'alignment');
};

/**
 * If a node has an aligmnent rule assigned and it's not "left"
 * Then return the appropriate styling for the alignment in HTML
 *
 * @param {Node} node
 * @returns {string}
 */
export const serializeAlignments = (node) => {
	if (hasAlignment(node) && node.alignment !== ALIGN_LEFT) {
		return ` style="text-align: ${node.alignment};"`;
	}

	return '';
};

/**
 * If a node has an aligmnent rule assigned and it's not "left"
 * Then return the appropriate styling for the alignment in JS
 *
 * @param {Node} node
 * @returns {object}
 */
export const serializeAlignmentsJSX = (node) => {
	if (hasAlignment(node) && node.alignment !== ALIGN_LEFT) {
		return { style: { textAlign: node.alignment } };
	}

	return {};
};
