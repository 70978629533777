import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonTableViewProps } from './model.SkeletonTableView';
const SkeletonTableView: React.FC<SkeletonTableViewProps> = (props) => {
	return (
		<ContentLoader
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={328}
			width={'calc(100% - 32px)'}
			// viewBox={'0 0 792 372'}
		>
			<rect
				x="32"
				y="32"
				width="80"
				height="40"
				rx="4"
			/>
			<rect
				x="120"
				y="32"
				width="144"
				height="40"
				rx="4"
			/>
			<rect
				x="272"
				y="32"
				width="488"
				height="40"
				rx="4"
			/>
			<rect
				x="32"
				y="112"
				width="100%"
				height="24"
				rx="4"
			/>
			<rect
				x="32"
				y="156"
				width="100%"
				height="40"
			/>
			<rect
				x="32"
				y="200"
				width="100%"
				height="40"
			/>
			<rect
				x="32"
				y="244"
				width="100%"
				height="40"
			/>
			<rect
				x="32"
				y="288"
				width="100%"
				height="40"
			/>
		</ContentLoader>
	);
};

export default SkeletonTableView;
