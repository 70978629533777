import React from 'react';
import styled from 'styled-components/macro';
import { ReactTableHeadRowProps } from './ReactTableHeadRow.types';

const ReactTableHeadRow: React.FC<ReactTableHeadRowProps> = (props) => {
	return (
		<ScTr {...props}>
			{props.children}
		</ScTr>
	);
};

export const ScTr = styled.div`
	display: table-row;
`;

export default ReactTableHeadRow;