import React from 'react';
import styled from 'styled-components/macro';
import { TableHeadProps } from './ReactTableHead.types';

const ReactTableHead: React.FC<TableHeadProps> = (props) => {
	return (
		<ScTableHead>
			{props.children}
		</ScTableHead>
	);
};

export default ReactTableHead;

export const ScTableHead = styled.div`
	display: table-header-group;
`;
