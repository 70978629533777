import { Column } from 'react-table';

/**
 * Constanst used for mapping property names of OrderListItem,
 * 	used in the column definition & later in logic when creating columns for the table.
 */
export const USERSLIST_COLUMNS_ENABLED = 'enabled';

// Columns for React Table.
export const USERS_LIST_COLUMNS: Column<object>[] = [
	{
		Header: 'Namn',
		accessor: 'name'
	},
	{
		Header: 'Användarnamn',
		accessor: 'login_name'
	},
	{
		Header: 'Email',
		accessor: 'email'
	},
	{
		Header: 'Aktiverade',
		accessor: USERSLIST_COLUMNS_ENABLED
	},
	{
		Header: 'Grupper',
		accessor: 'group_names'
	}
];
