import styled from 'styled-components/macro';
import { ScTreeWrapperProps } from './model.TreeWrapper';

const TreeWrapper = styled.div<ScTreeWrapperProps>`
	display: flex;
	flex-direction: ${(props) => (props.isHorizontal ? 'row' : 'column')};
	align-items: flex-start;
`;

export default TreeWrapper;
