import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import DateTimePicker from './DateTimePicker/DateTimePicker';
import { CheckItem } from '../Forms';

const PublishDateSelector = (props) => {
	// If there is a publish, unpublish datetime asigned.
	const havePublish = !!props.publish;
	const haveUnpublish = !!props.unpublish;

	// Disabled the orresponding fields if no callback for it given.
	//		Example, disable un-publish date for changes if no callback in 'props.unpublishChanged'
	const publishDisabled = props.publishChanged;
	const unpublishDisabled = props.unpublishChanged;

	/**
	 * Handles changes for "checkbox" Nodes elements.
	 *
	 * @param {SyntheticEvent} ev
	 */
	const checkboxChangedHandler = (ev) => {
		ev.stopPropagation();

		// name of the checkbox field, publish or unpublish
		const fieldName = ev.target.name;
		const checked = ev.target.checked;

		// if checked is true then use current timestamp (whe don't know any other time to use)
		// else use null, as it is used when no date specified, the function is disabled.
		const newDatetime = checked
			? DateTime.utc().toISO()
			: null;

		switch(fieldName) {
			case 'publish':
				if(props.publishChanged) props.publishChanged(newDatetime);
				break;

			case 'unpublish':
				//Check for error, publish date cannot be the same or after unpublish date
				if(props.publish >= newDatetime) {
					props.onError();
				}
				if(props.unpublishChanged) props.unpublishChanged(newDatetime);
				break;

			default:
				break;
		}
	};

	return (
		<>
			<CheckItem
				type="checkbox"
				title={props.checkboxTitle ?? 'Publicera sidan'}
				name="publish"
				changed={checkboxChangedHandler}
				checked={havePublish}
				// Disabled if no callback for changes given.
				disabled={publishDisabled}
			/>

			{havePublish && (
				<>
					<DateTimePicker
						label="Startdatum"
						description="Välj ett startdatum och tid för publicering"
						changed={(date) => props.publishChanged(date)}
						maxDate={haveUnpublish ? props.unpublish : null}
						value={props.publish}
					/>
					<CheckItem
						type="checkbox"
						title="Aktivera slutdatum för publicering"
						name="unpublish"
						description="Om ni vill att sidan endast ska vara aktiv under en viss period"
						changed={checkboxChangedHandler}
						checked={haveUnpublish}
						// Disabled if no callback for changes given.
						disabled={unpublishDisabled}
					/>
					{haveUnpublish && (
						<>
							<DateTimePicker
								label="Slutdatum"
								description="Välj ett slutdatum och tid för publicering."
								changed={(date) => props.unpublishChanged(date)}
								minDate={props.publish}
								value={props.unpublish}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

PublishDateSelector.propTypes = {
	publish: PropTypes.string,
	unpublish: PropTypes.string,

	publishChanged: PropTypes.func.isRequired,
	unpublishChanged: PropTypes.func.isRequired,

	onError: PropTypes.func.isRequired,
	checkboxTitle: PropTypes.string
};

export default PublishDateSelector;
