import React from 'react';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { BlockSettingsProps, BuilderBlock } from '../../../../../../components/Builder/Block.types';
import { CheckItem, Select, TextInput } from '../../../../../../components/Forms';
import { SelectProps } from '../../../../../../components/Forms/Select/model.Select';
import GridView from '../../../../../../components/ItemManager/Views/GridView/GridView';
import ImageThumbnail from '../../../../../../components/UI/ImageThumbnail/ImageThumbnailTS/ImageThumbnail';
import Draggable from '../../../../../../hoc/DragDrop/Draggable';
import { BlockCategoryType, StrippedCategoryProduct, CompleteCategoryProduct, DraggableProductItem, FilterNamesType, ProductDetailsState, ScBadgeProps } from '../BlockCategory.types';
import axios from '../../../../../../utils/oc-axios';
import useAlert, { AlertPriorityTypes } from '../../../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../../../hooks/useAlert/types.useAlert';
import { ImageThumbnailActionsType, ImageThumbnailProps } from '../../../../../../components/UI/ImageThumbnail/ImageThumbnailTS/ImageThumbnail.types';
import DragDrop from '../../../../../../hoc/DragDrop/DragDrop';
import StackingMultipleItems from '../../../../../../components/UI/StackingMultipleItems';
import useProductSearch from '../../../../hooks/useProductSearch';
import { ProductFilterType } from '../../BlockProduct/BlockProduct.types';
import { Icon } from '../../../../../../components/UI';
import useModal from '../../../../../../hooks/Modal/useModal';
import useFormValidation from '../../../../../../hooks/useFormValidation/useFormValidation';
import ProductDetails from '../../../../containers/ProductDetails';
import { CheckItemProps } from '../../../../../../components/Forms/CheckItem/model.CheckItem';
import { ScStatus, ScStatusWrapper } from '../../../../containers/Products';
import { DraggableDestination, DraggableInstruction, DraggableSource } from '../../../../../../hoc/DragDrop/DragDrop.types';

const ICON_SIZE = {
	regular: 'regular',
	small: 'small'
};

const SORTING = {
	inherit: 'inherit',
	custom: 'custom'
};

const BlockCategorySettings: React.FC<BlockSettingsProps<BuilderBlock<BlockCategoryType>>> = (props) => {
	const [iconSize, setIconSize] = React.useState<string>(ICON_SIZE.regular);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedProducts, setSelectedProducts] = React.useState<number[]>([]);
	const [modalIsSaving, setModalIsSaving] = React.useState<boolean>(false);
	const notification = useAlert()[1] as AlertDispatch;
	const formValidation = useFormValidation();

	//Modal props
	const updateState = props.modal.updateState;
	const currentState = props.modal.currentState.data;
	const products = currentState.products;
	const categoryId = currentState.category_id;
	const filterNames = currentState.filter_names;
	const inactiveFilters = currentState.inactive_filters;
	const allFilterNames = currentState.all_filter_names;
	const enabled = currentState.enabled;
	// "inherit" is used to determine whether a category has its own sorting
	// or if it inherits sorting from its parent
	const inherit = currentState.inherit;

	// A modal showing product specific info
	const productDetailModal = useModal();

	//Component to show when products are being dragged
	let dragPlaceholder;
	if(selectedProducts.length > 1) {
		const draggedProducts = products.filter((item) => selectedProducts.includes(item.id));

		dragPlaceholder = <StackingMultipleItems data={draggedProducts} />;
	}

	/**
     * Change icon size
     * 
     * @param {React.ChangeEvent<HTMLSelectElement>} ev
     * @param {SelectProps} props
     * @returns {void}
     */
	const setIconSizeHandler = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>, _props: SelectProps) => {
		setIconSize(ev.currentTarget.value);
	}, []);

	/**
     * Change sorting
     * 
     * @param {React.ChangeEvent<HTMLSelectElement>} ev
     * @param {SelectProps} props
     * @returns {void}
     */
	const sortingChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>, _props: SelectProps) => {
		const updatedInherit = ev.currentTarget.value === SORTING.inherit;

		if(updatedInherit) {
			//If updatedInherit is true, update products to be sorted correctly
			setIsLoading(true);
            
			axios
				.get(
					`/modules/products/sort/index/${categoryId}?inherit=${inherit}`
				)
				.then(({ data }) => {
					updateState({
						data:{
							inherit: {
								$set: updatedInherit
							},
							products: {
								$set: data
							}
						}
					});
				
					setIsLoading(false);
				})
				.catch(() => {
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title:'Ett fel har uppstått, prova igen',
						children: 'Det gick inte att sortera produkter.'
					});
					setIsLoading(false);
				});
			
		} else {
			updateState({
				data:{
					inherit: {
						$set: updatedInherit
					}
				}
			});
		}
		
		//If there is selected products, unselect them
		if(selectedProducts.length > 0) setSelectedProducts([]);
	}, [categoryId, inherit, notification, selectedProducts.length, updateState]);

	/**
     * Change order of products when typing into its action bar input field and pressing 'Enter'
     * 
     * @param {React.KeyboardEvent<HTMLInputElement>} ev
     * @param {ImageThumbnailProps} item
     * @param {HTMLInputElement} inputRef
     * @returns {void}
     */
	const itemOrderChangedByTypingHandler = React.useCallback((ev: React.KeyboardEvent<HTMLInputElement>, item: ImageThumbnailProps, _inputRef: HTMLInputElement) => {
		if(ev.key === 'Enter') {
		    const productCurrentIndex = products.findIndex(
		        (product) => product.id === item.id
		    );

		    const product = products[productCurrentIndex];

		    const amountOfProducts = products.length;

		    let newIndex = parseInt(ev.currentTarget.value);

			//If the new index is larger than the amount of products, place the product last in the list
		    if(newIndex > amountOfProducts) newIndex = amountOfProducts - 1;

			//If the new index is smaller than 0, place the product first in the list
		    if(newIndex < 0) newIndex = 0;

		    updateState({
		        data:{
		            products: {
		                $splice: [
		                    [productCurrentIndex, 1],
		                    [newIndex, 0, product]
		                ]
		            }
		        }
		    });
		}
	}, [products, updateState]);

	/**
     * Remove a product from selectedProducts
     * 
     * @param {number} productId 
     * @returns {void}
     */
	const removeSelectedProduct = React.useCallback((productId: number) => {
		const index = selectedProducts.indexOf(productId);
		const suggestedState = update(selectedProducts, {
			    $splice: [[index, 1]] 
		});
		setSelectedProducts(suggestedState);
	}, [selectedProducts]);

	/**
	 * Remove product by adding 'deleted: true' to the product in currentState
	 *
	 * @param {ImageThumbnailProps} product 	
   * @returns {void}	
	 */
	const productRemovedHandler = React.useCallback((selectedProduct: ImageThumbnailProps) => {
		//Select all filters except from the selected product's filters
		const updatedFilters = products.reduce((prev, item) => {
			if(item.id === selectedProduct.id || item.deleted || !item.enabled || !item.ready) return prev;

			prev = {
				...prev,
				...item.filters
			};

			return prev;
		}, {});

		//Select all filter names to update the currentSate's filter_names
		const updatedFilterNames = Object.keys(updatedFilters)
			.reduce((prev, filter) => {
				const filterKey = filter as keyof FilterNamesType;

				return Object.assign(prev, { [filterKey]: allFilterNames[filterKey] });
			}, {} as FilterNamesType);

		const selectedIndex = products.findIndex(
			(product) => product.id === selectedProduct.id
		);

		const product = products[selectedIndex];

		//If the product has the 'added'-flag we remove the flag,
		//but we still have to keep the product in the state in case the page has been saved (and not reloaded) 
		//after the product got added and before removing the same product again
		updateState({
			data:{
				products: {
					[selectedIndex]: {
						$set: update(product, {
							$unset: ['added'],
							$merge: {
								deleted: true
							}
						})
					}
				},
				filter_names: {
					$set: updatedFilterNames
				}
			}
		});

		//If the product was selected, remove it from SelectedProducts
		if(selectedProducts.includes(product.id)) removeSelectedProduct(product.id);
		
	}, [allFilterNames, products, removeSelectedProduct, selectedProducts, updateState]);

	/**
	 * Show a modal containing the selected product's settings
	 *
	 * @param {ImageThumbnailProps} selectedItem
     * @returns {void}
	 */
	const showProductSettings = React.useCallback((selectedItem: ImageThumbnailProps) => {
		const selectedProduct = products.find((product) => product.id === selectedItem.id);

		const saveProduct = (currentState: ProductDetailsState, closeModal: () => void) => {
			setModalIsSaving(true);
			if(!('number' in currentState.product) || !categoryId) return; 
			
			axios
				.put(`/modules/products/${currentState.product.number}`, {
					...currentState.product,
					category_id: categoryId
				})
				.then(() => {
					const productIndex = products.findIndex((product) => product.number === currentState.product.number);

					const updatedFilters = products.reduce((prev, item) => {
					//Don't get filters of products that are disabled or not marked as ready
						if((item.id === currentState.product.id && (currentState.product.base.status === 'disable' || !currentState.product.settings.ready)) 
                    || (item.id !== currentState.product.id && (!item.enabled || !item.ready))) return prev;
                    
						prev = {
							...prev,
							...item.filters
						};

						return prev;
					}, {});

					//Select all filter names to update the category-modal's currentSate's filter_names
					const updatedFilterNames = Object.keys(updatedFilters)
						.reduce((prev, filter) => {
							const filterKey = filter as keyof FilterNamesType;

							return Object.assign(prev, { [filterKey]: allFilterNames[filterKey] });
						}, {} as FilterNamesType);
    
					//When the product is saved update the BlockCategorySettings's state with the updated product
					updateState({
						data:{
							products: {
								[productIndex]: {
									thumbnail: {
										$set: currentState.product.primary_image.thumbnail
									},
									enabled: {
										$set: currentState.product.base.status !== 'disable'
									}
								},
								//Check if the updated product still is included in the current category, 
								//otherwise remove it from the state
								$splice: [
									!currentState.product.categories.includes(categoryId)
										? [productIndex, 1]
										: [productIndex, 0]
								]
							},
							filter_names: {
								$set: {
									...updatedFilterNames
								}
							}
						}
					});
					setModalIsSaving(false);
					closeModal();
                    
				})
				.catch((err) => {
					console.error(err);
					setModalIsSaving(false);
				});
			
		};

		productDetailModal.open({
			width: '80%',
			height: '80%',
			position: 'center',
			style: 'overflow: hidden;',
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						originalState: ProductDetailsState, 
						currentState: ProductDetailsState, 
						closeModal: () => void
					) => {
						closeModal();
					}
				},
				{
					text: 'Spara',
					action: (
						originalState: ProductDetailsState, 
						currentState: ProductDetailsState, 
						closeModal: () => void
					) => {
						formValidation.submit(() => {
							saveProduct(currentState, closeModal);
							closeModal();
						});				
					}
				}
			],
			state: {
				category_id: categoryId,
				product: {
					...selectedProduct
				}
			}
		});
	}, [allFilterNames, categoryId, formValidation, productDetailModal, products, updateState]);

	/**
   * Generate actions which are used by ImageThumbnail to display in action bar on hover
   * 
   * @returns {ImageThumbnailActionsType[]}
   */
	const generateProductAction = React.useMemo((): ImageThumbnailActionsType[] => [
		{
			component: <Icon
				color={'#fafafa'}
				icon={['fal', 'gear']}
			           />,
			action: showProductSettings
		},
		{
			component: (
				<ScInput
					type="number"
					isDisabled={inherit}
					isBright
					omitLabel={true}
				/>
			),
			// This will hook a function to trigger 'action' when onChange.
			type: 'input',
			action: itemOrderChangedByTypingHandler
		},
		{
			component: <Icon
				color={'#fafafa'}
				icon={['fal', 'trash']}
			           />,
			action: productRemovedHandler,
			styles: {
				hover: {
					backgroundColor: '#DA534D'
				}
			}
		}
	], [inherit, itemOrderChangedByTypingHandler, productRemovedHandler, showProductSettings]);

	/**
     * Select/Unselect item when clicked
     * 
     * @param {React.MouseEvent<HTMLElement>} ev
     * @param {ImageThumbnailProps} item
     * @returns {void}
     */
	const itemSelectedHandler = React.useCallback((_ev: React.MouseEvent<HTMLElement>, item: ImageThumbnailProps) => {
		if(!selectedProducts.includes(item.id)) {
			//If item isn't already selected, add its id to the array
			const suggestedState = update(selectedProducts, {
				$push: [item.id]
			});
			setSelectedProducts(suggestedState);

		} else {
			removeSelectedProduct(item.id);
		}
	}, [removeSelectedProduct, selectedProducts]);

	/**
	 * Updates the positions of the product when it's dropped on a horizontal position
	 *
	 * @param {DraggableSource<DraggableProductItem>} source -> the dragged item
	 * @param {DraggableDestination<DraggableProductItem>} destination -> the hovered item displaying a valid drop zone
	 * @param {DraggableInstruction} instruction -> additional instructions regarding the drop
     * @returns {void}
	 */
	const dragEndHandler = (source: DraggableSource<DraggableProductItem>, destination: DraggableDestination<DraggableProductItem>, instruction: DraggableInstruction) => {
		if(!source || !destination) return;

		const sourceIndex = source.properties.index;
		const itemObject = products[sourceIndex];
		let destinationIndex = +destination.properties.index;

		switch(instruction.position) {
			case 'right':
				if(sourceIndex > destinationIndex)
					destinationIndex =
						destinationIndex + instruction.adjustIndex;
				break;

			default:
				if(sourceIndex < destinationIndex)
					destinationIndex = destinationIndex - 1;
		}
        
		if(destinationIndex === sourceIndex) return;

		let suggestedState = update(products, {
			$splice: [
				[sourceIndex, 1],
				[destinationIndex, 0, itemObject]
			]
		});

		if(selectedProducts.length > 1) {
			const itemsToBeMoved = products.filter((item) => selectedProducts.includes(item.id));

			const stateWithoutAffectedItems = update(products, {
				$apply: (items: StrippedCategoryProduct[]) => items.filter((item) => !selectedProducts.includes(item.id))
			});

			// As the elements have been removed the destinationIndex is likely to have changed
			// Thus, find the new index according to the destination product's id
			destinationIndex = stateWithoutAffectedItems.findIndex(item => item.id === destination.properties.id);

			// If the destination is one of the selected products, it wont't be found in stateWithoutAffectedItems 
			// and destinationIndex will be equal to -1
			if(destinationIndex === -1) return;
            
			// If the drop occurs at the right side of an element
			// Then adjust the index by + 1
			if(instruction.position === 'right') {
				destinationIndex = destinationIndex + 1;
			}

			suggestedState = update(stateWithoutAffectedItems, {
				$splice: [[destinationIndex, 0, ...itemsToBeMoved]]
			});
		}

		itemOrderChangedByDraggingHandler(suggestedState);
	};

	/**
	 * Update state when order is changed by drag and drop.
	 *
	 * @param {StrippedCategoryProduct[]} suggestedState -> The new suggested state with the order updated.
   * @returns {void}
	 */
	const itemOrderChangedByDraggingHandler = React.useCallback((suggestedState: StrippedCategoryProduct[]) => {
		setSelectedProducts([]);
		updateState({
			data: {
				products: {
					$set: suggestedState
				}
			}
		});
	}, [updateState]);
 
	/**
     * Update the state's inactive_filters array when filter checkboxes is clicked
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} ev
     * @param {CheckItemProps} props
     * @returns {void}
     */
	const filterChangedHandler = React.useCallback((_ev: React.ChangeEvent<HTMLInputElement>, props: CheckItemProps) => {
		const targetFilter = props.name as keyof FilterNamesType;
		let suggestedState = update(inactiveFilters, {
			$push: [targetFilter]
		});
		
		//If the target filter already is included in inactive_filters, remove it from the array
		if(inactiveFilters.includes(targetFilter)) {
			const index = inactiveFilters.indexOf(targetFilter);
			suggestedState = update(inactiveFilters, {
			    $splice: [[index, 1]] 
			});
		}

		updateState({
			data: {
				inactive_filters: {
					$set: suggestedState
				}
			}
		});

	}, [inactiveFilters, updateState]);

	/**
	 * Toggle the state's enabled value
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} ev
	 * @param {CheckItemProps} props
	 * @returns {void}
	 */
	const toggleEnabledHandler = React.useCallback((_ev: React.ChangeEvent<HTMLInputElement>, _props: CheckItemProps): void => {
		updateState({
			data: {
				enabled: {
					$set: !enabled
				}
			}
		});
	}, [enabled, updateState]);
	
	/**
	 * When selecting a product from a search result this handler will update the currentState
	 *
	 * @param {CompleteCategoryProduct} selectedProduct
     * @returns {void}
	 */
	const selectSearchResultHandler = React.useCallback((selectedProduct: CompleteCategoryProduct) => {
		if('added' in selectedProduct) return;
        
		const productIndex = products.findIndex((product) => product.id === selectedProduct.id);
		let updatedItem = null;
		let addedFilters = {};

		//if product isn't disabled and marked as ready
		if(selectedProduct.status !== 'disable' && selectedProduct.product.settings.ready) {
			//Select the filters of the selected product that doesn't already exist in the currentState's filters 
			addedFilters = Object.keys(selectedProduct.product.filters)
				.filter((filter) => !(filter in filterNames))
				.reduce((prev, filter): ProductFilterType => {
					const filterKey = filter as keyof FilterNamesType;
					return Object.assign(prev, { [filterKey]: allFilterNames[filterKey] });
				}, {} as ProductFilterType);
		}

		//If the product already exists in the currentState
		if(productIndex !== -1) {
			const product = products[productIndex];

			//If the existing product is flagged as deleted
			if('deleted' in product) {
				return updateState({
					data: {
						products: {
							[productIndex]: {
								//remove the 'deleted' flag
								$set: update(product, {
									$unset: ['deleted'],
									//Add the 'added' flag just in case the page got saved but not reloaded after deleting the product
									//(which means the product will remain in the currentState but be removed in backend)
									$merge: { added: true }
								})
							}
						},
						filter_names: {
							$set: {
								...filterNames,
								...addedFilters
							}
						}
					}
				});
			}
			//If the product already exists and isn't flagged as deleted
			return;
		}

		updatedItem = update(selectedProduct, {
			$merge: {
				enabled: selectedProduct.status !== 'disable', // temp fix, backend removed property
				added: true
			}
		});

		updateState({
			data: {
				products: {
					$push: [updatedItem]
				},
				filter_names: {
					$set: {
						...filterNames,
						...addedFilters
					}
				}
			}
		});
	}, [allFilterNames, filterNames, products, updateState]);

	// product search hook instantiated
	const productSearch = useProductSearch(
		selectSearchResultHandler,
		null,
		false
	);
	
	return (
		<>
			{productDetailModal.getAsComponent(
				<ProductDetails
					formValidation={formValidation}
					isSaving={modalIsSaving}
				/>
			)}
			<ScHeadersWrapper>
				<ScHeader>
					<ScFilterItem>
						<Select
							changed={setIconSizeHandler}
							value={iconSize}
							isDisabled={products.length === 0}
						>
							<option value={ICON_SIZE.regular}>
								Stora ikoner
							</option>
							<option value={ICON_SIZE.small}>
								Små ikoner
							</option>
						</Select>
					</ScFilterItem>
					<ScFilterItem style={{ flex: 1 }}>
						{productSearch()}
					</ScFilterItem>
				</ScHeader>
				<ScHeader>
					<ScSelectWrapper>
						<Select
							label="Sortering"
							changed={sortingChangedHandler}
							value={inherit ? SORTING.inherit : SORTING.custom}
							isDisabled={isLoading}
						>
							<option value={SORTING.inherit}>
								Ärv från närmast förälder
							</option>
							<option value={SORTING.custom}>
								Anpassad
							</option>
						</Select>
						<ScCheckboxWrapper>
							<CheckItem
								changed={toggleEnabledHandler}
								checked={enabled}
								type="checkbox"
								name="enabled"
								title="Aktiverad"
							/>
						</ScCheckboxWrapper>
					</ScSelectWrapper>
					<div>
						<ScLabel>
							Aktiva filter
						</ScLabel>
						<ScFilterWrapper>
							{Object.keys(filterNames).map(filterName => {
								const filterNameKey = filterName as keyof FilterNamesType;
								return (
									<ScCheckbox
										key={`filter-${filterName}`}
										type="checkbox"
										title={filterNames[filterNameKey]}
										name={filterName}
										checked={!inactiveFilters.includes(filterName)}
										changed={filterChangedHandler}
									/>
								);
							})}
						</ScFilterWrapper>
					</div>
				</ScHeader>
			</ScHeadersWrapper>
            
			<DragDrop
				scope="category-settings"
				dragPlaceholder={dragPlaceholder}
				onDragEnd={dragEndHandler}
			>
				<GridView
					isLoading={products.length === 0 || isLoading}
				>
					{/* Add additionalLayers-property to products and filter out deleted products before iterating */}
					{productsWithAdditionalLayers(ignoreDeletedProducts(products)).map((product, index) => {
						return (
							<ScDraggable
								id={product.id}
								key={`gridItem_${product.id}`}
								index={index}
								scope="category-settings"
								isHandle={true}
								allowedDirection="horizontal"
								size={iconSize}
								enableDrag={!inherit}
							>
								<ImageThumbnail
									key={index}
									id={product.id}
									thumbnail={product.thumbnail}
									isSelected={selectedProducts.includes(product.id)}
									disabledItemsWithActionBar={inherit}
									width="100%"
									actions={generateProductAction}
									index={index}
									clicked={inherit ? undefined : itemSelectedHandler}
									isDisabled={inherit}
									description={product.name}
								>
									{(product.additionalLayers && product.additionalLayers.length > 0) ? renderAdditionalLayer(product.additionalLayers) : ''}
								</ImageThumbnail>
							</ScDraggable>
						);
					})}
				</GridView>
			</DragDrop>
		</>
	);
};

/**
 * Returns a component with additional layers
 * 
 * @param {string[]} productLayers 
 * @returns {JSX.Element}
 */
const renderAdditionalLayer = (productLayers: string[]): JSX.Element => {
	const isDisabled = productLayers.includes('isDisabled');
	const badge = productLayers.filter(badge => badge !== 'isDisabled')[0];

	return (
		<ScAdditionalLayer>
			{isDisabled && (
				<ScStatusWrapper>
					<ScStatus>
						<Icon icon={['fal', 'eye-slash']} />
					</ScStatus>
				</ScStatusWrapper>
			)}
			{badge && <ScBadge bgr={badge} />}
		</ScAdditionalLayer>
	);
};

/**
* Return products with an additional property called 'additionalLayers' 
* 
* @param {StrippedCategoryProduct[]} products
* @returns {StrippedCategoryProduct[]}
*/
const productsWithAdditionalLayers = (products: StrippedCategoryProduct[]): StrippedCategoryProduct[] => {
	return products.map((product) => {
		let layers: string[] = [];

		//Add "isDisabled" to layers-array if the product is not enabled
		if(!product.enabled) {
			layers = ['isDisabled'];

		}
		
		if(product.badge) {
			layers = [...layers, product.badge];
		}

		//Update the product with a property called additionalLayers which holds the array with layers
		return update(product, {
			additionalLayers: { $set: layers.length === 0 ? null : layers }
		});
	});
};

/**
* Return an array with products that doesn't have the 'deleted' flag
* 
* @param {StrippedCategoryProduct[]} products 
* @returns {StrippedCategoryProduct[]}
*/
const ignoreDeletedProducts = (products: StrippedCategoryProduct[]) => {
	return products.filter((product) => !('deleted' in product));
};

export default BlockCategorySettings;

const ScHeader = styled.div`
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`;

const ScHeadersWrapper = styled.div`
	padding: 16px 32px 0;
	top: 16px;
	z-index: 999;
    background-color: #fff;
`;

const ScFilterItem = styled.div`
	margin: 16px 16px 16px 0;

	:last-child {
		margin-right: 0;
	}
`;

const ScDraggable = styled(Draggable)`
	display: block;
	position: relative;
	margin-bottom: 16px;
	width: ${(props) => (props.size === 'small' ? '88px' : '136px')};
`;

const ScInput = styled(TextInput)`
	height: 24px;
	border: 1px solid #fafafa;
	background: transparent;
	color: #fafafa;
	font-size: 12px;
	text-align: center;
	padding: 4px;
`;

const ScLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 4px;
`;

const ScFilterWrapper = styled.div`
	display: flex;
	align-items: flex-start;
`;

const ScCheckbox = styled(CheckItem)`
	margin-left: 8px;

	&:first-child {
		margin-left: 0;
	}
`;

const ScBadge = styled.div`
	${(props: ScBadgeProps) => props.bgr && `background: url(${props.bgr}) 0 center no-repeat;`}
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 56px;
	height: 32px;
`;

const ScAdditionalLayer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const ScSelectWrapper = styled.div`
	display: flex;
	align-items: flex-end;
`;

const ScCheckboxWrapper = styled.div`
	margin: 0 0 24px 16px;
`;