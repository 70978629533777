import { useDragDrop } from '../../hooks/useDragDrop';
import { getElementExtendedBounds } from './DragDropUtils';

const MouseOverHandler = (ev, callback, props) => {
	ev.stopPropagation();

	const [state, dispatch] = useDragDrop();

	// If a draggedItem has not yet been set then ignore the move handler
	if (!('draggedItem' in state)) return;

	// Get the DOM element that we are hovering
	const hoveredElement = ev.currentTarget;

	// Get the DOM element bounds + offsetLeft and offsetTop
	const elementData = getElementExtendedBounds(hoveredElement);

	// Create a representation of the hovered item
	const hoveredItem = {
		scope: props.scope,
		hoveredElement: hoveredElement,
		hoveredData: elementData,
		properties: props,
	};

	// Store the hovered object to the state
	dispatch('IS_HOVERED', hoveredItem);

	// Call the provided callback function that were defined in DragDrop as a property (onDragOver)
	if (typeof callback === 'function')
		callback(state.draggedItem, hoveredItem, ev);
};

export default MouseOverHandler;
