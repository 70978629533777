import React from 'react';
import styled, { css } from 'styled-components/macro';
import { CheckItem } from '../../../components/Forms';
import { Icon, Tooltip } from '../../../components/UI';

const ProductImage = (props) => {
	// const selectedColor = props.colors[props.image.true_color];
	const trueColor = props.image.true_color;
	const selectedColor = Object.values(props.colors).find(
		(color) => color.code === trueColor || color.color_code === trueColor
	);

	const colors = Object.values(props.availableColors).map((color) => {
		let output = color.code;
		let isPattern = false;

		if ('pattern' in color) {
			if ('props' in color.pattern) {
				output = color.pattern.props.name;
				isPattern = true;
			}
		}
		if (isPattern) {
			return (
				<option
					key={`color-option-${color.code}`}
					data-pattern
					value={color.code}
				>
					Färg: {output}
				</option>
			);
		}

		return (
			<option key={`color-option-${color.code}`} value={color.code}>
				Färg: {output}
			</option>
		);
	});

	let selectedColorName = '';

	if (selectedColor) {
		selectedColorName = selectedColor.code;

		if ('pattern' in selectedColor) {
			selectedColorName = selectedColor.pattern.props.name;
		}
	}

	return (
		<ScWrapper>
			<ScContainer>
				<CheckItem
					type="radio"
					uuid={props.uuid}
					name="primary_image"
					changed={props.changed}
					style={{ marginBottom: 0 }}
					checked={props.image.main}
					fullImage={props.image}
				/>
				<ScImageWrapper
					isOutOfStock={props.isOutOfStock}
					url={props.image.uri}
				></ScImageWrapper>
				{Object.keys(props.colors).length > 1 && (
					<ScColorDot
						dotColor={selectedColor ? selectedColor.hex : ''}
					>
						<ScHiddenSelect
							onChange={(ev) =>
								props.changedColor(ev, props.uuid)
							}
							value={selectedColor ? selectedColor.code : -1}
						>
							{selectedColor && (
								<option value={selectedColor.code}>
									Färg: {selectedColorName}
								</option>
							)}
							{colors}
							{(!props.image.main || !selectedColor) && (
								<option value={-1}>
									{selectedColor
										? 'Koppla bort färg'
										: 'Färg ej kopplad'}
								</option>
							)}
						</ScHiddenSelect>
						<ScArrow>
							<Icon icon={['fal', 'angle-down']} />
						</ScArrow>

						{!selectedColor && (
							<ScNotConnected>
								<Icon icon={['fas', 'exclamation']} />
							</ScNotConnected>
						)}
					</ScColorDot>
				)}

				<ScRemove
					onClick={() =>
						!props.image.main
							? props.removed(props.type, props.uuid)
							: void 0
					}
				>
					{props.image.main && (
						<ScTooltip
							isOver
							text="För att radera denna välj en annan huvudbild"
						>
							<ScIcon icon={['fal', 'trash']} />
						</ScTooltip>
					)}

					{!props.image.main && <ScIcon icon={['fal', 'trash']} />}
				</ScRemove>
			</ScContainer>
			<ScName>{props.image.name}</ScName>
		</ScWrapper>
	);
};

export default ProductImage;

const ScWrapper = styled.div`
	margin-right: 8px;

	&:last-child {
		margin-right: 0;
	}
`;

const ScContainer = styled.div`
	display: flex;
	border: 1px solid #c4c4c4;
	padding: 8px;
	justify-content: space-between;
	align-items: center;
`;

const ScImageWrapper = styled.div`
	position: relative;
	width: 56px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 8px;
	background: url(${(props) => props.url}) no-repeat center;
	background-size: contain;

	${(props) =>
		props.isOutOfStock &&
		css`
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 28px;
				right: 0;
				width: 2px;
				height: 88%;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				background: #333;
			}
		`}
`;

const ScColorDot = styled.div`
	width: 24px;
	height: 24px;
	background: ${(props) => props.dotColor || 'transparent'};
	border-radius: 100%;
	position: relative;
	border: 4px solid #c4c4c4;
	box-sizing: content-box;
	margin-right: 24px;
`;

const ScHiddenSelect = styled.select`
	appearance: none;
	opacity: 0;
	background: transparent;
	width: 48px;
	position: absolute;
	top: -4px;
	left: -4px;
	right: -4px;
	bottom: -4px;
	cursor: pointer;
`;

const ScNotConnected = styled.div`
	font-size: 14px;
	color: #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const ScArrow = styled.div`
	position: absolute;
	width: 18px;
	height: 18px;
	left: 24px;
	top: 3px;
	pointer-events: none;
	background: #c4c4c4;
	border-radius: 0px 24px 24px 0px;
	color: #fafafa;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScName = styled.div`
	font-weight: 300;
	font-size: 10px;
	margin: 4px 0 8px;
	text-align: center;
`;

const ScIcon = styled(Icon)`
	font-size: 16px;
`;

const ScRemove = styled.div`
	position: relative;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	cursor: pointer;
`;

const ScTooltip = styled(Tooltip)`
	cursor: not-allowed;
`;
