import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Icon } from './UI';

/**
 *
 * @param {axis} string On what axis to split and place the slider, 'vertical' (Top -> Bottom) or 'horizontal' (Left -> Right).
 */
const SplitSlider = ({ axis = 'vertical', children }) => {
	if(axis !== 'vertical' && axis !== 'horizontal')
		throw new Error(
			`The axis/orientation '${axis}' is invalid, use 'vertical' or 'horizontal'`
		);

	const sideAComponent = children[0];
	const sideBComponent = children[1];

	const sideASize = sideAComponent.props.size || 50;
	const sideBSize = sideBComponent.props.size || 100 - sideASize || 50;

	const [state, setState] = useState({
		/*
		 * size:        The height/width in %,      defaults to 50%.
		 * minSize:  	Lowest height/width in %,   defaults to 40%
		 */
		sideA: {
			size: sideASize,
			minSize: sideAComponent.props.minSize || sideASize
		},
		sideB: {
			size: sideBSize,
			minSize: sideBComponent.props.minSize || sideBSize
		},

		wrapperRef: React.createRef(),
		sliderRef: React.createRef(),
		mouseYPos: 0
	});

	/**
	 * Takes care of actually moving the Slider and resizing panes when the mouse is moved.
	 *
	 * @param ev
	 */
	const onMouseMove = (ev) => {
		/**
		 * IMPORTANT!!! Read before continuing
		 *
		 * With 'size' i mean height/top or width/left depending is we use vertical(Y axis) or horizontal(X axis) mode.
		 */

		// used to know in that axis we are working on, mostly to skip this same comparison multiple times.
		const useVerticalAxis = axis === 'vertical';

		// scSplitWrapper info about size and position in the viewport.
		const wrapperBounding = state.wrapperRef.current.getBoundingClientRect();

		/**
		 * Amount in pixels the wrapper's position is from the viewport.
		 */
		const wrapperOffsetFromViewport = useVerticalAxis
			? wrapperBounding.top
			: wrapperBounding.left;

		/**
		 * all measures in this equation are in pixels.
		 */
		const sliderSize = useVerticalAxis
			? state.sliderRef.current.getBoundingClientRect().height
			: state.sliderRef.current.getBoundingClientRect().width;

		/**
		 *  Calculate the position of the cursor that is relative to the viewport to relative to the wrapper.
		 * 		Technically this makes so we use the top-left edge of the wrapper as starting point or position '0' for the X and Y.
		 *
		 * mousePos	(ev.clientY/X)		The position of the mouse cursor relative to the viewport
		 * wrapperOffsetFromViewport	Distance in pixels from the top-left edge of the screen to the to to-left of the wrapper.
		 *
		 */
		const mousePosRelativeToWrapperEdge =
			(useVerticalAxis ? ev.clientY : ev.clientX) -
			wrapperOffsetFromViewport;

		/**
		 * Calculate the size of side A in pixels, taking into account is we use horizontal or vertical mode.
		 *
		 * mousePosRelativeToWrapperEdge	Take into account the new starting or 0 point for the axis, See variable comment.
		 * sliderSize						As the slider itself does take space from as it lies inside the wrapper and between sides, we need to taken it into account to not get more than 100% totally in our calculations.
		 * /2 								Number of "ScSplitSide" or sides the slider manipulates, always 2
		 * 										This one is needed so we can distribute the space the slider takes, between side A and B, otherwise one side would be visually smaller than the over even is they take same space in % as the slider ocupates that diference in.
		 */
		const sideASizeInPixels =
			mousePosRelativeToWrapperEdge - sliderSize / 2;

		/**
		 * Convert side A from pixels to percent.
		 *
		 * sideASizeInPixels				See comemnts in variable
		 * wrapperBounding.height/width		The space the wrapper uses in the viewport (This is our '100%' or absolute as one side can't get bigger than the wrapper itself)
		 * *100								To calculate the percent
		 */
		const sizeAinPercent =
			(sideASizeInPixels /
				(useVerticalAxis
					? wrapperBounding.height
					: wrapperBounding.width)) *
			100;

		/**
		 * Calculate size of side B (the left overs of the space side A uses.)
		 */
		const sizeBinPercent = 100 - sizeAinPercent;

		/**
		 * Apply limits so we can't move the slider and make a side bigger/smaller than the user whants.
		 */
		if(
			sizeAinPercent <= state.sideA.minSize ||
			sizeBinPercent <= state.sideB.minSize
		)
			return;

		// updates state
		setState({
			...state,
			sideA: { ...state.sideA, size: sizeAinPercent },
			sideB: { ...state.sideB, size: sizeBinPercent },
			mouseYPos: ev.clientY
		});
	};

	/**
	 * Detect when the mouse was clicked down in the slider.
	 *
	 * @param ev
	 */
	const onSliderMouseDown = (ev) => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	};

	/**
	 * Detect when the mouse was released from the slider.
	 *
	 * @param ev
	 */
	const onMouseUp = (ev) => {
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseup', onMouseUp);
	};

	return (
		<ScSplitWrapper
			axis={axis}
			ref={state.wrapperRef}
		>
			<SplitSide
				axis={axis}
				size={state.sideA.size}
			>
				{sideAComponent}
			</SplitSide>

			<ScSlider
				axis={axis}
				ref={state.sliderRef}
				topPos={state.mouseYPos}
				// draggable={true}
				onMouseDown={onSliderMouseDown}
			>
				<ScSliderHandle>
					<Icon icon={['fal', 'arrows-left-right']} />
				</ScSliderHandle>
				<ScSliderLine axis={axis} />
			</ScSlider>

			<SplitSide
				axis={axis}
				size={state.sideB.size}
			>
				{sideBComponent}
			</SplitSide>
		</ScSplitWrapper>
	);
};

const ScSplitWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	user-select: none;
	${(props) =>
		props.axis === 'horizontal' &&
		`
			display: flex;
		`}
`;

const ScSliderHandle = styled.div`
	height: 24px;
	width: 24px;
	position: absolute;
	top: calc(50% - 12px);
	left: -10px;
	border: 1px solid var(--border-grey-color);
	border-radius: 40px;
	background: #fafafa;
	z-index: 99;
	justify-content: center;
	display: flex;
	align-items: center;
	color: var(--border-grey-color);
	cursor: ew-resize;
`;

const SplitSide = styled.div.attrs(({ axis, size }) => ({
	style: {
		height: axis === 'vertical' ? `${size}%` : '100%',
		width: axis === 'horizontal' ? `${size}%` : '100%'
	}
}))`
	position: relative;
`;

const ScSlider = styled.div`
	position: relative;
	z-index: 99;

	${(props) =>
		props.axis === 'vertical' &&
		`
			height: 1px;
			width: 100%;
		`}

	${(props) =>
		props.axis === 'horizontal' &&
		`
			// height: 100%;
			width: 1px;
		`}
`;

const ScSliderLine = styled.hr`
	position: relative;

	${(props) =>
		props.axis === 'vertical' &&
		`
			height: 1px;
			width: 100%;
			border-top: 1px dashed #aaa;
			top: 50%;
		`}

	${(props) =>
		props.axis === 'horizontal' &&
		`
			height: 100%;
			width: 1px;
			border-left: 1px dashed #aaa;
			left: 50%;
		`}
`;

export default SplitSlider;
export { SplitSide };
