import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { SeoFilters, SeoPages } from '../containers/Seo.types';
import axios from '../../../utils/oc-axios';

const useSeoPages = (params: SeoFilters) => {

	/**
     * Fetch events data
     * 
     * @param {AbortSignal | undefined} signal 
     * @returns {Promise<SeoPages>}
     */
	const fetchSeoPages = React.useCallback(async ({ signal } : { signal?: AbortSignal }): Promise<SeoPages> => {
		try {
			return axios.get('/modules/seo/pages', {
				params,
				signal
			}).then(res => res.data);
		} catch(error) {
			throw new Error('Det gick inte att hämta sidorna');
		}
		
	}, [params]);

	// Holds a useQueryResult with SEO pages data
	const query = useQuery(['seo-pages', params], fetchSeoPages, {
		refetchOnWindowFocus: false
	});

	return query;
};

export default useSeoPages;

