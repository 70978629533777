import axios from '../../utils/oc-axios';
import update from 'immutability-helper';

import {
	storeAllNavigation,
	storeAllTemplates,
	removeNavigationAction,
	duplicateNavigationAction,
	duplicateNavigationFolderAction,
	updateNavigationItems,
} from '../actions/action-cache';
import { storeAllNavigationsTypes } from '../actions/action-cache';
import { navigationWithPublishIcon } from '../../utils/publishDateHelper';

/**
 * How many seconds before re-fetching data
 */
const CACHE_TTL = 120;

/**
 * Return the current unix timestamp in seconds.
 */
const currentTimestamp = () => Math.round(new Date().getTime() / 1000);

// -----------------------------------
// --------- Pages

/**
 * Fetch a page's info and store in store
 *
 * @param {string(UUIDv4)} uuid     The page's uuid
 */
export const fetchPage = (uuid) => {
	return async () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`navigations/settings/pages/${uuid}`)
				.then(({ data }) => {
					if(data.error) reject(new Error(data.message));

					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
};

/**
 * Update a page's data by it's uuid
 *
 * @param {string(UUIDv4)} uuid 	The page's uuid to update (the page not the uuid x_x)
 * @param {object} updatedPage		The page's complete object with the new data already updated.
 */
export const updatePage = (uuid, updatedPage) => {
	return async (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			// Get the current state
			const navItems = getState().cache.navigation.items;

			const item = Object.values(navItems).find(
				(item) => item.uuid === uuid
			);

			// Create new items state with the update navigation object.
			const updatedState = update(navItems, {
				[item.navigation_id]: {
					$merge: updatedPage,
				},
			});

			// Update page's data in back-end.
			axios
				.put(`navigations/settings/pages/${uuid}`, updatedPage)
				.then(({ data }) => {
					// Update navigation in Redux.
					dispatch(updateNavigationItems(updatedState));

					resolve(data);
				})
				.catch((err) => {
					console.error('[thunk-cache]', err);
					reject(err);
				});
		});
	};
};

/**
 * Duplicate a page by it's uuid
 *
 * @param {string(UUIDv4)} pageUUID 	The page's uuid to duplicate
 * @param {string(UUIDv4)} navID 		The page's navigation id to duplicate
 */
export const duplicatePage = (pageUUID, navID) => {
	return async (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`/navigations/items/duplicate/${navID}`)
				.then(({ data }) => {
					dispatch(
						duplicateNavigationAction(
							data.nav_items[data.new_nav_item_id],
							navID
						)
					);

					resolve(data);
				});
		});
	};
};

// -----------------------------------
// --------- Navigation

/**
 * Fetch the complete navigation tree
 */
export const fetchAllNavigations = (disableCache) => {
	return async (dispatch, getState) => {
		const now = currentTimestamp();
		const cache = getState().cache.navigation;
		const navItems = cache.items;

		return new Promise((resolve, reject) => {
			// Only fetch if empty
			if (
				Object.keys(navItems).length === 0 ||
				now - cache.updated > CACHE_TTL ||
				disableCache === true
			) {
				axios
					.get('navigations/trees')
					.then(({ data }) => {
						dispatch(
							storeAllNavigation(navigationWithPublishIcon(data))
						);
						resolve(data);
					})
					.catch((err) => {
						console.error('[thunk-cache]', err);
						reject(err);
					});
			} else {
				resolve(navItems);
			}
		});
	};
};

/**
 * Duplicate a folder by it's navigation <id
 *
 * @param {string(UUIDv4)} navID 		The nav item of type folder's id to duplicate
 */
export const duplicateNavigationFolder = (navID) => {
	return async (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`/navigations/items/duplicate/${navID}`)
				.then(({ data }) => {
					resolve(data);

					dispatch(
						duplicateNavigationFolderAction(
							navID,
							data.nav_items[data.new_nav_item_id],
							data.nav_items
						)
					);
				})
				.catch((err) => {
					console.error('[thunk-cache]', err);
					reject(err);
				});
		});
	};
};

/**
 * Send a page to trashbin/remove by it's identifier (uuid)
 *
 * @param {int} 			itemID		The id of the navitem
 * @param {int} 			parentID   	The id of the navitems's parent
 */
export const removeNavigationItem = (navID, parentID, socketId) => {
	return async (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			// Send request and remove navigation/page from back-end
			axios
				.delete(`navigations/items/${navID}?socket=${socketId}`)
				.then(() => {
					// Remove page object from the navigation.
					dispatch(removeNavigationAction(navID, parentID));
					resolve();
				})
				.catch(reject);
		});
	};
};

// -----------------------------------
// --------- Templates

/**
 *
 */
export const fetchAllTemplates = () => {
	return async (dispatch, getState) => {
		const now = currentTimestamp();
		const cache = getState().cache.templates;
		const templates = cache.data;

		return new Promise((resolve, reject) => {
			// Only fetch if empty
			if (
				Object.keys(cache.data).length === 0 ||
				now - cache.updated > CACHE_TTL
			) {
				axios
					.get('templates')
					.then(({ data }) => {
						dispatch(storeAllTemplates(data));
						resolve(data);
					})
					.catch((err) => {
						console.error('[thunk-cache]', err);
						reject(err);
					});
			} else {
				resolve(templates);
			}
		});
	};
};

export const fetchAllNavigationTypes = () => {
	return async (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			// Only fetch if empty
			// if (Object.entries(navigationsTypes).length === 0) {
			axios
				.get('navigations/types')
				.then(({ data }) => {
					dispatch(storeAllNavigationsTypes(data));
					resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
			// } else {
			// 	resolve(navigationsTypes);
			// }
		});
	};
};
