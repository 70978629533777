import React from 'react';
import styled from 'styled-components/macro';
import { TableBodyProps } from './ReactTableBody.types';

const ReactTableBody: React.FC<TableBodyProps> = (props) => (
	<ScTableBody>
		{props.children}
	</ScTableBody>
);

export default ReactTableBody;

export const ScTableBody = styled.div`
	display: table-row-group;
`;
