import React from 'react';
import styled, { css } from 'styled-components/macro';
import { GeneralNavItemSettingsProps, StyledNavItemSettingsProps } from './GeneralNavItemSettings.types';
import { SUPPORTED_LANGUAGES } from '../../settings';

const GeneralNavItemSettings: React.FC<GeneralNavItemSettingsProps> = (props) => {
	return (
		<ScNavItemSettings hasLanguageSupport={SUPPORTED_LANGUAGES.length > 0}>
			{SUPPORTED_LANGUAGES.length > 0 && (
				<ScInfoText>
					{props.label}
				</ScInfoText>
			)}
			<ScInnerWrapper hasLanguageSupport={SUPPORTED_LANGUAGES.length > 0}>
				{props.children}
			</ScInnerWrapper>
		</ScNavItemSettings>
	);
};

export default GeneralNavItemSettings;

export const ScNavItemSettings = styled.div<StyledNavItemSettingsProps>`
	${props => props.hasLanguageSupport && css`
		border-radius: 4px;
		border: 2px solid var(--bg-dark-color);
	`}
`;

export const ScInfoText = styled.p`
	font-size: 14px;
	align-items: center;
	display: flex;
	background-color: var(--bg-dark-color);
	color: var(--font-bright-color);
	padding: 8px;
`;

export const ScInnerWrapper = styled.div<StyledNavItemSettingsProps>`
	${props => props.hasLanguageSupport && css`
		background-color: var(--bg-bright-color);
		padding: 8px;
		padding-top: 16px;
		border-radius: 4px;
	`}
`;