import React from 'react';
import axios from '../../../src/utils/oc-axios';

const useLanguageSelection = (activeLanguage: string) => {
	/**
	 * Updates the Accept-Langauge header when page language changes
	 */
	React.useEffect(() => {
		const requestInterceptor = updateAcceptLanguageHeader(activeLanguage);

		return () => {
			axios.interceptors.request.eject(requestInterceptor);
		};
	}, [activeLanguage]);
};

export default useLanguageSelection;

/**
 * Updates the Accept-Language header
 * 
 * @param {string} lang 
 * @returns {any}
 */
export const updateAcceptLanguageHeader = (lang: string) => {
	return axios.interceptors.request.use((request): any => {
		return {
			...request,
			headers: {
				...request.headers,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Accept-Language': lang
			}
		};
	});

};
