import BlockDefinitions from './../../../components/Builder/BlockDefinitions';

/**
 * Helper function that will return the index of a row in an area's scope
 *
 * @param state
 * @param areaId
 * @param rowId
 */
export const getIndexOfAreaRow = (state, areaId, rowId) => {
	return state.elements.areas[areaId].rows.indexOf(rowId);
};

/**
 * Helper function that returns the index of a column in a row's scope
 *
 * @param state
 * @param rowId
 * @param columnId
 */
export const getIndexOfRowColumn = (state, rowId, columnId) => {
	return state.elements.rows[rowId].columns.indexOf(columnId);
};

/**
 * Helper function that returns the index of a block in a columns scope
 *
 * @param state
 * @param columnId
 * @param blockId
 */
export const getIndexOfBlockInColumn = (state, columnId, blockId) => {
	return state.elements.columns[columnId].blocks.indexOf(blockId);
};

/**
 * Helper function that returns how many child blocks a column has
 *
 * @param state
 * @param columnId
 */
export const getAmountOfBlocksInColumn = (state, columnId) => {
	return state.elements.columns[columnId].blocks.length;
};

/**
 * Helper function that will give instructions whether a row should be kept or removed after column removal
 *
 * @param state
 * @param areaId
 * @param rowId
 * @returns {{id: *, keep: boolean, index: *, row}}
 */
export const calcColumnsAfterChange = (state, areaId, rowId) => {
	const rowsLeftAfterChange = state.elements.rows[rowId].columns.length - 1;

	return {
		id: rowId,
		keep: rowsLeftAfterChange > 0,
		index: getIndexOfAreaRow(state, areaId, rowId)
	};
};

/**
 * Will check how drastically a block scope changes after a block is either removed or moved
 * The scope may change in various ways. The instruction given by this function can thus be:
 *
 * - keep row and keep column
 * - keep row, remove column
 * - remove row, remove column
 *
 * In case of removal the following things are returned
 * - id of element (id of row or column)
 * - keep (bool) whether to keep the item or not
 * - index of element (the index in it's parent children's (in this case area.rows or rows.columns) array
 *
 * @param state
 * @param areaId
 * @param rowId
 * @param columnId
 * @returns {{row: {keep: boolean}, column: {keep: boolean}}} || {{ row: {id: string, keep: boolean, index: int }, {{ column: {id: string, keep: boolean, index: int }}}
 */
export const calcRowScopeAfterChange = (state, areaId, rowId, columnId) => {
	// defaults assumes that the column has more blocks and should be kept, thus row is kept too
	let status = {
		row: {
			keep: true
		},
		column: {
			keep: true
		}
	};

	// calculates blocks left after a change (e.g. moving a block or deleting a block)
	const blocksLeftAfterChange =
		state.elements.columns[columnId].blocks.length - 1;

	if(blocksLeftAfterChange <= 0) {
		status = {
			row: calcColumnsAfterChange(state, areaId, rowId),
			column: {
				id: columnId,
				keep: false,
				index: getIndexOfRowColumn(state, rowId, columnId)
			}
		};
	}

	return status;
};

/**
 * Will retunr the id of the block row where a block lives
 *
 * @param {string} blockId
 */
export const getBlockRowId = (state, blockId) => {
	const column = Object.values(state.columns).find((column) =>
		column.blocks.includes(blockId));

	// if the column where the block resides is not found return nul as the block may have been removed
	if(!column) return null;

	return Object.values(state.rows).find((row) =>
		row.columns.includes(column.key)).key;
};

export const getBlockColumnKey = (state, blockKey) => {
	const column = Object.values(state.columns).find((column) =>
		column.blocks.includes(blockKey));

	if(!column) return null;

	return column.key;
};

export const getBlockAreaId = (state, blockKey) => {
	const rowKey = getBlockRowId(state, blockKey);

	if(!rowKey) return null;

	const area = Object.entries(state.areas).find(([key, value]) =>
		value.rows.includes(rowKey));

	if(!area) return null;

	return area[0];
};

/**
 * 
 */
export const getBlockIndex = (state, blockKey) => {
	const columnKey = getBlockColumnKey(state, blockKey);

	if(!columnKey) return null;

	const index = state.columns[columnKey].blocks.findIndex((_blockKey) => _blockKey === blockKey);

	if(index === null || index === undefined) return null;

	return index;
};

/**
 * Fetches the default object for a block type and populates the key with provided ID
 *
 * @param type
 * @param id
 * @returns {*}
 */
export const getDefaultBlockData = (type, id) => {
	return {
		[id]: {
			...BlockDefinitions[type].baseStruct,
			key: id
		}
	};
};