import React from 'react';
import BlockDivider from './BlockDivider';
import BlockDividerSettings from './Settings/BlockDividerSettings';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal,
	closeModal,
	changed,
	removeBlock
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (_originalState, _currentState, closeModal) => {
							// Reset builder block settings
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (_originalState, currentState, closeModal) => {
							// Notify builder to update all block's atributes before closing the modal
							changed(currentState);
							closeModal();
						}
					}
				];

				openModal({
					title: 'Redigerar avledare',
					actions: modalActions,
					position: 'right',
					hideBackdrop: true,
					isPositionable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (data) => {
				// Tell the builder to remove the block
				removeBlock(data);
				closeModal();
			}
		}
	];
};

const BlockDividerDefinition = {
	baseStruct: {
		// Used by Builder.
		key: null,
		data: {
			block: 'divider',
			style: 'solid',
			type: 'divider',
			weight: 'thin'
		}
	},
	builder: {
		component: <BlockDivider />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockDividerSettings />
	}
};

export default BlockDividerDefinition;
