import React from 'react';
import styled from 'styled-components/macro';
import { ITablePaginationProps } from './model.TablePagination';
import { Button, Icon } from '../../UI';

const TablePagination: React.FC<ITablePaginationProps> = (props) => {
	const pageRef = React.useRef<number>(0);

	const {
		page,
		pageIndex,
		pageCount,
		pageOptions,
		totalResults,
		canPreviousPage,
		canNextPage,
		gotoPage,
		previousPage,
		nextPage,
		isDisabled
	} = props;

	/**
	 * Set pageState to the value from the input field
	 *
	 * @param {string} value
	 */
	const pageChangedHandler = React.useCallback((value: string) => {
		pageRef.current = value ? Number(value) - 1 : 0;
	}, []);

	// Go to the page specified in the input
	const pageSubmitHandler = React.useCallback(() => {
		gotoPage(pageRef.current);
	}, [gotoPage]);

	return (
		<ScContainer>
			<ScShowing>
				{isDisabled ? (
					<>
						Laddar...
					</>
				) : (
					<>
						Visar 
						{' '}
						{page.length}
						{' '}
						av
						{' '}
						{totalResults}
						{' '}
						resultat
					</>
				)}
			</ScShowing>

			<ScTablePagination>
				<ScPrev
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage || isDisabled}
				>
					<ScIcon icon={['fal', 'angles-left']} />
				</ScPrev>
				{' '}
				<ScPrev
					onClick={() => previousPage()}
					disabled={!canPreviousPage || isDisabled}
				>
					<ScIcon icon={['fal', 'angle-left']} />
				</ScPrev>
				<ScPages>
					Sida
					{' '}
					<strong>
						{pageIndex + 1}
						{' '}
						av
						{' '}
						{pageOptions.length}
					</strong>
				</ScPages>
				<ScGoTo>
					Gå till sida:
					<ScGoToInput
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(ev) => {
							pageChangedHandler(ev.target.value);
						}}
					/>
					<ScButton
						isXSmall
						isPrimary
						isRounded
						onClick={pageSubmitHandler}
					>
						Gå
					</ScButton>
				</ScGoTo>
				<ScNext
					onClick={() => nextPage()}
					disabled={!canNextPage || isDisabled}
				>
					<ScIcon icon={['fal', 'angle-right']} />
				</ScNext>
				<ScNext
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage || isDisabled}
				>
					<ScIcon icon={['fal', 'angles-right']} />
				</ScNext>
			</ScTablePagination>
		</ScContainer>
	);
};

export default TablePagination;

const ScContainer = styled.div`
	display: flex;
	margin-top: 8px;
	justify-content: space-between;
`;

const ScShowing = styled.div`
	font-size: 14px;
`;

const ScTablePagination = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const ScNextandPrev = styled.button`
	display: flex;
	appearance: none;
	background: transparent;
	cursor: pointer;

	:disabled {
		cursor: not-allowed;
	}
`;

const ScPrev = styled(ScNextandPrev)`
	margin-left: 8px;

	&:first-child {
		margin-left: 0;
	}
`;

const ScNext = styled(ScNextandPrev)`
	margin-right: 8px;

	&:last-child {
		margin-right: 0;
	}
`;

const ScPages = styled.span`
	margin-left: 16px;
	font-size: 14px;
`;

const ScGoTo = styled.span`
	border-left: 1px solid var(--font-body-color);
	padding-left: 8px;
	margin-left: 8px;
	margin-right: 16px;
	font-size: 14px;
	display: flex;
	align-items: center;
`;

const ScGoToInput = styled.input`
	width: 56px;
	margin-left: 4px;
	padding-left: 4px;
`;

const ScIcon = styled(Icon)`
	font-size: 15px;
`;

const ScButton = styled(Button)`
	margin-left: 8px;
	padding-left: 16px;
	padding-right: 16px;
`;
