import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

// https://www.npmjs.com/package/react-fontawesome

// Watch for icon in the DOM, e.g. <i class="fal fa-gear"></i>
dom.watch();

library.add(
	fal,
	far,
	fas
);
