import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Icon } from '../../../components/UI';

/**
 * @param isEnabled     If the product is disabled or not
 * @param articleNr     The article number of the product
 * @param name          The product's name
 * @param price         The price of the product.
 * @param recPrice      The recomended price of the product.
 * @param isPriceFrom   Is the price from the value in 'price' like 'From 45sek'.
 * @param thumbnail     The url for the thumbnail/product image
 * @param colors        An array with HEX colors as available colors.
 *
 * @param liked         Triggered when the user mark the product as liked. (articleNr)
 */
const Product = (props) => {
	const colors = props.colors
		? Object.values(props.colors).map((color) => (
			<div
				key={`color_${color.code}_product_${props.articleNr}`}
				className="productItem__color"
				style={{ backgroundColor: `${color.hex}` }}
			>
			</div>
		  ))
		: null;

	// text used for the alt.
	const altText = props.name;
	const hasVariants = props.images && props.images.length > 1;
	const classes = ['productItem'];

	if(hasVariants) classes.push('-hasVariants');

	return (
		<li className={classes.join(' ')}>
			<div className="productItem__outer">
				<div className="productItem__wrap">
					{hasVariants && (
						<>
							<div className="scrollBtn -up">
								<i className="fal fa-chevron-up"></i>
							</div>
							<div className="productItem__variants">
								{hasVariants &&
									props.images.map((image) => (
										<div
											key={image.uuid}
											className="productItem__variant"
										>
											{/* <a href="#"> */}
											<img
												alt={altText}
												src={image.thumbnail}
											/>
											{/* </a> */}
										</div>
									))}
							</div>
							<div className="scrollBtn -down">
								<i className="fal fa-chevron-down"></i>
							</div>
						</>
					)}
				</div>

				<div className="productItem__inner">
					<ScBadge
						badge={props.badge}
						className="productBadge"
					/>

					<div className="productItem__image">
						<ScImg
							isEnabled={props.isEnabled}
							alt={altText}
							src={props.thumbnail}
						/>
						{!props.isEnabled && (
							<ScDisabled>
								<ScIcon icon={['fal', 'eye-slash']} />
							</ScDisabled>
						)}
					</div>
					<ScDetails
						isEnabled={props.isEnabled}
						className="productItem__details"
					>
						<div className="favorite">
							<input
								type="checkbox"
								id={`artNr${props.articleNr}`}
							/>
							<label
								htmlFor={`artNr${props.articleNr}`}
								className="favorite__icon"
							>
								<i className="fal fa-heart noFavorite"></i>
								<i className="fa fa-heart yesFavorite"></i>
							</label>
						</div>

						<div className="artNr">
							{`Art nr ${props.articleNr}`}
						</div>
						<div className="productItem__name">
							{props.name}
						</div>
						<div className="productItem__price">
							<span className="productItem__priceLabel">
								{props.isPriceFrom ? 'Från ' : ''}
							</span>
							<span className="price">
								{props.price}
								{' '}
								kr
							</span>
							{/* <div className="recommendedPrice js-recommendedPrice__wrapper">
								<span className="recommendedPrice__label">
									Rek pris:
								</span>
								<span className="recommendedPrice__sum js-recommendedPrice">
									{props.recPrice}
								</span>
							</div> */}
						</div>
						<div className="productItem__colors">
							{colors}
						</div>
						{/* </a> */}
					</ScDetails>
				</div>
			</div>
		</li>
	);
};

Product.propTypes = {
	isEnabled: PropTypes.bool,
	articleNr: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	price: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired
	]),
	isPriceFrom: PropTypes.bool.isRequired,
	thumbnail: PropTypes.string.isRequired,
	colors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	images: PropTypes.array,
	badge: PropTypes.string,

	liked: PropTypes.func
};

export default memo(Product);

const ScDisabled = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	background: rgba(102, 102, 102, 0.64);
	border-radius: 32px;
	position: absolute;
	top: 8px;
	right: 8px;
`;

const ScIcon = styled(Icon)`
	width: 24px;
	height: 16px;
	display: block;
`;

const ScImg = styled.img`
	${(props) =>
		!props.isEnabled &&
		css`
			opacity: 0.4;
		`};
`;

const ScDetails = styled.div`
	${(props) =>
		!props.isEnabled &&
		css`
			opacity: 0.4;
		`};
`;

const ScBadge = styled.div`
	background: url(${(props) => props.badge}) 0 center no-repeat;
	background-size: contain;
`;
