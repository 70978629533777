import React, { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { GRID, THUMBNAIL_SIZE_REGULAR } from './constants';
import {
	isItemSelectionPossible,
	headerPropertyInjection,
	viewsPropertyInjection
} from './helper';
import GridView from '../../../../components/ItemManager/Views/GridView';
import MultiSelectBar from '../../../../components/MultiSelectBar/MultiSelectBar';
import TableView from '../../../../components/ItemManager/Views/TableView';

const ProductsWrapper = (props) => {
	const [activeView, setActiveView] = useState(GRID);
	const [thumbnailSize, setThumbnailSize] = useState(THUMBNAIL_SIZE_REGULAR);

	/**
	 * Describes values set in header
	 * As TABLE VIEW requires these values this componenent will take them instead of injecting directly to header
	 *
	 * @type object
	 */
	const headerSettings = props.headerSettings;

	/**
	 * Describes an array of objects used to display the contents
	 *
	 * @type [int]
	 */
	const selectedItems = props.selectedItems;

	/**
	 * Describes the maximum amount of items that are allowed to be selected
	 *
	 * @type [int]
	 */
	const selectionMaxAmount = props.selectionMaxAmount;

	/**
	 * Describes a callback function when an item is selected
	 *
	 * @type function
	 */
	const selectionChanged = props.selectionChanged;

	/**
	 * Describes whether selection is possible or if e.g. max has been reached
	 *
	 * @type boolean
	 */
	const selectionEnabled = isItemSelectionPossible(
		selectionChanged,
		selectionMaxAmount,
		selectedItems
	);

	/**
	 * Describes the maximum amount of items per page
	 *
	 * @type int
	 */
	const itemsPerPage = props.itemsPerPage ?? 100;

	/**
	 * Describes whether data is loading or not
	 *
	 * @type boolean
	 */
	const isLoading = props.isLoading || false;

	/**
	 * Custom header to be injected
	 *
	 * @type React.Component
	 */
	const header = props.header;

	/**
	 * Describes a callback function triggered on viewChange
	 *
	 * @type function
	 */
	const viewChangingCallback = props.viewChangingCallback;

	/**
	 * Describes the actual view in use (Grid/Table)
	 *
	 * @type React.Component
	 */
	const viewComponent = activeView === GRID ? <GridView /> : <TableView />;

	/**
	 * Used to handle item selections.
	 * To work properly the "selectionChanged" callback function needs to be defined
	 * Returns an suggested state
	 *
	 * @param {int|string} itemId
	 */
	const itemSelectedHandler = useCallback(
		(itemId) => {
			let suggestedState;

			switch(true) {
				case !selectedItems.includes(itemId) && selectionEnabled:
					suggestedState = update(selectedItems, {
						$push: [itemId]
					});
					break;

				default:
					suggestedState = update(selectedItems, {
						$set: (arr) => arr.filter((id) => id !== itemId)
					});
			}

			if(suggestedState && typeof selectionChanged === 'function')
				selectionChanged(false, itemId, suggestedState);
		},
		[selectedItems, selectionChanged, selectionEnabled]
	);

	/**
	 * Change the view between grid/table.
	 */
	const setViewHandler = useCallback(
		(view) => {
			// don't continue if the view is already active or ItemManager isLoading = true
			if(view === activeView || isLoading) return;

			if(viewChangingCallback) viewChangingCallback();

			setActiveView(view);
		},
		[activeView, isLoading, viewChangingCallback]
	);

	return (
		<ScWrapper className={props.className}>
			<ScHeadersWrapper>
				{headerPropertyInjection(header, {
					activeView,
					setActiveView,
					isLoading,
					thumbnailSize,
					setThumbnailSize,
					setViewHandler,
					headerSettings
				})}
			</ScHeadersWrapper>

			{viewsPropertyInjection(viewComponent, {
				thumbnailSize,
				itemsPerPage,
				isLoading,
				itemSelectedHandler,
				selectionEnabled,
				headerSettings,
				...props
			})}

			{props.selectionBarButtons && selectedItems.length > 0 && (
				<MultiSelectBar
					onClose={() => props.selectionChanged(false, 'all', [])}
					opts={props.selectionBarButtons}
					amount={selectedItems.length}
					isLoading={isLoading}
					// modal={moveFilesModal}
				/>
			)}
		</ScWrapper>
	);
};

export default ProductsWrapper;

const ScWrapper = styled.div``;

const ScHeadersWrapper = styled.div`
	margin: 16px 32px 0;
	/* position: sticky; */
	top: 16px;
	z-index: 999;
`;
