import React, { PropsWithChildren } from 'react';
import { PageLanguageContext } from './PageLanguageContext';
import useLanguageSelection from '../../hooks/useLanguageSelection/useLanguageSelection';
import { defaultLanguage } from '../../settings';

const PageLanguageContextProvider: React.FC<PropsWithChildren> = (props) => {
	// Sets the page language to the default language
	const [language, setLanguage] = React.useState<string>(defaultLanguage);

	// This hook requires only one instance, and should not be used elsewhere
	// It will register the relevant interceptos with headers in axios requests
	useLanguageSelection(language);

	return (
		<PageLanguageContext.Provider value={{ activeLanguage: language, setLanguage }}>
			{props.children}
		</PageLanguageContext.Provider>
	);
};

export default PageLanguageContextProvider;