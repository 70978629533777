import React from 'react';
import BlockSpecialOffer from './BlockSpecialOffer';
import BlockSpecialOfferSettings from './Settings/BlockSpecialOfferSettings';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal,
	closeModal,
	changed,
	removeBlock,
	formValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData) => {
				/**
				 * Open a modal with the settings when pressing the COG icon.
				 */
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							// Reset builder block settings
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState, currentState, closeModal) => {
							// Notify builder to update all block's atributes before closing the modal
							changed(currentState);
							closeModal();
						}
					}
				];

				openModal({
					title: 'Redigerar Specialerbjudande',
					actions: modalActions,
					position: 'right',
					hideBackdrop: true,
					isPositionable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

export default {
	// can be added in builder by the user?
	isAddable: true,
	baseStruct: {
		// Used by Builder.
		key: null,
		data: {
			special_offer: {
				product_id: null,
				product_number: null,
				name: '',
				alt_text: null,
				media: {},
				mobile_media: {},
				colors: [],
				sizes: [],
				status: 'enable',
				articles: [],
				price: '',
				has_tiers: false
			}
		}
	},
	builder: {
		component: <BlockSpecialOffer />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockSpecialOfferSettings />
	}
};
