/* eslint-disable @typescript-eslint/naming-convention */
export interface SeoPages {
	[key: string]: SeoPage[];
}

export interface SeoTablePage extends Omit<SeoPage, 'h1' | 'h2' | 'missing_alt_texts' | 'seo_hidden' > {
	h1: string;
	h2: string;
	missing_alt_texts: string;
	seo_hidden: string;
}

export const SEO_TITLE_MAX_LENGTH = 70;
export const SEO_DESCRIPTION_MAX_LENGTH = 150;

export interface SeoPage {
	page_uuid: string;
	created_at: string;
	last_saved: string;
	title: string;
	description: string | null;
	slug: string;
	url: string;
	seo_title: string | null;
	seo_description: string | null;
	seo_canonical_url: string | null;
	seo_long_description: string | null;
	seo_keywords: string | null;
	seo_hidden: boolean;
	lang: string;
	navigation_id: number;
	publish_date: string | null;
	unpublish_date: string | null;
	h1: SeoHeading[] | null;
	h2: SeoHeading[] | null;
	last_edited_by: string;
	missing_alt_texts: SeoAltTexts[] | null;
	boost_value: string;
}

export interface SeoHeading {
	block_id: string;
	text: string;
}

export interface SeoAltTexts {
	file_name: string;
	url: string;
}

export interface SeoFilters {
	lang: string;
	needle: string;
}