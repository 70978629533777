import { SetOrderAction, SetOrdersAction } from './../types/OrderTypes';
import { SET_LISTITEM_ORDERS, SET_ORDER } from './actionTypes';
import {
	Order,
	OrderListItem
} from '../../modules/EngelsonsOrders/containers/Orders/model.Orders';
import PaginationInfo from '../../definitions/PaginationInfo';

/**
 * Will trigger the storing of all orders in state with the pagination info.
 *
 * @param {OrderListItem[]} orders An array with all order objects to store.
 * @param {PaginationInfo} paginationInfo The information about the current pagination.
 * @returns {SetOrdersAction}
 */
export const setListItemOrders = (
	orders: OrderListItem[],
	paginationInfo: PaginationInfo
): SetOrdersAction => {
	return {
		type: SET_LISTITEM_ORDERS,
		orders,
		paginationInfo
	};
};

/**
 * Will trigger the storing of a single order in state.
 *
 * @param {Order} order The complete order data to store.
 * @returns {SetOrderAction}
 */
export const setOrder = (order: Order): SetOrderAction => {
	return {
		type: SET_ORDER,
		order: order
	};
};
