import React, { useMemo } from 'react';
import { Routes, Route, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import ModulesWrapper from './ModulesWrapper';
import Notification from '../components/Notification';
import SideDrawer from '../containers/SideDrawer/SideDrawer';
import PageiFrame from '../components/PageiFrame';
import MediaArchive from '../containers/MediaArchive/MediaArchive';
import Builder from '../containers/Builder/Builder';
import LayoutErrorBoundary from '../components/LayoutErrorBoundary';
import PageContentWrapper from '../components/Content/PageContentWrapper';
import Users from '../containers/ControlPanel/Users/Users';
import Groups from '../containers/ControlPanel/Groups/Groups';
import Redirects from '../containers/ControlPanel/Redirects/Redirects';
import PrivateRoute from '../components/UI/PrivateRoute/PrivateRoute';
import useAuth from '../hooks/useAuth/useAuth';
import {
	GroupsPrivileges,
	NavigationPrivileges,
	SettingsPrivileges,
	UsersPrivileges
} from '../definitions/Privileges';
import Settings from '../containers/ControlPanel/Settings/Settings';
import LanguageUpdater from '../components/LanguageUpdater/LanguageUpdater';

const Layout = () => {
	const { verifyUserPermission } = useAuth();

	// This hook returns the current 'location' object
	const location = useLocation();

	return useMemo(() => {
		return (
			<LayoutErrorBoundary>
				<Notification />

				<SideDrawer />

				<ScLayout location={location}>
					<Routes>
						<Route
							path="/page/:uuid"
							element={validateUuidHelper('/page/:uuid', <PageiFrame />)}
						/>

						<Route
							path="/page/:uuid/:lang"
							element={<LanguageUpdater />}
						>
							<Route
								path="/page/:uuid/:lang/edit"
								element={validateUuidHelper(
									'/page/:uuid/:lang/edit',
									<PrivateRoute
										isAuth={verifyUserPermission(UsersPrivileges.LIST)}
										component={Builder}
									/>
								)}
							/>

						</Route>

						<Route
							path="/page/:uuid/edit"
							element={validateUuidHelper(
								'/page/:uuid/edit',
								<PrivateRoute
									isAuth={verifyUserPermission(UsersPrivileges.LIST)}
									component={Builder}
								/>
							)}
						/>
						
						<Route
							path="/trashbin"
							element={<></>}
						/>

						<Route
							path="/control-panel/media-archive"
							element={(
								<PrivateRoute
									isAuth={verifyUserPermission(NavigationPrivileges.LIST)}
									component={MediaArchive}
								/>
							)}
						/>
					
						<Route
							path="/control-panel/settings"
							element={(
								<PrivateRoute
									isAuth={verifyUserPermission(SettingsPrivileges.OPTIONS_GENERAL) || verifyUserPermission(SettingsPrivileges.OPTIONS_ADVANCED)}
									component={Settings}
								/>
							)}
						/>
						
						<Route
							path="/control-panel/users"
							element={(
								<PrivateRoute
									isAuth={verifyUserPermission(UsersPrivileges.LIST)}
									component={Users}
								/>
							)}
						/>

						<Route
							path="/control-panel/groups"
							element={(
								<PrivateRoute
									isAuth={verifyUserPermission(GroupsPrivileges.LIST)}
									component={Groups}
								/>
							)}
						/>

						<Route
							path="/control-panel/links"
							element={(
								<PrivateRoute
									isAuth={true}
									component={Redirects}
								/>
							)}
						/>

						<Route
							path="/modules/:module"
							element={<ModulesWrapper />}
						/>

						{/* Default view */}
						<Route
							path="/"
							element={(
								<PageContentWrapper>
									<ScHeader>
										<h1>
											Välkommen
										</h1>
									</ScHeader>
								</PageContentWrapper>
      						)}
						/>
					</Routes>
				</ScLayout>
			</LayoutErrorBoundary>
		);
	}, [location, verifyUserPermission]);
};

export default Layout;

/**
 * Check if the given uuid param is a valid uuid
 * 
 * @param {string} path
 * @param {JSX.Element} component
 * @returns {JSX.Element}
 */
const validateUuidHelper = (path, component) => {
	// matchPath matches a route path pattern against a URL pathname and returns information about the match.
	// In this case it's used to extract the uuid in the current URL
	const match = matchPath({ path }, location.pathname);
	if(!match) return <></>;

	const uuid = match.params.uuid;
	const isValidUuid = /[a-fA-F0-9-]{32,36}/.test(uuid);

	if(isValidUuid) return component;
	return <></>;
};

const ScLayout = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	transition: width 0.3s ease, transform 0.3s ease;

	transform: ${({ location }) =>
		location.pathname.includes('/page') &&
			location.pathname.includes('/edit')
			? 'translateX(0)'
			: 'translateX(344px)'};

	width: ${({ location }) =>
		location.pathname.includes('/page') &&
			location.pathname.includes('/edit')
			? '100%'
			: 'calc(100% - 344px)'};
`;

const ScHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	content-align: center;
	width: 100%;
	text-align: center;
`;
