import React from 'react';
import styled from 'styled-components/macro';
import { Icon, Message } from '../../../UI';

const BlockEmpty = (props) => {
	return (
		<ScEmptyContainer
			className={props.className}
			style={props.style}
			onClick={props.clicked}
		>
			<ScIcon color="#333" icon={props.icon} />
			<ScTitle>{props.title}</ScTitle>
			<ScMessage>{props.description}</ScMessage>
			<ScButtonWrapper>{props.children}</ScButtonWrapper>
		</ScEmptyContainer>
	);
};

export default BlockEmpty;

const ScEmptyContainer = styled.div`
	background: #f9f9f9;
	padding: 40px;
	justify-content: center;
	align-items: center;
	flex: 1;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
`;

const ScTitle = styled.div`
	font-weight: 600;
	font-size: 20px;
	color: #444;
	margin: 8px 0;
`;

const ScMessage = styled(Message)`
	margin-bottom: 16px;
	color: #444;
`;

const ScButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const ScIcon = styled(Icon)`
	font-size: 32px;
	width: 32px;
	height: 32px;
`;
