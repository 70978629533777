import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useModal from '../../hooks/Modal/useModal';
import {  ModalSettingsContainer } from '../UI';
import MainNavigation from '../../containers/MainNavigation/MainNavigation';
import LinkSelector from '../LinkSelector/LinkSelector';
import { ValidationTypes } from '../../hooks/useFormValidation/types';

const enabledLinkTypes = {
	slug: {
		value: 'slug',
		label: 'Intern'
	},
	http: {
		value: 'http',
		label: 'Extern',
		placeholder: 'https://',
		icon: ['fal', 'link'],
		validation: ValidationTypes.URL
	}, 
	email: {
		value: 'email',
		label: 'E-postadress',
		placeholder: 'info@exempel.se',
		icon: ['fal', 'envelope'],
		validation: ValidationTypes.EMAIL
	}, 
	phone: {
		value: 'phone',
		label: 'Telefonnummer',
		placeholder: '+46 3512345',
		icon: ['fal', 'phone'],
		validation: ValidationTypes.PHONE
	}, 
	advanced: {
		value: 'advanced',
		label: 'Avancerad',
		placeholder: 'data-hash',
		icon: ['fal', 'link'],
		validation: ValidationTypes.REQUIRED
	}
};

const SelectMenuItem = (props) => {
	const { formValidation } = props;
	const { setState, currentState, updateState } = props.modal;

	const linkSelectModal = useModal();

	/**
	 * Handles when a checkbox changes it's value
	 * 
	 * @param {Event} ev
	 * @param {Object} props
	 * @param {string} linkType
	 * @returns {void}
	 */
	const linkChangedHandler = useCallback((ev, props, linkType) => {
		// clear the state and update link type
		if(!props) {
			// reset the valdiation to clean existing valdiation errors.
			formValidation.resetErrors();

			updateState({
				uri_type: { $set: linkType },
				uri: { $set: '' },
				value: { $set: '' },
				new_window: { $set: false }
			});

			return;
		}

		// If the link should be opened in a new window
		if(props.name === 'new_window') {
			updateState({
				[ev.target.name]: { $set: ev.target.checked }
			});

			return;
		}

		// Update the button class property
		if(props.name === 'button_class') {
			updateState({
				[ev.target.name]: { $set: ev.target.value }
			});

			return;
		}

		// updates the state with the new link value
		setState({
			...currentState,
			value: ev.target.value,
			type: 'text'
		});
	}, [currentState, setState, updateState, formValidation]);

	/**
	 * Opens a modal with the main menu for slecting an item
	 */
	const openMainMenuSelector = useCallback(() => {
		linkSelectModal.open({
			title: 'Välj mål för länk',
			position: 'center',
			hideBackdrop: false,
			isDismissable: true,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						// Reset builder block settings
						closeModal();
					}
				}
			],
			state: {}
		});
	}, [linkSelectModal]);

	const mainMenuItemSelectedHandler = useCallback(
		(navItem) => {
			setState({
				...currentState,
				type: 'navItem',
				value: navItem.slug,
				uri: navItem.uri,
				navItem: navItem
			});

			linkSelectModal.close();
		},
		[currentState, linkSelectModal, setState]
	);

	return (
		<ModalSettingsContainer>
			{linkSelectModal.getAsComponent(
				<ModalSettingsContainer>
					<MainNavigation
						itemDoubleClicked={mainMenuItemSelectedHandler}
					/>
				</ModalSettingsContainer>
			)}
			<>
				<LinkSelector
					title="Typ av länk"
					enabledLinkTypes={enabledLinkTypes}
					internalLinkClicked={openMainMenuSelector}
					newWindowIsChecked={currentState.new_window}
					formValidation={formValidation}
					linkValue={currentState.value}
					linkChanged={linkChangedHandler}
					activeLinkType={currentState.uri_type}
					isButtonStyleEnabled={true}
					buttonClass={currentState.button_class}
				/>
			</>
		</ModalSettingsContainer>
	);
};

SelectMenuItem.propTypes = {
	modal: PropTypes.object,
	formValidation: PropTypes.object
};

export default SelectMenuItem;