import React from 'react';
import { useSlate } from 'slate-react';
import ToolbarIcon from '../Toolbar/ToolbarIcon';
import ToolbarItem from '../Toolbar/ToolbarItem';
import { setAlignment } from './alignments-api';
import { isBlockActive } from '../BlockButton/blocks-api';

const AlignmentButton = (props) => {
	const editor = useSlate();

	return (
		<ToolbarItem
			active={isBlockActive(editor, props.format)}
			onMouseDown={(event) => {
				event.preventDefault();
				setAlignment(editor, props.format);
			}}
		>
			<ToolbarIcon icon={props.icon} />
		</ToolbarItem>
	);
};

export default AlignmentButton;
