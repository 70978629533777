import axios from '../../utils/oc-axios';
import {
	setCategoriesTree,
	setCategoriesToProducts,
	setCategoriesToProduct,
	markProductAsReady,
	updateProductState,
	appendProducts,
	setProducts
} from '../actions/action-products';

/**
 * Fetches items for categories tree
 */
export const fetchAllCategoriesThunk = () => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.get('navigations/trees/categories')
			.then(({ data }) => {
				dispatch(setCategoriesTree(data));
				resolve(data);
			})
			.catch((err) => {
				console.error('[thunk-products]', err);
				reject(err);
			});
		});
	};
};

export const appendProductsThunk = (page, limit = 100, query) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.get(
				`modules/products?page=${page}&limit=${limit}&sort=${query.sort}&order=${query.order}&filter=${query.filter}&brand=${query.brand}&badge=${query.badge}&status=${query.status}&query=${query.query}`
			)
			.then(({ data }) => {
				dispatch(appendProducts(data));
				resolve(data);
			})
			.catch((err) => {
				console.error('[thunk-products]', err);
				reject(err);
			});
		});
	};
};

export const setProductsThunk = (query) => {
	if(!query) {
		query = {
			sort: 'name',
			order: 'asc',
			filter: 'all',
			brand: '',
			badge: '',
			status: '',
			query: ''
		};
	}

	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.get(
				`modules/products?page=1&limit=100&sort=${query.sort}&order=${query.order}&filter=${query.filter}&brand=${query.brand}&badge=${query.badge}&status=${query.status}&query=${query.query}`
			)
			.then(({ data }) => {
				dispatch(setProducts(data, query));
				resolve(data);
			})
			.catch((err) => {
				console.error('[thunk-products]', err);
				reject(err);
			});
		});
	};
};

/**
 * Updates a product state
 *
 * @param {object} updatedState
 */
export const updateProductThunk = (updatedState) => {
	// update a product backend
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.put(`/modules/products/${updatedState.product.number}`, {
				...updatedState.product
			})
			.then(({ data }) => {
				const {
					id,
					categories,
					settings,
					images
				} = updatedState.product;

				let thumbnail = null;
				const mainImage = Object.values(images).find(
					(image) => image.main
				);

				if(mainImage) thumbnail = mainImage.media.thumbnail;

				dispatch(
					updateProductState(id, categories, settings, thumbnail)
				);
				resolve(data);
			})
			.catch((err) => reject(err));
		});
	};
};

/**
 * Sets categories to a single product
 *
 * @param {array} product
 * @param {array} categories
 */
export const addCategoriesToProductThunk = (product, categories) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.patch('modules/products', { products: product, categories })
			.then(({ data }) => {
				dispatch(setCategoriesToProduct(product[0], categories));
				resolve(data);
			})
			.catch((err) => reject(err));
		});
	};
};

/**
 * Appends categories to a multiple product
 *
 * @param {array} product
 * @param {array} categories
 */
export const addCategoriesToProductsThunk = (products, categories) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
			.patch('modules/products', { products, categories })
			.then(({ data }) => {
				resolve(data);
				dispatch(setCategoriesToProducts(products, categories));
			})
			.catch((err) => reject(err));
		});
	};
};

/**
 * Marks a list of products as "ready"
 *
 * @param {array} products
 */
export const setProductsAsReadyThunk = (productsIds) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			const data = {
				products: productsIds,
				ready: true
			};

			axios
			.put('modules/products', data)
			.then(({ data }) => {
				resolve(data);
				dispatch(markProductAsReady(productsIds));
			})
			.catch((err) => reject(err));
		});
	};
};
