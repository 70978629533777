import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
	BasicContentModalProps,
	ScCollapseableProps
} from './model.BasicContentModal';
import { Icon } from '..';

/**
 *
 * @param {string} title
 */
const BasicContentModal: React.FC<BasicContentModalProps> = (props) => {
	const [isCollapsed, setCollapsed] = useState(true);

	const collapseButtonClickedHandler = (ev: {
		stopPropagation: () => void;
	}) => {
		ev.stopPropagation();

		setCollapsed(!isCollapsed);
	};

	return (
		<ScContainer>
			<ScTitle>
				{props.title}
			</ScTitle>
			<ScText>
				{props.text}
			</ScText>

			{!props.enableCollapse && props.children && (
				<ScChildrenWrap>
					{props.children}
				</ScChildrenWrap>
			)}

			{props.enableCollapse && props.children && (
				<>
					{/* Show button to collapse or not depending on state */}
					{isCollapsed ? (
						<ScCollapseAction
							onClick={collapseButtonClickedHandler}
						>
							{props.showMoreTitle}
							<ScIcon icon={['fal', 'chevron-down']} />
						</ScCollapseAction>
					) : (
						<ScCollapseAction
							onClick={collapseButtonClickedHandler}
						>
							{props.showLessTitle}
							<ScIcon icon={['fal', 'chevron-up']} />
						</ScCollapseAction>
					)}

					<ScCollapseable isCollapsed={isCollapsed}>
						<ScChildrenWrap>
							{props.children}
						</ScChildrenWrap>
					</ScCollapseable>
				</>
			)}
		</ScContainer>
	);
};

export default BasicContentModal;

const ScContainer = styled.div`
	padding: 24px 16px;
	text-align: center;
`;

const ScTitle = styled.div`
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 16px;
`;

const ScText = styled.div`
	font-size: 16px;
`;
const ScChildrenWrap = styled.div`
	margin-top: 16px;
`;

const ScCollapseable = styled.div<ScCollapseableProps>`
	${(props) =>
		props.isCollapsed &&
		css`
			display: none;
		`}
`;

const ScCollapseAction = styled.div`
	margin-top: 16px;
	font-weight: 600;
	cursor: pointer;
`;

const ScIcon = styled(Icon)`
	margin-left: 8px;
`;
