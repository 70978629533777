import React from 'react';
import styled from 'styled-components/macro';
import { PositionGridProps } from './PositionGrid.types';
import Label from '../../Forms/Label/Label';

// hold data to create a grid with options for positioning caption
// this is an named export as a helper function needs this info
export const grid = [
	{ name: 'content_position', value: 'northwest' },
	{ name: 'content_position', value: 'north' },
	{ name: 'content_position', value: 'northeast' },
	{ name: 'content_position', value: 'west' },
	{ name: 'content_position', value: 'center' },
	{ name: 'content_position', value: 'east' },
	{ name: 'content_position', value: 'southwest' },
	{ name: 'content_position', value: 'south' },
	{ name: 'content_position', value: 'southeast' }
];

const PostionGrid: React.FC<PositionGridProps> = (props) => {
	return (
		<Label
			label={props.label}
			description={props.description}
		>
			<ScGridArea>
				{grid.map((input, key) => (
					<ScGrid key={`grid_${key}`}>
						<ScRadioButton
							type="radio"
							name={props.name ? props.name : input.name}
							value={input.value}
							onChange={(ev) => {
								ev.stopPropagation();
								props.changed(ev, props);
							}}
							checked={props.value === input.value}
						/>
						<ScCircle />
					</ScGrid>
				))}
			</ScGridArea>
		</Label>
	);
};

const ScGridArea = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	height: 210px;
	border-top: 1px dashed var(--border-grey-color);
	border-right: 1px dashed var(--border-grey-color);
	background-color: var(--bg-bright-color);
	margin-bottom: 16px;
`;

const ScGrid = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px dashed var(--border-grey-color);
	border-bottom: 1px dashed var(--border-grey-color);
	width: 33.333%;
	height: 33.333%;
	:hover {
		span {
			background-color: rgba(62, 62, 62, 0.5);
			transition: none;
		}
	}
`;

const ScRadioButton = styled.input`
	display: none;
	:checked ~ span {
		background-color: var(--bg-dark-color);
	}
`;

const ScCircle = styled.span`
	display: inline-block;
	width: 24px;
	height: 24px;
	border: 1px solid var(--dark-color);
	border-radius: 24px;
	background-color: transparent;
	transition: background-color 0.1s ease-in-out;
`;

export default PostionGrid;
