import axios from '../../utils/oc-axios';
import { deleteBlock, setInitialState, updateBlockContent } from '../actions/action-builder';
import { getBlockAreaId, getBlockColumnKey, getBlockRowId, getBlockIndex } from '../../containers/Builder/helpers/BuilderUtils';

/**
 * Fetches items for categories tree
 */
export const fetchContents = (pageUUID) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
				.all([
					axios.get(`/block-types?page=${pageUUID}`),
					axios.get(`/pages/contents/${pageUUID}`),
					axios.get('/forms/settings')
				])
				.then(
					axios.spread((...response) => {
						const blockTypes = response[0];
						const contents = response[1];
						const formStructure = response[2];

						dispatch(
							setInitialState(blockTypes.data, contents.data, formStructure.data)
						);
						resolve(response);
					})
				)
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		});
	};
};

/**
 * Check if a block exist before uptating it's state.
 */
export const updateBlockContentThunk = (updatedBlock) => {
	return async (dispatch, getState) => {
		return new Promise((resolve) => {
			const builderState = getState().builder;

			// Check the block still exist in by checking in wich row it is in.
			// 	rowId will be null if the block/it's row cannot be found.
			const rowId = getBlockRowId(builderState.elements, updatedBlock.key);

			// resolve in case the block does not exist as there is nothing to update
			//	better than rejecting as that will show & report an error, when that is not needed.
			if(!rowId) {
				resolve();
				return;
			}

			// update the blocks state with the new data
			dispatch(updateBlockContent(updatedBlock));

			resolve();
		});
	};
};

/**
 * Check if a block exist before uptating it's state.
 */
export const deleteBlockThunk = (block) => {
	return async (dispatch, getState) => {
		return new Promise((resolve) => {
			const builderState = getState().builder;

			const blockKey = block.key;
			const areaId = getBlockAreaId(builderState.elements, block.key);
			const columnKey = getBlockColumnKey(builderState.elements, block.key);
			const rowKey = getBlockRowId(builderState.elements, block.key);
			const index = getBlockIndex(builderState.elements, block.key);

			// resolve in case the block does not exist as there is nothing to update
			//	better than rejecting as that will show & report an error, when that is not needed.
			if(!rowKey) {
				resolve();
				return;
			}

			dispatch(deleteBlock(areaId, rowKey, columnKey, blockKey, index));

			resolve();
		});
	};
};
