/* OPS!! Never import ModuleDefinitions here or it will cause a dependency loop.
    As ModuleDefinitions needs the Builder Blocks components and they need isTyping.
        so importing ModuleDefinitions here would mean that this file would need iself
        for being evaluated correctly by Javascript and that would case a error of something being undefined,
        as the dependency tree and code cannot be evaluated/loaded into memory.
*/

import { defineLivingSpace } from 'react-utils';

/**
 * Adds a class to body when a text editor is active in order to hide the BlockAction
 *
 * @param arg
 */
export const isTyping = (arg) => {
	const target = defineLivingSpace('#editor');

	if (arg) target.classList.add('isTyping');
	else target.classList.remove('isTyping');
};
