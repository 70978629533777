import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
	TooltipProps,
	ScTooltipWrapperProps,
	ScContainerProps
} from './model.Tooltip';
const Tooltip: React.FC<TooltipProps> = (props) => {
	return (
		<>
			<ScChildren
				className={props.className}
				style={props.style}
			>
				{props.children}
			</ScChildren>
			<ScTooltipWrapper
				isLeft={props.isLeft}
				isOver={props.isOver}
				isRight={props.isRight}
				isUnder={props.isUnder}
				isCenter={props.isCenter}
			>
				<ScContainer
					isBright={props.isBright}
					isLeft={props.isLeft}
					isOver={props.isOver}
					isRight={props.isRight}
					isUnder={props.isUnder}
				>
					{props.text}
				</ScContainer>
			</ScTooltipWrapper>
		</>
	);
};

export default Tooltip;

const ScTooltipWrapper = styled.div<ScTooltipWrapperProps>`
	position: absolute;
	height: 0;
	opacity: 0;
	transition: opacity 0.3s ease;
	visibility: hidden;

	${(props) =>
		props.isOver &&
		css`
			top: -40px;
			margin-bottom: 8px;
		`}
	${(props) =>
		props.isCenter &&
		css`
			transform: translate(-50%, 0);
			left: 50%;
		`}

	${(props) =>
		props.isRight &&
		css`
			top: -4px;
			left: 100%;
			margin-left: 8px;
		`}

		${(props) =>
		props.isUnder &&
		css`
			bottom: -28px;
			margin-top: 8px;
		`}

	${(props) =>
		props.isLeft &&
		css`
			top: -4px;
			right: 100%;
			margin-right: 8px;
		`}
`;

const ScChildren = styled.div`
	display: flex;
	:hover + ${ScTooltipWrapper} {
		opacity: 1;
		height: auto;
		visibility: visible;
		z-index: 9;
	}
`;

const ScContainer = styled.div<ScContainerProps>`
	position: relative;
	z-index: eee;
	display: inline-block;
	white-space: nowrap;
	background: #333;
	padding: 4px 8px;

	border-radius: 4px;
	padding: 8px;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
	text-align: center;
	color: #fafafa;

	${(props) =>
		props.isBright &&
		css`
			background: #eee;
			color: #333;
		`}

	:after {
		content: ' ';
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-width: 8px;

		${(props) =>
		props.isOver &&
			css`
				top: 100%;
				left: 50%;
				margin-left: -8px;
				border-top-color: #333;
			`}
		${(props) =>
		props.isBright &&
			props.isOver &&
			css`
				border-top-color: #eee;
			`}
		${(props) =>
		props.isRight &&
			css`
				top: 50%;
				right: 100%;
				border-width: 4px;
				margin-top: -4px;
				border-right-color: #333;
			`}
			${(props) =>
		props.isBright &&
			props.isRight &&
			css`
				border-right-color: #eee;
			`}

		${(props) =>
		props.isUnder &&
			css`
				bottom: 100%;
				left: 50%;
				margin-left: -8px;
				border-bottom-color: #333;
			`}
			${(props) =>
		props.isBright &&
			props.isUnder &&
			css`
				border-bottom-color: #eee;
			`}

		${(props) =>
		props.isLeft &&
			css`
				top: 50%;
				left: 100%;
				border-width: 4px;
				margin-top: -4px;
				border-left-color: #333;
			`}
			${(props) =>
		props.isBright &&
			props.isLeft &&
			css`
				border-left-color: #eee;
			`}
	}
`;
