
import React from 'react';
import styled from 'styled-components/macro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import console from './utils/customConsole';
import { initAlertStore } from './hooks/useAlert';
import Layout from './hoc/Layout';
import './utils/fontawesome';
import withAuthentication from './hoc/withAuthentication';
import Login from './components/Login/Login';
import GlobalStyle from './assets/GlobalStyle';
import { Loader } from './components/UI';
import withRedux from './hoc/withRedux';
import PageLanguageContextProvider from './PageLanguage/PageLanguageContext/PageLanguageContextProvider';

// initialize hook stores.
initAlertStore();

console.spacer();
console.info('Made in 🇸🇪 with ‍❤️‍ by Shine Digital Sweden AB - shinedigital.se');
console.spacer();

/** 
 * The default query function that will receive the query key.
 * Pass the signal to axios for query cancellation
 */
const defaultQueryFn = async (queryKey, signal) => {
	const { data } = await axios.get(`${API_BASE}/${queryKey[0]}`, {
		signal
	});
	return data;
};

// Provide the default query function to the app with defaultOptions
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			queryFn: defaultQueryFn
		}
	}
});

const App = (props) => {
	/**
	 * Helper function to determine what initial view to render to the user
	 * While checking for an active session a Loader will be rendered
	 * - If the user is not authenticated a Login in view will be rendered
	 * - If the user indeed is authenticated the actual CMS will be rendered
	 */
	const getInitialView = React.useCallback(() => {
		switch(true) {
			case props.appIsLoading:
				return <Loader />;
			case props.authUser !== null:
				return <Layout />;

			default:
				return (
					<ScLoginWrapper>
						<Login
							statusCode={props.statusCode}
							isLoading={props.authLoader}
						/>
					</ScLoginWrapper>
				);
		}
	}, [
		props.appIsLoading,
		props.authLoader,
		props.authUser,
		props.statusCode
	]);

	return (
		<PageLanguageContextProvider>
			<QueryClientProvider client={queryClient}>
				<GlobalStyle />
				<>
					{getInitialView()}
				</>
			</QueryClientProvider>
		</PageLanguageContextProvider>
	);
};

export default withRedux(withAuthentication(App));

const ScLoginWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;
