import React from 'react';
import { ScTableHead } from './style.TableHead';
import { TableHeadProps } from './model.TableHead';

const TableHead: React.FC<TableHeadProps> = (props) => {
	return (
		<ScTableHead>
			{props.children}
		</ScTableHead>
	);
};

export default TableHead;
