import React from 'react';
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const Page = (props) => {
	const [ref, inView, entry] = useInView({
		triggerOnce: false
	});

	// when a page is outside of viewport it's content is removed
	// this causes a somewhat jumpy behavior that we can control
	// by setting a height to each page
	const height = entry ? entry.target.offsetHeight : null;

	// if the last page is in view, then notify main container
	// that a new page needs to be merged to items and provided to ItemManager
	if(inView && props.amountPages === props.number) {
		const nextPage = props.amountPages + 1;

		if(typeof props.nextPageCallback === 'function')
			props.nextPageCallback(nextPage);
	}

	/**
	 * Quickfix to handle tables with lazyloading
	 * To-Do: refactor of original TableRow and TableCell components
	 * so that they do can be used indidually and without a certain data structure
	 */
	const setContent = () => {
		switch(true) {
			case props.display === 'table-row-group':
				return (
					<ScTableRow display="table-row">
						<ScTableCell
							display="table-cell"
							height={height}
						/>
					</ScTableRow>
				);

			default:
				return null;
		}
	};

	return (
		<ScPageWrapper
			id={`page-${props.number}`}
			ref={ref}
			height={height}
			display={props.display}
		>
			{inView ? props.items : setContent()}
		</ScPageWrapper>
	);
};

export default Page;

const ScPageWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	${(props) =>
		props.display &&
		css`
			display: ${props.display};
		`}

	${(props) =>
		props.height &&
		css`
			min-height: ${props.height}px;
		`}
`;

const ScTableRow = styled.div`
	${(props) =>
		props.display &&
		css`
			display: ${props.display};
		`}
`;

const ScTableCell = styled.div`
	${(props) =>
		props.display &&
		css`
			display: ${props.display};
		`}

	${(props) =>
		props.height &&
		css`
			height: ${props.height}px;
		`}
`;
