import styled from 'styled-components/macro';
import { Icon } from '../../../UI';

const Menu = styled('div')``;

const Toolbar = styled(Menu)`
	max-width: 100%;
	box-sizing: border-box;
	background: #666;
	overflow: hidden;
	display: flex;
	top: 0;
	left: 0;
	z-index: 999;
	overflow-x: auto;

	position: fixed;
	width: 100%;
	justify-content: center;
	height: 40px !important;

	.isDragging & {
		display: none;
	}
`;

export const ToolbarIcon = styled(Icon)`
	height: 14px;
	color: #fafafa;
`;

export const ToolbarItem = styled.div`
	cursor: pointer;
	height: 40px;
	width: 40px;
	padding: 8px;
	text-align: center;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;

	:hover {
		background-color: #333;
	}
`;

export default Toolbar;
