import React, { memo } from 'react';

import { DateTime } from 'luxon';
import styled from 'styled-components/macro';

import { Icon } from '../UI';
import { publishDateStatus } from '../../utils/publishDateHelper';

const PublicationStatus = (props) => {
	const {
		publishDate,
		unpublishDate,
		isScheduled,
		hasPassed,
	} = publishDateStatus(props.publishDate, props.unpublishDate);

	if (isScheduled) {
		return (
			<>
				<ScIcon icon={['fas', 'clock']} color="#20b126" />
				Publiceras{' '}
				{publishDate.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
			</>
		);
	} else if (hasPassed) {
		return (
			<>
				<ScIcon icon={['fas', 'clock']} color="#d0021b" />
				Publisering utgick{' '}
				{unpublishDate.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
			</>
		);
	} else if (!props.publishDate) {
		return (
			<>
				<ScIcon icon={['fas', 'clock']} color="#888" />
				Publiceringsdatum ej satt
			</>
		);
	}
};

export default memo(PublicationStatus);

const ScIcon = styled(Icon)`
	margin-right: 4px;
`;
