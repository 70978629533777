export const columnWidthPossibilities = {
	1: {
		100: [100],
	},
	2: {
		'50/50': [50, 50],
		'66/33': [66, 33],
		'33/66': [33, 66],
		'75/25': [75, 25],
		'25/75': [25, 75],
	},
	3: {
		'33/33/33': [33, 33, 33],
		'25/25/50': [25, 25, 50],
		'50/25/25': [50, 25, 25],
		'25/50/25': [25, 50, 25],
	},
	4: {
		'25/25/25/25': [25, 25, 25, 25],
	},
	5: {
		'20/20/20/20/20': [20, 20, 20, 20, 20],
	},
	6: {
		'16/16/16/16/16/16': [16, 16, 16, 16, 16, 16],
	},
};
