import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ImageThumbnailProps, ScBorderProps, ScCheckProps, ScContainerProps, ScImageProps } from './ImageThumbnail.types';
import CheckItem from '../../../Forms/CheckItem/CheckItem';
import ImageThumbnailActions from '../ImageThumbnailActions';
import { Button, MediaPlaceholder } from '../..';

const ImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
	const { 
		thumbnail, 
		name, 
		description, 
		isSelected, 
		isDisabled, 
		disabledItemsWithActionBar, 
		isLarge,
		index,
		actions,
		height,
		className,
		clicked,
		placeholderClicked
	} = props;

	const mediaPlaceholderComp = React.useMemo(() => {
		return (
			<MediaPlaceholder isLarge={isLarge ?? false}>
				{!!placeholderClicked && (
					<Button
						onClick={(ev: React.MouseEvent<HTMLElement>) => {
                    	ev.stopPropagation();
                    	if(placeholderClicked) placeholderClicked();
						}}
					>
						Välj bild
					</Button>
				)}
			</MediaPlaceholder>
		);
	}, [isLarge, placeholderClicked]);

	const imageThumbnailComp = React.useMemo(() => {
		return (
			<>
				<ScBorder
					id="ScBorder"
					onClick={(ev) => {
                	ev.stopPropagation();
                	if(clicked) clicked(ev, props);
					}}
					height={height}
					isSelected={isSelected}
					isDisabled={isDisabled}
				>
					<ScImage url={thumbnail} />
					{(!isDisabled ||
                    disabledItemsWithActionBar) &&
                    actions && (
	                    <ScActionsWrapper>
		                    <ImageThumbnailActions
			                    id="imageThumbnailActions"
			                    thumbnailProps={props}
			                    imageIndex={index}
			                    actions={actions}
		                    />
	                    </ScActionsWrapper>
					)}

					<ScCheck
						type="radio"
						isNotClickable
						checked
						isBright
						isSelected={isSelected}
					/>
					{/* Extra layers */}
					{props.children}
				</ScBorder>
				{name && (
					<ScTitle>
						{name}
					</ScTitle>
				)}
				{description && (
					<ScDescription>
						{description}
					</ScDescription>
				)}
			</>
		);
	}, [height, isSelected, isDisabled, thumbnail, disabledItemsWithActionBar, actions, props, index, name, description, clicked]);

	const renderComponent = React.useCallback(() => {
		if(thumbnail) return imageThumbnailComp;
		return mediaPlaceholderComp;
	}, [imageThumbnailComp, mediaPlaceholderComp, thumbnail]);

	return (
		<ScContainer
			width={props.width}
			maxWidth={props.maxWidth}
			className={className}
			style={props.style}
			isDisabled={isDisabled}
			disabledItemsWithActionBar={disabledItemsWithActionBar}
		>
			{renderComponent()}
		</ScContainer>
	);
};

// Use memo to prevent re-renders when nothing changed, like in MediaArchive when changing isSelected
//	Neede as the img would be reloaded causing it to blink before the webbrowser fetch and draw the same image again.
export default React.memo(ImageThumbnail);

const ScCheck = styled(CheckItem)`
	display: none;

	${(props: ScCheckProps) =>
		props.isSelected &&
		css`
			display: block;
			position: absolute;
			bottom: 4px;
			right: 4px;
			margin-bottom: 0;
			z-index: 9;
		`}
`;

const ScActionsWrapper = styled.div`
	position: absolute;
	top: 0;
	z-index: 99;
	width: 100%;
	display: none;
`;

const ScTitle = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 4px;
	text-align: center;
`;

const ScDescription = styled.div`
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 4px;
	text-align: center;
`;

const ScImage = styled.div.attrs((props: ScImageProps) => ({
	style: {
		background: `url(${props.url}) no-repeat center/contain`
	}
}))`
    ${(_props: ScImageProps) =>
		css`
			position: absolute;
	        top: 0;
	        left: 0;
	        right: 0;
	        bottom: 0;
		`}
`;

const ScBorder = styled.div.attrs((props: ScBorderProps) => ({
	style: {
		height: props.height
	}
}))`
	border: 2px solid transparent;
	overflow: hidden;
	position: relative;

	${(props: ScBorderProps) =>
		props.isDisabled &&
		css`
			cursor: not-allowed;
			background-size: contain;

			&::after {
				opacity: 0.5;
			}

			${ScDescription}, ${ScTitle} {
				opacity: 0.5;
			}
		`}
	${
	// Show this as default if no props.height is given.
	(props) =>
		!props.height &&
			css`
				:before {
					content: '';
					display: block;
					padding-top: 100%;
					width: 100%;
					position: relative;
				}
			`
}

	${(props) =>
		props.isSelected &&
		css`
			border: 2px solid var(--dark-color);
		`}
`;

const ScContainer = styled.div<ScContainerProps>`
	position: relative;
	padding: 4px;

	${(props) => `width: ${props.width || '33.3333%'};`}
	${(props) => `max-width: ${props.maxWidth || '100%'};`}

	display: block;
	flex: none;

	${(props) =>
		(!props.isDisabled || props.disabledItemsWithActionBar) &&
		css`
			:hover {
				${ScBorder} {
					border: 2px solid var(--bg-dark-grey-color);
					box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
					background-color: #fff;
				}

				${ScActionsWrapper} {
					display: block;
					cursor: pointer;
				}
			}
		`}
`;
