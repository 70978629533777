import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import SearchResult from './SearchResult';
import { Button, Icon } from '../UI';

const Search = (props) => {
	const { searchBtnClicked, cleared, pressed, changed } = props;

	const [hasContent, setHasContent] = useState(false);
	const input = useRef(null);
	const btnRef = useRef(null);

	React.useEffect(() => {
		if(props.hasButton && props.enableSearchOnEnter) {
			const triggerActionBtn = (ev) => {
				if(ev.key === 'Enter') {
					btnRef.current.click();
				}
			};

			window.addEventListener('keydown', triggerActionBtn);

			return () => {
				window.removeEventListener('keydown', triggerActionBtn);
			};
		}
	}, [props.enableSearchOnEnter, props.hasButton]);

	/**
	 * Calls the given callback if not disabled
	 * 
	 * @param {React.MouseEvent<HTMLDivElement>} ev
	 * @returns {void}
	 */
	const clearButtonClickedHandler = React.useCallback((ev) => {
		ev.stopPropagation();

		if(props.isDisabled) return;

		input.current.value = '';
		setHasContent(false);

		if(cleared && typeof cleared === 'function') cleared();
	}, [cleared, props.isDisabled]);

	/**
	 * Calls the given callback if not disabled
	 * 
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback(() => {
		if(props.isDisabled) return;
		
		if(searchBtnClicked) searchBtnClicked(input);
	}, [props.isDisabled, searchBtnClicked]);

	/**
	 * Calls the given key pressed callback 
	 * 
	 * @param {React.KeyboardEventHandler<HTMLInputElement>} ev
	 * @returns {void}
	 */
	const keyPressedHandler = React.useCallback((ev) => {
		ev.stopPropagation();

		if(props.isDisabled) return;

		if(!hasContent && ev.target.value.trim() !== '') setHasContent(true);

		if(pressed && typeof pressed === 'function') pressed(ev);
	}, [hasContent, pressed, props.isDisabled]);

	/**
	 * Calls the given changed callback 
	 * 
	 * @param {React.ChangeEventHandler<HTMLInputElement>} ev
	 * @returns {void}
	 */
	const changedHandler = React.useCallback((ev) => {
		ev.stopPropagation();

		if(props.isDisabled) return;

		if(hasContent && ev.target.value.trim() === '') setHasContent(false);

		if(changed && typeof changed === 'function') changed(ev);
	}, [changed, hasContent, props.isDisabled]);

	return (
		<>
			<ScContainer
				style={props.style}
				maxHeight={props.maxHeight}
				className={props.className}
			>
				<ScInnerContainer>
					<ScIconWrapper>
						<Icon icon={['fal', 'magnifying-glass']} />
					</ScIconWrapper>
					
					<ScSearch
						ref={input}
						disabled={props.isDisabled}
						placeholder={
							props.searchPlaceholder
								? props.searchPlaceholder
								: 'Sök...'
						}
						onChange={changedHandler}
						onKeyPress={keyPressedHandler}
						value={props.value}
					/>

					{(hasContent || props.cleared) && (
						<ScClearBtn onClick={clearButtonClickedHandler}>
							<Icon icon={['fal', 'xmark']} />
						</ScClearBtn>
					)}

				</ScInnerContainer>

				{props.hasButton && (
					<ScButton
						innerRef={btnRef}
						isDisabled={props.isDisabled}
						onClick={searchButtonClickedHandler}
					>
						Sök
					</ScButton>
				)}
			</ScContainer>

			{props.data && hasContent && (
				<SearchResult
					searchResultRelative={props.searchResultRelative}
					data={props.data}
					searchInput={input}
					clicked={props.clicked}
				/>
			)}
		</>
	);
};

export default React.memo(Search);

const ScContainer = styled.div`
	position: relative;
	display: flex;
	height: 40px;
	width: 100%;
	font-size: 12px;
	color: var(--font-dark-color);

	${(props) =>
		props.maxHeight &&
		css`
			max-height: ${props.maxHeight}px;
		`}
`;

const ScInnerContainer = styled.div`
	flex: 1;
	position: relative;
`;

const ScSearch = styled.input`
	width: 100%;
	height: 100%;
	padding: 16px 32px 16px 40px;
	appearance: none;
	background: transparent;
	background: var(--bg-bright-color);
	border: 1px solid #a9a9a9;
	/* border-radius: 3px; */
	overflow: hidden;
	transition: border 0.3s ease;

	&[disabled] {
		opacity: 0.5;
	}

	&::placeholder {
		color: var(--medium-grey-color);
	}

	&:focus,
	&:active {
		border: 1px solid var(--dark-color);
	}
`;

const ScIconWrapper = styled.div`
	position: absolute;
	left: 16px;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	pointer-events: none;
`;

const ScClearBtn = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const ScButton = styled(Button)`
	margin-left: 8px;
`;
