import React from 'react';
import Products from './EngelsonsProducts/containers/Products';
import Customers from './EngelsonsCustomers/containers/Customers/Customers';
import Orders from './EngelsonsOrders/containers/Orders/Orders';
import BlockCategoryDefinition from './EngelsonsProducts/components/Blocks/BlockCategory/BlockCategoryDefinition';
import BlockCheckoutBuyMoreDefinition from './EngelsonsProducts/components/Blocks/CheckoutBuyMore/BlockCheckoutBuyMoreDefinition';
import BlockSpecialOfferDefinition from './EngelsonsProducts/components/Blocks/SpecialOffer/BlockSpecialOfferDefinition';
import BlockProductDefinition from './EngelsonsProducts/components/Blocks/BlockProduct/BlockProductDefinition';
import BlockFlowboxDefinition from './EngelsonsFlowbox/components/Blocks/BlockFlowbox/BlockFlowboxDefinition';
import BlockGiftCardDefinition from './EngelsonsGiftCards/components/Blocks/BlockGiftCard/BlockGiftCardDefinition';
import Statistics from './EngelsonsStatistics/containers/Statistics/Statistics';
import Seo from './Seo/containers/Seo';
import Companies from './IzonenCompanies/containers/Companies';
import NavItemAccess from './NavItemAccess/containers/NavItemAccess';
import SearchStatistics from './SearchStatistics/containers/SearchStatistics';
import {
	CustomerPrivileges,
	OrderPrivileges,
	ProductPrivileges
} from '../definitions/Privileges';
import { CUSTOM_MODULES } from '../settings';

const allModules = {
	products: {
		displayName: 'Produkter',
		permission: ProductPrivileges.LIST,
		icon: ['fal', 'shirt'],
		page: <Products />,
		blocks: {
			/**
			 * Category block
			 */
			category: {
				...BlockCategoryDefinition
			},
			batch: {
				...BlockProductDefinition
			},
			'buy-more': {
				...BlockCheckoutBuyMoreDefinition
			},
			'special-offer': {
				...BlockSpecialOfferDefinition
			}
		}
	},
	customers: {
		displayName: 'Kunder',
		permission: CustomerPrivileges.LIST,
		icon: ['fal', 'users'],
		page: <Customers />,
		blocks: {}
	},
	orders: {
		displayName: 'Ordrar',
		permission: OrderPrivileges.LIST,
		icon: ['fal', 'receipt'],
		page: <Orders />,
		blocks: {}
	},
	statistics: {
		displayName: 'Statistik',
		permission: OrderPrivileges.LIST,
		icon: ['fal', 'chart-line'],
		page: <Statistics />,
		blocks: {}
	},
	flowbox: {
		displayName: 'Flowbox',
		icon: ['fal', 'receipt'],
		blocks: {
			flowbox: {
				...BlockFlowboxDefinition
			}
		}
	},
	giftcard: {
		displayName: 'Presentkort',
		icon: ['fal', 'receipt'],
		blocks: {
			giftcard: {
				...BlockGiftCardDefinition
			}
		}
	},
	seo: {
		displayName: 'SEO',
		icon: ['fal', 'file-magnifying-glass'],
		page: <Seo />,
		blocks: {}
	},
	companies: {
		displayName: 'Företag',
		icon: ['fal', 'building'],
		page: <Companies />,
		blocks: {}
	},
	navItemAccess: {
		displayName: 'Sidåtkomst',
		icon: ['fal', 'file-lock'],
		page: <NavItemAccess />,
		blocks: {}
	}, 
	searchStatistics: {
		displayName: 'Sökstatistik',
		icon: ['fal', 'file-magnifying-glass'],
		page: <SearchStatistics />,
		blocks: {}
	}
};

// Filter all modules based on custom modules set in settings.ts
const modules = Object.keys(allModules).reduce((prev, current) => {
	if(CUSTOM_MODULES.includes(current)) {
		return {
			...prev, 
			[current]: allModules[current]
		};
	}
	return prev;
}, {});

export default modules;