import React, { SyntheticEvent } from 'react';
import { ScTableHeading, ScSortItem, ScSortIcon } from './style.TableHeading';
import { TableHeadingProps } from './model.TableHeading';

const TableHeading: React.FC<TableHeadingProps> = (props) => {
	// the current sorting options, like direction (asc or desc, etc)

	return (
		<ScTableHeading
			isSortable={props.isSortable}
			onClick={(ev: SyntheticEvent) => {
				ev.stopPropagation();
				if(!!props.clicked && props.isSortable) props.clicked(props);
			}}
		>
			{props.children}
			{props.isSortable && (
				<ScSortItem>
					{/* {option.name} */}
					{/* <ScSortIcon
						icon={[
							props.isActive ? 'fas' : 'fal',
							props.sortOrder === 'desc'
								? 'sort-up'
								: 'sort-down',
						]}
						sortOrder={props.sortOrder}
						isActive={props.isActive}
					/> */}

					{props.isActive && (
						<ScSortIcon
							icon={[
								'fas',
								props.sortOrder === 'desc'
									? 'sort-up'
									: 'sort-down'
							]}
							sortOrder={props.sortOrder}
							isActive={props.isActive}
						/>
					)}
				</ScSortItem>
			)}
		</ScTableHeading>
	);
};

export default TableHeading;
