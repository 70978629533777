import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const modal = (state = initialState, action) => {
	const modalId = action.modalId;
	switch (action.type) {
		case actionTypes.OPEN_MODAL:
			return update(state, {
				[action.initialState.config.id]: {
					$set: { ...action.initialState },
				},
			});

		case actionTypes.CLOSE_MODAL:
			return update(state, {
				$unset: [modalId],
			});

		case actionTypes.SET_MODAL_CONFIG:
			return update(state, {
				[modalId]: {
					config: {
						[action.configKey]: {
							$set: action.data,
						},
					},
				},
			});

		case actionTypes.SET_MODAL_STATE:
			return update(state, {
				[modalId]: {
					currentState: {
						$set: action.data,
					},
				},
			});

		case actionTypes.UPDATE_MODAL_STATE:
			return update(state, {
				[modalId]: {
					currentState: {
						$set: action.updatedState,
					},
				},
			});
	

		default:
			return state;
	}
};

export default modal;
