import { createRandomId } from 'react-utils';
import { FormItemGenerator } from './BlockForm.consts';
import { BlockFormItem, BlockFormItemLayout } from './BlockForm.types';

export const generateFormItemChildrens = (fields: FormItemGenerator[]): Record<string, BlockFormItem> => {

	const result: Record<string, BlockFormItem> = {};

	for(let ii = 0; ii < fields.length; ii++) {
		const field = fields[ii];
		const itemKey = createRandomId(field.type + '-child');

		result[itemKey] = {
			key: itemKey,
			uuid: null,
			name: field.name,
			unique_name: itemKey,
			value: null,
			description: null,
			placeholder: null,
			minlength: null,
			maxlength: null,
			min: null,
			max: null,
			step: null,
			rows: null,
			size: null,
			layout: BlockFormItemLayout.VERTICAL,
			js_target: null,
			required_error: null,
			validation: null,
			pattern: null,
			type: field.type,
			input_type: field.input_type,
			children: [],
			accept: [],
			sort_order: ii + 1,
			root: false,
			multiple: false,
			show_name: false,
			show_description: false,
			required: !!field.required,
			selected: false,
			disabled: false,
			readonly: false,
			submit: false,
			autofocus: false
		};
	}

	return result;
};