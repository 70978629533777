import React from 'react';
import styled from 'styled-components/macro';
import { TextInput } from '../../../components/Forms';

const Tier = (props) => {
	return (
		<ScContainer>
			<TextInput
				label="quantity (H10) #25353 stafflingsnivå (712)"
				value={props.quantity.toString()}
				isDisabled
			/>
			<TextInput
				label="price (P10) #25362 pris per styck #25362 (712)"
				value={props.price.toString()}
				isDisabled
				style={{ marginBottom: 0 }}
			/>
		</ScContainer>
	);
};

export default Tier;

const ScContainer = styled.div`
	background: #eee;
	padding: 8px;

	&:nth-child(even) {
		background: #f9f9f9;
	}
`;
