import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import styled from 'styled-components/macro';

import { bytesToMultiple } from 'react-utils';

import CheckItem from '../Forms/CheckItem/CheckItem';
import TableRowActions from './TableRowActions';
import TableCell from './TableCell';

/**
 *	Converts a value from one format to another if supported.
 *
 * @param {array(string)} format 		The format to use
 * @param {*} value 					The value to convert
 */
const convertValue = (format, value) => {
	//
	switch (format[0]) {
		// Digital size (bytes)
		// 	The scale (format[1]) may be any supported by 'react-utils/bytesToMultiple'
		case 'bytes':
			return bytesToMultiple(format[1], value);

		default:
			// Throw exception is an unsupported format is used.
			throw new Error(
				`TableRow - The 'format' value '${format.toString()}' is not supported!`
			);
	}
};

/**
 *
 * @param {string} key						Used to identify this component when mapping, etc.
 * @param {string} thumbnail				The absolute URL of the image to use as thumbnail
 * @param {string} size						The item's size as 'small', 'regular' or 'big'
 * @param {array} actions
 * @param {array(object)} sortingOptions	The available sorting options to show, used to select which columns should be added to table.
 * @param {object} extraData				Extra data used to show columns and values.
 * @param {bool} isSelected					If this item is selected
 * @param {function} clicked				Function to be triggered when the component is clicked
 *
 * @param {bool} isDisabled					If the item is disabled (greyed-out) or not
 */
const TableRow = (props) => {
	const extraData = props.extraData;

	const actions =
		props.actions && props.actions.length > 0 ? (
			<ScTableRowActions
				actions={props.actions}
				rowItemData={props.extraData}
			/>
		) : null;

	/**
	 * Convert sortingOptions to columns, so we can easily define what props/columns that we what to display,
	 * 	You may convert the format of the values using 'convertTo' like convertTo: ['bytes', 'KB'] to convert from bytes to Kilobytes
	 * 	You may 'join' multiple values together (you can convert the values too) into one settings 'property' to an object ex:
	 * 
	 *  	Use an object to "join" multiple of the item's properties and show i as one.
			property: {
				// The separator used by join.
				separator: 'x',

				// The properties of the item object to merge
				properties: ['height', 'width'],
			}

	 * Only recalculate if data or any dependency changes
	 */
	const columnComponents = useCallback(
		() =>
			props.sortingOptions.map((option) => {
				let value;

				// The key to use for the component
				// The property may be only one (typeof string) or multiple (joining them, typeof object)
				const key =
					typeof option.property === 'object'
						? // Join multiple properties into one key
						  option.property.properties.join('.')
						: // Use the single property as key
						  option.property;

				// Only show value of a single property
				if (typeof option.property === 'string') {
					// Use lodash in case the value is nested
					value = _.get(extraData, option.property);

					// Convert the value if "convertTo" if defined in the sorting option.
					if (!!option.convertTo)
						value = convertValue(option.convertTo, value);

					// Join multiple values into one, formating is allowed.
				} else if (typeof option.property === 'object') {
					const separator = option.property.separator;
					const properties = option.property.properties;

					value = properties
						// Iterate the multiple properties to join and format them if neccesary.
						.map((property) => {
							// The current item's property to format.
							const currentValue = extraData[property];

							// Convert the value if "convertTo" if defined in the sorting option.
							if (!!option.convertTo) {
								return convertValue(
									option.convertTo,
									currentValue
								);
							} else {
								// Return value without formating.
								return currentValue;
							}
						})

						// Join the values (they may be formated)together
						.join(separator);
				}

				return (
					<TableCell key={`tableColumn_${key}`}>
						{/* Call the render methos in the sorting option if exist with the tiem (extraData) & the calculated value */}
						{option.table && option.table.render
							? option.table.render(extraData, value)
							: value}
					</TableCell>
				);
			}),
		[extraData, props.sortingOptions]
	)();

	return (
		<ScTr className={props.className} isSelected={props.isSelected}>
			{props.selectionEnabled && (
				<TableCell>
					{/* Only show checkbox and opts if enabled */}
					{!props.isDisabled && (
						<ScCheckbox
							type="checkbox"
							data={props.extraData}
							changed={() => props.clicked(props)}
							checked={props.isSelected}
						/>
					)}
				</TableCell>
			)}
			{columnComponents}

			{/* Used by actions, show the extra column only if there are options to skip unused space */}
			{props.actions && (
				<TableCell width={96} position="relative">
					{actions}
					{props.children}
				</TableCell>
			)}
		</ScTr>
	);
};

TableRow.propTypes = {
	index: PropTypes.number.isRequired,
	// key: PropTypes.oneOfType([
	// 	PropTypes.string.isRequired,
	// 	PropTypes.number.isRequired,
	// ]),
	thumbnail: PropTypes.string,
	sortingOptions: PropTypes.array,
	extraData: PropTypes.object,
	isSelected: PropTypes.bool,
	clicked: PropTypes.func,

	selectionEnabled: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool,
};

export default TableRow;

const ScTableRowActions = styled(TableRowActions)`
	display: none;
`;

const ScTr = styled.div`
	width: 100%;
	position: relative;
	font-size: 14px;
	font-weight: 100;
	display: table-row;

	:nth-child(odd) {
		background-color: #f3f3f3;
	}

	:hover {
		background-color: #444;
		color: #fafafa;

		div {
			border-color: #fafafa;
		}

		a {
			color: #fafafa;
		}
	}

	:hover {
		${ScTableRowActions} {
			display: flex;
		}
	}
`;

// Should we remove from TableRow? Should be comming from the Component including ItemManager?
const ScCheckbox = styled(CheckItem)`
	margin: 0;
	cursor: pointer;
`;
// End
