import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { parseStringAsObjectPath } from 'react-utils';
import { AllowedChangeEventElements, AllowedComponentProps, initalState } from './BlockVideoSettings.types';
import useModal from '../../../../../hooks/Modal/useModal';
import TabContainer from '../../../../../components/Tabs/TabContainer';
import { CheckItem, Select } from '../../../../Forms';
import { ImageThumbnail, Icon, PositionGrid } from '../../../../UI';
import MediaArchive from '../../../../../containers/MediaArchive/MediaArchive';
import ScreenVariants from '../../../../ScreenVariants';
import { BuilderVariants, ConfigState } from '../../../../../store/types/ConfigTypes';
import useAlert, { AlertPriorityTypes } from '../../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../../hooks/useAlert/types.useAlert';
import { CloseModal } from '../../../../../hooks/useModal/useModal.model';
import { BlockVideoType } from '../BlockVideo.types';
import { ImageThumbnailActionsType } from '../../../../UI/ImageThumbnail/ImageThumbnailTS/ImageThumbnail.types';
import TextInput from '../../../../Forms/TextInput/TextInput';
import { VIDEO_PLATFORMS } from '../../../../../settings';
import { VideoPlatforms } from '../../../../../settings.types';
import { ValidationTypes } from '../../../../../hooks/useFormValidation/types';
import { BlockSettingsProps, BuilderBlock } from '../../../Block.types';

const BlockVideoSettings: React.FC<BlockSettingsProps<BuilderBlock<BlockVideoType>>> = (props) => {
	// Get the builder config state from the Redux store
	const builderConfig = useSelector((state: ConfigState) => state.config.builder);

	// Get the view variants of the builder config
	const viewVariants = builderConfig.variants;

	// State that holds the selected builder variant
	const [selectedVariant, setSelectedVariant] = React.useState<BuilderVariants>(builderConfig.variants.current);

	// The modal hook this component is inside of
	const { currentState, updateState } = props.modal;

	// The func to trigger when the modal state has changed,
	// used to notify the Builder to update the block for preview to work
	const changed = props.changed;

	// A form validation hook
	const formValidation = props.formValidation;

	// The block data to show inside this component
	const block = currentState.data;

	// Modal to show the media archive
	const mediaArchiveModal = useModal();

	// Used to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// Holds the selected video plaform 
	const [selectedPlatform, setSelectedPlatform] = React.useState<VideoPlatforms>(currentState.data.provider);

	/**
	 * Updates the modal state and calls the changed handler to notify the builder
     * in order to preview the changes
	 *
	 * @param {string} property
	 * @param {string | boolean} value
	 * @param {boolean | undefined} preventBuilderPreview
     * @returns {void}
	 */
	const stateChangedHandler = React.useCallback((property: string, newValue: string | boolean, preventBuilderPreview?: boolean): void => {
		const suggestedState = { 
			data: parseStringAsObjectPath(property, newValue) 
		};

		updateState({
			...suggestedState,
			$merge: {
				updated: Date.now()
			}
		}).then((updatedState) => {
			if(!preventBuilderPreview && changed) changed(updatedState);
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade!',
				children: 'Vänligen försök igen'
			});
		});
	}, [changed, notification, updateState]);

	/**
	 * Handles when an input changes it's value.
	 *
	 * @param {React.ChangeEvent<AllowedChangeEventElements>} ev
	 * @param {AllowedComponentProps} props
	 */
	const videoSettingChangedHandler = React.useCallback((ev: React.ChangeEvent<AllowedChangeEventElements>, props: AllowedComponentProps) => {
		const value = ev.target.type === 'checkbox' && 'checked' in ev.target ? ev.target.checked : ev.target.value;
		const name = ev.target.name;
		const preventBuilderPreview = 'preventBuilderPreview' in  props ? props.preventBuilderPreview : false;
		
		stateChangedHandler(
			name,
			value,
			preventBuilderPreview
		);
	}, [stateChangedHandler]);

	/**
	 * Handles when the autoplay checkbox changes it's value
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} ev
	 * @returns {void}
	 */
	const autoplayChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>): void => {
		// Uncheck the caption_active checkbox if autoplay is unchecked
		if(!ev.target.checked && block.caption_active) {
			stateChangedHandler('caption_active', false);
		}

		stateChangedHandler('autoplay', ev.target.checked);
	}, [block.caption_active, stateChangedHandler]);

	/**
     * Opens a modal with the media archive
     * 
     * @returns {void}
     */
	const openMediaArchive = React.useCallback((): void => {
		mediaArchiveModal.open({
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (originalState: BlockVideoType, currentState: BlockVideoType, closeModal: CloseModal) => {
						closeModal();
					}
				}
			]
		});
	}, [mediaArchiveModal]);

	/**
     * A set of actions for thumbnails
     */
	const imageThumbnailActions = React.useMemo((): ImageThumbnailActionsType[] => [
		{
			component: <Icon
				color={'#fafafa'}
				icon={['fal', 'gear']}
			           />,
			action: openMediaArchive
		},
		{
			component: <Icon
				color={'#fafafa'}
				icon={['fal', 'trash']}
			           />,
			action: () => {
				const variant =
						selectedVariant === 'desktop'
							? 'media'
							: 'mobile_media';

				// update the modal's state, will return the updated object/state
				updateState({
					data: {
						[variant]: {
							src: { $set: null },
							uuid: { $set: null }
						}
					}
				}).then((updatedState) => {
					// notify Builder to update the block for preview to work
					if(changed) changed(updatedState);
				}).catch(() => {
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: 'Ett fel inträffade!',
						children: 'Vänligen försök igen'
					});
				});
			},
			styles: { 
				hover: { 
					backgroundColor: '#DA534D' 
				}
			}
		}
	], [changed, notification, openMediaArchive, selectedVariant, updateState]);

	/**
	 * Media Archive returns an array of objects
	 * Uses the first object (as ImageBlock only allows one image)
	 * And updates the state and closes the modal
	 *
	 * @param {any[]} files // This has the type any because the media archive is not written in TypeScript
     * @returns {void}
	 */
	const selectImageHandler = React.useCallback((files: any[]): void => {
		const file = files[0];
		const variant = selectedVariant === 'desktop' ? 'media' : 'mobile_media';

		updateState({
			data: {
				[variant]: {
					$set: {
						src: file.src,
						uuid: file.uuid
					}
				}
			}
		}).then((updatedState) => {
			// notify Builder to update the block for preview to work
			if(changed) changed(updatedState);

			mediaArchiveModal.close();
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade!',
				children: 'Vänligen försök igen'
			});
		});
	}, [changed, mediaArchiveModal, notification, selectedVariant, updateState]);

	/**
     * Updates the selected platform 
     * 
     * @param {React.SyntheticEvent<HTMLSelectElement, Event>} ev
     * @returns {void}
     */
	const videoPlatformChangedHandler = React.useCallback((ev: React.SyntheticEvent<HTMLSelectElement, Event>): void => {
		const platform = ev.currentTarget.value as VideoPlatforms;
		setSelectedPlatform(platform);

		// Reset video id and other settings,
		// and update to the selected platform
		updateState({ 
			data: {
				$merge: initalState,
				provider: {
					$set: platform
				}
			}
		}).then((updatedState) => {
			// notify Builder to update the block for preview to work
			if(changed) changed(updatedState);
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ett fel inträffade!',
				children: 'Vänligen försök igen'
			});
		});
	}, [changed, notification, updateState]);

	return (
		<>
			{mediaArchiveModal.getAsComponent(
				<MediaArchive
					mediaChosen={selectImageHandler}
					maxSelectionAmount={1}
					fileShowOnly={['image']}
				/>
			)}
			<TabContainer>
				<>
					{VIDEO_PLATFORMS.length > 1 && (
						<Select
							changed={videoPlatformChangedHandler}
							label="Välj video platform"
							value={selectedPlatform}
						>
							{VIDEO_PLATFORMS.map(platform => (
								<option
									value={platform}
									key={platform}
								>
									{platform}
								</option>
							))}
						</Select>
					)}
					
					{selectedPlatform === 'youtube' && (
						<>
							<TextInput
								label="Youtube ID - desktop"
								name="video_id"
								id="video_id"
								value={block.video_id}
								error={formValidation.errors['video_id']}
								formValidationUnregister={
							formValidation.unregisterElement
						}
								changed={(ev, ...data) => {
									formValidation.watch(ev, videoSettingChangedHandler, data);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true,
										validation: {
											type: ValidationTypes.REGEX,
											pattern: /^[a-zA-Z0-9-_]+$/g,
											message: 'Det måste vara en giltigt Youtube ID vars tillåtna tecken matchar a-z A-Z 0-9 - _'
										}
									})}
							/>
							<TextInput
								label="Youtube ID - mobil"
								name="video_id_mobile"
								id="video_id_mobile"
								placeholder="Samma som för desktop"
								description="Om inget ID för mobil anges så kommer ID:t för desktop att användas"
								value={block.video_id_mobile ?? ''}
								error={formValidation.errors['video_id_mobile']}
								formValidationUnregister={
							formValidation.unregisterElement
						}
								changed={(ev, ...data) => {
									formValidation.watch(ev, videoSettingChangedHandler, data);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: false,
										validation: {
											type: ValidationTypes.REGEX,
											pattern: /^[a-zA-Z0-9-_]+$/g,
											message: 'Det måste vara en giltigt Youtube ID vars tillåtna tecken matchar a-z A-Z 0-9 - _'
										}
									})}
							/>
						</>
					)}

					{selectedPlatform === 'vimeo' && (
						<>
							<TextInput
								label="Vimeo ID - desktop"
								name="video_id"
								id="video_id"
								value={block.video_id}
								error={formValidation.errors['video_id']}
								formValidationUnregister={
							formValidation.unregisterElement
						}
								changed={(ev, ...data) => {
									formValidation.watch(ev, videoSettingChangedHandler, data);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true,
										validation: {
											type: ValidationTypes.NUMERIC,
											message: 'Det måste vara en giltigt Vimeo ID vars tillåtna tecken endast är nummer'
										}
									})}
							/>
							<TextInput
								label="Vimeo ID - mobil"
								name="video_id_mobile"
								id="video_id_mobile"
								placeholder="Samma som för desktop"
								description="Om inget ID för mobil anges så kommer ID:t för desktop att användas"
								value={block.video_id_mobile ?? ''}
								error={formValidation.errors['video_id_mobile']}
								formValidationUnregister={
							formValidation.unregisterElement
						}
								changed={(ev, ...data) => {
									formValidation.watch(ev, videoSettingChangedHandler, data);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: false,
										validation: {
											type: ValidationTypes.NUMERIC,
											message: 'Det måste vara en giltigt Vimeo ID vars tillåtna tecken endast är nummer'
										}
									})}
							/>
						</>
					)}

					<CheckItem
						type="checkbox"
						title="Autoplay"
						name="autoplay"
						changed={autoplayChangedHandler}
						checked={block.autoplay}
						isDisabled={block.thumbnail_active}
						description="För att kunna ställa in videon på autoplay behöver utvald bild vara avaktiverad"
					/>

					<CheckItem
						type="checkbox"
						name="thumbnail_active"
						title="Använd utvald bild"
						changed={videoSettingChangedHandler}
						checked={block.thumbnail_active}
						isDisabled={block.autoplay || selectedPlatform !== 'youtube'}
						description={'För att kunna använda en utvald bild behöver autoplay vara avaktiverat' + (selectedPlatform !== 'youtube' ? ' och videon vara en Youtube video' : '')}
					/>

					{block.thumbnail_active && selectedPlatform === 'youtube' && (
						<ScreenVariants
							currentVariant={selectedVariant}
							variants={viewVariants.available}
							changed={(variantName) => setSelectedVariant(variantName)}
						>
							<ScImageThumbnail
								height="200px"
								width="100%"
								actions={imageThumbnailActions}
								placeholderClicked={openMediaArchive}
								thumbnail={
									selectedVariant === 'desktop'
										? block.media.src
										: block.mobile_media.src
								}
							/>
						</ScreenVariants>
					)}

					<CheckItem
						type="checkbox"
						title="Visa text"
						name="caption_active"
						changed={videoSettingChangedHandler}
						checked={block.caption_active}
						isDisabled={!block.autoplay}
						description="För att visa text behöver videon vara inställd på autoplay"
					/>

					{block.caption_active && (
						<>
							<PositionGrid
								value={block.content_position ?? 'center'}
								changed={videoSettingChangedHandler}
								name="content_position"
								label="Textplacering"
							/>
							<Select
								label="Bakgrundsfärg"
								name="content_theme"
								changed={videoSettingChangedHandler}
								value={block.content_theme ?? 'default'}
							>
								<option value="default">
									Ingen
								</option>
								<option value="lightTransparent">
									Ljus med transparens
								</option>
								<option value="light">
									Ljus
								</option>
								<option value="darkTransparent">
									Mörk med transparens
								</option>
								<option value="dark">
									Mörk
								</option>
							</Select>
						</>
					)}
					
				</>
			</TabContainer>
		</>
	);
};

export default BlockVideoSettings;

const ScImageThumbnail = styled(ImageThumbnail)`
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;
