import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import axios from '../../../utils/oc-axios';
import { BASE_URL as AXIOS_BASE_URL } from '../../../settings';
import { Button, Icon } from '../../../components/UI';
import { SkeletonSettings } from '../../../components/Skeletons';
import { TextInput } from '../../../components/Forms';
import useAlert from '../../../hooks/useAlert';

/**
 *
 * @param {object} modal	Modal state > originalState, currentState, setState
 */
const ImageSettings = (props) => {
	// deconstruct modal
	const { currentState, updateState } = props.modal;

	const notification = useAlert()[1];

	const humanFilesize = (size, precision = 2) => {
		let i = 0;
		for(; size / 1024 > 0.9; i++, size /= 1024) {}
		return (
			Number(size).toFixed(precision) +
			['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
		);
	};

	// deconstruct modal state
	const { isFetching, data } = currentState;

	// When the image was created
	const created = data.created ? new Intl.DateTimeFormat('sv-SV', {
		dateStyle: 'short',
		timeStyle: 'medium'
	}).format(new Date(data.created)) : null;
	
	// When the image was updated
	const updated = data.updated ? new Intl.DateTimeFormat('sv-SV', {
		dateStyle: 'short',
		timeStyle: 'medium'
	}).format(new Date(data.updated)) : null;

	// Handles when cahnging any property/settings of the file
	const dataChangedHandler = useCallback(
		(ev) => {
			updateState({
				data: {
					[ev.target.name]: { $set: ev.target.value }
				}
			});
		},
		[updateState]
	);

	/**
	 * Fetch information7data of the file.
	 */
	useEffect(() => {
		if(isFetching)
			axios
				.get(`/media/files/${data.uuid}`)
				.then(({ data }) =>
					updateState({
						isFetching: { $set: false },
						data: { $set: data }
					}))
				.catch((err) => {
					props.modal.close();
				});
	}, [data.uuid, isFetching, notification, props.modal, updateState]);

	return (
		<>
			{isFetching ? (
				<SkeletonSettings />
			) : (
				<ScWrapper>
					<ScImageContainer>
						<ScImage src={data.thumb} />
					</ScImageContainer>
					<ScUl>
						<ScButtonContainer>
							<ScButton
								onClick={(ev) => {
									ev.stopPropagation();
									window.location.href = `${AXIOS_BASE_URL}media/downloads/files?files[]=${data.uuid}`;
								}}
								isPrimary
								isSmall
							>
								<ScIcon icon={['fal', 'cloud-arrow-down']} />
								Ladda ner
							</ScButton>
						</ScButtonContainer>
						<ScLi>
							<ScListLabel>
								Upplösning:
							</ScListLabel>
							<ScListValue>
								{data.height}
								{' '}
								&times;
								{' '}
								{data.width}
							</ScListValue>
						</ScLi>
						<ScLi>
							<ScListLabel>
								Storlek:
							</ScListLabel>
							<ScListValue>
								{humanFilesize(data.size)}
							</ScListValue>
						</ScLi>
						<ScLi>
							<ScListLabel>
								Uppdaterad:
							</ScListLabel>
							<ScListValue>
								{updated}
							</ScListValue>
						</ScLi>
						<ScLi>
							<ScListLabel>
								Uppladdad:
							</ScListLabel>
							<ScListValue>
								{created}
							</ScListValue>
						</ScLi>
						<ScLi>
							<ScListLabel>
								Länk:
							</ScListLabel>
							<ScListValue>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={data.src}
								>
									{data.src}
								</a>
							</ScListValue>
						</ScLi>
						<ScLi style={{ marginTop: 16 }}>
							<TextInput
								label="Filnamn"
								name="filename"
								value={data.filename ?? data.original_name}
								changed={dataChangedHandler}
							/>
							<TextInput
								label="ALT text"
								name="alt_text"
								description="Sätter en alt-standardtext där denna bild används."
								value={data.alt_text}
								changed={dataChangedHandler}
							/>
							{/* <TextInput
								lavel="Beskrivning"
								name="description"
								value={data.description}
								changed={dataChangedHandler}
							/> */}
						</ScLi>
					</ScUl>
					{data.reference_count > 0 && (
						<ScUsage>
							{`Bilden används på ${data.reference_count} sidor`}
							{/* <a href="#">Visa</a> */}
						</ScUsage>
					)}
				</ScWrapper>
			)}
		</>
	);
};

export default ImageSettings;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;

const ScImageContainer = styled.div`
	text-align: center;
	margin-bottom: 16px;
`;
const ScImage = styled.img`
	max-height: 384px;
	max-width: 100%;
`;
const ScLi = styled.li`
	margin-bottom: 4px;

	:last-child {
		margin-bottom: 0;
	}
`;
const ScUl = styled.ul`
	list-style: none;
	padding: 0;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 8px;
	position: relative;
`;
const ScListLabel = styled.span`
	font-weight: 600;
	margin-right: 4px;
`;
const ScListValue = styled.span`
	font-weight: 100;
`;

const ScUsage = styled.div`
	font-size: 14px;
	margin-bottom: 16px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;
const ScIcon = styled(Icon)`
	margin-right: 8px;
`;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;
