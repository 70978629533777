import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
	SelectProps,
	ScSelectProps,
	ScScIconWrapperProps
} from './model.Select';
import { Icon } from '../../UI';
import Label from '../Label/Label';

const Select: React.FC<SelectProps> = (props) => {
	const hasError =
		props.error && Array.isArray(props.error) && props.error.length > 0;

	return (
		<Label
			label={props.label}
			description={props.description}
			style={props.style}
			className={props.className}
		>
			<ScSelectWrapper
				hasError={hasError}
				isDark={props.isDark}
				fullWidth={props.fullWidth}
				multiple={props.multiple}
				hasMinWidth={!props.hasNoMinWidth}
			>
				<ScSelect
					ref={props.inputRef}
					onChange={(ev) => {
						ev.stopPropagation();
						if(props.changed) props.changed(ev, props);
					}}
					id={props.id}
					name={props.name}
					value={props.value}
					isDark={props.isDark}
					disabled={props.isDisabled}
					multiple={props.multiple}
				>
					{props.children}
				</ScSelect>
				<ScIconWrapper isDark={props.isDark}>
					<ScIcon icon={['fal', 'angle-down']} />
				</ScIconWrapper>
			</ScSelectWrapper>
			{hasError && (
				<ScError>
					{props.error}
				</ScError>
			)}
		</Label>
	);
};

export default Select;

const ScError = styled.div`
	background: rgba(208, 2, 27, 0.1);
	border: 1px solid #d0021b;
	padding: 8px;
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
`;
const ScIconWrapper = styled.div<ScScIconWrapperProps>`
	position: absolute;
	right: 16px;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	pointer-events: none;

	${props => props.isDark && css`
		${ScIcon}
		color: var(--font-bright-color);
	`}
`;

const ScSelectWrapper = styled.div<ScSelectProps>`
	background: var(--bg-bright-color);
	border: 1px solid #a9a9a9;
	width: 100%;
	height: 40px;
	
	max-width: 600px;
	cursor: pointer;
	position: relative;

	${props => props.hasMinWidth && css`
		min-width: 164px;
	`}

	${props => props.isDark && css`
		background: var(--dark-grey-color);
		border: 1px solid transparent;
	`}

	${props => props.hasError && css`
		border: 1px solid #d0021b;
		margin-bottom: 0px;
	`}
	
	${props => props.fullWidth && css`
		max-width: none;
	`}

	${props => props.multiple && css`
		height: auto;

		${ScIconWrapper} {
			display: none;
		}
			
	`}
`;

const ScSelect = styled.select<ScSelectProps>`
	height: 100%;
	width: 100%;
	appearance: none;
	background: transparent;
	padding: 8px 40px 8px 16px;
	box-sizing: border-box;
	cursor: pointer;
	color: var(--font-body-color);

	${props => props.isDark && css`
		color: var(--font-bright-color);
	`}

	/* Apply disabled styling */
	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const ScIcon = styled(Icon)``;

