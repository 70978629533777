import React from 'react';

/**
 * Wraps a component with Scroll Into View functionality for given DOM reference
 *
 * @param {*} WrappedComponent
 */
const withScrollIntoView = (WrappedComponent) => {
	return (props) => {
		/**
		 * Needs a React Reference to DOM element
		 * Will use that reference to use the native scrollIntoView functionality
		 *
		 * @param {*} reference
		 */
		const scrollTo = (reference = null) => {
			if (reference) {
				setTimeout(() => {
					reference.focus();
				}, 100);

				reference.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		};

		return <WrappedComponent scrollTo={scrollTo} {...props} />;
	};
};

export default withScrollIntoView;
