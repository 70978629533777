import React from 'react';
import styled from 'styled-components/macro';
import DialogAddItem from '../../components/Dialogs/DialogAddItem';
import DialogSection from '../../components/Dialogs/DialogSection';

const ChooseNavItemDialog = (props) => {
	// iterate available templates and return corresponding item
	const templates = [];

	// fill with available types
	const types = [];

	// Get rid of possible duplicates
	const templateAllowances = [...new Set(props.allowances.current.templates)];
	const typeAllowances = [...new Set(props.allowances.current.types)];

	// map templates to a component the user can lick on and choose
	templateAllowances.forEach((templateId) => {
		const template = props.templates[templateId];

		if(template) {
			templates.push(
				<DialogAddItem
					key={`selectNavTypeTemplate_${templateId}`}
					title={template.name}
					fIcon={['fal', template.icon || 'file']}
					pressed={(ev) => {
						ev.stopPropagation();
						props.addChosenNavItemHandler('page', templateId);
					}}
				/>
			);
		}
	});

	// iterate available types and return corresponding items
	typeAllowances.forEach((typeId) => {
		const type = props.navigationTypes[typeId];

		if(type) {
			types.push(
				<DialogAddItem
					key={`selectNavType_${typeId}`}
					title={type.name}
					fIcon={['fal', type.icon]}
					pressed={(ev) => {
						ev.stopPropagation();
						props.addChosenNavItemHandler(type.name);
					}}
				/>
			);
		}
	});

	return (
		<ScAddNavItem>
			{templates.length > 0 && (
				<DialogSection title="Sida">{templates}</DialogSection>
			)}

			{types.length > 0 && (
				<DialogSection title="Övrigt">{types}</DialogSection>
			)}
		</ScAddNavItem>
	);
};

export default ChooseNavItemDialog;

const ScAddNavItem = styled.div`
	display: flex;
	flex-direction: column;
`;
