import update from 'immutability-helper';

import { updateModalStateAction } from '../actions/action-modals';

/**
 * Update the current state of a modal.
 *
 * @param {int} modalID         Modal's id.
 * @param {object} updateQry    An inmutability-helper update wry object.
 */
export const updateModalCurrentState = (modalID, updateQry) => {
	return async (dispatch, getState) => {
		const state = getState();
		if (state.modal[modalID]) {
			const modalState = state.modal[modalID].currentState;

			const updatedState = update(modalState, updateQry);

			// dispatch the update to Redux;
			dispatch(updateModalStateAction(modalID, updatedState));

			return updatedState;
		}
	};
};
