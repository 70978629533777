import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import TextEditor from '../../../../containers/TextEditor/TextEditor';
import BlockTextExpander from './BlockTextExpander';
import { updateTextContent } from '../../../../store/actions/action-builder';
// import { isTyping } from '../../../../containers/Builder/helpers/TextEditorUtils';

const BlockText = (props) => {
	// declare redux dispatcher
	const dispatch = useDispatch();

	/**
	 * Handles TextEditor content for specifik block
	 * @To-Do: Check if ordinary block content fn can be used instead
	 *
	 * @param {*} content
	 * @param {*} block
	 * @param {*} valueProp
	 */
	const setTextEditorContentHandler = useCallback(
		(content, block, valueProp = 'contents') => {
			dispatch(updateTextContent(content, block, valueProp));
		},
		[dispatch]
	);

	const { contents, partially_hidden } = props.data;

	return (
		<>
			<div className="cms-block cms-blockText">
				<BlockTextExpander isExpandable={partially_hidden}>
					<TextEditor
						setTextEditorContent={setTextEditorContentHandler}
						block={props.id}
						// isTyping={isTyping}
						initialText={contents}
						openInputModal={props.openLinkToolModal}
					/>
				</BlockTextExpander>
			</div>
		</>
	);
};

export default BlockText;
