import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ImageThumbnail } from '../../UI';
import BlockEmpty from '../../Builder/BlockTypes/BlockEmpty/BlockEmpty';
import { SkeletonGrid } from '../../Skeletons';
import Draggable from '../../../hoc/DragDrop/Draggable';
import Page from '../../InfiniteScroll/Page';

// component to show when props.items is empty
const emptyComp = (
	<BlockEmpty
		style={{ background: 'transparent' }}
		icon={['fal', 'ghost']}
		title="Här var det tomt..."
		description=""
	/>
);

/**
 * @param items	            		An array of objects to render.
 * @param itemSize					The item's size as 'small', 'regular' or 'big'
 * @param itemActions				Array containing all actions to show when overing an item.
 * @param itemComponent				The component to use for rendering the item in the GridView
 *
 * @param selectionEnabled			If the selection of items is enabled.
 * @param selectedItems				An array containing all selected file's key
 * @param itemSelectedHandler		A function to call when an item is selected
 *
 * @param thumbnailExtraLayers		Object with extra "layers" for ImageThumbnail
 *
 * @param isLoading					Defines if items or a skeleton should be shown.
 */
const GridView = (props) => {
	const itemSelectedHandler = props.itemSelectedHandler;

	/**
	 * Handle when an ImageThumbnail is clicked
	 *
	 * @param {Event} ev			The onClick event.
	 * @param {object} compProps	The React props of the element that triggered the event.
	 */
	const itemClickedHandler = useCallback(
		(ev, compProps) => {
			// trigger function with only the item's id to select it.
			itemSelectedHandler(compProps.id);
		},
		[itemSelectedHandler]
	);

	/**
	 * Convert array with items (as objects) to Components
	 */
	const renderItems = useCallback(
		(itemsInPage = null) => {
			const itemsReference = itemsInPage ? itemsInPage : props.items;

			return itemsReference.map((item, index) => {
				// array of the components to render as additional layers
				const additionalLayers = [];

				if(
					props.thumbnailExtraLayers &&
					Object.keys(props.thumbnailExtraLayers).length > 0
				) {
					// populate the additionalLayers array with the components.
					for(const [layerName, layer] of Object.entries(
						props.thumbnailExtraLayers
					)) {
						if(
							item.additionalLayers &&
							item.additionalLayers.includes(layerName)
						) {
							// clone the layer component to append new prop
							const tempLayer = React.cloneElement(layer, {
								key: `layer_${layerName}`
							});

							additionalLayers.push(tempLayer);
						}
					}
				}

				// if using an alternative component for rendering the item.
				const itemComp = props.itemComponent ? (
					React.cloneElement(props.itemComponent, {
						id: item.id,
						children: (
							<ScAdditionalLayer>
								{additionalLayers}
							</ScAdditionalLayer>
						)
					})
				) : (
					// the default component for every item if no custom component is defined.
					<ScImageThumbnail
						id={item.id}
						index={index}
						size={props.itemSize}
						clicked={itemClickedHandler}
						doubleClicked={props.doubleClicked}
						actions={props.itemActions}
						isSelected={props.selectedItems.includes(item.id)}
						disabledItemsWithActionBar={
							props.disabledItemsWithActionBar
						}
						isDisabled={
							!props.selectionEnabled &&
							!props.selectedItems.includes(item.id)
						}
						name={item.description}
						description={item.name}
						thumbnail={item.thumbnail}
					>
						<ScAdditionalLayer>
							{additionalLayers}
						</ScAdditionalLayer>
					</ScImageThumbnail>
				);

				return (
					<ScDraggable
						key={`gridItem_${item.id}`}
						index={index}
						id={item.id}
						scope="item-manager"
						enableDrag={props.enableDnD}
						isHandle={true}
						size={props.itemSize}
						allowedDirection="horizontal"
					>
						{itemComp}
					</ScDraggable>
				);
			});
		},

		[props.items, props.thumbnailExtraLayers, props.itemComponent, props.itemSize, props.doubleClicked, props.itemActions, props.selectedItems, props.disabledItemsWithActionBar, props.selectionEnabled, props.enableDnD, itemClickedHandler]

	);

	/**
	 * Renders grid items in pages
	 */
	const renderPages = useCallback(() => {
		return props.items.map((page, index) => (
			<Page
				key={`page-${index}`}
				id={`page-${index}`}
				number={index + 1}
				amountPages={props.items.length}
				nextPageCallback={props.nextPageCallback}
				items={renderItems(page)}
			/>
		));
	}, [props.items, props.nextPageCallback, renderItems]);

	// component/skeleton to show when loading
	const loadingComp = <SkeletonGrid />;

	let out = loadingComp;
	switch(true) {
		case props.items.length > 0:
			out = hasPages(props.items) ? renderPages() : renderItems();
			break;

		default:
			out = emptyComp;
	}

	return (
		<ScView>
			{props.isLoading ? loadingComp : out}
		</ScView>
	);
};

GridView.propTypes = {
	items: PropTypes.array,
	itemSize: PropTypes.string,
	itemActions: PropTypes.array,
	itemComponent: PropTypes.object,
	selectionEnabled: PropTypes.bool,
	selectedItems: PropTypes.array,
	itemSelectedHandler: PropTypes.func,
	thumbnailExtraLayers: PropTypes.object,
	enableDnD: PropTypes.bool,
	isLoading: PropTypes.bool,
	doubleClicked: PropTypes.func

};

export default GridView;

/**
 * Will determine if the item manager currently uses a "page" setup
 * If it is a page setup, all the items in the items array are array wrappers
 * thus we can safely assume that if the first element is an array, then we are
 * in fact going to render pages
 *
 * @param {array} items
 */
const hasPages = (items) => {
	return Array.isArray(items[0]);
};

const ScImageThumbnail = styled(ImageThumbnail)`
	margin-bottom: 16px;
	width: ${(props) => (props.size === 'small' ? '88px' : '136px')};
`;

const ScView = styled.div`
	align-content: flex-start;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	overflow-y: auto;
	padding: 32px 32px 80px;
	background: #fff;
`;

const ScDraggable = styled(Draggable)`
	display: block;
	position: relative;
	margin-bottom: 16px;
	width: ${(props) => (props.size === 'small' ? '88px' : '136px')};
`;

const ScAdditionalLayer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
