import React from 'react';
import styled, { css } from 'styled-components/macro';

const TabContents = (props) => {
	return <ScContainer
		noPadding={props.noPadding}
		active={props.active}
	       >{props.children}
	</ScContainer>;
};

export default TabContents;

const ScContainer = styled.div`
	display: none;
	flex-grow: 1;
	overflow: auto;

	${(props) =>
		props.active &&
		css`
			display: block;
		`}

	${(props) =>
		props.state === 'entered' &&
		css`
			display: block;
		`}


		${(props) =>
		!props.noPadding &&
		css`
			padding: var(--modal-padding);
		`}
`;
