import { CheckItemProps } from '../../../../Forms/CheckItem/model.CheckItem';
import { SelectProps } from '../../../../Forms/Select/model.Select';
import { TextInputProps } from '../../../../Forms/TextInput/model.TextInput';
import { PositionGridProps } from '../../../../UI/PositionGrid/PositionGrid.types';

export const initalState = {
	autoplay: false,
	autoplay_playlist: false,
	caption: '',
	caption_active: false,
	media: {
		src: null,
		uuid: null,
		thumbnail: null
	},
	mobile_media: {
		src: null,
		uuid: null,
		thumbnail: null
	},
	mute: false,
	provider: 'youtube',
	show_info: false,
	thumbnail_active: false,
	type: 'video',
	video_id: '',
	video_id_mobile: null
};

export type AllowedComponentProps = TextInputProps | CheckItemProps | PositionGridProps | SelectProps;
export type AllowedChangeEventElements = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;