import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
	// Information about pages
	pages: { updated: 0, data: {} },

	// The navigation tree
	navigation: { updated: 0, items: {}, selected: [] },

	// The available templates
	templates: { updated: 0, data: {} },

	navigationTypes: { updated: 0, data: {} },
};

/**
 * Return the current unix timestamp in seconds.
 */
const currentTimestamp = () => Math.round(new Date().getTime() / 1000);

/**
 *
 * @param {object} state
 * @param {object} action
 */
const config = (state = initialState, action) => {
	const data = action.data;

	switch (action.type) {
		// -----------------------------------
		// --------- Pages

		// When saving the information for a page
		case actionTypes.CACHE_PAGE_SAVE:
			return update(state, {
				pages: {
					updated: { $set: currentTimestamp() },
					data: {
						$set: {
							...state.pages.data,
							[data.uuid]: data,
						},
					},
				},
			});

		// When DUPLICATING a page
		case actionTypes.CACHE_PAGE_DUPLICATE:
			return update(state, {
				pages: {
					updated: { $set: currentTimestamp() },
					data: {
						$set: {
							...state.pages.data,
							[action.newPageObj.uuid]: action.newPageObj,
						},
					},
				},
			});

		// When removing a page
		case actionTypes.CACHE_PAGE_REMOVE:
			return update(state, {
				pages: {
					updated: { $set: currentTimestamp() },
					data: {
						$unset: [action.pageUUID],
					},
				},
			});

		// -----------------------------------
		// --------- Navigation

		// Set the selected nav items.
		case actionTypes.CACHE_NAVIGATION_SET_SELECTED:
			return update(state, {
				navigation: {
					selected: { $set: action.selected },
				},
			});

		// Clean the selected nav items
		case actionTypes.CACHE_NAVIGATION_CLEAN_SELECTED:
			return update(state, {
				navigation: {
					selected: { $set: [] },
				},
			});

		case actionTypes.CACHE_NAVIGATION_POPULATE:
			return update(state, {
				navigation: {
					updated: { $set: currentTimestamp() },
					items: { $set: action.navigation },
				},
			});

		case actionTypes.CACHE_NAVIGATION_UPDATE:
			return update(state, {
				navigation: {
					items: { $set: action.navigation },
				},
			});

		case actionTypes.CACHE_NAVIGATION_DUPLICATE:
			// The new navigation item's data from back-end
			const parent =
				state.navigation.items[action.newNavObj.parent_reference];

			return update(state, {
				navigation: {
					updated: { $set: currentTimestamp() },
					items: {
						// Add new navigation item to the state
						[action.newNavObj.navigation_id]: {
							$set: action.newNavObj,
						},

						// Add new page to parent's children array.
						[parent.is_root ? `r${parent.id}` : parent.id]: {
							// Add the new children after the original one whe duplicated from
							children: {
								$splice: [
									[
										parent.children.indexOf(
											action.originalNavID
										) + 1,
										0,
										action.newNavObj.navigation_id,
									],
								],
							},
						},
					},
				},
			});

		case actionTypes.CACHE_NAVIGATION_FOLDER_DUPLICATE:
			// Switch cases does not have block for every case statment and canc reare multiple
			// variables with the same name for that i wrapped everything in a funcion that is called automatically.
			return (() => {
				// The new navigation item's data from back-end
				const parent =
					state.navigation.items[
						data.duplicatedItem.parent_reference
					];

				return update(state, {
					navigation: {
						updated: { $set: currentTimestamp() },
						items: {
							// add new items to the tree
							$merge: data.newItems,

							// Add the duplicated item itself as children to it's parent.
							[parent.is_root ? `r${parent.id}` : parent.id]: {
								// Add the new children after the original one whe duplicated from
								children: {
									$splice: [
										[
											parent.children.indexOf(
												data.originalNavID
											) + 1,
											0,
											data.duplicatedItem.navigation_id,
										],
									],
								},
							},
						},
					},
				});
			})();

		case actionTypes.CACHE_NAVIGATION_REMOVE:
			const itemID = action.itemID;

			return update(state, {
				navigation: {
					updated: { $set: currentTimestamp() },
					items: {
						// remove reference from parent
						[action.parentID]: {
							children: {
								$splice: [
									[
										state.navigation.items[
											action.parentID
										].children.indexOf(itemID),
										1,
									],
								],
							},
						},

						// remove the navItem
						$unset: [itemID],
					},
				},
			});

		// -----------------------------------
		// --------- Templates

		case actionTypes.CACHE_TEMPLATES_POPULATE:
			return update(state, {
				templates: {
					updated: { $set: currentTimestamp() },
					data: { $set: action.templates },
				},
			});

		case actionTypes.CACHE_NAVIGATION_TYPES_POPULATE:
			return update(state, {
				navigationTypes: {
					updated: { $set: currentTimestamp() },
					data: { $set: action.navigationTypes },
				},
			});

		default:
			return state;
	}
};

export default config;
