import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import lodash from 'lodash';
import { FormItemSettingsProps, ScFormItemSettingsProps } from './FormItemSettings.types';
import { CheckItem, Select, Textarea, TextInput } from '../../../../../../Forms';
import { BlockFormItem, ConnectedElements, GeneralFormItemSetting, GeneralFormItemSettings, GeneralFormItemStructures, Setting } from '../../../BlockForm.types';
import { Icon } from '../../../../../../UI';
import Label from '../../../../../../Forms/Label/Label';

const FormItemSettings: React.FC<FormItemSettingsProps> = (props) => {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	// This ts-ignore is necessary until the rest of the Builder is rewritten in TypeScript
	//@ts-ignore
	const { settings, structures }: { settings: GeneralFormItemSettings, structures: GeneralFormItemStructures } = useSelector((selector) => selector.builder.formStructure);

	// The type of the editted element, e.g. "input"
	const { type } = props.affectedItem;

	// Get the settings for the structure type of the editted element
	const { settings: formItemSettings } = structures[type];

	// Destruct the settings to their own container objects
	const { simple, advanced } = formItemSettings;

	/**
     * Used to expand the advanced settings
     */
	const expansionHandler = React.useCallback(() => {
		setIsExpanded((state) => !state);
	}, []);

	/**
     * Function responsible of dynamic rendering of elements related to a FormItem settings
     * 
     * @param {Setting} settingStructure 
     * @param {BlockFormItem} item 
     * @param {GeneralFormItemSettings} settings
     * @returns {JSX.Element[] | undefined}
     */
	const renderSetting = React.useCallback((settingStructure: Setting, item: BlockFormItem, settings: GeneralFormItemSettings) => {
		/**
         * Used to map a setting to a component and return it
         * 
         * @param {keyof BlockFormItem} setting 
         * @param {JSX.Element|undefined)[]} related 
         * @returns {JSX.Element|undefined}
         */
		const createJSX = (affectedProp: keyof BlockFormItem, setting: GeneralFormItemSetting, related?: (JSX.Element|undefined)[]) => {
			const value = affectedProp in item ? item[affectedProp] : null;
    
			switch(setting.type) {
				case 'range':
				case 'number':
				case 'search':
				case 'text':    
				case 'url':
				case 'tel':
				case 'password':
				case 'email':
				case 'input':
					const inputType = setting.type === 'input' ? 'text' : setting.type;
					const correctedValue = value ? value.toString() : null;
                    
					return (
						<React.Fragment key={setting.name}>
							<TextInput
								changed={props.itemChangedCallback}
								name={affectedProp}
								type={inputType}
								label={setting.name}
								description={setting.info}
								value={correctedValue}
							/>
							{related}
						</React.Fragment>
					);
    
				case 'radio':
                    
					if('items' in setting) {
						return (
							<React.Fragment key={setting.name}>
								<Label
									label={setting.name}
									description={setting.info}
								>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										{setting.items && Object.entries(setting.items).map((item, key) => {
											const [itemValue, text] = item;
											return (
												<div
													key={key}
													style={{ marginRight: 16 }}
												>
													<CheckItem
														key={key}
														changed={props.itemChangedCallback}
														checked={value === itemValue}
														type="radio"
														name={affectedProp}
														title={text}
														value={itemValue}
													/>
												</div>
											); 
										})}
									</div>
								</Label>
							</React.Fragment>  
						);
					}

					return undefined;

				case 'checkbox':
					return (
						<React.Fragment key={setting.name}>
							<CheckItem
								changed={props.itemChangedCallback}
								type="checkbox"
								name={affectedProp}
								title={setting.name}
								description={setting.info}
								checked={!!value}
								value={value}
							/>
							{value && related}
						</React.Fragment>
					);
    
				case 'textarea':
					return (
						<React.Fragment key={setting.name}>
							<Textarea 
								changed={props.itemChangedCallback}
								id={affectedProp}
								name={affectedProp}
								label={setting.name}
								description={setting.info}
								value={value?.toString()}
							/>
							{related}
						</React.Fragment>
					);
    
				case 'select':
					return (
						<React.Fragment key={setting.name}>
							<Select 
								key={setting.name}
								changed={props.itemChangedCallback}
								id={setting.name}
								name={affectedProp}
								label={setting.name}
								description={setting.info}
								multiple={setting.multiple}
								value={value ?? undefined}
							>
								{setting.items && Object.entries(setting.items).map((item, key) => {
									const [value, text] = item;
									return (
										<option
											key={key}
											value={value}
										>
											{text}
										</option>
									); 
								})}
							</Select>
							{related}
						</React.Fragment>
					);
    
				default:
					return undefined;
			}
		};
    
		/**
         * Returns connected elements to a certain setting or undefined if no such elements exist
         * Those items are referred to as input dependencies. E.g. if "required" is checked, then render an input called "required_error"
         * 
         * @param {string[]} elems 
         * @returns {JSX.Element[] | undefined}
         */
		const getConnectedElements = (elems: ConnectedElements) => {
			if(Array.isArray(elems)) {
				return elems.map((affectedProp) => {
					const setting = settings[affectedProp];
					return createJSX(affectedProp, setting);
				});
			}
    
			return undefined;
		};

		// Loops through the elements for the actual setting
		// A setting structure is either a simple or an advanced structure in the structures[type].settings
		return Object.entries(settingStructure).map((settingsItem) => {
			let [affectedProp, connectedElements] = settingsItem;
			const setting = settings[affectedProp];
            
			// If connectedElements is an object then we know it require an extra step into that object to reach an array of strings
			if(lodash.isPlainObject(connectedElements)) {
				const itemValue = item[affectedProp as keyof BlockFormItem];
                
				if(connectedElements && typeof itemValue === 'string' && typeof connectedElements === 'object') {
					connectedElements = connectedElements[itemValue];
				}
			}
            
			// If it's not an object it's either null or an array of strings
			// Each string points to an element in the settings object and is used to render dependencies for an input
			const relatedElements = getConnectedElements(connectedElements);
			
			return createJSX(affectedProp as keyof BlockFormItem, setting, relatedElements);
		});
	}, [props.itemChangedCallback]);
    
	return (
		<ScFormItemSettings isOpen={props.isOpen}>
			<ScInner>
				{renderSetting(simple, props.affectedItem, settings)}

				{advanced && (
					<>
						<ScAdvancedSettingsToggle onClick={expansionHandler}>
							Avancerade inställningar <Icon icon={['fal', isExpanded ? 'chevron-up' : 'chevron-down']} />
						</ScAdvancedSettingsToggle>
						{isExpanded && (
							<ScAdvancedSettings>
								{renderSetting(advanced, props.affectedItem, settings)}
							</ScAdvancedSettings>
						)}
					</>
				)}
			</ScInner>
		</ScFormItemSettings>
	);
};

export default FormItemSettings;

const ScAdvancedSettingsToggle = styled.div`
	margin-bottom: 8px;
	cursor: pointer;	
	
`;
const ScAdvancedSettings = styled.div`
	background: #F2F2F2;
	padding: 24px;
`;

const ScFormItemSettings = styled.div<ScFormItemSettingsProps>`
	box-sizing:border-box;
	overflow-y: scroll;
	height: 100%;
	max-height: calc(100vh - 272px);
	transition: width 0.3s ease;
	width: ${(props) => (props.isOpen ? '600px' : '0px')};
`;

const ScInner = styled.div`
	padding: 24px;
`;