import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import Sortable from '../../../../../../components/Sortable/SortableList';
import { Message } from '../../../../../../components/UI';
import useProductSearch from '../../../../hooks/useProductSearch';

const BlockProductSettings = (props) => {
	// fetched the callback function that will display changes to builder
	const builderCallback = props.changed;

	const { currentState, updateState } = props.modal;

	// fetches the current products from the modal state
	const block = currentState.data;
	const products = block.products;

	/**
	 * Adds a product to the product block
	 * Updates modal state + builder accoridngly
	 *
	 * @param {object} product
	 */
	const addProductHandler = useCallback(
		(item) => {
			updateState({
				data: {
					products: {
						$push: [
							{
								...item,
								enabled: item.product.base.status !== 'disable'
							}
						]
					}
				}
			}).then((updatedState) => {
				builderCallback(updatedState);
			});
		},
		[builderCallback, updateState]
	);

	/**
	 * Changes the order of a product
	 *
	 * @param {object} suggestedState
	 */
	const setProductOrderHandler = useCallback(
		(suggestedState) => {

			updateState({ 
				data: {
					products: {
						$set: [].concat(suggestedState)
					}
				}
			}).then((updatedState) => {
				builderCallback(updatedState);
			});
		},
		[builderCallback, updateState]
	);

	/**
	 * Removes a product from block
	 *
	 * @param {int} index
	 */
	const removeProductHandler = useCallback(
		(index) => {
			updateState({ 
				data: {
				
					products: {
						$splice: [[index, 1]]
					}
				
				}
			}).then((updatedState) => {
				builderCallback(updatedState);
			});
		},
		[builderCallback, updateState]
	);

	// instantiate product search
	const productSearch = useProductSearch(addProductHandler);

	return (
		<ScWrapper>
			{productSearch()}

			<Message
				style={{ marginTop: 16 }}
				isSmall
			>
				{products.length > 1 &&
					'Dra och släpp för att sortera ordningen i blocket.'}
			</Message>

			<Sortable
				items={products}
				changed={(suggestedState) =>
					setProductOrderHandler(suggestedState)}
				itemRemoved={removeProductHandler}
			/>
		</ScWrapper>
	);
};

BlockProductSettings.propTypes = {};

export default BlockProductSettings;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;
