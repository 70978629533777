import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactTableRowProps, StyledReactTableRow } from './ReactTableRow.types';

const ReactTableRow: React.FC<ReactTableRowProps> = (props) => {

	const { clicked, index } = props;

	/**
	 * Returns a callback when clicked
	 * 
	 * @param {React.MouseEvent<HTMLDivElement>} ev 
	 * @returns {void} 
	 */
	const clickedHandler = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
		if(clicked) clicked(ev, index);
	}, [clicked, index]);

	return (
		<ScTr
			onClick={clickedHandler}
			isShowingPointer={props.isShowingPointer}
		>
			{props.children}
		</ScTr>
	);
};

export default React.memo(ReactTableRow);

export const ScTr = styled.div<StyledReactTableRow>`
	width: 100%;
	position: relative;
	font-size: 14px;
	font-weight: 100;
	display: table-row;

	:nth-child(odd) {
		background-color: #f3f3f3;
	}

	:hover {
		background-color: #444;
		color: #fafafa;

		div {
			border-color: #fafafa;
		}

		a {
			color: #fafafa;
		}
	}

	${(props) => props.isShowingPointer && css`
		cursor: pointer;
	`}


	[data-show-on-hover] {
		visibility: hidden;
	}

	:hover {
		[data-show-on-hover] {
			visibility: visible;
		}
	}
`;
