import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import BlockEmpty from '../../../../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';

const loadFlowbox = () => {
	return new Promise((resolve) => {
		(function (d, id) {
			// Flowbox behöver läggas till i iFramen editor ligger i för att få
			// 	den att fungera.
			const iframe = d.getElementById('editor');

			// iFrame's window
			const iWindow = iframe.contentWindow;

			// iFrame's document
			const idoc =
				iframe.contentDocument || iframe.contentWindow.document;

			// kod från flowbox, justerad till att gå på iframen
			if(!iWindow.flowbox) {
				const f = function () {
					f.q.push(arguments);
				};
				f.q = [];
				iWindow.flowbox = f;
			}

			if(idoc.getElementById(id)) return resolve(iWindow);

			const s = idoc.createElement('script'),
				ibody = idoc.body;

			s.id = id;
			s.async = true;
			s.src = 'https://connect.getflowbox.com/flowbox.js';
			ibody.appendChild(s);

			resolve(iWindow);
		})(document, 'flowbox-js-embed');
	});
};

const BlockFlowbox = (props) => {
	const { key, tags, product_id, show_buttons } = props.data;
	
	const containerID = `js-flowbox-flow-${key}-${Math.round(
		Math.random() * 1000
	)}`;

	useEffect(() => {
		// only load/init flowbox if the key exist
		if(key) {
			loadFlowbox().then((iw) => {
				iw.flowbox('init', {
					container: `#${containerID}`,
					key: key,
					productId: product_id,
					tags: tags ? tags.trim().split(',') : []
				});
			});
		}
	}, [containerID, key, tags, product_id]);

	return (
		<>
			{!key && (
				<BlockEmpty
					icon={['fal', 'grip']}
					title="Här var det tomt..."
					description="Tryck på kugghjulet för att redigera ditt Flowbox block"
				>
				</BlockEmpty>
			)}

			{key && (
				<>
					{show_buttons && (
						<div className="flowboxTags">
							<button className="button -black">
								Alla
							</button>
							<button className="button -black">
								Jakt
							</button>
							<button className="button -black">
								Barn
							</button>
							<button className="button -black">
								Vardag- och friluftsliv
							</button>
							<button className="button -black">
								Bakom kulisserna
							</button>
							<button className="button -black">
								Hund
							</button>
						</div>
					)}
					<ScContainer id={containerID}>
						Flowbox is loading...
					</ScContainer>
				</>
			)}
		</>
	);
};

export default BlockFlowbox;

const ScContainer = styled.div`
	min-height: 100px;
`;
