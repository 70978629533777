import { useDragDrop } from '../../hooks/useDragDrop';
import {
	defineDOMLivingSpace,
	setPosition,
	autoScrollHelper,
} from './DragDropUtils';
import { isEmptyObject } from 'react-utils';

const MouseMoveHandler = (ev, callback, props) => {
	ev.stopPropagation();

	const [state, dispatch] = useDragDrop();

	/**
	 * The scope of the drag instance
	 * @type String
	 */
	const scope = props.scope;

	/**
	 * PageX coordinate
	 */
	const pageX = ev.pageX;

	/**
	 * PageY coordinate
	 */
	const pageY = ev.pageY;

	/**
	 * The targetDocumentSelector is used to determine the document where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDocumentSelector = state[scope].targetDocumentSelector
		? state[scope].targetDocumentSelector
		: null;

	/**
	 * The targetDOMSelector is used to determine the DOM element where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDOMSelector = state[scope].targetDOMSelector
		? state[scope].targetDOMSelector
		: null;

	/**
	 * PageX coordinate
	 */
	const clientX = ev.clientX;

	/**
	 * PageY coordinate
	 */
	const clientY = ev.clientY;

	/**
	 * Creates a new dragable element and appends it to target document and target dom element
	 *
	 * @param element
	 * @param elementData
	 * @param documentSelector
	 * @param DOMSelector
	 * @returns {ActiveX.IXMLDOMNode | Node}
	 */
	const createDragableClone = (
		element,
		elementData,
		documentSelector,
		DOMSelector,
		isHandle
	) => {
		// clone the DOM element and make a dragable out of it
		const dragable = element.cloneNode(true);

		// if a drag place holder is used, then replace the innerHTML
		// of the element clone that will be dragged
		if (state[scope].dragPlaceholder)
			dragable.innerHTML = state[scope].dragPlaceholder;

		// define the target DOM element
		const targetDOM = defineDOMLivingSpace(DOMSelector, documentSelector);

		// make the dragable position absolute
		dragable.style.position = 'absolute';

		// make the dragable zIndex matter
		dragable.style.zIndex = 1000;

		// remove the dragable's pointer events
		dragable.style.pointerEvents = 'none';

		// make sure the dragable width is the same as the original element's width
		dragable.style.width = `${elementData.width}px`;

		// make semi transparent
		dragable.style.opacity = 0.8;

		// add class to dragable clone
		dragable.classList.add('dragClone');

		// add a class reference to the targetDOM that a drag occurs
		targetDOM.classList.add('isDragging');

		// append the element to the targetDOM
		targetDOM.append(dragable);

		// if the dragable is not the handle target the handle and
		if (!isHandle) {
			const handle = dragable.querySelector(
				'[data-is-draghandle="true"]'
			);

			// set pointerEvents to none
			handle.style.pointerEvents = 'none';

			// and parentNode pointerEvents to none
			handle.parentNode.style.pointerEvents = 'none';
		}

		// return the dragable element
		return dragable;
	};

	/**
	 * Used to make sure that the element you are dragging is not a parent of where you wish to drop it
	 *
	 * @param originalElement
	 * @param hoveredElement
	 */
	const isParentToDestination = (originalElement, hoveredElement) => {
		return originalElement.contains(hoveredElement);
	};

	/**
	 * Move in any directions
	 * Can give more advanced instructions whether the app should create new elements or move them there
	 *
	 * @param dragable
	 * @param hovered
	 * @param isParentToHoveredElement
	 * @param posX
	 * @param posY
	 * @param allowedDirection
	 * @returns {{}}
	 */
	const calculateAnyDirection = (
		dragable,
		hovered,
		isParentToHoveredElement,
		posX,
		posY,
		allowedDirection
	) => {
		let action = 'create';
		if (
			isEmptyObject(hovered) ||
			hovered.properties.restriction === dragable.properties.type ||
			hovered.properties.type === dragable.properties.type
		) {
			action = 'move';
		}

		let type =
			!isEmptyObject(hovered) && action === 'create'
				? hovered.properties.type // e.g. row
				: allowedDirection; // if moving then either any, vertical or horizontal

		let instruction = {};

		switch (true) {
			// inserts block in new block column to the right of hovered element
			case !isParentToHoveredElement &&
				hovered.properties.direction !== 'vertical' &&
				posX >= 50 &&
				posY <= posX:
				hovered.hoveredElement.style.boxShadow =
					'inset -3px 0px 0px black';
				instruction = {
					action: 'create',
					type: 'column',
					adjustIndex: 1,
					position: 'right',
				};
				break;

			// inserts block in new block column to the left of hovered element
			case !isParentToHoveredElement &&
				hovered.properties.direction !== 'vertical' &&
				posX < 50 &&
				posY >= posX:
				hovered.hoveredElement.style.boxShadow =
					'inset 3px 0px 0px black';
				instruction = {
					action: 'create',
					type: 'column',
					adjustIndex: 0,
					position: 'left',
				};
				break;

			// inserts block below hovered element
			case !isParentToHoveredElement && posX >= 50 && posY >= posX:
				hovered.hoveredElement.style.boxShadow =
					'inset 0 -3px 0px black';
				instruction = {
					action: action,
					type: type,
					adjustIndex: 1,
					position: 'bottom',
				};
				break;

			// inserts block above hovered element
			case !isParentToHoveredElement && posX < 50 && posY <= posX:
				hovered.hoveredElement.style.boxShadow =
					'inset 0 3px 0px black';
				instruction = {
					action: action,
					type: type,
					adjustIndex: 0,
					position: 'top',
				};
				break;

			default:
		}

		return instruction;
	};

	/**
	 * Calculate vertical possible drops
	 *
	 * @param dragable
	 * @param hovered
	 * @param isParentToHoveredElement
	 * @param posX
	 * @param posY
	 * @returns {{}}
	 */
	const calculateVerticalDirection = (
		dragable,
		hovered,
		isParentToHoveredElement,
		posX,
		posY
	) => {
		let instruction = {
			isParentToHoveredElement: isParentToHoveredElement,
		};

		switch (true) {
			// inserts block above hovered element
			case !isParentToHoveredElement && posY <= 40:
				hovered.hoveredElement.style.boxShadow =
					'inset 0 3px 0px black';
				instruction = {
					action: 'move',
					type: 'vertical',
					adjustIndex: 0,
					position: 'top',
					isParentToHoveredElement: isParentToHoveredElement,
				};
				break;

			// inserts block below hovered element
			case !isParentToHoveredElement && posY > 60:
				hovered.hoveredElement.style.boxShadow =
					'inset 0 -3px 0px black';
				instruction = {
					action: 'move',
					type: 'vertical',
					adjustIndex: 1,
					position: 'bottom',
					isParentToHoveredElement: isParentToHoveredElement,
				};
				break;

			default:
		}

		return instruction;
	};

	/**
	 * Calculate vertical possible drops
	 *
	 * @param dragable
	 * @param hovered
	 * @param isParentToHoveredElement
	 * @param posX
	 * @param posY
	 * @returns {{}}
	 */
	const calculateHorizontalDirection = (
		dragable,
		hovered,
		isParentToHoveredElement,
		posX,
		posY
	) => {
		let instruction = {
			isParentToHoveredElement: isParentToHoveredElement,
		};

		switch (true) {
			// inserts block left of hovered element
			case !isParentToHoveredElement && posX <= 40:
				hovered.hoveredElement.style.boxShadow =
					'inset 3px 0px 0px black';
				instruction = {
					action: 'move',
					type: 'horizontal',
					adjustIndex: 0,
					position: 'left',
					isParentToHoveredElement: isParentToHoveredElement,
				};
				break;

			// inserts block right of hovered element
			case !isParentToHoveredElement && posX > 60:
				hovered.hoveredElement.style.boxShadow =
					'inset -3px 0px 0px black';
				instruction = {
					action: 'move',
					type: 'horizontal',
					adjustIndex: 1,
					position: 'right',
					isParentToHoveredElement: isParentToHoveredElement,
				};
				break;

			default:
		}

		return instruction;
	};

	/**
	 * This function calculates the possible drop zones of the dragable element
	 * It will visualize by adding box-shadows of where the dragable can be dropped
	 *
	 * @param dragable
	 * @param hovered
	 * @param clientX
	 * @param clientY
	 */
	const analyzePossibleDrops = (dragable, hovered, clientX, clientY) => {
		if (dragable.scope !== hovered.scope) return;

		// A dragable could be restricted to vertical or horizontal drops
		// Standard behavior is no restrictions and a drop could occur at any side of the destination element
		const allowedDirection = dragable.properties.allowedDirection
			? dragable.properties.allowedDirection
			: 'any';
		const restriction = dragable.properties.restriction;

		if (hovered.properties.disabled) return;

		// if an element is restricted to only switch place with a certain type (e.g. row can switch place with other rows)
		if (
			restriction !== void 0 &&
			!isEmptyObject(hovered) &&
			restriction !== hovered.properties.type
		)
			return;

		// Get the postions on the screen of the hovered element
		const x = clientX - hovered.hoveredData.left;
		const y = clientY - hovered.hoveredData.top;

		const posX = (x / hovered.hoveredData.width) * 100;
		const posY = (y / hovered.hoveredData.height) * 100;

		// Reset all box-shadow of hoveredElement
		hovered.hoveredElement.style.boxShadow = 'none';

		// Define an instructions object
		let instruction = {};

		// It is not possible to drag a parent element into becomming it's own child element
		const isParentToHoveredElement = isParentToDestination(
			dragable.originalElement,
			hovered.hoveredElement
		);

		switch (allowedDirection) {
			case 'vertical':
				instruction = calculateVerticalDirection(
					dragable,
					hovered,
					isParentToHoveredElement,
					posX,
					posY
				);
				break;

			case 'horizontal':
				instruction = calculateHorizontalDirection(
					dragable,
					hovered,
					isParentToHoveredElement,
					posX,
					posY
				);
				break;

			default:
				instruction = calculateAnyDirection(
					dragable,
					hovered,
					isParentToHoveredElement,
					posX,
					posY,
					allowedDirection
				);
		}

		dispatch('ADD_INSTRUCTION', instruction);
	};

	// if a draggedItem has not yet been set then ignore the move handler
	if (!('draggedItem' in state)) return;

	// Destruct the draggedItem object to be able to set positions
	const {
		originalElement,
		dragableElement,
		elementData,
		calculatedX,
		calculatedY,
		initialPageX,
		initialPageY,
	} = state.draggedItem;

	// If the dragable clone haven't been created yet, then see if it's time to create it
	if (!dragableElement) {
		// Check so that the mouse have moved at least 5px since the mouse down so that we can distinguish a regular click event
		if (
			Math.abs(initialPageX - pageX) >= 5 ||
			Math.abs(initialPageY - pageY) >= 5
		) {
			// Create a dragable clone
			const dragableClone = createDragableClone(
				originalElement,
				elementData,
				targetDocumentSelector,
				targetDOMSelector,
				props.isHandle
			);

			// Store the clone reference to the dragable state
			dispatch('ADD_DRAGABLE', { dragableElement: dragableClone });
		}

		return;
	}

	// Set the new position of the dragable clone
	setPosition(dragableElement, pageX, pageY, calculatedX, calculatedY);

	// If the mouse position is close to the top or the bottom of the DragDrop wrapping HOC
	// then trigger autoscroll in that direction
	autoScrollHelper(state, scope, pageY, clientY);

	// If there is a valid hovered item then display possible drop zones depending on mouse positon within the element
	if ('hoveredItem' in state)
		analyzePossibleDrops(
			state.draggedItem,
			state.hoveredItem,
			clientX,
			clientY
		);

	// Call the provided callback function that were defined in DragDrop as a property (onDragMove)
	if (typeof callback === 'function')
		callback(state.draggedItem, state.hoveredItem, ev);
};

export default MouseMoveHandler;
