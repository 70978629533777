import React from 'react';
import { BlockGiftCardProps } from './BlockGiftCard.types';

const BlockGiftCard: React.FC<BlockGiftCardProps> = (props) => {

	const placeholder = props?.data?.placeholder ?? undefined;

	return (
		<div className="cms-block cms-blockGiftcard">
			<div className="blockGiftcardWrapper">
				<h3>
					<label htmlFor="giftcard">
						{props?.data?.title}
					</label>
				</h3>
				<div>
					{props?.data?.description}
				</div>
				<div className="blockGiftcard_inputWrapper">
					<input
						className="blockGiftcard_input"
						placeholder={placeholder}
						name="number"
					/>
					<button className="button -orange">
						{props?.data?.button_text}
					</button>
				</div>
				<div className="js-giftcard-data">
					Aktuellt saldo: 
					{' '}
					<span data-value="value">
						192
					</span>
					{' '}
					kr
					<br />
					Giltig t.o.m.: 
					{' '}
					<span data-value="valid">
						2023-04-01
					</span>
				</div>
			</div>
		</div>
	);
};

export default BlockGiftCard;