import React from 'react';
import styled, { css } from 'styled-components/macro';

const TableCell = (props) => {
	return (
		<Cell
			className={props.className}
			width={props.width}
			height={props.height}
			position={props.position}
			isSelected={props.isSelected}
		>
			{props.children}
		</Cell>
	);
};

export default TableCell;

const Cell = styled.div`
	vertical-align: middle;
	padding: 8px 16px 8px 0;
	font-size: 14px;
	text-align: left;
	overflow: hidden;
	display: table-cell;

	&:first-child {
		padding-left: 16px;
    }
    
    ${(props) =>
		props.width &&
		css`
			width: ${props.width}px;
		`}

	${(props) =>
		props.height &&
		css`
			height: ${props.height}px;
		`}
        
    ${(props) =>
		props.position &&
		css`
			position: ${props.position};
		`}

	${(props) =>
		props.isSelected &&
		css`
			color: white;
		`}
`;
