export interface Privileges {
	'groups.add'?: boolean;
	'groups.delete'?: boolean;
	'groups.edit'?: boolean;
	'groups.list'?: boolean;

	'media.folder.create'?: boolean;
	'media.folder.delete'?: boolean;
	'media.folder.edit'?: boolean;

	'media.item.delete'?: boolean;
	'media.item.edit'?: boolean;
	'media.item.upload'?: boolean;

	'navigation.tree.list'?: boolean;
	'navigation.item.archive'?: boolean;
	'navigation.item.create'?: boolean;
	'navigation.item.delete'?: boolean;
	'navigation.item.edit'?: boolean;
	'navigation.item.move'?: boolean;
	'navigation.item.moveAnywhere'?: boolean;

	'navigation.settings.edit'?: boolean;

	'users.add'?: boolean;
	'users.delete'?: boolean;
	'users.edit'?: boolean;
	'users.list'?: boolean;

	'settings.options.advanced': boolean;
	'settings.options.general': boolean;
	'settings.translations.list': boolean;

	'module.customers.list'?: boolean;
	'module.customers.delete'?: boolean;

	'debug.formResults'?: boolean;
}

export enum PrivilegeCategories {
	GROUPS = 'groups',
	MEDIA = 'media',
	NAVIGATION = 'navigation',
	USERS = 'users',
}

export enum GroupsPrivileges {
	LIST = 'groups.list',
	ADD = 'groups.add',
	EDIT = 'groups.edit',
	DELETE = 'groups.delete',
}

export enum MediaFolderPrivileges {
	CREATE = 'media.folder.create',
	EDIT = 'media.folder.edit',
	DELETE = 'media.folder.delete',
}

export enum MediaFilePrivileges {
	UPLOAD = 'media.item.upload',
	EDIT = 'media.item.edit',
	DELETE = 'media.item.delete',
}

export enum NavigationPrivileges {
	LIST = 'navigation.tree.list',
	CREATE = 'navigation.item.create',
	EDIT = 'navigation.item.edit',
	DELETE = 'navigation.item.delete',
	ARCHIVE = 'navigation.item.archive',
	MOVE = 'navigation.item.move',
	MOVE_ANYWHERE = 'navigation.item.moveAnywhere',
}

export enum NavigationSettingsPrivileges {
	EDIT = 'navigation.settings.edit',
}

export enum UsersPrivileges {
	LIST = 'users.list',
	ADD = 'users.add',
	EDIT = 'users.edit',
	DELETE = 'users.delete',
}

export enum SettingsPrivileges {
	OPTIONS_ADVANCED = 'settings.options.advanced',
	OPTIONS_GENERAL = 'settings.options.general',
	TRANSLATIONS_LIST = 'settings.translations.list',
}

// Modules

export enum OrderPrivileges {
	LIST = 'module.orders.list',
}

export enum CustomerPrivileges {
	LIST = 'module.customers.list',
	DELETE = 'module.customers.delete',
}

export enum ProductPrivileges {
	LIST = 'module.products.list',
}

export enum DebugPrivileges {
	FORM_RESULTS = 'debug.formResults'
}

export type PrivilegeTypes = PrivilegeCategories | GroupsPrivileges | MediaFolderPrivileges | MediaFilePrivileges | NavigationPrivileges | NavigationSettingsPrivileges | UsersPrivileges | SettingsPrivileges | OrderPrivileges | CustomerPrivileges | ProductPrivileges | DebugPrivileges;
