import React from 'react';
import styled, { css } from 'styled-components/macro';
import useAuth from '../../hooks/useAuth/useAuth';

import { InternalLink, Icon } from '../UI';

// @todo Store all Control panel pages and modules in the database and write them out dynamically
const NavigationList = (props) => {
	const { verifyUserPermission, verifyUserPermissions } = useAuth();

	// List all modules as links
	const linkComponents = props.items.map((item) => {
		let hasPermission = false;

		if (Array.isArray(item.permissions)) {
			const permissions = verifyUserPermissions(item.permissions);
			hasPermission = permissions.some;
		} else hasPermission = verifyUserPermission(item.permissions);

		if (item.permission && !hasPermission) {
			return (
				<ScTreeItem isDisabled={true} key={`link_${item.name}`}>
					<ScDisabledItemWrapper>
						<ScIcon fixedWidth icon={item.icon} />
						<ScText>{item.name}</ScText>
					</ScDisabledItemWrapper>
				</ScTreeItem>
			);
		}

		return (
			<ScTreeItem key={`link_${item.name}`}>
				{item.link.internal ? (
					<ScInternalLink to={item.link.to}>
						<ScIcon fixedWidth icon={item.icon} />
						<ScText>{item.name}</ScText>
					</ScInternalLink>
				) : (
					<ScLink
						href={item.link.to}
						target={item.link.target}
						rel="noopener noreferrer"
					>
						<ScIcon icon={item.icon} />
						<ScText>{item.name}</ScText>
					</ScLink>
				)}
			</ScTreeItem>
		);
	});

	return <ScNavigationList>{linkComponents}</ScNavigationList>;
};

// NavigationList.propTypes

export default NavigationList;

const ScNavigationList = styled.section`
	flex: 1 1 auto;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 24px;
`;

const ScLink = styled.a`
	width: 100%;
	text-decoration: none;
	color: var(--font-dark-grey-color);
`;

const ScInternalLink = styled(InternalLink)`
	width: 100%;
`;

const ScDisabledItemWrapper = styled.div`
	width: 100%;
`;

const ScIcon = styled(Icon)`
	color: var(--font-dark-grey-color);
`;

const ScText = styled.div`
	display: inline-block;
	margin-left: 8px;
	color: var(--font-dark-grey-color);
`;

const ScTreeItem = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	font-weight: 300;
	line-height: 40px;
	justify-content: space-between;
	padding: 0 10px 0 16px;
	user-select: none;

	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}

	:hover {
		background-color: var(--bg-grey-color);
		${ScText},${ScIcon},${ScLink} {
			color: var(--font-body-color);
		}
	}

	${(props) =>
		props.isActive &&
		css`
			${ScText},${ScIcon},${ScLink} {
				color: var(--font-body-color);
			}

			${ScText} {
				font-weight: 600;
			}
		`}
`;
