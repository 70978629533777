import React, { useState } from 'react';
import { Icon } from '../../../UI';

import styled from 'styled-components/macro';

const BlockTextExpander = ({ isExpandable, children, ...props }) => {
	const [isExpanded, setExpandedText] = useState(false);

	const isExpandedClass = isExpanded ? '--isExpanded' : '';

	const classes = ['blockTextWrapper', '--partially_hidden', isExpandedClass];

	const expandableText = (
		<div className={classes.join(' ')}>
			<div className="blockText" onClick={() => setExpandedText(true)}>
				{children}
			</div>
			<div className="blockTextExpand">
				<div
					className="blockTextBtn"
					onClick={() => setExpandedText(!isExpanded)}
				>
					{isExpanded ? 'Visa mindre' : 'Visa mer'}
					<ScIcon
						className="blockTextBtnIcon"
						icon={[
							'fal',
							isExpanded ? 'chevron-up' : 'chevron-down',
						]}
					/>
				</div>
			</div>
		</div>
	);

	return <>{isExpandable ? expandableText : children}</>;
};

export default BlockTextExpander;

const ScIcon = styled(Icon)`
	height: 16px;
	width: 16px;
`;
