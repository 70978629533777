import React from 'react';
import styled from 'styled-components/macro';

const StackingMultipleItems = (props) => {
	const images = props.data.map((obj, index) => (
		<ScItem key={`placeholder_${index}`}>
			<img alt="placeholder" src={obj.thumbnail} />
		</ScItem>
	));

	return <ScMultiDrag>{images}</ScMultiDrag>;
};

export default StackingMultipleItems;

const ScMultiDrag = styled.div`
	position: relative;
`;

const ScItem = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
	z-index: 1;
	background: #fff;
	transform: rotate(16deg);
	margin-left: 8px;

	&:first-child {
		transform: rotate(0);
		margin-left: 0;
	}

	&:last-child {
		transform: rotate(24deg);
		margin-left: 16px;
	}

	img {
		height: 100%;
	}
`;
