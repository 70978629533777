let state = {};

let actions = {
	ADD_INSTANCE: (currentState, payload) => {
		state = {
			...state,
			[payload.scope]: {
				...payload,
			},
		};

		return state;
	},
	ADD_DRAG_REFERENCE: (currentState, payload) => {
		state = {
			...currentState,
			draggedItem: {
				...payload,
			},
		};

		return state;
	},
	ADD_DRAGABLE: (currentState, payload) => {
		state = {
			...currentState,
			draggedItem: {
				...currentState.draggedItem,
				...payload,
			},
		};

		return state;
	},
	IS_HOVERED: (currentState, payload) => {
		state = {
			...currentState,
			hoveredItem: {
				...payload,
			},
		};

		return state;
	},
	RESET_HOVERED_ELEMENT: (currentState, payload) => {
		const updatedState = Object.keys(currentState).reduce((prev, key) => {
			if (key !== 'hoveredItem') prev[key] = currentState[key];

			return prev;
		}, {});

		return updatedState;
	},
	ADD_INSTRUCTION: (currentState, payload) => {
		state = {
			...currentState,
			instruction: {
				...payload,
			},
		};

		return state;
	},
	ADD_SCROLL_INTERVAL: (currentState, interval) => {
		state = {
			...currentState,
			scrollInterval: interval,
		};

		return state;
	},
	CLEAR_SCROLL_INTERVAL: (currentState) => {
		state = {
			...currentState,
			scrollInterval: null,
		};

		return state;
	},
	IS_RELEASED: (currentState, payload) => {
		state = Object.keys(currentState).reduce((previous, key) => {
			if (
				key !== 'draggedItem' &&
				key !== 'hoveredItem' &&
				key !== 'instruction'
			)
				previous[key] = currentState[key];
			return previous;
		}, {});

		return state;
	},
};

export const useDragDrop = () => {
	const dispatch = (action, payload = {}) => {
		return actions[action](state, payload);
	};

	return [state, dispatch];
};
