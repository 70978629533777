import React from 'react';
import { useSlate } from 'slate-react';
import ToolbarIcon from '../Toolbar/ToolbarIcon';
import ToolbarItem from '../Toolbar/ToolbarItem';
import { isMarkActive, toggleMark } from './marks-api';

const MarkButton = (props) => {
	const editor = useSlate();
	return (
		<ToolbarItem
			active={isMarkActive(editor, props.format)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, props.format);
			}}
		>
			<ToolbarIcon icon={props.icon} />
		</ToolbarItem>
	);
};

export default MarkButton;
