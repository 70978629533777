import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../../../utils/oc-axios';
import Search from '../../../components/Search/Search';

const useProductSearch = (
	selectedResultCallback,
	type = null,
	searchResultRelative = true
) => {
	if (!selectedResultCallback || typeof selectedResultCallback !== 'function')
		throw Error(
			'[useProductSearch] expects first parameter to be of type function'
		);

	// keeps track of search timer
	const searchTimerRef = useRef(null);

	// keeps track of current search result
	const [searchResult, setSearchResult] = useState(null);

	useEffect(() => {
		return () => {
			clearTimeout(searchTimerRef);

			if (typeof axios.triggerCancel === 'function') {
				axios.triggerCancel();
			}
		};
	}, []);

	/**
	 * Passes selected product to callback
	 * And clears result and search input
	 *
	 * @param {object} product
	 * @param {HTMLFormElmenet} input
	 */
	const selectedResultHandler = useCallback(
		(product, input) => {
			selectedResultCallback(product);

			setSearchResult(null);
			input.current.value = '';
		},
		[selectedResultCallback]
	);

	/**
	 * Performs a search after article number or name
	 * Will update the search result if > 0 results are found
	 *
	 * @param {Event} ev
	 */
	const criteriaChangedHandler = useCallback(
		(ev) => {
			const value = ev.target.value;

			// if a timer is set, then clear it
			if (searchTimerRef.current) {
				clearTimeout(searchTimerRef.current);
				searchTimerRef.current = null;
			}

			// set a new timer referece
			searchTimerRef.current = setTimeout(() => {
				if (value) {
					if (typeof axios.triggerCancel === 'function') {
						axios.triggerCancel();
					}

					const params = new URLSearchParams({
						query: value,
						type: type,
					});

					axios
						.get(`modules/products/search?${params.toString()}`, {
							cancelToken: new axios.CancelToken(
								function executor(c) {
									axios.triggerCancel = c;
								}
							),
						})
						.then((response) => {
							const data = response.data;
							setSearchResult(Object.values(data));
						})
						.catch((err) => {
							if (axios.isCancel(err)) {
								console.log('[RequestCancelled]', err);
							} else {
								console.log('[Server]', err);
							}
						});
				}
			}, 500);

			if (!value) setSearchResult(null);
		},
		[type]
	);

	/**
	 * Returns JSX
	 */
	const render = useCallback(() => {
		return (
			<Search
				changed={criteriaChangedHandler}
				data={searchResult}
				clicked={selectedResultHandler}
				placeholder="Sök efter art nummer eller namn"
				searchResultRelative={searchResultRelative}
				maxHeight={40}
			/>
		);
	}, [
		criteriaChangedHandler,
		searchResult,
		searchResultRelative,
		selectedResultHandler,
	]);

	return render;
};

export default useProductSearch;
