import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const TableRow = (props) => {
	return <ScTableRow>{props.children}</ScTableRow>;
};

TableRow.propTypes = {
	children: PropTypes.any.isRequired,
};

export default TableRow;

const ScTableRow = styled.div`
	display: table-row;
`;
