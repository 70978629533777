import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components/macro';
import { useDragDrop } from '../../hooks/useDragDrop';

const DragDrop = (props) => {
	/**
	 * To be able to have several instances of drag and drop where callbacks are handled individually we need to set
	 * a context where the functionality is used
	 *
	 * @Obligated
	 * @type {undefined} | string
	 */
	const scope = props.scope ? props.scope : undefined;

	/**
	 * The targetDocumentSelector is used to determine the document where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDocumentSelector = props.targetDocumentSelector
		? props.targetDocumentSelector
		: null;

	/**
	 * The targetDOMSelector is used to determine the DOM element where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDOMSelector = props.targetDOMSelector
		? props.targetDOMSelector
		: null;

	/**
	 * Receives a React component and translates it to plain html
	 *
	 * @type ReactComponent
	 */
	const dragPlaceholder = props.dragPlaceholder
		? ReactDOMServer.renderToString(props.dragPlaceholder)
		: null;

	/**
	 * Mouse events are mainly used internal to make the drag and drop happen
	 * However, if specified by props (see below) a callback is triggered to referenced function
	 * This allows the user to customize and/or trigger additional custom actions in the app
	 *
	 * Currently supporting the following mouse events:
	 * [EventType] -> [triggered to prop]
	 *
	 *
	 * Triggered when left mouse button is held pressed down on a valid draggable
	 * mouseDown -> mouseDown
	 *
	 * Returns in callback: draggedItem, event
	 */
	const mouseDown = props.onDragStart;

	/**
	 * Triggered when left mouse button is held pressed down on a valid draggable and coords are changing
	 * mouseMove -> onDragMove
	 *
	 * Returns in callback: draggedItem, hoveredItem, event
	 */
	const mouseMove = props.onDragMove;

	/**
	 * Triggered when left mouse button is released from a valid draggable
	 * mouseUp -> onDragEnd
	 *
	 * Returns in callback: draggedItem, hoveredItem, instruction, event
	 */
	const mouseUp = props.onDragEnd;

	/**
	 * Triggered when a valid draggable is moved over another valid draggable
	 * mouseOver -> onDragOver
	 *
	 * Returns in callback: draggedItem, hoveredItem, event
	 */
	const mouseOver = props.onDragOver;

	/**
	 * Triggered when a valid draggable is moving out from another valid draggable
	 * mouseOut -> onDragOut
	 *
	 * Returns in callback: draggedItem, hoveredItem, event
	 */
	const mouseOut = props.onDragOut;

	// /**
	//  * Check so that a "context" where the drag and drop should be used has been provided
	//  * If undefined or incorrect type write a warning to the console
	//  *
	//  * Returns in callback: draggedItem, hoveredItem, event
	//  */
	// if (scope === void 0 && typeof scope !== 'string') {
	// 	console.warn(
	// 		'[DragDrop] The mandatory "scope" property has to be defined as a string'
	// 	);
	// }

	// /**
	//  * Check so that the mandatory "onDragsEnd" has been set by the provided props
	//  * If undefined or incorrect type write a warning to the console
	//  */
	// if (props.onDragEnd === void 0 && typeof props.onDragEnd !== 'function') {
	// 	console.warn(
	// 		'[DragDrop] The mandatory "onDragEnd" property has to be defined as a function'
	// 	);
	// }

	/**
	 * In order to make this work we will create a custom hook
	 */
	const dispatch = useDragDrop()[1];

	/**
	 * Once a new DragDrop HOC is used we will store the instance of it as the component mounts
	 * The instance will be used to keep track of the specifik scope of where drag and drop functionality should be used
	 * Each scope provide their own call back functions and can thus handle e.g. state in a customized manner
	 */

	if(scope !== void 0) {
		dispatch('ADD_INSTANCE', {
			scope: scope,
			scrollInterval: null,
			dragPlaceholder: dragPlaceholder,
			targetDocumentSelector: targetDocumentSelector,
			targetDOMSelector: targetDOMSelector,
			callbackOnMouseDown: mouseDown,
			callbackOnMouseMove: mouseMove,
			callbackOnMouseUp: mouseUp,
			callbackOnMouseOver: mouseOver,
			callbackOnMouseOut: mouseOut
		});
	}

	/**
	 * Render the drag and drop wrapper and all it's children
	 */
	return (
		<ScDragWrapper
			id={`drag-scope-${scope}`}
			className={props.className}
		>
			{props.children}
		</ScDragWrapper>
	);
};

export default DragDrop;

const ScDragWrapper = styled('div')``;
