import React from 'react';
import styled, { css } from 'styled-components/macro';

const Tab = (props) => {
	return (
		<ScTabs
			onClick={(ev) => {
				ev.stopPropagation();
				props.clickHandler(ev);
			}}
			className={props.className}
			active={props.active}
		>
			{props.children}
		</ScTabs>
	);
};

export default Tab;

const ScTabs = styled.div`
	position: relative;

	cursor: pointer;
	flex-grow: 1;

	text-align: center;
	user-select: none;

	::after {
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;

		background: transparent;
		content: '';
		display: block;
		height: 1px;
		margin: 0 auto;
		transition: width 0.3s, background-color 0.3s;
		width: 0;
	}

	${(props) =>
		props.active &&
		css`
			::after {
				background-color: var(--bg-dark-color);
				width: 100%;
			}
		`}
`;
