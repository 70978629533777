import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TextareaProps, ScTextareaProps } from './model.Textarea';
import Label from '../Label/Label';

const Textarea: React.FC<TextareaProps> = (props) => {
	const inputRef = React.useRef<HTMLTextAreaElement>(null);

	// in case form validation is used fetch the unregister method from the object
	// doing it it this way will help useEffect to be more efficient instead of providing
	// the entire formValidation object in it's depts
	const unregisterElement = props.formValidationUnregister
		? props.formValidationUnregister
		: null;

	// Determines whether an error message should be shown or not
	const hasError =
		props.error && Array.isArray(props.error) && props.error.length > 0;

	React.useEffect(() => {
		return () => {
			// if form validation is used, then unregister listener when input component unmounts
			if(unregisterElement) {
				unregisterElement(props.id);
			}
		};
	}, [props.id, unregisterElement]);

	return (
		<Label
			label={props.label}
			description={props.description}
			isRequired={props.isRequired}
			className={props.className}
		>
			<ScTextarea
				className={props.className}
				id={props.id}
				resizeHorizontal={props.resizeHorizontal}
				resizeVertical={props.resizeVertical}
				resizeNone={props.resizeNone}
				placeholder={props.placeholder}
				onChange={(ev) => {
					ev.stopPropagation();
					if(props.changed) props.changed(ev, props);
				}}
				name={props.name}
				value={props.value}
				disabled={props.isDisabled}
				ref={props.inputRef ? props.inputRef : inputRef}
				readOnly={props.readOnly}
			/>
			{hasError && (
				<ScError>
					{props.error}
				</ScError>
			)}
			{props.info && (
				<ScInfo>
					{props.info}
				</ScInfo>
			)}
		</Label>
	);
};

const ScTextarea = styled.textarea<ScTextareaProps>`
	min-height: 80px;
	width: 100%;
	border: 1px solid #a9a9a9;
	padding: 8px;
	background: var(--bg-bright-color);
	color: var(--font-body-color);

	/* Apply disabled styling */
	&[disabled] {
		opacity: 0.5;
	}

	/* Apply read-only styling */
	&[readonly] {
		opacity: 0.8;
	}

	${(props) =>
		props.resizeHorizontal &&
		css`
			resize: horizontal;
		`}
	${(props) =>
		props.resizeVertical &&
		css`
			resize: vertical;
		`}
		${(props) =>
		props.resizeNone &&
		css`
			resize: none;
		`};
`;

const ScError = styled.div`
	background: rgba(208, 2, 27, 0.1);
	border: 1px solid #d0021b;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;
	color: #d0021b;

	&:last-child {
		margin-bottom: 16px;
	}
`;

const ScInfo = styled.div`
	background: rgba(169, 169, 169, 0.1);
	border: 1px solid #a9a9a9;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;

	&:last-child {
		margin-bottom: 16px;
	}
`;

export default Textarea;
