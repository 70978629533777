import React from 'react';
import styled from 'styled-components/macro';
import { isEmptyObject } from 'react-utils';
import MouseDownHandler from './MouseDownHandler';
import MouseOverHandler from './MouseOverHandler';
import MouseOutHandler from './MouseOutHandler';
import { useDragDrop } from '../../hooks/useDragDrop';
import blockRowHandle from '../../assets/icon-blockRowHandle.svg';

const Draggable = (props) => {
	const state = useDragDrop()[0];

	const enabledDrag =
		props.enableDrag === void 0 || props.enableDrag ? true : false;

	let output = (
		<DragWrapper
			className={props.className}
			id={props.id}
		>
			{props.children}
		</DragWrapper>
	);

	/**
	 * All the properties (that are not objects or functions) that is set to the Draggable will be stored
	 * That way you got all you can possibly need in the callbacks to update your state accordingly
	 *
	 * @param properties
	 * @returns {{}}
	 */
	const extractProperties = (properties) => {
		return Object.keys(properties).reduce((previous, prop) => {
			const property = properties[prop];

			if(
				typeof property !== 'object' &&
				typeof property !== 'function'
			) {
				previous[prop] = property;
			}

			return previous;
		}, {});
	};

	if(!isEmptyObject(state) && enabledDrag && props.scope in state) {
		const properties = extractProperties(props);

		const {
			callbackOnMouseDown,
			callbackOnMouseMove,
			callbackOnMouseUp,
			callbackOnMouseOver,
			callbackOnMouseOut
		} = state[props.scope];

		output = (
			<DragWrapper
				className={props.className}
				id={props.id}
				data-index={props.index}
				data-is-draghandle={props.isHandle === true}
				onMouseDown={(ev) =>
					MouseDownHandler(
						ev,
						callbackOnMouseDown,
						callbackOnMouseMove,
						callbackOnMouseUp,
						properties
					)}
				// onMouseMove={(ev) => MouseMoveHandler(ev, callbackOnMouseMove, properties)}
				// onMouseUp={(ev) => MouseUpHandler(ev, callbackOnMouseUp, properties)}
				onMouseOver={(ev) =>
					MouseOverHandler(ev, callbackOnMouseOver, properties)}
				onMouseOut={(ev) =>
					MouseOutHandler(ev, callbackOnMouseOut, properties)}
			>
				{!props.isHandle && props.useSeperateHandle && (
					<Handle data-is-draghandle="true">
						<img
							alt=""
							src={blockRowHandle}
						/>
					</Handle>
				)}
				{props.children}
			</DragWrapper>
		);
	}

	return output;
};

export default Draggable;

const Handle = styled('div')`
	height: 24px;
	width: 24px;
	background: #fbfbfb;
	border: 1px solid var(--border-grey-color);
	border-radius: 100%;
	position: absolute;
	left: -12px;
	top: -12px;
	z-index: 900;
	opacity: 0;
	cursor: all-scroll;
	text-align: center;

	img {
		pointer-events: none;
		user-select: none;
	}
`;

const DragWrapper = styled('div')`
	width: 100%;
	position: relative;
	display: flex;
	height: 100%;

	:hover {
		${Handle} {
			opacity: 1;
		}
	}
`;
