import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import { SkeletonNavItemProps } from './model.SkeletonNavItem';

const SkeletonNavItem: React.FC<SkeletonNavItemProps> = (props) => {
	return (
		<ScSkeletonNavItem
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={'24'}
			viewBox={'0 0 320 24'}
		>
			<rect
				x="16"
				y="0"
				width="24"
				height="24"
				rx="12"
			/>
			<rect
				x="48"
				y="4"
				width="192"
				height="16"
				rx="4"
			/>
		</ScSkeletonNavItem>
	);
};

export default SkeletonNavItem;

const ScSkeletonNavItem = styled(ContentLoader)`
	margin: 8px 0;
`;
