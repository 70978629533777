import React from 'react';
import styled from 'styled-components/macro';

const Loader = (props) => {
	let loader = (
		<ScLoader
			isDark={props.isDark}
			size={props.size}
			viewBox="0 0 50 50"
		>
			<circle
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="4"
			>
			</circle>
		</ScLoader>
	);

	if(!props.hasNoBackdrop) {
		loader = (
			<ScBackDrop
				className={props.className}
				opacityEnabled={props.opacityEnabled}
				isDark={props.isDark}
				hasNoBackdrop={props.hasNoBackdrop}
			>
				{loader}
			</ScBackDrop>
		);
	}

	return loader;
};

export default Loader;

const ScBackDrop = styled.div`
	display: flex;
	position: absolute;
	z-index: 99;
	width: 100%;
	height: 100%;
	background: ${(props) => (props.isDark ? '#eee' : '#555')};
	opacity: ${(props) => (props.opacityEnabled ? 0.8 : 1)};
	justify-content: center;
	align-items: center;
`;

const ScLoader = styled.svg`
	animation: rotate 2s linear infinite;
	z-index: 2;
	width: ${(props) => (props.size ? props.size : '50px')};
	height: ${(props) => (props.size ? props.size : '50px')};

	circle {
		stroke: ${(props) => (props.isDark ? '#555' : '#eee')};
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;
