import React from 'react';
import styled from 'styled-components/macro';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { LoginProps } from './model.Login';
import TextInput from '../Forms/TextInput/TextInput';
import { Button, Loader, InfoBox } from '../UI';
import { Credentials } from '../../auth/context/types';
import useFormValidation from '../../hooks/useFormValidation/useFormValidation';
import { ValidationTypes } from '../../hooks/useFormValidation/types';
import useAuth from '../../hooks/useAuth/useAuth';
import { LOGO_IMAGE } from '../../settings';

const Login: React.FC<LoginProps> = (props) => {
	const actionBtnRef = React.useRef<HTMLDivElement | null>(null);
	const { authUser, signIn, setPass, lostCredentials } = useAuth();

	// This hook returns the current location object. 
	const location = useLocation();

	// Intantiate formvalidation
	const {
		submit,
		errors,
		watch,
		registerElement,
		unregisterElement
	} = useFormValidation();

	// State handling the lost password form
	const [email, setEmail] = React.useState<string>('');

	// State handling the set password form
	const [password, setPassword] = React.useState<string>('');

	// State handling the status code result for any action towards backend
	const [statusCode, setStatusCode] = React.useState<number | null>(null);

	// State handling the login form
	const [credentials, setCredentials] = React.useState<Credentials>({
		username: '',
		password: ''
	});

	// Loading state by parent container
	const { isLoading } = props;

	/**
	 * Enables clicking enter to perform an action in any form in this view
	 */
	React.useEffect(() => {
		const triggerActionBtn = (ev: KeyboardEvent) => {
			if(ev.key === 'Enter') {
				actionBtnRef.current?.click();
			}
		};

		window.addEventListener('keydown', triggerActionBtn);

		return () => {
			window.removeEventListener('keydown', triggerActionBtn);
		};
	}, []);

	/**
	 * Clear states when location changes
	 */
	React.useEffect(() => {
		setEmail('');
		setPassword('');
		setStatusCode(null);
		setCredentials({ username: '', password: '' });
	}, [location.pathname]);

	/**
	 * Stores given login credentials in a state
	 *
	 * @param ev
	 * @param inputProps
	 */
	const credentialsChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputProps: any) => {
		const value = ev.target.value;
		const { name } = inputProps;

		setStatusCode(null);
		setCredentials((state) => ({
			...state,
			[name]: value
		}));
	}, []);

	/**
	 * Stores given email in a state
	 *
	 * @param ev
	 */
	const emailChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = ev.target.value;
		setStatusCode(null);
		setEmail(value);
	}, []);

	/**
	 * Stores given password in a state
	 *
	 * @param ev
	 */
	const passwordChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = ev.target.value;
		setStatusCode(null);
		setPassword(value);
	}, []);

	/**
	 * Handles the login action
	 * If formvalidation passes and a signInCallback was provided it will await the result of the action
	 *
	 * If the result is 200, the user will be automatically forwarded to the CMS by the withAuthentication HOC
	 * If the result is anything else a status code will be set and an error message will be displayed in the appropriate form
	 */
	const loginHandler = React.useCallback(() => {
		submit(async () => {
			if(signIn) {
				const status = await signIn(credentials);
				if(status !== 200) {
					setStatusCode(status);
				}
			}
		});
	}, [credentials, signIn, submit]);

	/**
	 * Handles setting a new password
	 * If formvalidation passes and a lostCredentialsCallback was provided it will await the result of the action
	 *
	 * A status code will be stored and used to provide feedback to the user
	 */
	const lostPasswordHandler = React.useCallback(() => {
		submit(async () => {
			if(lostCredentials) {
				const status = await lostCredentials(email);
				setStatusCode(status);
			}
		});
	}, [email, lostCredentials, submit]);

	/**
	 * Handles setting a new password
	 * If formvalidation passes and a setPassCallback was provided it will await the result of the action
	 *
	 * A status code will be stored and used to provide feedback to the user
	 */
	const setPasswordHandler = React.useCallback(async () => {
		submit(async () => {
			if(setPass) {
				const urlParams = new URLSearchParams(location.search);
				const hash = urlParams.get('hash');

				const status = await setPass(hash, password);
				setStatusCode(status);
			}
		});
	}, [password, location.search, setPass, submit]);

	const loader = React.useMemo(() => (
		<Loader
			hasNoBackdrop
			size="24px"
		/>
	), []);

	const loginScreen = React.useMemo(() => {
		return (
			<ScLogin>
				<ScTitle>
					Logga in
				</ScTitle>
				<ScDescription>
					Välkommen, logga in för att skapa nya sidor och hantera er
					hemsida.
				</ScDescription>
				<TextInput
					id="username"
					name="username"
					leftIcon={['fal', 'user']}
					label="Användarnamn"
					placeholder="Användarnamn"
					formValidationUnregister={unregisterElement}
					error={errors['username']}
					changed={(
						ev: React.ChangeEvent<HTMLInputElement>,
						...data
					) => {
						watch(ev, credentialsChangedHandler, data);
					}}
					inputRef={(ref) =>
						registerElement(ref, {
							required: true
						})}
				/>
				<TextInput
					id="password"
					name="password"
					leftIcon={['fal', 'lock']}
					type="password"
					label="Lösenord"
					placeholder="Lösenord..."
					formValidationUnregister={unregisterElement}
					error={errors['password']}
					changed={(
						ev: React.ChangeEvent<HTMLInputElement>,
						...data
					) => {
						watch(ev, credentialsChangedHandler, data);
					}}
					inputRef={(ref) =>
						registerElement(ref, {
							required: true
						})}
				/>

				{statusCode === 401 && (
					<InfoBox isError>
						Användarnamn eller lösenord är felaktigt. Försök igen
						eller klicka på Glömt inloggningsuppgifter?.
					</InfoBox>
				)}
				
				{statusCode === 429 && (
					<InfoBox isError>
						Du har försökt logga in för många gånger. Försök igen om några minuter.
					</InfoBox>
				)}

				<Button
					innerRef={actionBtnRef}
					onClick={loginHandler}
					isPrimary
					isLarge
				>
					{isLoading ? loader : 'Logga in'}
				</Button>
				{!authUser && (
					<ScLink to="/lost_password">
						Glömt inloggningsuppgifter?
					</ScLink>
				)}
			</ScLogin>
		);
	}, [
		authUser,
		credentialsChangedHandler,
		errors,
		isLoading,
		loader,
		loginHandler,
		registerElement,
		statusCode,
		unregisterElement,
		watch
	]);

	const lostPassword = React.useMemo(() => {
		return (
			<ScForgotLogin>
				<ScTitle>
					Glömt inloggningsuppgifter?
				</ScTitle>
				<ScDescription>
					Skriv in din e-postadress så skickar vi ett mail med
					användarnamn samt en länk som du kan följa för att sätta ett
					nytt lösenord.
				</ScDescription>
				<TextInput
					id="email"
					name="email"
					leftIcon={['fal', 'envelope']}
					label="E-postadress"
					placeholder="namn@exempel.se"
					formValidationUnregister={unregisterElement}
					error={errors['email']}
					changed={(
						ev: React.ChangeEvent<HTMLInputElement>,
						...data
					) => {
						watch(ev, emailChangedHandler, data);
					}}
					inputRef={(ref) =>
						registerElement(ref, {
							required: true,
							validation: {
								type: ValidationTypes.EMAIL
							}
						})}
				/>

				{statusCode === 204 && (
					<InfoBox>
						Om e-postadressen som du har angett finns registrerat i
						vårt system så har du nu fått instruktioner på mail för
						att fortsätta.
					</InfoBox>
				)}

				<Button
					innerRef={actionBtnRef}
					onClick={lostPasswordHandler}
					isPrimary
					isLarge
				>
					{isLoading ? loader : 'Skicka'}
				</Button>
				<ScLink to="/">
					Tillbaka till inloggningen
				</ScLink>
			</ScForgotLogin>
		);
	}, [
		emailChangedHandler,
		errors,
		isLoading,
		loader,
		lostPasswordHandler,
		registerElement,
		statusCode,
		unregisterElement,
		watch
	]);

	return (
		<ScContainer>
			<ScLogoContainer>
				<ScLogo src={LOGO_IMAGE} />
			</ScLogoContainer>

			{authUser && loginScreen}

			<Routes>
				{!authUser && (
					<>
						<Route
							path="/"
							element={loginScreen}
						/>
						<Route
							path="/lost_password"
							element={lostPassword}
						/>
					</>
				)}

				<Route
					path="/auth/password"
					element={(
						<ScSetPassword>
							<ScTitle>
								Sätt nytt lösenord
							</ScTitle>
							<ScDescription>
								Fyll i ett nytt lösenord nedan för att komma igång.
							</ScDescription>

							<TextInput
								id="password"
								name="password"
								leftIcon={['fal', 'lock']}
								type="password"
								label="Nytt lösenord"
								placeholder="Skriv in ett nytt lösenord."
								info={
								!password && !errors['password']
									? 'Lösenordet måste vara minst 8st tecken långt & innehålla minst en versal, ett nummer och ett specialtecken'
									: ''
							}
								formValidationUnregister={unregisterElement}
								error={errors['password']}
								changed={(
									ev: React.ChangeEvent<HTMLInputElement>,
									...data
								) => {
									watch(ev, passwordChangedHandler, data);
								}}
								inputRef={(ref) =>
									registerElement(ref, {
										required: true,
										validation: {
											type: ValidationTypes.REGEX,
											pattern: /^(?=.*[a-z])(?=.*\d)(?=.*[^\w\d]).*$/,
											min: 8,
											message:
											'Lösenordet måste vara minst 8st tecken långt & innehålla minst en versal, ett nummer och ett specialtecken'
										}
									})}
							/>

							{statusCode === 204 && (
								<InfoBox isSuccess>
									Ditt lösenord har ändrats!
								</InfoBox>
							)}

							{(statusCode === 404 || statusCode === 400) && (
								<InfoBox isError>
									Den här länken är ogiltig.
								</InfoBox>
							)}

							{statusCode === 422 && (
								<InfoBox isError>
									Den här länken är föråldrigad eller så möter
									inte lösenordet de kriterier som är uppsatta.
								</InfoBox>
							)}

							{statusCode === 201 && (
								<InfoBox isSuccess>
									Dina uppgifter har uppdaterats!
								</InfoBox>
							)}

							<Button
								innerRef={actionBtnRef}
								onClick={setPasswordHandler}
								isPrimary
								isLarge
							>
								{isLoading ? loader : 'Sätt lösenord'}
							</Button>
							<ScLink to="/">
								Tillbaka till inloggningen
							</ScLink>
						</ScSetPassword>
    				)}
				/>
			</Routes>
		</ScContainer>
	);
};

export default Login;

const ScContainer = styled.div`
	
	background: #fafafa;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	@media only screen and (min-width: 480px) {
		min-height: auto;
	}
	@media only screen and (min-width: 1024px) {
		width: 1024px;
		height: 480px;
		flex-direction: row;
	
	}
`;
const ScLogoContainer = styled.div`
	background: #888;
	
	padding: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (min-width: 480px) {
		padding: 56px 32px;
	}
	@media only screen and (min-width: 1024px) {
		height: 480px;
		width: 512px;
	}
`;

const ScLogo = styled.img`
	width: 100%;
	@media only screen and (max-width: 1024px) {
		width: 400px;
		max-width: 100%;
	}
`;

const ScForm = styled.div`
	background: #fafafa;
	
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 32px;
	@media only screen and (min-width: 480px) {
		padding: 56px 32px;
	}
	@media only screen and (min-width: 1024px) {
		padding: 0 72px;
		height: 100%;
		width: 512px;
	}
`;

const ScLogin = styled(ScForm)``;
const ScForgotLogin = styled(ScForm)``;
const ScSetPassword = styled(ScForm)``;

const ScTitle = styled.div`
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 8px;
`;

const ScDescription = styled.div`
	font-size: 14px;
	margin-bottom: 16px;
`;

const ScLink = styled(Link)`
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;
	text-decoration: underline;
	margin-top: 8px;
	color: var(--font-body-color);
	cursor: pointer;
	&:active,
	&:visited {
		color: var(--font-body-color);
	}
`;
