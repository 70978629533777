import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
	LinkSelectorProps,
	LinkType,
	ScLinkSelectorProps,
	ScLinkTypeProps
} from './LinkSelector.types';
import { CheckItem, Select } from '../Forms';
import TextInput from '../Forms/TextInput/TextInput';
import { Icon } from '../UI';
import { ValidationTypes } from '../../hooks/useFormValidation/types';
import { BUTTON_STYLES } from '../../settings';
import { CheckItemProps } from '../Forms/CheckItem/model.CheckItem';

const LinkSelector: React.FC<LinkSelectorProps> = (props) => {
	const { linkChanged, buttonClass } = props;
	const [activeLinkType, setActiveLinkType] = React.useState<LinkType>('slug');

	// Boolean that determines if the button styles should be shown
	const [showButtonStyles, setShowButtonStyles] = React.useState<boolean>(!!buttonClass);

	const formValidation = props.formValidation;
    
	/**
	 * Sets the active link type when a link type is clicked
	 * and calls the external link changed callback to reset the link value
	 * 
	 * @param linkType The link type that was clicked
	 * @returns void
	 */
	const linkTypeClickedHandler = React.useCallback((linkType: LinkType): void => {
		setActiveLinkType(linkType);

		// This should reset the value when called without a prop
		linkChanged(undefined, undefined, linkType);
	}, [linkChanged]);

	/**
	 * Sets the active link type when the active link type prop changes
	 */
	React.useEffect(() => {
		if(props.activeLinkType) setActiveLinkType(props.activeLinkType);
	}, [props.activeLinkType]);

	/**
	 * Toggles the showButtonStyles state.
	 * And If the buttonClass prop is set then call the link changed callback
	 * in order to reset the buttonClass prop
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} event 
	 * @param {CheckItemProps} props
	 * @returns {void}
	 */
	const showButtonStylesHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, props: CheckItemProps): void => {
		setShowButtonStyles(state => !state);

		if(!!buttonClass) {
			linkChanged(event, props);
		}
	}, [buttonClass, linkChanged]);

	return (
		<ScContainer
			className={props.className}
		>
			<ScHeader>
				{props.linkValue} 
				{(!props.linkValue) ? props.title : ''} 
				{(props.linkValue) && (
					<ScRemoveIcon
						onClick={props.linkChanged}
						icon={['fal', 'trash']}
					/>
				)}
			</ScHeader>

			{props.enabledLinkTypes && (
				<ScLinkSelector>
					<ScLinkTypeList>
						{Object.values(props.enabledLinkTypes).map((linkType) => (
							<ScLinkType
								key={linkType.value}
								onClick={() => linkTypeClickedHandler(linkType.value)}
								isActive={activeLinkType === linkType.value}
							>
								{linkType.label}
							</ScLinkType>
						))}
					</ScLinkTypeList>

					{activeLinkType === 'slug' && (
						<ScInternalLinkWrapper onClick={props.internalLinkClicked}>
							<ScInternalTextInput
								placeholder="Välj sida..."
								value={props.linkValue ?? ''}
								rightIcon={['fal', 'sitemap']}
								id="internal-link"
								isDisabled
								inputRef={(ref) =>
									formValidation?.registerElement(ref, {
                                	required: true,
                                	validation: {
                                		type: ValidationTypes.SLUG
                                	}
									})}
								formValidationUnregister={formValidation?.unregisterElement}
								error={formValidation?.errors['internal-link']}
							/>
						</ScInternalLinkWrapper>
					)}

					{activeLinkType !== 'slug' && (
						<>
							<TextInput
								id="url"
								changed={(ev: React.ChangeEvent<HTMLInputElement>, ...data) => {
									formValidation?.watch(ev, props.linkChanged, data);
								}}
								placeholder={props.enabledLinkTypes[activeLinkType]?.placeholder}
								value={props.linkValue ?? ''}
								rightIcon={props.enabledLinkTypes[activeLinkType]?.icon}
								name={props.enabledLinkTypes[activeLinkType]?.value}
								error={formValidation?.errors['url']}
								formValidationUnregister={formValidation?.unregisterElement}
								inputRef={(ref) => {
									const validationType = props.enabledLinkTypes ? props.enabledLinkTypes[activeLinkType]?.validation : ValidationTypes.URL;

									return formValidation?.registerElement(ref, {
										required: true,
										validation: {
											type: validationType ?? ValidationTypes.URL
										}
									});
								}}
							/>

							{activeLinkType === 'http' && (
								<CheckItem
									changed={props.linkChanged}
									type="checkbox"
									title="Öppna i ny flik"
									description="Länken öppnas i en ny flik i webbläsaren"
									checked={props.newWindowIsChecked}
									name="new_window"
								/>
							)}
						</>
					)}

					{props.isButtonStyleEnabled && BUTTON_STYLES.length > 0 && (
						<div>
							<CheckItem
								changed={showButtonStylesHandler}
								name="button_class"
								type="checkbox"
								title="Visa länk som knapp"
								checked={showButtonStyles}
							/>
							{showButtonStyles && (
								<Select
									changed={props.linkChanged}
									label="Välj utseende på knappen:"
									name="button_class"
									value={props.buttonClass}
								>
									<option value="">
										Välj utseende...
									</option>
									{BUTTON_STYLES.map((buttonStyle) => (
										<option
											key={buttonStyle.label}
											value={buttonStyle.class}
										>
											{buttonStyle.label}
										</option>
									))}
								</Select>
							)}
						</div>
					)}
				</ScLinkSelector>
			)}
		</ScContainer>
	);
};

export default LinkSelector;

const ScContainer = styled.div<ScLinkSelectorProps>`
	margin-bottom: 16px;

	:last-child {
		margin-bottom: 0;
	}
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;

const ScHeader = styled.div`
	min-height: 40px;
	height: auto;
	background: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	font-size: 14px;
	color: #fafafa;
`;

const ScLinkSelector = styled.div`
	background: #fafafa;
	box-shadow: 0px -1px 16px rgba(0, 0, 0, 0.25);
	padding: 16px;
`;

const ScLinkTypeList = styled.div`
	margin-bottom: 16px;
	display: flex;
	justify-content: flex-end;
`;

const ScLinkType = styled.div<ScLinkTypeProps>`
	font-size: 11px;
	font-weight: ${(props) => (props.isActive ? 600 : 300)};
	margin-right: 8px;
	cursor: pointer;

	::after {
		content: '/';
		font-weight: 300;
		padding-left: 8px;
	}

	:last-child {
		margin-right: 0;
		::after {
			content: none;
			padding-left: 0;
		}
	}
`;

const ScRemoveIcon = styled(Icon)`
	cursor: pointer;
`;

const ScInternalTextInput = styled(TextInput)`
	pointer-events: none;
`;

const ScInternalLinkWrapper = styled.div`
	margin-bottom: 16px;
`;