export type PaymentMethods = 'invoice' | 'card' | 'swish';

/**
 * The login form the customer used when placing the order.
 *
 * 1 = guest - new customer
 * 2 = semi - lite-logged-in
 * 3 = full - logged in with password
 */
export enum AuthenticationTypes {
	guest = 1,
	semi = 2,
	full = 3,
}

export interface FilterState {
	filterBy: OrderSearchFilters;

	filterByPaymethod: PaymentMethods;
}

export const ORDERS_FILTER_ALL = 'all';
export const ORDERS_FILTER_STATUS_FAILED = 'failed';
export const ORDERS_FILTER_STATUS_SUCCESS = 'successful';
export const ORDERS_FILTER_STATUS_NOT_SYNCHRONIZED = 'successful_not_synchronized';
export const ORDERS_FILTER_METHOD = 'method';

export type OrderSearchFilters =
	| typeof ORDERS_FILTER_ALL
	| typeof ORDERS_FILTER_STATUS_FAILED
	| typeof ORDERS_FILTER_STATUS_SUCCESS
	| typeof ORDERS_FILTER_METHOD;

export interface Country {
	/**
	 * Country code, e.g. "se"
	 */
	alpha2: string;

	/**
	 * Country name, e.g. "sweden"
	 */
	name: string;
}
export interface APIModuleOrdersResponse {
	/**
	 * All orders returned
	 */
	orders: OrderListItem[];

	/**
	 * Number of total results
	 */
	amount: string;
}

export interface Customer {
	/**
	 * The UUIDv4 identifiying the customer.
	 */
	uuid: string;

	/**
	 * Representation of customer C3 id
	 */
	id: number;

	/**
	 * Representation of customer Pyramid id
	 */
	number: string; // ???

	/**
	 * The login form the customer used when placing the order.
	 *
	 * 1 = guest,
	 * 2 = semi
	 * 3 = full
	 */
	logged_in: AuthenticationTypes;

	/**
	 * Customer e-mail address
	 */
	email: string;

	/**
	 * Customer's mobile number.
	 */
	mobile_phone: string;

	/**
	 * Customer's phone number.
	 */
	phone: string;

	/**
	 * Company name, if applicable.
	 */
	company: string | null;

	/**
	 * Customer first name
	 */
	firstname: string;

	/**
	 * Customer last name
	 */
	lastname: string;

	/**
	 * Customer ssn number
	 */
	ssn: string;

	/**
	 * Customer street address
	 */
	street: string;

	/**
	 * Customer postal/zip-code
	 */
	postal_code: string;

	/**
	 * Customer city
	 */
	city: string;

	/**
	 * Customer country
	 */
	country: Country;
}

export interface OrderItem {
	/**
	 * Article name name, the name of the sold product
	 */
	article_name: string;

	/**
	 * The pyramid number of the sold product
	 */
	article_number: string;

	/**
	 * Quantity purchased of the specific product
	 */
	quantity: number;

	/**
	 * Total price of qty * single price of the purchased products
	 */
	price: string;

	/**
	 * An array cointaing e.g. set items
	 */
	children: OrderItem[] | null;
}

export interface Shipping {
	/**
	 * A string representing the shipping method
	 */
	code: string;

	/**
	 * Whether the receiver is a company or not
	 */
	company: boolean;

	/**
	 * An array of payment method codes
	 * A shipping method may rule out certain payment methods
	 */
	excluded_payment_methods: string[];

	/**
	 * The cost of a specific shipping method as a number 10 000
	 */
	fee: number;

	/**
	 * The cost of a specific shipping method formatted "10 000"
	 */
	price: string;

	/**
	 * A description that describes a shipment fee
	 */
	fee_description: string;

	/**
	 * An internal number representing the ID of the shipping method
	 */
	id: number;

	/**
	 * Whether this shipment method is available or not
	 */
	inactive: boolean;

	/**
	 * The name of the shipping method
	 */
	name: string;

	/**
	 * An array of objects containing information of notification methods available
	 * A notification method may for example be Email and an object may be descriebed as:
	 * { code: 1, name: "Email" }
	 */
	notification_methods: { code: number; name: string }[];

	/**
	 * Whether this shipment method requires that the customer picks the parcel up
	 * at a representative.
	 */
	parcel_representatives: boolean;

	private: boolean;

	/**
	 * Backend reference for the representational order of this item
	 */
	sort_order: number;
	uuid: string;
	vat: number;
}
export interface Order {
	/**
	 * UUIDv4 identificating the order.
	 */
	id: string;

	/**
	 * The IP if the customer when it made the order.
	 * If the order was never completed, it will be the IP-address from when the created the order
	 */
	ip: string;

	/**
	 * The order number
	 */
	number: number;

	/**
	 * Whether the order is an additional order
	 */
	complementary: boolean;

	/**
	 * The total price of articles
	 */
	sum: string;

	/**
	 * The total price, including other fees such as shipping and payment fees as a string
	 */
	total: string;

	/**
	 * The total price, vat, including other fees such as shipping and payment fees as a number
	 */
	raw_total: number; // The total cost, the sum payed by the customer.

	/**
	 * The total VAT
	 */
	vat: string; // The vat cost, ex. 609.80

	/**
	 * Whether an order was paid or not
	 */
	paid: boolean;

	/**
	 * If the order has all the info needed to initiate payment
	 */
	complete: boolean;

	/**
	 * When the order was created
	 */
	created: string;

	/**
	 * Information about payment attempts
	 * If no payment were set it will deliver an empty array
	 */
	payments: PaymentHistory[]; // Info about the payment history

	/**
	 * If the order has a customer such an object will be used here
	 * Otherwise this value will point at null
	 */
	customer: Customer | null;

	/**
	 * Information about order items - the actual products ordered
	 * If no items were added it will deliver an empty array
	 */
	items: OrderItem[];

	/**
	 * Information about a giftcard being used as a payment method
	 * If no giftcards were used, it will deliver an empty array
	 */
	giftcards: Giftcard[];

	/**
	 * Information about the shipping
	 * If no shipping were set it will deliver null
	 */
	shipping: Shipping | null;

	/**
	 * Information about the payment
	 * If no payment were set it will deliver null
	 */
	payment: Payment | null;

	/**
	 * Information about the shipping requires a parcel representative
	 */
	parcel: Parcel | null;

	/**
	 * The id of the selected notification method
	 */
	notification_method_code: number;

	/**
	 * A string describing notification destination
	 * E.g. if method is email, the destination may be info@test.com
	 */
	notification_destination: string;

	/**
	 * Whether the order has been sent to Yotpo
	 */
	sent_to_yotpo: boolean;
}

export interface Giftcard {

	/**
	 * Giftcard number
	 */
	number: number;

	/**
	 * How much remains on the card after the order is placed
	 */
	remaining: number;

	/**
	 * How much was used on the card when the order is placed
	 */
	used: number;

	/**
	 * How much value the card has
	 */
	value: number;
}

export interface Parcel {
	/**
	 * An internal number as identifier
	 */
	id: number;

	/**
	 * A string describing the type of the package in a few letters
	 */
	type: string;

	/**
	 * The name of the parcel representative
	 */
	name: string;

	/**
	 * The street address of the parcel representative
	 */
	address: string;

	/**
	 * The postal/zip-code of the parcel representative
	 */
	postal_code: string;

	/**
	 * The city of the parcel representative
	 */
	city: string;

	/**
	 * The phone of the parcel representative
	 */
	phone: string;

	eligible: boolean;

	/**
	 * Whether the parcel representative is active or not
	 */
	inactive: boolean;

	/**
	 * An uuid used to identify the parcel representative in C3
	 */
	uuid: string;

	/**
	 * Below properties describes opening hours of parceö representative
	 */
	monday: null | string;
	tuesday: null | string;
	wednesday: null | string;
	thursday: null | string;
	friday: null | string;
	saturday: null | string;
	sunday: null | string;
	weekdays: null | string;

	/**
	 * A string describing parcel representative country, e.g. "Sweden"
	 */
	country: string;
}

export interface Payment {
	active: boolean;
	code: string;
	company: boolean;
	credit_control: boolean;
	credit_control_failure_code: null | string;
	credit_limit: null | number;
	description: null | string;
	fee: number;
	fee_description: null;
	id: number;
	name: string;
	price: string;
	private: boolean;
	sort_order: number;
	use_card: boolean;
	uuid: string;
	vat: number;
}

export interface PaymentHistory {
	/**
	 * An uuid representing the payment in C3
	 */
	uuid: string;

	reported: string;

	/**
	 * A number representing the payment method
	 */
	method: number;

	/**
	 * The name of the payment method.
	 */
	method_name: string;

	/**
	 * The amount of the payment
	 */
	amount: number;

	/**
	 * The currency of the payment
	 */
	currency: string;

	/**
	 * Language of the payment
	 */
	language: string;

	/**
1	 * DateTime of when the payment attempt was created
	 * E.g. 2020-12-11T09:31:48.000Z
	 */
	created: string;

	/**
	 * An array containg payment attempts
	 */
	attempts: PaymentAttempt[];

	/**
	 * Whether the payment was successful
	 */
	is_successfull: boolean;

	/**
	 * PSP reference
	 */
	reference: string | null;

	retrieve_details: boolean;
}

export interface PaymentAttempt {
	/**
	 * An uuid representing the payment attempt in C3
	 */
	uuid: boolean;

	/**
	 * DateTime of when the payment attempt was created
	 * E.g. 2020-12-11T09:31:48.000Z
	 */
	initiated: string;

	/**
	 * DateTime of when the payment attempt succeded
	 * E.g. 2020-12-11T09:31:48.000Z
	 */
	success: string | null;

	/**
	 * DateTime of when the payment attempt failed
	 * E.g. 2020-12-11T09:31:48.000Z
	 */
	failure: string | null;

	/**
	 * Code for the exception if any, otherwise null
	 */
	exception_code: string;
}

export interface OrderListItem {
	/**
	 * DateTime of when the order was sent to Pyramid's FTP.
	 * E.g. 2020-12-11T09:31:48.000Z
	 */
	synchronized: string | null;

	/**
	 * DateTime when the latest sucessful payment attempt was made.
	 */
	successful_payment: string | null;

	/***
	 * DateTime when the last payment attempt was made.
	 */
	latest_payment: string | null;

	/**
	 * DateTime when the order was created.
	 * 	(when the first product was added to the cart)
	 */
	created: string;

	/**
	 * Whether the order is an additional order
	 */
	complementary: boolean;

	/**
	 * Order's id on Pyramid
	 */
	number: number;

	/**
	 * Customers C3 id
	 */
	c3_id: string | null;

	/**
	 * Customers Pyramid id
	 */
	customer_number: string | null;

	/**
	 * Customer's full name
	 */
	name: string | null;

	/**
	 * Customer's email
	 */
	email: string | null;

	/**
	 * Customer's street
	 */
	street: string | null;

	/**
	 * Customer citu
	 */
	city: string | null;

	/**
	 * Customer complete address
	 */
	postal: string | null;

	/**
	 * Customer country
	 */
	country: string | null;

	/**
	 * Customer postal/zip-code
	 */
	postal_code: number | null;

	/**
	 * String describing the payment method
	 */
	payment_method: string;

	/**
	 * A formatted price string describing the total cost of an order
	 */
	currency: string;

	/**
	 * A formatted price string describing the total cost of an order
	 */
	total: string | null;

	sent_to_yotpo: boolean;
}
