import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ModuleContainerProps, StyledButtonProps } from './model.ModuleContainer';
import {
	ScContainer,
	ScInnerContainer,
	ScHeader,
	ScContent
} from './style.ModuleContainer';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import { Button, Loader } from '../../UI';

const ModuleContainer: React.FC<ModuleContainerProps> = (props) => {
	return (
		<ScContainer>
			<ScInnerContainer>
				<ScHeader>
					{props.header}
					{(props.buttonText || props.buttonIcon) && (
						<ScButton
							isSmall
							hasLargerFontSize
							isRounded
							isPrimary
							buttonIcon={props.buttonIcon}
							onClick={props.buttonOnClick}
							isDisabled={props.buttonIsDisabled}
							isLoading={props.buttonIsLoading}
						>
							{props.buttonIsLoading ? <Loader size="15px" /> : props.buttonText}
						</ScButton>
					)}
				</ScHeader>

				<ScContent className={props.className}>
					{props.children}
				</ScContent>
			</ScInnerContainer>
		</ScContainer>
	);
};

export default withErrorBoundary(ModuleContainer);

const ScButton = styled(Button)<StyledButtonProps>`
	${props => props.isLoading && css`
		width: 60px;
		overflow: hidden;
	`}
`;