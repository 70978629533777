import React, { SyntheticEvent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { DateTime } from 'luxon';
import {
	Order,
	OrderListItem,
	PaymentAttempt,
	PaymentHistory,
	AuthenticationTypes
} from '../Orders/model.Orders';
import {
	Table,
	TableCell,
	TableHead,
	TableHeading,
	TableBody,
	TableRow
} from '../../../../components/Table/index';
import { BasicContentModal, Button, Icon, Loader } from '../../../../components/UI';
import { fetchOrder } from '../../../../store/thunks/thunk-orders';
import { RootState } from '../../../../store/types/RootTypes';
import axios from '../../../../utils/oc-axios';
import { BASE_URL } from '../../../../settings';
import OrderSummary from '../../OrderSummary/OrderSummary';
import { ModalComponentProps } from '../../../../hooks/useModal/useModal.model';
import useModal from '../../../../hooks/Modal/useModal';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import { Textarea } from '../../../../components/Forms';
import { errorMessage } from '../../../../utils/errorHelpers';

const OrderModal: React.FC<ModalComponentProps<OrderListItem>> = (props) => {
	const dispatch: ThunkDispatch<null, any, AnyAction> = useDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const order: OrderListItem | undefined = props.modal?.currentState;
	const orderNumber = order?.number;

	// The complete order's object.
	const fullOrder: Order | null = useSelector(
		(state: RootState) => state.orderManagement.currentOrder
	);

	const customer = fullOrder?.customer;
	
	const notification = useAlert()[1] as AlertDispatch;

	// A modal to display Sendinblue data
	const SBDataModal = useModal();

	// A modal to display payment details
	const paymentDetailsModal = useModal();

	// Stores the order's Sendinblue data
	const [SBData, setSBData] = useState<string | null>(null);

	// Stores payment details
	const [paymentDetails, setPaymentDetails] = useState<string | null>(null);

	// State is true while fetching payment details or Sendinblue data
	const [isLoadingDetails, setIsLoadingDetails] = React.useState<boolean>(false);

	/**
	 * Fetch the order first time.
	 */
	useEffect(() => {
		if(!!orderNumber) {
			dispatch(fetchOrder(orderNumber)).then(() => {
				setIsLoading(false);
			});
		}
	}, [dispatch, orderNumber]);

	/**
	 * The synchronize date.
	 */
	const synchronizedDateTime = useMemo(
		() =>
			order?.synchronized
				? DateTime.fromISO(order?.synchronized)
					.toLocal()
					.setLocale('sv-SV')
				: 'Ej synkroniserad',
		[order]
	);

	const attemptStatusTimestamp = React.useCallback(
		(attempt: PaymentAttempt) => {
			if(attempt.success) {
				return (
					<ScSuccess>
						Lyckad
						{' '}
						{DateTime.fromISO(attempt.success)
							.toLocal()
							.setLocale('sv-SV')
							.toLocaleString(DateTime.DATETIME_SHORT)}
					</ScSuccess>
				);
			} else if(attempt.failure) {
				return (
					<ScFailure>
						Misslyckad
						{' '}
						{DateTime.fromISO(attempt.failure)
							.toLocal()
							.setLocale('sv-SV')
							.toLocaleString(DateTime.DATETIME_SHORT)}
					</ScFailure>
				);
			} else return null;
		},
		[]
	);

	/**
	 * The text to show of how the customer is logged in.
	 */
	const logginMethodText = useMemo(() => {
		switch(customer?.logged_in) {
			case AuthenticationTypes.guest:
				return 'Gäst';
			case AuthenticationTypes.semi:
				return 'Light';
			case AuthenticationTypes.full:
				return 'Lösenord';
			default:
				return 'N/A';
		}
	}, [customer]);

	/**
	 * Fetches the order's Sendinblue data and displays it in modal
	 * 
	 * @returns {void}
	 */
	const showSBDataModalHandler = React.useCallback(() => {
		setIsLoadingDetails(true);

		if(!SBData) {
			// Fetch Sendinblue data
			axios.get(`/modules/orders/sendinblue/W${orderNumber}`)
				.then((response) => {
					const data = JSON.stringify(response.data, null, 3);
					setSBData(data);
				})
				.catch((error) => {
					const message = errorMessage(error);

					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: message
					});
				})
				.finally(() => {
					setIsLoadingDetails(false);
				});
		}

		// Opens modal to display Sendinblue data
		SBDataModal.open({
			title: `Order ${orderNumber}`,
			width: '650px',
			height: '500px',
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (
						_originalState: null,
						_currentState: null,
						closeModal: () => void
					) => {
						closeModal();
					}
				}
			]
		});
	}, [SBData, SBDataModal, notification, orderNumber]);

	/**
	 * Fetches details about a payment and displays it in a modal
	 * 
	 * @param {PaymentHistory} payment
	 * @returns {void}
	 */
	const showPaymentDetailsHandler = React.useCallback((payment: PaymentHistory) => {
		setIsLoadingDetails(true);

		if(!paymentDetails) {
			// Fetch payment details
			axios.get(`/modules/orders/payments/${payment.method}/${payment.reference}`)
				.then((response) => {
					const paymentDetails = JSON.stringify(response.data, null, 3);
					setPaymentDetails(paymentDetails);
				})
				.catch((error) => {
					const message = errorMessage(error);
				
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: message
					});
				})
				.finally(() => {
					setIsLoadingDetails(false);
				});
		}

		// Open modal to display payment details
		paymentDetailsModal.open({
			title: `Order ${orderNumber}`,
			width: '650px',
			height: '500px',
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (
						_originalState: null,
						_currentState: null,
						closeModal: () => void
					) => {
						closeModal();
					}
				}
			]
		});
	}, [notification, orderNumber, paymentDetails, paymentDetailsModal]);

	return (
		<>
			{SBDataModal.getAsComponent(
				<BasicContentModal title="Sendinblue data">
					{isLoadingDetails && (
						<Loader
							hasNoBackdrop
							isDark
						/>
					)}
					{!isLoadingDetails && (
						<ScTextarea
							readOnly
							id="SBData"
							value={SBData ?? ''}
						/>
					)}
				</BasicContentModal>
			)}
			{paymentDetailsModal.getAsComponent(
				<BasicContentModal title="Betalningsdetaljer">
					{isLoadingDetails && (
						<Loader
							hasNoBackdrop
							isDark
						/>
					)}
					{!isLoadingDetails && (
						<ScTextarea
							readOnly
							id="paymentDetails"
							value={paymentDetails ?? ''}
						/>
					)}
				</BasicContentModal>
			)}
			{isLoading ? (
				<Loader />
			) : (
				<ScContainer>
					<ScHeading>
						Kunduppgifter
					</ScHeading>

					<ScDetailSection>
						<ScCustomerInfo>
							<div>
								<div>
									{`${customer?.firstname} ${customer?.lastname}`}
								</div>
								{customer?.company ? (
									<div>
										Företag:
										{' '}
										{customer?.company}
									</div>
								) : null}
								<div>
									{customer?.ssn}
								</div>
								<div>
									{customer?.street}
								</div>
								<div>
									{`${customer?.postal_code} ${customer?.city}, ${customer?.country?.name}`}
								</div>
								<div>
									Mobilnummer:
									{' '}
									{customer?.mobile_phone}
								</div>
								<div>
									Telefonnummer:
									{' '}
									{customer?.phone}
								</div>
							</div>
							<div>
								<div>
									E-postadress:
									{' '}
									{customer?.email}
								</div>
								<div>
									Kundnummer:
									{' '}
									{customer?.number ?? 'N/A'}
								</div>
								<div>
									C3 ID:
									{' '}
									{customer?.id ?? 'N/A'}
								</div>
								<div>
									{`Inloggad: ${logginMethodText}`}
								</div>
								<div>
									{`IP-address: ${fullOrder?.ip ?? 'N/A'}`}
								</div>
							</div>

							<div>
								<ScMargin>
									<strong>
										Synkroniserad
									</strong>
									<div>
										{synchronizedDateTime.toLocaleString(
											DateTime.DATETIME_SHORT
										)}
									</div>
								</ScMargin>

								<ScMargin>
									<strong>
										Tilläggsorder
									</strong>
									<div>
										{fullOrder?.complementary ? 'Ja' : 'Nej'}
									</div>
								</ScMargin>
								
								<strong>
									Skickad till Yotpo
								</strong>
								<div>
									{fullOrder?.sent_to_yotpo ? 'Ja' : 'Nej'}
								</div>
								
							</div>
							<ScActions>
								<ScButton
									isPrimary
									isSmall
									isRounded
									onClick={() => {
										if(!order?.synchronized) return;
										showSBDataModalHandler();
									}}
									isDisabled={!order?.synchronized}
								>
									Hämta SB data
								</ScButton>
								{fullOrder?.complete && (
									<>
										<ScButton
											isPrimary
											isSmall
											isRounded
											onClick={(ev: SyntheticEvent) => {
												ev.stopPropagation();
												window.location.href = `${BASE_URL}modules/orders/download/${orderNumber}`;
											}}
										>
											<Icon
												style={{ marginRight: 8 }}
												icon={['fal', 'file-code']}
											/>
											Ladda ner fil
										</ScButton>
										<ScButton
											isPrimary
											isSmall
											isRounded
											onClick={(ev: SyntheticEvent) => {
												ev.stopPropagation();
												window.print();
											}}
										>
											<Icon
												style={{ marginRight: 8 }}
												icon={['fal', 'print']}
											/>
											Skriv ut
										</ScButton>
									</>
								)}
							</ScActions>
						</ScCustomerInfo>
					</ScDetailSection>

					<ScDetailSection>
						<ScHeading>
							Betalningar
						</ScHeading>
						{fullOrder?.payments.map(
							(payment: PaymentHistory, pIndex: number) => (
								<ScPayment key={`payment_row_${pIndex}`}>
									<ScPaymentTitle>
										{payment.method_name}
										{payment.reference
											? `- Betalnings ID: ${payment.uuid}`
											: null}
									</ScPaymentTitle>

									<Table overFlowScroll>
										<TableHead>
											<TableHeading
												// isSortable={!isLoading}
												isActive={false}
												sortOrder="asc"
											>
												Initierad
											</TableHeading>
											<TableHeading
												// isSortable={!isLoading}
												isActive={false}
												sortOrder="asc"
											>
												Status
											</TableHeading>
											<TableHeading
												// isSortable={!isLoading}
												isActive={false}
												sortOrder="asc"
											>
												Felkod
											</TableHeading>
											<TableHeading
												// isSortable={!isLoading}
												isActive={false}
												sortOrder="asc"
											>
												Vår referens
											</TableHeading>
										</TableHead>

										<TableBody>
											{payment.attempts.map(
												(
													attempt: PaymentAttempt,
													aIndex: number
												) => {
													const initiatedDateTime = DateTime.fromISO(
														attempt.initiated
													)
														.toLocal()
														.setLocale('sv-SV');

													return (
														<TableRow
															key={`payment_attempt_row_${aIndex}`}
														>
															<TableCell>
																{initiatedDateTime.toLocaleString(
																	DateTime.DATETIME_SHORT
																)}
															</TableCell>
															<TableCell>
																{attemptStatusTimestamp(
																	attempt
																)}
															</TableCell>
															<TableCell>
																{
																	attempt.exception_code
																}
															</TableCell>
															<TableCell>
																{attempt.uuid}
															</TableCell>
															<TableCell>
																<ScButtonWrapper>
																	<Button
																		width={100}
																		isPrimary
																		isSmall
																		isRounded
																		isDisabled={!payment.retrieve_details}
																		onClick={() => {
																			if(!payment.retrieve_details) return; 
																			showPaymentDetailsHandler(payment);
																		}}
																	>
																		Detaljer
																	</Button>
																</ScButtonWrapper>
															</TableCell>
														</TableRow>
													);
												}
											)}
										</TableBody>
									</Table>
								</ScPayment>
							)
						)}
					</ScDetailSection>
					{fullOrder && (
						<ScDetailSection>
							<ScHeading>
								Order
							</ScHeading>

							<OrderSummary order={fullOrder} />
						</ScDetailSection>
					)}
				</ScContainer>
			)}
		</>
	);
};

export default OrderModal;

const ScContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	@media print {
		padding: 0;
	}
`;
const ScActions = styled.div`
	display: flex;
	flex-direction: column;
	@media print {
		display: none;
	}
`;

const ScDetailSection = styled.section`
	margin-bottom: 40px;

	:last-child {
		margin-bottom: 0;
	}
`;

const ScCustomerInfo = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ScFailure = styled.span`
	color: #d0021b;
`;

const ScSuccess = styled.span`
	color: #20b126;
`;

const ScHeading = styled.div`
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 16px;
`;

const ScPayment = styled.div`
	margin-bottom: 24px;
`;

const ScPaymentTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 16px;
	
`;
const ScButton = styled(Button)`
	margin-bottom: 16px;
`;

const ScTextarea = styled(Textarea)`
	height: 300px;
`;

const ScButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const ScMargin = styled.div`
	margin-bottom: 10px;
`;
