import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper';
import { DateTime } from 'luxon';

export const AlertPriorityTypes = {
	success: 'success',
	info: 'info',
	warning: 'warning',
	error: 'error',
	loading: 'loading',
};

// The hook is created once when used and all places that use this hook shares the state.

// the state,defined outside the hook so all consumers of the hook share the same data.
let globalState = [];

// listens to changes in the state
let listeners = [];

// actions to dispatch
let actions = {};

// the hook itself
const useAlert = () => {
	const setState = useState(globalState)[1];

	// parameter 2 can be a undefined amount of params.
	const dispatch = useCallback((actionIdentifier, data) => {
		const { alertID, priority, title, children, timeoutDuration } = data;
		// do some checks
		if (!actions.hasOwnProperty(actionIdentifier)) {
			throw new Error(
				`Tried to use action named '${actionIdentifier}' that is not registered, only ${Object.keys(
					actions
				)} available.'`
			);
		}

		const alert = actions[actionIdentifier]({
			currentState: globalState,
			alertID,
			priority,
			title,
			children,
			timeoutDuration,
		});

		globalState = alert.state;

		for (const listener of listeners) {
			listener(alert);
		}

		return alert.id;
	}, []);

	// register a component mounts
	useEffect(() => {
		listeners.push(setState);

		// remove the listener when the component unmount.
		return () => {
			listeners = listeners.filter((li) => li !== setState);
		};
	}, [setState]);

	return [globalState, dispatch];
};

/**
 * Initializes the hook.
 *
 * @param {*} useActions
 * @param {*} initialState
 */
const initAlertHook = (useActions, initialState) => {
	if (initialState) {
		globalState = [...globalState, ...initialState];

		actions = { ...actions, ...useActions };
	}
};

/**
 * Initializes the hook store with default parameters.
 */
const initAlertStore = () => {
	const defaultState = [];

	const actions = {
		/**
		 * Open an alert.
		 *
		 * @param {object} currentState             - The current state passed by the hook.
		 * @param {AlertPriorityTypes} priority     - The priority of the alert, see AlerPriority for available options.
		 * @param {string} title                    - The title in the alert
		 * @param {object} children                 - Any children that will be passed to the alert as 'children' prop.
		 * @param {number} timeoutDuration          - The duration of the timeout in the alert
		 */
		SHOW: ({
			currentState,
			alertID,
			priority = AlertPriorityTypes.info,
			title,
			children = <></>,
			timeoutDuration = 6000
		}) => {
			if (Object.values(AlertPriorityTypes).indexOf(priority) < 0) {
				throw new Error(
					`Can't show an Alert with priority '${priority}' that is not implemented, available are ${Object.keys(
						AlertPriorityTypes
					)}!`
				);
			}

			// hand-pick props instead of deconstructing to prevent users of sending custom data into the state
			const createdAt = DateTime.local();

			// use an id or generate random
			const id = alertID
				? alertID
				: Math.random().toString(36).substring(2) +
				  Math.random().toString(36).substring(2);

			return {
				id: id,
				state: update(currentState, {
					$push: [
						{
							// Datetime is in local time.
							id: id,
							createdAt: createdAt,
							priority: priority,
							title: title || '',
							children: children || <></>,
							timeout: setTimeout(() => {
								// remove the alert directly from the state...:s
								globalState = [...globalState].filter(
									(alert) => {
										return +alert.createdAt !== +createdAt;
									}
								);

								for (const listener of listeners) {
									listener(globalState);
								}
							}, timeoutDuration),
						},
					],
				}),
			};
		},

		MODIFY: ({
			currentState,
			alertID,
			priority = AlertPriorityTypes.info,
			title,
			children = <></>,
		}) => {
			if (Object.values(AlertPriorityTypes).indexOf(priority) < 0) {
				throw new Error(
					`Can't show an Alert with priority '${priority}' that is not implemented, available are ${Object.keys(
						AlertPriorityTypes
					)}!`
				);
			}

			const alertIndex = globalState
				.map((e) => {
					return e.id;
				})
				.indexOf(alertID);

			// Create new alert is disapeared already.
			if (alertIndex === -1)
				return actions.SHOW({
					currentState,
					alertID,
					priority,
					title,
					children,
				});

			const alertObj = globalState[alertIndex];
			const updatedAlert = update(alertObj, {
				$merge: {
					priority: priority,
					title: title || '',
					children: children || <></>,
					timeout: setTimeout(() => {
						// remove the alert directly from the state...:s
						globalState = [...globalState].filter((alert) => {
							return +alert.createdAt !== +alertObj.createdAt;
						});

						for (const listener of listeners) {
							listener(globalState);
						}
					}, 8000),
				},
			});

			return {
				state: update(currentState, {
					[alertIndex]: {
						$set: updatedAlert,
					},
				}),
			};
		},

		CLOSE: ({ currentState, createdAt }) => {
			// filter out the alert with the same createdAt as the date passed in the parameters
			const alertIndex = currentState.findIndex(
				(alert) => +alert.createdAt === +createdAt
			);

			return {
				state: update(currentState, {
					$splice: [[alertIndex, 1]],
				}),
			};
		},

		/**
		 * Close all current alerts.
		 *
		 * @param {object} currentState     - The current state passed by the hook.
		 */
		CLOSE_ALL: ({ currentState }) => {
			// reset state when we close all alerts.
			return { state: [...defaultState] };
		},
	};

	initAlertHook(actions, [...defaultState]);
};

export default useAlert;
export { initAlertStore };
