import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon } from '../../../../../components/UI';
import BlockEmpty from '../../../../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';

/**
 * Used to show the content in the settings modal for the SpecialOffer block.
 * 	The image and product are not connected int he way that is one of them is preset, the other may not
 * and the code have to separete those 2 parts and show them when present.
 *
 * @param data		Block data comming from Builder.
 */
const BlockSpecialOffer = (props) => {
	const data = props.data.special_offer;

	// TODO: media & media_mobile are the hardcoded prop names for now until back-end makes variants dinamic
	const imageSrc =
		props.viewInUse === 'desktop' ? data.media.src : data.mobile_media.src;

	const isEnabled = data.product_id && data.status === 'enable';

	const colors = data.colors
		? Object.values(data.colors)
				.filter((color, key) => key)
				.map((color, key) => (
					<option key={`color-${key}`} value={color.hex}>
						{color.name}
					</option>
				))
		: [];

	const sizes = data.sizes
		? Object.keys(data.sizes)
				.filter((size, key) => key)
				.map((size, key) => (
					<option key={`size-${key}`} value={size}>
						{size}
					</option>
				))
		: [];

	return (
		<div className="cms-block cms-blockProductsSpecialOffer">
			{/* <div className="blockSpecialOffer_title">Specialerbjudande</div> */}

			{!isEnabled && (
				<ScDisabled>
					<ScIcon icon={['fal', 'eye-slash']} />
				</ScDisabled>
			)}

			<ScDetails
				isDisabled={!isEnabled}
				className="blockSpecialOffer_container"
			>
				{/* Show the image if there is one for the current variant else a placeholder */}
				{imageSrc && 
					<img
						className="blockSpecialOffer_image"
						alt="Non-meaningful text"
						src={imageSrc}
					/>
				}

					{/* {imageSrc ? (
					<img
						className="blockSpecialOffer_image"
						alt="Non-meaningful text"
						src={imageSrc}
					/>
				) : (
					<BlockEmpty
						isBlockArea
						icon={['fal', 'image']}
						title="Ingen bild vald"
						description="Ingen bild vald för denna skärmvariant"
					></BlockEmpty>
				)} */}

				{/* Handle placeholder if there is or not produtk connected */}
				{data.product_id ? (
					<div className="blockSpecialOffer_info">
						{colors.length > 0 && (
							<>
								<label htmlFor="color">
									<span className="color-red">*</span> Färg
								</label>

								<div className="styledSelect -full blockSpecialOffer_input">
									<select id="color">{colors}</select>
									<i className="fal fa-chevron-down"></i>
								</div>
							</>
						)}

						{sizes.length > 0 && (
							<>
								<label htmlFor="size">
									<span className="color-red">*</span> Storlek
								</label>
								<div className="styledSelect -full blockSpecialOffer_input">
									<select id="size">{sizes}</select>
									<i className="fal fa-chevron-down"></i>
								</div>
							</>
						)}

						<div className="fieldset blockSpecialOffer_action">
							<div className="jsQtyInput qtyInput addToCart__qtyInput">
								<span
									className="js-updateProductQty"
									data-action="-"
								>
									<i className="fa fa-minus"></i>
								</span>
								<input
									type="number"
									name="qty"
									id="qty"
									maxLength="12"
									value="1"
									title="Välj antal"
									className="js-qtyInputValue inputText qty"
									data-validate='{"required-number":true,"validate-item-quantity":{"minAllowed":1}}'
									readOnly
								/>
								<span
									className="js-updateProductQty"
									data-action="+"
								>
									<i className="fa fa-plus"></i>
								</span>
							</div>
							<div className="blockSpecialOffer_button">
								<button
									type="button"
									title="Köp till"
									className="button -big -orange -full"
									id="product-addtocart-button"
								>
									<span className="button__text">
										Köp till
									</span>
									<span className="button__text -success">
										<i className="fal fa-check"></i>Tillagd
										i varukorgen
									</span>
									<i className="fal fa-spinner spinner"></i>
								</button>
							</div>
						</div>
						<div>
							<span className="color-orange">Rensa val</span>
						</div>
					</div>
				) : (
					<BlockEmpty
						isBlockArea
						icon={['fal', 'dollar-sign']}
						title="Ingen produkt valt"
						description="Gå till blockets inställningar för att koppla mot en produkt"
					></BlockEmpty>
				)}
			</ScDetails>
		</div>
	);
};

BlockSpecialOffer.propTypes = {};

export default BlockSpecialOffer;

const ScDisabled = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	background: rgba(102, 102, 102, 0.64);
	border-radius: 32px;
	position: absolute;
	top: 16px;
	right: 16px;
`;

const ScIcon = styled(Icon)`
	width: 24px;
	height: 16px;
	display: block;
`;

const ScDetails = styled.div`
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.4;
		`};
`;
