import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FilterDates, SearchStatisticsResult } from '../containers/SearchStatistics.types';
import axios from '../../../utils/oc-axios';

const useSearchStatistics = (params: FilterDates) => {
	/**
	 * Fetch search statistics data
	 * 
	 * @param {AbortSignal | undefined} signal 
	 * @returns {Promise<SearchStatisticsResult>}
	 */
	const fetchSearchStatistics = React.useCallback(async ({ signal }: { signal?: AbortSignal }): Promise<SearchStatisticsResult> => {
		try {
			const response = await axios.get('/modules/search/statistics', {
				params,
				signal
			});
			return response.data;
		} catch(error) {
			throw new Error('Det gick inte att hämta statistik för sökningar');
		}
	}, [params]);

	const query = useQuery(['search-statistics', params], fetchSearchStatistics, {
		refetchOnWindowFocus: false
	});

	return query;
};

export default useSearchStatistics;