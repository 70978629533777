import { ITALIC, STRONG, UNDERLINE } from '../../OC7Editor';

/**
 * Goes through nodes and wrap the texts accordingly to a format if one is set
 *
 * @param {Node[]} children
 * @returns {Node[] | Node}
 */
export const serializeMarks = (children) => {
	if (Array.isArray(children)) {
		return children.map((child) => {
			const keys = Object.keys(child);

			for (const key of keys) {
				switch (key) {
					case STRONG:
						child = {
							...child,
							text: `<strong>${child.text}</strong>`,
						};
						break;

					case ITALIC:
						child = {
							...child,
							text: `<em>${child.text}</em>`,
						};
						break;

					case UNDERLINE:
						child = {
							...child,
							text: `<u>${child.text}</u>`,
						};
						break;

					default:
				}
			}

			return child;
		});
	}

	return children;
};
