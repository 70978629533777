import React from 'react';
import styled from 'styled-components/macro';
import BlockContainer from './BlockContainer';
import Draggable from '../../hoc/DragDrop/Draggable';
import withErrorBoundary from '../../hoc/withErrorBoundary';

export const BlockColumnObject = {
	key: null,
	blocks: []
};

const MAX_COLUMNS_IN_ROW = 6;

const BlockColumn = (props) => {
	return (
		<ScContainer
			id={props.id}
			border={props.border}
			empty={props.is === 'empty'}
			className={`cms-blockColumn -col${props.width}`}
		>
			{props.blocks.map((block, blockIndex) => {
				return (
					<Draggable
						scope="page-builder"
						key={block.key}
						index={blockIndex}
						areaId={props.areaId}
						rowId={props.rowId}
						columnId={props.id}
						blockId={block.key}
						type="block"
						direction={
							props.amountColumns < MAX_COLUMNS_IN_ROW
								? 'any'
								: 'vertical'
						}
					>
						<BlockContainer
							key={block.key}
							id={block.key}
							updated={
								block.updated ? block.updated : 1
							}
							type={block.data.type}
							data={block.data}
							blockChanged={props.blockChanged}
							blockTypes={props.blockTypes}
							addBlock={props.addBlock}
							index={blockIndex}
							areaId={props.areaId}
							rowId={props.rowId}
							columnId={props.id}
							viewInUse={props.viewInUse}
							openLinkToolModal={props.openLinkToolModal}
							openBlockSettingsModal={
								props.openBlockSettingsModal
							}
							closeBlockSettingsModal={
								props.closeBlockSettingsModal
							}
							deleteBlockHandler={props.deleteBlockHandler}
							blockSettingsFormValidation={
								props.blockSettingsFormValidation
							}
						/>
					</Draggable>
				);
			})}
		</ScContainer>
	);
};

export default withErrorBoundary(BlockColumn);

const ScContainer = styled.div`
	/* display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 100%; */
`;
