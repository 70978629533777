import { useEffect } from 'react';
import { isEmptyObject } from 'react-utils';

const client = {
	on: () => void 0,
	removeAllListeners: () => void 0
};

const useSocket = (events = {}) => {
	/**
	 * Checks of the socket connection id is the client's connection
	 *
	 * @param {string} data
	 */
	const isMe = (data) => {
		return data.socket === client.id;
	};

	useEffect(() => {
		for(const event of Object.keys(events)) {
			client.on(event, (data) => {
				const callback = events[event];

				if(data && !isEmptyObject(data)) {
					if(!isMe(data) && typeof callback === 'function')
						events[event](data);
				}
			});
		}

		return () => {
			// this may need adjustment when more listeners for other
			// parts of the CMS is registered in future
			client.removeAllListeners();
		};
	}, [events]);

	return client;
};

export default useSocket;
