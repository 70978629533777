import React from 'react';
import { ScNavItemSettings, ScInnerWrapper, ScInfoText } from './PageSettings';
import { Select } from '../../../../components/Forms';
import { SUPPORTED_LANGUAGES } from '../../../../settings';

const AdvancedSettings = (props) => {
	return (
		<ScNavItemSettings hasLanguageSupport={SUPPORTED_LANGUAGES.length > 0}>
			{SUPPORTED_LANGUAGES.length > 0 && (
				<ScInfoText>
					Inställningar nedan appliceras på alla översättningar för sidan
				</ScInfoText>
			)}
			<ScInnerWrapper hasLanguageSupport={SUPPORTED_LANGUAGES.length > 0}>
				<Select
					label="Vald designmall"
					description="Här kan ni byta designmall för sidan"
					name="template_id"
					changed={(ev) =>
						props.changed(ev.target.name, parseInt(ev.target.value))}
					value={props.data.template_id}
				>
					{props.templates.map((template) => (
						<option
							key={`template_${template.id}`}
							value={template.id}
							disabled={!template.enabled}
						>
							{template.name}
						</option>
					))}
				</Select>
			</ScInnerWrapper>
		</ScNavItemSettings>
	);
};

export default AdvancedSettings;