import React from 'react';
import { PrivateRouteProps } from './model.PrivateRoute';
import PageContentWrapper from '../../Content/PageContentWrapper';

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	const RouteComponent = props.component;

	if(props.isAuth) {
		return <RouteComponent />;
	}

	return (
		<PageContentWrapper>
			Du har inte de rättigheter som krävs för att använda specifik
			funktionalitet
		</PageContentWrapper>
	);
};

export default PrivateRoute;
