import { DELETE_CUSTOMER, SET_CUSTOMER_LIST } from './actionTypes';
import {
	CustomerList,
	DeleteCustomerAction,
	SetCustomersAction
} from '../types/CustomerTypes';
import PaginationInfo from '../../definitions/PaginationInfo';

/**
 * Used when retrieving all customers
 * This function is called through a thunk
 *
 * @param {CustomerList} customersList
 * @returns {SetCustomersAction}
 */
export const setCustomersList = (
	customersList: CustomerList,
	paginationInfo: PaginationInfo
): SetCustomersAction => {
	return {
		type: SET_CUSTOMER_LIST,
		data: customersList,
		paginationInfo
	};
};

/**
 * Used to delete a specific customers
 * This function is called through a thunk
 *
 * @param {number} customerId
 * @returns {DeleteCustomerAction}
 */
export const deleteCustomerFromState = (
	customerId: number
): DeleteCustomerAction => {
	return {
		type: DELETE_CUSTOMER,
		data: customerId
	};
};
