/**
 * Returns the draggable bounds + offsetTop and offsetLeft
 * These values will be used through out the drag operation
 *
 * Note: it seems to be impossible to spread getBoundingClientRect() according to MDN (see below)
 *
 * "Properties in the returned DOMRect object are not own properties.
 * While the in operator and for...in will find returned properties, other APIs such as Object.keys() will fail.
 * Moreover, and unexpectedly, the ES2015 and newer features such as Object.assign() and object rest/spread will
 * fail to copy returned properties."
 *
 * @param element
 * @returns {{bottom: number, height: number, left: number, right: number, top: number, width: number, offsetLeft: null|*|number, offsetTop: null|*|number}}
 */
export const getElementExtendedBounds = (element) => {
	const bounds = element.getBoundingClientRect();
	const offsetLeft = element.offsetLeft;
	const offsetTop = element.offsetTop;

	return {
		width: bounds.width,
		height: bounds.height,
		top: bounds.top,
		left: bounds.left,
		x: bounds.x,
		y: bounds.y,
		offsetLeft,
		offsetTop,
	};
};

/**
 * Defines the document where the dragable should be live
 * A selector could thus point to an iFrame if that is where the dragable context is
 * The reason why this is important to define is that circumstances for the dragable may be different outside an iframe
 *
 * @param selector
 * @returns {Document}
 */
export const defineTargetDocument = (selector = null) => {
	if (!selector) return document;
	else return document.querySelector(selector).contentWindow.document;
};

/**
 * Defines the DOM element where the dragable is appended to
 *
 * @param selector
 * @returns {HTMLElement}
 */
export const defineDOMLivingSpace = (
	selector = null,
	documentSelector = null
) => {
	const targetDocument = defineTargetDocument(documentSelector);
	return targetDocument.body;
};

/**
 * All the properties (that are not functions or objects) that is set to the Draggable will be stored
 * That way you got all you can possibly need in the callbacks to update your state accordingly
 *
 * @param properties
 * @returns {{}}
 */
export const getProperties = (properties = {}) => {
	return Object.keys(properties).reduce((previous, prop) => {
		const property = properties[prop];

		if (typeof property !== 'object' && typeof property !== 'function') {
			previous[prop] = property;
		}

		return previous;
	}, {});
};

/**
 * Sets the position of the dragable element
 *
 * @param element
 * @param pageX
 * @param pageY
 * @param calculatedX
 * @param calculatedY
 */
export const setPosition = (
	element = {},
	pageX = 0,
	pageY = 0,
	calculatedX = 0,
	calculatedY = 0
) => {
	const positionX = `${pageX - calculatedX}px`;
	const positionY = `${pageY - calculatedY}px`;

	element.style.left = positionX;
	element.style.top = positionY;
};

export const findScrollableParent = (node) => {
	if (!node || node.nodeName === '#document') return null;

	const regex = /(auto|scroll)/;

	const style = (node, prop) => {
		getComputedStyle(node, null).getPropertyValue(prop);
	};

	const styleCheckNode = (node) => {
		return regex.test(
			style(node, 'overflow') +
				style(node, 'overflow-y') +
				style(node, 'overflow-x')
		);
	};

	const heightCheckNode = (node) => {
		return node.scrollHeight > node.clientHeight;
	};

	switch (true) {
		case styleCheckNode(node):
			return node;

		case heightCheckNode(node):
			return node;

		default:
			return findScrollableParent(node.parentNode);
	}
};

export const autoScrollHelper = (state, scope, pageY, clientY) => {
	const scopeState = state[scope];
	const targetDocumentSelector = scopeState.targetDocumentSelector;
	const targetDocument = defineTargetDocument(targetDocumentSelector);
	const dndScopeContainer = targetDocument.getElementById(
		`drag-scope-${scope}`
	);

	const scrollableElement = findScrollableParent(dndScopeContainer);

	// if the drag and drop is not used within a scrollable context
	// then just return here to save it from additional checks
	if (!scrollableElement) return;

	const scrollableElementRect = scrollableElement.getBoundingClientRect();
	let scrollableElementHeight = scrollableElementRect.height;
	let mouseTopPositionWithinScrollable = clientY - scrollableElementRect.top;

	if (scrollableElement.tagName === 'HTML') {
		mouseTopPositionWithinScrollable = clientY;
		scrollableElementHeight = window.innerHeight;
	}

	switch (true) {
		case mouseTopPositionWithinScrollable < 25:
			if (!scopeState.scrollInterval) {
				scopeState.scrollInterval = setInterval(() => {
					scrollableElement.scrollTop--;
				}, 5);
			}
			break;

		case scrollableElementHeight - mouseTopPositionWithinScrollable < 25:
			if (!scopeState.scrollInterval) {
				scopeState.scrollInterval = setInterval(() => {
					scrollableElement.scrollTop++;
				}, 5);
			}

			break;

		default:
			if (scopeState.scrollInterval) {
				clearInterval(scopeState.scrollInterval);
				scopeState.scrollInterval = null;
			}
	}
};
