import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactTableCellProps, ScTableCellProps } from './ReactTableCell.types';

const ReactTableCell: React.FC<ReactTableCellProps> = (props) => {
	return (
		<ScCell
			maxWidthCell={props.maxWidthCell}
			className={props.className}
		>
			{props.children}
		</ScCell>
	);
};

export default ReactTableCell;

export const ScCell = styled.div<ScTableCellProps>`
	vertical-align: middle;
	padding: 8px 16px 8px 0;
	font-size: 14px;
	text-align: left;
	display: table-cell;
	&:first-child {
		padding-left: 16px;
	}

	${props => props.maxWidthCell && css`
		max-width: 400px;
		overflow: hidden;
		text-overflow: ellipsis;
	`}
`;
