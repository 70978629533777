import React from 'react';
import { HomePageResult } from './useHomePages.types';
import useAlert, { AlertPriorityTypes } from '../useAlert';
import axios from '../../utils/oc-axios';
import { AlertDispatch } from '../useAlert/types.useAlert';

const useHomePages = () => {
	// Notification used to inform user about startpages
	const notification = useAlert()[1]  as AlertDispatch;
    
	/**
	 * Fetch home pages and show an alert if there is an discrepancy or overlap between pages
	 * 
	 * @return {void}
	 */
	const checkHomePages = React.useCallback((): void => {
		axios.get('pages/homepages').then((resp) => {
			const data = resp.data;

			// if there is no home page
			if(data.items.length === 0) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: 'Det finns ingen startsida!',
					timeoutDuration: 12000
				});
			}

			if(data.fluent === false) {
				// if there is only one home page and it's in the future or has no one to follow
				if(data.items.length === 1) {
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: 'Det finns en tidsperiod där ingen startsida är satt!',
						children: data.message,
						timeoutDuration: 12000
					});
				}

				// if there is a gap between home pages
				if(data.items.length > 1) {
					const discrepantPages = data.items.filter((page: HomePageResult) => page.status === 'discrepancy' || page.status === 'finite');
                
					const message = getMessage(discrepantPages);
                
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: 'Det finns en lucka där ingen startsida är satt!',
						children: message,
						timeoutDuration: 12000
					});
				}
			}

			// if there is home pages overlapping each other
			if(data.fluent && data.flawless === false) {
				const overlappingPages = data.items.filter((page: HomePageResult) => page.status === 'overlap');

				const message = getMessage(overlappingPages);

				notification('SHOW', {
					priority: AlertPriorityTypes.warning,
					title: 'Det finns en tisperiod där två startsidor överlappar varandra.',
					children: message,
					timeoutDuration: 12000
				});
			}
		}).catch(() => {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Det gick inte att utföra en kontroll på startsidor just nu.',
				children: 'Detta görs för att förhindra att det uppstår en lucka där ingen startsida är satt',
				timeoutDuration: 12000
			});
		});
	}, [notification]);

	return {
		checkHomePages
	};
};

export default useHomePages;

/**
 * Loop through pages and find the relevant dates and page names, merge into one string
 *
 * @param {HomePageResult[]} pages
 * @return {string | undefined}
 */
const getMessage = (pages: HomePageResult[]): string | undefined => {
	let message: string | undefined = undefined;

	pages.forEach(page => {
		// Return the page's inital message if its status === 'finite'
		if(page.status === 'finite') {
			message = `${page.name}: ${page.message}`;
			return;
		}

		// Format dates
		const unpublish_date = page.unpublish_date ? 
			new Intl.DateTimeFormat('sv-SV', {
				dateStyle: 'medium',
				timeStyle: 'medium'
			}).format(new Date(page.unpublish_date)) : null;

		const next_publish_date = page.next_publish_date ? new Intl.DateTimeFormat('sv-SV', {
			dateStyle: 'medium',
			timeStyle: 'medium'
		}).format(new Date(page.next_publish_date)) : null;

		// merge messages to one string, with linebreak as separator
		const string = `"${page.name}" avpubliceras ${unpublish_date}, nästa startsida publiceras ${next_publish_date}.`;
		message = message ? message.concat('\n', string) : string;
	});

	return message;
};