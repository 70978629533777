import React from 'react';

import styled from 'styled-components/macro';

import BlockDefinitions from '../Builder/BlockDefinitions';
import DialogAddItem from './DialogAddItem';
import DialogSection from './DialogSection';
import ModuleDefinitions from '../../modules/ModuleDefinitions';

const ChooseBlockDialog = (props) => {
	const { allowedBlocktypes, blockTypeSelected } = props.modal.currentState;

	const output = [];

	const allowedBlocks = Object.keys(allowedBlocktypes);

	/*
	 Process "Core" / default blocks first
	 */
	const coreBlocksButtons = Object.keys(BlockDefinitions).reduce(
		(result, blockName) => {
			const blockObj = BlockDefinitions[blockName];

			const isModuleBlock = blockObj.isModuleBlock;

			const blockInfo = allowedBlocktypes[blockName];

			// Only process non-modules blocks first as core7modules blocks are in the same object
			if (!isModuleBlock && blockInfo && blockInfo.selectable) {
				result.push(
					<DialogAddItem
						key={`selectNavType_${blockName}`}
						title={blockInfo.name}
						fIcon={['fal', blockInfo.icon]}
						pressed={() =>
							blockTypeSelected(props.modal, blockName)
						}
					/>
				);
			}

			return result;
		},
		[]
	);

	// Add core blocks category/section
	output.push(
		<DialogSection key={`section_core`} title="Kärnblock">
			{coreBlocksButtons}
		</DialogSection>
	);

	/* 
		Process module's blocks
	*/
	for (const [moduleName, moduleData] of Object.entries(ModuleDefinitions)) {
		const blocks = moduleData.blocks;

		// don't continue processing module if module haves no blocks,
		//	prevents showing an empty Section in the modal when adding blocks.
		if (Object.keys(blocks).length === 0) continue;

		// Loop the current module's blocks
		const blocksButtons = [];
		for (const [blockName, blockData] of Object.entries(blocks)) {
			const isOnlyBlock = Object.keys(blocks).length <= 1;

			const blockPath = isOnlyBlock
				? blockName
				: `${moduleName}-${blockName}`;

			const blockInfo = allowedBlocktypes[blockPath];

			// add block to list only if it is addable or addable is null (not defined)
			// & is allowed
			if (blockData.isAddable && allowedBlocks.includes(blockPath)) {
				// add block to a category with the module name.

				blocksButtons.push(
					<DialogAddItem
						key={`selectNavType_${blockPath}`}
						title={blockInfo.name}
						fIcon={['fal', blockInfo.icon]}
						pressed={() =>
							blockTypeSelected(props.modal, blockPath)
						}
					/>
				);
			}
		}

		output.push(
			<DialogSection
				key={`section_${moduleName}`}
				title={moduleData.displayName}
			>
				{blocksButtons}
			</DialogSection>
		);
	}

	return <ScAddBlock>{output}</ScAddBlock>;
};

export default ChooseBlockDialog;

const ScAddBlock = styled.div`
	display: flex;
	flex-direction: column;
`;
