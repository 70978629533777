import React from 'react';
import { useSlate } from 'slate-react';
import ToolbarItem from '../Toolbar/ToolbarItem';
import { isLinkActive, unwrapLink } from '../LinkButton/links-api';
import ToolbarIcon from '../Toolbar/ToolbarIcon';

const DeleteLinkButton: React.FC = () => {
	const editor = useSlate();
    
	return (
		<ToolbarItem
			active={isLinkActive(editor)}
			onMouseDown={(event) => {
        	    event.preventDefault();

				if(isLinkActive(editor)) {
					unwrapLink(editor);
				}
			}}
		>
			<ToolbarIcon icon={['fas', 'link-slash']} />
		</ToolbarItem>
	);
};

export default DeleteLinkButton;