import styled from 'styled-components/macro';

export const ScOrderSummary = styled.div`
	width: 100%;
	margin-top: 24px;
	border: 1px solid #d3d3d3;

	table,
	tbody,
	tr {
		width: 100%;
		text-align: left;
		border-spacing: 0;
		border-collapse: collapse;
		background-color: #fff;
	}
	td,
	th {
		padding: 16px;
	}

	th {
		background-color: #d3d3d3;
	}
`;

export const ScDivider = styled.tr`
	border-bottom: 1px solid #ddd;
`;
