import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const TableHeading = (props) => {
	return <ScTableHeading>{props.children}</ScTableHeading>;
};

TableHeading.propTypes = {
	children: PropTypes.any,
};

export default TableHeading;

const ScTableHeading = styled.div`
	text-align: left;
	vertical-align: middle;
	&:first-child {
		padding-left: 16px;
	}

	display: table-cell;
`;
