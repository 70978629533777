import { useCallback } from 'react';
import {
	identifyRelationsDownwards,
	identifyRelationsUpwards,
} from '../../../components/Tree/Tree';

const useCommonProduct = () => {
	/**
	 * Marks products as "ready"
	 * A requirement for this is that every selected product has categories assigned
	 *
	 * @param {array} selectedProductIds
	 * @param {array} products
	 */
	const markProductsAsReadyHelper = useCallback(
		(selectedProductIds, products) => {
			// find selected products without assigned categories
			const productsWithoutCategories = selectedProductIds.filter(
				(productId) => {
					const productIndex = products.findIndex(
						(product) => product.id === productId
					);

					const product = products[productIndex];

					if (product.categories.length === 0) return productId;
					return null;
				}
			);

			return {
				productsWithoutCategories: productsWithoutCategories,
				selectedProductIds: selectedProductIds,
			};
		},
		[]
	);

	/**
	 * Handles the checking of categories in tree
	 *
	 * @param {Event} ev
	 * @param {object} props
	 */
	const addToCategoryHelper = useCallback(
		(selectedCategories, categories, categoryId) => {
			// in case we are unselecting a category from the tree
			// then make sure selected children recursivily are deselecte
			// in case we are unselecting a category from the tree
			// then make sure selected children recursivily are deselecte
			if (selectedCategories.includes(categoryId)) {
				const children = categories[categoryId].children;

				// calculate deselection
				const childrenToUnCheck = identifyRelationsDownwards(
					categories,
					children
				).concat(categoryId);

				// calculates the selected categories after change
				const updatedState = selectedCategories.filter(
					(itemId) => !childrenToUnCheck.includes(itemId)
				);

				// dispatches to update state
				return updatedState;
			} else {
				const checkedItems = identifyRelationsUpwards(
					categories,
					categoryId
				);

				// create an updated state
				const updatedState = [
					...new Set(
						selectedCategories.concat(checkedItems, categoryId)
					),
				];

				// dispatch to store updated state in redux
				return updatedState;
			}
		},
		[]
	);

	return [addToCategoryHelper, markProductsAsReadyHelper];
};

export default useCommonProduct;
