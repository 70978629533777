import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

/**
 *
 * @param {array} actions       Array of actions to render.
 * @param {object} rowItemData	TThe props passed to the TableRow (props for the item in itemManager)
 */
const TableRowActions = (props) => {
	/**
	 * Set key, onClick action, index and text for each button
	 *
	 * @type {*[]}
	 */

	const actions = props.actions.map((action, index) => (
		<ScActionButton
			onClick={(ev) => {
				ev.stopPropagation();
				action.action(props.rowItemData);
			}}
			key={`action_${index}`}
		>
			{action.component}
		</ScActionButton>
	));

	return (
		<ScTableRowActions className={props.className} style={props.style}>
			{actions}
		</ScTableRowActions>
	);
};

TableRowActions.propTypes = {
	actions: PropTypes.array,

	rowItemData: PropTypes.object,
};

export default TableRowActions;

// const ScActionButtonWrapper = styled.td`
// 	background-color: var(--bg-dark-grey-color);
// 	position: relative;
// 	width: 96px;
// `;

const ScTableRowActions = styled.div`
	background-color: var(--bg-dark-grey-color);
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
`;

const ScActionButton = styled.div`
	flex: 1;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	:hover {
		background-color: #333;
	}
`;
