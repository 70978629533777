import React from 'react';
import styled, { css } from 'styled-components/macro';
import moment, { Moment, isMoment } from 'moment';
import 'moment/locale/sv';
import Datetime from 'react-datetime';
import { DateTimePickerProps, StyledDateTimePickerProps } from './DateTimePicker.types';
import { Icon } from '..';
import Label from '../../Forms/Label/Label';

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
	// props are destructured this way to help useCallback in functions below
	// useCallback were asking the full props object, which sort of renders it useless when trying to optimize
	const { minDate, maxDate, label, description, secondaryColor, isDisabled, inputRef } = props;

	/**
	 * Will enable/disable dates if minDate and/or maxDate is set
	 * If those props are not provided - then all dates are active
	 *
	 * @param {Moment} currentDate
	 * @returns {boolean}
	 */
	const setValidDates = React.useCallback((currentDate: Moment): boolean => {
		switch(true) {
			case minDate && minDate.length > 0 &&
				 maxDate && maxDate.length > 0:
				return (
					currentDate.isBefore(moment(maxDate)) &&
						currentDate.isAfter(moment(minDate))
				);

			case minDate && minDate.length > 0:
				return currentDate.isAfter(moment(minDate));

			case maxDate && maxDate.length > 0:
				return currentDate.isBefore(moment(maxDate));

			default:
				return true;
		}
	}, [maxDate, minDate]);

	/**
	 * Will render a customized input for the calendar
	 * This input is disabled, which requires the calendar to be opened/closed via buttons
	 *
	 * @param {React.HTMLProps<HTMLInputElement>} props
	 * @param {Function} openCalendar
	 * @param {Function} closeCalendar
	 * @returns {JSX.Element}
	 */
	const renderInput = React.useCallback((props: React.HTMLProps<HTMLInputElement>, openCalendar: Function, closeCalendar: Function): JSX.Element => {
		return (
			<Label
				description={description}
				label={label}
			>
				<ScDateTimePicker
					secondaryColor={secondaryColor}
					onClick={openCalendar as (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
					isDisabled={isDisabled}
				>
					<input
						type="text"
						className={props.className}
						value={props.value}
						onChange={props.onChange}
						disabled
						ref={inputRef}
					/>
					<ScIconWrapper secondaryColor={secondaryColor}>
						<ScOpenCal>
							<Icon icon={['fal', 'calendar']} />
						</ScOpenCal>
						<ScCloseCal onClick={closeCalendar as (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}>
							<Icon icon={['fal', 'xmark']} />
						</ScCloseCal>
					</ScIconWrapper>
				</ScDateTimePicker>
			</Label>
		);
	}, [secondaryColor, description, label, isDisabled, inputRef]);

	return (
		<Datetime
			className={props.className}
			isValidDate={setValidDates}
			renderInput={renderInput}
			onChange={(momentObj) => {
				if(props.changed && !isDisabled && isMoment(momentObj)) {
					props.changed(momentObj.utc().format());
				}
			}}
			dateFormat="YYYY-MM-DD"
			timeFormat={props.timeFormat ?? 'HH:mm:ss'}
			value={props.value ? new Date(props.value) : undefined}
			initialValue={props.initialValue ? new Date(props.initialValue) : undefined}
		/>
	);
};

export default DateTimePicker;

const ScIconWrapper = styled.div<StyledDateTimePickerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	font-size: 16px;
	width: 16px;

	${(props) =>
		props.secondaryColor &&
		css`
			color: #333;
		`}
`;

const ScDateTimePicker = styled.div<StyledDateTimePickerProps>`
	height: 40px;
	background: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	font-size: 16px;

	${(props) =>
		props.secondaryColor &&
		css`
			background: #fafafa;
			border: 1px solid #a9a9a9;
		`}

	input {
		background: transparent;
		color: #fafafa;
		user-select: none;

		${(props) =>
		props.secondaryColor &&
			css`
				color: #333;
			`}
	}

	${(props) =>
		props.isDisabled &&
		css`
			cursor: not-allowed;

			input {
				opacity: 0.5;
				cursor: not-allowed;
			}

			${ScIconWrapper} {
				opacity: 0.5;
				cursor: not-allowed;
			}
		`}
`;

const ScOpenCal = styled.div`
	.rdtOpen & {
		display: none;
	}
`;

const ScCloseCal = styled.div`
	display: none;
	.rdtOpen & {
		display: block;
	}
`;
