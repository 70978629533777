import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import Loader from './UI/Loaders/Loader';
import { fetchPage } from '../store/thunks/thunk-cache';

const VanillaiFrame = () => {
	const [loaded, setLoaded] = useState(false);
	const [pageInfo, setPageInfo] = useState(null);
	const [error, setError] = useState(null);

	// An object of key/value pairs of the dynamic params from the current URL
	const params = useParams();

	const dispatch = useDispatch();

	useEffect(() => {
		// reset states to trigger shwoing of the skeleton
		setLoaded(false);
		setPageInfo(null);
		setError(null);

		dispatch(fetchPage(params.uuid))
			.then((data) => setPageInfo(data))
			.catch((err) => {
				setError(err);
			});
	}, [dispatch, params.uuid]);

	return (
		<>
			{/* Show error if any happened */}
			{error && (
				<ScErrorInfo>
					Något har gått fel, prova igen. Om felet kvarstår vänligen
					kontakta support med nedanstående information
					<div>
						<b>
							Internt meddelande:
						</b> 
						{' '}
						{error.message}
					</div>
				</ScErrorInfo>
			)}
			{(!loaded || !pageInfo) && !error && <Loader />}
			{pageInfo && (
				<iframe
					title={pageInfo.title}
					src={pageInfo.uri}
					onLoad={() => setLoaded(true)}
					style={{
						width: '100%'
					}}
				>
				</iframe>
			)}
		</>
	);
};

export default VanillaiFrame;

const ScErrorInfo = styled.div`
	font-size: 12px;
	text-align: left;
	font-family: monospace;
	padding: 8px;
`;
