import React from 'react';
import styled from 'styled-components/macro';
import * as Redux from 'react-redux';
import { AddFormItemModalProps } from './AddFormItemModal.types';
import DialogAddItem from '../../../../../../Dialogs/DialogAddItem';
import DialogSection from '../../../../../../Dialogs/DialogSection';
import { RootState } from '../../../../../../../store/types/RootTypes';
import { BlockFormItemTypes } from '../../../BlockForm.types';

const AddFormItemModal: React.FC<AddFormItemModalProps> = (props) => {

	const { addItemHandler, modal } = props;

	// Condifuration for the form block.
	const formsConfig = Redux.useSelector((state: RootState) => state.builder.formStructure);
	
	/**
	 * Handles when user choose an item alternative to add.
	 */
	const itemClickedHandler = React.useCallback((event: any) => {
		event.stopPropagation();

		const formElementType = event.currentTarget.getAttribute('name');
		const inputType = formElementType === 'input' ? 'text' : null;
		modal?.close();

		addItemHandler(formElementType, inputType, 'root');
	}, [addItemHandler, modal]);

	/**
	 * All available & selectable options to add, from backend.
	 */
	const availableStructures = React.useMemo(
		() => 
			Object.entries(formsConfig.structures)
				.filter(([key, value]) => value.selectable)
		, [formsConfig.structures]
	);

	return ( 
		<ScAddFormItemModal>
			<DialogSection
				key={'section_default'}
				title="Fördefinierade element"
			>
				<DialogAddItem
					key="select_address"
					title="Adress"
					name={BlockFormItemTypes.ADDRESS_GROUP}
					fIcon={['fal', 'map-pin']}
					pressed={itemClickedHandler}
				/>
				<DialogAddItem
					key="select_name"
					title="Namn"
					name={BlockFormItemTypes.NAME_GROUP}
					fIcon={['fal', 'user']}
					pressed={itemClickedHandler}
				/>
				<DialogAddItem
					key="select_email"
					title="E-postadress"
					name={BlockFormItemTypes.EMAIL}
					fIcon={['fal', 'at']}
					pressed={itemClickedHandler}
				/>
				<DialogAddItem
					key="select_phone"
					title="Telefon"
					name={BlockFormItemTypes.PHONE}
					fIcon={['fal', 'phone']}
					pressed={itemClickedHandler}
				/>
			</DialogSection>
			
			<DialogSection
				key={'section_formelements'}
				title="Formulärelement"
			>
				{availableStructures.map(([key, value]) => (
					<DialogAddItem
						key={`select_${key}`}
						title={value.name}
						name={key}
						fIcon={['fal', value.icon]}
						pressed={itemClickedHandler}
					/>
				))}
			</DialogSection>
		</ScAddFormItemModal>
	);
};

export default AddFormItemModal;

const ScAddFormItemModal = styled.div`
	display: flex;
	flex-direction: column;
`;
