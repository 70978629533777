import React from 'react';
import { useSlate } from 'slate-react';
import ToolbarIcon from '../Toolbar/ToolbarIcon';
import ToolbarItem from '../Toolbar/ToolbarItem';
import { isBlockActive, toggleBlock } from './blocks-api';

const BlockButton = (props) => {
	const editor = useSlate();
	return (
		<ToolbarItem
			active={isBlockActive(editor, props.format)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleBlock(editor, props.format);
			}}
		>
			<ToolbarIcon icon={props.icon} />
		</ToolbarItem>
	);
};

export default BlockButton;
