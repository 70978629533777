import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TableProps, ScTableProps, ScTableWrapperProps } from './model.Table';

const Table: React.FC<TableProps> = (props) => {
	return (
		<ScTableWrapper overFlowScroll={props.overFlowScroll}>
			<ScTable isTableFixed={props.isTableFixed}>
				{props.children}
			</ScTable>
		</ScTableWrapper>
	);
};

export default Table;

export const ScTable = styled.div<ScTableProps>`
	width: 100%;
	border-spacing: 0px 4px;
	display: table;

	${(props) =>
		props.isTableFixed &&
		css`
			table-layout: fixed;
		`}
`;
export const ScTableWrapper = styled.div<ScTableWrapperProps>`
	overflow-x: ${(props) => (props.overFlowScroll ? 'auto' : 'hidden')};
`;
