import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextInput, CheckItem } from '../../../../../../components/Forms';
import {
	ImageThumbnail,
	ModalSettingsContainer
} from '../../../../../../components/UI';
import useProductSearch from '../../../../../EngelsonsProducts/hooks/useProductSearch';

const BlockFlowboxSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;

	// This function triggers the builder to update.
	const changed = props.changed;

	// The hook to make formValidation possible
	const formValidation = props.formValidation;

	// The block data to show inside this component
	const block = currentState.data;

	/**
	 * Handles when an input text is changed by the user.
	 *
	 */
	const textInputChangedHandler = useCallback(
		(ev) => {
			// update modal's state,w ill return the updated/new state.
			updateState({
				data: {
					[ev.target.name]: { $set: ev.target.value }
				}
			});
		},
		[updateState]
	);

	const tagsInputChangedHandler = useCallback(
		(ev) => {
			// use a null value instead of an empty string
			const value =
				ev.target.value.trim().length > 0
					? ev.target.value.trim()
					: null;
			// update modal's state,w ill return the updated/new state.
			updateState({
				data: {
					tags: { $set: value }
				}
			});
		},
		[updateState]
	);

	/**
	 * Handles when changing value of a checkbox.
	 * 
	 * @param {React.ChangeEvent<HTMLInputElement>} ev
	 * @param {CheckItemProps} props
	 */
	const checkboxChangedHandler = useCallback((ev, props) => {
		const { preventBuilderPreview } = props;

		const newState = {
			data: {
				[ev.target.name]: { $set: ev.target.checked }
			}
		};
		
		// update modal's state, it will return the updated object.
		updateState(newState).then((updatedState) => {
			// notify Builder to update the block for preview to work
			if(!preventBuilderPreview) changed(updatedState);
		});
	}, [changed, updateState]);

	/**
	 * Adds a product to the product block
	 * Updates modal state + builder accoridngly
	 *
	 * @param {object} product
	 */
	const addProductHandler = useCallback(
		(product) => {
			updateState({
				data: {
					product_id: {
						$set: product.number
					},

					product_data: {
						$set: product
					}
				}
			});
		},
		[updateState]
	);

	// instantiate product search
	const productSearch = useProductSearch(addProductHandler);

	const productThumbnailActions = useMemo(
		() => [
			{
				component: block.product_data ? block.product_data?.name : null,
				styles: {
					hover: {
						color: '#ffffff',
						backgroundColor: 'var(--bg-dark-grey-color);'
					},

					color: '#ffffff'
				}
			}
		],
		[block.product_data]
	);

	return (
		<ModalSettingsContainer>
			<TextInput
				label="Flowbox nyckel"
				name="key"
				id="key"
				value={block.key ?? ''}
				error={formValidation.errors['key']}
				formValidationUnregister={formValidation.unregisterElement}
				changed={(ev, ...data) => {
					formValidation.watch(ev, textInputChangedHandler, data);
				}}
				inputRef={(ref) =>
					formValidation.registerElement(ref, {
						required: true
					})}
			/>

			<CheckItem
				type="checkbox"
				name="show_buttons"
				title="Visa knappar"
				changed={checkboxChangedHandler}
				checked={block.show_buttons ?? false}
				isDisabled={!block.key}
				error={!block.key ? ['Ange en flowbox nyckel först'] : undefined}
			/>

			{!block.has_tags && (
				<CheckItem
					type="checkbox"
					name="has_products"
					title="Produktflöde"
					changed={checkboxChangedHandler}
					checked={block.has_products}
					preventBuilderPreview
				/>
			)}

			{!block.has_products && (
				<CheckItem
					type="checkbox"
					name="has_tags"
					title="Anpassade taggar"
					changed={checkboxChangedHandler}
					checked={block.has_tags}
					preventBuilderPreview
				/>
			)}

			{block.has_tags && !block.has_products && (
				<TextInput
					label="Taggar delade med kommatecken"
					name="tags"
					id="tag"
					value={block.tags}
					error={formValidation.errors['tags']}
					formValidationUnregister={formValidation.unregisterElement}
					changed={(ev, ...data) => {
						formValidation.watch(ev, tagsInputChangedHandler, data);
					}}
					inputRef={(ref) =>
						formValidation.registerElement(ref, {
							required: true
						})}
				/>
			)}

			{/* Show product search field to search for product to connect to flow */}
			{block.has_products && !block.has_tags && (
				<>
					{productSearch()}

					{block.product_data && (
						<ImageThumbnail
							height="200px"
							width="100%"
							actions={productThumbnailActions}
							thumbnail={block.product_data.thumbnail}
						/>
					)}
				</>
			)}
		</ModalSettingsContainer>
	);
};

BlockFlowboxSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func
	//formValidation: PropTypes.object,
};

export default BlockFlowboxSettings;
