import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from '../../../../utils/oc-axios';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import { RedirectMutationsVariables } from '../Redirects.types';

const useDeleteRedirect = () => {
	const notification = useAlert()[1] as AlertDispatch;

	/**
     * Delete a redirect
     * 
     * @returns {Promise<void>}
     */
	const deleteRedirect = React.useCallback(async (id: number): Promise<void> => {
		return axios.delete(`/modules/redirects/v2/${id}`);
	}, []);

	// Returns a mutation object in order to handle the delete redirect request
	const mutation = useMutation((variables: RedirectMutationsVariables) => {
		return deleteRedirect(variables.data.id);
	}, {
		onSuccess(_data, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.success,
					title: 'Radera',
					children: '301-länken har raderats'
				});
			}

			// Call the callback method from variables if such method is provided
			if(variables.cb) variables.cb();
		},
		onError(_error, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.error,
					title: 'Radera',
					children: 'Det gick inte att radera 301-länken'
				});
			}
		}
	});

	return mutation;
};

export default useDeleteRedirect;