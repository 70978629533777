import update from 'immutability-helper';
import {
	SettingsList,
	Setting,
	SettingsAction,
	SettingsActions,
	UpdatedSettings,
	SettingsInitialState,
	SettingInputTypes
} from '../../types/ControlPanel/SettingsTypes';

const initialState = {
	list: {} as SettingsList,
	item: {} as Setting
} as SettingsInitialState;

const settingsManagement = (
	state = initialState,
	action: SettingsAction
): SettingsInitialState => {
	switch(action.type) {
		// Sets the initial state
		case SettingsActions.SET_INITIAL:
			return update(state, {
				list: {
					$set: action.data as SettingsList
				}
			});

		// When editing an item it's kept in it's own key in state
		case SettingsActions.SET_EDIT_ITEM:
			return update(state, {
				item: {
					$set: action.data as Setting
				}
			});

		// When updating an item
		case SettingsActions.UPDATE_ITEM:
			return update(state, {
				list: {
					$apply: (settings: SettingsList) => {
						const actionData = action.data as UpdatedSettings;

						let updatedSettings: SettingsList = settings;

						// Loop all items we have updated, to update their version in state.
						for(let updatedSetting of actionData.options) {
							const settingKey = updatedSetting.name;

							// Skipp element if it does not have a name for using as key.
							if(!settingKey) continue;

							const { type } = updatedSetting;

							if(type === SettingInputTypes.SECRET) {
								const mask = '****';

								updatedSetting = update(updatedSetting, {
									value: {
										$set: mask
									},
									override: {
										$set: mask
									}
								});
							}

							// update the state's element.
							updatedSettings = update(updatedSettings, {
								[settingKey]: { $set: updatedSetting }
							});
						}

						return updatedSettings;
					}
				},
				item: {}
			});

		// Used to clear the state for an edited item
		case SettingsActions.CLEAR_EDIT_ITEM:
			return update(state, {
				item: {}
			});

		// Return the current state as the default action
		default:
			return state;
	}
};

export default settingsManagement;
