import { DateTime } from 'luxon';
import update from 'immutability-helper';

const publishDateStatus = (publishDateStr, unpublishDateStr, locale) => {
	const publishDate = DateTime.fromISO(publishDateStr).toUTC();
	const publishDateisValid = !!publishDateStr && publishDate.isValid;

	const unpublishDate = DateTime.fromISO(unpublishDateStr).toUTC();
	const unpublishDateisValid = !!unpublishDateStr && unpublishDate.isValid;

	const isScheduled = publishDateisValid && publishDate > DateTime.utc();

	// If the publish date has passed based on the publish_date and if present the unpublish_date
	let hasPassed = false;

	if(unpublishDateisValid) {
		// If unpulish date given and format is valid.
		// true if unpublish date if before than now.
		hasPassed = unpublishDate < DateTime.utc();
	}

	return {
		publishDate: publishDate,
		unpublishDate: unpublishDateisValid ? unpublishDate : publishDate,
		isPublished: publishDateisValid && !isScheduled && !hasPassed,
		isScheduled: isScheduled,
		hasPassed: hasPassed
	};
};

const navigationWithPublishIcon = (items) => {
	// Map the data to inject the tooltip prop related to publish & un-publish date, as it does not comes from the backend
	// 		(The icon have to do with front-end so back-end should need to take care of that too)
	const output = {};

	for(const itemKey of Object.keys(items)) {
		let item = { ...items[itemKey] };
		output[itemKey] = item;

		// skip items with no publish & unpublish_date properties
		if(!('publish_date' in item) && !('unpublish_date' in item)) {
			if(item.type === 'page')
				console.warn(
					'[navigationWithPublishIcon]',
					`Page item '${itemKey}' does not have publish nor unpublish dates`,
					item
				);
			continue;
		}

		// Skip items of type folder
		if(item.type === 'folder') continue;
		
		const {
			publishDate,
			unpublishDate,
			isScheduled,
			hasPassed
		} = publishDateStatus(item.publish_date, item.unpublish_date);

		if(isScheduled) {
			output[itemKey] = update(item, {
				tooltip: {
					$set: {
						text: `Publiceras ${publishDate
							.toLocal()
							.toFormat('yyyy-LL-dd HH:mm')}`,

						icon: ['fas', 'clock'],
						style: {
							color: '#20b126'
						}
					}
				}
			});
		} else if(hasPassed) {
			output[itemKey] = update(item, {
				tooltip: {
					$set: {
						text: `Publicering utgick ${unpublishDate
							.toLocal()
							.toFormat('yyyy-LL-dd HH:mm')}`,
						icon: ['fas', 'clock'],
						style: {
							color: '#d0021b'
						}
					}
				}
			});
		} else if(!item.publish_date) {
			output[itemKey] = update(item, {
				tooltip: {
					$set: {
						text: 'Publiceringsdatum ej satt',
						icon: ['fas', 'clock'],
						style: {
							color: '#888'
						}
					}
				}
			});
		} else {
			// Remove tooltip if publish/unpublish-date is not set/is null
			output[itemKey] = update(item, {
				$unset: ['tooltip']
			});
		}
	}

	return output;
};

export { publishDateStatus, navigationWithPublishIcon };
