import { useDragDrop } from '../../hooks/useDragDrop';

/**
 * Makes sure that borders are removed accordingly
 */
export const MouseOutBorderCheck = () => {
	const [state] = useDragDrop();

	if ('hoveredItem' in state) {
		state.hoveredItem.hoveredElement.style.boxShadow = 'none';
	}
};

const MouseOutHandler = (ev, callback, props) => {
	const [state] = useDragDrop();

	/**
	 * The target element that the mouse will be leaving
	 */
	const targetElement = ev.currentTarget;

	// if a draggedItem has not yet been set then ignore the move handler
	if (!('draggedItem' in state)) return;

	// clear it's inline box-shadow
	targetElement.style.boxShadow = 'none';

	// Call the provided callback function that were defined in DragDrop as a property (onDragOut)
	if (typeof callback === 'function')
		callback(state.draggedItem, state.hoveredItem, ev);
};

export default MouseOutHandler;
