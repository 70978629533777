import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import update from 'immutability-helper';

import DragDrop from '../../hoc/DragDrop/DragDrop';
import Draggable from '../../hoc/DragDrop/Draggable';
import SortableItem from './SortableItem';

/**
 * @param items         Array of the items to show.
 * @param changed		Triggered when the order changes, the only parameter is the suggested state.
 * @param itemRemoved	Triggered when an items is removed.
 */
const SortableList = (props) => {
	const { itemRemoved } = props;

	/**
	 * Handles when an item is removed from the list.
	 *
	 * @param {number} itemIndex	The item's index in the array of props.items.
	 */
	const itemRemovedHandler = useCallback(
		(itemIndex) => {
			if (itemRemoved) itemRemoved(itemIndex);
		},
		[itemRemoved]
	);

	/**
	 * Updates the positions when an image is dropped on a horizontal position
	 *
	 * @param {object} source -> the dragged item
	 * @param {object} destination -> the hovered item displaying a valid drop zone
	 * @param {object} instruction -> additional instructions regarding the drop
	 */
	const dragEnd = (source, destination, instruction) => {
		if (!source || !destination) return;

		const sourceIndex = source.properties.index;
		const destinationIndex = destination.properties.index;
		const movedElement = props.items[sourceIndex];

		const updatedOrder = update(props.items, {
			$splice: [
				[sourceIndex, 1],
				[destinationIndex, 0, movedElement],
			],
		});

		props.changed(updatedOrder);
	};

	return (
		<ScDragDrop scope="dnd-list" onDragEnd={dragEnd}>
			{useMemo(
				() =>
					props.items.map((item, index) => {
						return (
							<ScDraggable
								id={index}
								key={index}
								index={index}
								scope="dnd-list"
								allowedDirection="vertical"
								isHandle
							>
								<SortableItem
									key={`item_${index}`}
									icon={['fal', 'trash']}
									index={index}
									removed={itemRemovedHandler}
								>
									{item.number} | {item.name}
								</SortableItem>
							</ScDraggable>
						);
					}),
				[props.items, itemRemovedHandler]
			)}
		</ScDragDrop>
	);
};

SortableList.propTypes = {
	items: PropTypes.array.isRequired,
	changed: PropTypes.func.isRequired,
	itemRemoved: PropTypes.func,
};

export default SortableList;

const ScDragDrop = styled(DragDrop)`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

const ScDraggable = styled(Draggable)`
	margin-bottom: 8px;
	cursor: row-resize;

	&:last-child {
		margin-bottom: 0;
	}
`;
