import React from 'react';
import { BlockCategoryProps, StrippedCategoryProduct, FilterNamesType } from './BlockCategory.types';
import Product from '../../Product';
import { ProductImage } from '../BlockProduct/BlockProduct.types';
import BlockEmpty from '../../../../../components/Builder/BlockTypes/BlockEmpty/BlockEmpty';

/**
 * Used to show the content in the settings modal for the Category block.
 */
const BlockCategory: React.FC<BlockCategoryProps> = (props) => {
	const enabledProducts = props.data?.products
		.filter((product: StrippedCategoryProduct) => !('deleted' in product) && product.enabled)
		.slice(0, 50);

	// Get all filters that's not icluded in inactive_filters
	let activeFilters: string[] = [];
	if(props.data?.filter_names) {
		activeFilters = Object.keys(props.data?.filter_names)
		                .filter((filter) => !props.data?.inactive_filters.includes(filter));
	}

	/**
     * Return an array with the product's images
     * 
     * @param {StrippedCategoryProduct} product
     * @returns {ProductImage[]}
     */
	const getArticleImages = React.useCallback((product: StrippedCategoryProduct): ProductImage[] => {
		if(!product.image_connections || !('article' in product.image_connections)) return [];

		return product.image_connections.article.map(
			(imageUuid: string) => product.images[imageUuid]
		);
	}, []);

	if(!props.data?.enabled) {
		return (
			<BlockEmpty
				icon={['fal', 't-shirt']}
				title="Blocket är inaktiverat"
				description="Tryck på kugghjulet för att aktivera blocket."
			>
			</BlockEmpty>
		);
	}

	return (
		<div className="cms-block cms-blockProductsCategory">
			<div className="categoryHeader">
				<div className="productToolbar">
					<div className="filterWrapper">
						<div className="product__label">
							Filtrering:
							<div className="filterBtn">
								OK
							</div>
						</div>
						<div className="filters">
							{activeFilters.map((filter) => {
								const filterName = filter as keyof FilterNamesType;
								return (
									<div
										key={`filter-${filter}`}
										className="filter"
									>
										<div className="filterTitle">
											{props.data?.filter_names[filterName]}
											<i className="fal fa-chevron-down"></i>
										</div>
									</div>
								);})}
						</div>
					</div>
					<div className="optionsWrapper">
						<div className="optionsInnerWrapper -filter">
							<div className="product__label"></div>
							<div className="filterBtn">
								Filtrera
								<i className="fal fa-chevron-down"></i>
							</div>
						</div>
						<div className="optionsInnerWrapper -sorting">
							<div className="product__label">
								Sortering:
							</div>
							<div className="styledSelect -full">
								<select>
									<option>
										Populärast
									</option>
									<option>
										Artikelnummer
									</option>
									<option>
										A-Ö
									</option>
									<option>
										Pris
									</option>
								</select>
								<i className="fal fa-chevron-down"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="filterResult">
					<div className="filterResult__hits">
						{enabledProducts?.length}
						{' '}
						artiklar
					</div>
					<div className="filterOptionsUsed">
						<div className="-clearAll">
							RENSA FILTER
						</div>
					</div>
				</div>
			</div>

			<div className="categoryProducts products">
				<ul className="productList -horizontal -normal">
					{enabledProducts?.map((product: StrippedCategoryProduct) => {
						const images = getArticleImages(product);

						return (
							<Product
								key={`key-${product.number}`}
								articleNr={product.number}
								name={product.name}
								price={product.prices?.current}
								isPriceFrom={true}
								thumbnail={product.thumbnail}
								images={images}
								colors={product.color_names}
								isEnabled={product.enabled}
								badge={product.badge}
							/>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default BlockCategory;
