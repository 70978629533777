import React, { memo } from 'react';
import styled, { css } from 'styled-components/macro';
import BlockColumn from './BlockColumn';
import { Icon, Tooltip } from '../UI';
import BlockRowLayout from '../Builder/BlockRowLayout';
import { columnWidthPossibilities } from '../../containers/Builder/helpers/ColumnWidthHelper';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { SHOW_BLOCK_ROW_WIDTH_SETTING, SHOW_BLOCK_ROW_REVERSE_SETTING } from '../../settings';

/**
 * Will determine whether to re-render a row set or not
 *
 * @param {object} prevProps
 * @param {object} nextProps
 */
const arePropsEqual = (prevProps, nextProps) => {
	return (
		prevProps.updated === nextProps.updated &&
		prevProps.viewInUse === nextProps.viewInUse
	);
};

const BlockRow = (props) => {
	const { row, blockRowWidthClicked, blockRowReverseClicked } = props;
	
	// amount of columns in the row set
	const amountColumns = props.row.columns.length;

	// available column sizes for the qty of columns on the row set
	const availableColumnsOptions = columnWidthPossibilities[amountColumns];

	// current column widths
	const currentColumnWidths =
		availableColumnsOptions[props.row.settings.column_division];

	const currentColumnWidthSettings = props.row.settings.column_division;

	// checks whether a row should be hidden in the current view mode
	const isRowHidden = !props.row.settings[`visible_${props.viewInUse}`];

	// checks whether there is more than one column division setting available
	const renderColumnWidthsOptions =
		Object.values(availableColumnsOptions).length > 1;

	/**
	 * Toggle the has_max_width setting of the current row
	 * 
	 * @returns {void} 
	 */
	const blockRowWidthClickedHandler = React.useCallback(() => {
		let hasMaxWidth = !row.settings.has_max_width;
		
		blockRowWidthClicked(row, hasMaxWidth);
	}, [row, blockRowWidthClicked]);

	/**
	 * Toggle the reverse_in_mobile setting of the current row
	 * 
	 * @returns {void} 
	 */
	const blockRowReverseClickedHandler = React.useCallback(() => {
		let isReversed = !row.settings.reverse_in_mobile;
		
		blockRowReverseClicked(row, isReversed);
	}, [row, blockRowReverseClicked]);

	return (
		<ScContainer
			className={`cms-blockRow ${row.settings.has_max_width ? '-hasMaxWidth' : ''} ${row.settings.reverse_in_mobile ? '-reverse' : ''}`}
			isShowingHiddenBlockRow={isRowHidden}
		>
			<ScHiddenStatus>
				<Icon
					style={{ display: 'flex', width: 16, height: 16 }}
					icon={['far', 'eye-slash']}
				/>
			</ScHiddenStatus>
			<ScBlockAction>
				<ScActionContainer>
					<ScBlockItem
						draghandle
						data-is-draghandle={true}
					>
						<ScIcon icon={['fal', 'arrows-up-down-left-right']} />
						<ScTitle>
							Block Rad
						</ScTitle>
					</ScBlockItem>

					{getRowVisibilityOptions(props.row, props.hideBlockRowClicked)}

					{SHOW_BLOCK_ROW_WIDTH_SETTING && (
						<ScBlockItem onClick={blockRowWidthClickedHandler}>
							<ScIcon icon={['fal', 'arrows-left-right-to-line']} />
						</ScBlockItem>
					)}

					{SHOW_BLOCK_ROW_REVERSE_SETTING && props.viewInUse === 'mobile' && (
						<ScBlockItem onClick={blockRowReverseClickedHandler}>
							<ScIcon icon={['fal', 'rotate']} />
						</ScBlockItem>
					)}

					{props.viewInUse === 'desktop' &&
						renderColumnWidthsOptions && (
							<ScBlockItem layout>
								<ScIcon icon={['fal', 'columns-3']} />
								<ScLayouts>
									<ScLayoutsInner>
										{getRowLayoutOptions(
											amountColumns,
											availableColumnsOptions,
											props.row.key,
											props.columnChanged,
											currentColumnWidthSettings
										)}
									</ScLayoutsInner>
								</ScLayouts>
							</ScBlockItem>
					)}
				</ScActionContainer>
			</ScBlockAction>

			{props.row.columns.map((column, index) => (
				<BlockColumn
					id={column.key}
					key={column.key}
					areaId={props.areaId}
					rowId={props.id}
					blocks={column.blocks}
					amountColumns={amountColumns}
					blockTypes={props.blockTypes}
					addBlock={props.addBlock}
					blockChanged={props.blockChanged}
					width={
						currentColumnWidths ? currentColumnWidths[index] : null
					}
					opts={props.opts}
					viewInUse={props.viewInUse}
					openLinkToolModal={props.openLinkToolModal}
					openBlockSettingsModal={props.openBlockSettingsModal}
					closeBlockSettingsModal={props.closeBlockSettingsModal}
					deleteBlockHandler={props.deleteBlockHandler}
					blockSettingsFormValidation={
						props.blockSettingsFormValidation
					}
				/>
			))}

			<ScAddNewBlock>
				<ScAddNewBlockContianer
					onClick={(ev) => {
						ev.stopPropagation();
						props.addBlock(
							props.areaId,
							props.id,
							null,
							null,
							props.index,
							ev
						);
					}}
				>
					<ScAddNewBlockIcon icon={['fal', 'xmark']} />
				</ScAddNewBlockContianer>
			</ScAddNewBlock>
		</ScContainer>
	);
};

export default memo(withErrorBoundary(BlockRow), arePropsEqual);

/**
 * Render row layout options
 *
 * @param {int} amountColumns
 * @param {object} availableColumnsOptions
 * @param {string} rowId
 * @param {function} columnChanged
 */
const getRowLayoutOptions = (
	amountColumns,
	availableColumnsOptions,
	rowId,
	columnChanged,
	currentColumnWidthSettings
) => {
	if(!amountColumns) return null;
	if(!currentColumnWidthSettings)
		currentColumnWidthSettings = Object.keys(availableColumnsOptions)[0];

	return Object.keys(availableColumnsOptions).map((layout, index) => (
		<BlockRowLayout
			key={`row-${rowId}_layout-${layout}_${index}`}
			rowId={rowId}
			changed={columnChanged}
			currentLayout={currentColumnWidthSettings}
			layout={layout}
			cols={availableColumnsOptions[layout]}
			isActive={layout === currentColumnWidthSettings}
			style={{ width: 120 }}
		/>
	));
};

/**
 * Helps calculate possibilities to toggle hide/show for a block row
 *
 * @param {object} row
 * @param {function} callback
 */
const getRowVisibilityOptions = (row, callback) => {
	const variants = ['mobile', 'desktop'];
	const rowSettings = row.settings;
	const visibleDesktop = rowSettings.visible_desktop;
	const visibleMobile = rowSettings.visible_mobile;

	const getRowSettingsToolTip = (component, key) => {
		return (
			<ScTooltip
				key={key}
				isOver
				text="Detta block måste finnas synlig i någon variant."
			>
				{component}
			</ScTooltip>
		);
	};

	const options = variants.map((variant) => {
		const key = `${row.key}_${variant}`;
		const icon = ['fal', variant];
		const currentOptionSetting = rowSettings[`visible_${variant}`];

		if(visibleDesktop && visibleMobile) {
			return (
				<ScHideBlock
					key={key}
					onClick={() => callback(row, variant)}
				>
					<ScHideBlockIcon icon={icon} />
				</ScHideBlock>
			);
		}

		switch(true) {
			case currentOptionSetting:
				return getRowSettingsToolTip(
					<ScHideBlock
						isLastAvailable
						isDisabled
					>
						<ScHideBlockIcon icon={icon} />
					</ScHideBlock>,
					key
				);

			default:
				return (
					<ScHideBlock
						key={key}
						onClick={() => callback(row, variant)}
						isHidden
					>
						<ScHideBlockIcon icon={icon} />
					</ScHideBlock>
				);
		}
	});

	return (
		<ScHideBlockItem>
			{options}
		</ScHideBlockItem>
	);
};

const ScActionContainer = styled.div`
	display: flex;
	pointer-events: visible;
	height: 0;
	opacity: 0;
	background-color: #666;
	/* Can't be css variable since this is inserted in iframe */
`;

const ScBlockAction = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	width: 100%;
	height: 16px;
	justify-content: center;
	z-index: 999;
	font-size: 14px;
	overflow: visible;
	justify-content: flex-start;

	:hover {
		${ScActionContainer} {
			opacity: 1;
			height: 48px;
		}
	}

	.isTyping & {
		display: none;
	}

	.isDragging & {
		pointer-events: none;
		display: none;
	}
`;

const ScLayouts = styled.div`
	display: none;
	position: absolute;
	background: #555;
	cursor: default;

	top: 0;
	left: 40px;
	width: auto;
`;

const ScLayoutsInner = styled.div`
	position: relative;
	padding: 8px 8px;

	flex-direction: row;
	display: flex;
	:after {
		right: 100%;
		top: 50%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-right-color: #555;
		border-width: 12px;
		margin-top: -12px;
	}
`;

const ScBlockItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	user-select: none;
	cursor: pointer;
	color: #fafafa;
	position: relative;
	/* Can't be css variable since this is inserted in iframe */

	:hover {
		background: ${(props) => (props.delete ? '#DA534D' : '#333')};

		${(props) =>
		props.draghandle &&
			css`
				cursor: grab;
			`}
	}

	${(props) =>
		props.isDisabled &&
		css`
			color: #999;
			cursor: not-allowed;
		`}

	${(props) =>
		props.layout &&
		css`
			:hover {
				${ScLayouts} {
					display: block;
				}
			}
		`}
`;

const ScIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	display: block;
	pointer-events: none;
`;

const ScTitle = styled.div`
	line-height: 16px;
	pointer-events: none;
	margin-left: 8px;
`;

const ScAddNewBlock = styled.div`
	position: absolute;
	height: 2px;
	width: 100%;
	left: 0;
	bottom: -1px;
	opacity: 0;
	user-select: none;
	transition: opacity 0.3s ease-in-out;
	z-index: 9999;

	:hover {
		background: #333;
		cursor: pointer;
	}
`;

const ScAddNewBlockContianer = styled.div`
	position: absolute;
	top: -9px;
	left: -1px;
	width: 20px;
	height: 20px;
	z-index: 9999;
	background: #555;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 80px 0px 80px 80px;
	transform: rotate(45deg);
`;

const ScAddNewBlockIcon = styled(Icon)`
	color: #fafafa;
	width: 8px;
	pointer-events: none;
`;

const ScHiddenStatus = styled.div`
	display: none;
	position: absolute;
	z-index: 99;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
	width: 40px;
	height: 40px;
	color: #fafafa;
	justify-content: center;
	align-items: center;
`;

const ScContainer = styled.div`
	&:after {
		content: '';
		border: 1px dashed rgba(0, 0, 0, 0);
		transition: border ease-in-out 0.3s;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
	}

	${(props) =>
		props.isShowingHiddenBlockRow &&
		css`
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, 0.16);
				z-index: 99;
			}

			${ScHiddenStatus} {
				display: flex;
			}
		`}

	:hover {
		&:after {
			content: '';
			border: 1px dashed #333;
		}

		${ScAddNewBlock} {
			opacity: 1;
			.isDragging & {
				opacity: 0;
			}
		}
	}

	.isDragging & {
		cursor: all-scroll;
	}

	${ScAddNewBlock}:hover ~ & {
		border: 1px solid rgba(0, 0, 0, 0);

		.isDragging & {
			border: 1px solid transparent;
			border: 1px dashed #333;
		}
	}
`;

// Hide BlockRow

const ScHideBlockItem = styled(ScBlockItem)`
	cursor: default;
`;

const ScHideBlock = styled.div`
	width: 16px;
	height: 16px;
	display: block;
	position: relative;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-left: 16px;

	:first-child {
		margin-left: 0;
	}
	::after {
		content: '';
		display: none;
		width: 1px;
		height: 140%;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		background: #666;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: -20% auto;
	}

	:hover {
		color: #666;
		::after {
			display: block;
		}
	}

	${(props) =>
		props.isHidden &&
		css`
			color: #909090;
			::after {
				display: block;
				background: #909090;
			}

			:hover {
				color: #909090;
				::after {
					display: block;
					background: #909090;
				}
			}
		`}
`;
const ScHideBlockIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const ScTooltip = styled(Tooltip)`
	:first-child {
		margin-left: 0;
	}
	margin-left: 16px;

	${ScHideBlock} {
		:hover {
			::after {
				content: '';
				display: none;
			}
		}
	}
	${ScHideBlockIcon} {
		cursor: not-allowed;
	}
`;
