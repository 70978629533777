import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../../../components/UI';
import { Select } from '../../../components/Forms';

const Article = (props) => {
	const statuses = {
		show: {
			text: 'Synlig',
			icon: 'eye'
		},
		disable: {
			text: 'Dold',
			icon: 'eye-slash'
		},
		enable: {
			text: 'Köpbar',
			icon: 'cart-shopping'
		}
	};
	const status = statuses[props.status];

	return (
		<ScContainer>
			<ScInfo>
				<ScStatus>
					<Icon icon={['far', status.icon]} />
				</ScStatus>
				<ScTextContainer>
					<ScArtNr>
						{props.number}
					</ScArtNr>
					<ScName noPadding>
						{props.name}
					</ScName>
				</ScTextContainer>
			</ScInfo>

			<Select
				style={{ marginBottom: 0, flex: 1 }}
				isDisabled
				value={props.status}
			>
				<option value={props.status}>
					{status.text}
				</option>
			</Select>
		</ScContainer>
	);
};

export default Article;

const ScContainer = styled.div`
	display: flex;
	flex: 1;
	background: #eee;
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;

	&:nth-child(even) {
		background: #f9f9f9;
	}
`;

const ScInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
`;

const ScTextContainer = styled.div`
	flex-direction: column;
	display: flex;
	margin-left: 16px;
`;

const ScArtNr = styled.div`
	font-weight: 600;
	font-size: 16px;
`;

const ScName = styled.div`
	font-weight: 300;
	font-size: 12px;
`;

const ScStatus = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border: 1px solid #333;
	border-radius: 100%;
	font-size: 14px;
`;
