import React from 'react';
import { useParams } from 'react-router-dom';
import ModuleDefinitions from '../modules/ModuleDefinitions';
import PageContentWrapper from '../components/Content/PageContentWrapper';
import useAuth from '../hooks/useAuth/useAuth';

const ModulesWrapper = () => {
	const { verifyUserPermission } = useAuth();

	// Get the module param from the current url
	let { module: moduleName } = useParams();

	const moduleObj = ModuleDefinitions[moduleName];

	if(!moduleObj)
		return (
			<PageContentWrapper>
				Den här modulen existerar inte
			</PageContentWrapper>
		);

	if(moduleObj.permission && !verifyUserPermission(moduleObj.permission)) {
		return (
			<PageContentWrapper>
				Du har inte rättigheter att använda den här modulen.
			</PageContentWrapper>
		);
	}

	const component = moduleObj.page;

	return (
		<>
			{component || (
				<PageContentWrapper>
					Den här modulen har inte konfigurerats korrekt
				</PageContentWrapper>
			)}
		</>
	);
};

export default ModulesWrapper;
