import React from 'react';
import { BlockCategoryType } from './BlockCategory.types';
import BlockCategorySettings from './Settings/BlockCategorySettings';
import BlockCategory from './BlockCategory';
import { BuilderBlock } from '../../../../../components/Builder/Block.types';
import { FormValidation } from '../../../../../hooks/useFormValidation/types';
import { ModalAction, ModalConfig, ModalProps } from '../../../../../hooks/useModal/useModal.model';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockCategoryType>>) => void, 
	closeModal: () => void,
	changed: (blockState: BuilderBlock<BlockCategoryType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockCategoryType>) => void,
	_formValidation: FormValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData: BuilderBlock<BlockCategoryType>) => {
				/**
				 * Open a modal with the settings when pressing the COG icon.
				 */
				const modalActions: ModalAction<BuilderBlock<BlockCategoryType>>[] = [
					// {
					// 	text: 'Avbryt',
					// 	isDefault: true,
					// 	action: (originalState, currentState, closeModal) => {
					// 		const currentCategory = currentState.category_id;
					//         let updatedState = blockData;

					//         blockData.products((product => {
					//             const updatedProduct = currentState.products.find(p => p.id === product.id);
					//             return
					//         });

					// 		if (currentState.products.length) {
					// 			const updatedProducts = blockData.products
					// 				.filter((product) => {
					// 					const updatedProduct = currentState.products.find(
					// 						(p) => p.id === product.id
					// 					);
					// 					return (
					// 						updatedProduct &&
					// 						updatedProduct.categories.includes(
					// 							currentCategory
					// 						)
					// 					);
					// 				})
					// 				.map((product) => {
					// 					const current = currentState.products.find(
					// 						(currentProduct) =>
					// 							currentProduct.id === product.id
					// 					);

					// 					const former = blockData.products.find(
					// 						(formerProduct) =>
					// 							formerProduct.id === product.id
					// 					);

					// 					console.log(current, former);

					// 					let images = {};

					// 					if (
					// 						former &&
					// 						!Array.isArray(
					// 							former.product.images
					// 						) &&
					// 						current &&
					// 						!Array.isArray(
					// 							current.product.images
					// 						)
					// 					) {
					// 						images = {
					// 							images: {
					// 								[former.product
					// 									.primary_image.uuid]: {
					// 									main: {
					// 										$set: false,
					// 									},
					// 								},
					// 								[former.product
					// 									.primary_image.uuid]: {
					// 									main: {
					// 										$set: true,
					// 									},
					// 								},
					// 							},
					// 						};
					// 					}

					// 					return update(product, {
					// 						product: {
					// 							primary_image: {
					// 								$set: current.primary_image,
					// 							},
					// 							$merge: {
					// 								images,
					// 							},
					// 						},
					// 						thumbnail: {
					// 							$set: current.thumbnail,
					// 						},
					// 					});
					// 				});

					// 			updatedState = update(blockData, {
					// 				products: {
					// 					$set: updatedProducts,
					// 				},
					// 			});
					// 		} else {
					// 			updatedState = update(blockData, {
					// 				products: {
					// 					$set: [],
					// 				},
					// 			});
					// 		}

					// 		// Reset builder block settings
					// 		changed(updatedState);
					// 		closeModal();
					// 	},
					// },
					{
						isDefault: true,
						text: 'Applicera ändringar',
						action: (originalState: BuilderBlock<BlockCategoryType>, currentState: BuilderBlock<BlockCategoryType>, closeModal: ()=>void) => {
							// Notify builder to update all block's atributes before closing the modal
							changed(currentState);
							closeModal();
						}
					}
				];

				openModal({
					title: 'Redigerar kategori',
					actions: modalActions,
					position: 'center',
					width: 'calc(100% - 32px)',
					height: 'calc(100% - 32px)',
					state: blockData,
					hideBackdrop: false,
					isPositionable: false,
					isDismissable: false,
					isDraggable: false
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockCategoryType>) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStruct =  {
	// can be added in builder by the user?
	isAddable: false,
	baseStruct: {
		// Used by Builder.
		key: null,
		data: {
			empty: false,
			uuid: '',
			short_uuid: '',
			type: '',
			inherit: false,
			products: [],
			filters: null,
			category_id: null,
			filter_names: null,
			inactive_filters: [],
			all_filter_names: null
		}
	},
	builder: {
		component: <BlockCategory />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockCategorySettings 
			modal={{} as ModalProps<BuilderBlock<BlockCategoryType>>}
			formValidation={{} as FormValidation}
		           />
	}
};

export default baseStruct;