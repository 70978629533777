import React, { ChangeEvent } from 'react';
import update from 'immutability-helper';
import { createRandomId } from 'react-utils';
import styled from 'styled-components/macro';
import { ValidationTypes } from '../../../../../../hooks/useFormValidation/types';
import { CheckItem, Select, Textarea, TextInput } from '../../../../../Forms';
import { Message } from '../../../../../UI';
import Chip from '../../../../../UI/Chip';
import { SettingsTabProps } from '../SettingsTab.types';
import { BlockFormInputItemTypes, BlockFormResult, BlockFormRulesetResultActions, BlockFormRulesetTypes } from '../../BlockForm.types';
import { TextInputProps } from '../../../../../Forms/TextInput/model.TextInput';
import { TextareaProps } from '../../../../../Forms/Textarea/model.Textarea';
import useAlert, { AlertPriorityTypes } from '../../../../../../hooks/useAlert';

export type RecipientType = 'recipient' | 'cc' | 'bcc';

const Settings: React.FC<SettingsTabProps> = (props) => {

	// The modal hook.
	const modal = props?.modal;

	// Callback to update the modal's state
	//	only call it with an object for immutability-helper.
	const updateState = modal.updateState;

	// The block's data.
	const block = props.modal.currentState.data;
	const blockRulesets = block.rulesets;

	// Intantiate formvalidation for validating when adding new recipients and so.
	const formValidation = props.formValidation;
	
	const formElements = block.items;

	const notification = useAlert()[1];

	/**
	 * Find the ruleset that contains the settings options.
	 */
	 const [configRulesetKey, configRuleset] = React.useMemo(
		() => {
			if(!blockRulesets) return [null, null];

			return Object.entries(blockRulesets)
				.find(([_key, _value]) => _value.type === BlockFormRulesetTypes.EMAIL && _value.unconditional === true) || [null, null];
		}, 
		[blockRulesets]
	);

	/**
	 * Find the result that contains subject info.
	 */
	 const [formSubjectResultKey, formSubjectResult] = React.useMemo(
		() => {
			if(!configRuleset || !configRuleset.results) return [null, null];
			
			return Object.entries(configRuleset.results).find(([resultKey, result]) => {
				return result.action === BlockFormRulesetResultActions.SUBJECT;
			}) || [null, null];
		}, 
		[configRuleset]
	);

	/**
	 * Results for the recipients.
	 */
	 const recipients: Record<string, BlockFormResult> = React.useMemo(
		() => {
			if(!configRuleset || !configRuleset.results) return {};
			
			const output = {} as Record<string, BlockFormResult>;

			const results = Object.entries(configRuleset.results).filter(([resultKey, result]) => {
				return result.action === BlockFormRulesetResultActions.RECIPIENT || result.action === BlockFormRulesetResultActions.BCC;
			});

			// convert array to object
			for(const [resultKey, result] of results) {
				output[resultKey] = result;
			}

			return output;
		}, 
		[configRuleset]
	);

	/**
	 * Find the ruleset that contains the settings options.
	 */
	const [feedbackRulesetKey, feedbackRuleset] = React.useMemo(
		() => {
			if(!blockRulesets) return [null, null];

			return Object.entries(blockRulesets)
				.find(([_key, _value]) => _value.type === BlockFormRulesetTypes.FEEDBACK) || [null, null];
		}, 
		[blockRulesets]
	);
	
	/**
	 * Contains the reply-to rule for the feedback email.
	 */
	 const [replytoFeedbackKey, replytoFeedback] = React.useMemo(
		() => {
			if(!feedbackRuleset?.results) return [null, null];
			 
			return Object.entries(feedbackRuleset.results)
				.find(([_key, _value]) => _value.action === BlockFormRulesetResultActions.REPLY_TO) || [null, null];
		},
		[feedbackRuleset]
	);

	/**
	 * Message with the feedback email text.
	 */
	const [messageFeedbackKey, messageFeedback] = React.useMemo(
		() =>  {
			if(!feedbackRuleset?.results) return [null, null];
			 
			return Object.entries(feedbackRuleset.results)
				.find(([_key, _value]) => _value.action === BlockFormRulesetResultActions.MESSAGE) || [null, null];
		},
		[feedbackRuleset]
	);

	/**
	 * The feedback result for setting the recipient.
	 */
	const [recipientFeedbackKey, recipientFeedback] = React.useMemo(
		() =>  {
			if(!feedbackRuleset?.results) return [null, null];
			 
			return Object.entries(feedbackRuleset.results)
				.find(([_key, _value]) => _value.action === BlockFormRulesetResultActions.RECIPIENT) || [null, null];
		},
		[feedbackRuleset]
	);

	/**
	 * The feedback result for setting the subject.
	 */
	const [subjectFeedbackKey, subjectFeedback] = React.useMemo(
		() =>  {
			if(!feedbackRuleset?.results) return [null, null];
			 
			return Object.entries(feedbackRuleset.results)
				.find(([_key, _value]) => _value.action === BlockFormRulesetResultActions.SUBJECT) || [null, null];
		},
		[feedbackRuleset]
	);

	const [inputValues, setAddEmailValue] = React.useState<{
		// recipients
		recipient: string;
		cc:string;
		bcc: string;
	}>({
		recipient:'',
		cc:'',
		bcc: ''
	});

	/**
	 * Select the first email element as default.
	 */
	const defaultFeedbackEmailItem = React.useMemo(() => {
		return Object.entries(formElements)
			.filter(([_key, formItem]) => {
				return formItem.input_type === BlockFormInputItemTypes.EMAIL && (!formItem.disabled || !formItem.readonly);
			})[0] || ['', null];
	}, [formElements]);

	/**
	 * True if there is at least one input that is of type email.
	 */
	const isAnyEmailFormItem = React.useMemo(() => {
		return Object.values(formElements).some((formItem) => {
			return formItem.input_type === BlockFormInputItemTypes.EMAIL && (!formItem.disabled || !formItem.readonly);
		});
		
	}, [formElements]);

	// state to mark is using any recipients or feedback emails.
	const [isUsingRecipients, setIsUsingRecipients] = React.useState<boolean>(!!formSubjectResult?.value || !!Object.entries(recipients).length);
	const [isUsingFeedback, setIsUsingFeedback] = React.useState<boolean>(!!feedbackRulesetKey && (!!replytoFeedback?.value || !!messageFeedback?.value));

	/**
	 * Stores given email in a state for recipient, cc, and bcc.
	 *
	 * @param ev
	 */
	const addEmailValueChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

		const value = ev.currentTarget.value;
		const emailType = ev.currentTarget.getAttribute('name')?.replace('recipient_', '').replace('feedback_', '');

		if(!emailType) return;

		const suggestedState = {
			[emailType]: {
				$set: value
			}
		};

		setAddEmailValue(update(inputValues, suggestedState));
	}, [inputValues]);

	/**
	 * Adds a new recipient.
	 * 
	 * @param ev
	 * @param props
	 */
	const addEmailRecipientHandler = React.useCallback((ev: React.KeyboardEvent<HTMLInputElement>, props: TextInputProps | TextareaProps) => {

		if(props.value && props.name && ev.key === 'Enter' && props.value.length > 5) {
			ev.preventDefault();

			if(!configRulesetKey) return;

			// check for formValidation errors
			if(!!formValidation.errors[props.name]) return;

			const emailType = props.name.replace('recipient_', '');
			const isAlreadyAdded = Object.entries(recipients).find(([resultKey, result]) => result.value === props.value);

			// show error if the email is already added and return.
			if(isAlreadyAdded) {
				// @ts-ignore
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					// title: '💾 Sparar sida...',
					title: 'E-postaddressen används redan'
				});
				return;
			}

			updateState({
				data: {
					rulesets: {
						[configRulesetKey]: {
							results: {
								$merge: {
									[createRandomId('result')]: {
										action: emailType,
										value: props.value
									}									
								}
							}
						}
					}
				}
			}).then(() => {
				const suggestedState = {
					[emailType]: {
						$set: ''
					}
				};
			
				setAddEmailValue(update(inputValues, suggestedState));
			});
		  }
	}, [configRulesetKey, formValidation.errors, recipients, updateState, notification, inputValues]);

	/**
	 * Handles when user changes the use of a feedback email for the form sender.
	 */
	const feedbackRecipientsToggledHandler = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
		// Dont continue if we have missing the key of the ruleset's rule
		if(!feedbackRulesetKey) return;
	
		setIsUsingFeedback(event.currentTarget.checked);

		// reset values of results to null, when toggling the checkbox.
		if(!event.currentTarget.checked) 
			updateState({
				data: {
					rulesets: {
						[feedbackRulesetKey]: {
							results: {
								$apply: (_results:any) => {								
									let output = _results;

									for(const resultKey of Object.keys(output)) {
										if(!['subject', 'recipient', 'message', 'reply-to'].includes(output[resultKey].action)) continue;

										output = update(output, {
											[resultKey]: { 
												value: { 
													$set: null
												}
											}
										});
									
									}

									return output;
								}
							}
						}
					}
				}
			});
	}, [feedbackRulesetKey, updateState]);

	/**
	 * Handles when selecting/un-selecting the use of recipients for the form.
	 */
	const recipientsToggledHandler = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setIsUsingRecipients(event.currentTarget.checked);
		// don't reset things if checking the checkbox.
		if(!configRulesetKey || event.currentTarget.checked) return;

		// reset values of results to null, when toggling the checkbox.
		updateState({
			data: {
				rulesets: {
					[configRulesetKey]: {
						results: {
							$apply: (_results:any) => {								
								let output = _results;
	
								for(const resultKey of Object.keys(output)) {
									if(!['bcc', 'subject', 'recipient', 'message', 'reply-to'].includes(output[resultKey].action)) continue;

									// remove the result entry totally if they are multiple of same "action"
									if(['recipient', 'cc', 'bcc'].includes(output[resultKey].action)) {
										output = update(output, {
											$unset: [resultKey]
										});
									} else {
										output = update(output, {
											[resultKey]: { 
												value: { 
													$set: null
												}
											}
										});
									}
										
								}
	
								return output;
							}
						}
					}
				}
			}
		});
			
	}, [configRulesetKey, updateState]);
	
	/**
	 * Remove a recipient when user clicks on a Chip's x icon. 
	 */
	const removeRecipientClickedHandler = (event: React.SyntheticEvent<MouseEvent>, props: any) => {
		const resultKey = props.name;

		if(configRulesetKey && block && block.rulesets.root.children) 
			updateState({
				data: {
					rulesets: {
						[configRulesetKey]: {
							results: {
								$unset: [resultKey]
							}
						}
					}
				}
			});
	};

	/**
	 * Handle when the form's name changes.
	 * 
	 * @param event Input change event.
	 */
	 const formSubjectChangedHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const newValue = event?.currentTarget.value;

		if(!configRulesetKey || !formSubjectResultKey) return;

		updateState({
			data: {
				rulesets: {
					[configRulesetKey]: {
						results: {
							[formSubjectResultKey]: {
								value: {
									$set: newValue
								}
							}
						}
					}
				}
			}
		});
	};

	/**
	 * Handles when the Textarea for feedback message changes.
	 */
	const feedbackChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, props: TextInputProps | TextareaProps) => {
		let resultKey = null;

		switch(true) {
			case props.name?.includes('reply-to'):
				resultKey = replytoFeedbackKey;
				break;

			case props.name?.includes('message'):
				resultKey = messageFeedbackKey;
				break;
                
			case props.name?.includes('subject'):
				resultKey = subjectFeedbackKey;
				break;
		}

		const newValue = event?.currentTarget.value;

		let rulesetKey = feedbackRulesetKey ?? createRandomId('ruleset');

		if(resultKey) {
			updateState({
				data: {
					rulesets: {
						[rulesetKey]: {
							results: {
								[resultKey]: {
									value: {
										$set: newValue
									}
								}
							}
						}
					}
				}
			});
		};

	}, [feedbackRulesetKey, replytoFeedbackKey, messageFeedbackKey, subjectFeedbackKey, updateState]);

	/**
	 * Handles when a different form input is selected for sending customer a response.
	 */
	 const feedbackRecipientElementChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
		// Dont continue if we have missing the key of the ruleset's rule
		if(!feedbackRulesetKey || !recipientFeedbackKey) return;

		updateState({
			data: {
				rulesets: {
					[feedbackRulesetKey]: {
						results: {
							[recipientFeedbackKey]: {
								form_item: {
									$set: event.currentTarget.value
								}
							}
						}
					}
				}
			}
		});
	}, [feedbackRulesetKey, recipientFeedbackKey, updateState]);

	return (
		<ScContainer>
			<Message
				style={{ marginBottom: 16 }}
				isSmall
			>
				Alla formulär loggas i formloggen...
			</Message>

			<CheckItem
				type="checkbox"
				title="E-postformulär"
				checked={isUsingRecipients}
				description="Uppgifterna i formuläret skickas till vald mottagare för formuläret"
				changed={recipientsToggledHandler}
			/>

			{isUsingRecipients && (
				<>
					<TextInput
						label="Ämnesrad"
						placeholder="Ämnet på formuläret"
						value={formSubjectResult?.value}
						changed={formSubjectChangedHandler}
					/>

					<TextInput
						id="recipient_recipient"
						name="recipient_recipient"
						label="Mottagare av formulärmail"
						description="Tryck Enter för att lägga till mottagare."
						placeholder="namn@exempel.se"
						value={inputValues.recipient}
						error={formValidation?.errors['recipient_recipient']}

						pressed={addEmailRecipientHandler}

						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation?.watch(ev, addEmailValueChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) =>
							formValidation?.registerElement(ref, {
								required: false,
								validation: {
									type: ValidationTypes.EMAIL
								}
							})}
					/>

					{Object.entries(recipients).length > 0 && (
						<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: -12, marginBottom: 16 }}>
							{Object.entries(recipients)
								.filter(([resultKey, result]) => 
									result.action === BlockFormRulesetResultActions.RECIPIENT)
								.map(([resultKey, result]) => (
									<Chip
										key={resultKey}
										name={resultKey}
										text={result.value || 'Empty email'}
										removed={removeRecipientClickedHandler}
									/>
								))}
						</div>
					)}
			 
					<TextInput
						id="recipient_bcc"
						name="recipient_bcc"
						label="BCC av formulärmail"
						description="Tryck Enter för att lägga till mottagare."
						placeholder="namn@exempel.se"
						value={inputValues.bcc}

						pressed={addEmailRecipientHandler}

						error={formValidation?.errors['recipient_bcc']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation?.watch(ev, addEmailValueChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) =>
							formValidation?.registerElement(ref, {
								required: false,
								validation: {
									type: ValidationTypes.EMAIL
								}
							})}
					/>

					{Object.entries(recipients).length > 0 && (
						<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: -12, marginBottom: 16 }}>
							{Object.entries(recipients)
								.filter(([resultKey, result]) => 
									result.action === BlockFormRulesetResultActions.BCC)
								.map(([resultKey, result]) => (
									<Chip
										key={resultKey}
										name={resultKey}
										text={result.value || 'Empty email'}
										removed={removeRecipientClickedHandler}
									/>
								))}
						</div>
					)}
			
				</>
			)}

			<CheckItem
				type="checkbox"
				title="Skicka ett svarsmail"
				description="Om ett svarsmail ska skickas till besökaren som fyller i forumläret."
				checked={isUsingFeedback}
				changed={feedbackRecipientsToggledHandler}
				// isDisabled={!isAnyEmailFormItem}
			/>
		
			{isUsingFeedback && (
				<>		
					<Message
						style={{ marginBottom: 16 }}
					>
						Inställningar för besökare
					</Message>
					
					<Select
						label="Svarsmail"
						description={isAnyEmailFormItem ? 'Välj vilket epostfält som ska vara mottagare av svarsmail' : 'Det saknas epost-fält i formuläret. Lägg till ett epost-fält för att använda svarsmail nedan.' }
						// isDisabled={!isAnyEmailFormItem}
						value={recipientFeedback?.form_item ?? ''} 
						changed={feedbackRecipientElementChangedHandler}
					>
						{!isAnyEmailFormItem && (
							<option>
								Inget fält för E-postadress hittades
							</option>
						)}

						{isAnyEmailFormItem && defaultFeedbackEmailItem[0] !== recipientFeedback?.form_item && (
							<option>
								Välj ett E-postadress fält
							</option>
						)}

						{formElements && Object.entries(formElements).map(([_elementKey, _element]) => {
							// skip non-root form elements and root itself
							if(!formElements.root.children.includes(_elementKey) || _elementKey === 'root') return null;
						
							return (
								<option
									key={_elementKey}
									value={_elementKey}
									disabled={_element.input_type !== BlockFormInputItemTypes.EMAIL || _element.disabled || _element.readonly }
								>
									{_element.name}
								</option>
							);
						})}
					</Select>

					<TextInput
						id="feedback_reply-to"
						name="feedback_reply-to"
						label="ReplyTo-adress"
						description="Defaultas till värdet på inställningen 'email.replyTo'"
						placeholder="namn@exempel.se"
						value={replytoFeedback?.value}
						error={formValidation?.errors['feedback_reply-to']}

						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation?.watch(ev, feedbackChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) =>
							formValidation?.registerElement(ref, {
								required: false,
								validation: {
									type: ValidationTypes.EMAIL
								}
							})}
						formValidationUnregister={formValidation?.unregisterElement}
					/>

					<TextInput
						isRequired
						id="feedback_subject"
						name="feedback_subject"
						label="Ämnesrad"
						placeholder="Ämnet på svarsmailet"
						value={subjectFeedback?.value}
						error={formValidation?.errors['feedback_subject']}

						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation?.watch(ev, feedbackChangedHandler, data);
						}}
						inputRef={(ref: HTMLInputElement | null) =>
							formValidation?.registerElement(ref, {
								required: true
							})}
						formValidationUnregister={formValidation?.unregisterElement}
					/>

					<Textarea
						isRequired
						id="feedback_message"
						name="feedback_message"
						label="Skicka med text som ska visas i svarsmailet"
						value={messageFeedback?.value || ''}
						error={formValidation?.errors['feedback_message']}
						formValidationUnregister={formValidation?.unregisterElement}
						resizeVertical
						changed={(
							ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
							...data
						) => {
							formValidation?.watch(ev, feedbackChangedHandler, data);
						}}
						inputRef={(ref: HTMLTextAreaElement | null) =>
							formValidation?.registerElement(ref, {
								required: true,
								validation: {
									type: ValidationTypes.REQUIRED
								}
							})}
					/>
				</>
			)}
		</ScContainer>
	);
};

export default Settings;

const ScContainer = styled.div`
	width: 416px;
	overflow-y: scroll;
	height: 100%;
	max-height: calc(100vh - 272px);
	padding: var(--modal-padding);
	box-sizing:border-box;
	background: var(--modal-side-bg-color);
`;
