import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Icon } from '../../../../../components/UI';

/**
 * @param articleNr     The article number of the product
 * @param name          The product's name
 * @param price         The price of the product.
 * @param currency      The currency for the price like usd, sek, clp, ....
 * @param thumbnail     The url for the thumbnail/product image
 */
const BuyMoreProduct = (props) => {
	// text used for the alt.
	const altText = `${props.name} - ${props.price} ${props.currency}`;

	return (
		<div className="buyMoreItemWrapper">
			<div className="buyMoreItem">
				{props.isDisabled && (
					<ScDisabled>
						<ScIcon icon={['fal', 'eye-slash']} />
					</ScDisabled>
				)}

				<ScImg
					isDisabled={props.isDisabled}
					className="buyMoreItem__img"
					alt={altText}
					src={props.thumbnail}
				/>
				<ScDetails isDisabled={props.isDisabled}>
					<div className="buyMoreItem__name">{props.name}</div>
					<div className="buyMoreItem__artNr">{`Art nr ${props.articleNr}`}</div>
					<div className="buyMoreItem__price">{props.price} kr</div>
					<button className="button -orange buyMoreItem__btn">
						Köp till
					</button>
				</ScDetails>
			</div>
		</div>
	);
};

BuyMoreProduct.propTypes = {
	articleNr: PropTypes.string,
	name: PropTypes.string,
	price: PropTypes.string,
	// currency: PropTypes.string,
	thumbnail: PropTypes.string,
};

export default memo(BuyMoreProduct);

const ScDisabled = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	background: rgba(102, 102, 102, 0.64);
	border-radius: 32px;
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 99;
`;

const ScIcon = styled(Icon)`
	width: 24px;
	height: 16px;
	display: block;
`;

const ScImg = styled.img`
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.4;
		`};
`;

const ScDetails = styled.div`
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.4;
		`};
`;
