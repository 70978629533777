import React, { useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
	openModalAction,
	closeModalAction,
	setModalConfigAction,
	setModalStateAction,
} from '../../store/actions/action-modals';

import Modal from './Modal';
import { updateModalCurrentState } from '../../store/thunks/thunk-modal';

const useModal = () => {
	// Store the modalId that belong to this instances of useModal
	const [modalID, setModalID] = useState(null);

	// The modal's config like actions, buttons, etc
	const modalConfig = useSelector((state) =>
		state.modal[modalID] ? state.modal[modalID].config : null
	);

	// The state the modal was opened with, it will remain the same under the modal's lifetime
	//		usefull when currentState changes and you need the original state, like for restoring changes
	const originalState = useSelector((state) =>
		state.modal[modalID] ? state.modal[modalID].originalState : null
	);

	// The state of the modal used to storing data, it will always be the latest data
	//	and can be changed using updateState
	const currentState = useSelector((state) =>
		state.modal[modalID] ? state.modal[modalID].currentState : null
	);

	// Redux
	const dispatch = useDispatch();

	/**
	 * Closes the modal.
	 */
	const close = useCallback(() => {
		// Don't execute on already closed modals.
		if (!modalID) return;

		// IMPORTANT! The modal's state cleaning have to run AFTER the nullation of modalID!!!!!
		// 	if the dispatch runs before the nullation of the ID, it will trigger a re-render and React will try to render the modal and will crash as the Redux state for the modal is now empty.
		// So that is why the nullation have to be performed before the cleaning of the state, to again, prevent rendering out a modal with no state, crashing with modal.config is undefined.
		const modalId = modalID;
		setModalID(null);
		dispatch(closeModalAction(modalId));
	}, [dispatch, modalID]);

	/**
	 * Open a modal with the specified configs.
	 * @param {object} config
	 */
	const open = useCallback(
		(config) => {
			// Close current opened modal before opening a new.
			if (modalID) close();

			// generate random id for the modal
			const id =
				Math.random().toString(36).substring(2) +
				Math.random().toString(36).substring(2);

			/**
			 * {array:['fal', 'ghost']}			icon			UNUSED! The Font Awesome's icon to show with the text for ex. ['fal', 'ghost']
			 * {string:No text}					text			The text to show in the action button
			 * {func:close}						action			The method to trigger when action is clicked. 
			 * {boolean:false}					isDefault		Only one Action can be default at a time, If the action is triggered by the backdrop of the modal.
			 * {boolean:true}					isVisible		If the action generates a button
             * 														may be used in combination with isDefault in case the backdrop action should not generate a button.
			 */

			// Add a default action if none is given.
			// 	The default func is to close the modal.
			let normalizedActions = null;
			if (config.actions !== false) {
				normalizedActions = config.actions
					? config.actions.map((action) => {
							return {
								icon: action.icon || ['fal', 'ghost'],
								text: action.text || 'No text',
								action: action.action || void 0,

								isDefault: !!action.isDefault,
								isVisible:
									action.isVisible === undefined ||
									action.isvisible === null
										? true
										: action.isVisible,
							};
					  })
					: [];

				// If no default action present, add the default Close, or if the array is empty =  there is no default action as there is no action at all.
				// if (!defaultActionPresent || normalizedActions.length <= 0) {
				// 	normalizedActions.push({
				// 		icon: ['fal', 'trash'],
				// 		text: 'Close',
				// 		action: close,

				// 		isDefault: true,
				// 		isVisible: true,
				// 	});
				// }
			}

			/**
		 * By "normalizing" i mean that the config get a default value if none set, do checks, and so on.
		 * 
			 * {string:null}				title			Modal's title, if null no title will be shown.
			 * {array[Actions]:null}		actions			Action to show, if null/empty a default Close action will be used, if false then no actions will be shown
			 * {object:{}}					state			The data to populate the modal's 'state' with.
			 *
			 * {int|boolean:false}			height			Set the height, if falsy autoAdjust using min- & max-height
			 * {string:5%}					minHeight		Min height in percent
			 * {string:98%}					maxHeight		Max height in percent
			 * {string:416px}				width			Width of modal in a CSS measure like px, %, vh, etc
			
			 * {string:center}				position		The position in the screen the modal will be shown, Left, Center, Right
			 *
			 * {boolean:false}				hideBackdrop	Hide the backdrop?
			 * {boolean:false}				isBackdropBlurred If the backgrop has a blur effect.
			 * {boolean:false}				isDismissable	Can the modal be closed by clicking on the backdrop? False hidens the default Action button too
			 * {boolean:false}				isDraggable		Can the modal be moved if dragged from the header? Can be minimized, Overrides hideBackdrop=true
			 * {boolean:false}				isPositionable	Can the modal's position be changed by the user?, It will or not show a icon to the left of the modal's title
			 *
			 */

			// a valid position is NOt null and with a value of 'left', 'center' or right
			const positionIsValid =
				config.position &&
				['left', 'center', 'right'].some((el) =>
					el.includes(config.position)
				);

			const normalizedConfig = {
				id: id,
				title: config.title || null,

				actions: normalizedActions,

				height: config.height || false,
				minHeight: config.minHeight || '5%',
				maxHeight: config.maxHeight || 'calc(100% - 32px)',
				width: config.width || '416px',

				position: positionIsValid ? config.position : 'center', // If position not valid use default 'center'

				hideBackdrop: !!config.hideBackdrop,
				isBackdropBlurred: !!config.isBackdropBlurred,
				isDismissable: !!config.isDismissable,
				isDraggable: !!config.isDraggable,
				isPositionable: !!config.isPositionable,
			};

			// Do some checks
			if (
				!normalizedConfig.isDismissable &&
				(!normalizedConfig.actions ||
					normalizedConfig.actions.length <= 0)
			)
				throw new Error(
					'[useModal] - Tried to open a Modal with no actions and is NOT dismissable, making it impossible to close'
				);

			// Check for isDismissable && a default action is present.
			const defaultActionPresent =
				normalizedActions &&
				!!normalizedActions.find((action) => action.isDefault);

			if (!defaultActionPresent && config.isDismissable)
				throw new Error(
					'[useModal] - Your modal is dismissable, but no default action given, please specify one!'
				);

			// Check if is isPositionable and position center as it will break due to not having a valid icon to show for positioning button.
			if (config.isPositionable && config.position === 'center')
				throw new Error(
					"[useModal] - Yor modal have an invalid combination, 'isPositionable' with position 'center', only 'left' or 'right are valid'"
				);

			dispatch(
				openModalAction({
					config: normalizedConfig,
					originalState: config.state,
					currentState: config.state,
				})
			);

			setModalID(id);

			return;
		},
		[close, dispatch, modalID]
	);

	/**
	 * Updates the modal config to something else
	 *
	 * @param {*} configKey 		The modal config key to update like position or isDismissable
	 * @param {*} value 			The new value for the specified config property.
	 */
	const setConfig = useCallback(
		(configKey, value) => {
			if (!value) {
				console.warn(
					`useModal - Can't call setConfig with an invalid 'value' = ${value}, ignoring`
				);
				return;
			}

			if (!modalID) {
				console.error(
					`useModal - Can't call setConfig on a modal that have not been opened yet or that has been closed, ignoring`
				);
				return;
			}

			if (configKey !== 'position') {
				console.warn(
					`useModal - Can't setConfig to ${configKey}, only 'position' allowed, ignoring`
				);
				return;
			}

			dispatch(setModalConfigAction(modalID, configKey, value));
		},
		[dispatch, modalID]
	);

	/**
	 * Sets the current state of the modal, replaces the complete state with data.
	 *
	 * @param {*} newState 			The new value for the state
	 */
	const setState = useCallback(
		(newState) => dispatch(setModalStateAction(modalID, newState)),
		[dispatch, modalID]
	);

	/**
	 * Updates the current state of the modal.
	 *
	 * @param {object} updateQry	An inmutability-helper update wry object.
	 */
	const updateState = useCallback(
		(updateQry) => {
			return dispatch(updateModalCurrentState(modalID, updateQry));
		},
		[dispatch, modalID]
	);

	/**
	 * Wrap a component inside a Modal (as Modal's child) and send down some extra props.
	 *
	 * @param {*} children
	 */
	const getAsComponent = useCallback(
		(children) => {
			if (!modalID) return null;

			// Return the Modal component only if the modal have been initialized with open as it generates the Modal's id
			const modalChildren = React.cloneElement(
				children || (
					<>
						This modal have no content, set some by passing a React
						component as the first and only parameter to
						<b>yourModal.getAsComponent()</b>
					</>
				),
				// Push extra props
				{
					modal: {
						config: modalConfig,
						currentState: currentState,
						setState: setState,
						close: close,
						updateState: updateState,
					},
				}
			);

			return (
				<Modal
					modal={{
						config: modalConfig,
						setConfig: setConfig,
						currentState: currentState,
						setState: setState,
						close: close,
					}}
				>
					{modalChildren}
				</Modal>
			);
		},
		[
			close,
			currentState,
			modalConfig,
			modalID,
			setConfig,
			setState,
			updateState,
		]
	);

	return {
		getAsComponent,
		open,
		close,
		originalState,
		currentState,
	};
};

export default useModal;
