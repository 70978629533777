import React from 'react';
import styled from 'styled-components/macro';
import { Row } from 'react-table';
import { DateTime } from 'luxon';
import { Redirect, RedirectsParams } from './Redirects.types';
import RedirectDetails from './RedirectDetails';
import useRedirects from './hooks/useRedirects';
import useCreateRedirect from './hooks/useCreateRedirect';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import useModal from '../../../hooks/Modal/useModal';
import { CloseModal, ModalProps } from '../../../hooks/useModal/useModal.model';
import { SkeletonTable } from '../../../components/Skeletons';
import { Message } from '../../../components/UI';
import Search from '../../../components/Search/Search';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';

// Columns used in the table
const columns = [
	{
	  Header: 'Begärd URL',
	  accessor: 'request_url' // accessor is the "key" in the data
	},
	{
	  Header: 'Skicka vidare till',
	  accessor: 'redirect_url'
	},
	// THIS IS HIDDEN UNTIL WE BUILD SUPPORT FOR LANGUAGES WHEN CREATING REDIRECT LINKS
	// {
	//   Header: 'Språk',
	//   accessor: 'language'
	// },
	{
	  Header: 'Senast anropad',
	  accessor: 'last_requested'
	}
];

const Redirects: React.FC = () => {
	// Modal to create or edit a redirect
	const redirectDetailsModal = useModal();

	// Holds the selected language and search term
	const [params, setParams] = React.useState<RedirectsParams>({
		lang: '',
		query: ''
	});

	// A function to show notifications
	const notification = useAlert()[1] as AlertDispatch;

	// Fetch redirects data
	const { data, isLoading: isLoadingRedirects, isFetching: isFetchingRedirects, isError: hasRedirectsError, refetch: refetchRedirects } = useRedirects(params);

	// Holds the useMutationResult with information about the create redirect mutation
	const createRedirect = useCreateRedirect();

	// Set how many pages to show on each page of the table
	const [resultsPerPage, setResultsPerPage] = React.useState(25);
	
	// Create an array of all redirects
	const redirects = React.useMemo(() => {
		if(!data?.redirects) return null;

		return Object.values(data?.redirects).reduce((prev, current) => {

			// Turn last_requested to a readable date with DateTime
			const redirects = current.map((redirect: Redirect) => ({
				...redirect,
				last_requested: DateTime.fromISO(redirect.last_requested).toLocaleString(DateTime.DATETIME_SHORT)
			}));

			return [
				...prev,
				...redirects
			];
		}, [] as Redirect[]);
		
	}, [data?.redirects]);

	/**
	 * Reset all states to their default values and refetch redirects
	 * 
	 * @returns {void}
	 */
	const resetModuleHandler = React.useCallback((): void => {
		refetchRedirects();
		setParams({
			lang: '',
			query: ''
		});
	}, [refetchRedirects]);

	/**
	 * Opens the modal to create or edit a redirect
	 * 
	 * @param {Row<Redirect>|undefined} selectedRow
	 * @returns {void}
	 */
	const openRedirectDetailsHandler = React.useCallback((selectedRow?: Row<Redirect>): void => {
		const selectedRedirect = selectedRow?.original;

		let modalButtons = [{
			text: 'Avbryt',
			isDefault: true,
			action: async (
				originalState: Redirect,
				currentState: Redirect,
				closeModal: CloseModal
			) => {
				closeModal();
			}
		}];

		if(!selectedRedirect) {
			modalButtons = [
				...modalButtons,
				{
					text: 'Spara',
					isDefault: false,
					action: async (
						originalState: Redirect,
						currentState: Redirect,
						closeModal: CloseModal
					) => {
						let alertId = notification('MODIFY', {
							priority: AlertPriorityTypes.info,
							title: 'Skapar 301-länk'
						});

						const cb = () => {
							refetchRedirects();
							closeModal();
						};

						createRedirect.mutate({ data: currentState, alertId, cb });
					}
				}
			];
		}

		redirectDetailsModal.open({
			title: selectedRedirect ? 'Redigera 301-länk' : 'Lägg till 301-länk',
			position: 'center',
			width: '40%',
			isDismissable: false,
			actions: modalButtons,
			state: selectedRedirect ?? {
				request_url: null,
				redirect_url: null,
				id: null
			}
		});
	}, [createRedirect, notification, redirectDetailsModal, refetchRedirects]);

	/**
	 * Language changed
	 * THIS IS HIDDEN UNTIL WE BUILD SUPPORT FOR LANGUAGES WHEN CREATING REDIRECT LINKS
	 * 
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	// const languageChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>): void => {
		
	// 	setParams(state => ({
	// 		...state,
	// 		lang: event.target.value
	// 	}));
	// }, []);

	/**
	 * Search query changed
	 * 
	 * @param {React.MutableRefObject<null>} input
	 * @returns {void}
	 */
	const searchQueryChangedHandler = React.useCallback((input?: React.MutableRefObject<HTMLInputElement>): void => {
		setParams(state => ({
			...state,
			query: input?.current ? input.current.value : ''
		}));
	}, []);

	return (
		<ModuleContainer
			header="Länkar - 301 Redirect"
			hasButton
			buttonText="Lägg till ny"
			buttonIcon={['fal', 'plus']}
			buttonOnClick={openRedirectDetailsHandler}
		>
			<ErrorBoundary resetted={resetModuleHandler}>

				{redirectDetailsModal.getAsComponent(
					<RedirectDetails
						modal={{} as ModalProps<Redirect>}
						refetchRedirects={refetchRedirects}
					/>
				)}

				<ScFilterContainer>
					{/** THIS LANGUAGE SELECT IS HIDDEN UNTIL WE BUILD SUPPORT FOR LANGUAGES WHEN CREATING REDIRECT LINKS */}
					{/* {SUPPORTED_LANGUAGES.length > 1 && (
						<ScSelect
							value={params.lang}
							changed={languageChangedHandler}
						>
							<option value="">
								Alla språk
							</option>
							{SUPPORTED_LANGUAGES.map((lang) => (
								<option
									key={lang.value}
									value={lang.value}
								>
									{lang.name}
								</option>
							))}
						</ScSelect>
					)} */}

					<Search 
						hasButton
						searchPlaceholder="Navigationstitel, url, titel..."
						searchBtnClicked={searchQueryChangedHandler}
						cleared={searchQueryChangedHandler}
						isDisabled={isLoadingRedirects || isFetchingRedirects}
						enableSearchOnEnter
					/>
				</ScFilterContainer>

				{(isLoadingRedirects || isFetchingRedirects) && <SkeletonTable />}

				{!isFetchingRedirects && hasRedirectsError && (
					<ScMessage
						size="16"
						icon={['fal', 'redo']}
						pressed={refetchRedirects}
					>
						Ett fel inträffade, ladda om
					</ScMessage>
				)}

				{!isLoadingRedirects && !redirects && !hasRedirectsError && !isFetchingRedirects && (
					<ScNoRedirects>
						Det finns inga länkar att visa...
					</ScNoRedirects>
				)}

				{!isLoadingRedirects && !hasRedirectsError && !isFetchingRedirects && redirects && (
					<ReactTable
						columns={columns}
						data={Object.values(redirects)}
						totalResults={Object.values(redirects).length}
						amountPages={Object.values(redirects).length / resultsPerPage}
						resultsPerPage={resultsPerPage}
						hasNativePagination
						hasNativeSorting
						rowClickedCallback={openRedirectDetailsHandler}
						paginationSelectOptions={['10', '25', '50', '100']}
						resultsPerPageChanged={(ev) => {
							const value = Number(ev.target.value);
							setResultsPerPage(value);
						}}
					/>
				)}
			</ErrorBoundary>
		</ModuleContainer>
	);
};

export default withErrorBoundary(Redirects);

const ScFilterContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
`;

// const ScSelect = styled(Select)`
// 	margin-right: 8px;
// `;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;

const ScNoRedirects = styled.p`
	font-style: italic;
`;