import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

// import PropTypes from 'prop-types';
import Label from './Label/Label';
import { Icon } from '../UI';

const TextInput = (props) => {
	const inputRef = useRef(null);

	// in case form validation is used fetch the unregister method from the object
	// doing it it this way will help useEffect to be more efficient instead of providing
	// the entire formValidation object in it's depts
	const unregisterElement = props.formValidationUnregister
		? props.formValidationUnregister
		: null;

	// Determines whether an error message should be shown or not
	const hasError =
		props.error && Array.isArray(props.error) && props.error.length > 0;

	useEffect(() => {
		return () => {
			// if form validation is used, then unregister listener when input component unmounts
			if (unregisterElement) {
				unregisterElement(props.id);
			}
		};
	}, [props.id, unregisterElement]);

	let input = (
		<>
			<ScInputContainer>
				{(props.leftIcon || props.rightIcon) && (
					<>
						{props.leftIcon && (
							<ScIconContainer leftIcon={props.leftIcon}>
								<Icon icon={props.leftIcon} />
							</ScIconContainer>
						)}

						{props.rightIcon && (
							<ScIconContainer rightIcon={props.rightIcon}>
								<Icon icon={props.rightIcon} />
							</ScIconContainer>
						)}
					</>
				)}
				<ScInput
					ref={props.inputRef ? props.inputRef : inputRef}
					id={props.id}
					name={props.name}
					type={props.type ? props.type : 'text'}
					min={0}
					className={props.className}
					style={props.style}
					maxLength={props.maxLength}
					disabled={props.isDisabled}
					tabIndex={props.tabIndex}
					readOnly={props.isReadOnly}
					onChange={(ev) => {
						ev.stopPropagation();
						if (
							props.changed &&
							typeof props.changed === 'function'
						)
							props.changed(ev, props, ev.target, inputRef);
					}}
					onBlur={(ev) => {
						ev.stopPropagation();
						if (
							props.blurred &&
							typeof props.blurred === 'function'
						)
							props.blurred(ev, props, ev.target, inputRef);
					}}
					onKeyPress={(ev) => {
						ev.stopPropagation();
						if (
							props.pressed &&
							typeof props.pressed === 'function'
						) {
							props.pressed(ev, props, ev.target, inputRef);
						}
					}}
					rightIcon={props.rightIcon}
					leftIcon={props.leftIcon}
					preventBuilderPreview={props.preventBuilderPreview}
					hasError={hasError}
					placeholder={props.placeholder}
					placeholderColor={props.placeholderColor}
					value={props.value === null ? '' : props.value}
				/>
			</ScInputContainer>
			{hasError && <ScError>{props.error}</ScError>}
			{props.info && <ScInfo>{props.info}</ScInfo>}
		</>
	);

	// if the omitLavel prop is not provided, then the input will be
	// wrapped in a label
	if (!props.omitLabel) {
		input = (
			<Label
				label={props.label}
				description={props.description}
				isRequired={props.isRequired}
				style={props.style}
			>
				{input}
			</Label>
		);
	}

	// in case it is an input combined with icons
	// if (props.leftIcon || props.rightIcon) {
	// 	input = (
	// 		<ScIconWrapper>
	// 			{props.leftIcon && (
	// 				<ScIconContainer leftIcon={props.leftIcon}>
	// 					<Icon icon={props.leftIcon} />
	// 				</ScIconContainer>
	// 			)}

	// 			{input}

	// 			{props.rightIcon && (
	// 				<ScIconContainer rightIcon={props.rightIcon}>
	// 					<Icon icon={props.rightIcon} />
	// 				</ScIconContainer>
	// 			)}
	// 		</ScIconWrapper>
	// 	);
	// }

	return input;
};

export default TextInput;

TextInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.oneOf(['color', 'text', 'number', 'password', 'email']),
	className: PropTypes.string,
	placeholder: PropTypes.string,
	placeholderColor: PropTypes.string,
	maxLength: PropTypes.number,
	value: PropTypes.string,
	tabIndex: PropTypes.number,
	isDisabled: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isRequired: PropTypes.bool,
	changed: PropTypes.func,
	pressed: PropTypes.func,
	blurred: PropTypes.func,
	leftIcon: PropTypes.array,
	rightIcon: PropTypes.array,
	preventBuilderPreview: PropTypes.bool,
	hasError: PropTypes.array,
	label: PropTypes.string,
	description: PropTypes.string,
	style: PropTypes.object,
	info: PropTypes.string,
	formValidationUnregister: PropTypes.func,
	error: PropTypes.array,
	inputRef: PropTypes.any,
};

const ScInputContainer = styled.div`
	position: relative;
`;

const ScError = styled.div`
	background: rgba(208, 2, 27, 0.1);
	border: 1px solid #d0021b;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;
	color: #d0021b;

	&:last-child {
		margin-bottom: 16px;
	}
`;

const ScInfo = styled.div`
	background: rgba(169, 169, 169, 0.1);
	border: 1px solid #a9a9a9;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;

	&:last-child {
		margin-bottom: 16px;
	}
`;

const ScInput = styled.input`
	background: var(--bg-bright-color);
	border: 1px solid #a9a9a9;
	box-shadow: 0 0 0 rgba(51, 51, 51, 0);
	flex-grow: 1;
	max-width: 100%;
	padding: 8px;
	height: 40px;
	transition: border 0.2s ease;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	/* margin-bottom: 16px; */
	width: 100%;
	color: var(--font-body-color);

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	-moz-appearance: textfield;

	:focus {
		border-color: var(--dark-color);
		box-shadow: 0 0 4px rgba(51, 51, 51, 0.5);
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	${(props) =>
		props.placeholderColor &&
		css`
			&::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: ${props.placeholderColor};
				opacity: 1; /* Firefox */
			}
		`}

	${(props) =>
		props.isBright &&
		css`
			border-color: #fafafa;
			box-shadow: 0 0 0px rgba(0, 0, 0, 0);
		`}


	${(props) =>
		props.hasError &&
		css`
			border: 1px solid #d0021b;
			margin-bottom: 0px;
		`}

	${(props) =>
		props.type === 'checkbox' &&
		css`
			display: none;
		`}

	${(props) =>
		props.type === 'radio' &&
		css`
			display: none;
		`}

	${(props) =>
		props.leftIcon &&
		css`
			padding-left: 38px;
		`}

	${(props) =>
		props.rightIcon &&
		css`
			padding-right: 38px;
		`}
`;

const ScIconContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;

	${(props) =>
		props.leftIcon &&
		css`
			left: 16px;
		`}

	${(props) =>
		props.rightIcon &&
		css`
			right: 16px;
		`}
`;
