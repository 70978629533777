import React from 'react';
import styled from 'styled-components/macro';
import { ModalContainerProps } from './model.ModalContainer';

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
	return (
		<ScContainer className={props.className}>
			{props.children}
		</ScContainer>
	);
};

export default ModalContainer;

const ScContainer = styled.div`
	padding: 40px;
	border-top: 1px solid var(--border-grey-color);
`;
