import React from 'react';
import styled from 'styled-components/macro';
import { ControlPanelHeadingProps } from './model.ControlPanelHeading';

const ControlPanelHeading: React.FC<ControlPanelHeadingProps> = (props) => {
	return (
		<ScDiv>
			{props.children}
		</ScDiv>
	);
};
export default ControlPanelHeading;

const ScDiv = styled.h2`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 16px;
`;
