import React from 'react';
import { AuthContextProps } from './types';

export const AuthContext = React.createContext<AuthContextProps>({
	authUser: null,
	authLoader: false,
	lostCredentials: async () => 1,
	setPass: async () => 1,
	signIn: async () => 1,
	signOut: async () => 1,
});
