import React from 'react';
import styled, { css } from 'styled-components/macro';
import { MessageProps, StyledMessageProps } from './Message.types';
import Icon from '../Icon/Icon';

const Message: React.FC<MessageProps> = (props) => {
	return (
		<ScContainer
			onClick={props.pressed}
			style={props.style}
			className={props.className}
			isSmall={props.isSmall}
			size={props.size}
			weight={props.weight}
		>
			{props.children}
			
			{props.icon && (
				<ScIcon
					icon={props.icon}
				/>
			)}
		</ScContainer>
	);
};

export default Message;

const ScContainer = styled.div<StyledMessageProps>`
	font-size: ${(props) => props.size || 14}px;
	font-weight: ${(props) => props.weight || 400};
	display: flex;
	cursor: pointer;
	align-items: center;

	${(props) => props.isSmall && css`
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 8px;
	`}
`;

const ScIcon = styled(Icon)`
	margin-left: 8px;
`;