import axios from '../../utils/oc-axios';
import {
	CustomerList,
	SetCustomersAction,
	CustomersListResponse,
	DeleteCustomerAction
} from '../types/CustomerTypes';
import { AjaxResponse, ThunkDispatch } from '../types/ThunkTypes';
import {
	setCustomersList,
	deleteCustomerFromState
} from '../actions/action-customers';
import { FinalSortFilterState } from '../../modules/EngelsonsCustomers/containers/Customers/types.Customers';

const pageSize = 100;
/**
 * Used to retrieve all settings
 *
 * @returns {Promise<CustomerList>}
 */
export const fetchAllCustomers = (filter: FinalSortFilterState) => {
	return async (dispatch: ThunkDispatch<SetCustomersAction>) => {
		return new Promise<CustomerList>((resolve, reject) => {
			axios
				.get('modules/customers', { params: filter })
				.then((resp: AjaxResponse<CustomersListResponse>) => {
					const { status, data } = resp;
					const amount = data?.amount;

					if(status !== 200) reject();

					const { customers } = data;

					dispatch(
						setCustomersList(customers, {
							pageCount: Math.ceil(amount / pageSize),
							totalResults: amount,
							resultsPerPage: pageSize
						})
					);
					resolve(customers);
				})
				.catch((err) => {
					console.error('[thunk-settings]', err);
					reject(err);
				});
		});
	};
};

/**
 * Used to delete a specific customer
 *
 * @returns {ThunkDispatch<void>}
 */
export const deleteCustomer = (customerId: number) => {
	if(!customerId) {
		return Promise.reject('Please provide a valid customer id');
	}

	return async (dispatch: ThunkDispatch<DeleteCustomerAction>) => {
		return new Promise<void>((resolve, reject) => {
			axios
				.delete(`modules/customers/${customerId}`)
				.then((resp) => {
					const { status } = resp;

					if(status !== 204) reject();

					dispatch(deleteCustomerFromState(customerId));
					resolve();
				})
				.catch((err) => {
					console.error('[thunk-settings]', err);
					reject(err);
				});
		});
	};
};
