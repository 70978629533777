import React from 'react';
import lodash from 'lodash';
import {
	_doRegex,
	_verifyDate,
	_verifyEmail,
	_verifyNumeric,
	_verifyPhoneNumber,
	_verifySlug,
	_verifySlugOrUrl,
	_verifyUrl
} from './testingAlgoritms';
import {
	ErrorMessages,
	ExternalCallback,
	FormValidation,
	SubmitCallback,
	ValidationByObject,
	ValidationElementConfig,
	ValidationElements,
	ValidationErrors,
	ValidationOptions,
	ValidationTypes
} from './types';

const useFormValidation = (): FormValidation => {
	const elementsRef = React.useRef<ValidationElements>({});
	const [errors, setErrors] = React.useState<ValidationErrors>({});

	/**
	 * Handles the validaton of text fields, text areas
	 *
	 * @param {ValidationErrors} inputErrors
	 * @param {ValidationOptions} validation
	 * @param {string | number} value
	 * @returns {ValidationErrors}
	 */
	const _verifyTextFormFields = React.useCallback(
		(
			inputErrors: ValidationErrors,
			validation: ValidationOptions,
			value: string | number,
			id: string
		): ValidationErrors => {
			// handles common cases and custom regex checks
			let validationType = validation;

			if(lodash.isObject(validation)) {
				const { type } = validation;
				validationType = type;
			}

			switch(true) {
				// verifies email
				case validationType === ValidationTypes.EMAIL:
					if(!_verifyEmail(value as string))
						inputErrors[id].push(ErrorMessages.EMAIL);
					break;

				case validationType === ValidationTypes.PHONE:
					if(!_verifyPhoneNumber(value as number))
						inputErrors[id].push(ErrorMessages.PHONE);
					break;

				// verifies url
				case validationType === ValidationTypes.URL:
					if(!_verifyUrl(value as string))
						inputErrors[id].push(ErrorMessages.URL);
					break;

				// verifies number
				case validationType === ValidationTypes.NUMERIC:
					if(!_verifyNumeric(value))
						inputErrors[id].push(ErrorMessages.NUMERIC);
					break;

				case validationType === ValidationTypes.DATE:
					if(!_verifyDate(value as string))
						inputErrors[id].push(ErrorMessages.DATE);
					break;

				case validationType === ValidationTypes.SLUG:
					if(!_verifySlug(value as string))
						inputErrors[id].push(ErrorMessages.SLUG);
					break;

				case validationType === ValidationTypes.SLUGURL:
					if(!_verifySlugOrUrl(value as string))
						inputErrors[id].push(ErrorMessages.SLUGURL);
					break;

				default:
					if(lodash.isObject(validation)) {
						const valStr = value as string;
						let validationMin = true;
						let validationMax = true;

						// deals with fields where length is < min
						if(validation.min && valStr.length < validation.min) {
							const msg = ErrorMessages.MIN;
							validationMin = false;

							inputErrors[id].push(
								msg.replace('{min}', ErrorMessages.MIN)
							);
						}

						// deals with fields where length is > max
						if(validation.max && valStr.length > validation.max) {
							const msg = ErrorMessages.MAX;
							validationMax = false;
							inputErrors[id].push(
								msg.replace('{max}', ErrorMessages.MAX)
							);
						}

						if(
							validationType === ValidationTypes.REGEX &&
							validation.pattern
						) {
							const regex = _doRegex(value, validation.pattern);
							if(!regex || !validationMin || !validationMax) {
								const errorMsg = extractCustomMessage(
									validation
								);
								inputErrors[id] = [errorMsg];
							}
						}
					} else {
						if(!value)
							inputErrors[id].push(ErrorMessages.REQUIRED);
					}
			}

			return inputErrors;
		},
		[]
	);

	/**
	 * Handles verification of checkboxes and radio buttons
	 *
	 * @param {ValidationErrors} inputErrors
	 * @param {HTMLTextAreaElement | HTMLInputElement} reference
	 * @param {ValidationOptions} validation
	 * @param {string} id
	 * @returns {ValidationErrors}
	 */
	const _verifyCheckedInputs = React.useCallback(
		(
			inputErrors: ValidationErrors,
			reference: HTMLTextAreaElement | HTMLInputElement,
			validation: ValidationOptions,
			id: string
		): ValidationErrors => {
			if(
				(!reference.value ||
					('checked' in reference && !reference?.checked)) &&
				lodash.isObject(validation)
			) {
				const errorMsg = extractCustomMessage(
					validation,
					ErrorMessages.UNCHECKED
				);

				inputErrors[id].push(errorMsg);
			}

			return inputErrors;
		},
		[]
	);

	/**
	 * Registers an element and stores it to a reference
	 *
	 * @param {HTMLInputElement} ref
	 * @param {ValidationElementConfig} config
	 */
	const registerElement = React.useCallback(
		(
			ref: HTMLTextAreaElement | HTMLInputElement | null | undefined,
			config: ValidationElementConfig
		): void => {
			if(!ref) return undefined;

			const id = ref.getAttribute('id') as string;

			elementsRef.current = {
				...elementsRef.current,
				[id]: {
					...elementsRef.current[id],
					reference: ref,
					config: config
				}
			};
		},
		[]
	);

	/**
	 * Unregisters an element from form validation
	 * Useful in dynamic forms where input elements may be unmounted
	 *
	 * @param {string} id
	 */
	const unregisterElement = React.useCallback((id: string): void => {
		const updatedRef = Object.keys(elementsRef.current).reduce(
			(
				object: ValidationElements,
				key: keyof ValidationElements
			): ValidationElements => {
				if(key !== id) {
					object[key] = elementsRef.current[key];
				}

				return object;
			},
			{}
		);

		elementsRef.current = updatedRef;
	}, []);

	/**
	 * Will return a bool whether there are form validation errors or not
	 *
	 * @returns {boolean}
	 */
	const formHasErrors = React.useCallback(() => {
		return !lodash.isEmpty(errors);
	}, [errors]);

	/**
	 * Resets the error state
	 */
	const resetErrors = React.useCallback(() => {
		setErrors({});
	}, []);

	/**
	 * Verify whether the given value for an input matches it's config
	 *
	 * @param {string} id
	 * @returns {ValidationErrors}
	 */
	const verify = React.useCallback(
		(id: string): ValidationErrors => {
			const element = elementsRef.current[id];
			const reference = element.reference;
			const value = reference.value.trim();
			const { validation, required } = element.config;
			const inputErrors: ValidationErrors = {
				[id]: []
			};

			if(required || value) {
				switch(true) {
					case reference.type === 'checkbox' &&
						(lodash.isObject(validation) ||
							lodash.isString(validation)):
						if(validation) {
							return _verifyCheckedInputs(
								inputErrors,
								reference,
								validation,
								id
							);
						}
						break;

					case (reference.type === 'text' ||
						reference.type === 'textarea' ||
						reference.type === 'password') &&
						(lodash.isObject(validation) ||
							lodash.isString(validation)):
						if(validation) {
							return _verifyTextFormFields(
								inputErrors,
								validation,
								value,
								id
							);
						}
						break;

					default:
						if(required && !value) {
							inputErrors[id].push(ErrorMessages.REQUIRED);
						}
				}
			}

			return inputErrors;
		},
		[_verifyCheckedInputs, _verifyTextFormFields]
	);

	/**
	 * Goes through form elements that were registered in the hooks instance
	 * Each element will be verified according to it's unique rule set
	 *
	 * Can be called in parent component
	 * Is called automatically by the submit function when executed
	 *
	 * @returns {ValidationErrors}
	 */
	const verifyAll = React.useCallback((): ValidationErrors => {
		return Object.keys(elementsRef.current).reduce((current, inputId) => {
			const input = verify(inputId);

			if(input[inputId].length) {
				return {
					...current,
					...input
				};
			} else return current;
		}, {});
	}, [verify]);

	/**
	 * Will listen to changes, verify them and call the given callback method
	 *
	 * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} ev
	 * @param {ExternalCallback} externalCallback
	 * @param {any} callbackArgs
	 * @returns {ExternalCallback | undefined}
	 */
	const watch = React.useCallback(
		(
			ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
			externalCallback: ExternalCallback | null = null,
			callbackArgs: any | null = null
		): ExternalCallback | void => {
			const id = ev.target.id;

			if(!lodash.isFunction(externalCallback)) {
				console.warn(
					'[watch expects second parameter to be a function]'
				);
				return;
			}

			if(id in elementsRef.current) {
				const inputErrors = verify(id);

				if(inputErrors[id]) {
					let errorState = {
						...errors,
						...inputErrors
					};

					if(!inputErrors[id].length) {
						errorState = Object.keys(errorState).reduce(
							(prev: ValidationErrors, input: string) => {
								if(input !== id) prev[input] = errors[input];

								return prev;
							},
							{}
						);
					}

					setErrors(errorState);
				}

				return externalCallback(ev, ...callbackArgs);
			}

			console.warn(
				'[input was not successfully registered. Watch cannot work with what it got, element\'s property \'id\' may be missing]'
			);
		},
		[errors, verify]
	);

	/**
	 * Typically added as the action of a button
	 * Will either call the given callback registered with the hook
	 * Or update the state and re-render parent component if errors were found
	 */
	const submit = React.useCallback(
		(submitCallback: SubmitCallback | null = null): void => {
			const formErrors = verifyAll();

			if(!lodash.isEmpty(formErrors)) setErrors(formErrors);
			else if(submitCallback && lodash.isFunction(submitCallback)) {
				setErrors(formErrors);
				submitCallback();
			}
		},
		[verifyAll]
	);
    
	/**
     * Removes a prop property from the error object
     * 
     * @param {string} id
     */
	const resetSpecificError = React.useCallback((id: string) => {
		setErrors((errors) => lodash.omit(errors, [id]));
	}, []);

	/**
	 * Reset the hook and use the same instance for another form.
	 */
	const reset = React.useCallback(() => {
		elementsRef.current = {};

		if(errors.length) resetErrors();
	}, [errors.length, resetErrors]);

	return React.useMemo(() => {
		return {
			registerElement,
			unregisterElement,
			watch,
			errors,
			submit,
			verify,
			elementsRef,
			formHasErrors,
			reset,
			resetErrors,
			resetSpecificError
		};
	}, [
		errors,
		formHasErrors,
		registerElement,
		reset,
		resetErrors,
		submit,
		unregisterElement,
		verify,
		watch,
		resetSpecificError
	]);
};

/**
 * Will extract the error message if set in an validation object
 * If an optional error message is provided that will be returned
 * As the last option an "unknown"-error message will be returned if no of above criteria is truthy
 *
 * @param validation
 * @returns string
 */
const extractCustomMessage = (
	validation: ValidationByObject,
	opt?: string
): string => {
	if('message' in validation && lodash.isString(validation.message)) {
		return validation.message;
	}

	if(opt) return opt;

	return ErrorMessages.UNKNOWN;
};

export default useFormValidation;
