import {
	UpdatedSettings,
	SettingsActions,
	SettingsList,
	Setting
} from '../../types/ControlPanel/SettingsTypes';

/**
 * Used when retrieving all settings
 * This function is called through a thunk
 *
 * @param {SettingsList} setting
 * @returns {SettingsAction}
 */
export const setInitialSettings = (settingsList: SettingsList) => {
	return {
		type: SettingsActions.SET_INITIAL,
		data: settingsList
	};
};

/**
 * Used to edit a specific setting
 * This function is called through a thunk
 *
 * @param {Setting} setting
 * @returns {SettingsAction}
 */
export const setSpecificSettings = (setting: Setting) => {
	return {
		type: SettingsActions.SET_EDIT_ITEM,
		data: setting
	};
};

/**
 * Used when updating one or more settings
 * This function is called through a thunk
 *
 * @param {UpdatedSettings} settings
 * @returns {SettingsAction}
 */
export const updateMultipleSettings = (settings: UpdatedSettings) => {
	return {
		type: SettingsActions.UPDATE_ITEM,
		data: settings
	};
};

/**
 * Used to clear an specific item selected for editing
 * This function would normally be called when hitting "Cancel" from a modal
 *
 * @returns {SettingsAction}
 */
export const clearEditItem = () => {
	return {
		type: SettingsActions.CLEAR_EDIT_ITEM
	};
};
