import styled, { css } from 'styled-components/macro';

const ToolbarItem = styled.div<{ active?: boolean}>`
	cursor: pointer;
	height: 40px;
	width: 40px;
	padding: 8px;
	text-align: center;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;

	:hover {
		background-color: #333;
	}

	${(props) => props.active && css`
		background-color: #888888;
		color: #fafafa;
	`}
`;

export default ToolbarItem;
