import React from 'react';

/**
 * Hook that check clicks outside of the passed refs
 */
const useClickOutside = (refs: React.MutableRefObject<any>[]) => {
	const [isClickedOutside, setIsClickedOutside] = React.useState(false);

	/**
     * Set state if clicked on outside of the given element
     */
	React.useEffect(() => {
        
		const handleClickOutside = (event: MouseEvent) => {
			// Check if any of the given refs contains the event.target
			const isClickedOutside = refs.every(ref => {
				if(ref.current && !ref.current.contains(event.target)) {
					return true;
				}
			});

			setIsClickedOutside(isClickedOutside);
		};

		// Listen to click events
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refs]);

	/**
     * Reset the isClickedOutside state
     * 
     * @returns {void}
     */
	const resetIsClickedOutside = React.useCallback((): void => {
		setIsClickedOutside(false);
	}, []);

	return { 
		isClickedOutside,
		resetIsClickedOutside
	};
};

export default useClickOutside;