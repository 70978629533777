import { jsx } from 'slate-hyperscript';
import {
	LINK,
	BULLETED_LIST,
	HEADING_FOUR,
	HEADING_ONE,
	HEADING_THREE,
	HEADING_TWO,
	LIST_ITEM,
	NUMBERED_LIST,
	PARAGRAPH
} from '../../OC7Editor';

// Describing DOM nodes of type Element and their custom and internal props in this Slate setup
// The "type"-is mandatory, you may extend with more props (see e.g. A)
const ELEMENT_TAGS = {
	A: (el) => {
		const href = el.getAttribute('data-hash') ?? el.getAttribute('href');
		
		let attributes = {
			type: LINK,
			href: href,
			new_window: el.getAttribute('target'),
			button_class: el.getAttribute('class')
		};
		
		// If the link has a data-hash attribute, then it is an advanced link and will be treated differently
		if(el.getAttribute('data-hash')) {
			attributes = {
				...attributes,
				uri_type: 'advanced'
			};
		}

		return attributes;
	},
	H1: () => ({ type: HEADING_ONE }),
	H2: () => ({ type: HEADING_TWO }),
	H3: () => ({ type: HEADING_THREE }),
	H4: () => ({ type: HEADING_FOUR }),
	LI: () => ({ type: LIST_ITEM }),
	OL: () => ({ type: NUMBERED_LIST }),
	UL: () => ({ type: BULLETED_LIST }),
	P: () => ({ type: PARAGRAPH })
};

// Describing marks and their custom and internal props in this Slate setup
const TEXT_TAGS = {
	CODE: () => ({ code: true }),
	DEL: () => ({ strikethrough: true }),
	EM: () => ({ italic: true }),
	I: () => ({ italic: true }),
	S: () => ({ strikethrough: true }),
	STRONG: () => ({ strong: true }),
	U: () => ({ underline: true })
};

/**
 * Deserializes DOM to JS-objects that Slate can interpret
 *
 * @param {Element} el
 * @returns {string | null | Node[]}
 */
export const deserialize = (el) => {
	// If element is a text node, then return text content
	if(el.nodeType === 3) {
		return el.textContent;
		// If the type is not of type Element, then return null
	} else if(el.nodeType !== 1) {
		return null;
		// If the nodeName is BR then replace it with \n as Slate does not support <br/>
		// Slate needs nodes of type Element to contain at least one text element, thus <br/> is out of the question
	} else if(el.nodeName === 'BR') {
		return '\n';
	}

	const { nodeName } = el;
	let parent = el;

	// Recursivily deserialize child elements;
	const children = Array.from(parent.childNodes).map(deserialize).flat();

	// As an element node needs at least on text node we will prepare children without data with an empty text
	if(!children.length) children.push({ text: '' });

	// Transform body into a fragment
	if(el.nodeName === 'BODY') {
		let textObject = jsx('fragment', {}, children);

		// Old text content from the previous version is rarely wrapped in an element node
		// This fix will guarantee each node has what it needs to be interpreted by slate
		textObject = textObject.map((obj) => {
			if(!obj.type) {
				return {
					type: PARAGRAPH,
					children: [obj]
				};
			}

			return obj;
		});

		return textObject;
	}

	if(ELEMENT_TAGS[nodeName]) {
		const attrs = ELEMENT_TAGS[nodeName](el);

		let extendedAttrs = {
			...attrs
		};

		// Custom fix for element alignments
		if(el.style.textAlign) {
			extendedAttrs = {
				...attrs,
				alignment: el.style.textAlign
			};
		}

		// Convert elements to an object slate can interpret
		return jsx('element', extendedAttrs, children);
	}

	// Convert text tags to an object slate can interpret
	if(TEXT_TAGS[nodeName]) {
		const attrs = TEXT_TAGS[nodeName](el);
		return children.map((child) => jsx('text', attrs, child));
	}

	return children;
};
