import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { parseStringAsObjectPath } from 'react-utils';
import { CheckItem } from '../../../../Forms';
import { ModalSettingsContainer } from '../../../../UI';

const BlockTextSettings = (props) => {
	// The modal hook this component is inside of.
	const { updateState } = props.modal;
	const state = props.modal.currentState.data;

	// The func to trigger when something inside here changed.
	const changed = props.changed;

	/**
	 * HAndles when the checkbox changes it's value.
	 *
	 * @param {Event} ev
	 * @param {object} componentProps
	 */
	const checkboxChangedHandler = useCallback(
		(ev, componentProps) => {
			const { preventBuilderPreview } = componentProps;

			const suggestedState = {
				data: parseStringAsObjectPath(
					ev.target.name,
					ev.target.checked
				)
			};

			// update modal's state, it will return the updated object.
			updateState(suggestedState).then((updatedState) => {
				// notify Builder to update the block for preview to work
				if(!preventBuilderPreview) changed(updatedState);
			});
		},
		[changed, updateState]
	);

	return (
		<ModalSettingsContainer>
			<CheckItem
				type="checkbox"
				title="Hopfällbar text"
				description="Gömmer texten utan de första 3 rader."
				name="partially_hidden"
				changed={checkboxChangedHandler}
				checked={state.partially_hidden}
			/>
		</ModalSettingsContainer>
	);
};

BlockTextSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func
};

export default BlockTextSettings;
