import React, { useEffect } from 'react';
import update from 'immutability-helper';
import { Category, Item } from './types.useCheckboxTree';

/**
 * Calculate if a category should be check if all it's items are checked.
 *
 * @param boolean	If the category is checked or not
 */
const calcCategoryCheckboxFromChildren = (category: Category): boolean => {
	// set the isChecked to default true.
	let newCheckedValue = true;

	// have the return false as true and if any child is not checked then
	// 	change it to false and stop execution as if ony one children/item
	//	if not checked then the category will not be that either.
	//	With other words the category checkedbox will be check is all items are checked.
	if(category.items) {
		for(const item of Object.values(category.items)) {
			if(!item.isChecked) {
				newCheckedValue = false;
				break;
			}
		}
	}

	return newCheckedValue;
};

/**
 * Calculate if a category should be disabled if all it's items are disabled.
 *
 * @param category
 */
const calcCategoryDisabledFromChildren = (category: Category): boolean => {
	// set the isDisabled to default false.
	let newCheckboxValue = false;

	// have the return false as true and if any child is not checked then
	// 	change it to false and stop execution as if ony one children/item
	//	if not checked then the category will not be that either.
	//	With other words the category checkedbox will be check is all items are checked.
	if(category.items) {
		for(const item of Object.values(category.items)) {
			if(item.isEnabled) {
				newCheckboxValue = true;
				break;
			}
		}
	}

	return newCheckboxValue;
};

type beforeRenderCbType = (tree: Category[]) => Category[];

interface CheckboxTreeConfig {
	data: Category[];
	beforeRenderCb?: beforeRenderCbType;
}

/**
 * TODO
 *
 * @param config
 */
const useCheckboxTree = (config: CheckboxTreeConfig) => {
	const { data, beforeRenderCb } = config;

	const [isCalculatingTree, setIsCalculatingTree] = React.useState<boolean>(
		true
	);
	const [tree, setTree] = React.useState<Category[]>([]);

	/**
	 * Toggle a category's checkbox.
	 *
	 * @param id The category's id.
	 */
	const toggleCategoryCheckbox = React.useCallback(
		(id: string) => {
			const categoryIndex = tree.findIndex((cat) => cat.id === id);
			const category = tree[categoryIndex];

			// toggle the current state of the category.
			const newCheckedState = !category.isChecked;

			// update the category's items to have the new checked state.
			const updatedItems = category.items.map((item: Item) =>
				update(item, {
					isChecked: { $set: newCheckedState }
				}));

			// update the tree with the updated category.
			const updatedTree = update(tree, {
				[categoryIndex]: {
					isChecked: { $set: newCheckedState },
					items: { $set: updatedItems }
				}
			});

			// run tree throw beforeRenderCallback to do another
			//	user specific modification to the tree before rendering it.
			const newTree = beforeRenderCb
				? beforeRenderCb(updatedTree)
				: updatedTree;

			// update state.
			setTree(newTree);
		},
		[beforeRenderCb, tree]
	);

	/**
	 * Toggle a category's item checkbox.
	 *
	 * @param id The items's id.
	 */
	const toggleItemCheckbox = React.useCallback(
		(key: string) => {
			let categoryIndex = 0;
			let itemIndex = 0;

			// lopp all categories and their items to fins the category's & item's index.
			for(const [_catIndex, _cat] of tree.entries()) {
				for(const [_itemIndex, _item] of _cat.items.entries()) {
					const isCorrectITem = _item.key === key;

					// if the item match then stre the category & item index.
					if(isCorrectITem) {
						categoryIndex = _catIndex;
						itemIndex = _itemIndex;
					}
				}
			}

			const item = tree[categoryIndex].items[itemIndex];

			// Update the item with the new checked value.
			let updatedTree = update(tree, {
				[categoryIndex]: {
					items: {
						[itemIndex]: {
							isChecked: { $set: !item.isChecked }
						}
					}
				}
			});

			// update the category's checkbox depending on it's items value
			updatedTree = update(updatedTree, {
				[categoryIndex]: {
					isChecked: {
						$set: calcCategoryCheckboxFromChildren(
							updatedTree[categoryIndex]
						)
					}
				}
			});

			// run tree throw beforeRenderCallback to do another
			//	user specific modification to the tree before rendering it.
			const newTree = beforeRenderCb
				? beforeRenderCb(updatedTree)
				: updatedTree;

			// update state.
			setTree(newTree);
		},
		[beforeRenderCb, tree]
	);

	/**
	 * Store the tree data in state.
	 */
	useEffect(() => {
		// calculate the checkbox checked status for every category
		const updatedTree = data.map((category) =>
			update(category, {
				isChecked: { $set: calcCategoryCheckboxFromChildren(category) },
				isEnabled: { $set: calcCategoryDisabledFromChildren(category) }
			}));

		// run tree throw beforeRenderCallback to do another
		//	user specific modification to the tree before rendering it.
		const newTree = beforeRenderCb
			? beforeRenderCb(updatedTree)
			: updatedTree;

		// update state.
		setTree(newTree);

		setIsCalculatingTree(false);
	}, [beforeRenderCb, data]);

	return {
		tree,
		isCalculatingTree,
		toggleCategoryCheckbox,
		toggleItemCheckbox
	};
};

export default useCheckboxTree;
