import React from 'react';
import { TableRowProps } from './model.TableRow';
import { ScTr } from './style.TableRow';

const TableRow: React.FC<TableRowProps> = (props) => {
	const { name, className, children, clicked } = props;

	/**
	 * Triggers when user clicks on element.
	 */
	const clickedHandler = React.useCallback(
		(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			ev.stopPropagation();

			if(clicked) {
				if(!name)
					throw new Error(
						'A \'name\' prop must be given to use click function.'
					);

				clicked(name);
			}
		},
		[clicked, name]
	);

	return (
		<ScTr
			className={className}
			onClick={clickedHandler}
		>
			{children}
		</ScTr>
	);
};

export default TableRow;
