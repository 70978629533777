import React from 'react';
import styled from 'styled-components/macro';
import { InputRangeProps } from './model.InputRange';

const InputRange: React.FC<InputRangeProps> = (props) => {
	return (
		<ScInputRange
			type="range"
			onChange={(ev: any) => props.changed(ev, props)}
			min={props.min}
			max={props.max}
			step={props.step}
			name={props.name}
			value={props.value}
			// {...props}
		/>
	);
};

export default InputRange;

const ScInputRange = styled.input`
	-webkit-appearance: none;
	margin: 20px 0 4px;
	width: 100%;
	background-color: transparent;

	:focus {
		outline: none;
	}

	::-webkit-slider-runnable-track {
		width: 100%;
		height: 18px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: none;
		background: transparent;
		border-top: 1px solid #c4c4c4;
	}

	::-webkit-slider-thumb {
		box-shadow: 0 0 2px rgba(0, 0, 0, 0);
		border: 1px solid var(--dark-color);
		height: 16px;
		width: 16px;
		border-radius: 16px;
		background: var(--dark-color);
		cursor: grab;
		-webkit-appearance: none;
		margin-top: -8px;
		transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
		transform: scale(1);
		:hover {
			transform: scale(1.2);
			box-shadow: 1px 0 4px rgba(0, 0, 0, 0.6);
		}
		:active {
			cursor: grabbing;
		}
	}

	:focus::-webkit-slider-runnable-track {
		background: transparent;
	}

	::-moz-range-track {
		width: 100%;
		height: 1px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: none;
		background: #dfdfdf;
		border-radius: 1.3px;
		border: 0;
	}

	::-moz-range-thumb {
		box-shadow: 0 0 2px rgba(0, 0, 0, 0);
		border: 1px solid var(--dark-color);
		height: 16px;
		width: 16px;
		border-radius: 16px;
		background: var(--dark-color);
		cursor: grab;
		margin-top: -8px;
		transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
		transform: scale(1);
		:hover {
			transform: scale(1.2);
			box-shadow: 1px 0 4px rgba(0, 0, 0, 0.6);
		}
		:active {
			cursor: grabbing;
		}
	}

	::-ms-track {
		width: 100%;
		height: 1px;
		cursor: pointer;
		animate: 0.2s;
		background: transparent;
		border-color: transparent;
		border-width: 16px 0;
		color: transparent;
	}

	::-ms-fill-lower {
		background: #c4c4c4;
		border: 0;
		border-radius: 2.6px;
		box-shadow: none;
	}

	::-ms-fill-upper {
		background: #c4c4c4;
		border: 0;
		border-radius: 2.6px;
		box-shadow: none;
	}

	::-ms-thumb {
		box-shadow: none;
		border: 0;
		height: 36px;
		width: 16px;
		border-radius: 3px;
		background: var(--bg-dark-color);
		cursor: grab;
		:active {
			cursor: grabbing;
		}
	}

	:focus::-ms-fill-lower {
		background: transparent;
	}

	:focus::-ms-fill-upper {
		background: transparent;
	}
`;
