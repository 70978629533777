import styled from 'styled-components/macro';

export const ScContainer = styled.div`
	position: relative;
	margin: 0 16px;
	width: 100%;
	overflow-y: auto;
`;

export const ScInnerContainer = styled.section`
	background-color: var(--bg-bright-color);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: calc(100% - 32px);
	margin: 16px 0;
	padding: 40px;
`;

export const ScHeader = styled.h1`
	font-size: 24px;
	padding-bottom: 40px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-weight: 600;
`;

export const ScContent = styled.div`
	height: 100%;
	width: 100%;
`;
