import React from 'react';
import styled from 'styled-components/macro';

const SmallList = (props) => {
	const children = React.Children.toArray(props.children);
	const items = children.map((child, index) => <ScListRow key={`listRow_${index}`}>{child}</ScListRow>);

	return (
		<ScWrapper>
			<ScContainer>{items}</ScContainer>
		</ScWrapper>
	);
};

export default SmallList;

const ScWrapper = styled.div`
	width: 100%;
	overflow: auto;
	border: 1px solid var(--border-grey-color);
`;

const ScContainer = styled.div`
	text-align: left;
	font-size: 14px;
	display: table;
	width: 100%;
`;

const ScListRow = styled.div`
	padding: 8px 16px;
	white-space: nowrap;
	:nth-child(odd) {
		background: #fafafa;
	}
`;
