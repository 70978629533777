import * as actionTypes from './actionTypes';

/**
 * Set the Builder view mode to a variant
 * @param {string} variantName
 */
export const setBuilderViewVariant = (variantName) => {
	return {
		type: actionTypes.CONFIG_BUILDER_SET_VIEW_VARIANT,
		variantName: variantName,
	};
};

/**
 * Toggles whether hidden block rows should be shown but disabled
 * or not rendered at all
 */
export const toggleBuilderVariantRowVisibility = () => {
	return {
		type: actionTypes.CONFIG_BUILDER_TOGGLE_VISIBILITY_VARIANT_ROWS,
	};
};

/**
 * Saves the currently selected folder & expanded folders in the media archive navigation menu.
 * Used to pre-expand and select those when loading media archive first time, to skip opening everything every time.
 *
 * @param {number} selected Id of the selected folder.
 */
export const setMediaArchiveSelectedFolder = (treeData, selectedFolderObject) => {
	return {
		type: actionTypes.CONFIG_MEDIAARCHIVE_SET_SELECTED_FOLDER,
		selectedFolderObject: selectedFolderObject,
		treeData: treeData
	};
};
