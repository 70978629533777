import React, { useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import axios from '../../utils/oc-axios';
import { Icon } from '../UI';

const UploadDropzone = (props) => {
	const [status, setStatus] = React.useState({
		progress: 0,
		isUploading: false,
		isComplete: false
	});

	const dropzoneRef = useRef();
	const fileInputRef = useRef();

	/**
	 * Files to be uploaded
	 *
	 * @param {*} files
	 */
	const uploaderDoUpload = React.useCallback((files) => {
		return new Promise((resolve, reject) => {
			const API_ENDPOINT = '/media/upload';
			const formData = new FormData();

			for(const file of files) {
				formData.append('files[]', file);
			}

			formData.append('target_folder', props.uploadTo);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (progressEvent) => {
					const progressInPerecentage = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					const updatedState = {
						...status,
						progress: progressInPerecentage,
						isUploading: true,
						isComplete: false,
						isError: false
					};

					setStatus(updatedState);
				}
			};

			axios.post(API_ENDPOINT, formData, config)
				 .then((res) => {
				 	resolve(res);
				 })
				 .catch(function (err) {
				 	reject(err);
				 });
		});
	}, [props.uploadTo, status]);

	/**
	 * Triggered when files are fed to the uploader
	 *
	 * @param {*} ev
	 */
	const onFilesSelected = React.useCallback((fileList) => {
		if(props.disabled) return;

		const files = fileListToArray(fileList);

		if(files.length > 15) {
			const updatedState = {
				...status,
				progress: 0,
				isUploading: false,
				isComplete: false,
				isError: true,
				message:
						'Det går inte att ladda upp mer än 15 filer åt gången'
			};

			setStatus(updatedState);
			reset(props.resetTime);

			return;
		}

		// wait for all promises/file uploads to complete
		uploaderDoUpload(files)
			.then((resp) => {
			// check if whe got any denied files.
				if(resp.data.denied_files.length <= 0) {
					const updatedState = {
						...status,
						progress: 0,
						isUploading: false,
						isComplete: true,
						isError: false,
						message: ''
					};

					setStatus(updatedState);
					reset(props.resetTime);

					if(props.onComplete)
						props.onComplete(resp.data.approved_files);
				} else {
					const updatedState = {
						...status,
						progress: 0,
						isUploading: false,
						isComplete: true,
						isError: true,
						message:
								'Vissa filer har inte laddats upp, vänligen kontrollera och försök igen.'
					};

					setStatus(updatedState);
					reset(props.resetTime);
				}
			})
			.catch((err) => {
				const updatedState = {
					...status,
					progress: 0,
					isUploading: false,
					isComplete: true,
					isError: true,
					message:
							'Vissa filer har inte laddats upp, vänligen kontrollera och försök igen.'
				};

				setStatus(updatedState);
				reset(props.resetTime);
			});
	}, [props, status, uploaderDoUpload]);

	/**
	 * Handle when files are dropped in the uploadDropzone.
	 *
	 * @param {*} e
	 */
	const dropzoneDropHandler = React.useCallback((e) => {
		preventDefault(e);

		onFilesSelected(e.dataTransfer.files);
	}, [onFilesSelected]);

	/**
	 * Add and remove event listener for the drag & drop functionality
	 */
	useEffect(() => {
		const currentDropZoneRef = dropzoneRef.current;

		currentDropZoneRef.addEventListener('drop', dropzoneDropHandler, false);
		currentDropZoneRef.addEventListener('dragover', preventDefault, false);

		// remove event lsiteners when cleaning up.
		return () => {
			currentDropZoneRef.removeEventListener(
				'drop',
				dropzoneDropHandler,
				false
			);

			currentDropZoneRef.removeEventListener(
				'dragover',
				preventDefault,
				false
			);
		};
	}, [dropzoneDropHandler, props]);

	/**
	 * Prevent defaults, used in registering events so we can remove them.
	 *
	 * @param {*} e
	 */
	const preventDefault = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	/**
	 * Automatically triggered after an upload was completed
	 *
	 * @param {*} time
	 */
	const reset = (time) => {
		setTimeout(() => {
			const updatedState = {
				progress: 0,
				isUploading: false,
				isComplete: false,
				isError: false
			};

			setStatus(updatedState);
			if(fileInputRef.current) fileInputRef.current.value = '';
		}, time);
	};

	/**
	 * Triggers the native browser file dialog
	 */
	const openFileDialog = () => {
		if(props.disabled) return;
		fileInputRef.current.click();
	};

	/**
	 * Loop return array of files from a FileList.
	 *
	 * @param list
	 * @returns {Array}
	 */
	const fileListToArray = (list) => {
		const files = [];
		for(let i = 0; i < list.length; i++) {
			files.push(list.item(i));
		}
		return files;
	};

	let visualFeedback = (
		<>
			<Icon icon={['fal', 'cloud-arrow-up']} />
			<ScText>
				{props.label}
			</ScText>
		</>
	);

	if(status.isUploading) {
		visualFeedback = (
			<>
				<ScIcon icon={['fal', 'spinner']} />
				<ScText>
					Laddar upp filer...
				</ScText>
			</>
		);
	}

	if(status.isComplete) {
		visualFeedback = (
			<>
				<Icon icon={['fal', 'check']} />
				<ScText>
					Din uppladdning är klar!
				</ScText>
			</>
		);
	}

	if(status.isError) {
		visualFeedback = (
			<>
				<Icon icon={['fal', 'xmark']} />
				<ScText>
					{status.message}
				</ScText>
			</>
		);
	}

	return (
		<ScDropzoneWrapper
			ref={dropzoneRef}
			status={status}
			onClick={openFileDialog}
		>
			{visualFeedback}
			<ScInput
				ref={fileInputRef}
				type="file"
				multiple="multiple"
				onChange={(e) => {
					onFilesSelected(e.target.files);
				}}
			/>
		</ScDropzoneWrapper>
	);
};

export default UploadDropzone;

const ScDropzoneWrapper = styled.div`
	align-self: center;
	position: fixed;
	position: absolute;
	bottom: 16px;
	width: calc(100% - 32px);
	max-width: 664px;
	z-index: 999;
	padding: 32px 16px;
	border: 2px dashed var(--border-grey-color);
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.24);
	/* backdrop-filter: blur(16px); */
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background 0.3s ease, border 0.3s ease;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

	background: linear-gradient(
		90deg,
		rgba(153, 153, 153, 0.8) 0%,
		rgba(153, 153, 153, 0.8) ${(props) => props.status.progress}%,
		rgba(238, 238, 238, 0.8) ${(props) => props.status.progress}%,
		rgba(238, 238, 238, 0.8) 100%
	);

	:hover {
		border: 2px dashed #999;
	}

	${(props) =>
		props.status.isComplete &&
		!props.status.isError &&
		css`
			border: none;
			background: rgba(32, 177, 38, 0.48);
			:hover {
				border: none;
			}
		`}

	${(props) =>
		props.status.isComplete &&
		props.status.isError &&
		css`
			border: none;
			background: rgba(255, 0, 0, 0.48);
			:hover {
				border: none;
			}
		`}

	${(props) =>
		props.status.isUploading &&
		css`
			border: none;
			:hover {
				border: none;
			}
		`}
`;

const ScInput = styled.input`
	display: none;
`;

const ScText = styled.div`
	margin-left: 8px;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ScIcon = styled(Icon)`
	animation: ${rotate} 2s linear infinite;
`;
