import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';
import { identifyRelationsUpwards } from '../../components/Tree/Tree';

const initialState = {
	builder: {
		variants: {
			// The currently selected variant used in the builder.
			current: 'desktop',
			showHiddenRows: false,

			// The pixels for CSS media queries on when to change to another depending on screen size
			available: {
				desktop: {
					icon: ['fal', 'desktop'],
					width: '100%',
				},
				// tablet: {
				// 	icon: ['fal', 'tablet'],
				// 	width: '1024px',
				// },
				mobile: {
					icon: ['fal', 'mobile'],
					width: '414px',
				},
			},
		},
	},

	mediaarchive: {
		selectedFolder: {},
		expandedFolders: [],
	},
};

/**
 *
 * @param {object} state
 * @param {object} action
 */
const config = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CONFIG_BUILDER_SET_VIEW_VARIANT:
			return update(state, {
				builder: {
					variants: {
						current: {
							$set: action.variantName,
						},
					},
				},
			});

		case actionTypes.CONFIG_BUILDER_TOGGLE_VISIBILITY_VARIANT_ROWS:
			const updatedSetting = !state.builder.variants.showHiddenRows;

			return update(state, {
				builder: {
					variants: {
						showHiddenRows: {
							$set: updatedSetting,
						},
					},
				},
			});

		case actionTypes.CONFIG_MEDIAARCHIVE_SET_SELECTED_FOLDER:
			return update(state, {
				mediaarchive: {
					selectedFolder: { $set: action.selectedFolderObject },

					// Get all folders/navitems from the selected folder up to the root as those should be expanded.
					expandedFolders: {
						$set: identifyRelationsUpwards(
							action.treeData,
							action.selectedFolderObject.id
						),
					},
				},
			});

		default:
			return state;
	}
};

export default config;
