import { PrivilegeTypes } from '../../definitions/Privileges';

/**
 * If you need to add more flags, just change the names of the ones starting with FLAG_ to what you want.
 * 
 * LEAVE THE NUMBERS/VALUES AS THEY ARE! or it will break!.
 */
export enum CustomerMenuOptions {

	// Byte 1
	NONE = 0, // 0000 0000 0000 0000
	INFOTOOL_FORMS_DOWNLOAD = 1, // 0000 0000 0000 0001
	FLAG_1 = 2, // 0000 0000 0000 0010
	FLAG_2 = 4, // 0000 0000 0000 0100
	FLAG_3 = 8, // 0000 0000 0000 1000
	FLAG_4 = 16, // 0000 0000 0001 0000
	FLAG_5 = 32, // 0000 0000 0010 0000
	FLAG_6 = 64, // 0000 0000 0100 0000
	FLAG_7 = 128, // 0000 0000 1000 0000

	// Byte 2
	FLAG_8 = 256, // 0000 0001 0000 0000
	FLAG_9 = 512, // 0000 0010 0000 0000
	FLAG_10 = 1024, // 0000 0100 0000 0000
	FLAG_11 = 2048, // 0000 1000 0000 0000
	FLAG_12 = 4096, // 0001 0000 0000 0000
	FLAG_13 = 8192, // 0010 0000 0000 0000
	FLAG_14 = 16384, // 0100 0000 0000 0000
	FLAG_15 = 32768 // 1000 0000 0000 0000
}

export interface ControlPanelMenuDefinition {
	name: string,

	/**
	 * Permissions required to view this menu item.
	 *  Validated in NavigationList.js 
	 * 	The user will be required to only have some of the permissions listed here, meaning an OR operation is done on the permissions.
	 */
	permissions: PrivilegeTypes[],
	icon: [string, string],
	link: {
		internal: boolean,
		to: string,
		target: '_blank' | 'self'
	}
}
