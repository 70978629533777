export enum ErrorMessages {
	UNKNOWN = 'Detta fält har validerats felaktigt',
	REQUIRED = 'Detta fält är obligatoriskt',
	MIN = 'Detta fält kräver minst {min} tecken',
	MAX = 'Detta fält får max innehålla {max} tecken',
	EMAIL = 'Den angivna e-post adressen är inte giltig',
	PHONE = 'Det angivna nummret är inte giltig',
	URL = 'Den angivna URLen verkar inte vara giltig',
	NUMERIC = 'Detta fältet får endast innehålla siffror',
	UNCHECKED = 'Du måste kryssa i denna rutan för att fortsätta',
	DATE = 'Du har inte angivit ett korrekt datum',
	SLUG = 'Tillåtna tecken: a-z 0-9 / -',
	SLUGURL = 'Det måste vara en giltig URL eller en "slug" vars tillåtna tecken matchar a-z 0-9 / -',
}

export enum ValidationTypes {
	EMAIL = 'email',
	PHONE = 'phone',
	URL = 'url',
	SLUG = 'slug',
	SLUGURL = 'slugurl',
	NUMERIC = 'numeric',
	DATE = 'date',
	REGEX = 'regex',
	REQUIRED = 'required',
	MIN = 'min',
	MAX = 'max',
}

export interface ValidationByObject {
	type: ValidationTypes;
	min?: number;
	max?: number;
	message?: string;
	pattern?: RegExp;
}

export type ValidationOptions = ValidationByObject | string;

export type SubmitCallback = () => void;
export interface ValidationElementConfig {
	required?: boolean;
	validation?: ValidationOptions;
}

export interface ValidationElement {
	reference: HTMLTextAreaElement | HTMLInputElement;
	config: ValidationElementConfig;
}

export interface ValidationElements {
	[key: string]: ValidationElement;
}

export interface ValidationErrors {
	[key: string]: string[];
}

export type ExternalCallback =
	| null
	| ((
		ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		callbackArgs?: any
	  ) => void);

export interface FormValidation {
	registerElement: (
		ref: HTMLInputElement | HTMLTextAreaElement | null | undefined,
		config: ValidationElementConfig
	) => void;

	unregisterElement: (id: string) => void;

	watch: (
		ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		externalCallback: ExternalCallback | null,
		callbackArgs: any | null
	) => ExternalCallback | void;

	errors: ValidationErrors;

	submit: (submitCallback: SubmitCallback | null) => void;

	verify: (id: string) => ValidationErrors;

	elementsRef: React.MutableRefObject<ValidationElements>;

	formHasErrors: () => boolean;

	reset: () => void;

	resetErrors: () => void;

	resetSpecificError: (id: string) => void;
}
