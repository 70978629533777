import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const TableHead = (props) => {
	return <ScTableHead>
        {props.children}
    </ScTableHead>;
};

TableHead.propTypes = {
    children: PropTypes.any.isRequired
};

export default TableHead;

const ScTableHead = styled.div`
    display: table-header-group;
`;
