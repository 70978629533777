import React from 'react';
import styled, { css } from 'styled-components/macro';

const BlockRowLayout = (props) => {
	const columns = props.cols.map((col, index) => (
		<ScColumn
			key={`row-${props.rowId}_column-${col}_layout-${props.layout}_${index}`}
			width={`${col}%`}
		/>
	));

	return (
		<ScRowLayout
			className={props.className}
			onClick={() => props.changed(props.rowId, props.layout)}
			style={props.style}
			isActive={props.isActive}
		>
			{columns}

			<ScOverlay>{props.cols.join('/')}</ScOverlay>
		</ScRowLayout>
	);
};

export default BlockRowLayout;

const ScOverlay = styled.div`
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #333;
	opacity: 0.64;
	color: #fafafa;
	font-weight: 700;
	font-size: 12px;
	text-align: center;
	justify-content: center;
	align-items: center;
`;
const ScRowLayout = styled.div`
	width:100%;
	/* margin-bottom: 8px; */
	background: #c4c4c4;
	padding: 4px;
	display: flex;
	border-radius: 2px;
	position: relative;
	cursor: pointer;

	margin-right: 8px;

	:last-child {
		/* margin-bottom: 0; */
		margin-right: 0;
	}

	:hover {
		${ScOverlay} {
			display: flex;
		}
	}

	 ${(props) =>
			props.isActive &&
			css`
				background: #333;
				/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
			`}

		
	/* width: ${(props) => (props.isFullWidth ? '100%' : 'auto')}; */

	/* ${(props) =>
		props.hasBorder &&
		css`
			border: 1px solid var(--border-grey-color);
		`} */
`;

const ScColumn = styled.div.attrs(({ width }) => ({
	size: width || '100%',
}))`
	background: #f3f3f3;
	width: ${(props) => props.size};
	height: 24px;
	border-radius: 2px;
	margin-left: 2px;
	:first-child {
		margin-left: 0;
	}
`;
