import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonSettingsProps } from './model.SkeletonSettings';
const SkeletonSettings: React.FC<SkeletonSettingsProps> = (props) => {
	return (
		<ContentLoader
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={'445'}
			viewBox={'0 0 400 445'}
		>
			<rect
				x="16"
				y="69"
				width="96"
				height="18"
			/>
			<rect
				x="16"
				y="91"
				width="368"
				height="40"
			/>
			<rect
				x="16"
				y="153"
				width="96"
				height="18"
			/>
			<rect
				x="16"
				y="175"
				width="368"
				height="40"
			/>
			<rect
				x="16"
				y="237"
				width="96"
				height="18"
			/>
			<rect
				x="16"
				y="259"
				width="368"
				height="40"
			/>
			<rect
				x="16"
				y="331"
				width="368"
				height="48"
			/>
			<rect
				x="44"
				y="8"
				width="48"
				height="18"
			/>
			<rect
				x="176"
				y="8"
				width="48"
				height="18"
			/>
			<rect
				x="308"
				y="8"
				width="48"
				height="18"
			/>
			<rect
				x="16"
				y="404"
				width="24"
				height="24"
			/>
			<rect
				x="48"
				y="403"
				width="143"
				height="14"
			/>
			<rect
				x="48"
				y="421"
				width="176"
				height="8"
			/>
			<rect
				y="34"
				width="400"
				height="1"
			/>
		</ContentLoader>
	);
};

export default SkeletonSettings;
