import React from 'react';
import { VerifiedPermission, VerifiedPermissions } from './types';
import { AuthContext } from '../../auth/context/AuthContext';
import { Privileges } from '../../definitions/Privileges';

const useAuth = () => {
	const {
		authUser,
		signIn,
		signOut,
		setPass,
		lostCredentials
	} = React.useContext(AuthContext);

	/**
	 * Generic helper function that will return a boolean for a specific user permission
	 *
	 * @param {keyof Privilieges} permission
	 * @returns {boolean}
	 */
	const verifyUserPermission = React.useCallback(
		(permission: keyof Privileges): boolean => {
			if(!authUser) return false;

			return authUser.privileges[permission] === true;
		},
		[authUser]
	);

	/**
	 * Allows you to veryify multiple user permissions at once
	 *
	 * @param {(keyof Privileges)[]} permissions
	 * @returns {VerifiedPermissions  {VerifiedPermissions}
	 */
	const verifyUserPermissions = React.useCallback(
		(permissions: (keyof Privileges)[]): VerifiedPermissions => {
			const result = permissions.reduce(
				(prev: VerifiedPermission, current: keyof Privileges) => {
					if(!(current in prev)) {
						prev[current] = verifyUserPermission(current);
					}

					return prev;
				},
				{}
			);

			return {
				some: permissions.some((permission) =>
					verifyUserPermission(permission)),
				every: permissions.every((permission) =>
					verifyUserPermission(permission)),
				...result
			};
		},
		[verifyUserPermission]
	);

	/**
	 * Check if the user belong to a group using the group's key.
	 */
	const isMemberOfGroup = React.useCallback(
		(groupKey: string): boolean =>
			!!authUser?.groups.find((g) => g.key === groupKey),
		[authUser]
	);

	return {
		authUser,
		verifyUserPermission,
		verifyUserPermissions,
		isMemberOfGroup,
		signIn,
		signOut,
		setPass,
		lostCredentials
	};
};

export default useAuth;
