import React from 'react';
import * as ReactTable from 'react-table';
import { CUSTOMERS_LIST_COLUMN_FIRSTNAME } from './consts.Customers';
import { Icon } from '../../../../components/UI';
import { Customer } from '../../../../store/types/CustomerTypes';

/**
 * Generates a TableCell with custom content.
 *
 * @param cell 			The cell data to work on.
 * @returns {TableCell}
 */
export const renderTableCellContent = (
	cell: ReactTable.Cell<Customer>,
	row: ReactTable.Row<any>
) => {
	// Only work on data if there is any,
	//	otherwise return with the default cellContent of empty string.
	if(cell.value === undefined || cell.value === null) return null;

	//Add an icon before the customer's name based on whether the customer is private or a company
	if(cell.column.id === CUSTOMERS_LIST_COLUMN_FIRSTNAME) {
		const icon = row.original.company
			? ['far', 'building']
			: ['far', 'user'];
		return (
			<>
				<Icon
					style={{ marginRight: 8 }}
					icon={icon}
				/>
				{' '}
				{row.original.company
					? row.original.company
					: `${cell.value} ${row.original.lastname}`}
			</>
		);
	}
	// id is the same as 'accessor' when defining table columns
	// if (cell.column.id === CUSTOMERS_LIST_COLUMN_TYPE) {
	// 	// this goes after the prop company

	// 	const icon = cell.value ? ['far', 'building'] : ['fal', 'trash'];
	// 	return <Icon icon={icon} />;
	else {
		return cell.render('Cell');
	}
};
