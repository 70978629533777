import update from 'immutability-helper';
import { default as ObjectHash } from 'object-hash';
import * as actionTypes from '../actions/actionTypes';
import { getBlockRowId } from '../../containers/Builder/helpers/BuilderUtils';
import {
	dndCreateRowSetToAreaHelper,
	dndCreateColumnSetToRowHelper,
	dndMoveBlockWithinAnyColumnHelper,
	dndMoveRowVerticallyHelper
} from '../../containers/Builder/helpers/BlockStateDndHelper';
import {
	createRowSetToAreaHelper,
	createRowSetToEmptyAreaHelper,
	createBlockInColumnHelper,
	deleteSpecificBlockHelper,
	toggleBlockRowVisibility
} from '../../containers/Builder/helpers/BlockStateHelper';

const initialState = {};
let updatedState;
let rowId;

/**
 *
 * @param {object} state
 * @param {object} action
 */
const builder = (state = initialState, action) => {
	switch(action.type) {
		// sets the builder initial state after having retrieved contents
		case actionTypes.SET_BUILDER_INITIAL_STATE:
			return update(state, {
				blockTypes: {
					$set: action.blockTypes
				},
				formStructure: {
					$set: action.formStructure
				},
				$merge: {
					...action.pageData
				},
				saveHash: { $set: ObjectHash(action.pageData.elements) }
			});

		// updates the savehash whith content of the state, like when saving the page, update it to mirror the current saved content.
		case actionTypes.BUILDER_UPDATE_SAVEHASH:
			return update(state, {
				saveHash: { $set: ObjectHash(state.elements) }
			});

		// clears the builder state when closing the builder
		case actionTypes.BUILDER_CLEAN_STATE:
			return {};

		// updates the builder settings
		// mainly used to update publish dates
		case actionTypes.SET_PAGE_SETTINGS:
			return update(state, {
				settings: {
					$merge: {
						...action.settings
					}
				}
			});

		// updates a block's content for a specific field
		case actionTypes.UPDATE_BLOCK_CONTENT:
			const blockKey = action.block.key;
			
			rowId = getBlockRowId(state.elements, blockKey);

			return update(state, {
				elements: {
					blocks: {
						[blockKey]: {
							data: {
								// Changed from $set to $merge
								$merge: action.block.data
							},
							updated: { $set: Date.now() }
						}
					},
					rows: {
						[rowId]: {
							$merge: {
								updated: Date.now()
							}
						}
					}
				}
			});

		// updates text content
		case actionTypes.UPDATE_TEXT_CONTENT:
			rowId = getBlockRowId(state.elements, action.blockId);
			return update(state, {
				elements: {
					blocks: {
						[action.blockId]: {
							data: {
								[action.valueProp]: {
									$set: action.content
								},
								$merge: {
									updated: Date.now()
								}
							}
						}
					},
					rows: {
						[rowId]: {
							$merge: {
								updated: Date.now()
							}
						}
					}
				}
			});

		// sets visibilty setting for a block row
		case actionTypes.SET_ROW_VISIBILITY_SETTINGS:
			return toggleBlockRowVisibility(
				state,
				action.rowId,
				action.variantType
			);

		// sets width setting for a block row
		case actionTypes.SET_ROW_WIDTH_SETTINGS:
			return update(state, {
				elements: {
					rows: {
						[action.rowId]: {
							settings: {
								has_max_width: {
									$set: action.hasMaxWidth
								}
							},
							$merge: {
								updated: Date.now()
							}
						}
					}
				}
			});

		// sets reverse setting for a block row
		case actionTypes.SET_ROW_REVERSE_SETTINGS:
			return update(state, {
				elements: {
					rows: {
						[action.rowId]: {
							settings: {
								reverse_in_mobile: {
									$set: action.isReversed
								}
							},
							$merge: {
								updated: Date.now()
							}
						}
					}
				}
			});

		// changes column width settings of a block row
		case actionTypes.CHANGE_COLUMN_WIDTH:
			return update(state, {
				elements: {
					rows: {
						[action.rowId]: {
							settings: {
								$merge: {
									column_division: action.column_division
								}
							},
							$merge: {
								updated: Date.now()
							}
						}
					}
				}
			});

		// adds a new row set to a non-empty area
		case actionTypes.CREATE_ROW_SET_TO_AREA:
			return createRowSetToAreaHelper(
				state,
				action.areaId,
				action.index,
				action.blockType
			);

		// adds a new row set to an empty area
		case actionTypes.CREATE_ROW_SET_TO_EMPTY_AREA:
			return createRowSetToEmptyAreaHelper(
				state,
				action.areaId,
				action.blockType
			);

		// adds a new block to an existing columb
		case actionTypes.CREATE_BLOCK_IN_COLUMN:
			return createBlockInColumnHelper(
				state,
				action.rowId,
				action.columnId,
				action.index,
				action.blockType
			);

		// deletes a block
		case actionTypes.DELETE_BLOCK:
			return deleteSpecificBlockHelper(
				state,
				action.areaId,
				action.rowId,
				action.columnId,
				action.blockId,
				action.index
			);

		// creates a row set to an area by dnd
		case actionTypes.DND_CREATE_ROW_SET_TO_AREA:
			return dndCreateRowSetToAreaHelper(state, ...action.args);

		// creates a new column to a row set by dnd
		case actionTypes.DND_CREATE_COLUMN_SET_TO_ROW:
			return dndCreateColumnSetToRowHelper(state, ...action.args);

		// moves a block within any column by dnd
		case actionTypes.DND_MOVE_BLOCK_WITHIN_ANY_COLUMN:
			updatedState = dndMoveBlockWithinAnyColumnHelper(
				state,
				...action.args
			);
			return updatedState ? updatedState : state;

		// moves a block row vertically by dnd
		case actionTypes.DND_MOVE_ROW_VERTICALLY:
			updatedState = dndMoveRowVerticallyHelper(state, ...action.args);
			return updatedState ? updatedState : state;

		default:
			return state;
	}
};

export default builder;
