/**
 * **********************************************
 * This file exists to handle block specific logic for drag and drop
 * In order to keep those advanced tasks as simple as possible
 * I decided to outsource each operation it's own function
 * That way it will be easier, and safer, to tweak or make changes to this logic when necessary
 *
 * Using immutability-helper to factor the new state
 * https://github.com/kolodny/immutability-helper
 * **********************************************
 */

import update from 'immutability-helper';
import { createRandomId } from 'react-utils';
import {
	getIndexOfRowColumn,
	getIndexOfBlockInColumn,
	getIndexOfAreaRow,
	calcRowScopeAfterChange,
} from './BuilderUtils';

/**
 * Triggered when drag and drop have instructed to "create" a new "row"
 * This happens when dragging a block and position it between two row sets
 * As a consequence of this several things may happen:
 * - If the block is the last in it's source column, remove the column
 * - If the column is removed and the source row has no columns left, remove the source row
 *
 * A new row and column are always created at destination
 *
 * @param state
 * @param source
 * @param destination
 * @param instruction
 * @returns {{elements: {columns: {$merge: {}, $unset: *[]}, rows: {$merge: {}, $unset: *[]}}}}
 */
export const dndCreateRowSetToAreaHelper = (
	state,
	source,
	destination,
	instruction
) => {
	// creates a new row id for internal reference
	const rowId = createRandomId('row');

	// creates a new column id for internal reference
	const columnId = createRandomId('col');

	// will forsee the future of the source row's scope
	const status = calcRowScopeAfterChange(
		state,
		source.areaId,
		source.rowId,
		source.columnId
	);

	return update(state, {
		elements: {
			columns: {
				// create a new column
				// and assign the moved block to it
				$merge: {
					[columnId]: {
						key: columnId,
						blocks: [source.blockId],
						updated: Date.now(),
					},
				},
				// remove block from source column
				[source.columnId]: {
					blocks: {
						$splice: [[source.index, 1]],
					},
					$merge: {
						updated: Date.now(),
					},
				},
				// if a column no longer contains blocks, then get rid of the column
				$unset: [status.column.keep ? null : source.columnId],
			},
			rows: {
				// add a new row
				// assign a new column to it
				$merge: {
					[rowId]: {
						key: rowId,
						columns: [columnId],
						updated: Date.now(),
						settings: {
							column_division: null,
							visible_mobile: true,
							visible_desktop: true,
						},
					},
				},
				// in case a column is now empty then remove if the source rows column reference
				[source.rowId]: {
					columns: {
						$splice: status.column.keep
							? [[0, 0]]
							: [[status.column.index, 1]],
					},
					$merge: {
						updated: Date.now(),
					},
					settings: {
						$merge: {
							column_division: null,
						},
					},
				},
				// if a row no longer contains columns, then get rid of it
				$unset: [status.row.keep ? null : source.rowId],
			},
			areas: {
				// create a new row refernece to the destionation area
				[destination.areaId]: {
					rows: {
						$splice: [
							// remove the row reference from area if moving out the last block from it
							status.row.keep ? [0, 0] : [status.row.index, 1],
							// add a new row reference to the area
							[
								destination.index + instruction.adjustIndex,
								0,
								rowId,
							],
						],
					},
					$merge: {
						updated: Date.now(),
					},
				},
			},
		},
	});
};

/**
 * Triggered when drag and drop operation is instructed to "create" a new "column"
 * This happens when dropped on a drop zone to the left or to the right of a valid target, then a new column is created
 * As a consequence of such operation the following may happen
 *
 * - if it's the last block in a column that is being dragged, the source column will be removed
 * - if the source column is erased and is the last column of the source row scope, the source row will be removed
 * - if the source row is removed, it's reference form the parent area rows array will also be removed
 *
 * The dragged item (the block) is always removed from it's source column
 *
 * @param state
 * @param source
 * @param destination
 * @param instruction
 * @returns {* | boolean}
 */
export const dndCreateColumnSetToRowHelper = (
	state,
	source,
	destination,
	instruction
) => {
	// get the original index of the source columns parent within row
	const positionInParentSource = getIndexOfRowColumn(
		state,
		source.rowId,
		source.columnId
	);

	// get the original index of where to place the new column in the destination row
	const positionInParentDestination = getIndexOfRowColumn(
		state,
		destination.rowId,
		destination.columnId
	);

	// to avoid unnecessary re-rendering we will do a check that will predictthe new position of a move
	// if the end position calculates to be exactly the same as the current position the operation will be cancelled
	if (
		source.rowId === destination.rowId &&
		source.columnId === destination.columnId &&
		((positionInParentDestination - 1 === positionInParentSource &&
			instruction.position === 'left') ||
			(positionInParentSource - 1 === positionInParentDestination &&
				instruction.position === 'right'))
	) {
		return false;
	}

	// creates a new column id for internal reference
	const columnId = createRandomId('col');

	// will forsee the future of the source columns's scope
	const status = calcRowScopeAfterChange(
		state,
		source.areaId,
		source.rowId,
		source.columnId
	);

	// as a new column is created we might need to tweak the index in the row's columns array depending
	// on if the item was dropped at left side or right side of the block
	let tweakedIndex = positionInParentDestination + instruction.adjustIndex;

	return update(state, {
		elements: {
			columns: {
				// create a new column and fill it's blocks array with the dragged block
				$merge: {
					[columnId]: {
						key: columnId,
						blocks: [source.blockId],
						$merge: {
							updated: Date.now(),
						},
					},
				},
				// remove the block that is dragged from it's columns blocks array ([source.index, 1])
				[source.columnId]: {
					blocks: {
						$splice: [[source.index, 1]],
					},
					$merge: {
						updated: Date.now(),
					},
				},
				// if column no longer is a parent to any blocks, remove that column from the state
				$unset: [status.column.keep ? null : source.columnId],
			},
			rows: {
				// this code runs only if the source row and destination row are different
				// if they are the same the javascript will process the code in the [destination.row] key reference further below
				// however if predicted to be empty after operation the column in the source row is removed [status.column.index, 1]
				// if the column is predicted to not be empty, we will simply keep the reference of it untouched [0, 0]
				[source.rowId]: {
					columns: {
						$splice: [
							status.column.keep
								? [0, 0]
								: [status.column.index, 1],
						],
					},
					$merge: {
						updated: Date.now(),
					},
					settings: {
						$merge: {
							column_division: null,
						},
					},
				},
				[destination.rowId]: {
					columns: {
						$splice: [
							// if destination and source are within the same row then remove the old column [status.column.index, 1]
							// else let it be [0, 0]
							destination.rowId === source.rowId &&
							!status.column.keep
								? [status.column.index, 1]
								: [0, 0],
							// add the new columnId to the destination rows columns array at the correct position
							[tweakedIndex, 0, columnId],
						],
					},
					$merge: {
						updated: Date.now(),
					},
					settings: {
						$merge: {
							column_division: null,
						},
					},
				},
				// if the row no longer parent any columns, remove that row from the state
				$unset: [status.row.keep ? null : source.rowId],
			},
			areas: {
				[source.areaId]: {
					rows: {
						$splice: [
							// if a row is predicted to to be kept (it still has columns after operation) then keep it [0, 0]
							// else remove the row from the areas rows array [status.row.index, 1]
							status.row.keep ? [0, 0] : [status.row.index, 1],
						],
					},
					$merge: {
						updated: Date.now(),
					},
				},
			},
		},
	});
};

/**
 * Triggered when drag and drop operation is instructed to "move" to "any"
 * In the editor this only blocks will be moved this way, as block rows are set to move vertically
 * As a consequence of such operation the following may happen
 *
 * - if it's the last block in a column that is being dragged, the source column will be removed
 * - if the source column is erased and is the last column of the source row scope, the source row will be removed
 * - if the source row is removed, it's reference form the parent area rows array will also be removed
 *
 * The dragged item (the block) is always removed from it's source column
 *
 * @param state
 * @param source
 * @param destination
 * @param instruction
 * @returns {*}
 */
export const dndMoveBlockWithinAnyColumnHelper = (
	state,
	source,
	destination,
	instruction
) => {
	// get the original index of the source block within it's parent column
	const positionInParentSource = getIndexOfBlockInColumn(
		state,
		source.columnId,
		source.blockId
	);

	// get the original index of the destination block within it's parent column
	const positionInParentDestination = getIndexOfBlockInColumn(
		state,
		destination.columnId,
		destination.blockId
	);

	// to avoid unnecessary re-rendering we will do a check that will predictthe new position of a move
	// if the end position calculates to be exactly the same as the current position the operation will be cancelled
	if (
		source.columnId === destination.columnId &&
		((positionInParentDestination - 1 === positionInParentSource &&
			instruction.position === 'top') ||
			(positionInParentSource - 1 === positionInParentDestination &&
				instruction.position === 'bottom'))
	) {
		return false;
	}

	// will forsee the future of the source row's scope
	const status = calcRowScopeAfterChange(
		state,
		source.areaId,
		source.rowId,
		source.columnId
	);

	const tweakedIndex = destination.index + instruction.adjustIndex;

	return update(state, {
		elements: {
			blocks: {
				[source.blockId]: {
					$merge: {
						updated: Date.now(),
					},
				},
			},
			columns: {
				// remove the block from it's source column
				[source.columnId]: {
					blocks: {
						$splice: [[source.index, 1]],
					},
					$merge: {
						updated: Date.now(),
					},
				},
				[destination.columnId]: {
					blocks: {
						$splice: [
							// in case we are swapping position within the same column, remove the source index
							// otherwise do nothing as this was handled earlier in ([source.column]) operation
							source.columnId === destination.columnId
								? [source.index, 1]
								: [0, 0],
							// add the dragged item to the target column on the right place
							[tweakedIndex, 0, source.blockId],
						],
					},
					$merge: {
						updated: Date.now(),
					},
				},
				// if the source column is empty after a move, make sure it's removed
				$unset: [status.column.keep ? null : source.columnId],
			},
			rows: {
				[destination.rowId]: {
					$merge: {
						updated: Date.now(),
					},
				},
				[source.rowId]: {
					columns: {
						$splice: [
							// if the source column should be kept then nothing happens to it's reference [0, 0]
							// if the source column should be removed [status.column.index, 1]
							status.column.keep
								? [0, 0]
								: [status.column.index, 1],
						],
					},
					$merge: {
						updated: Date.now(),
					},
				},

				// if the source row is empty after a move, make sure it's removed
				$unset: [status.row.keep ? null : source.rowId],
			},
			areas: {
				[source.areaId]: {
					rows: {
						$splice: [
							// if the source row should be kept then nothing happens to it's reference [0, 0]
							// if the source row should be removed [status.row.index, 1]
							status.row.keep ? [0, 0] : [status.row.index, 1],
						],
					},
					$merge: {
						updated: Date.now(),
					},
				},
			},
		},
	});
};

export const dndMoveRowVerticallyHelper = (
	state,
	source,
	destination,
	instruction
) => {
	// get the original index of the source block within it's parent column
	const positionInParentSource = getIndexOfAreaRow(
		state,
		source.areaId,
		source.rowId
	);

	// get the original index of the destination block within it's parent column
	const positionInParentDestination = getIndexOfAreaRow(
		state,
		destination.areaId,
		destination.rowId
	);

	// to avoid unnecessary re-rendering we will do a check that will predictthe new position of a move
	// if the end position calculates to be exactly the same as the current position the operation will be cancelled
	if (
		source.areaId === destination.areaId &&
		((positionInParentDestination - 1 === positionInParentSource &&
			instruction.position === 'top') ||
			(positionInParentSource - 1 === positionInParentDestination &&
				instruction.position === 'bottom'))
	) {
		return false;
	}

	let tweakedIndex = destination.index;

	if (source.areaId === destination.areaId) {
		switch (true) {
			case instruction.position === 'top' &&
				destination.index > source.index:
				tweakedIndex = destination.index - 1;
				break;

			case instruction.position === 'bottom' &&
				destination.index < source.index:
				tweakedIndex = destination.index + instruction.adjustIndex;
				break;

			default:
		}
	}

	return update(state, {
		elements: {
			areas: {
				// remove the row from it's source
				// if the source area and destination area are the same javascript will ignore this code scope
				// and proceed to the next
				[source.areaId]: {
					rows: {
						$splice: [[source.index, 1]],
					},
				},
				[destination.areaId]: {
					rows: {
						$splice: [
							// remove the row from it's current position if area is the same
							// otherwise the removal is already handled above [source.area]
							source.areaId === destination.areaId
								? [source.index, 1]
								: [0, 0],
							// add it within it's new position
							[tweakedIndex, 0, source.rowId],
						],
					},
				},
			},
		},
	});
};
