import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Icon } from './UI';

const ScreenVariants = (props) => {
	/* The variants to render, format for the object
    {
        variantName1:{
            icon: ['fal', 'trash']
        },
        variantName2: {
            icon: ['fal', 'trash']
        }
    }
    */

	// the current selected variant, is no one given, use the first variant available.
	// is the name of the variant, example variantName1
	const currentVariant = props.currentVariant;

	const variants = props.variants;

	//const variantProps = props.variantProps;

	// triggered when another variant is selected, only param is the name of the variant.
	const changed = props.changed;

	const variantNames = Object.keys(variants);

	const previewModeOptions = useMemo(
		() =>
			variantNames.map((variantName, index) => {
				// the variant is active/selected right now
				const isActive = currentVariant === variantName;
				const variantObj = variants[variantName];

				return (
					<React.Fragment key={`variantBtn_${variantName}`}>
						{variantNames.length > 1 && (
							<ScImageType
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();

									// only trigger change if the variant is not the same as current one.
									if (variantName !== currentVariant)
										changed(variantName);
								}}
							>
								<ScVariantBg isActive={isActive}>
									<ScVariantTitle isActive={isActive}>
										<Icon icon={variantObj.icon} />
									</ScVariantTitle>
								</ScVariantBg>
							</ScImageType>
						)}
					</React.Fragment>
				);
			}),
		[changed, currentVariant, variantNames, variants]
	);

	return (
		<>
			{props.children}
			{variantNames.length > 1 && (
				<ScChangeImageVariant>
					{previewModeOptions}
				</ScChangeImageVariant>
			)}
		</>
	);
};

ScreenVariants.propTypes = {
	currentVariant: PropTypes.string.isRequired,
	variants: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,

	changed: PropTypes.func.isRequired,
};

export default ScreenVariants;

const ScChangeImageVariant = styled.div`
	background-color: var(--bg-grey-color);
	border-left: 1px solid var(--bg-dark-grey-color);
	border-right: 1px solid var(--bg-dark-grey-color);
	border-bottom: 1px solid var(--bg-dark-grey-color);
	margin: 0 0 16px;
	display: flex;
`;

const ScImageType = styled.label`
	display: flex;
	flex: 1;
	cursor: pointer;
`;

const ScImageTypeBg = styled.div`
	padding: 24px;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: ${(props) => (props.isActive ? '#333' : 'transparent')};

	svg path {
		fill: ${(props) => (props.isActive ? '#f2f2f2' : '#333')};
	}
`;

const ScImageTypeTitle = styled.div`
	margin-top: 16px;
	font-weight: 300;
	font-size: 14px;
	color: ${(props) => (props.isActive ? '#fafafa' : '#333')};
`;

const ScVariantBg = styled(ScImageTypeBg)`
	padding: 8px;
`;

const ScVariantTitle = styled(ScImageTypeTitle)`
	margin-top: 0;
`;
