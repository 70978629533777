import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonGridProps } from './model.SkeletonGrid';

const SkeletonGrid: React.FC<SkeletonGridProps> = (props) => {
	return (
		<ContentLoader
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={'174'}
			viewBox={'0 0 592 201'}
		>
			<rect
				x="0"
				y="40"
				width="136"
				height="125"
				rx="2"
			/>
			<rect
				x="36"
				y="173"
				width="64"
				height="12"
			/>
			<rect
				x="24"
				y="193"
				width="88"
				height="8"
			/>
			<rect
				x="152"
				y="40"
				width="136"
				height="125"
				rx="2"
			/>
			<rect
				x="188"
				y="173"
				width="64"
				height="12"
			/>
			<rect
				x="176"
				y="193"
				width="88"
				height="8"
			/>
			<rect
				x="304"
				y="40"
				width="136"
				height="125"
				rx="2"
			/>
			<rect
				x="340"
				y="173"
				width="64"
				height="12"
			/>
			<rect
				x="328"
				y="193"
				width="88"
				height="8"
			/>
			<rect
				x="456"
				y="40"
				width="136"
				height="125"
				rx="2"
			/>
			<rect
				x="492"
				y="173"
				width="64"
				height="12"
			/>
			<rect
				x="480"
				y="193"
				width="88"
				height="8"
			/>
		</ContentLoader>
	);
};

export default SkeletonGrid;
