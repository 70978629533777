import _ from 'lodash';

/**
 * A custom function for sorting by a string value
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *
 * @param {*} a
 * @param {*} b
 */
const sortByString = (a, b, direction) => {
	switch (true) {
		case a < b:
			return direction === 'asc' ? -1 : 1;

		case a > b:
			return direction === 'asc' ? 1 : -1;

		default:
			return 0;
	}
};
/**
 * A custom function for sorting by a boolean value.
 *
 * @param {*} a
 * @param {*} b
 * @param {*} direction
 */
const sortByBoolean = (a, b, direction) => {
	switch (true) {
		case direction === 'asc':
			return a === b ? 1 : a ? 1 : -1;

		default:
			return b === a ? 0 : b ? 1 : -1;
	}
};

/**
 * A custom function for sorting by a numeric value
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *
 * @param {*} a
 * @param {*} b
 */
const sortByNumber = (a, b, direction) => {
	switch (true) {
		case direction === 'asc':
			return a - b;

		default:
			return b - a;
	}
};

/**
 * Helper function that will determine what kind of sort operation will be used
 * Will determine the type of the value of the given prop to run an appropriate sort method
 *
 * @param {*} a
 * @param {*} b
 */
const sortHelper = (items, criteria, direction) => {
	return (a, b) => {
		const criteriaValueType = typeof _.get(items[0], criteria);
		switch (criteriaValueType) {
			case 'string':
				return sortByString(
					_.get(a, criteria),
					_.get(b, criteria),
					direction
				);

			case 'number':
				return sortByNumber(
					_.get(a, criteria),
					_.get(b, criteria),
					direction
				);

			case 'boolean':
				return sortByBoolean(
					_.get(a, criteria),
					_.get(b, criteria),
					direction
				);

			default:
				throw new Error(
					`Sorting for type '${criteriaValueType}' not supported`
				);
		}
	};
};

export { sortByString, sortByNumber, sortHelper };
