import React from 'react';
import styled from 'styled-components/macro';
import { DateTime } from 'luxon';
import {
	SettingDetailsProps,
	ScSettingDetailsProps
} from './model.SettingDetails';
import { SettingsPrivileges } from '../../../../definitions/Privileges';
import { SettingInputTypes } from '../../../../store/types/ControlPanel/SettingsTypes';
import useAuth from '../../../../hooks/useAuth/useAuth';
import { CheckItem, Textarea, TextInput } from '../../../../components/Forms';
import Label from '../../../../components/Forms/Label/Label';
import {
	Icon,
	ImageThumbnail,
	ModalContainer
} from '../../../../components/UI';
import useModal from '../../../../hooks/Modal/useModal';
import MediaArchive from '../../../MediaArchive/MediaArchive';
import DateTimePicker from '../../../../components/UI/DateTimePicker/DateTimePicker';
import { ValidationTypes } from '../../../../hooks/useFormValidation/types';
import { CloseModal } from '../../../../hooks/useModal/useModal.model';
import { MediaFile, NewMediaFile } from '../../../../definitions/Media';

const SettingDetails: React.FC<SettingDetailsProps> = (props) => {
	const { verifyUserPermission } = useAuth();

	const updateState = props.modal?.updateState;
	const setting = props.modal?.currentState;

	const formValidation = props.formValidation;

	const mediaArchiveModal = useModal();

	/**
	 * If the iser have the Adavanced privilege.
	 */
	const userIsAdvanced = React.useMemo(
		() => verifyUserPermission(SettingsPrivileges.OPTIONS_ADVANCED),
		[verifyUserPermission]
	);

	/**
	 * The setting name/display_name for use as label.
	 */
	const label = React.useMemo(
		() => (userIsAdvanced ? setting?.name : setting?.display_name),
		[setting, userIsAdvanced]
	);

	/**
	 * Updates the current value for the Setting.
	 */
	const updateValue = React.useCallback((newValue: string|boolean|NewMediaFile|null) => {
		if(updateState)
			updateState({
				value: { $set: newValue },
				$merge: {
					wasChanged: true
				}
			});
	}, [updateState]);

	/**
	 * update the current value fot EasyAccess
	 */
	const updateEasyAccess = React.useCallback((newValue: boolean) => {
		if(updateState)
			updateState({
				general: { $set: newValue },
				$merge: {
					wasChanged: true
				}
			});
	}, [updateState]);

	/**
	 *
	 * Media
	 *
	 */
	/**
	 * Actions used for ImageThumbnail, used for Media type Settings.
	 */
	const mediaActions = React.useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				action: () => updateValue(null)
			}
		],
		[updateValue]
	);

	/**
	 * Open the MediaArchive.
	 */
	const openMediaArchive = React.useCallback(() => {
		mediaArchiveModal.open({
			position: 'center',
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (
						_oState: null,
						_cState: null,
						closeModal: CloseModal
					) => closeModal()
				}
			]
		});
	}, [mediaArchiveModal]);

	/**
	 * Media Archive returns an array of objects
	 * Uses the first object (as ImageBlock only allows one image)
	 * And updates the state and closes the modal
	 *
	 * @param {array} files
	 */
	const mediaArchiveFileSelectedHandler = React.useCallback((files: any) => {
		const file = files[0] as MediaFile;

		// translate the old MediaFile struct from MediaArchive to NewMediaFile.
		const newStruct: NewMediaFile = {
			// The unique identifier of the file.
			uuid: file.uuid,

			// URL to the file.
			src: file.src,

			// URL to the file's thumbnail image.
			thumbnail: file.thumb,

			// The file's extension
			extension: file.extension,

			// File's tecnical data.
			props: {
				// The MIME of the file
				// https://www.iana.org/assignments/media-types/media-types.xhtml
				mime: file.mime,

				// Dimensions in pixels of the file if it is an image.
				height: file.height || 0,
				width: file.width || 0,

				// Size in bytes of the original file.
				size: file.size,

				name: file.original_name,

				// Title of the file.
				title: file.title,

				// Alternative text for the file, used in 'img' HTML elements > alt property.
				alt_text: file.alt_text
			},

			settings: {
				// If the file is cropped, this contains the coordinates in the image to crop it.
				crop_x: file.crop_x,
				crop_y: file.crop_y,

				// A short description of the file.
				description: file.description,

				// The UUIDv4 of the containing folder.
				//  can be null if the data is not needed, otherwise it should have a value.
				folder_uuid: file.folder_uuid,

				// DateTime when the file was uploaded.
				created: file.created,

				// Datetime when the file was last updated.
				updated: file.updated,

				// In how many places the file as been used/referenced on.
				reference_count: file.reference_count
			}
		};

		// update state
		updateValue(newStruct);

		mediaArchiveModal.close();
	}, [mediaArchiveModal, updateValue]);

	/**
	 * Renders the correct element depending of the settings data type.
	 */
	const settingComponent = React.useMemo(() => {
		const validation = setting?.validation?.toLowerCase() as ValidationTypes;
		let formValidationType: ValidationTypes = ValidationTypes.REQUIRED;

		// asign the validation type if there is any from the settings props (sent from backend)
		if(validation && Object.values(ValidationTypes).includes(validation))
			formValidationType = validation;

		switch(setting?.type) {
			case SettingInputTypes.BOOL:
				return (
					<CheckItem
						title={label}
						description={setting?.comment}
						type="checkbox"
						checked={!!setting?.value}
						changed={(event, _props) =>
							updateValue(event.currentTarget.checked)}
					/>
				);

			case SettingInputTypes.COLOR:
				// https://casesandberg.github.io/react-color/
				return null;
				// return (
				// 	<TextInput
				// 		type="color"
				// 		label={label}
				// 		description={setting?.comment}
				// 		// value={setting?.value as string}
				// 	/>
				// );

			case SettingInputTypes.DATE:
				let date = null;

				// use default null if we don't have a value
				if(!setting.value) {
					date = DateTime.local().toISO();
				} else {
					date = DateTime.fromISO(setting.value as string)
						.toLocal()
						.setLocale('sv-SV')
						.toISO();
				}

				return (
					<DateTimePicker
						label={label}
						description={setting?.comment}
						changed={(date: string) => updateValue(date)}
						value={date ?? undefined}
					/>
				);

			case SettingInputTypes.MEDIA:
				const value = setting.value as NewMediaFile;

				return (
					<Label
						label={label}
						description={setting?.comment}
					>
						<ScImageThumbnail
							id={setting?.id}
							actions={setting.value ? mediaActions : []}
							placeholderClicked={openMediaArchive}
							thumbnail={value?.thumbnail}
						/>
					</Label>
				);

			case SettingInputTypes.SECRET:
			case SettingInputTypes.TEXT:
				return (
					<Textarea
						id="value"
						label={label}
						description={setting?.comment}
						resizeVertical
						placeholder={setting?.default as string}
						value={(setting.value as string) || ''}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['value']}
						changed={(
							ev: React.ChangeEvent<
							HTMLInputElement | HTMLTextAreaElement
							>,
							...data
						) => {
							formValidation.watch(
								ev,
								() => updateValue(ev.target.value),
								data
							);
						}}
						inputRef={(ref: HTMLTextAreaElement | null) =>
							formValidation.registerElement(ref, {
								required: false,
								validation: formValidationType
							})}
					/>
				);

			case SettingInputTypes.FLOAT:
			case SettingInputTypes.INT:
				return (
					<TextInput
						type="text"
						id="value"
						label={label}
						description={setting?.comment}
						value={`${setting?.value}`}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['value']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(
								ev,
								() => updateValue(ev.target.value),
								data
							);
						}}
						inputRef={(ref: HTMLInputElement) =>
							formValidation.registerElement(ref, {
								validation: ValidationTypes.NUMERIC
							})}
					/>
				);

			default:
				return null;
		}
	}, [
		formValidation,
		label,
		mediaActions,
		openMediaArchive,
		setting,
		updateValue
	]);

	return (
		<>
			{mediaArchiveModal.getAsComponent(
				<MediaArchive
					mediaChosen={mediaArchiveFileSelectedHandler}
					maxSelectionAmount={1}
					fileShowOnly={['image']}
				/>
			)}

			<ScContainer>
				<ModalContainer>
					{settingComponent}

					{userIsAdvanced && (
						<CheckItem
							title="Easy access"
							description="Tillgänlig för kund att redigera"
							type="checkbox"
							checked={setting?.general}
							changed={(ev, _props) =>
								updateEasyAccess(ev.currentTarget.checked)}
						/>
					)}
				</ModalContainer>
			</ScContainer>
		</>
	);
};

const ScContainer = styled.div<ScSettingDetailsProps>``;

export default SettingDetails;

const ScImageThumbnail = styled(ImageThumbnail)`
	width: 100%;
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;
