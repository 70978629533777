import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../../utils/oc-axios';
import { RedirectsResponse, RedirectsParams } from '../Redirects.types';

const useRedirects = (params: RedirectsParams) => {

	/**
     * Fetch redirects data
     * 
     * @param {AbortSignal | undefined} signal 
     * @returns {Promise<RedirectsResponse>}
     */
	const fetchRedirects = React.useCallback(async ({ signal } : { signal?: AbortSignal }): Promise<RedirectsResponse> => {
		try {
			return axios.get('modules/redirects/v2', {
				params,
				signal
			}).then(res => res.data);
		} catch(error) {
			throw new Error('Det gick inte att hämta länkar');
		}
		
	}, [params]);

	// Holds a useQueryResult with redirects data
	const query = useQuery(['redirects', params], fetchRedirects, {
		refetchOnWindowFocus: false
	});

	return query;
};

export default useRedirects;