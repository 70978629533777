import { LINK } from '../../OC7Editor';

const withLinkAsInline = (editor) => {
	if (!editor) return editor;

	const { isInline } = editor;

	/**
	 * Will determine whether the element is or should be an inline element
	 *
	 * @param {Element} element
	 * @returns {boolean}
	 */
	editor.isInline = (element) => {
		return element.type === LINK ? true : isInline(element);
	};

	return editor;
};

export default withLinkAsInline;
