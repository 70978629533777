import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from '../../../../utils/oc-axios';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import { Redirect, RedirectMutationsVariables } from '../Redirects.types';

const useCreateRedirect = () => {
	const notification = useAlert()[1] as AlertDispatch;

	/**
     * Create a redirect
     * 
	 * @param {Redirect} data
     * @returns {Promise<void>}
     */
	const createRedirect = React.useCallback(async (data: Redirect): Promise<void> => {
		return axios.post('/modules/redirects/v2', { 
			redirect_url: data.redirect_url,
			request_url: data.request_url
		 });
	}, []);

	// Returns a mutation object in order to handle the create redirect request
	const mutation = useMutation((variables: RedirectMutationsVariables) => {
		return createRedirect(variables.data);
	}, {
		onSuccess(_data, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.success,
					title: 'Skapar 301-länk',
					children: 'Länken har skapats'
				});
			}

			// Call the callback method from variables if such method is provided
			if(variables.cb) variables.cb();
		},
		onError(_error, variables) {
			if(variables.alertId) {
				notification('MODIFY', {
					alertID: variables.alertId,
					priority: AlertPriorityTypes.error,
					title: 'Skapar 301-länk',
					children: 'Det gick inte att skapa länken'
				});
			}
		}
	});

	return mutation;
};

export default useCreateRedirect;