import { Column } from 'react-table';

/**
 * Constanst used for mapping property names of OrderListItem,
 * 	used in the column definition & later in logic when creating columns for the table.
 */

// Columns for React Table.
export const GROUPS_LIST_COLUMNS: Column<object>[] = [
	{
		Header: 'Gruppnamn',
		accessor: 'name'
	},
	{
		Header: 'Beskrivning',
		accessor: 'description'
	},
	{
		Header: 'Användare',
		accessor: 'users'
	}
];
