import React from 'react';
import styled from 'styled-components/macro';
import Tier from './Tier';
import AlternativeImage from './AlternativeImage';
import ProductImage from './ProductImage';
import Article from './Article';
import { Button, Message, Icon, Tooltip } from '../../../components/UI';
import { TextInput, Textarea, Select } from '../../../components/Forms';
import DragDrop from '../../../hoc/DragDrop/DragDrop';
import Draggable from '../../../hoc/DragDrop/Draggable';

const ProductView = (props) => {
	// check if product is a bundle
	const isBundle = props.details.bundles.length > 0;

	// When the product was last imported
	const imported = props.details.base.imported ? new Intl.DateTimeFormat('sv-SV', {
		dateStyle: 'short',
		timeStyle: 'medium'
	}).format(new Date(props.details.base.imported)) : null;

	// checks if product has tiers
	const hasTiers =
		Array.isArray(props.details.tiers) && props.details.tiers.lenght > 0;

	const images = props.details.images;

	// image object containing main, article, detail, environmental
	const imageConnections = props.details.image_connections;

	// fetches article images
	const mainImage = Object.values(images).find((image) => image.main);

	// fetches article images
	const articleImages =
		'article' in imageConnections
			? imageConnections.article.map((article) => images[article])
			: null;

	const availableColors = getAvailableColors(
		articleImages,
		props.details.all_color_names
	);

	// fetches detail images
	const detailImages = getAlternativeImages(
		'detail',
		imageConnections,
		images,
		props.removeImage,
		props.setEnabledStatus
	);

	// fetches environmental images
	const environmentalImages = getAlternativeImages(
		'environmental',
		imageConnections,
		images,
		props.removeImage,
		props.setEnabledStatus
	);

	// product articles
	const articles = props.details.articles.map((article) => (
		<Article
			key={`article_${article.number}`}
			number={article.number}
			name={article.name}
			status={article.status}
		/>
	));
	
	/**
	 * Shows the product-page on the website in a new tab
	 */
	const showOnWebsiteClickedHandler = React.useCallback(() => {

		window.open(props.details.uri, '_blank');

	}, [props.details.uri]);

	// product videos
	let videos = 'Inga videos finns tillagda';

	if(props.details.videos.length) {
		videos = props.details.videos.map((video, index) => {
			const number = index + 1;
			const id = `video_id-${index}`;
			return (
				<ScVideoContainer key={`video_${number}`}>
					<ScInputContainer>
						<Select
							name="provider"
							index={index}
							changed={props.editVideo}
							label={`Videoprovider ${number}`}
							value={video.provider}
						>
							<option value="youtube">
								Youtube
							</option>
							<option value="vimeo">
								Vimeo
							</option>
						</Select>
						<TextInput
							id={id}
							name="video_id"
							index={index}
							label={`Video ID ${number}`}
							placeholder="Y_vTyhtF_-g"
							value={video.video_id}
							formValidationUnregister={
								props.formValidation.unregisterElement
							}
							changed={(ev, ...data) => {
								props.formValidation.watch(
									ev,
									props.editVideo,
									data
								);
							}}
							error={props.formValidation.errors[id]}
							inputRef={(ref) =>
								props.formValidation.registerElement(ref, {
									required: true,
									validation: {
										min: 5,
										max: 20
									}
								})}
						/>
					</ScInputContainer>
					<Icon
						icon={['fal', 'trash']}
						style={{ marginLeft: 8, cursor: 'pointer' }}
						onClick={(ev) => props.removeVideo(ev, index)}
					/>
				</ScVideoContainer>
			);
		});
	}

	// products tiers
	const tiers = hasTiers
		? props.details.tiers.map((tier) => (
			<Tier
				quantity={tier.quantity}
				price={getPrice(tier.price)}
			/>
		  ))
		: null;

	return (
		<>
			<ScHeader>
				Redigera
			</ScHeader>
			<ScDetailWrapper>
				<ScColumn borderRight>
					<ScSectionContainer>
						<ScSectionHeader>
							Senast importerad
							{imported ? (
								<ScMessage>
									{imported}
								</ScMessage>
							) : (
								<ScMessageItalic>
									Uppgifter saknas
								</ScMessageItalic>
							)}
						</ScSectionHeader>
						<ScSectionHeader>
							Status
						</ScSectionHeader>
						<Select
							name="base.status"
							changed={props.changed}
							label="Produktstatus (normalt enable)"
							description="Överstyrs av Pyramid vid nästa sync"
							value={props.details.base.status}
						>
							<option value="enable">
								Köpbar
							</option>
							<option value="show">
								Synlig
							</option>
							<option value="disable">
								Dold
							</option>
						</Select>
						<ScFlexWrapper>
							<div
								style={{
									display: 'flex',
									flexDirecton: 'row'
								}}
							>
								{mainImage && (
									<ScImage
										alt=""
										src={mainImage.uri}
									/>
								)}
								<div>
									<div>
										Art:
										{' '}
										{props.details.base.number}
									</div>
									<div style={{ fontSize: 14 }}>
										Namn: 
										{' '}
										{props.details.base.name}
									</div>

									{!props.details.settings.ready && (
										<ScTooltipWrapper>
											<Tooltip
												isOver
												text="Produkten är ny, klarmarkera för att produkten ska kunna visas på hemsidan."
											>
												<ScNewProduct>
													Ny produkt
												</ScNewProduct>
											</Tooltip>
										</ScTooltipWrapper>
									)}

									{/* <CheckItem
										type="radio"
										isDisabled
										title="Klarmarkerad"
										description="Produkten ligger inte live på hemsidan"
										checked={props.details.settings.ready}
										style={{ marginTop: 8 }}
									/> */}
								</div>
							</div>
							<ScButtonContainer>
								<Button
									onClick={showOnWebsiteClickedHandler}
									isPrimary
									isSmall
									isRounded
								>
									Visa produkt på hemsidan
								</Button>
							</ScButtonContainer>
						</ScFlexWrapper>
					</ScSectionContainer>

					<ScSectionContainer>
						<ScSectionHeader>
							Produktbilder
							<ScMessage>
								{articleImages &&
									'Välj huvudbild för denna produkt'}
								{!articleImages &&
									'Det finns inga artikelbilder till denna produkt'}
							</ScMessage>
						</ScSectionHeader>
						{articleImages && (
							<ScSectionContainer
								style={{
									flexDirection: 'row',
									display: 'flex',
									flexWrap: 'wrap'
								}}
							>
								{articleImages.map((image, index) => {
									const isOutOfStock = props.details.articles
										.filter(
											(article) =>
												article.true_color &&
												article.true_color ===
													image.true_color
										)
										.every(
											(article) =>
												article.status !== 'enable'
										);

									return (
										<ProductImage
											key={`product_image_${image.uuid}`}
											isOutOfStock={isOutOfStock}
											image={image}
											uuid={image.uuid}
											changed={props.setMainImage}
											removed={props.removeImage}
											changedColor={props.setArticleColor}
											availableColors={availableColors}
											colors={
												props.details.all_color_names
											}
											type="article"
										/>
									);
								})}
							</ScSectionContainer>
						)}
					</ScSectionContainer>

					{detailImages && (
						<ScSectionContainer>
							<ScSectionHeader>
								Detaljbilder
								<ScMessage>
									Sortera och välj vilka detaljbilder som ska
									vara synliga
								</ScMessage>
							</ScSectionHeader>
							<ScDragDrop
								scope="dnd-detail-images"
								onDragEnd={props.dragEndHandler}
							>
								{detailImages}
							</ScDragDrop>
						</ScSectionContainer>
					)}

					{environmentalImages && (
						<ScSectionContainer>
							<ScSectionHeader>
								Miljöbilder
								<ScMessage>
									Sortera och välj vilka miljöbilder som ska
									vara synliga
								</ScMessage>
							</ScSectionHeader>
							<ScDragDrop
								scope="dnd-environmental-images"
								onDragEnd={props.dragEndHandler}
							>
								{environmentalImages}
							</ScDragDrop>
						</ScSectionContainer>
					)}

					<ScSectionContainer>
						<ScSectionHeader>
							Knapp
							<ScMessage>
								Lägger till en knapp på produktvyn
							</ScMessage>
						</ScSectionHeader>
						<TextInput
							id="settings.button_text"
							name="settings.button_text"
							label="Knapptext"
							placeholder="Knapptext..."
							value={props.details.settings.button_text}
							formValidationUnregister={
								props.formValidation.unregisterElement
							}
							changed={(ev, ...data) => {
								props.formValidation.watch(
									ev,
									props.changed,
									data
								);
							}}
							error={
								props.formValidation.errors[
									'settings.button_text'
								]
							}
							inputRef={(ref) =>
								props.formValidation.registerElement(ref, {
									required: false,
									validation: {
										min: 3
									}
								})}
						/>

						<TextInput
							label="URL eller artikel"
							id="settings.button_uri"
							name="settings.button_uri"
							placeholder="6000"
							value={props.details.settings.button_uri}
							formValidationUnregister={
								props.formValidation.unregisterElement
							}
							changed={(ev, ...data) => {
								props.formValidation.watch(
									ev,
									props.changed,
									data
								);
							}}
							error={
								props.formValidation.errors[
									'settings.button_uri'
								]
							}
							inputRef={(ref) =>
								props.formValidation.registerElement(ref, {
									validation: {
										type: 'regex',
										pattern: /(^\d+$)|(^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$)/gm,
										message:
											'Detta fält kräver antingen en URL eller ett artikelnummer'
									}
								})}
						/>
					</ScSectionContainer>

					<ScSectionContainer>
						<ScSectionHeader>
							Video
							<ScMessage>
								Lägger till en video i slutet på karusellen för
								produkten
							</ScMessage>
						</ScSectionHeader>

						{videos}

						<Button
							style={{ marginTop: 16 }}
							onClick={props.addVideo}
						>
							Lägg till video
						</Button>
					</ScSectionContainer>
				</ScColumn>

				<ScColumn>
					<ScSectionContainer>
						<ScSectionHeader>
							Produktinformation
						</ScSectionHeader>

						<TextInput
							label="bubble (A) *3 Pratis F7"
							name="bubble"
							isDisabled
							placeholder="En bubbla"
							value={props.details.base.bubble}
						/>

						<Textarea
							label="Beskrivningar"
							isDisabled
							resizeNone
							placeholder="Beskrivning"
							value={props.details.base.description}
						/>

						<Textarea
							label="ingress (A) *3 Ingress F7"
							isDisabled
							resizeNone
							placeholder="Ingress"
							value={props.details.base.ingress}
						/>

						<Textarea
							label="description (A) *3 Brödtext F7"
							isDisabled
							resizeNone
							placeholder="Brödtext"
							value={props.details.base.description}
						/>
					</ScSectionContainer>

					{!isBundle && !hasTiers && (
						<ScSectionContainer>
							<h4>
								Prices
							</h4>

							<TextInput
								label="current (P10) #1155 (710, 1) - (Butikspris)"
								value={getPrice(props.details.prices.current)}
								isDisabled
							/>

							<TextInput
								label="regular (P10) #18031 Ordinarie pris (710, 1)"
								value={getPrice(props.details.prices.regular)}
								isDisabled
							/>

							<TextInput
								label="recommended (P10) #1186 (710, 1) - (Rek. pris)"
								value={getPrice(
									props.details.prices.recommended
								)}
								isDisabled
							/>
						</ScSectionContainer>
					)}

					{isBundle && (
						<ScSectionContainer>
							<h4>
								Bundle_product - (Setpris)
							</h4>

							<TextInput
								label="Price (P10) #18875 Pris (5701)"
								value={getPrice(props.details.price)}
								isDisabled
							/>
						</ScSectionContainer>
					)}

					<ScSectionContainer>
						<ScSectionHeader>
							Artiklar
						</ScSectionHeader>
						{articles}
					</ScSectionContainer>

					{tiers && (
						<ScSectionContainer>
							<ScSectionHeader>
								Stafflings
							</ScSectionHeader>

							{tiers}
						</ScSectionContainer>
					)}
				</ScColumn>
			</ScDetailWrapper>
		</>
	);
};

export default ProductView;

/**
 * Formats a price e.g. 125 SEK
 *
 * @param {object} price
 */
const getPrice = (price) => {
	return price ? `${price} SEK` : null;

	// return price ? `${price.total} ${price.currency}` : null;
};

/**
 * Gets available colors
 *
 * @param {array} articleImages
 * @param {object} colors
 */
const getAvailableColors = (articleImages, colors) => {
	if(!articleImages) return {};

	return Object.keys(colors).reduce((prev, colorKey) => {
		const color = colors[colorKey];
		// const entry = articleImages.find(
		// 	(image) =>
		// 		image.true_color &&
		// 		(image.true_color === color.code ||
		// 			image.true_color === color.color_code)
		// );

		if(articleImages) {
			const entry = articleImages.find(
				(article) =>
					article.color_code && article.color_code === colorKey
			);

			if(!entry) {
				prev[colorKey] = {
					...color
				};
			}
		}

		return prev;
	}, {});
};

/**
 * Renders images
 *
 * @param {string} key
 * @param {object} images
 */
const getAlternativeImages = (
	key = '',
	imageConnections,
	images,
	removeImage,
	setEnabledStatus
) => {
	if(!(key in imageConnections)) return;

	return imageConnections[key].map((imageUuid, index) => {
		const image = images[imageUuid];

		return (
			<Draggable
				id={index}
				uuid={image.uuid}
				key={index}
				index={index}
				type={key}
				scope={`dnd-${key}-images`}
				allowedDirection="vertical"
				isHandle
			>
				<AlternativeImage
					key={`${key}_image_${image.uuid}`}
					thumbnail={image.uri}
					name={image.name}
					type={key}
					index={index}
					uuid={image.uuid}
					removeImage={removeImage}
					setEnabledStatus={setEnabledStatus}
					isChecked={image.enabled}
				/>
			</Draggable>
		);
	});
};

const ScDetailWrapper = styled.div`
	width: 100%;
	padding: 8px;
	display: flex;
	flex-wrap: wrap;
`;

const ScColumn = styled.div`
	border-right: ${(props) => (props.borderRight ? '1px solid #ccc' : 'none')};
	flex: ${(props) => props.flex || 1};
	flex-direction: column;
	padding: 16px;
`;

const ScSectionHeader = styled.div`
	margin-bottom: 16px;
	font-size: 16px;
`;

const ScMessage = styled(Message)`
	font-size: 14px;
	margin-bottom: 16px;
`;

const ScMessageItalic = styled(Message)`
	font-size: 14px;
	margin-bottom: 16px;
	font-style: italic;
`;

const ScHeader = styled.h2`
	padding: 16px;
	text-align: center;
	font-size: 24px;
	margin: 0 0 16px;
`;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScFlexWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ScSectionContainer = styled.div`
	margin-bottom: 32px;
`;

const ScImage = styled.img`
	margin-right: 16px;
	height: 112px;
`;

const ScVideoContainer = styled.div`
	background: #eee;
	display: flex;
	align-items: center;
	padding: 8px;

	&:nth-child(even) {
		background: #f9f9f9;
	}
`;

const ScInputContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const ScNewProduct = styled.div`
	border-radius: 4px;
	padding: 8px;
	font-size: 12px;
	background: #c4c4c4;
	color: #fafafa;
	display: inline-block;
`;

const ScTooltipWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	margin-top: 8px;
`;

const ScDragDrop = styled(DragDrop)`
	margin-bottom: 32px;
`;
