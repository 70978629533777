import { User } from '../../containers/ControlPanel/Users/model.Users';

export type AuthMethodCb = (statusCode: number) => void;

export interface Credentials {
	username: string;
	password: string;
}

export interface AuthContextProps {
	authUser: AuthUser | null;
	authLoader: boolean;
	lostCredentials: (email: string) => Promise<number>;
	setPass: (hash: string | null, password: string) => Promise<number>;
	signIn: (data: Credentials) => Promise<number>;
	signOut: () => Promise<number>;
}

export interface AuthUser extends User {}

export interface RequestBody {
	[key: string]: string | number | boolean | undefined | null;
}

export enum AllowedMethods {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
}
