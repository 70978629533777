import { useDragDrop } from '../../hooks/useDragDrop';
import { defineDOMLivingSpace, defineTargetDocument } from './DragDropUtils';

const MouseUpHandler = (ev, callback, props) => {
	ev.stopPropagation();

	/**
	 * Get the current drag state and the dispatch function
	 */
	const [state, dispatch] = useDragDrop();

	/**
	 * The scope of the drag instance
	 * @type String
	 */
	const scope = props.scope;

	/**
	 * The targetDocumentSelector is used to determine the document where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDocumentSelector = state[scope].targetDocumentSelector
		? state[scope].targetDocumentSelector
		: null;

	/**
	 * The targetDOMSelector is used to determine the DOM element where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDOMSelector = state[scope].targetDOMSelector
		? state[scope].targetDOMSelector
		: null;

	// If a draggedItem has not yet been set then ignore the move handler
	if (!('draggedItem' in state)) return;

	/**
	 * Get the dragged item from the state
	 *
	 * @type {{}|draggedItem|ctx.draggedItem|*}
	 */
	const draggedItem = state.draggedItem;

	/**
	 * Get the hovered item from the state
	 *
	 * @type {{}|hoveredItem|{instruction: {}}|{instruction}}
	 */
	const hoveredItem = state.hoveredItem;

	/**
	 * Get the instructions for the drop
	 *
	 * @type {{}|instruction}
	 */
	const instruction = state.instruction;

	/**
	 * Get the DOM element where the drag instance lives
	 *
	 * @type {HTMLElement}
	 */
	const targetDOM = defineDOMLivingSpace(
		targetDOMSelector,
		targetDocumentSelector
	);

	// Define scope document
	const targetDocument = defineTargetDocument(targetDocumentSelector);

	// remove the class reference from the DOM node where the drag instance lives
	targetDOM.classList.remove('isDragging');

	// remove the dragable element
	if (draggedItem.dragableElement) draggedItem.dragableElement.remove();

	// free memory
	targetDocument.removeEventListener(
		'mousemove',
		draggedItem.mouseMoveListenerReference,
		false
	);
	targetDocument.removeEventListener(
		'mouseup',
		draggedItem.mouseUpListenerReference,
		false
	);

	// remove the box-shadow referencing the drop position from the hoveredElement
	if (hoveredItem !== void 0)
		hoveredItem.hoveredElement.style.boxShadow = 'none';

	// clear any autoscroll event
	if (state[scope].scrollInterval) {
		clearInterval(state[scope].scrollInterval);
		dispatch('CLEAR_SCROLL_INTERVAL');
	}

	// Dispatch the IS_RELEASED which will clear the draggedItem and hoveredItem references from state
	dispatch('IS_RELEASED');

	if (typeof callback === 'function')
		callback(draggedItem, hoveredItem, instruction, ev);

	// Call the provided callback function that were defined in DragDrop as a property (onDragEnd)
	// if (
	// 	typeof callback === 'function' &&
	// 	!isEmptyObject(hoveredItem) &&
	// 	draggedItem.properties.id !== hoveredItem.properties.id
	// )
	// 	callback(draggedItem, hoveredItem, instruction, ev);
};

export default MouseUpHandler;
