import React from 'react';
import styled, { css } from 'styled-components/macro';
import useAuth from '../../hooks/useAuth/useAuth';
import { InternalLink, Icon } from '../UI';

const ClientHeader = () => {
	const { authUser, signOut } = useAuth();

	return (
		<ScContainer>
			<ScUser>
				<ScName>
					{authUser.name}
				</ScName>
				<ScUsername>
					{authUser.login_name}
				</ScUsername>
				<ScSignOut onClick={signOut}>
					Logga ut
					<ScSignOutIcon
						fixedWidth
						icon={['fal', 'arrow-right-from-bracket']}
					/>
				</ScSignOut>
			</ScUser>

			<ScItem>
				<ScInternalLink to="/">
					<Icon
						fixedWidth
						icon={['fal', 'folder-tree']}
					/>
					<ScText>
						Webbsida
					</ScText>
				</ScInternalLink>
			</ScItem>

			<ScItem>
				<ScInternalLink to="/modules">
					<ScIcon
						fixedWidth
						icon={['fal', 'puzzle-piece']}
					/>
					<ScText>
						Moduler
					</ScText>
				</ScInternalLink>
			</ScItem>
			<ScItem>
				<ScInternalLink to="/control-panel">
					<ScIcon
						fixedWidth
						icon={['fal', 'sliders']}
					/>
					<ScText>
						Kontrollpanel
					</ScText>
				</ScInternalLink>
			</ScItem>
		</ScContainer>
	);
};

export default ClientHeader;

const ScContainer = styled.header`
	flex: 0 1 auto;
	background-color: var(--clientHeader-bg-color);
	padding: 32px 16px;
	user-select: none;
`;

const ScIcon = styled(Icon)`
	color: var(--font-dark-grey-color);
`;

const ScInternalLink = styled(InternalLink)`
	color: var(--font-dark-grey-color);
`;

const ScText = styled.div`
	color: var(--font-dark-grey-color);
	display: inline-block;
	margin-left: 8px;
	font-weight: 300;
`;

const ScItem = styled.div`
	margin-bottom: 16px;
	:last-child {
		margin-bottom: 0;
	}

	:hover {
		${ScText},${ScIcon},${ScInternalLink} {
			color: var(--font-body-color);
		}
	}

	${(props) =>
		props.isActive &&
		css`
			${ScText},${ScIcon},${ScInternalLink} {
				color: var(--font-body-color);
			}

			${ScText} {
				font-weight: 600;
			}
		`}
`;

const ScUser = styled.div`
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #ddd;
	padding-bottom: 8px;
`;

const ScName = styled.div``;

const ScUsername = styled.div`
	font-size: 14px;
	opacity: 0.8;
`;
const ScSignOut = styled.div`
	font-size: 12px;
	opacity: 0.8;
	cursor: pointer;
`;

const ScSignOutIcon = styled(Icon)`
	margin-left: 4px;
`;
