import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactTableHeadingProps, StyledSortIconProps } from './ReactTableHeading.types';
import { Icon } from '../../UI';

const ReactTableHeading: React.FC<ReactTableHeadingProps> = (props) => {

	const { customSortingCallback, id } = props;
	
	/**
	 * Clickedhanler for custom callbacks
	 * 
	 * @param {React.SyntheticEvent<HTMLDivElement>} ev
	 * @returns {void} 
	 */
	const clickedHandler = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
		if(customSortingCallback) customSortingCallback(ev, id);
	}, [customSortingCallback, id]);

	// Whether or not to allow a custom callback for sorting, or let React Table handle it
	const customProps = React.useMemo(() => {
		if(!customSortingCallback) return {};

		return {
			onClick: clickedHandler
		};
	}, [clickedHandler, customSortingCallback]);
	
	return (
		<ScTableHeading
			{...props}
			{...customProps}
		>
			{props.children}
			{props.sortOrder && (
				<ScSortItem>
					<ScSortIcon
						icon={[
							'fas',
							props.sortOrder === 'desc'
								? 'sort-up'
								: 'sort-down'
						]}
						sortOrder={props.sortOrder}
					/>
				</ScSortItem>
			)}
		</ScTableHeading>
	);
};

export default React.memo(ReactTableHeading);

export const ScTableHeading = styled.div`
	text-align: left;
	vertical-align: middle;
	white-space: nowrap;
	padding: 8px 16px 8px 0;
	font-size: 12px;
	font-weight: 600;
	display: table-cell;
	&:first-child {
		padding-left: 16px;
	}
	user-select: none;
	height: 46px;
`;

export const ScSortItem = styled.div`
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
`;

export const ScSortIcon = styled(Icon)<StyledSortIconProps>`
	margin-left: 8px;
	bottom: 0px;
	position: relative;

	/* Fix for icons not being centered by Font-not-so-awesome*/
	${(props) =>
		props.sortOrder === 'desc' &&
		css`
			bottom: -6px;
		`}
`;
