import * as actionTypes from './actionTypes';

/**
 * Sets the initial data for rendering the builder
 *
 * @param {object} blockTypes
 * @param {object} pageData
 */
export const setInitialState = (blockTypes, pageData, formStructure) => ({
	type: actionTypes.SET_BUILDER_INITIAL_STATE,
	blockTypes: blockTypes,
	pageData: pageData,
	formStructure: formStructure

});

/**
 * Trigger update of the savehash, use when saving the page to keep the hash updated to correctly check for changes.
 */
export const updateSavehash = () => ({
	type: actionTypes.BUILDER_UPDATE_SAVEHASH
});

/**
 * Cleans the Builder state.
 */
export const cleanState = () => ({
	type: actionTypes.BUILDER_CLEAN_STATE
});

export const setPageSettings = (settings) => {
	return {
		type: actionTypes.SET_PAGE_SETTINGS,
		settings: settings
	};
};

/**
 * Generic block content updating
 *
 * @param {object} block
 */
export const updateBlockContent = (block) => {
	return {
		type: actionTypes.UPDATE_BLOCK_CONTENT,
		block: block
	};
};

/**
 * Updates content in textareas
 *
 * @param {string} content
 * @param {int} blockId
 * @param {string} valueProp
 */
export const updateTextContent = (content, blockId, valueProp) => {
	return {
		type: actionTypes.UPDATE_TEXT_CONTENT,
		content,
		blockId,
		valueProp
	};
};

/**
 * Updates row visibility of a block row
 *
 * @param {string} rowId
 * @param {string} variantType
 */
export const setRowVisibilitySettings = (rowId, variantType) => {
	return {
		type: actionTypes.SET_ROW_VISIBILITY_SETTINGS,
		rowId: rowId,
		variantType: variantType
	};
};

/**
 * Updates width of a block row
 *
 * @param {string} rowId
 * @param {boolean} hasMaxWidth
 */
export const setRowWidthSettings = (rowId, hasMaxWidth) => {
	return {
		type: actionTypes.SET_ROW_WIDTH_SETTINGS,
		rowId: rowId,
		hasMaxWidth: hasMaxWidth
	};
};

/**
 * Updates reverse setting of a block row
 *
 * @param {string} rowId
 * @param {boolean} hasMaxWidth
 */
export const setRowReverseSettings = (rowId, isReversed) => {
	return {
		type: actionTypes.SET_ROW_REVERSE_SETTINGS,
		rowId: rowId,
		isReversed: isReversed
	};
};

/**
 * Updates column width of a block row
 * 
 * @param {string} rowId
 * @param {string} column_division
 */
export const changeColumnWidth = (rowId, column_division) => {
	return {
		type: actionTypes.CHANGE_COLUMN_WIDTH,
		rowId,
		column_division
	};
};

/**
 * Triggers a state update that will create a row set in specific area
 *
 * @param {string*} area
 * @param {int} index
 * @param {string} blockType
 */
export const createRowSetToArea = (areaId, index, blockType) => {
	return {
		type: actionTypes.CREATE_ROW_SET_TO_AREA,
		areaId,
		index,
		blockType
	};
};

/**
 * Triggers a state update that will create a row set in specific empty area
 *
 * @param {string} area
 * @param {string} blockType
 */
export const createRowSetToEmptyArea = (areaId, blockType) => {
	return {
		type: actionTypes.CREATE_ROW_SET_TO_EMPTY_AREA,
		areaId,
		blockType
	};
};

/**
 * Triggers a state update that will create a new block in specific column
 *
 * @param {string} column
 * @param {int} index
 * @param {string} blockType
 */
export const createBlockInColumn = (rowId, columnId, index, blockType) => {
	return {
		type: actionTypes.CREATE_BLOCK_IN_COLUMN,
		rowId,
		columnId,
		index,
		blockType
	};
};

/**
 * Triggers a state update that will create a new row set to specific area
 *
 * @param {array} args
 */
export const dndCreateRowSetToArea = (args) => {
	return {
		type: actionTypes.DND_CREATE_ROW_SET_TO_AREA,
		args: args
	};
};

/**
 * Triggers a state update that will create a new column to specific row set
 *
 * @param {array} args
 */
export const dndCreateColumnSetToRow = (args) => {
	return {
		type: actionTypes.DND_CREATE_COLUMN_SET_TO_ROW,
		args: args
	};
};

/**
 * Triggers a state update that will move a block within/to another column
 *
 * @param {array} args
 */
export const dndMoveBlockWithinAnyColumn = (args) => {
	return {
		type: actionTypes.DND_MOVE_BLOCK_WITHIN_ANY_COLUMN,
		args: args
	};
};

/**
 * Triggers a state update that will move a row set vertically
 *
 * @param {array} args
 */
export const dndMoveRowVertically = (args) => {
	return {
		type: actionTypes.DND_MOVE_ROW_VERTICALLY,
		args: args
	};
};

export const deleteBlock = (areaId, rowId, columnId, blockId, index) => {
	return {
		type: actionTypes.DELETE_BLOCK,
		areaId,
		rowId,
		columnId,
		blockId,
		index
	};
};
