import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { PositionGrid, Button } from '../../../../UI';
import { Select, TextInput, CheckItem } from '../../../../Forms';

const Poster = (props) => {
	const block = props.block;
	const contentPosition = props.contentPosition;
	const openMainMenuSelector = props.openMainMenuSelector;

	const changedHandler = props.changed;
	const inputChangedHandler = props.inputChanged;
	const checkboxChangedHandler = props.checkboxChanged;

	const formValidation = props.formValidation;

	return (
		<>
			<Select
				label="Text"
				name="has_caption"
				changed={(ev) => {
					changedHandler(
						ev.target.name,
						ev.target.value === 'true' ? true : false,
						false
					);
				}}
				value={block.has_caption}
			>
				<option value={false}>
					Dold
				</option>
				<option value={true}>
					Synlig
				</option>
			</Select>

			<Select
				label="Länk"
				name="link_type"
				changed={inputChangedHandler}
				value={block.link_type}
			>
				<option value="none">
					Ingen
				</option>
				<option value="on_image">
					Hela bilden
				</option>
				<option value="button">
					Knapp
				</option>
			</Select>

			{(block.has_caption || block.link_type === 'button') && (
				<>
					<PositionGrid
						value={contentPosition}
						changed={inputChangedHandler}
						label="Text/knapp placering"
						description="Om länken är en knapp så placeras knappen i anslutning till texten"
					/>

					<Select
						label="Bakgrundsfärg"
						name="theme"
						changed={inputChangedHandler}
						value={block.theme}
					>
						<option value="default">
							Ingen
						</option>
						<option value="lightTransparent">
							Ljus med transparens
						</option>
						<option value="light">
							Ljus
						</option>
						<option value="darkTransparent">
							Mörk med transparens
						</option>
						<option value="dark">
							Mörk
						</option>
					</Select>
				</>
			)}

			{(() => {
				if(block.link_type === 'button') {
					return (
						<>
							<TextInput
								label="Knapp Text"
								name="link.title"
								id="link.title"
								value={block.link.title}
								formValidationUnregister={
									formValidation.unregisterElement
								}
								error={formValidation.errors['link.title']}
								changed={(ev, ...data) => {
									formValidation.watch(
										ev,
										inputChangedHandler,
										data
									);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true
									})}
							/>
							<TextInput
								label="Länkadress (URL)"
								name="link.uri"
								id="link.uri"
								preventBuilderPreview
								value={block.link.uri}
								error={formValidation.errors['link.uri']}
								formValidationUnregister={
									formValidation.unregisterElement
								}
								changed={(ev, ...data) => {
									formValidation.watch(
										ev,
										inputChangedHandler,
										data
									);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true,
										validation: 'slugurl'
									})}
							/>
							<ScButtonContainer>
								<ScButton
									isPrimary
									isSmall
									onClick={openMainMenuSelector}
								>
									Välj i sidträd
								</ScButton>
							</ScButtonContainer>

							<CheckItem
								type="checkbox"
								title="Öppna länken i ett nytt fönster"
								description="Länken öppnas i ett nytt fönster eller flik"
								name="link.new_window"
								changed={checkboxChangedHandler}
								preventBuilderPreview
								checked={
									block.link ? block.link.new_window : false
								}
							/>
						</>
					);
				}

				if(block.link_type === 'on_image') {
					return (
						<>
							<TextInput
								label="Länkadress (URL)"
								name="link.uri"
								id="link.uri"
								value={block.link.uri}
								preventBuilderPreview
								formValidationUnregister={
									formValidation.unregisterElement
								}
								error={formValidation.errors['link.uri']}
								changed={(ev, ...data) => {
									formValidation.watch(
										ev,
										inputChangedHandler,
										data
									);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true,
										validation: 'slugurl'
									})}
							/>

							<ScButtonContainer>
								<ScButton
									isPrimary
									isSmall
									onClick={openMainMenuSelector}
								>
									Välj i sidträd
								</ScButton>
							</ScButtonContainer>

							<CheckItem
								type="checkbox"
								title="Öppna länken i ett nytt fönster"
								description="Länken öppnas i ett nytt fönster eller flik"
								name="link.new_window"
								changed={checkboxChangedHandler}
								checked={
									block.link ? block.link.new_window : false
								}
								preventBuilderPreview
							/>
						</>
					);
				}
			})()}
		</>
	);
};

Poster.propTypes = {
	block: PropTypes.object.isRequired,
	contentPosition: PropTypes.string.isRequired,
	openMainMenuSelector: PropTypes.func,

	changed: PropTypes.func.isRequired,
	inputChanged: PropTypes.func.isRequired,
	checkboxChanged: PropTypes.func.isRequired,

	formValidation: PropTypes.object.isRequired
};

export default Poster;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;
