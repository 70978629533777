import React from 'react';
import styled from 'styled-components/macro';
import {
	MediaPlaceholderProps,
	ScMediaPlaceholderProps
} from './model.MediaPlaceholder';

const MediaPlaceholder: React.FC<MediaPlaceholderProps> = (props) => {
	return (
		<ScContainer
			isLarge={props.isLarge}
			className={props.className}
		>
			{props.children}
		</ScContainer>
	);
};

export default MediaPlaceholder;

const ScContainer = styled.div<ScMediaPlaceholderProps>`
	display: flex;
	height: ${(props) => (props.isLarge ? '400px' : '200px')};
	width: 100%;
	background: #f1f1f1;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	background: var(--bg-bright-color);
	border: 1px solid var(--bg-dark-grey-color);
`;
