import React from 'react';
import BlockFlowbox from './BlockFlowbox';
import BlockFlowboxSettings from './Settings/BlockFlowboxSettings';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
const generateOpts = (
	openModal,
	closeModal,
	changed,
	removeBlock,
	formValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							// Reset builder block settings
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState, currentState, closeModal) => {
							// Notify builder to update all block's atributes before closing the modal
							changed(currentState);
							closeModal();
						}
					}
				];

				openModal({
					title: 'Redigerar Flowbox',
					actions: modalActions,
					position: 'right',
					hideBackdrop: true,
					isPositionable: true,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

export default {
	isAddable: true,
	baseStruct: {
		key: null,
		data: {
			tags: null,
			//product_id: null,
			has_tags: false,
			has_products: false,
			show_buttons: false
		}
	},
	builder: {
		component: <BlockFlowbox />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockFlowboxSettings />
	}
};
