import React from 'react';
import PropTypes from 'prop-types';
import { parseStringAsObjectPath } from 'react-utils';
import { Textarea } from '../../../../Forms';
import { InfoBox, ModalSettingsContainer } from '../../../../UI';

const BlockEmbedSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;

	// The func to trigger when something inside here changed.
	const changed = props.changed;

	// The hook to make formValidation possible
	//const formValidation = props.formValidation;

	// The block data to show inside this component
	const block = currentState.data;

	/**
	 * Handles when a text area changed it's value.
	 *
	 * @param {Event} ev
	 * @param {object} componentProps
	 */
	const textareaChangedHandler = React.useCallback((ev, componentProps) => {
		const { preventBuilderPreview } = componentProps;

		const suggestedState = parseStringAsObjectPath(
			ev.target.name,
			ev.target.value
		);

		// update modal's state,w ill return the updated/new state.
		updateState({ data: suggestedState }).then((updatedState) => {
			// notify Builder to update the block for preview to work
			if(!preventBuilderPreview) changed(updatedState);
		});
	}, [changed, updateState]);

	return (
		<ModalSettingsContainer>
			<Textarea
				name="contents"
				changed={textareaChangedHandler}
				preventBuilderPreview
				resizeVertical
				label="Embed code"
				value={block.contents}
			/>
			<InfoBox isError>
				Embed-blocket innebär vissa risker och bör användas med försiktighet!
			</InfoBox>
		</ModalSettingsContainer>
	);
};

BlockEmbedSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func
};

export default BlockEmbedSettings;
