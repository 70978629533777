import { Column } from 'react-table';

export const CUSTOMER_FILTERBY_ALL = 'all';
export const CUSTOMER_FILTERBY_COMPANY = 'company';
export const CUSTOMER_FILTERBY_PRIVATE = 'private';

export const CUSTOMERS_LIST_COLUMN_TYPE = 'company';
export const CUSTOMERS_LIST_COLUMN_FIRSTNAME = 'firstname';

/**
 * Constanst used for mapping property names of OrderListItem,
 * 	used in the column definition & later in logic when creating columns for the table.
 */
export const MODULE_CUSTOMERS_LIST_COLUMNS: Column<any>[] = [
	// {
	// 	Header: '',
	// 	accessor: CUSTOMERS_LIST_COLUMN_TYPE,
	// },
	{
		Header: 'Namn',
		accessor: CUSTOMERS_LIST_COLUMN_FIRSTNAME
	},
	{
		Header: 'Kundnr',
		accessor: 'number'
	},
	{
		Header: 'C3 ID',
		accessor: 'id'
	},
	{
		Header: 'E-postadress',
		accessor: 'email'
	},
	{ Header: 'Address', accessor: 'street' },
	{ Header: 'Postnr', accessor: 'postal_code' },
	{ Header: 'Postort', accessor: 'city' }
];
