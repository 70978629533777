import React, { ReactNode } from 'react';
import { Cell } from 'react-table';
import styled from 'styled-components/macro';
import { DateTime } from 'luxon';
import { OrderListItem } from './model.Orders';
import {
	ORDERLIST_COLUMNS_ORDER_NR,
	ORDERLIST_COLUMNS_SENT,
	ORDERLIST_COLUMNS_PAYMENT_METHOD,
	ORDERLIST_COLUMNS_TOTAL_AMOUNT,
	ORDERLIST_COLUMNS_COMPLEMENTARY,
	ORDERLIST_COLUMNS_YOTPO
} from './consts.Orders';
import { ScClickable } from './style.Orders';
import { Icon } from '../../../../components/UI';
import { TableCell } from '../../../../components/Table/index';

/**
 *
 * Generates a TableCell with custom content.
 *
 * @param cell 			The cell data to work on.
 * @param cellClicked 	Callback for when the cell is clicked by the user.
 * @returns {TableCell}
 */
export const renderOrdersTableCell = (
	cell: Cell<OrderListItem>,
	cellClicked: (rowData: OrderListItem) => void
) => {
	let cellContent: string | ReactNode = '';

	// id is the same as 'accessor' when defining table columns
	switch(cell.column.id) {
		case ORDERLIST_COLUMNS_ORDER_NR:
			cellContent = (
				<ScClickable>
					W
					{cell.value}
				</ScClickable>
			);
			break;

		case ORDERLIST_COLUMNS_SENT:
			if(cell.value) {
				const localDateTime = DateTime.fromISO(cell.value)
					.toLocal()
					.setLocale('sv-SV');

				cellContent = localDateTime.toLocaleString(DateTime.DATETIME_SHORT);
			}
			break;

		case ORDERLIST_COLUMNS_PAYMENT_METHOD:
			const isSuccess = !!cell.row.original.successful_payment;
			// set the default cell data, override if necessary
			cellContent = cell.value;

			// If the payment is failed add a warning.
			if(!isSuccess)
				cellContent = (
					<ScFailure>
						<Icon icon={['fal', 'triangle-exclamation']} />
						{` ${cell.value ? cell.value : ''}`}
					</ScFailure>
				);

			break;

		case ORDERLIST_COLUMNS_TOTAL_AMOUNT:
			cellContent =
				cell.value && `${cell.value} ${cell.row.original.currency}`;
			break;

		case ORDERLIST_COLUMNS_COMPLEMENTARY:
			cellContent = cell.row.original.complementary ? 'Ja' : 'Nej';
			break;

		case ORDERLIST_COLUMNS_YOTPO:
			cellContent = cell.row.original.sent_to_yotpo ? 'Ja' : 'Nej';
			break;

		default:
			cellContent = cell.render('Cell');
			break;
	}

	return (
		<TableCell
			clicked={() => {
				if(!!cellClicked) cellClicked(cell.row.original);
			}}
			{...cell.getCellProps()}
		>
			{cellContent}
		</TableCell>
	);
};

const ScFailure = styled.span`
	color: #d0021b;
`;
