import styled from 'styled-components/macro';

export const ScTr = styled.div`
	width: 100%;
	position: relative;
	font-size: 14px;
	font-weight: 100;
	display: table-row;
	cursor: pointer;

	:nth-child(odd) {
		background-color: #f3f3f3;
	}

	:hover {
		background-color: #444;
		color: #fafafa;

		div {
			border-color: #fafafa;
		}

		a {
			color: #fafafa;
		}
	}
`;
