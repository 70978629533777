import React, { Children, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Tab from './Tab';
import TabContents from './TabContents';

// https://medium.com/front-end-developers/sliding-react-components-4873e232907e

const TabContainer = (props) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const isTabDisabled = (index) => {
		return props.disableAllTabsButActive && index !== activeIndex;
	};

	// props.children are the tabs and the tabs children is the content.
	const tabs = [];
	const contents = [];
	Children.map(props.children, (tab, index) => {
		// add tab
		tabs.push(
			<ScTab
				disableTab={isTabDisabled(index)}
				showPadding={props.children.length > 1}
				active={activeIndex === index && props.children.length > 1}
				key={index}
				clickHandler={() => {
					if(!props.disableAllTabsButActive) setActiveIndex(index);
				}}
			>
				{tab.props.title}
			</ScTab>
		);

		// add content
		if(activeIndex === index) {
			contents.push(
				<TabContents
					key={index}
					active={activeIndex === index}
					noPadding={props.noPadding}
				>
					{tab.props.children}
				</TabContents>
			);
		}
		
	});

	return (
		<ScContainer>
			<ScTabs hasTabs={tabs.length >= 2 && true}>{tabs}</ScTabs>
			{contents}
		</ScContainer>
	);
};

export default TabContainer;

const ScTab = styled(Tab)`
	${(props) =>
		props.disableTab &&
		css`
			cursor: not-allowed;
			opacity: 0.3;
		`}

	${(props) =>
		props.showPadding &&
		css`
			padding: 8px;
			height: 40px;
		`}
`;

const ScContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const ScTabs = styled.div`
	display: flex;
	border-bottom: 1px solid var(--border-grey-color);
	position: sticky;
	top: 0;
	background: var(--modal-side-bg-color);
	/* width: 416px; */
	z-index: 999;

	${(props) =>
		props.hasTabs &&
		css`
			min-height: 40px;
		`}
`;
