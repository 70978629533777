import React from 'react';
import styled from 'styled-components/macro';
import { CheckItem } from '../../../components/Forms';
import { Icon } from '../../../components/UI';

const AlternativeImage = (props) => {
	return (
		<ScContainer>
			<Icon icon={['fal', 'grip-lines']} />
			<ScImageContainer>
				<ScImage alt="produkt_bild" src={props.thumbnail} />
			</ScImageContainer>
			<ScName>{props.name}</ScName>
			<CheckItem
				type="checkbox"
				style={{ marginBottom: 0 }}
				title="Visa på produktsida"
				changed={props.setEnabledStatus}
				imageType={props.type}
				index={props.index}
				uuid={props.uuid}
				checked={props.isChecked}
			/>
			<Icon
				onClick={() => props.removeImage(props.type, props.uuid)}
				icon={['fal', 'trash']}
			/>
		</ScContainer>
	);
};

export default AlternativeImage;

const ScContainer = styled.div`
	display: flex;
	flex: 1;
	border-bottom: 1px solid #333;
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	cursor: row-resize;
`;

const ScImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	pointer-events: none;
`;

const ScImageContainer = styled.div`
	height: 56px;
	width: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScName = styled.div`
	font-weight: 300;
	font-size: 10px;
`;
