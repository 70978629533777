import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { isEmptyObject, parseStringAsObjectPath } from 'react-utils';
import { all, spread } from 'axios';
import SplitSlider from '../../../components/SplitSlider';
import Tree from '../../../components/Tree/Tree';
import { SkeletonTree } from '../../../components/Skeletons';
import ProductView from '../components/ProductView';
import { Loader } from '../../../components/UI';
import axios from '../../../utils/oc-axios';
import useCommonProduct from '../hooks/useCommonProduct';

const ProductDetails = (props) => {
	// fetches common product methods
	const [addToCategoryHelper] = useCommonProduct();

	// fetches modal
	const { modal } = props;

	// form validation instance from parent
	const validation = props.formValidation;

	// extracts product and categories
	const { product, categories } = modal.currentState;

	const currentCategoryId = modal.currentState.category_id
		? modal.currentState.category_id
		: null;

	// fetched the update state method from modal
	const { updateState } = modal;

	/**
	 * Filters out categories of type 'link'
	 * and return the new categories object
	 * 
	 * @param categories
	 * @returns {object}
	 */
	const removeLinksFromCategories = React.useCallback((categories) => {
		// Select ID's from categories of type 'link'
		const linkIds = Object.values(categories)
							  .filter(category => category.type === 'link')
							  .map(category => category.id);

		// return if there is no links
		if(linkIds.length <= 0) return categories;
		
		// Filter out links
		const categoriesWithoutLinks = Object.entries(categories).reduce((prev, current) => {
			const currentCategory = current[1];
			const key = current[0];

			if(currentCategory.type === 'link') return prev;

			// Remove link ID's from children array
			const children = currentCategory.children.filter(child => !linkIds.includes(child));

			const category = {
				...currentCategory,
				children: children
			};

			return {
				...prev,
				[key]: category
			};
		}, {});

		return categoriesWithoutLinks;
	}, []);

	/**
	 * Since categories of type link should not be assignable to a product
	 * we have to remove them from the category object which will be displayed in 
	 * the tree
	 */
	React.useEffect(() => {
		if(isEmptyObject(categories)) return;

		const categoriesWithoutLinks = removeLinksFromCategories(categories);

		updateState({
			categories: {
				$set: categoriesWithoutLinks
			}
		});
		
	}, [categories, removeLinksFromCategories, updateState]);

	/**
	 * If product or categories is not set then reach out to server and get them
	 */
	React.useEffect(() => {
		if(!product.base || isEmptyObject(categories)) {
			// dynamically adds product and categories requests to axios
			const requests = [axios.get(`modules/products/${product.number}`)];
			if(isEmptyObject(categories))
				requests.push(axios.get('navigations/trees/categories'));

			// axios.all is equilevent to Promise.all
			all(requests).then(
				spread((prod, cats) => {
					const updatedCategoriesTree = cats ? cats.data : categories;
					let updatedProductCategories = prod.data.categories;

					const categoriesWithoutLinks = removeLinksFromCategories(updatedCategoriesTree);

					// if a product has been added through category block
					// then add it to it's appropriate categories
					if(
						currentCategoryId &&
						!updatedProductCategories.includes(currentCategoryId)
					) {
						updatedProductCategories = addToCategoryHelper(
							updatedProductCategories,
							categoriesWithoutLinks,
							currentCategoryId
						);
					}

					updateState({
						product: {
							$merge: {
								...modal.currentState.product,
								...prod.data,
								categories: updatedProductCategories
							},
							$unset: ['product']
						},
						categories: {
							$set: categoriesWithoutLinks
						}
					});
				})
			);
		}
	}, [categories, product.base, product.number, modal.currentState, updateState, product.categories, currentCategoryId, addToCategoryHelper, removeLinksFromCategories]);

	/**
	 * Handles the updating of individual fields
	 *
	 * @param {Event} ev
	 * @param {object} input
	 */
	const inputChangedHandler = useCallback(
		(ev, input) => {
			const inputValue = ev.target.value;
			const inputName = input.name;

			if(inputName) {
				updateState({
					product: {
						...parseStringAsObjectPath(inputName, inputValue)
					}
				});
			}
		},
		[updateState]
	);

	/**
	 * Will add a new video set (prodider + video_id)
	 */
	const addVideoHandler = useCallback(() => {
		const video = {
			provider: 'youtube',
			video_id: ''
		};

		updateState({
			product: {
				videos: {
					$push: [video]
				}
			}
		});
	}, [updateState]);

	/**
	 * Will trigger a state update for a specific change in a video set
	 *
	 * @param {Event} ev
	 * @param {object} input
	 */
	const editVideoHandler = useCallback(
		(ev, inputProps) => {
			const inputValue = ev.target.value;
			const inputName = inputProps.name;
			const videoIndex = inputProps.index;

			updateState({
				product: {
					videos: {
						[videoIndex]: {
							[inputName]: {
								$set: inputValue
							}
						}
					}
				}
			});
		},
		[updateState]
	);

	/**
	 * Updates main image
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const setMainImageHandler = useCallback(
		(ev, item) => {
			const currentMainImage = Object.values(product.images).find(
				(image) => image.main
			);

			const primaryImage = product.images[item.uuid].media;

			updateState({
				product: {
					images: {
						[currentMainImage.uuid]: {
							main: {
								$set: false
							}
						},
						[item.uuid]: {
							main: {
								$set: true
							}
						}
					},
					primary_image: {
						$set: primaryImage
					},
					thumbnail: {
						$set: primaryImage.thumbnail
					}
				}
			});
		},
		[product.images, updateState]
	);

	/**
	 * Will remove an image
	 *
	 * @param {string} key
	 * @param {int} imageIndex
	 */
	const removeImageHandler = useCallback(
		(key = '', imageUuid) => {
			const imageIndex = product.image_connections[key].findIndex(
				(uuid) => uuid === imageUuid
			);

			updateState({
				product: {
					image_connections: {
						[key]: {
							$splice: [[imageIndex, 1]]
						}
					},
					images: {
						$unset: [imageUuid]
					}
				}
			});
		},
		[product.image_connections, updateState]
	);

	/**
	 * Will remove the selected video set
	 *
	 * @param {Event} ev
	 * @param {int} index
	 */
	const removeVideoHandler = useCallback(
		(ev, index) => {
			updateState({
				product: {
					videos: {
						$splice: [[index, 1]]
					}
				}
			});
		},
		[updateState]
	);

	/**
	 * Handles the checking of categories in tree
	 *
	 * @param {Event} ev
	 * @param {object} props
	 */
	const addToCategoryHandler = useCallback(
		(ev, itemProps) => {
			const categoryId = itemProps.id;
			const updatedState = addToCategoryHelper(
				product.categories,
				categories,
				categoryId
			);

			updateState({
				product: {
					categories: {
						$set: updatedState
					}
				}
			});
		},
		[addToCategoryHelper, categories, product.categories, updateState]
	);

	/**
	 * Sets a color on an article
	 *
	 * @param {Event} ev
	 * @param {string} uuid
	 */
	const setArticleColorHandler = useCallback(
		(ev, uuid) => {
			const target = ev.target;
			const colorCode = target.value;
			const selectedIndex = target.selectedIndex;
			const selectedOption = target.options[selectedIndex];
			const selectedOptionData = selectedOption.dataset;

			if('pattern' in selectedOptionData) {
				switch(colorCode) {
					case '-1':
						return updateState({
							product: {
								images: {
									[uuid]: {
										pattern_uuid: {
											$set: null
										},
										true_color: {
											$set: null
										}
									}
								}
							}
						});

					default:
						return updateState({
							product: {
								images: {
									[uuid]: {
										pattern_uuid: {
											$set: colorCode
										},
										true_color: {
											$set: colorCode
										}
									}
								}
							}
						});
				}
			}

			switch(colorCode) {
				case '-1':
					return updateState({
						product: {
							images: {
								[uuid]: {
									color_code: {
										$set: null
									},
									pattern_uuid: {
										$set: null
									},
									true_color: {
										$set: null
									}
								}
							}
						}
					});

				default:
					return updateState({
						product: {
							images: {
								[uuid]: {
									color_code: {
										$set: colorCode
									},
									pattern_uuid: {
										$set: null
									},
									true_color: {
										$set: colorCode
									}
								}
							}
						}
					});
			}
		},
		[updateState]
	);

	/**
	 * Will set the enabled to true/false on detail/environmental images
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const setEnabledStatusHandler = useCallback(
		(ev, item) => {
			const currentStatus = product.images[item.uuid].enabled;

			updateState({
				product: {
					images: {
						[item.uuid]: {
							enabled: {
								$set: !currentStatus
							}
						}
					}
				}
			});
		},
		[product.images, updateState]
	);

	/**
	 * Handles state updates from final dnd event sorting images
	 *
	 * @param {*} source
	 * @param {*} destination
	 * @param {*} instruction
	 */
	const dragEndHandler = (source, destination, instruction) => {
		if(!source || !destination) return;

		const sourceIndex = source.properties.index;
		const sourceUuid = source.properties.uuid;
		const destinationIndex = destination.properties.index;

		let adjustedIndex = destinationIndex;

		switch(instruction.position) {
			case 'bottom':
				if(destinationIndex + instruction.adjustIndex === sourceIndex)
					return;
				break;

			case 'top':
				if(
					sourceIndex + instruction.adjustIndex ===
					destinationIndex - 1
				)
					return;

				if(destinationIndex > sourceIndex) adjustedIndex--;
				break;

			default:
		}

		updateState({
			product: {
				image_connections: {
					[source.properties.type]: {
						$splice: [
							[sourceIndex, 1],
							[adjustedIndex, 0, sourceUuid]
						]
					}
				}
			}
		});
	};

	// re-render tree only when necessary
	const tree = useMemo(
		() => (
			<Tree
				scope="categories"
				payload={categories}
				skeleton={<SkeletonTree />}
				preExpanded={['r6'].concat(product.categories)}
				useCheckboxes={addToCategoryHandler}
				checked={product.categories}
				stackableIcons={stackableIcons}
			/>
		),
		[addToCategoryHandler, categories, product.categories]
	);

	// while waiting for data retrival show a loader
	let out = <Loader />;

	// keep the above loader showing until data is fetched
	if(product.base && !isEmptyObject(categories) && !props.isSaving) {
		out = (
			<SplitSlider axis="horizontal">
				<ScSide
					key="side_A"
					size={20}
					minSize={10}
				>
					{tree}
				</ScSide>
				<ScMain
					size={80}
					minSize={70}
				>
					{isEmptyObject(product) ? (
						'Loading...'
					) : (
						<ProductView
							formValidation={validation}
							details={product}
							changed={inputChangedHandler}
							addVideo={addVideoHandler}
							removeVideo={removeVideoHandler}
							editVideo={editVideoHandler}
							setMainImage={setMainImageHandler}
							removeImage={removeImageHandler}
							setArticleColor={setArticleColorHandler}
							setEnabledStatus={setEnabledStatusHandler}
							dragEndHandler={dragEndHandler}
						/>
					)}
				</ScMain>
			</SplitSlider>
		);
	}

	return (
		<ScContainer>
			{out}
		</ScContainer>
	);
};

export default ProductDetails;

const stackableIcons = {
	stacked: {
		collapsed: ['fal', 'tags'],
		expanded: ['fas', 'tags']
	},
	single: ['fal', 'tag']
};

const ScContainer = styled.section`
	position: relative;
	width: 100%;
	height: calc(100% - 55px);
`;

const ScSide = styled.div`
	/* min-width: 308px; */
	height: 100%;
	overflow-y: auto;
`;

const ScMain = styled.div`
	background-color: #fff;
	overflow-y: auto;
	height: 100%;
`;
