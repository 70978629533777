import React from 'react';
import styled, { css } from 'styled-components/macro';

import Dragable from '../../hoc/DragDrop/Draggable';
import { Icon } from '../UI';

const Header = (props) => {
	// Create additional icons
	// if a middleware parameter is set to the opts declaration then
	// the middleware will be called first and may change original data or add more parameters
	let opts = null;
	if (Array.isArray(props.opts) && props.opts.length > 0) {
		opts = props.opts.map((opt, index) => {
			const hasPermission =
				'hasPermission' in opt ? opt.hasPermission : true;

			return (
				<ScIconWrapper isDisabled={!hasPermission} key={`opt_${index}`}>
					<Icon
						icon={opt.icon}
						onClick={(ev) => {
							if (!hasPermission) return;

							ev.stopPropagation();
							if (
								!opt.middleware &&
								!props.middlewares[opt.middleware]
							) {
								return opt.action(ev, opt, props);
							} else {
								return props.middlewares[opt.middleware](
									ev,
									opt,
									{
										parent_id: null,
										rootNavigationId:
											props.item.rootNavigationId,
										...props,
									},
									opt.action
								);
							}
						}}
						{...opt.props}
					/>
				</ScIconWrapper>
			);
		});
	}

	return (
		<ScContainer disabled scope={props.scope} itemType="header">
			<ScTitle>{props.item.label}</ScTitle>
			<ScIconAction>
				{opts}
				{props.item.children.length > 0 && (
					<ScIconWrapper>
						<Icon
							onClick={(ev) =>
								props.toggleExpand(ev, props.item.id)
							}
							icon={[
								'fal',
								props.isExpanded
									? 'chevron-up'
									: 'chevron-down',
							]}
						/>
					</ScIconWrapper>
				)}
			</ScIconAction>
		</ScContainer>
	);
};

export default Header;

const ScContainer = styled(Dragable)`
	align-items: center;
	color: var(--font-dark-grey-color);
	display: flex;
	font-weight: 600;
	line-height: 40px;
	justify-content: space-between;
	padding: 0 16px;
	padding: 0 8px 0 16px;
	user-select: none;

	/* :hover {
		background-color: var(--bg-grey-color);

		.isDragging {
			background-color: transparent;
		}
	} */
`;

const ScIconAction = styled.div`
	cursor: pointer;
	display: flex;
`;

const ScTitle = styled.div`
	color: var(--font-dark-grey-color);
	font-weight: 300;
	padding: 8px 0;
`;

const ScIconWrapper = styled.div`
	margin-right: 8px;
	${(props) =>
		props.isDisabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;
