import React from 'react';
import styled from 'styled-components/macro';

const PageContentWrapper = (props) => {
	return <ScPageContentWrapper>{props.children}</ScPageContentWrapper>;
};

const ScPageContentWrapper = styled.div`
	position: relative;
	flex-direction: column;
	margin: 16px;
	width: 100%;
	overflow-y: auto;
	padding: 40px;

	background: var(--bg-bright-color);
`;

export default PageContentWrapper;
