import React from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import styled from 'styled-components/macro';

export default (props) => {
	const settings = {
		enter: {
			animation: 'slideDown',
			duration: 200,
		},
		leave: {
			animation: 'slideUp',
			duration: 200,
		},
	};

	return (
		<VelocityTransitionGroup style={{ width: '100%' }} {...settings}>
			{props.expand ? (
				<ExpansionWrapper>{props.children}</ExpansionWrapper>
			) : null}
		</VelocityTransitionGroup>
	);
};

const ExpansionWrapper = styled.div`
	width: 100%;
`;
