import React, { useState, useRef } from 'react';
import update from 'immutability-helper';
import { isEmptyObject } from 'react-utils';
import ExpansionWrapper from './ExpansionWrapper';
import Header from './Header';
import Item from './Item';
import {
	storeInFolder,
	addToEmptyCategory,
	changePosition,
	storeInStack
} from './stateManager';
import Dropzone from '../UI/Dropzone';
import Draggable from '../../hoc/DragDrop/Draggable';
import DragDrop from '../../hoc/DragDrop/DragDrop';
import withScrollIntoView from '../../hoc/withScrollIntoView';

const Tree = (props) => {
	const { expansionCallback, dblClicked, dragStart, dragMove, dragOver, dragOut, payload, dragEnd, inputCallback } = props;
	
	// keeps track of which root navigation category
	// a drag operation occurs from sections can e.g.
	// be temporarily disabled or highlighted
	const [
		isDraggingFromRootCategory,
		setIsDraggingFromRootCategory
	] = useState(null);

	// handles and keeps track of the expanded sections
	const [expandedItems, setExpandedItems] = useState(
		Array.isArray(props.preExpanded) ? props.preExpanded : []
	);

	const isInputCallbackTriggered = useRef(false);

	// that keeps track of whether to expand or not
	const expandTimer = useRef(null);

	// check so that we have a payload
	if(!props.payload && !isEmptyObject(props.payload)) {
		throw Error('[Tree] expects a valid "payload" of type object');
	}

	// check so that the scope is of type string
	if(!props.scope || typeof props.scope !== 'string') {
		throw Error('[Tree] expects a valid "scope" to be of type string');
	}

	// check so that highlighted, if set, is an array
	if(props.highlighted && !Array.isArray(props.highlighted)) {
		throw Error('[Tree] expects "highlighted" to be an array of ids/uuids');
	}

	// check so that the inputCallback is a fn if declared
	if(props.inputCallback && typeof props.inputCallback !== 'function') {
		throw Error('[Tree] expects "inputCallback" to be a function');
	}

	// check so that the inputCallback is a fn if declared
	if(
		props.expansionCallback &&
		typeof props.expansionCallback !== 'function'
	) {
		throw Error('[Tree] expects "expansionCallback" to be a function');
	}

	// check so that clicked is of type function
	if(props.clicked && typeof props.clicked !== 'function') {
		throw Error('[Tree] expects "clicked" to be a function');
	}

	// check so that dblClicked is of type function
	if(props.dblClicked && typeof props.dblClicked !== 'function') {
		throw Error('[Tree] expects "dblClicked" to be a function');
	}

	// check so that dragEnd is a declared function in case dnd is enabled
	if(
		props.enableDrag &&
		(!props.dragEnd || typeof props.dragEnd !== 'function')
	) {
		throw Error(
			'[Tree] expects "dragEnd" to be a function to activate drag and drop functionality'
		);
	}

	// check so that dragStart is of type function if set
	if(props.dragStart && typeof props.dragStart !== 'function') {
		throw Error('[Tree] expects "dragStart" to be of type "function"');
	}

	// check so that dragMove is of type function if set
	if(props.dragMove && typeof props.dragMove !== 'function') {
		throw Error('[Tree] expects "dragMove" to be of type "function"');
	}

	// check so that dragOut is of type function if set
	if(props.dragOut && typeof props.dragOut !== 'function') {
		throw Error('[Tree] expects "dragOut" to be of type "function"');
	}

	// check so that dragOver is of type function if set
	if(props.dragOver && typeof props.dragOver !== 'function') {
		throw Error('[Tree] expects "dragOver" to be of type "function"');
	}

	// check so that opts are of type array
	if(props.opts && !Array.isArray(props.opts)) {
		throw Error('[Tree] expects "opts" to be an array of objects');
	}

	// if set the icon will be removed and replaced by a checkbox
	if(props.useCheckboxes && typeof props.useCheckboxes !== 'function') {
		throw Error('[Tree] expects a useCheckboxes to be of type "function"');
	}

	// if set the icon will be removed and replaced by a checkbox
	if(props.stackableIcons && typeof props.stackableIcons !== 'object') {
		throw Error('[Tree] expects a stackableIcons to be of type "object"');
	}

	React.useEffect(() => {
		if(Array.isArray(props.preExpanded)) {
			setExpandedItems((prevState) => {
				if(props.collapsePreviouslyExpanded)
					// get rid of duplicate values in array
					return [...new Set(props.preExpanded)];
				// get rid of duplicate values in array
				else return [...new Set(prevState.concat(props.preExpanded))];
			});
		}
	}, [props.collapsePreviouslyExpanded, props.preExpanded]);

	/**
	 * Helper function to check whether an item is expanded or not
	 *
	 * @param {int|string} id
	 */
	const isExpanded = React.useCallback((id) => {
		return expandedItems.includes(id);
	}, [expandedItems]);

	/**
	 * Collapses an item
	 *
	 * @param {int|string} id
	 */
	const collapse = React.useCallback((id) => {
		const itemIndex = expandedItems.indexOf(id);
		const updatedState = update(expandedItems, {
			$splice: [[itemIndex, 1]]
		});

		setExpandedItems(updatedState);

		if(
			expansionCallback &&
			typeof expansionCallback === 'function'
		) {
			expansionCallback(id, 'collapsed');
		}
	}, [expandedItems, expansionCallback]);

	/**
	 * Expands an item
	 *
	 * @param {int|string} id
	 */
	const expand = React.useCallback((id) => {
		const updatedState = update(expandedItems, {
			$push: [id]
		});
		setExpandedItems(updatedState);

		if(
			expansionCallback &&
			typeof expansionCallback === 'function'
		) {
			expansionCallback(id, 'expanded');
		}
	}, [expandedItems, expansionCallback]);

	/**
	 * Clears the timer that regulates whether or not to expand a folder
	 */
	const clearExpansionTimeout = React.useCallback(() => {
		// and update the visual the tree
		clearTimeout(expandTimer.current);
		expandTimer.current = null;
	}, []);

	/**
	 * Handles the state whether a ul is collapsed or not
	 *
	 * @param {Event} ev
	 * @param {string|string} id
	 */
	const toggleExpandHandler = React.useCallback((ev, id) => {
		ev.stopPropagation();

		switch(isExpanded(id)) {
			case true:
				collapse(id);
				break;

			default:
				expand(id);
		}
	}, [collapse, expand, isExpanded]);

	/**
	 * Opens folders on dblClick
	 * Will also trigger a callback if one was provided by props
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const dblClickHandler = React.useCallback((ev, item) => {
		if(item.type === 'folder') toggleExpandHandler(ev, item.id);

		if(dblClicked && typeof dblClicked === 'function') {
			dblClicked(ev, item);
		}
	}, [dblClicked, toggleExpandHandler]);

	/**
	 * Drag start callback
	 * Will only trigger callback to parent component if set
	 *
	 * @param {object} source
	 * @param {object} destination
	 * @param {Event} event
	 */
	const dragStartHandler = React.useCallback((source, destination, event) => {
		if(dragStart && typeof dragStart === 'function')
			dragStart(source, destination, event);
	}, [dragStart]);

	/**
	 * Drag move callback
	 * Will only trigger callback to parent component if set
	 *
	 * @param {object} source
	 * @param {object} destination
	 * @param {Event} event
	 */
	const dragMoveHandler = React.useCallback((source, destination, event) => {
		if(!isDraggingFromRootCategory) {
			setIsDraggingFromRootCategory(source.properties.rootNavigationId);
		}

		if(dragMove && typeof dragMove === 'function')
			dragMove(source, destination, event);
	}, [dragMove, isDraggingFromRootCategory]);

	/**
	 * Drag over callback
	 * Used to expand collapsed item to make them available for drop
	 *
	 * @param {object} source
	 * @param {object} destination
	 * @param {Event} event
	 */
	const dragOverHandler = React.useCallback((source, destination, event) => {
		if(source.properties.scope !== destination.properties.scope) return;

		const { isExpanded, itemId, amountChildren } = destination.properties;

		if(!isExpanded && amountChildren > 0) {
			expandTimer.current = setTimeout(() => {
				expand(itemId);
			}, 700);
		}

		if(dragOver && typeof dragOver === 'function')
			dragOver(source, destination, event);
	}, [expand, dragOver]);

	/**
	 * Drag out callback
	 * Used to kill the timer that is set on dragOver
	 *
	 * @param {object} source
	 * @param {object} destination
	 * @param {Event} event
	 */
	const dragOutHandler = React.useCallback((source, destination, event) => {
		clearExpansionTimeout();

		if(dragOut && typeof dragOut === 'function')
			dragOut(source, destination, event);
	}, [clearExpansionTimeout, dragOut]);

	/**
	 * Triggered on dragEnd (mouseUp) to update the state of the tree
	 *
	 * @param {object} source
	 * @param {object} destination
	 * @param {object} instruction
	 * @param {Event} ev
	 */
	const dragEndHandler = React.useCallback(async (source, destination, instruction, ev) => {
		setIsDraggingFromRootCategory(null);

		if(!source || !destination || !instruction) return;

		const sourceProperties = source.properties;
		const destinationProperties = destination.properties;
		let updateInstructions = {};

		if(sourceProperties.scope !== destinationProperties.scope) return;

		// dropped straight on a stackable navItem
		if(
			source.properties.isStackable &&
			destination.properties.isStackable &&
			!instruction.action
		) {
			updateInstructions = storeInStack(
				payload,
				sourceProperties,
				destinationProperties
			);
		}
		// dropped straight on a folder
		else if(
			destinationProperties.itemType === 'folder' &&
			!instruction.isParentToHoveredElement &&
			!instruction.action
		) {
			updateInstructions = storeInFolder(
				payload,
				sourceProperties,
				destinationProperties
			);
		}
		// if dropped on a dropzone	(only appears for categories with no items assigned)
		else if(destinationProperties.itemType === 'dropzone') {
			updateInstructions = addToEmptyCategory(
				payload,
				sourceProperties,
				destinationProperties
			);
			// change the position of a dragged item
		} else if(
			!isEmptyObject(instruction) &&
			!('disabled' in destinationProperties) &&
			!instruction.isParentToHoveredElement
		) {
			updateInstructions = changePosition(
				payload,
				sourceProperties,
				destinationProperties,
				instruction
			);
		}
		if(isEmptyObject(updateInstructions)) return;

		clearExpansionTimeout();

		if(dragEnd && typeof dragEnd === 'function')
			dragEnd(
				updateInstructions,
				source,
				destination,
				instruction,
				ev
			);
	}, [clearExpansionTimeout, dragEnd, payload]);

	/**
	 * Acts a middleware that a parent component can call within the "opts"
	 * It will calculate the position of the a suggested state for where an input
	 * should be added and then add the suggested callback as a param to the original cb fn
	 *
	 * This function does not change or manipulate the original data
	 *
	 * @param {Event} ev
	 * @param {object} opt
	 * @param {object} fullProps
	 * @param {function} action
	 */
	const middlewareAddInput = React.useCallback((ev, opt, fullProps, action) => {
		if(payload.add) return;

		const input = {
			// properties are NOT camel case because this object simulates a response from the back-end
			add: {
				id: 'add',
				children: [],
				parent_reference: fullProps.item.id,
				rootNavigationId: fullProps.rootNavigationId,
				template_id: fullProps.item.template_id,
				is_child: !fullProps.item.id.toString().includes('r'),
				type: opt.type,
				input: true
			}
		};

		const suggestedState = update(payload, {
			$merge: input,
			[fullProps.item.id]: {
				children: { $push: ['add'] }
			}
		});

		expand(fullProps.item.id);
		action(ev, opt, suggestedState, fullProps);
	}, [expand, payload]);

	/**
	 * Makes sure that callbacks on keyPress "Enter" and "Blur" cannot happen at the same time
	 */
	const inputCallbackRefHandler = React.useCallback(() => {
		isInputCallbackTriggered.current = true;

		setTimeout(() => {
			isInputCallbackTriggered.current = false;
		}, 2000);
	}, []);

	/**
	 * By bluring or pressing "Enter" you will actually save the new item
	 * If nothing is typed into the input the new item will be stored as "New item"
	 *
	 * @param {Event} ev
	 * @param {DOMElement} input
	 * @param {string} rootNavId
	 */
	const inputDoneHandler = React.useCallback((ev, input, rootNavId) => {
		let text = 'New item';
		if(ev.target.value) text = ev.target.value;

		if(inputCallback && typeof inputCallback === 'function' && !isInputCallbackTriggered.current) {
			inputCallbackRefHandler();
			inputCallback(ev, input, text, rootNavId);
		}
	}, [inputCallback, inputCallbackRefHandler]);

	/**
	 * Check if is the last item of a parent
	 *
	 * @param {int|string} parentReference
	 */
	const isParentLastInScope = React.useCallback((parentReference) => {
		const parent = props.payload[parentReference];

		const grandParent = props.payload[parent.parent_reference];

		if(!grandParent) return false;
		else
			return (
				grandParent.children.length - 1 ===
				grandParent.children.indexOf(parent.id)
			);
	}, [props.payload]);

	/**
	 * Check if parent is located at root
	 *
	 * @param {int|string} parentReference
	 */
	const isParentInRootLevel = React.useCallback((parentReference) => {
		const parent = props.payload[parentReference];

		if(typeof parent.parent_reference !== 'string') return false;
		else return parent.parent_reference.includes('r');
	}, [props.payload]);

	/**
	 * Creates a navigation header and the first ul
	 * Then it renders it's navigation items
	 * This function is called only once
	 *
	 * @param {object} items
	 */
	const renderTreeCategories = React.useCallback((items) => {
		// filter out categories and sort them accordingly to sort_order
		return Object.values(items)
			.filter((item) => 'is_root' in item && item.is_root === true)
			.sort((a, b) => a.sort_order - b.sort_order)
			.map((item) => {
				const rootNavigationId = `r${item.id}`;
				const children = item.children.map(
					(item) => props.payload[item]
				);

				const updatedHeaderItem = {
					...item,
					id: rootNavigationId,
					parent_reference: rootNavigationId,
					rootNavigationId: rootNavigationId
				};

				const restrictions = {
					isolated: item.isolated,
					multiple: item.multiple,
					stackable: item.stackable,
					dnd: item.dnd
				};

				const enableDrop = dragShouldBeEnabled(
					rootNavigationId,
					restrictions.isolated
				);

				return (
					<div key={`category_${rootNavigationId}`}>
						{!props.hideHeaders && (
							<Header
								scope={props.scope}
								item={updatedHeaderItem}
								opts={props.headerOpts}
								toggleExpand={toggleExpandHandler}
								isExpanded={expandedItems.includes(
									rootNavigationId
								)}
								middlewares={{
									middlewareAddInput: middlewareAddInput
								}}
							/>
						)}
						<div key={`menu_${item.name}_${rootNavigationId}`}>
							{children.length > 0 && (
								<ExpansionWrapper
									key={`rootmenu_${rootNavigationId}`}
									expand={isExpanded(rootNavigationId)}
								>
									{renderTreeItems(
										children,
										restrictions,
										rootNavigationId
									)}
								</ExpansionWrapper>
							)}
							{!restrictions.isolated && children.length === 0 && (
								<Draggable
									itemType="dropzone"
									navId={rootNavigationId}
									scope={props.scope}
									enableDrag={enableDrop}
									disabled
								>
									<Dropzone
										enabled={enableDrop}
										text={item.description}
									/>
								</Draggable>
							)}
						</div>
					</div>
				);
			});
	}, [expandedItems, isExpanded, middlewareAddInput, props.headerOpts, props.hideHeaders, props.payload, props.scope, renderTreeItems, toggleExpandHandler]);

	/**
	 * Receives the children and loops them out
	 * It will figure out if a child is also in fact a parent
	 *
	 * @param {object} items
	 * @param {object} restrictions
	 * @param {string} rootNavigationId
	 */
	const renderTreeItems = React.useCallback((items, restrictions = null, rootNavigationId = null) => {
		return items.map((item, index) => {
			if(!item) return;

			const childIds = 'children' in item ? item.children : void 0;
			let children = [];

			if(childIds !== void 0) {
				children = childIds.map((item) => props.payload[item]);
			}

			// if the enableDrag prop is set to true
			// this check will determine whether items under a certain
			// root category are isolated and thus should be disabled from
			// interaction or not
			const dragIsEnabled = dragShouldBeEnabled(
				props.payload,
				props.enableDrag,
				isDraggingFromRootCategory,
				rootNavigationId,
				restrictions.isolated,
				restrictions.dnd
			);

			// in case the disabledItems prop is used
			// then disable the items in the array identified by either uuids or ids
			// OR
			// in case the enableDrag prop is set but an item should be temporarily disabled
			// due to the above check ("dragIsEnabled") then disable the item
			let isDisabled =
				(props.disabledItems &&
					(props.disabledItems.includes(item.id) ||
						props.disabledItems.includes(item.uuid))) ||
				(props.enableDrag && !dragIsEnabled);

			// Fix to handle root categories with dnd restrictions
			// Allow items to be clickable and look normal until something is dragged
			if(!isDraggingFromRootCategory && !restrictions.dnd) {
				isDisabled = false;
			}

			// in case the highlighted prop is used
			// then items in the array identified by either uuids or ids will be highlighted
			const isHighlighted =
				props.highlighted &&
				(props.highlighted.includes(item.id) ||
					props.highlighted.includes(item.uuid));

			// if the useCheckboxes prop is provided this check
			// will determine whether a checkbox should be checked or not
			const isChecked =
				props.checked &&
				(props.checked.includes(item.id) ||
					props.checked.includes(item.uuid));

			// Checks if the parent is the last one it's scope
			// used to successfully draw the lines between the items
			const parentLastInScope = isParentLastInScope(
				item.parent_reference
			);

			// Checks if the parent is on root level
			// used to successfully draw the lines between the items
			const parentRootLevel = isParentInRootLevel(item.parent_reference);

			// Item is marked as hidden if it doesn't have a translated version for the selected language
			if(item.is_hidden) return null;

			return (
				<React.Fragment key={`navItem_${item.id}`}>
					<Item
						key={item.uuid}
						scope={props.scope}
						index={index}
						id={item.id}
						item={item}
						opts={props.opts}
						enableDrag={dragIsEnabled}
						stackableIcons={props.stackableIcons}
						isStackable={item.type === 'link' ? false : restrictions.stackable}
						isHighlighted={isHighlighted}
						isChecked={isChecked}
						isInput={'input' in item}
						isDisabled={isDisabled}
						isInactive={'is_translated' in item && !item.is_translated}
						isParentLastInScope={parentLastInScope}
						isParentInRootLevel={parentRootLevel}
						isExpanded={isExpanded(item.id)}
						isLoading={item.isLoading}
						selectMultiple={restrictions.multiple}
						useCheckboxes={props.useCheckboxes || false}
						highlightedCheckboxEffect={
							props.highlightedCheckboxEffect
						}
						scrollTo={props.scrollTo}
						rootNavigationId={rootNavigationId}
						toggleExpand={toggleExpandHandler}
						newItemIsBeingCreated={'add' in props.payload}
						middlewares={{ middlewareAddInput: middlewareAddInput }}
						inputDoneEditing={inputDoneHandler}
						clicked={props.clicked}
						dblClicked={dblClickHandler}
					>
						{children.length > 0 &&
							renderSubTree(
								children,
								item.id,
								rootNavigationId,
								restrictions
							)}
					</Item>
				</React.Fragment>
			);
		});
	}, [dblClickHandler, inputDoneHandler, isDraggingFromRootCategory, isExpanded, isParentInRootLevel, isParentLastInScope, middlewareAddInput, props.checked, props.clicked, props.disabledItems, props.enableDrag, props.highlighted, props.highlightedCheckboxEffect, props.opts, props.payload, props.scope, props.scrollTo, props.stackableIcons, props.useCheckboxes, renderSubTree, toggleExpandHandler]);

	/**
	 * Creates a new ul and passes the received children on to renderMenuItems
	 * This way we can in fact create a completely recursive tree
	 *
	 * @param {array} children
	 * @param {int|string} parent
	 * @param {string} rootNavigationId
	 * @param {object} restrictions
	 */
	const renderSubTree = React.useCallback((children, parent, rootNavigationId, restrictions) => {
		return (
			<ExpansionWrapper
				key={`submenu_${parent}`}
				expand={isExpanded(parent)}
			>
				{renderTreeItems(children, restrictions, rootNavigationId)}
			</ExpansionWrapper>
		);
	}, [isExpanded, renderTreeItems]);

	// if a skeleton prop was provided, then render that
	// a skeleton may be a for example a string, a component or any other
	// renderable type
	if(props.skeleton && (isEmptyObject(props.payload)) || props.isLoading) {
		return props.skeleton;
	} else {
		//Returns the rendered navigation tree
		return (
			<DragDrop
				scope={props.scope}
				onDragStart={dragStartHandler}
				onDragMove={dragMoveHandler}
				onDragOver={dragOverHandler}
				onDragOut={dragOutHandler}
				onDragEnd={dragEndHandler}
			>
				{renderTreeCategories(props.payload)}
			</DragDrop>
		);
	}
};

export default withScrollIntoView(Tree);

/**
 * Will determine whether a root category and it's children
 * should be disabled from a drag and drop action
 *
 * @param {object} payload
 * @param {boolean} enableDrag
 * @param {string} isDraggingFromRootCategory
 * @param {string} rootNavigationId
 * @param {boolean} isIsolated
 */

const dragShouldBeEnabled = (
	payload,
	enableDrag,
	isDraggingFromRootCategory,
	rootNavigationId,
	isIsolated,
	rootCategoryDnd
) => {
	// if drag and drop is enabled
	if(enableDrag) {
		switch(true) {
			case !rootCategoryDnd:
				return false;

			// if the root category is isolated
			case isIsolated:
				// if we are dragging an item
				if(isDraggingFromRootCategory) {
					// if we are dragging an item within an isolated root category
					// then enable drag and drop for that category and disable for others
					return rootNavigationId === isDraggingFromRootCategory;
					// otherwise just return the default drag settings
				} else return enableDrag;

			// if we are dragging an item and the dragged item belongs to
			// an isolated root category, then enable dragging within that category
			case isDraggingFromRootCategory &&
				payload[isDraggingFromRootCategory].isolated:
				return rootNavigationId === isDraggingFromRootCategory;

			// otherwise just return the defauly drag and drop settings
			default:
				return enableDrag;
		}
		// if enableDrag is not set or is false then just return it
	} else return enableDrag;
};

/**
 * Helper function to find parents of a category all the way to root level
 *
 * @param {object} tree
 * @param {int|string} id
 * @param {array} items
 */
export const identifyRelationsUpwards = (treeData, id, items = []) => {
	const item = treeData[id];
	const parentId = item.parent_reference;
	const parentItem = treeData[parentId];

	if(!parentItem.is_root) {
		items = items.concat(parentId);
		return identifyRelationsUpwards(treeData, parentId, items);
	} else {
		return items;
	}
};

/**
 * Helper function to recursivily find all children of a parent
 *
 * @param {object} categories
 * @param {array} children
 * @param {array} items
 */
export const identifyRelationsDownwards = (treeData, children, items = []) => {
	const hasChildren = (itemId) => {
		return treeData[itemId].children.length > 0;
	};

	const getChildren = (itemId) => {
		treeData[itemId].children.forEach((childId) => {
			if(hasChildren(childId)) getChildren(childId);
			items = items.concat(childId);
		});
	};

	children.forEach((itemId) => {
		items = items.concat(itemId);
		if(hasChildren(itemId)) getChildren(itemId);
	});

	return items;
};
