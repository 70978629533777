import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { FilterDates } from './SearchStatistics.types';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import useSearchStatistics from '../hooks/useSearchStatistics';
import { Button, Message } from '../../../components/UI';
import TimeIntervalPicker from '../../../components/TimeIntervalPickers/TimeIntervalPicker';
import { Dates } from '../../../components/TimeIntervalPickers/TimeIntervalPicker.types';
import { SkeletonTable } from '../../../components/Skeletons';

const SearchStatistics: React.FC = () => {
	const today = DateTime.local();
	
	// Use React's useState hook to manage the results per page in the table.
	const [resultsPerPage, setResultsPerPage] = React.useState(10);

	// Initialize the filter date range with the previous month's start and today's date as the end.
	const [filterDates, setFilterDates] = React.useState<FilterDates>({
		from: today.minus({ month: 1 }).toISO(),
		to: today.toISO()
	});

	// Fetch search statistics data using the useSearchStatistics hook.
	const { data: statisticsData, isLoading: isLoadingStatistics, isFetching: isFetchingStatistics, isError: hasStatisticsError, refetch: refetchStatistics } = useSearchStatistics(filterDates);
	const searchStatistics = statisticsData?.searches;

	// Calculate the total number of table pages based on the number of results and results per page.
	const amountPages = React.useMemo(() => {
		if(!searchStatistics) return 0;
		return Math.ceil(searchStatistics.length / resultsPerPage);
	}, [resultsPerPage, searchStatistics]);

	/**
	 * Reset all states to their default values and refetch statistics.
	 * 
	 * @returns {void}
	 */
	const resetModuleHandler = React.useCallback((): void => {
		setFilterDates({
			from: today.minus({ month: 1 }).toISO(),
			to: today.toISO()
		});

		setResultsPerPage(10);
		refetchStatistics();
	}, [refetchStatistics, today]);

	/**
 	* Update the filter date state with selected dates, if they have changed.
 	*
 	* @param {Dates} selectedDates - An object containing selected start and end dates.
 	* @returns {void}
 	*/
	const filterDateHandler = (selectedDates: Dates): void => {
		const { startDate, endDate } = selectedDates;
	  
		if(startDate !== filterDates.from || endDate !== filterDates.to) {
		  setFilterDates({
				from: startDate,
				to: endDate
		  });
		};
	};

	return (
		<ModuleContainer header="Statistik">
			<ErrorBoundary resetted={resetModuleHandler}>
				<ScFilterContainer>
					<TimeIntervalPicker
						initialStartDate={filterDates.from ?? ''}
						initialEndDate={filterDates.to ?? ''}
						buttonText="Filtrera"
						maxEndDate={filterDates.to}
						datesSubmitted={(dates) => filterDateHandler(dates)}
						isDisabled={isLoadingStatistics || isFetchingStatistics}
					/>
				
				</ScFilterContainer>
				<ScTableContainer>
					{!isFetchingStatistics && hasStatisticsError && (
						<ScMessage
							size="16"
							icon={['fal', 'redo']}
							pressed={refetchStatistics}
						>
							Ett fel inträffade, ladda om
						</ScMessage>
					)}

					{!isLoadingStatistics && !searchStatistics && !hasStatisticsError && !isFetchingStatistics && (
						<div>
							Kunde inte hitta någon statistik...
						</div>
					)}
					
					{(isLoadingStatistics || isFetchingStatistics) && <SkeletonTable />}

					{searchStatistics && !hasStatisticsError && !isLoadingStatistics && !isFetchingStatistics && (
						<ScReactTable
							data={searchStatistics}
							columns={columns}
							totalResults={searchStatistics.length}
							hasNativeSorting
							hasNativePagination
							amountPages={amountPages}
							resultsPerPage={resultsPerPage}
							paginationSelectOptions={['10', '25', '50', '100']}
							resultsPerPageChanged={(ev) => {
                        	const value = Number(ev.target.value);
                        	setResultsPerPage(value);
							}}
						/>
					)}
				</ScTableContainer>
			</ErrorBoundary>
		</ModuleContainer>
	);
};

export default React.memo(SearchStatistics);

const columns = [
	{
	  Header: 'Sökfras',
	  accessor: 'search_term'
	},
	{
	  Header: 'Antal sökningar',
	  accessor: 'number_of_searches'
	}
];

const ScTableContainer = styled.div`
	margin-bottom: 24px;
`;

const ScReactTable = styled(ReactTable)`
	margin-top: 16px;
`;

export const ScFilterContainer = styled.div`
	display: flex;
	margin-top: 16px;

	.rdt {
		margin-left: 8px;
		:first-child {
			margin-left: 0;
		}

		&Picker {
			position: absolute;
		}
	}
`;

export const ScButton = styled(Button)`
	margin-left: 8px;
    height: 40px;
    align-self: flex-end;
    margin-bottom: 16px;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;
