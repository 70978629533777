import React from 'react';
import immutable from 'immutable';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Select, Textarea } from '../../../../../Forms';
import TextInput from '../../../../../Forms/TextInput/TextInput';
import LinkSelector from '../../../../../LinkSelector/LinkSelector';
import { BlockFormResult, BlockFormRuleset } from '../../BlockForm.types';
import useModal from '../../../../../../hooks/Modal/useModal';
import { Button, ModalSettingsContainer } from '../../../../../UI';
import MainNavigation from '../../../../../../containers/MainNavigation/MainNavigation';
import { NavItemLink, NavItemPage } from '../../../../../../containers/MainNavigation/MainNavigation.types';
import { CheckItemProps } from '../../../../../Forms/CheckItem/model.CheckItem';
import { TextInputProps } from '../../../../../Forms/TextInput/model.TextInput';
import { TextareaProps } from '../../../../../Forms/Textarea/model.Textarea';
import { ActiveLink, SettingsTabProps } from '../SettingsTab.types';
import { Link } from '../../../../../../definitions/Link';
import Label from '../../../../../Forms/Label/Label';
import { LinksState } from '../../../../../LinkSelector/LinkSelector.types';
import { ValidationTypes } from '../../../../../../hooks/useFormValidation/types';
import { SelectProps } from '../../../../../Forms/Select/model.Select';
import { BASE_URL } from '../../../../../../settings';

const ACTION = {
	Notice: 'Visa notis',
	Redirect: 'Skicka vidare till sida',
	Notice_Redirect: 'Visa notis & Skicka vidare till sida',
	Nothing: 'Inget'
};

const enabledLinkTypes: LinksState = {
	http: {
		value: 'http',
		label: 'Extern',
		placeholder: 'https://',
		icon: ['fal', 'link'],
		validation: ValidationTypes.URL
	}, 
	slug: {
		value: 'slug',
		label: 'Intern'
	}
};

const Functions: React.FC<SettingsTabProps> = (props) => {
	// The modal hook.
	const modal = props?.modal;

	//modal to show internal links
	const linkSelectModal = useModal();

	// An object of key/value pairs of the dynamic params from the current URL
	// used to get the page uuid
	const params = useParams();

	//Updates the form in builder
	const { builderUpdateCbk } = props;

	// Callback to update the modal's state
	// only call it with an object for immutability-helper.
	const updateState = modal?.updateState;
	
	// The block's data.
	const block = props.modal?.currentState.data;

	const formValidation = props.formValidation;

	/**
	 * Get specific key and value of given object, 
	 * if the value in its turn contains the given key and keyValue
	 * 
	 * @param {Record<string, BlockFormRuleset> | Record<string, BlockFormResult>} object
	 * @param {string} key
	 * @param {string} value
	 * @return {[string | null, BlockFormRuleset | BlockFormResult | null]}
	 */
	const getKeyValuePair = React.useCallback((object:  Record<string, BlockFormRuleset> | Record<string, BlockFormResult>, key: string, keyValue: string) => {
		const keyValuePair = Object.entries(object)
			.find(([_key, value]) => value[key] === keyValue);
			
		if(!keyValuePair) return [null, null];

		return keyValuePair;
	}, []);

	// Get key and object of rulset with type feedback
	const [feedbackRulesetKey, feedbackRuleset] = React.useMemo(() => {
		if(!block) return [null, null];

		return getKeyValuePair(block.rulesets, 'type', 'feedback');
	}, [block, getKeyValuePair]);

	//get key and object of result with action redirect
	const [redirectKey, redirectResult] = React.useMemo(() => {
		if(!feedbackRuleset) return [null, null];

		return getKeyValuePair(feedbackRuleset.results, 'action', 'redirect');
	}, [feedbackRuleset, getKeyValuePair]);

	//get key and object of result with action notice
	const [noticeKey, noticeResult] = React.useMemo(() => {
		if(!feedbackRuleset) return [null, null];

		return getKeyValuePair(feedbackRuleset.results, 'action', 'notice');
	}, [feedbackRuleset, getKeyValuePair]);

	//This is used with immutable when updating the redirect result in the block data
	const pathToLink = React.useMemo(() => ['rulesets', `${feedbackRulesetKey}`, 'results', `${redirectKey}`, 'link'], [feedbackRulesetKey, redirectKey]);

	// Get the current link value
	const currentLinkValue = React.useMemo((): ActiveLink | null => {
		if(!redirectResult) return null;
	
		// If the link is a navigation item return the title
		if(redirectResult.link.title) return {
			value: redirectResult.link.title,
			type: 'slug'
		};
	
		// If the link is an external link return the uri
		if(!redirectResult.link.navigation_id && redirectResult.link.uri) return {
			value: redirectResult.link.uri,
			type: 'http'
		};

		return null;
	}, [redirectResult]);
		
	/**
	 * Open a modal with the main menu for selecting a link
	 */
	const openMainMenuSelector = React.useCallback(() => {
		linkSelectModal.open({
			title: 'Välj mål för länk',
			position: 'center',
			hideBackdrop: false,
			isDismissable: true,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (
						originalState: undefined, 
						currentState: Object, 
						closeModal:()=>void
					) => {
						closeModal();
					}
				}
			],
			state: {}
		});
	}, [linkSelectModal]);

	/**
	 * Update modal's state with the selected link
	 * 
	 * @param { NavItemLink | NavItemPage } navItem 
	 * @return {void}
	 */
	const mainMenuItemSelectedHandler = React.useCallback((navItem: NavItemLink | NavItemPage) => {
		//If the navItem is a folder return as you shouldn't be able to add it as a link
		if(navItem.type === 'folder' || navItem.type === 'link') return;
		let link;

		if('slug' in navItem) {
			link = {
				uuid: '',
				uri: navItem.uri,
				title: navItem.slug,
				new_window: false,
				navigation_id: navItem.id
			};
		} else {
			link = {
				uuid: '',
				uri: navItem.uri,
				title: '',
				new_window: false,
				navigation_id: navItem.id
			};
		}

		//update link in the redirect result
		const updatedState = immutable.setIn(block, pathToLink, link);
		
		if(updateState) updateState({ data: { $set: updatedState }});

		formValidation.resetSpecificError('internal-link');

		linkSelectModal.close();
	}, [block, formValidation, linkSelectModal, pathToLink, updateState]);

	/**
	 * Update the notice result's value or button_text in modal's state
	 * 
	 * @param {React.ChangeEvent} ev
	 * @param {TextareaProps | TextInputProps} props
	 * @return {void}
	 */
	const textChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, props: TextareaProps | TextInputProps) => {        
		const value = ev.target.value;
		const { name } = props;

		if(!name) return;
		const keyPath = name.split('.');
		
		const updatedState = immutable.setIn(block, keyPath, value);

		if(updateState) updateState({ data: { $set: updatedState }})
			.then((updatedState) => {
				if(builderUpdateCbk) builderUpdateCbk(updatedState);
			});
		
	}, [block, builderUpdateCbk, updateState]);

	/**
     * Update modal's state with the new link
     * 
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.SyntheticEvent<HTMLSelectElement, Event> | undefined} ev 
     * @param {CheckItemProps | TextInputProps | SelectProps | undefined} props
     * @return {void}
     */
	const linkChangedHandler = React.useCallback((ev?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.SyntheticEvent<HTMLSelectElement, Event>, props?: CheckItemProps | TextInputProps | SelectProps) => {
		let newValue;

		const currentUri = redirectResult.link.uri;
		const currentNewWindow = redirectResult.link.new_window;

		// new link object 
		if(props && ev && 'checked' in ev.target) {
			newValue = {
				uuid: props.name === 'http' && ev.target.value.length < 1 ? '1' : '', //set a temporary value to uuid to prevent <LinkSelector> from closing
				uri: props.name === 'http' ? ev.target.value : currentUri, 
				title: '',  
				new_window: props.name === 'new_window' ? ev.target.checked : currentNewWindow, 
				navigation_id: null
			};
		} else {
			//If props is undefined the function is called from the delete-link icon
			newValue = {
				uuid: '1', //set a temporary value to uuid to prevent <LinkSelector> from closing
				uri: '',
				title: '',
				new_window: false,
				navigation_id: null
			};
		}

		const updatedState = immutable.setIn(block, pathToLink, newValue);
		
		if(updateState) updateState({ data: { $set: updatedState }});

	}, [block, pathToLink, redirectResult.link.new_window, redirectResult.link.uri, updateState]);

	/**
     * Update selected value
     * 
     * @param {React.ChangeEvent<HTMLSelectElement>} ev
     * @return {void}
     */
	const selectChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = ev.currentTarget.value;
		let noticeValue: string|null = '';
		let link: Link = {
			uuid: '1', // set a temporary value to uuid to prevent LinkSelector from closing
			uri: '',
			title: '',
			new_window: false,
			navigation_id: null
		};

		//Remove notice or redirect result if only one of them is selected
		if(selectedValue === ACTION.Notice) {
			//Remove link
			link = {
				uuid: '',
				uri: '',
				title: '',
				new_window: false,
				navigation_id: null
			};

		} else if(selectedValue === ACTION.Redirect) {
			// Remove notice
			noticeValue = null;

		} else if(selectedValue === ACTION.Nothing) {
			// Remove notice
			noticeValue = null;

			// Remove link
			link = {
				uuid: '',
				uri: '',
				title: '',
				new_window: false,
				navigation_id: null
			};
		}

		// Update redirect
		const updatedRedirect = immutable.setIn(block, pathToLink, link);
		
		// Update notice
		const pathToNotice = ['rulesets', `${feedbackRulesetKey}`, 'results', `${noticeKey}`, 'value'];
		const updatedState = immutable.setIn(updatedRedirect, pathToNotice, noticeValue);
		
		if(updateState) updateState({ data: { $set: updatedState }});

	}, [block, feedbackRulesetKey, noticeKey, pathToLink, updateState]);
	
	/**
	 * Return action based on which results is defined
	 * 
	 * @return {string}
	 */
	const getSelectedResults = React.useCallback(() => {
		switch(true) {
			// Notice
			case (typeof noticeResult.value === 'string' && redirectResult.link.uri.length < 1 && redirectResult.link.uuid.length < 1):
				return ACTION.Notice;
			// Redirect
			case (typeof noticeResult.value !== 'string' && (redirectResult.link.uri.length > 0 || redirectResult.link.uuid.length > 0)):
				return ACTION.Redirect;
			// Notice + Redirect
			case (typeof noticeResult.value === 'string' && (redirectResult.link.uri.length > 0 || redirectResult.link.uuid.length > 0)):
				return ACTION.Notice_Redirect;

			default:
				return ACTION.Nothing;
		}
	}, [noticeResult.value, redirectResult.link.uri, redirectResult.link.uuid.length]);

	/**
	 * Triggers a file download in a new tab/window with the form data.
	 */
	const downloadFormSubmitsClickedHandler = React.useCallback(() => {
		window.open(`${BASE_URL}forms/export/${params.uuid}`, '_blank', 'noreferrer');
	}, [params.uuid]);

	return (
		<>
			{linkSelectModal.getAsComponent(
				<ModalSettingsContainer>
					<MainNavigation
						itemDoubleClicked={mainMenuItemSelectedHandler}
					/>
				</ModalSettingsContainer>
			)}
		
			<ScContainer>
				<TextInput
					name="button_text"
					label="Knapptext"
					value={block?.button_text ?? undefined}
					changed={textChangedHandler}
				/>

				<Select
					changed={selectChangedHandler}
					label="Vad ska hända efter inskickning av formuläret?"
					value={getSelectedResults()}
				>
					<option>
						{ACTION.Nothing}
					</option>
					<option>
						{ACTION.Notice}
					</option>
					<option>
						{ACTION.Redirect}
					</option>
					<option>
						{ACTION.Notice_Redirect}
					</option>
				</Select>

				{(getSelectedResults() === ACTION.Notice || getSelectedResults() === ACTION.Notice_Redirect) && (
					<Textarea
						id="notice"
						label="Text för notis"
						changed={(
							ev: React.ChangeEvent< HTMLInputElement | HTMLTextAreaElement>,
							...data
						) => {
							formValidation?.watch(ev, textChangedHandler, data);
						}}
						value={noticeResult?.value ?? undefined}
						name={`rulesets.${feedbackRulesetKey}.results.${noticeKey}.value`}
						inputRef={(ref) =>
							formValidation?.registerElement(ref, {
								required: true
							})}
						formValidationUnregister={formValidation?.unregisterElement}
						error={formValidation?.errors['notice']}
					/>
				)}
			
				{(getSelectedResults() === ACTION.Redirect || getSelectedResults() === ACTION.Notice_Redirect) && (
					<>
						<div>
							<Label label="Retursida" />
						</div>
						<LinkSelector
							title="Välj retursida"
							linkValue={currentLinkValue?.value}
							enabledLinkTypes={enabledLinkTypes}
							internalLinkClicked={openMainMenuSelector}
							linkChanged={linkChangedHandler}
							activeLinkType={currentLinkValue?.type}
							newWindowIsChecked={redirectResult.link.new_window}
							formValidation={formValidation}
						/>
					</>
				)}

				<StyledButton
					isPrimary
					onClick={downloadFormSubmitsClickedHandler}
				>
					Hämta formulärets inskickade data
				</StyledButton>
			
			</ScContainer>
		</>
	);
};

export default Functions;

const ScContainer = styled.div`
	width: 416px;
	overflow-y: scroll;
	height: 100%;
	max-height: calc(100vh - 272px);
	padding: var(--modal-padding);
	box-sizing:border-box;
	background: var(--modal-side-bg-color);
`;

const StyledButton = styled(Button)`
	margin-top: 4px;
`;
