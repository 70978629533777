import { BlockFormInputItemTypes, BlockFormItem, BlockFormItemLayout, BlockFormItemTypes } from './BlockForm.types';

export const FORMITEM_DEFAULT: BlockFormItem = {
	key: '',
	uuid: null,
	name: null,
	unique_name: '',
	value: null,
	description: null,
	placeholder: null,
	minlength: null,
	maxlength: null,
	min: null,
	max: null,
	step: null,
	rows: null,
	size: null,
	layout: BlockFormItemLayout.VERTICAL,
	js_target: null,
	required_error: null,
	validation: null,
	pattern: null,
	type: BlockFormItemTypes.INPUT,
	input_type: BlockFormInputItemTypes.TEXT,
	children: [],
	accept: [],
	sort_order: 0,
	root: false,
	multiple: false,
	show_name: false,
	show_description: false,
	required: false,
	selected: false,
	disabled: false,
	readonly: false,
	submit: false,
	autofocus: false
};

export interface FormItemGenerator {
	name: string,
	type: BlockFormItemTypes,
	required?: boolean
	input_type: BlockFormInputItemTypes,
}

