import { DateTime } from 'luxon';
import axios from '../../utils/oc-axios';
import { setListItemOrders, setOrder } from '../actions/action-orders';
import {
	OrderListItem,
	Order,
	APIModuleOrdersResponse
} from '../../modules/EngelsonsOrders/containers/Orders/model.Orders';
import { SetOrdersAction, SetOrderAction } from '../types/OrderTypes';
import { SortOrderType } from '../../definitions/Sorting';
import { AjaxResponse, ThunkDispatch } from '../types/ThunkTypes';

/**
 * Fetches all orders that matches filters.
 *
 * @param {DateTime} start				The date and time from when to fetch orders, is a timerange together with end parameter.
 * @param {DateTime} end				The date & time to when to fetch orders, is a timerange together with start parameter.
 * @param {string} searchText			A text to search orders for.
 * @param {string} filterBy				One filter to filter orders by, like paymethod, payment status, etc.
 * @param {string} filterByPaymethod	If payment method is selected as filter, this will contain the method's name.
 * @param {string} sortBy				To sort the orders after a column, this is the columns name/order object's property name.
 * @param {SortOrderType} sortOrder		If the sorting is ASC or DESC.
 * @param {number} pageIndex			Pagination, the current page in view.
 * @param {number} pageSize				Pagination, how many results per page to show.
 * @returns {Promise<OrderListItem[]}
 */
export const fetchAllListItemOrders = (
	start: DateTime,
	end: DateTime,
	searchText: string,
	filterBy: string,
	filterByPaymethod: string,
	sortBy: string,
	sortOrder: SortOrderType,
	pageIndex: number,
	pageSize: number
) => {
	return async (dispatch: ThunkDispatch<SetOrdersAction>) => {
		return new Promise<OrderListItem[]>((resolve, reject) => {
			axios
				.get('modules/orders', {
					params: {
						newApi: true,
						start: start.toUTC().toISO(),
						end: end.toUTC().toISO(),
						query: searchText,
						filter: filterBy,
						// filterByPaymethod,
						sort: sortBy,
						order: sortOrder,
						page: pageIndex,
						limit: pageSize
					}
				})

				.then((resp: AjaxResponse<APIModuleOrdersResponse>) => {
					const orders = resp.data.orders;
					const amount = parseInt(resp.data.amount);

					dispatch(
						setListItemOrders(orders, {
							pageCount: Math.ceil(amount / pageSize),
							totalResults: amount,
							resultsPerPage: pageSize
						})
					);

					resolve(orders);
				})
				
				.catch((err) => {
					console.error('[thunk-orders]', err);
					reject(err);
				});
		});
	};
};

/**
 * Fetches a specific order and stores it in state.
 *
 * @param {number} orderID The id of the order to fetch.
 * @returns {Promise<Order>}
 */
export const fetchOrder = (orderID: number) => {
	return async (dispatch: ThunkDispatch<SetOrderAction>) => {
		return new Promise<Order>((resolve, reject) => {
			axios
				.get(`modules/orders/${orderID}`)
				.then((resp: AjaxResponse<Order>) => {
					dispatch(setOrder(resp.data));
					resolve(resp.data);
				})
				.catch((err) => {
					console.error('[thunk-orders]', err);
					reject(err);
				});
		});
	};
};
