import React, { useMemo, memo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Action, Icon } from '../../components/UI';

const modalPositionConfig = {
	left: [{ icon: ['fal', 'arrow-right-to-line'], nextPosition: 'right' }],

	right: [{ icon: ['fal', 'arrow-left-to-line'], nextPosition: 'left' }]
};

const Modal = (props) => {
	/**
	 * README IMPORTANT!!
	 * All default options and checks are coded in the useModal hook.
	 *
	 *
	 * props.modal
	 * 		config			The config the Modal was initialized with
	 * 		setConfig		A function to change the prop of one of the modals config like 'position' or 'isDismissable'
	 * 		originalState	The original state the Modal was open with
	 *	 	currentState	The current updated state stored in the Modal.
	 * 		setState		Function to updated/change the Modal's state
	 * 		close			Function to close the modal
	 */

	const {
		close,
		originalState,
		currentState,
		config,
		setConfig
	} = props.modal;

	if(!config)
		throw new Error(
			'[useModal] A modal was about to render but it doesn\'t have any config! Maybe it was removed from Redux state?'
		);

	// Map the plain array with plain action objects to components.

	// Get a default action to trigger, it could close the Modal in it's action function
	//	OPS! not using memo here as it is relatibly simple and the whole component will re-render anyways on every state change.
	let defaultAction = config.actions
		? config.actions.find((action) => action.isDefault)
		: null;

	if(!defaultAction) throw new Error('[useModal] There is no default action!, please provide one by setting the "isDefault" property to true in one action.');

	defaultAction = defaultAction.action;

	// Generate the position change icons (arrows) as the depends on the current config.position
	const modalPositionIcons = config.isPositionable
		? modalPositionConfig[config.position].map((icon, index) => {
			return (
				<ScIcon
					style={{ left: 16 }}
					key={`modalPosIcon_${index}`}
					icon={icon.icon}
					css={icon.css}
					onClick={() => {
						setConfig('position', icon.nextPosition);
					}}
				/>
			);
		})
		: null;

	// map the modals position buttons to Components.
	const actionsComponents = useMemo(() => {
		if(config && config.actions) {
			const actions = config.actions.map((action, index) => {
				if(action.isVisible)
					return (
						<Action
							key={`ctrl-${index}`}
							clicked={(ev) => {
								ev.stopPropagation();
								action.action(
									originalState,
									currentState,
									close
								);
							}}
							index={index}
						>
							{action.text}
						</Action>
					);

				return null;
			});

			// If there is any visible action
			// 	used to know if there is any action to render, as some or all may be isVisible: false
			const anyVisibleAction = config.actions
				? !!config.actions.find((action) => action.isVisible)
				: false;

			if(anyVisibleAction) return (
				<ScControls>
					{actions}
				</ScControls>
			);
		}
	}, [close, config, currentState, originalState]);

	return ReactDOM.createPortal(
		<>
			{!config.hideBackdrop && (
				<ScBackdrop
					config={config}
					onClick={(ev) => {
						ev.stopPropagation();

						if(config.isDismissable) {
							defaultAction(originalState, currentState, close);
						}
					}}
				/>
			)}

			<ScModalContainer config={config}>
				<ScModal
					extraCss={props.extraCss}
					config={config}
				>
					<ScHeader config={config}>
						{config.isDismissable && (
							<ScIcon
								style={{ right: 16 }}
								icon={['fal', 'xmark']}
								css="float: right;"
								onClick={() => {
									defaultAction(
										originalState,
										currentState,
										close
									);
								}}
							/>
						)}
						{modalPositionIcons}
						{config.title && (
							<div>
								{config.title}
							</div>
						)}
					</ScHeader>
					<ScChildrenWrapper config={config}>
						{props.children}
					</ScChildrenWrapper>
					{actionsComponents}
				</ScModal>
			</ScModalContainer>
		</>,
		document.getElementById('modals')
	);
};

Modal.propTypes = {
	modal: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired
};

export default memo(Modal);

const ScHeader = styled.div`
	position: absolute;
	height: 48px;
	z-index: 1;
	padding: 16px;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
	z-index: 999;
	font-size: 14px;
	pointer-events: none;
	@media print {
		display: none;
	}
	${(props) =>
		props.config.title &&
		css`
			/* position: fixed; */
			/* width: ${(props) => props.config.width}}; */
			background-color: var(--modal-side-bg-color);
		`}
`;

const ScIcon = styled(Icon)`
	position: absolute;
	cursor: pointer;
	pointer-events: initial;
	font-size: 16px;
`;

// const ScBackdrop = styled.div`
// 	position: fixed;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background-color: rgba(0, 0, 0, 0.4);
// 	z-index: 999;
// `;

const ScBackdrop = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 999;
	@media print {
		background-color: #fff;
	}
	${(props) =>
		props.config.isBackdropBlurred &&
		css`
			background: rgba(0, 0, 0, 0.8);
			backdrop-filter: blur(16px);
			-webkit-backdrop-filter: blur(16px);
		`}
`;

const ScModalContainer = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	display: flex;
	pointer-events: none;
	justify-content: center;
	align-items: center;
	@media print {
		bottom: inherit;
		position: absolute;
	}
	${(props) =>
		props.config.position === 'right' &&
		css`
			align-items: flex-start;
			justify-content: flex-end;
			padding-top: 48px;
		`}

	${(props) =>
		props.config.position === 'left' &&
		css`
			align-items: flex-start;
			justify-content: flex-start;
			padding-top: 48px;
		`}
`;

const ScControls = styled.div`
	bottom: 0px;
	position: sticky;
	display: flex;
	border-top: 1px solid var(--border-grey-color);
	width: 100%;
	@media print {
		display: none;
	}
`;

const ScModal = styled.div`
	background-color: var(--modal-side-bg-color);
	border-radius: 4px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.6),
		0 40px 30px -30px rgba(0, 0, 0, 0.2);
	z-index: 999;
	position:relative;
	pointer-events: visible;
	overflow: hidden;

	width: ${(props) => props.config.width}};
	@media print {
		box-shadow: none;
		background-color: #ffffff;
		height: auto;
		width: 100%;
		overflow: visible;
	}
	${(props) =>
		props.config.position === 'left' &&
		css`
			margin: 16px;
		`}

	${(props) =>
		props.config.position === 'right' &&
		css`
			margin: 16px;
		`}

	${(props) =>
		props.config.height &&
		css`
			height: ${props.config.height};
		`}

	${(props) =>
		!props.config.height &&
		css`
			min-height: ${props.config.minHeight};
			max-height: ${props.config.maxHeight};
		`}
`;

const ScChildrenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 80px;
	overflow-y: auto;
	@media print {
		height: auto;
		overflow: visible;
		max-height: none;
		margin-top: 0;
	}
	height: ${(props) => (props.config.title ? 'calc(100% - 126px)' : '100%')};

	height: 100%;

	max-height: ${(props) =>
		props.config.title ? 'calc(100vh - 183px)' : '100%'};

	${(props) =>
		props.config.title &&
		css`
			margin-top: 48px;
		`}
`;
