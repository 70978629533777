export default [
	{
		id: 1,
		name: 'page',
		icon: ['fal', 'file'],
	},
	{
		id: 2,
		name: 'folder',
		icon: ['fal', 'folder'],
	},
	{
		id: 3,
		name: 'link',
		icon: ['fal', 'link'],
	},
	{
		id: 4,
		name: 'index',
		icon: ['fal', 'layer-group'],
	},
	{
		id: 5,
		name: 'gallery',
		icon: ['fal', 'images'],
	},
	{
		id: 6,
		name: 'blog',
		icon: ['fal', 'layer-group'],
	},
];
