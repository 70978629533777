import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonTableProps } from './model.SkeletonTable';

const SkeletonTable: React.FC<SkeletonTableProps> = (props) => {
	return (
		<ContentLoader
			className={props.className}
			backgroundColor={
				props.backgroundColor ? props.backgroundColor : '#ddd'
			}
			foregroundColor={
				props.foregroundColor ? props.foregroundColor : '#eee'
			}
			height={214}
			width={'100%'}
			// viewBox={'0 0 728 212'}
		>
			{/* <rect y="10" width="100%" height="24" rx="4" />
			<rect y="42" width="100%" height="40" />
			<rect y="86" width="100%" height="40" />
			<rect y="130" width="100%" height="40" />
			<rect y="174" width="100%" height="40" /> */}

			<rect
				y="0"
				width="100%"
				height="40"
			/>
			<rect
				y="44"
				width="100%"
				height="40"
			/>
			<rect
				y="88"
				width="100%"
				height="40"
			/>
			<rect
				y="132"
				width="100%"
				height="40"
			/>
		</ContentLoader>
	);
};

export default SkeletonTable;
