import React from 'react';
import Layout from './Layout/Layout';
import Settings from './Settings/Settings';
import Functions from './Functions/Functions';
import Tab from '../../../../Tabs/Tab';
import TabContainer from '../../../../Tabs/TabContainer';
import { BlockSettingsProps, BuilderBlock } from '../../../Block.types';
import { BlockFormType } from '../BlockForm.types';

const BlockFormSettings: React.FC<BlockSettingsProps<BuilderBlock<BlockFormType>>> = (props) => {
	
	const {
		// injected by useModal hook, contains all modal related things.
		modal,

		// Callback for updating the editor when something changed.
		changed,

		formValidation
	} = props;

	return (
		<TabContainer 
			disableAllTabsButActive={formValidation.formHasErrors()}
			noPadding
		>
			<Tab title="Utseende">
				<Layout
					modal={modal}
					builderUpdateCbk={changed}
					formValidation={formValidation}
				/>
			</Tab>

			<Tab title="Inställningar">
				<Settings
					modal={modal}
					formValidation={formValidation}
				/>
			</Tab>

			<Tab title="Funktioner">
				<Functions
					modal={modal}
					builderUpdateCbk={changed}
					formValidation={formValidation}
				/>
			</Tab>
		</TabContainer>
	);
};

export default BlockFormSettings;
