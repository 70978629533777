import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import ImageThumbnailActions from './ImageThumbnailActions';
import CheckItem from '../../Forms/CheckItem/CheckItem';
import { MediaPlaceholder, Button } from '..';
import useDoubleClick from '../../../hooks/useDoubleClick/useDoubleClick';

/**
 * @param {string}	id				The components unique id, used ex. when needed to identify wich component was clicked.
 * @param {string}	thumbnail		An URL for the image / thumbnail to show.
 * @param {bool}	isSelected		To apply styling and a checked icon.
 * @param {bool}	isDisabled		Whether the component is disabled and greyed out or not, will still trigger prop 'clicked'
 * @param {array(object)} actions	Array containing all actions to show when overing.
 *
 * @param {string}	height			The height of the component, can be any measure type, px, vh, %, etc
 * @param {string}	width			The width of the component, can be any measure type, px, vh, %, etc
 * @param {string}	maxWidth		The max width of the component, can be any measure type, px, vh, %, etc
 * @param {string}	className		CSS classes to apply used by styled-components
 *
 * @param {function} clicked		The function to call when the component is clicked.
 */
const ImageThumbnail = (props) => {
	const { clickedHandler } = useDoubleClick();

	return (
		<ScContainer
			width={props.width}
			maxWidth={props.maxWidth}
			className={props.className}
			style={props.style}
			isDisabled={props.isDisabled}
			disabledItemsWithActionBar={props.disabledItemsWithActionBar}
		>
			{props.thumbnail ? (
				<>
					<ScBorder
						id="ScBorder"
						onClick={(ev) => {
							ev.stopPropagation();
							clickedHandler(ev).then((isDoubleClick) => {
								if(isDoubleClick && props.doubleClicked) props.doubleClicked(props.id);
								if(!isDoubleClick && props.clicked) props.clicked(ev, props);
							});
						}}
						height={props.height}
						isSelected={props.isSelected}
						isDisabled={props.isDisabled}
					>
						<ScImage url={props.thumbnail} />
						{(!props.isDisabled ||
							props.disabledItemsWithActionBar) &&
							props.actions && (
								<ScActionsWrapper>
									<ImageThumbnailActions
										id="imageThumbnailActions"
										thumbnailProps={props}
										imageIndex={props.index}
										actions={props.actions}
									/>
								</ScActionsWrapper>
						)}

						<ScCheck
							type="radio"
							isNotClickable
							checked
							isBright
							isSelected={props.isSelected}
						/>
						{/* Extra layers */}
						{props.children}
					</ScBorder>
					{props.name && (
						<ScTitle>
							{props.name}
						</ScTitle>
					)}
					{props.description && (
						<ScDescription>
							{props.description}
						</ScDescription>
					)}
				</>
			) : (
				<MediaPlaceholder>
					{!!props.placeholderClicked && (
						<Button
							onClick={(ev) => {
								ev.stopPropagation();
								props.placeholderClicked();
							}}
						>
							Välj bild
						</Button>
					)}
				</MediaPlaceholder>
			)}
		</ScContainer>
	);
};

ImageThumbnail.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	thumbnail: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	isSelected: PropTypes.bool,
	isDisabled: PropTypes.bool,
	actions: PropTypes.array,

	// Styling
	height: PropTypes.string,
	width: PropTypes.string,
	maxWidth: PropTypes.string,
	className: PropTypes.string,

	// Functions
	clicked: PropTypes.func,
	placeholderClicked: PropTypes.func,
	doubleClicked: PropTypes.func
};

// Use memo to prevent re-renders when nothing changed, like in MediaArchive when changing isSelected
//	Neede as the img would be reloaded causing it to blink before the webbrowser fetch and draw the same image again.
export default React.memo(ImageThumbnail);

const ScCheck = styled(CheckItem)`
	display: none;

	${(props) =>
		props.isSelected &&
		css`
			display: block;
			position: absolute;
			bottom: 4px;
			right: 4px;
			margin-bottom: 0;
			z-index: 9;
		`}
`;

const ScActionsWrapper = styled.div`
	position: absolute;
	top: 0;
	z-index: 99;
	width: 100%;
	display: none;
`;

const ScTitle = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 4px;
	text-align: center;
`;

const ScDescription = styled.div`
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 4px;
	text-align: center;
`;

const ScImage = styled.div.attrs((props) => ({
	style: {
		background: `url(${props.url}) no-repeat center/contain`
	}
}))`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const ScBorder = styled.div.attrs((props) => ({
	style: {
		//background: `url(${props.url}) no-repeat center/contain`
		height: props.height
	}
}))`
	border: 2px solid transparent;
	overflow: hidden;
	position: relative;

	/* &::after {
		content: '';
		position: absolute;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		background-size: contain;
	} */

	${(props) =>
		props.isDisabled &&
		css`
			cursor: not-allowed;
			background-size: contain;

			&::after {
				opacity: 0.5;
			}

			${ScDescription}, ${ScTitle} {
				opacity: 0.5;
			}
		`}
	${
	// Show this as default if no props.height is given.
	(props) =>
		!props.height &&
			css`
				:before {
					content: '';
					display: block;
					padding-top: 100%;
					width: 100%;
					position: relative;
				}
			`
}

	${(props) =>
		props.isSelected &&
		css`
			border: 2px solid var(--dark-color);
		`}
`;

const ScContainer = styled.div`
	position: relative;
	padding: 4px;

	${(props) => `width: ${props.width || '33.3333%'};`}
	${(props) => `max-width: ${props.maxWidth || '100%'};`}

	display: block;
	flex: none;

	${(props) =>
		(!props.isDisabled || props.disabledItemsWithActionBar) &&
		css`
			:hover {
				${ScBorder} {
					border: 2px solid var(--bg-dark-grey-color);
					box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
					background-color: #fff;
				}

				${ScActionsWrapper} {
					display: block;
					cursor: pointer;
				}
			}
		`}
`;
