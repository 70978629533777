import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ITableCellProps } from './model.TableCell';

const TableCell: React.FC<ITableCellProps> = (props) => {
	return (
		<ScCell
			className={props.className}
			width={props.width}
			height={props.height}
			position={props.position}
			isSelected={props.isSelected}
			isSingleRow={props.isSingleRow}
			onClick={props.clicked}
		>
			{props.children}
		</ScCell>
	);
};

export default TableCell;

export const ScCell = styled.div<ITableCellProps>`
	vertical-align: middle;
	padding: 8px 16px 8px 0;
	font-size: 14px;
	text-align: left;
	display: table-cell;

	${(props) =>
		props.isSingleRow &&
		css`
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`}

	&:first-child {
		padding-left: 16px;
	}

	${(props) =>
		props.width &&
		css`
			width: ${props.width}px;
		`}

	${(props) =>
		props.height &&
		css`
			height: ${props.height}px;
		`}
        
    ${(props) =>
		props.position &&
		css`
			position: ${props.position};
		`}

	${(props) =>
		props.isSelected &&
		css`
			color: white;
		`}
`;
