import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

import { Icon } from '../UI';

const SortableItem = (props) => {
	return (
		<ScContainer>
			<ScIconWrapper>
				<Icon icon={['fal', 'grip-lines']} />
			</ScIconWrapper>
			<ScText>{props.children}</ScText>
			{props.removed && (
				<ScIconWrapper
					style={{ cursor: 'pointer' }}
					onClick={(ev) => {
						ev.stopPropagation();
						props.removed(props.index);
					}}
				>
					<Icon icon={props.icon} />
				</ScIconWrapper>
			)}
		</ScContainer>
	);
};

SortableItem.propTypes = {
	icon: PropTypes.array.isRequired,
	children: PropTypes.any.isRequired,
	removed: PropTypes.func,
};

export default SortableItem;

const ScContainer = styled.div`
	display: flex;
	height: 40px;
	width: 100%;
	background: rgba(204, 204, 204, 0.56);
	user-select: none;
	align-items: center;
`;

const ScText = styled.div`
	font-weight: 300;
	font-size: 12px;
	flex: 1;
`;

const ScIconWrapper = styled.div`
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	display: flex;
`;
