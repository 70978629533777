import React from 'react';
import PropTypes from 'prop-types';
import { parseStringAsObjectPath } from 'react-utils';
import { Select } from '../../../../Forms';
import { ModalSettingsContainer } from '../../../../UI';

const BlockDividerSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;
	const state = currentState.data;

	// The func to trigger when something inside here changed.
	const changed = props.changed;

	/**
	 * @param {Event} ev
	 * @param {object} componentProps
	 */
	const selectChangedHandler = React.useCallback((ev, componentProps) => {
		const { preventBuilderPreview } = componentProps;

		// update the modal's state with the new data
		const suggestedState = {
			data: parseStringAsObjectPath(
				ev.target.name,
				ev.target.value
			)
		};
			
		// update modal's state, will return the new state
		updateState(suggestedState).then((updatedState) => {
			// notify Builder to update the block for preview to work
			if(!preventBuilderPreview) changed(updatedState);
		});
	}, [changed, updateState]);

	return (
		<ModalSettingsContainer>
			<Select
				label="Stil"
				name="style"
				changed={selectChangedHandler}
				value={state.style}
			>
				<option value="solid">
					Solid
				</option>
				<option value="dashed">
					Streckad
				</option>
				<option value="spacer">
					Tom
				</option>
			</Select>

			{(() => {
				if(state.style === 'solid' || state.style === 'dashed') {
					return (
						<Select
							label="Tjocklek"
							name="weight"
							changed={selectChangedHandler}
							value={state.weight}
						>
							<option value="thick">
								Tjock
							</option>
							<option value="thin">
								Smal
							</option>
						</Select>
					);
				}
			})()}
		</ModalSettingsContainer>
	);
};

BlockDividerSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func
};

export default BlockDividerSettings;
