import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

/**
 *
 * @param {Event} ev 		Event for onChange.
 * @param action			The function to trigger.
 * @param payload			Is sent as the only and first parameter to the action action method
 */
const actionChangedHandler = (ev, action, payload, inputRef) => {
	ev.stopPropagation();
	if(action) action(ev, payload, null, inputRef);
};

const ImageThumbnailActions = (props) => {
	/**
	 * Set key, onClick action, index and text for each button
	 *
	 * @type {*[]}
	 */
	const actions = props.actions.map((action, index) => {
		const component =
			action.type === 'input'
				? React.cloneElement(action.component, {
					placeholder: `${props.imageIndex}`,
					placeholderColor: '#fff',
					pressed: (ev, componentProps, target, inputRef) => {
						actionChangedHandler(
							ev,
							action.action,
							props.thumbnailProps,
							inputRef
						);
					}
				  })
				: action.component;

		return (
			<ScActionButton
				onClick={(ev) => {
					ev.stopPropagation();
					if(props.settingsIsOpen) return void 0;

					if(action.action && !action.type)
						action.action(props.thumbnailProps);
				}}
				key={`action_${index}`}
				styles={action.styles}
			>
				{component}
			</ScActionButton>
		);
	});

	return (
		<ScActionButtonWrapper id={props.id}>
			{actions}
		</ScActionButtonWrapper>
	);
};

ImageThumbnailActions.propTypes = {
	actions: PropTypes.array.isRequired,
	id: PropTypes.string,
	thumbnailProps: PropTypes.object,
	imageIndex: PropTypes.number
};

export default ImageThumbnailActions;

const ScActionButtonWrapper = styled.div`
	display: flex;
	text-align: center;

	position: absolute;
	top: 0;
	width: 100%;

	background-color: var(--bg-dark-grey-color);

	z-index: 99;
`;

const ScActionButton = styled.div`
	flex: 1;
	padding: 4px;

	color: ${(props) => props.styles?.color ?? ''};

	:hover {
		${(props) => `
			color: ${props.styles?.hover?.color ?? ''};
			background-color: ${props.styles?.hover?.backgroundColor ?? '#333'}
		`}
	}
`;
