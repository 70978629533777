import { Column } from 'react-table';
import { DateTime } from 'luxon';

/**
 * Constanst used for mapping property names of OrderListItem,
 * 	used in the column definition & later in logic when creating columns for the table.
 */
export const ORDERLIST_COLUMNS_ORDER_NR = 'number';
export const ORDERLIST_COLUMNS_PAYMENT_METHOD = 'payment_method';
export const ORDERLIST_COLUMNS_TOTAL_AMOUNT = 'total';
export const ORDERLIST_COLUMNS_SENT = 'synchronized';
export const ORDERLIST_COLUMNS_COMPLEMENTARY = 'complementary';
export const ORDERLIST_COLUMNS_YOTPO = 'sent_to_yotpo';

// Columns for React Table.
export const ORDERS_LIST_COLUMNS: Column<object>[] = [
	{
		Header: 'Ordernummer',
		accessor: ORDERLIST_COLUMNS_ORDER_NR
	},
	{
		Header: 'Skickad',
		accessor: ORDERLIST_COLUMNS_SENT
	},
	{
		Header: 'C3 ID',
		accessor: 'c3_id'
	},
	{
		Header: 'Kundnummer',
		accessor: 'customer_number'
	},
	{
		Header: 'E-postadress',
		accessor: 'email'
	},
	{
		Header: 'Namn',
		accessor: 'name'
	},
	{
		Header: 'Address',
		accessor: 'street'
	},
	{
		Header: 'Postnummer',
		accessor: 'postal_code'
	},
	{
		Header: 'Yotpo',
		accessor: ORDERLIST_COLUMNS_YOTPO
	},
	{
		Header: 'Betalmedel',
		accessor: ORDERLIST_COLUMNS_PAYMENT_METHOD
	},
	{
		Header: 'TO',
		accessor: ORDERLIST_COLUMNS_COMPLEMENTARY
	},
	{
		Header: 'Summa',
		accessor: ORDERLIST_COLUMNS_TOTAL_AMOUNT
	}
];

// Initial dates for the filtering.
export const ORDERS_INITIAL_START_DATE: DateTime = DateTime.local().minus({ hours: 1 });
export const ORDERS_INITIAL_END_DATE: DateTime = DateTime.local();
