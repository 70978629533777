import React from 'react';
import { usePagination, useTable, HeaderGroup, Row, Cell } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import update from 'immutability-helper';
import {
	OrderListItem,
	OrderSearchFilters,
	FilterState,
	PaymentMethods,
	ORDERS_FILTER_ALL,
	ORDERS_FILTER_STATUS_SUCCESS,
	ORDERS_FILTER_STATUS_FAILED,
	ORDERS_FILTER_METHOD,
	ORDERS_FILTER_STATUS_NOT_SYNCHRONIZED
} from './model.Orders';
import {
	ScFilterContainer,
	ScSearch,
	ScSelect,
	ScButton
} from './style.Orders';
import {
	ORDERS_INITIAL_START_DATE,
	ORDERS_INITIAL_END_DATE,
	ORDERS_LIST_COLUMNS
} from './consts.Orders';
import { renderOrdersTableCell } from './func.Orders';
import axios from '../../../../utils/oc-axios';
import useModal from '../../../../hooks/Modal/useModal';
import { RootState } from '../../../../store/types/RootTypes';
import PaginationInfo from '../../../../definitions/PaginationInfo';
import DateTimePicker from '../../../../components/UI/DateTimePicker/DateTimePicker';
import { fetchAllListItemOrders } from '../../../../store/thunks/thunk-orders';
import {
	Table,
	TableHead,
	TableHeading,
	TableBody,
	TableRow,
	TablePagination
} from '../../../../components/Table/index';
import ModuleContainer from '../../../../components/Content/ModuleContainer/ModuleContainer';
import ErrorBoundary from '../../../../hoc/ErrorBoundary/ErrorBoundary';
import OrderDetails from '../OrderDetails/OrderDetails';
import SkeletonTable from '../../../../components/Skeletons/SkeletonTable/SkeletonTable';
import { SortingState } from '../../../../definitions/Sorting';
import withErrorBoundary from '../../../../hoc/withErrorBoundary';
import { SelectProps } from '../../../../components/Forms/Select/model.Select';

const Orders: React.FC = (_props) => {
	// State to keep track of wheter a request to fetch orders has been sent or not
	// (used to display a dialog box when first showing module, since we don't fetch orders on mount)
	const [hasFetched, setHasFetched] = React.useState<boolean>(false);

	const dispatch = useDispatch<any>();

	const orderDetailsModal = useModal();

	// Show skeleton while fetch is loading
	const [isLoading, setLoading] = React.useState(false);

	const [filter, setFilter] = React.useState<FilterState>({
		// all, success, failed, paymethod
		filterBy: ORDERS_FILTER_ALL,

		// swish, invoice, card, account
		filterByPaymethod: 'card'
	});

	const [sort] = React.useState<SortingState>({
		// the name of a property of OrderListItem to sort after.
		sortBy: 'successful',

		// swish, invoice, card, account
		sortOrder: 'asc'
	});

	const [searchText, setSearchText] = React.useState<string>('');

	// stores the date & time for the Datepickers
	const [startDate, setStartDate] = React.useState<DateTime>(ORDERS_INITIAL_START_DATE);
	const [endDate, setEndDate] = React.useState<DateTime>(ORDERS_INITIAL_END_DATE);

	// all the orders from the Redux state.
	const orders = useSelector(
		(state: RootState) => state.orderManagement.listItemOrders
	) as OrderListItem[];

	// information about how to paginate from the Redux state.
	const paginationInfo: PaginationInfo = useSelector((state: RootState) => {
		return state.orderManagement.paginationInfo;
	});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// setPageSize,
		// Get the state from the instance
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns: ORDERS_LIST_COLUMNS,
			data: orders,
			initialState: { pageIndex: 0, pageSize: 50 },
			manualPagination: true,

			// Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: paginationInfo.pageCount
		},

		usePagination
	);
		
	/**
	 * Fetch orders
	 * 
	 * @param {number} pageIndex index of the page that's used when fetching orders
	 * @param {string} searchText search string used to filter orders
	 * @param {boolean} resetPage wether or not to reset page to show the start page
	 * @returns {void}
	 */
	 const fetchOrdersHandler = React.useCallback((pageIndex: number, searchText: string, resetPage: boolean = false): void => {
		setHasFetched(true);
		setLoading(true);
		let page = pageIndex + 1;

		if(resetPage) {
			page = 1;
			gotoPage(0);
		}

		dispatch(
			fetchAllListItemOrders(
				startDate,
				endDate,
				searchText,
				filter.filterBy,
				filter.filterByPaymethod,
				sort.sortBy,
				sort.sortOrder,
				page,
				pageSize
			)
		)
			.then(() => setLoading(false))
			.catch(() => {});
	}, [dispatch, endDate, filter.filterBy, filter.filterByPaymethod, gotoPage, pageSize, sort.sortBy, sort.sortOrder, startDate]);

	/**
	 * Changes the DateTime stored in state with the new one.
	 *
	 * @param {string} dateType	What date is going to be updated, start or end date.
	 * @param {string} isoDate  The new date & time in a ISO compatible format.
	 */
	const setDateTime = React.useCallback(
		_.debounce((dateType: 'start' | 'end', isoDate: string) => {
			const newDateTime = DateTime.fromISO(isoDate);

			switch(dateType) {
				case 'start':
					// This applicate specially when changing times
					// if endDate if lower/before than the new startDate
					// set endDate to same as startDate
					if(endDate < newDateTime) setEndDate(newDateTime);
					setStartDate(newDateTime);

					return;
				case 'end':
					// This applicate specially when changing times
					// if startDate if higher/after than the new endDate
					// set startDate to same as endDate
					if(startDate > newDateTime) setStartDate(newDateTime);
					setEndDate(newDateTime);

					return;
			}
		}, 500),
		[startDate, endDate]
	);

	/**
	 * Updates state when the filterBy select is changed.
	 *
	 * @param {React.ChangeEvent<HTMLSelectElement>} ev The event triggered by the user interaction.
	 */
	const filterByChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>, _props: SelectProps): void => {

		const updatedState = update(filter, {
			filterBy: {
				$set: ev.currentTarget.value as OrderSearchFilters
			}
		});

		setFilter(updatedState);
	}, [filter]);

	/**
	 * Updates state when the payment method filter is changed.
	 *
	 * @param {React.SyntheticEvent} ev The event triggered by the user interaction.
	 */
	const filterByPaymethodChangedHandler = React.useCallback((ev: React.SyntheticEvent<HTMLSelectElement>, _props: SelectProps) => {

		const updatedState = update(filter, {
			filterByPaymethod: {
				$set: ev.currentTarget.value as PaymentMethods
			}
		});

		// reset current page in the pagination
		gotoPage(0);
		setFilter(updatedState);
	}, [gotoPage, filter]);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 * and refetch orders
	 *
	 * @param {HTMLElement} input An HTML input element
	 */
	const searchButtonClickedHandler = React.useCallback((input: React.RefObject<HTMLInputElement>) => {
		if(!input.current) return;
		
		const value = input.current.value;

		setSearchText(value);
		fetchOrdersHandler(pageIndex, value, true);
	}, [fetchOrdersHandler, pageIndex]);

	/**
	 * Clears the search input texts when x button is clicked
	 * and refetches orders
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback(() => {
		setSearchText('');
		fetchOrdersHandler(pageIndex, '', true);
	}, [fetchOrdersHandler, pageIndex]);

	/**
	 * Handles when user clicks on filter button. 
	 * Calls fetchOrdersHandler to refetch orders
	 * 
	 * @returns {void}
	 */
	const filterOrdersHandler = React.useCallback(() => {
		fetchOrdersHandler(pageIndex, searchText, true);
	}, [fetchOrdersHandler, pageIndex, searchText]);

	/**
	 * Go to next page (with function provided by React Table) and refetch orders
	 * 
	 * @returns {void}
	 */
	const nextPageHandler = React.useCallback(() => {
		nextPage();
		fetchOrdersHandler(pageIndex + 1, searchText);
	}, [fetchOrdersHandler, nextPage, pageIndex, searchText]);

	/**
	 * Go to previous page (with function provided by React Table) and refetch orders
	 * 
	 * @returns {void}
	 */
	const previousPageHandler = React.useCallback(() => {
		previousPage();
		fetchOrdersHandler(pageIndex - 1, searchText);
	}, [fetchOrdersHandler, pageIndex, previousPage, searchText]);

	/**
	 * Go to the given page (with function provided by React Table) and refetch orders
	 * 
	 * @param {((pageIndex: number) => number) | number} updater (type comes from React Table)
	 * @returns {void}
	 */
	const goToPageHandler = React.useCallback((updater: ((pageIndex: number) => number) | number) => {
		gotoPage(updater);

		const pageIndex = updater as number;
		fetchOrdersHandler(pageIndex, searchText);

	}, [fetchOrdersHandler, gotoPage, searchText]);

	/**
	 * Opens a modal with the order details.
	 *
	 * @param {OrderListItem} order The order object to open details for.
	 */
	const openOrderDetails = React.useCallback((order: OrderListItem) => {
		let buttons = [
			{
				text: 'Skicka om orderbekräftelse',
				isDefault: false,
				action: async (
					originalState: OrderListItem,
					currentState: OrderListItem,
					closeModal: () => void
				) => {
					closeModal();

					try {
						await resendOrderConfirmation(order.number);
					} catch(e) {
						alert(
							'Det gick inte att skicka bekräftelse på nytt.'
						);
					}
				}
			},
			{
				text: 'Stäng',
				isDefault: true,
				action: async (
					originalState: OrderListItem,
					currentState: OrderListItem,
					closeModal: () => void
				) => closeModal()
			}
		];

		if(!order.synchronized) {
			buttons.shift();
			buttons = [...buttons];
		}

		orderDetailsModal.open({
			title: `Visar Order W${order.number}`,
			state: order,
			width: '98%',
			height: '98%',
			isDismissable: 'true',
			actions: buttons
		});
	}, [orderDetailsModal]);

	return (
		<>
			{orderDetailsModal.getAsComponent(<OrderDetails />)}

			<ModuleContainer header="Ordrar">
				<ScSearch
					searchPlaceholder="Sök på förnamn, efternamn, e-postadress, m.m."
					searchBtnClicked={searchButtonClickedHandler}
					hasButton
					isDisabled={isLoading}
					cleared={searchClearedHandler}
				/>

				<ErrorBoundary>
					<ScFilterContainer>
						<ScSelect
							label="Filtreringsområde"
							isDisabled={isLoading}
							value={filter.filterBy}
							changed={filterByChangedHandler}
						>
							<option value={ORDERS_FILTER_ALL}>
								Alla
							</option>
							<option value={ORDERS_FILTER_STATUS_FAILED}>
								Misslyckade
							</option>
							<option value={ORDERS_FILTER_STATUS_SUCCESS}>
								Godkända
							</option>
							<option value={ORDERS_FILTER_STATUS_NOT_SYNCHRONIZED}>
								Godkända men ej synkroniserad
							</option>
						</ScSelect>
						{filter.filterBy === ORDERS_FILTER_METHOD && (
							<ScSelect
								label="Välj betalmedel"
								value={filter.filterByPaymethod}
								isDisabled={isLoading}
								changed={filterByPaymethodChangedHandler}
							>
								<option value="swish">
									Swish
								</option>
								<option value="invoice">
									Faktura
								</option>
								<option value="card">
									Kort
								</option>
								<option value="account">
									Kundkonto
								</option>
							</ScSelect>
						)}

						<DateTimePicker
							label="Startdatum"
							changed={(isoDate: string) =>
								setDateTime('start', isoDate)}
							value={startDate.toISO() ?? undefined}
							maxDate={endDate.toISO()}
							timeFormat={true}
							secondaryColor
							isDisabled={isLoading}
						/>

						<DateTimePicker
							label="Slutdatum"
							changed={(isoDate: string) =>
								setDateTime('end', isoDate)}
							minDate={startDate.minus({ days: 1 }).toISO()}
							maxDate={ORDERS_INITIAL_END_DATE.toISO()}
							timeFormat={true}
							value={endDate.toISO() ?? undefined}
							secondaryColor
							isDisabled={isLoading}
						/>
						<ScButton onClick={filterOrdersHandler}>
							Filtrera
						</ScButton>
					</ScFilterContainer>
				</ErrorBoundary>

				{!hasFetched && (
					<div>
						Klicka på "Filtrera" eller "Sök" för att hämta ordrar.
					</div>
				)}

				{hasFetched && (
					<>
						<ErrorBoundary>
							<TablePagination
								page={page}
								pageIndex={pageIndex}
								pageCount={pageCount}
								totalResults={paginationInfo.totalResults}
								pageOptions={pageOptions}
								canPreviousPage={canPreviousPage}
								canNextPage={canNextPage}
								gotoPage={goToPageHandler}
								previousPage={previousPageHandler}
								nextPage={nextPageHandler}
								isDisabled={isLoading}
							/>

							<Table
								overFlowScroll
								{...getTableProps()}
							>
								<TableHead>
									{headerGroups.map((headerGroup: HeaderGroup) => (
										<div
											style={{ display: 'table-row' }}
											{...headerGroup.getHeaderGroupProps()}
										>
											{headerGroup.headers.map(
												(column: HeaderGroup) => (
													<TableHeading
														isSortable={false}
														isActive={
													sort.sortBy === column.id
												}
														sortOrder={sort.sortOrder}
														{...column.getHeaderProps()}
													>
														{column.render('Header')}
													</TableHeading>
												)
											)}
										</div>
									))}
								</TableHead>

								<TableBody {...getTableBodyProps()}>
									{page.map((row: Row<any>) => {
										prepareRow(row);

										return (
											<TableRow {...row.getRowProps()}>
												{row.cells.map(
													(cell: Cell<OrderListItem>) => {
														return renderOrdersTableCell(
															cell,
															openOrderDetails
														);
													}
												)}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</ErrorBoundary>

						<div>
							{isLoading && orders.length === 0 && (
							// Use our custom loading state to show a loading indicator
								<SkeletonTable />
							)}

							<TablePagination
								page={page}
								pageIndex={pageIndex}
								pageCount={pageCount}
								totalResults={paginationInfo.totalResults}
								pageOptions={pageOptions}
								canPreviousPage={canPreviousPage}
								canNextPage={canNextPage}
								gotoPage={goToPageHandler}
								previousPage={previousPageHandler}
								nextPage={nextPageHandler}
								isDisabled={isLoading}
							/>
						</div>
					</>
				)}
			</ModuleContainer>
		</>
	);
};

export default withErrorBoundary(Orders);

/**
 * Trigger a resend of the order confirmation to customer.
 *
 * @param {number} orderID The id of the corresponding order.
 */
const resendOrderConfirmation = (orderID: number) => {
	return axios.post(`modules/orders/confirmation/${orderID}`);
};
