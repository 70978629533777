import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../UI';

const SearchResult = (props) => {
	const items = props.data.map((item) => {
		return (
			<ScResultItem
				key={`result_${item.type}_${item.id}`}
				onClick={() => props.clicked(item, props.searchInput)}
			>
				<ScIcon
					icon={['fal', item.type === 'media' ? 'file' : 'folder']}
				/>
				<ScTitle>
					{item.number} | {getName(item)}
				</ScTitle>
			</ScResultItem>
		);
	});

	let empty = null;
	if (!items.length) {
		empty = (
			<ScEmptyResult>Ingen resultat matchade din sökning</ScEmptyResult>
		);
	}

	return (
		<ScContainer
			className={props.className}
			searchResultRelative={props.searchResultRelative}
		>
			<ScHeading>Sökresultat:</ScHeading>
			{items}
			{empty}
		</ScContainer>
	);
};

/**
 * Figures out the name of the searched item
 *
 * @param {object} item
 */
const getName = (item) => {
	switch (true) {
		case 'name' in item:
			return item.name;

		case 'original_name' in item:
			return item.original_name;

		default:
			return item.title;
	}
};

export default SearchResult;

const ScContainer = styled.div`
	position: absolute;
	position: ${(props) =>
		props.searchResultRelative ? 'relative' : 'absolute'};
	width: ${(props) =>
		props.searchResultRelative ? '100%' : 'calc(100% - 32px)'};
	font-size: 12px;
	background: var(--bg-bright-color);
	box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.24);
	border-radius: 4px;
	margin-top: 8px;
	overflow: auto;
	max-height: 600px;
	z-index: 9;
`;

const ScHeading = styled.div`
	text-transform: uppercase;
	font-size: 14px;
	color: var(--font-body-color);
	border-bottom: 1px solid #eee;
	margin: 16px;
	padding-bottom: 16px;
`;

const ScTitle = styled.div`
	margin-left: 8px;
	color: var(--font-dark-grey-color);
	transition: color 0.3s ease;
	font-weight: 300;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		font-weight: 600;
		color: var(--font-body-color);
		transition: color 0.3s ease;
	}
`;

const ScIcon = styled(Icon)`
	color: var(--font-dark-grey-color);
	transition: color 0.3s ease;
`;

const ScResultItem = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	transition: background 0.3s ease;
	background: transparent;
	font-size: 14px;
	height: 32px;
	cursor: pointer;

	/* &:last-child {
		margin-bottom: 16px;
	} */
	:hover {
		background: var(--bg-dark-color);

		${ScTitle} {
			color: var(--font-bright-color);
			span {
				color: var(--font-bright-color);
			}
		}

		${ScIcon} {
			color: var(--font-bright-color);
		}
	}
`;

const ScEmptyResult = styled.div`
	text-align: center;
	margin-bottom: 16px;
`;
