import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { ScDivider, ScOrderSummary } from './style.OrderSummary';
import { OrderSummaryProps } from './model.OrderSummary';
import { OrderItem, Giftcard } from '../containers/Orders/model.Orders';

const OrderSummary: React.FC<OrderSummaryProps> = (props) => {
	// Used to print payment fee if the payment method has one
	const paymentFee = props.order.payment ? props.order.payment.fee : null;

	// Used to print payment name, if a payment was set
	const paymentName = props.order.payment ? props.order.payment.name : null;

	// Used to display a parcel representative
	const parecelRepresentative = props.order.parcel;

	const { notification, destination } = getNotificationMethod(props);

	/**
	 * The synchronize date.
	 */
	const createdDateTime = useMemo(
		() =>
			DateTime.fromISO(props.order.created).toLocal().setLocale('sv-SV'),
		[props.order.created]
	);

	return (
		<>
			<ScOrderSummary className="ordersWrapper">
				<table>
					<thead>
						<tr>
							<th colSpan={2}>
								<span>
									Dina kunduppgifter
								</span>
							</th>
							<th className="col">
								<span></span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								Ordernummer
							</td>
							<td>
								{props.order.number}
							</td>
						</tr>
						<tr>
							<td>
								Betalningssätt
							</td>
							<td>
								{props.order?.payment?.name}
							</td>
						</tr>
						<tr>
							<td>
								Datum
							</td>
							<td>
								{createdDateTime.toLocaleString(
									DateTime.DATETIME_SHORT
								)}
							</td>
						</tr>
						<tr>
							<td style={{ verticalAlign: 'top' }}>
								Leveransadress
							</td>
							<td>
								{props.order?.customer?.firstname}
								{' '}
								{props.order?.customer?.lastname}
								<br />
								{props.order?.customer?.street}
								<br />
								{props.order?.customer?.postal_code}
								{' '}
								{props.order?.customer?.city}
								<br />
								{props.order?.customer?.country?.alpha2?.toUpperCase()}
							</td>
						</tr>
						<tr>
							<td style={{ verticalAlign: 'top' }}>
								Utlämningsställe
							</td>
							<td>
								{parecelRepresentative?.name}
								<br />
								{parecelRepresentative?.address}
								<br />
								{parecelRepresentative?.postal_code}
								<br />
								{parecelRepresentative?.city}
								<br />
								{parecelRepresentative?.country}
							</td>
						</tr>
						<tr>
							<td>
								Aviseringssätt
							</td>
							<td>
								{notification} 
								{' '}
								{destination}
							</td>
						</tr>
					</tbody>
				</table>
			</ScOrderSummary>

			<ScOrderSummary>
				<table>
					<thead>
						<tr>
							<th>
								<span>
									Art. no
								</span>
							</th>
							<th>
								<span>
									Produkt
								</span>
							</th>
							<th></th>
							<th>
								<span>
									Antal
								</span>
							</th>
							<th>
								<span>
									Summa
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{props.order.items.map((item: OrderItem, index) => (
							<React.Fragment key={`fragment_order_row_${index}`}>
								<tr key={`order_row_${index}`}>
									<td>
										{item.article_number}
									</td>
									<td>
										{item.article_name}
									</td>
									<td></td>
									<td>
										{item.quantity}
									</td>
									<td>
										{item.price}
										{' '}
										kr
									</td>
								</tr>

								{item.children &&
									item.children.map((child, cIndex) => (
										<tr key={`order_row_child_${cIndex}`}>
											<td></td>
											<td>
												{child.article_name}
											</td>
											<td></td>
											<td>
												{child.quantity}
											</td>
											<td></td>
										</tr>
									))}
							</React.Fragment>
						))}
						{props.order.giftcards.map((item: Giftcard, index) => (
							<React.Fragment key={`fragment_order_row_giftcard_${index}`}>
								<tr key={`order_row_giftcard_${index}`}>
									<td>
										Presentkort
										{' '}
										{item.number}
									</td>
									<td colSpan={3}></td>
									<td>
										-
										{item.used}
										{' '}
										kr
									</td>
								</tr>
							</React.Fragment>
						))}
						<ScDivider>
							<td colSpan={5}></td>
						</ScDivider>
						<tr>
							<td colSpan={3}></td>
							<td>
								Varuvärde
							</td>
							<td>
								{props.order.sum}
								{' '}
								kr
							</td>
						</tr>
						<tr>
							<td colSpan={3}></td>
							<td>
								Frakt
							</td>
							<td>
								{props.order?.shipping?.price}
								{' '}
								kr
							</td>
						</tr>
						{paymentFee !== null && paymentFee > 0 && (
							<tr>
								<td colSpan={3}></td>
								<td>
									{paymentName}
								</td>
								<td>
									{paymentFee}
									{' '}
									kr
								</td>
							</tr>
						)}

						<tr>
							<td colSpan={3}></td>
							<td>
								<i>
									Moms ingår med
								</i>
							</td>
							<td>
								{props.order.vat}
								{' '}
								kr
							</td>
						</tr>
						<tr>
							<td colSpan={3}></td>
							<td>
								<strong>
									Totalsumma
								</strong>
							</td>
							<td>
								<strong>
									{props.order.total}
									{' '}
									kr
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</ScOrderSummary>
		</>
	);
};

export default OrderSummary;

/**
 * Helper function to extract notifcation details
 *
 * @param {getNotificationMethod} props
 * @returns {{notification: undefined | string, destination: null | string}}
 */
const getNotificationMethod = (props: OrderSummaryProps) => {
	const code = props.order.notification_method_code;
	const shipping = props.order.shipping;

	let notificationType;

	if(code && shipping) {
		notificationType = shipping.notification_methods.find(
			(method) => method.code === code
		);

		if(notificationType) notificationType = notificationType.name;
	}

	return {
		notification: notificationType,
		destination: props.order.notification_destination
	};
};
