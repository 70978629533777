import React from 'react';
import { useSlate } from 'slate-react';
import { getLinkNode, isLinkActive } from './links-api';
import ToolbarIcon from '../Toolbar/ToolbarIcon';
import ToolbarItem from '../Toolbar/ToolbarItem';

const LinkButton = (props) => {
	const editor = useSlate();

	return (
		<ToolbarItem
			active={isLinkActive(editor)}
			onMouseDown={(event) => {
				event.preventDefault();

				// Get the current link node if there is one
				// otherwise we'll get null
				const currentLink = getLinkNode(editor);

				props.clicked(currentLink);
			}}
		>
			<ToolbarIcon icon={['fas', 'link']} />
		</ToolbarItem>
	);
};

export default LinkButton;
