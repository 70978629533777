import React from 'react';
import styled from 'styled-components';
import { BlockSettingsProps, BuilderBlock } from '../../../../../../components/Builder/Block.types';
import { TextInput } from '../../../../../../components/Forms';
import { TextInputProps } from '../../../../../../components/Forms/TextInput/model.TextInput';
import { ModalSettingsContainer } from '../../../../../../components/UI';
import { BlockGiftCardType } from '../BlockGiftCard.types';

const BlockGiftCardSettings: React.FC<BlockSettingsProps<BuilderBlock<BlockGiftCardType>>> = (props) => {
	// Fetches the current state and method
	const { currentState, updateState } = props.modal;

	// Destructs the data from the current state
	const { data } = currentState;

	/**
	 * Handles when an input changes it's value.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} ev
	 * @param {TextInputProps} inputProps
	 */
	const inputChangedHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputProps: TextInputProps) => {
		const { name } = inputProps;
		const value = ev.target.value;

		if(name) {
			updateState({
				data: {
					[name]: {
						$set: value
					}
				}
			}).then((updatedState) => {
				if(updatedState && props.changed) props.changed(updatedState);
			});
		}
		
	}, [props, updateState]);

	return (
		<ModalSettingsContainer>
			<ScInnerWrapper>
				<TextInput
					label="Titel"
					id="title"
					name="title"
					value={data.title}
					formValidationUnregister={
						props.formValidation.unregisterElement
					}
					error={props.formValidation.errors['title']}
					changed={(ev, ...data) => {
						props.formValidation.watch(
							ev,
							inputChangedHandler,
							data
						);
					}}
					inputRef={(ref: HTMLInputElement) =>
						props.formValidation.registerElement(ref, {
							required: true
						})
					}
				/>
				<TextInput
					label="Beskrivning"
					id="description"
					name="description"
					value={data.description}
					formValidationUnregister={
						props.formValidation.unregisterElement
					}
					error={props.formValidation.errors['description']}
					changed={(ev, ...data) => {
						props.formValidation.watch(
							ev,
							inputChangedHandler,
							data
						);
					}}
					inputRef={(ref: HTMLInputElement) =>
						props.formValidation.registerElement(ref, {
							required: true
						})
					}
				/>
				<TextInput
					label="Platshållartext"
					id="placeholder"
					name="placeholder"
					value={data.placeholder}
					formValidationUnregister={
						props.formValidation.unregisterElement
					}
					error={props.formValidation.errors['placeholder']}
					changed={(ev, ...data) => {
						props.formValidation.watch(
							ev,
							inputChangedHandler,
							data
						);
					}}
					inputRef={(ref: HTMLInputElement) =>
						props.formValidation.registerElement(ref, {
							required: true
						})
					}
				/>
				<TextInput
					label="Knapptext"
					id="button_text"
					name="button_text"
					value={data.button_text}
					formValidationUnregister={
						props.formValidation.unregisterElement
					}
					error={props.formValidation.errors['button_text']}
					changed={(ev, ...data) => {
						props.formValidation.watch(
							ev,
							inputChangedHandler,
							data
						);
					}}
					inputRef={(ref: HTMLInputElement) =>
						props.formValidation.registerElement(ref, {
							required: true
						})
					}
				/>
			</ScInnerWrapper>
			
		</ModalSettingsContainer>
	);
};

export default BlockGiftCardSettings;

const ScInnerWrapper = styled.div`
    width: 300px;
`;