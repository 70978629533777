import { createGlobalStyle } from 'styled-components/macro';
import OpenSansLight from './fonts/OpenSansLight.woff2';
import OpenSansRegular from './fonts/OpenSansRegular.woff2';
import OpenSansItalic from './fonts/OpenSansItalic.woff2';
import OpenSansSemiBold from './fonts/OpenSansSemiBold.woff2';
import OpenSansBold from './fonts/OpenSansBold.woff2';

const GlobalStyle = createGlobalStyle`

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-display: fallback;
	src: local('Open Sans Light'), local('OpenSans-Light'),
		url(${OpenSansLight}) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url(${OpenSansRegular}) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-display: fallback;
	src: local('Open Sans Italic'), local('OpenSans-Italic'),
		url(${OpenSansItalic}) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: fallback;
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url(${OpenSansSemiBold}) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
		url(${OpenSansBold}) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

:root {
	
	/*  Fonts */
	--font-family-primary: 'Open Sans', sans-serif;
	--font-family-secondary: 'Open Sans', sans-serif;

	/*  Global Colors */
	--dark-color: #333;
	--dark-grey-color: #666;
	--medium-grey-color: #999;
	--grey-color: #eee;
	--bright-color: #fafafa;
	--red-color: #FE1E1E;

	/*  Background color */
	--bg-dark-color: var(--dark-color);
	--bg-dark-grey-color: var(--dark-grey-color);
	--bg-grey-color: var(--grey-color);
	--bg-bright-color: var(--bright-color);
	--border-grey-color: #c4c4c4;
	--bg-cms-color: #e1e1e1;

	/*  Font color */
	--font-body-color: var(--dark-color);
	--font-dark-grey-color: var(--dark-grey-color);
	--font-medium-grey-color: var(--medium-grey-color);
	--font-grey-color: var(--grey-color);
	--font-bright-color: var(--bright-color);

	/*  Standard Button */
	--btn-bg-color: #e3e3e3;
	--btn-font-color: var(--dark-color);
	--btn-bg-hover-color: #b4b4b4;
	--btn-font-hover-color: var(--font-bright-color);

	/*  Dark Button */
	--btn-primary-bg-color: var(--dark-grey-color);
	--btn-primary-font-color: var(--font-bright-color);
	--btn-primary-bg-hover-color: var(--dark-color);
	--btn-primary-font-hover-color: var(--font-bright-color);

	--action-bg-color: var(--bg-grey-color);
	--action-bg-hover-color: var(--bg-bright-color);

	/* Modal Background Color */
	--modal-side-bg-color: var(--bg-grey-color);
	--modal-bg-color: var(--bg-grey-color);

	/* Modal settings */
	--modal-padding: 16px;
	--modal-padding: 32px 16px 16px;
	--modal-padding: 24px 16px;

	--alert-padding: 24px 16px;

	/* SideDrawer colors */
	--clientHeader-bg-color: #f3f3f3;
	--sideDrawer-bg-color: var(--bg-bright-color);

	--navItemNotPublished-font-Color: var(--font-medium-grey-color);
	--navItemHighlited-font-Color: var(--font-bright-color);
	--navItemHighlited-bg-Color: var(--dark-color);

	--ScImageTypeBg-bg: #f3f3f3;
	--ScImageTypeBg-bg-active: #dadada;
}

/*  @media (prefers-color-scheme: dark) {
 	:root {
 		 Global Colors
 		--dark-color: #fafafa;
 		--dark-grey-color: var(--medium-grey-color);
		--medium-grey-color: #999;
 		--grey-color: #666;
 		--bright-color: #333;

 		 Background color
 		--bg-dark-color: var(--dark-color);
 		--bg-dark-grey-color: var(--dark-grey-color);
 		--bg-grey-color: var(--grey-color);
 		--bg-bright-color: var(--bright-color);
 --bg-cms-color: #111;

 		 Font color
 		--font-body-color: var(--dark-color);
 		--font-dark-grey-color: var(--dark-grey-color);
 		--font-grey-color: var(--grey-color);
 		--font-bright-color: var(--bright-color);

 		 Standard Button
 		--btn-bg-color: var(--bright-color);
 		--btn-font-color: var(--dark-color);
 		--btn-bg-hover-color: #b4b4b4;
 		--btn-font-hover-color: var(--font-bright-color);

 		 Primary Button
 		--btn-primary-bg-color: var(--dark-grey-color);
 		--btn-primary-font-color: var(--dont-bright-color);
 		--btn-primary-bg-hover-color: var(--dark-color);
 		--btn-primary-font-hover-color: var(--font-bright-color);

 		--action-bg-color: #222;
 		--action-bg-hover-color: var(--bg-bright-color);

 		Modal Background Color
 		--modal-side-bg-color: #222;

 		SideDrawer colors
 		--clientHeader-bg-color: #222;
 		--sideDrawer-bg-color: var(--bg-bright-color);

		--navItemNotPublished-font-Color: var(--font-grey-color);
		--navItemHighlited-font-Color: var(--font-bright-color);
		--navItemHighlited-bg-Color: var(--dark-color);
 --ScImageTypeBg-bg: var(--modal-side-bg-color);
 --ScImageTypeBg-bg-active: var(--bg-bright-color);
 	}
 } */

*:not(ul),
*::before,
*::after {
	box-sizing: border-box;
	border: 0;
	color-adjust: exact;
	font: inherit;
	font-size: 100%;
	margin: 0;
	outline: 0;
	padding: 0;
	-webkit-print-color-adjust: exact;
	-webkit-tap-highlight-color: transparent;
	/*  vertical-align: baseline; */
}

body {
	background: #e1e1e1;
	font-family: var(--font-family-primary);
	color: var(--font-body-color);
	font-weight: 400;
	position: relative;
	overscroll-behavior-y: none;
	overflow-x: hidden;
}

body.isDragging {
	cursor: grabbing;
	user-select: none;
	overflow: hidden;

	*,
	*::before,
	*::after {
		cursor: grabbing;
		user-select: none;
	}
	iframe {
		pointer-events: none;
		user-select: none;
		overflow: hidden;
	}
	main {
		cursor: all-scroll;
		user-select: none;
		overflow: hidden;
	}
}

/*  ::-webkit-scrollbar {
 	width: 10px;
 }

 ::-webkit-scrollbar-thumb {
 	background-image: linear-gradient(#ff9500, #D62542);
 	border-radius: 16px;
 	border: 2px solid #232325;
 } */

#root {
	display: flex;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
}

.hidden {
	display: none !important;
}

strong,
b {
	font-weight: 700;
}

em,
i {
	font-style: italic;
}


@-webkit-keyframes example {
	0% {
		color: rgba(#333, 1);
	}
	40% {
		color: rgba(#ccc, 1);
	}
	100% {
		color: rgba(#333, 1);
	}
}
@keyframes example {
	0% {
		color: rgba(#333, 1);
	}
	40% {
		color: rgba(#ccc, 1);
	}
	100% {
		color: rgba(#333, 1);
	}
}

/*!
 * https:github.com/YouCanBookMe/react-datetime
 */

.rdt {
	position: relative;
	margin-bottom: 16px;
	user-select: none;
}
.rdtPicker {
	display: none;
	position: relative;
	width: 100%;
	
	padding: 8px;
	margin-top: 8px;
	background: #fafafa;
	z-index: 9;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	
	
}
.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 32px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #333;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #333;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 24px;
	vertical-align: top;
	vertical-align: middle;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}


`;

export default GlobalStyle;
