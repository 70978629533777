import update from 'immutability-helper';
import { DELETE_CUSTOMER, SET_CUSTOMER_LIST } from '../actions/actionTypes';
import {
	CustomerList,
	SetCustomersAction,
	CustomersInitialState,
	DeleteCustomerAction
} from '../types/CustomerTypes';

const initialState = {
	list: [] as CustomerList,

	// the current state of the pagination.
	paginationInfo: {
		// total amount of pages available.
		pageCount: 0,

		// total amount of results in all pages.
		totalResults: 0,

		// how many items/results there are showed in a page.
		resultsPerPage: 0
	}
} as CustomersInitialState;

const settingsManagement = (
	state = initialState,
	action: SetCustomersAction | DeleteCustomerAction
): CustomersInitialState => {
	switch(action.type) {
		// Sets the initial state
		case SET_CUSTOMER_LIST:
			return update(state, {
				list: {
					$set: action.data as CustomerList
				},
				paginationInfo: {
					$set: action.paginationInfo
				}
			});

		case DELETE_CUSTOMER:
			const customerId = action.data as number;
			const customerIndex = state.list.findIndex(
				(customer) => customer.id === customerId
			);

			return update(state, {
				list: {
					$splice: [[customerIndex, 1]]
				},
				paginationInfo: {
					totalResults: {
						$set: state.paginationInfo.totalResults - 1
					}
				}
			});

		// Return the current state as the default action
		default:
			return state;
	}
};

export default settingsManagement;
