import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import PublishDateSelector from '../UI/PublishDateSelector';
import useFormValidation from '../../hooks/useFormValidation';

const PublishModal = (props) => {
	const state = props.modal.currentState;
	const updateState = props.modal.updateState;
	const formValidation = useFormValidation();

	// Handle when the any property of the page's changes
	const stateChangedHandler = useCallback(
		(property, newValue) =>
			updateState({
				[property]: { $set: newValue },
			}),
		[updateState]
	);

	return (
		<ScWrapper className={props.className} style={props.style}>
			<PublishDateSelector
				formValidation={formValidation}
				// Publish date
				publish={state.publish_date}
				publishChanged={(newValue) =>
					stateChangedHandler('publish_date', newValue)
				}
				// Un-publish/end datetime
				unpublish={state.unpublish_date}
				unpublishChanged={(newValue) =>
					stateChangedHandler('unpublish_date', newValue)
				}
				onError={() =>
					alert(
						'Slut datumet kan inte vara samma eller innan publicerings-datumet'
					)
				}
			/>
		</ScWrapper>
	);
};

PublishModal.propTypes = {
	modal: PropTypes.object,
};

export default PublishModal;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;
