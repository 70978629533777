import { useDragDrop } from '../../hooks/useDragDrop';
import {
	defineTargetDocument,
	getElementExtendedBounds,
} from './DragDropUtils';
import MouseMoveHandler from './MouseMoveHandler';
import MouseUpHandler from './MouseUpHandler';
import { MouseOutBorderCheck } from './MouseOutHandler';

const MouseDownHandler = (
	ev,
	callbackMouseDown,
	callbackMouseMove,
	callbackMouseUp,
	props
) => {
	ev.stopPropagation();

	/**
	 * Fetch the dispatch function from the outsourced state handler
	 */
	const [state, dispatch] = useDragDrop();

	// Check s that it is in fact the left mouse button that is used
	if (ev.button !== 0) return;

	// Check so that the scope is defined for the drag event
	if (!props.scope) {
		console.warn(
			'[MouseDownHandler] No scope has been set to the Draggable. Cancelling the drag action.'
		);
		return;
	}

	/**
	 * The scope of the drag instance
	 * @type String
	 */
	const scope = props.scope;

	/**
	 * Current Target DOM element
	 */
	const currentTargetElement = ev.currentTarget;

	/**
	 * Target DOM element
	 */
	const targetElement = ev.target;

	/**
	 * Sets a bool whether a draggable is disabled or not
	 *
	 * @type {boolean}
	 */
	const disabled = props.isDisabled === true;

	/**
	 * The targetDocumentSelector is used to determine the document where the dragable element should be added
	 *
	 * @type {null}
	 */
	const targetDocumentSelector = state[scope].targetDocumentSelector
		? state[scope].targetDocumentSelector
		: null;

	/**
	 * PageX coordinate
	 */
	const pageX = ev.pageX;

	/**
	 * PageY coordinate
	 */
	const pageY = ev.pageY;

	/**
	 * Reference of function triggered on mouse move
	 * This is probably not the best solution, but simply binding params to the event listener function
	 * made React throw warnings in the console
	 *
	 * @param ev
	 */
	const onMouseMoveListener = (ev) => {
		MouseMoveHandler(ev, callbackMouseMove, props);
	};

	/**
	 * Reference of function triggered on mouse up
	 * This is probably not the best solution, but simply binding params to the event listener function
	 * made React throw warnings in the console
	 *
	 * @param ev
	 */
	const onMouseUpListener = (ev) => {
		MouseUpHandler(ev, callbackMouseUp, props);
	};

	// A draghandle is always required to be able to initiate a new drag event
	// Check whether there is a specifik handle
	let targetHasFunctionalHandler = true;
	if (
		!('isDraghandle' in targetElement.dataset) ||
		targetElement.dataset.isDraghandle !== 'true'
	)
		targetHasFunctionalHandler = false;

	// Or whether the entire dragable wrapper is a handle
	let currentTargetHasFunctionalHandler = true;
	if (
		!('isDraghandle' in currentTargetElement.dataset) ||
		currentTargetElement.dataset.isDraghandle !== 'true'
	)
		currentTargetHasFunctionalHandler = false;

	// If no valid handle was applied, then cancel the drag process
	if (!targetHasFunctionalHandler && !currentTargetHasFunctionalHandler)
		return;

	// A draggable that is disabled cannot initiate a new drag event
	if (disabled) return;

	// To distinguish click events from a mousedown event (that initializes drag) make sure we have some mouse movement
	// If left button is mouse down and mouse moves 5px from original position, then allow to begin the drag action
	const targetDocument = defineTargetDocument(targetDocumentSelector);

	// Get the DOM element bounds + offsetLeft and offsetTop
	const elementData = getElementExtendedBounds(currentTargetElement);

	// Get the exact mouse positions to prevent "jumpy" behavior when "grabbing" the element
	const calculatedX = ev.clientX - elementData.left;
	const calculatedY = ev.clientY - elementData.top;

	// Create an object references the dragged item and all it's characteristics
	const draggedItem = {
		scope: props.scope,
		originalElement: currentTargetElement,
		elementData: elementData,
		calculatedX: calculatedX,
		calculatedY: calculatedY,
		properties: props,
		initialPageX: pageX,
		initialPageY: pageY,
		mouseMoveListenerReference: onMouseMoveListener,
		mouseUpListenerReference: onMouseUpListener,
	};

	// Store the object to the state
	dispatch('ADD_DRAG_REFERENCE', draggedItem);

	targetDocument.addEventListener('mousemove', onMouseMoveListener);
	targetDocument.addEventListener('mouseup', onMouseUpListener);
	targetDocument.addEventListener('mouseout', MouseOutBorderCheck);

	// Call the provided callback function that were defined in DragDrop as a property (onDragStart)
	if (typeof callbackMouseDown === 'function')
		callbackMouseDown(draggedItem, ev);
};

export default MouseDownHandler;
