import React from 'react';
import styled, { css } from 'styled-components/macro';
import { MultiSelectBarProps } from './MultiSelectBar.types';
import { Icon, Loader } from '../UI';

const MultiSelectBar: React.FC<MultiSelectBarProps> = (props) => {

	// All normalized texts with defaults.
	const texts = React.useMemo(() => {
		return {
			selectedSingle:  props.texts?.selectedSingle ?? 'Markerad fil',
			selectedMany: props.texts?.selectedMany ?? 'Markerade filer'
		};
	}, [props.texts]);
	
	// Map all options to components
	const actions = props.opts.map((opt, index) => {
		// Validate input options & throw if something is invalid (for legacy non TS code.)
		if(!opt.icon || !opt.label || !opt.action) {
			throw new Error(`[MultiSelectBar] The option with index '${index}' must contain an icon, label & action, please recheck what you sent`);
		}

		return (
			<React.Fragment
				key={`MultiSelectBar_${index}`}
			>
				<ScAction
					isDisabled={props.isDisabled}
					onClick={(ev) => {
						ev.stopPropagation();
						opt.action();
					}}
				>
					<Icon icon={opt.icon} />
					<ScText>
						{opt.label}
					</ScText>
				</ScAction>
			</React.Fragment>
		);
	});

	// Opts prop is a required prop for this component
	// Validate input options & throw if something is invalid (for legacy non TS code.)
	if(!actions.length || actions[0] === void 0) {
		throw new Error('[MultiSelectBar] Require at least 1 option to show.');
	}

	return (
		<ScWrapper className={props.className}>
			{!props.isLoading ? (
				<>
					<ScContainer>
						<ScAmount>
							{props.amount}
						</ScAmount>
						<ScInnerContainer>
							<div>
								{props.amount <= 1
									? texts.selectedSingle
									: texts.selectedMany}
							</div>
							<ScActions>
								{actions}
							</ScActions>
						</ScInnerContainer>
						<ScClose
							onClick={(ev) => {
								ev.stopPropagation();
								if(props.onClose) props.onClose();
							}}
						>
							<Icon
								style={{ height: 24, width: 24 }}
								icon={['fal', 'xmark']}
							/>
						</ScClose>
					</ScContainer>
				</>
			) : (
				<ScLoader />
			)}
		</ScWrapper>
	);
};

export default MultiSelectBar;

const ScWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 88px;
	bottom: 16px;
	z-index: 999;
	display: flex;
	justify-content: center;
`;

const ScContainer = styled.div`
	width: calc(100% - 32px);
	max-width: 664px;
	height: 88px;
	display: flex;
	flex-direction: row;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	overflow: hidden;
`;

const ScInnerContainer = styled.div`
	background: var(--bright-color);
	padding: 8px 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ScAmount = styled.div`
	min-width: 88px;
	height: 88px;
	justify-content: center;
	display: flex;
	align-items: center;
	background-color: var(--dark-color);
	color: var(--bright-color);
	font-size: 24px;
`;

const ScActions = styled.div`
	display: flex;
	align-items: center;
	margin-left: 16px;
`;

const ScText = styled.div`
	font-size: 12px;
`;

const ScAction = styled.div<{
	isDisabled?: boolean
}>`
	margin-right: 16px;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;

	&:last-child {
		margin-right: 0;
	}

	${(props) =>
		props.isDisabled &&
		css`
			color: var(--font-medium-grey-color);
			cursor: not-allowed;
		`}
`;

const ScClose = styled.div`
	min-width: 88px;
	height: 88px;
	justify-content: center;
	display: flex;
	align-items: center;
	background: var(--grey-color);
`;

const ScLoader = styled(Loader)`
	width: calc(100% - 32px);
	max-width: 664px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
`;
