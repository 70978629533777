/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTable, HeaderGroup, Row, useSortBy, usePagination } from 'react-table';
import { ReactTableProps, StyledTableProps, StyledTableWrapperProps } from './ReactTable.types';
import ReactTableRow from '../ReactTableRow/ReactTableRow';
import ReactTableCell from '../ReactTableCell/ReactTableCell';
import ReactTableHeading from '../ReactTableHeading/ReactTableHeading';
import ReactTableHead from '../ReactTableHead/ReactTableHead';
import ReactTableBody from '../ReactTableBody/ReactTableBody';
import ReactTablePagination from '../ReactTablePagination/ReactTablePagination';
import ReactTableHeadRow from '../ReactTableHeadRow/ReactTableHeadRow';
import { SortOrderType } from '../ReactTableHeading/ReactTableHeading.types';

const ReactTable: React.FC<ReactTableProps> = (props) => {

	const { data, hasNativeSorting, hasNativePagination, customSortingCallback, customPaginationCallback, rowClickedCallback, sortableColumns, totalResults, resultsPerPage, amountPages, customInitialPageIndex, getHighlightColor } = props;

	// Check whether the table is using custom pagination
	const hasManualPagination = typeof customPaginationCallback === 'function';

	// Set the inital page index 
	const initialPageIndex = customInitialPageIndex ?? 0;

	// Check whether the table uses pagination
	const hasPagination = hasManualPagination || hasNativePagination;

	const pageCounter = React.useMemo(() => {
		if(!amountPages) return {};
		return {
			pageCount: amountPages
		};
	}, [amountPages]);
	
	// Use the useTable Hook to send the columns and data to build the table
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		rows,
		prepareRow, 
		page, 
		state: { pageIndex, pageSize },
		canPreviousPage, 
		canNextPage, 
		pageOptions, 
		pageCount,
		gotoPage, 
		nextPage, 
		previousPage,
		setPageSize
	} = useTable({ columns: props.columns, data: data, initialState: { pageIndex: initialPageIndex, pageSize: resultsPerPage }, manualPagination: hasManualPagination, ...pageCounter }, useSortBy, usePagination);

	React.useEffect(() => {
		// If a custom pagination callback is provided it will be triggered when the provided deps changes
		if(customPaginationCallback) customPaginationCallback(pageIndex, pageSize);
	}, [customPaginationCallback, initialPageIndex, pageIndex, pageSize]);

	/**
	 * If the resultsPerPage changes the pageSize will be updated
	 */
	React.useEffect(() => {
		if(resultsPerPage) setPageSize(resultsPerPage);
	}, [resultsPerPage, setPageSize]);
	
	/**
	 * Renders the header columns
	 * 
	 * @param {HeaderGroup<{}>} headerGroup 
	 * @returns {JSX.Element[]}
	 */
	const renderHeaderColumns = React.useCallback((headerGroup: HeaderGroup<{}>) => {
		return headerGroup.headers.map(column => {

			// Define the whether specific or all columns are sortable
			const isSortable = sortableColumns ? sortableColumns.includes(column.id) : hasNativeSorting;

			// Variable holding the React Table sorting policy and functionality
			let sorting = undefined;
			
			// Variable defining the current sort order
			let currentSorting = isSortable ? ('asc' as SortOrderType) : undefined;

			// If the sorting is provided by props
			// hasNativeSorting has to be false
			if(props.customSortBy) {
				const { id, order } = props.customSortBy;
				if(column.id === id) currentSorting = order;
			}

			// If we use native sorting
			if(hasNativeSorting && isSortable) {
				sorting = column.getSortByToggleProps();
				if(!customSortingCallback) {
					if(column.isSorted) currentSorting = column.isSortedDesc ? 'desc' : 'asc';
					else currentSorting = undefined;
				}
			}

			return (
				<ReactTableHeading
					id={column.id}
					customSortingCallback={customSortingCallback}
					sortOrder={currentSorting}
					{...column.getHeaderProps(sorting)}
				>
					{column.render('Header')}
				</ReactTableHeading>
			);
		});
	}, [customSortingCallback, hasNativeSorting, props.customSortBy, sortableColumns]);

	/**
	 * Renders a header group
	 * 
	 * @returns {JSX.Element[]}
	 */
	const renderHeaderGroups = React.useCallback(() => {
		return headerGroups.map(headerGroup => {
			return (
				<ReactTableHeadRow
					{...headerGroup.getHeaderGroupProps()}
				>
					{renderHeaderColumns(headerGroup)}
				</ReactTableHeadRow>
			);
		});
	}, [headerGroups, renderHeaderColumns]);

	/**
	 * Render the row cells
	 * 
	 * @param {Row<{}>} row 
	 * @returns {JSX.Element[]}
	 */
	const renderRowCells = React.useCallback((row: Row<{}>) => {
		return row.cells.map(cell => {
			return (
				<ScReactTableCell
					maxWidthCell={props.maxWidthCell}
					highlightColor={getHighlightColor ? getHighlightColor(cell) : undefined}
					{...cell.getCellProps()}
				>
					{cell.render('Cell')}
				</ScReactTableCell>
			);
		});
	}, [getHighlightColor, props.maxWidthCell]);

	/**
	 * Render the body groups rows
	 * 
	 * @returns {JSX.Element[]}
	 */
	const renderBodyGroupRow = React.useCallback(() => {
		return (hasPagination ? page : rows).map((row, index) => {
			prepareRow(row);

			return (
				<ReactTableRow
					isShowingPointer={!!rowClickedCallback}
					clicked={() => {
						if(rowClickedCallback) rowClickedCallback(row);
					}}
					index={index}
					{...row.getRowProps()}
				>
					{renderRowCells(row)}
				</ReactTableRow>
			);
		});
	}, [hasPagination, page, prepareRow, renderRowCells, rowClickedCallback, rows]);

	return (
		<div className={props.className}>
			{hasPagination && (
				<ReactTablePagination
					page={page}
					pageOptions={pageOptions}
					pageIndex={pageIndex}
					pageCount={pageCount}
					totalResults={totalResults}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
				/>
			)}

			<ScTableWrapper overflowXScroll={props.overflowXScroll}>
				<ScTable
					isFixed={!props.isNotFixed}
					nowrap={props.nowrap}
					{...getTableProps()}
				>
					<ReactTableHead>
						{renderHeaderGroups()}
					</ReactTableHead>
					<ReactTableBody {...getTableBodyProps()}>
						{renderBodyGroupRow()}
					</ReactTableBody>
				</ScTable>
			</ScTableWrapper>
			
			{hasPagination && (
				<div>
					<ReactTablePagination
						page={page}
						pageOptions={pageOptions}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={totalResults}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						paginationSelectOptions={props.paginationSelectOptions}
						resultsPerPage={resultsPerPage}
						resultsPerPageChanged={props.resultsPerPageChanged}
					/>
				</div>
				
			)}
			
		</div>
		
	);
};

export default React.memo(ReactTable);

export const ScTable = styled.div<StyledTableProps>`
	border-spacing: 0px 4px;
	display: table;
	width: 100%;

	${(props) => props.isFixed && css`
		table-layout: fixed;
	`}

	${props => props.nowrap && css`
		white-space: nowrap;
	`}
`;

export const ScTableWrapper = styled.div<StyledTableWrapperProps>`
	overflow-x: ${(props) => (props.overflowXScroll ? 'auto' : 'hidden')};
`;

const ScReactTableCell = styled(ReactTableCell)<StyledTableProps>`
	${props => props.highlightColor && css`
		color: ${props.highlightColor};
	`}
	
`;