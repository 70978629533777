import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Dates, TimeIntervalPickerProps } from './TimeIntervalPicker.types';
import DateTimePicker from '../UI/DateTimePicker/DateTimePicker';
import Button from '../UI/Button/Button';

const TimeIntervalPicker: React.FC<TimeIntervalPickerProps> = (props) => {
	const { datesSubmitted } = props;
    
	// Stores the start and end dates
	const [dates, setDates] = React.useState<Dates>({
		startDate: props.initialStartDate,
		endDate: props.initialEndDate
	});

	/**
     * Handles the dates submitted event
     * 
     * @returns {void}
     */
	const datesSubmittedHandler = React.useCallback((): void => {
		if(!props.isDisabled) datesSubmitted(dates);
	}, [dates, datesSubmitted, props.isDisabled]);
		
	return (
		<ScFilterContainer>
			<DateTimePicker
				label="Startdatum"
				initialValue={props.initialStartDate}
				minDate={props.minStartDate}
				maxDate={DateTime.fromISO(dates.endDate).minus({ days: 1 }).toISO()}
				value={dates.startDate}
				changed={(isoDate: string) => setDates(state => {
                	return {
                		...state,
                		startDate: isoDate
                	};
				})}
				secondaryColor
				timeFormat
				isDisabled={props.isDisabled}
			/>
			<DateTimePicker
				label="Slutdatum"
				minDate={dates.startDate}
				maxDate={props.maxEndDate}
				initialValue={props.initialEndDate}
				value={dates.endDate}
				changed={(isoDate: string) => setDates(state => {
                	return {
                		...state,
                		endDate: isoDate
                	};
				})}
				secondaryColor
				timeFormat
				isDisabled={props.isDisabled}
			/>
			<ScButton
				onClick={datesSubmittedHandler}
				isDisabled={props.isDisabled}
			>
				{props.buttonText}
			</ScButton>
		</ScFilterContainer>
	);
};

export default React.memo(TimeIntervalPicker);

export const ScFilterContainer = styled.div`
	display: flex;
	margin-top: 16px;

	.rdt {
		margin-left: 8px;
		:first-child {
			margin-left: 0;
		}

		&Picker {
			position: absolute;
		}
	}
`;

export const ScButton = styled(Button)`
	margin-left: 8px;
    height: 40px;
    align-self: flex-end;
    margin-bottom: 16px;
`;