/**
 * Verifies a regular expression
 *
 * @param {string | number | undefined | null} value
 * @param {string} regex
 * @returns {boolean}
 */
export const _doRegex = (
	value: string | number | undefined | null,
	regex: RegExp
) => {
	if(!value || !regex) return false;
	return regex.test(String(value).toLowerCase());
};

/**
 * Verifies whether input is numeric
 *
 * @param {string|number} number
 * @returns {boolean}
 */
export const _verifyNumeric = (number: string | number) => {
	const regex = /^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$/;
	return _doRegex(number, regex);
};

/**
 * Verifies whether a string is an email or not
 *
 * @param {string} email
 * @returns {boolean}
 */
export const _verifyEmail = (email: string) => {
	const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	return _doRegex(email, regex);
};

/**
 * Verifies whether a string is a phone number or not
 *
 * @param {string} number
 * @returns {boolean}
 */
export const _verifyPhoneNumber = (number: number) => {
	const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
	return _doRegex(number, regex);
};

/**
 * Verifies if a string is a URL or not
 *
 * @param {string} url
 * @returns {boolean}
 */
export const _verifyUrl = (url: string) => {
	const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
	return _doRegex(url, regex);
};

/**
 * Verifies if a string id a valid slug
 *
 * @param {string} slug
 * @returns {boolean}
 */
export const _verifySlug = (slug: string) => {
	const regex = /^[a-z0-9-/]+$/g;
	return _doRegex(slug, regex);
};

/**
 * Verifies a slug or a URL
 *
 * @param {string} str
 * @returns {boolean}
 */
export const _verifySlugOrUrl = (str: string) => {
	const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.?(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$|(^[a-z0-9-/]+$)/;
	return _doRegex(str, regex);
};

/**
 * Verifies a date in the following variations
 *
 * - 11102019 OR 11-10-2019 OR 11/10/2019
 * - 10112019 OR 10-11-2019 OR 10/11/2019
 * - 20191110 OR 2019-11-10 OR 2019/11/10
 *
 *
 * @param {*} date
 * @returns {boolean}
 */
export const _verifyDate = (date: string) => {
	const mmddyyyyRegex = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/g;
	const ddmmyyyyRegex = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/g;
	const yyyymmddRegex = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048])00)[-/]?02[-/]?29)$/g;

	switch(true) {
		// verifies if the input matches MMDDYYYY (with/(out) / or -)
		case _doRegex(date, mmddyyyyRegex):
			return true;

		case _doRegex(date, ddmmyyyyRegex):
			return true;

		case _doRegex(date, yyyymmddRegex):
			return true;

		default:
			return false;
	}
};
