import { Transforms } from 'slate';
import {
	LIST_ITEM,
	OC7Editor,
	PARAGRAPH,
	ALIGN_LEFT,
	NUMBERED_LIST,
	BULLETED_LIST,
} from '../../../OC7Editor';

const LIST_TYPES = [NUMBERED_LIST, BULLETED_LIST];

export const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type),
		split: true,
	});

	let type = isList ? LIST_ITEM : format;
	if (isActive) type = PARAGRAPH;

	Transforms.setNodes(editor, {
		type,
	});

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

export const isBlockActive = (editor, format) => {
	const [match] = OC7Editor.nodes(editor, {
		match: (n) =>
			n.type === format ||
			(n.alignment && n.alignment === format && format !== ALIGN_LEFT),
	});

	return !!match;
};
