import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon } from './UI';
import useAlert, { AlertPriorityTypes } from '../hooks/useAlert';

const Notification = () => {
	const [alerts, setAlert] = useAlert();

	const notis = alerts.map((alert) => {
		const { priority, title, children } = alert;

		return (
			<ScNotis
				key={`alert_${alert.id}`}
				priority={priority}
			>
				<ScHeader>
					<ScTitle>
						{priority === AlertPriorityTypes.info && (
							<ScStatusIcon icon={['fal', 'circle-info']} />
						)}
						{priority === AlertPriorityTypes.success && (
							<ScStatusIcon icon={['fal', 'check']} />
						)}
						{priority === AlertPriorityTypes.warning && (
							<ScStatusIcon
								icon={['fal', 'triangle-exclamation']}
							/>
						)}
						{priority === AlertPriorityTypes.error && (
							<ScStatusIcon icon={['fal', 'xmark']} />
						)}
						{priority === AlertPriorityTypes.loading && (
							<ScStatusIcon icon={['fal', 'spinner']} />
						)}
						{title}
					</ScTitle>
					<ScCloseBtn
						onClick={() => {
							setAlert('CLOSE', { createdAt: alert.createdAt });
						}}
					>
						<Icon icon={['fal', 'xmark']} />
					</ScCloseBtn>
				</ScHeader>
				{/* <ScDate>{createdAt.format('Y-MM-DD HH:mm:ss')}</ScDate> */}

				{children && (
					<ScText>
						{children}
					</ScText>
				)}
			</ScNotis>
		);
	});

	return (
		<ScContainer>
			{notis}
		</ScContainer>
	);
};

export default Notification;

const ScContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column-reverse;
`;

const ScNotis = styled.div`
	position: relative;
	margin: 16px 16px 0;
	padding: 24px;
	z-index: 9999;
	max-width: 600px;
	color: var(--font-bright-color);
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(2px);

	/* info */
	${(props) =>
		props.priority === AlertPriorityTypes.info &&
		css`
			background: rgba(115, 210, 243, 0.8);
			box-shadow: 0px 16px 40px -12px rgba(115, 210, 243, 0.32);

			background: #87d5f0;
			box-shadow: 0px 2px 14px rgba(135, 213, 240, 0.24);
		`}

	/* success */    
    ${(props) =>
		props.priority === AlertPriorityTypes.success &&
		css`
			background: rgba(32, 177, 38, 0.48);
			box-shadow: 0px 16px 40px -12px rgba(32, 177, 38, 0.32);

			background: #7ed3b2;
			box-shadow: 0px 2px 14px rgba(126, 211, 178, 0.24);
		`}    

	/* warning */
	${(props) =>
		props.priority === AlertPriorityTypes.warning &&
		css`
			background: rgba(243, 207, 115, 0.8);
			box-shadow: 0px 16px 40px -12px rgba(243, 207, 115, 0.32);

			background: #f0d387;
			box-shadow: 0px 2px 14px rgba(240, 211, 135, 0.24);
		`}

	/* error */
	${(props) =>
		props.priority === AlertPriorityTypes.error &&
		css`
			background: rgba(232, 74, 95, 0.8);
			box-shadow: 0px 16px 40px -12px rgba(232, 74, 95, 0.32);

			background: #e76677;
			box-shadow: 0px 2px 14px rgba(231, 102, 119, 0.24);
		`}

	/* error */
	${(props) =>
		props.priority === AlertPriorityTypes.loading &&
		css`
			background: #a6a6a6;
			box-shadow: 0px 2px 14px rgba(166, 166, 166, 0.24);
		`}
`;

const ScHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const ScStatusIcon = styled(Icon)`
	margin-right: 8px;
`;

const ScTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	text-transform: uppercase;
`;

const ScCloseBtn = styled.div`
	height: 24px;
	width: 24px;
	margin-left: 24px;
	font-size: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScText = styled.div`
	font-size: 16px;
	white-space: pre-wrap;
	/* margin-top: 8px; */
`;

// const ScDate = styled.small`
// 	font-size: 12px;
// 	margin: 8px 0;
// `;
