export const GRID = 'grid';
export const TABLE = 'table';
export const THUMBNAIL_SIZE_REGULAR = 'regular';

// Filter types
export const SORT_ORDER = 'sort_order';
export const FILTER = 'filter';
export const BRAND = 'brand';
export const BADGE = 'badge';
export const STATUS = 'status';
