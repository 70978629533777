import React from 'react';

const BlockDivider = (props) => {
	const classes = [
		'line',
		// add type (solid/dashed/none)
		`--${props.data.style}`,

		// add type (thin/thick)
		`--${props.data.weight}`
	];

	return (
		<div className="cms-block cms-blockDivider">
			<div className={classes.join(' ')}></div>
		</div>
	);
};

export default BlockDivider;
