import { NewMediaFile } from '../../../definitions/Media';
export interface SettingsInitialState {
	list: SettingsList;
	item: Setting;
}

export enum SettingsActions {
	SET_INITIAL = 'SETTINGS_SET_INITIAL',
	SET_EDIT_ITEM = 'SETTINGS_SET_EDIT_ITEM',
	CLEAR_EDIT_ITEM = 'SETTINGS_CLEAR_EDIT_ITEM',
	UPDATE_ITEM = 'SETTINGS_UPDATE_ITEM',
}

export enum SettingInputTypes {
	BOOL = 'BOOL',
	DATE = 'DATE',
	INT = 'INT',
	FLOAT = 'FLOAT',
	TEXT = 'TEXT',
	SECRET = 'SECRET',
	COLOR = 'COLOR',
	MEDIA = 'MEDIA',
}

export enum SettingsInputValidation {
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	URL = 'URL',
	DATE = 'DATE',
}

export interface UpdatedSettings {
	options: Setting[];
}

export interface Setting {
	id?: number;
	name?: string;
	display_name: string;
	value?: string | number | boolean | NewMediaFile;
	default?: string | number | boolean | NewMediaFile;
	override?: string | number | boolean | NewMediaFile | null;
	type?: SettingInputTypes;
	validation?: SettingsInputValidation;
	comment?: string;
	visible?: boolean;
	general?: boolean;
	installation_id?: number;
	is_root: boolean;
	children: string[];
    wasChanged?: boolean;
}
export interface SettingsList {
	[key: string]: Setting;
}

export interface SettingsAction {
	type: SettingsActions;
	data: SettingsList | Setting | UpdatedSettings;
}
